import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';


ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  ChartDataLabels
);

const innerText = {
  id: 'innerText',
  beforeDatasetsDraw(chart, args) {
    const { ctx } = chart;

    ctx.save();
    const xCoor = chart.getDatasetMeta(0).data[0].x;
    const yCoor = chart.getDatasetMeta(0).data[0].y;
    ctx.font = 'bold 24px Noto Sans TC';
    ctx.fillStyle = '#666';
    ctx.textAlign = 'center';
    ctx.fillText('沒有可用資料', xCoor, yCoor + 10);
  }
};

@observer
class NoDataPie extends React.Component {
  render() {
    return (
      <div style={{ maxWidth: 800, margin: 'auto' }}>
        <Doughnut
          key={uuidv4()}
          height={500}
          width={500}
          data={{
            labels: [''],
            datasets: [{
              label: [''],
              data: [1],
              backgroundColor: ['#cccccc'],
              datalabels: {
                display: false
              }
            }]
          }}
          options={{
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                enabled: false
              }
            },
            cutout: '50%'
          }}
          plugins={[innerText]}
        />
      </div>
    );
  }
}

export default NoDataPie;
