import { makeObservable, observable, action, computed } from 'mobx';
import { encodeURI } from 'js-base64';
import { message } from 'antd';
import * as xlsx from 'xlsx';
import html2canvas from 'html2canvas';
import dayjs from 'dayjs';

import optionsStore from 'src/stores/optionsStore';
import { getHost } from 'src/utils';
import { generateSingleDayArray, getTrendDate } from 'src/utils/chart';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import { COLOR_SET } from 'src/consts/chart';

export default class DayTrendViewModel {
  @observable originData = null;
  @observable chartData = null;
  @observable searchData = null;
  @observable options = null;
  @observable everydayArr = [];
  @observable tickType = 'day';
  @observable tickArr = [];

  @computed get excelTitle() {
    return [
      { key: 'date', header: '日期' },
      { key: 'volume', header: '聲量' }
    ];
  }

  @computed get excelData() {
    const excel = [];
    if (!this.originData) {
      return excel;
    }

    for (let i = 0; i < this.originData.length; i += 1) {
      const item = this.originData[i];
      excel.push({
        date: item.name,
        volume: item.count
      });
    }

    return excel;
  }

  constructor() {
    makeObservable(this);
  }

  @action update = (data, searchData) => {
    this.updateContent(data, searchData);
    this.updateOptions(searchData);
  };

  @action updateContent = (data, searchData) => {
    if (!data || data.length === 0) {
      return;
    }

    this.originData = data;

    let arr = [];

    const startAt = searchData.date.gte;
    const endAt = searchData.date.lte;

    const totalDays = dayjs(endAt).diff(dayjs(startAt), 'day') + 1;

    const dayArr = generateSingleDayArray(dayjs(startAt), totalDays);
    const { resultArr: list, resultType } = getTrendDate(dayjs(startAt), dayjs(endAt));
    this.tickType = resultType;
    this.tickArr = list;
    this.everydayArr = dayArr;

    arr = dayArr.map((el) => ({
      date: el,
      count: data.find((s) => s.name === el)?.count ?? 0
    }));

    const labels = [];
    const datas = [];
    const accurate = [];

    for (let i = 0; i < arr.length; i += 1) {
      const item = arr[i];
      labels.push(item.date);
      datas.push(item.count);
      accurate.push(item.accurate ?? item.date);
    }

    this.chartData = {
      labels,
      datasets: [{
        label: searchData.keyword,
        data: datas,
        accurate,
        borderColor: COLOR_SET[0],
        backgroundColor: COLOR_SET[19]
      }]
    };
  };

  @action updateOptions = (searchData) => {
    this.searchData = searchData;
    const onPointClick = (elements, chart) => {
      if (elements.length === 0) {
        return;
      }
      const { datasetIndex, index } = elements[0];
      const [gte, lte] = chart.data.datasets[datasetIndex].accurate[index].split('~');
      const womCount = chart.data.datasets[datasetIndex].data[index];

      const { keyword, postType, womType, category, website, channel } = searchData;

      const archiveData = {
        keyword,
        ...(womType && {
          womType
        }),
        ...(postType && {
          postType
        }),
        ...(category ? {
          category: category.map((el) => ({
            id: el,
            name: SOURCE_CATEGORY_TYPE[el]
          }))
        } : {
          category: SOURCE_CATEGORY.map((el) => ({
            id: el.value,
            name: el.label
          }))
        }),
        ...(website && { website: { id: website[0], name: website[0] } }),
        ...(channel && { channel: { id: channel[0].id, name: channel[0].name } }),
        chart: {
          name: '輿情搜尋/摘要圖表/日聲量趨勢'
        },
        date: {
          gte,
          lte: lte ?? gte
        },
        womCount
      };

      console.log('archiveData', archiveData);

      const json = JSON.stringify(archiveData);
      const result = encodeURI(json);
      const url = `${getHost()}/opinion-topics?result=${result}`;

      window.open(url, '_blank');
    };

    this.options = {
      responsive: true,
      event: ['click'],
      tension: 0.3,
      maintainAspectRatio: false,
      scales: {
        y: {
          title: {
            display: true,
            text: '聲量',
            color: '#000'
          },
          beginAtZero: true,
          grace: '5%',
          ticks: {
            color: '#000'
          }
        },
        x: {
          title: {
            display: true,
            text: '日期',
            color: '#000'
          },
          ticks: {
            color: '#000',
            callback: (value, index) => {
              switch (this.tickType) {
                case 'day':
                  return this.tickArr[index];
                case 'five':
                  return index % 5 === 0 ? this.tickArr[index / 5] : null;
                case 'month':
                  if (index === 0) {
                    return this.tickArr[0];
                  }
                  if (dayjs(this.everydayArr[index]).startOf('day').valueOf() === dayjs(this.everydayArr[index]).startOf('month').valueOf()) {
                    return dayjs(this.everydayArr[index]).startOf('day').format('YYYY-MM');
                  }
                  return null;
                default:
                  return null;
              }
            }
          }
        }
      },
      onHover: (event, elements) => {
        const native = event.native;
        if (!native) {
          return;
        }
        const target = native.target;
        if (!target) {
          return;
        }
        if (elements.length > 0) {
          target.style.cursor = 'pointer';
        } else {
          target.style.cursor = 'default';
        }
      },
      onClick: (ev, elements, chart) => onPointClick(elements, chart),
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            color: '#000'
          }
        },
        datalabels: {
          display: false
        }
      }
    };
  };

  @action onDownloadExcel = () => {
    this.isDownload = false;
    if (!this.excelTitle || !this.excelData) {
      message.error('csv檔不存在');
      return;
    }
    const data = [...this.excelData];
    const header = this.excelTitle.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [this.excelTitle.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    const wordbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wordbook, workSheet, '日聲量趨勢');
    xlsx.writeFile(wordbook, `輿情搜尋-${this.searchData.keyword}-日聲量趨勢-${this.searchData.date.gte}-${this.searchData.date.lte}.xlsx`);
  };

  @action appendSheet = (wordbook) => {
    this.isDownload = false;
    if (!this.excelTitle || !this.excelData) {
      message.error('csv檔不存在');
      return;
    }
    const data = [...this.excelData];
    const header = this.excelTitle.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [this.excelTitle.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    xlsx.utils.book_append_sheet(wordbook, workSheet, '日聲量趨勢');
  };

  @action onDownload = async () => {
    await optionsStore.onImgDownload();
    const chart = document.querySelector('.opinion-day-trend-chart');
    const canvas = await html2canvas(chart);
    const a = document.createElement('a');
    a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
    a.download = `輿情搜尋-${this.searchData.keyword}-日聲量趨勢-${this.searchData.date.gte}-${this.searchData.date.lte}.jpg`;
    a.click();
    optionsStore.onImgDownloadEnd();
  };
}
