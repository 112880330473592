import API from 'src/apis';
import OtherResourceModel from 'src/models/response/OtherResource';

export default class OtherResourceService {
  static async getOtherResource() {
    const res = await API.otherResource.getOtherResource();

    return { list: res.data.result.map((el) => OtherResourceModel.fromRes(el)) };
  }

  static async createOtherResource(data) {
    const res = await API.otherResource.createOtherResource(data);

    return OtherResourceModel.fromRes(res.data.result);
  }

  static async putOtherResource(id, data) {
    await API.otherResource.putOtherResource(id, data);
  }

  static async deleteOtherResource(id) {
    await API.otherResource.deleteOtherResource(id);
  }
}
