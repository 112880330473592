import { makeObservable, observable, action, computed } from 'mobx';

import { SENTIMENT_TYPE } from 'src/consts';

import CheckItemViewModel from './CheckItem/viewModel';

export default class BulletinSentimentSelectModalViewModel {
  @observable isModalOpen = false;
  @observable keyword = '';
  @observable keywordCache = '';

  @observable sentimentList = [];

  @computed get indeterminate() {
    return this.sentimentList.some((el) => el.checkedCache) && !this.isAllChecked;
  }

  @computed get isAllChecked() {
    return this.sentimentList.every((el) => el.checkedCache);
  }

  @computed get searchSentiment() {
    return this.sentimentList.filter((el) => el.name.toLowerCase().includes(this.keywordCache.toLowerCase()));
  }

  @computed get selectedSentiment() {
    return this.sentimentList.filter((el) => el.checked).map((el) => el.id);
  }

  @computed get text() {
    const isSelected = this.sentimentList.filter((el) => el.checked).map((el) => el.name);
    return `${isSelected.length >= 1 ? `「${isSelected.join(', ')}」` : ' 「尚未選擇」'}`;
  }


  constructor() {
    makeObservable(this);

    this.init();
  }

  @action init = () => {
    this.sentimentList = SENTIMENT_TYPE.map((el) => new CheckItemViewModel({ id: el.value, name: el.label, checked: false }));
  };

  @action initText = (sentiment) => {
    this.updateSelected(sentiment);

    return this.text;
  };

  @action updateSelected = (data) => {
    this.sentimentList.forEach((el) => {
      if (data.includes(el.id)) {
        el.setInit();
      } else {
        el.setInit(false);
      }
    });
  };

  @action onModalOpen = () => {
    this.isModalOpen = true;
  };

  @action onModalClose = () => {
    this.isModalOpen = false;

    this.keyword = '';
    this.keywordCache = '';
  };

  @action onAllSelect = () => {
    if (!this.isAllChecked) {
      this.sentimentList.forEach((el) => {
        el.setSelect(true);
      });
    } else {
      this.sentimentList.forEach((el) => {
        el.setSelect(false);
      });
    }
  };

  @action onCleanAll = () => {
    this.sentimentList.forEach((el) => {
      el.setSelect(false);
    });
  };

  @action onSubmit = (onSubmitCallback = () => {}) => {
    this.sentimentList.forEach((el) => {
      el.confirmSelect();
    });

    onSubmitCallback({
      sentiment: this.selectedSentiment,
      text: this.text
    });

    this.onModalClose();
  };

  @action onClose = () => {
    this.sentimentList.forEach((el) => {
      el.onDisposeSelect();
    });

    this.onModalClose();
  };

  @action onKeywordChange = (e) => {
    this.keyword = e.target.value;
    if (!e.target.value.trim()) {
      this.onSearchSubmit();
    }
  };

  @action onSearchSubmit = () => {
    this.keywordCache = this.keyword;
  };

  @action onKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onSearchSubmit();
    }
  };
}
