import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import i18n from 'src/i18n';
import { Tabs, Modal, Button, Tooltip } from 'antd';
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import ChartFactory from 'src/components/Charts/factory';
import NoDataChart from 'src/components/Charts/NoData';

import styles from './styles.module.scss';

@observer
class WordChartItem extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <div className={clsx(styles.chartContainer)}>
        {
          viewModel.onReady
            ? (
              <>
                {
                  viewModel.data.length > 1
                  && (
                    <Tabs
                      activeKey={viewModel.activeTabId}
                      items={viewModel.data.map((el) => ({ key: el.id, label: el.name }))}
                      onChange={viewModel.onTabsChange}
                      className={clsx('chartTabs', styles.tabContainer)}
                    />
                  )
                }
                <div className={clsx('download-chart', styles.downloadContainer)}>
                  <div className={styles.chartContent}>
                    <div className={styles.chartTitle}>
                      <span className={styles.titleText}>
                        {`行銷7P分析-${viewModel.activeTabName}文字雲`}
                      </span>
                      <span className={styles.tooltip}>
                        <Tooltip
                          title={(
                            <div className={styles.descContainer}>
                              針對行銷7P量身打造了獨特的分類詞庫，並運用獨家演算法，以掌握該品牌之Product (產品)、Place (地點)、Price (價格)、Promotion (促銷/宣傳)、People/Participants (參與人員)、Process (過程)、Physical Evidence(有形的展示)的消費者真實回饋。
                            </div>
                          )}
                          placement="rightTop"
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                  {
                    viewModel.showChart.map((el) => {
                      return (
                        <div key={el.key}>
                          {
                            ChartFactory.generateChartView(el)
                          }
                        </div>
                      );
                    })
                  }
                </div>
              </>
            )
            : (
              <NoDataChart subChart={ChartFactory.generateNoDataChart(null)} />
            )
        }
        <Modal
          open={viewModel.isModalOpen}
          onCancel={viewModel.onModalClose}
          width={500}
          centered
          closable={false}
          footer={null}
          className={styles.modal}
          bodyStyle={{
            height: 160,
            display: 'flex',
            flexDirection: 'column',
            rowGap: 36,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div className={styles.modalHeader}>
            <div className={styles.title}>
              {i18n.t('wordbook_page_modal_content').replace('%word%', viewModel.wordsViewModel?.selectedName)}
            </div>
            <CloseOutlined className={styles.close} onClick={viewModel.onModalClose} />
          </div>
          <div className={styles.btnContainer}>
            <Button
              className={clsx(styles.btn, styles.normalBtn)}
              onClick={viewModel.onTopicClick}
            >
              {i18n.t('wordbook_page_modal_action_topic')}
            </Button>
            <Button
              className={clsx(styles.btn, styles.blockBtn)}
              onClick={() => viewModel.onWordBlock(viewModel.wordsViewModel?.selectedName)}
            >
              {i18n.t('wordbook_page_modal_action_block')}
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

WordChartItem.propTypes = {
  viewModel: PropTypes.object
};

WordChartItem.defaultProps = {
  viewModel: {}
};


export default WordChartItem;
