import BrandSourceWebsiteTrendModel from './Trend';
import BrandSourceWebsiteRankingModel from './Ranking';
import BrandSourceWebsiteProportionModel from './Proportion';
import BrandSourceWebsiteCompareModel from './Compare';

export default {
  BrandSourceWebsiteTrendModel,
  BrandSourceWebsiteRankingModel,
  BrandSourceWebsiteProportionModel,
  BrandSourceWebsiteCompareModel
};
