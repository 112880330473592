import BrandVolumeTrendViewModel from './BrandVolumeTrend/viewModel';
import BrandSeriesWGSViewModel from './BrandWGS/viewModel';
import BrandProductVolumeRankingViewModel from './BrandProductVolumeRanking/viewModel';
import BrandSourceCategoryProportionViewModel from './BrandSourceCategoryProportion/viewModel';
import BrandSourceChannelRankingViewModel from './BrandSourceChannelRanking/viewModel';
import BrandSentimentOverviewViewModel from './BrandSentimentOverview/viewModel';
import BrandSentimentWordViewModel from './BrandSentimentWord/viewModel';
import BrandConsumerFeatureViewModel from './BrandConsumerFeature/viewModel';
import BrandTopsFeatureWordViewModel from './BrandTopsFeatureWord/viewModel';

import BrandVolumeTrend from './BrandVolumeTrend';
import BrandSeriesWGS from './BrandWGS';
import BrandProductVolumeRanking from './BrandProductVolumeRanking';
import BrandSourceCategoryProportion from './BrandSourceCategoryProportion';
import BrandSourceChannelRanking from './BrandSourceChannelRanking';
import BrandSentimentOverview from './BrandSentimentOverview';
import BrandSentimentWord from './BrandSentimentWord';
import BrandConsumerFeature from './BrandConsumerFeature';
import BrandTopsFeatureWord from './BrandTopsFeatureWord';

export default class BrandHealthFactory {
  static createChartViewModel(id, parent) {
    switch (id) {
      case 'brand_main_volume_trend':
        return new BrandVolumeTrendViewModel(parent);
      case 'brand_main_series_WGS':
        return new BrandSeriesWGSViewModel(parent);
      case 'brand_main_product_volume_ranking':
        return new BrandProductVolumeRankingViewModel(parent);
      case 'brand_main_source_category_proportion':
        return new BrandSourceCategoryProportionViewModel(parent);
      case 'brand_main_source_channel_ranking':
        return new BrandSourceChannelRankingViewModel(parent);
      case 'brand_main_sentiment_overview':
        return new BrandSentimentOverviewViewModel(parent);
      case 'brand_main_pn_word':
        return new BrandSentimentWordViewModel(parent);
      case 'brand_main_consumer_feature':
        return new BrandConsumerFeatureViewModel(parent);
      case 'brand_top1_feature_word':
        return new BrandTopsFeatureWordViewModel(parent, 'top1');
      case 'brand_top2_feature_word':
        return new BrandTopsFeatureWordViewModel(parent, 'top2');
      case 'brand_top3_feature_word':
        return new BrandTopsFeatureWordViewModel(parent, 'top3');
      default:
        return null;
    }
  }

  static createChartView(item) {
    const { id, viewModel } = item;

    switch (id) {
      case 'brand_main_volume_trend':
        return (<BrandVolumeTrend key={id} viewModel={viewModel} />);
      case 'brand_main_series_WGS':
        return (<BrandSeriesWGS key={id} viewModel={viewModel} />);
      case 'brand_main_product_volume_ranking':
        return (<BrandProductVolumeRanking key={id} viewModel={viewModel} />);
      case 'brand_main_source_category_proportion':
        return (<BrandSourceCategoryProportion key={id} viewModel={viewModel} />);
      case 'brand_main_source_channel_ranking':
        return (<BrandSourceChannelRanking key={id} viewModel={viewModel} />);
      case 'brand_main_sentiment_overview':
        return (<BrandSentimentOverview key={id} viewModel={viewModel} />);
      case 'brand_main_pn_word':
        return (<BrandSentimentWord key={id} viewModel={viewModel} />);
      case 'brand_main_consumer_feature':
        return (<BrandConsumerFeature key={id} viewModel={viewModel} />);
      case 'brand_top1_feature_word':
      case 'brand_top2_feature_word':
      case 'brand_top3_feature_word':
        return (<BrandTopsFeatureWord key={id} viewModel={viewModel} />);
      default:
        return null;
    }
  }
}
