import { COLOR_SET } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY } from 'src/consts';
import { generateCSVKey } from 'src/utils/chart';

export default class BrandVolumeProportionModel {
  constructor(origin, query) {
    if (origin.length === 0) {
      this.data = {
        chartData: [{
          id: 'brand_volume_proportion',
          name: '品牌聲量佔比',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }]
        }]
      };
      return;
    }

    const data = origin.slice(0, 10);

    const total = (() => {
      let sum = 0;
      data.forEach((el) => {
        sum += el.count;
      });
      return sum;
    })();

    const {
      postType,
      womType
    } = query;

    const [gte, lte] = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'));

    const csv = [
      {
        date: `${gte} ~ ${lte}`
      }
    ];

    const allTotal = (() => {
      let sum = 0;
      origin.forEach((el) => {
        sum += el.count;
      });
      return sum;
    })();

    origin.forEach((el) => {
      const row = csv[0];

      row[`${el.name}`] = el.count;
      row[`${el.name}-佔比`] = `${Math.floor((el.count / allTotal) * 1000) / 10}%`;
    });

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'brand_volume_proportion',
          name: '品牌聲量佔比',
          data: [
            ...(
              total
                ? [{
                  labels: data.map((el) => el.name),
                  datasets: [{
                    label: `${gte} ~ ${lte}`,
                    brandId: data.map((el) => el.id),
                    data: data.map((el) => el.count),
                    backgroundColor: data.map((el, i) => COLOR_SET[i % 20]),
                    datalabels: {
                      color: '#fff',
                      formatter: (value) => {
                        const percentage = ((value / total) * 100).toFixed();
                        return percentage > 5 ? `${percentage}%` : null;
                      }
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const percentage = ((context.parsed / total) * 100).toFixed();
                          return ` ${context.parsed}: [${percentage}%]`;
                        }
                      }
                    }
                  }]
                }]
                : [{ type: 'nodata' }]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const brandName = chart.data.labels[index];
              const brandId = chart.data.datasets[datasetIndex].brandId[index];
              const womCount = chart.data.datasets[datasetIndex].data[index];

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                category: SOURCE_CATEGORY.map((el) => ({
                  id: el.value,
                  name: el.label
                })),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '數據報告/品牌競爭分析/品牌聲量佔比'
                },
                brand: [{
                  id: brandId,
                  name: brandName
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'pie',
          options: {
            event: ['click'],
            plugins: {
              legend: {
                position: 'right'
              }
            }
          },
          csv,
          csvKey
        }
      ],
      brand: origin.map((el) => ({ id: el.id, name: el.name }))
    };
  }

  static fromRes(data, query) {
    return new BrandVolumeProportionModel(data, query);
  }
}
