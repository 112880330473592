import { request, getHost, getHeaders } from './utils';

export const getAdvancedResearchTrend = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/adv-search/trend`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getAdvancedResearchCount = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/adv-search/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getAdvancedResearchStack = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/adv-search/stack`,
    headers: getHeaders(),
    data
  };
  return request(options);
};
