import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import { Waypoint } from 'react-waypoint';
import dayjs from 'dayjs';

import ScrollTop from 'src/components/ScrollTop';

import { CaretUpOutlined, CaretDownOutlined, PlusOutlined, MoreOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Table, Dropdown, Modal } from 'antd';
import RangeDatePicker from 'src/components/datepicker';

import { PACKAGE_SORT_TYPE } from 'src/consts';
import { PACKAGES_TABLE } from 'src/consts/tables';
import SearchIcon from 'src/assets/MenuIcons/SearchIcon.svg';

import styles from './styles.module.scss';

@observer
class ManageSection extends React.Component {

  componentDidMount() {
    this.props.viewModel.init();
  }
  render() {
    const { viewModel, drawerViewModel, onSectionChange } = this.props;
    return (
      <>
        <div className={styles.contentContainer}>
          <div className={styles.actionContainer}>
            <div className={styles.filterContainer}>
              <div className={styles.filterDateText}>
                {i18n.t('packages_filter_date_title')}
              </div>
              <RangeDatePicker
                className={styles.dateSelector}
                suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                value={viewModel.filterDate}
                onChange={viewModel.onDateChange}
                onOpenChange={viewModel.onPickerChange}
                allowEmpty={[true, true]}
              />
            </div>
            <div className={styles.btnContainer}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                className={styles.addBtn}
                onClick={drawerViewModel.onAddBtnClick}
              >
                {i18n.t('packages_add_title')}
              </Button>
              <Button
                type="link"
                className={styles.deleteBtn}
                onClick={() => onSectionChange('delete')}
              >
                {i18n.t('packages_delete_title')}
              </Button>
            </div>
          </div>
          <div className={styles.resContainer}>
            <div className={styles.tableActionsContainer}>
              <div className={styles.rowActions}>
                {
                  viewModel.selectedPackages.length > 0
                  && (
                    <>
                      <Button
                        className={styles.deleteBtn}
                        icon={<DeleteOutlined />}
                        onClick={viewModel.onDeleteSelected}
                      >
                        {i18n.t('packages_table_row_action_delete')}
                      </Button>
                      <Button
                        className={styles.cancelBtn}
                        icon={<CloseOutlined />}
                        onClick={viewModel.onClearSelected}
                      >
                        {i18n.t('packages_table_row_action_cancel')}
                      </Button>
                    </>
                  )
                }
              </div>
              <div className={styles.searchContainer}>
                <Button
                  type="link"
                  className={clsx(styles.sortBtn, viewModel.sortedBy === PACKAGE_SORT_TYPE.PinDateDesc ? styles.activePin : styles.inactivePin)}
                  icon={(
                    <div className={styles.sortIconContainer}>
                      <CaretUpOutlined className={styles.up} />
                      <CaretDownOutlined className={styles.down} />
                    </div>
                  )}
                  onClick={viewModel.onPinClick}
                >
                  {i18n.t('packages_sort_pin_title')}
                </Button>
                <div className={styles.searchInputContainer}>
                  <input
                    className={styles.searchInput}
                    placeholder={i18n.t('packages_search_placeholder')}
                    value={viewModel.keyword}
                    onChange={viewModel.onChange}
                    onKeyDown={viewModel.onKeyDown}
                  />
                  <div
                    className={styles.searchBtn}
                    onClick={viewModel.onSubmit}
                  >
                    <img src={SearchIcon} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.tableContainer}>
              <Table
                key={viewModel.packages.length}
                loading={viewModel.isProcess}
                rowClassName={styles.row}
                rowKey={(item) => item.id}
                dataSource={viewModel.packages}
                pagination={false}
                className="packagesTable"
                showSorterTooltip={false}
                rowSelection={{
                  columnWidth: '4%',
                  selectedRowKeys: viewModel.selectedPackageKeys,
                  onSelect: viewModel.onSelect,
                  onSelectAll: viewModel.onSelectAll
                }}
              >
                {
                  PACKAGES_TABLE.map((el) => {
                    if (el.key === 'action') {
                      return (
                        <Table.Column
                          title={el.title}
                          dataIndex={el.dataIndex}
                          key={el.key}
                          className={styles.columns}
                          width="4%"
                          render={(_, record) => {
                            return (
                              <Dropdown
                                menu={{
                                  items: [
                                    {
                                      key: 'edit',
                                      label: ['DONE', 'CANCEL', 'FAIL'].includes(record.packageStatusType) ? i18n.t('packages_table_action_edit') : '檢視數據包設定',
                                      onClick: () => { viewModel.onEditPackages(record.id, drawerViewModel.onDrawerOpen, drawerViewModel.onReadOnlyDrawerOpen); }
                                    },
                                    {
                                      key: 'analysis',
                                      label: '重新分析數據包',
                                      onClick: () => { viewModel.reAnalysis(record.id); }
                                    },
                                    {
                                      key: 'pin',
                                      label: i18n.t('packages_table_action_pin'),
                                      onClick: () => { viewModel.onPinPackages(record.id); }
                                    },
                                    {
                                      key: 'project',
                                      label: i18n.t('packages_table_action_check_project'),
                                      onClick: () => { viewModel.onCheckProjectPackages(record.projectId); }
                                    },
                                    {
                                      key: 'delete',
                                      label: i18n.t('packages_table_action_delete'),
                                      onClick: () => { viewModel.onDeletePackages(record); }
                                    }
                                  ]
                                }}
                                arrow={{ pointAtCenter: true }}
                                placement="topRight"
                              >
                                <Button
                                  icon={<MoreOutlined />}
                                  type="link"
                                  className={styles.actionBtn}
                                />
                              </Dropdown>
                            );
                          }}
                        />
                      );
                    }
                    if (el.key === 'users') {
                      return (
                        <Table.Column
                          title={el.title}
                          dataIndex={el.dataIndex}
                          key={el.key}
                          className={styles.columns}
                          width="15%"
                          render={(text, record) => {
                            if (record.users?.length === 0) {
                              return <div>---</div>;
                            }

                            if (record.users?.length === 4) {
                              const content = record.users?.slice(0, 3).map((user) => {
                                return user.name;
                              }).join(',  ');
                              return <div>{`${content}, ...`}</div>;
                            }

                            const content = record.users?.map((user) => {
                              return user.name;
                            }).join(',  ');
                            return <div>{content}</div>;
                          }}
                        />
                      );
                    }
                    if (el.key === 'establishTime' || el.key === 'adjustTime') {
                      return (
                        <Table.Column
                          title={(
                            <div
                              className={styles.tableTitleContainer}
                              onClick={() => viewModel.onSortChange(el.key)}
                            >
                              <div>
                                {el.title}
                              </div>
                              {
                                el.key === 'establishTime'
                                  ? (
                                    <div className={styles.sortIconContainer}>
                                      <CaretUpOutlined className={clsx(styles.up, viewModel.sortedBy === PACKAGE_SORT_TYPE.CreateDateAsc && styles.activeIcon)} />
                                      <CaretDownOutlined className={clsx(styles.down, viewModel.sortedBy === PACKAGE_SORT_TYPE.CreateDateDesc && styles.activeIcon)} />
                                    </div>
                                  )
                                  : (
                                    <div className={styles.sortIconContainer}>
                                      <CaretUpOutlined className={clsx(styles.up, viewModel.sortedBy === PACKAGE_SORT_TYPE.LastChangeDateAsc && styles.activeIcon)} />
                                      <CaretDownOutlined className={clsx(styles.down, viewModel.sortedBy === PACKAGE_SORT_TYPE.LastChangeDateDesc && styles.activeIcon)} />
                                    </div>
                                  )
                              }

                            </div>
                          )}
                          dataIndex={el.dataIndex}
                          key={el.key}
                          className={styles.columns}
                          width="15%"
                          render={(text, record) => {
                            if (!text) {
                              return <div>---</div>;
                            }
                            return <div>{text}</div>;
                          }}
                        />
                      );
                    }
                    if (el.key === 'projectStatus') {
                      return (
                        <Table.Column
                          title={el.title}
                          dataIndex={el.dataIndex}
                          key={el.key}
                          className={styles.columns}
                          width="15%"
                          render={(text, record) => {
                            if (!text) {
                              return <div>---</div>;
                            }
                            return (
                              <div className={clsx(record.projectStatusType !== 'DONE' && styles.error)}>
                                {text}
                              </div>
                            );
                          }}
                        />
                      );
                    }
                    if (el.key === 'packageStatus') {
                      return (
                        <Table.Column
                          title={el.title}
                          dataIndex={el.dataIndex}
                          key={el.key}
                          className={styles.columns}
                          width="15%"
                          render={(text, record) => {
                            if (!text) {
                              return <div>---</div>;
                            }
                            return (
                              <div className={clsx(record.packageStatusType !== 'DONE' && styles.error)}>
                                {text}
                              </div>
                            );
                          }}
                        />
                      );
                    }
                    return (
                      <Table.Column
                        title={el.title}
                        dataIndex={el.dataIndex}
                        key={el.key}
                        className={styles.columns}
                        width={(el.key === 'name' || el.key === 'holder') ? '10%' : '15%'}
                        render={(text, record) => {
                          if (!text) {
                            return <div>---</div>;
                          }
                          return <div>{text}</div>;
                        }}
                      />
                    );
                  })
                }
              </Table>
            </div>
          </div>
        </div>
        {
          viewModel.anchor
          && (
            <Waypoint
              onEnter={viewModel.getPackages}
            />
          )
        }
        <Modal
          open={viewModel.isDeleteModalOpen}
          closable={false}
          centered
          onCancel={viewModel.onDeleteModalClose}
          className="selectModal"
          width={422}
          footer={null}
        >
          <div className={styles.modalContent}>
            {
              viewModel.deleteType === 'single'
                ? (
                  <div className={styles.description}>
                    <p>
                      <span>
                        確認是否刪除
                      </span>
                      <span className={styles.bold}>
                        {viewModel.deleteTarget.name}
                      </span>
                      <span>
                        ？
                      </span>
                    </p>
                    <p>
                      {'注意：將會永久刪除此數據包的所有資料。\n且會同時刪除被分享者的數據包'}
                    </p>
                  </div>
                )
                : (
                  <div className={styles.description}>
                    <div>
                      確認是否刪除
                    </div>
                    <div className={styles.packagesInfo}>
                      {
                        viewModel.selectedPackages.map((el, i) => {
                          return (
                            <div className={styles.bold} key={el.id}>
                              {`${el.name}${i !== viewModel.selectedPackages.length - 1 ? ',' : ''}`}
                            </div>
                          );
                        })
                      }
                    </div>
                    <div>
                      {`等共${viewModel.selectedPackages.length}個數據包？`}
                    </div>
                    <div className={styles.note}>
                      {'注意：將會永久刪除此數據包的所有資料。\n且會同時刪除被分享者的數據包'}
                    </div>
                  </div>
                )
            }
            <div className={styles.actions}>
              <Button
                className={clsx(styles.btn, styles.confirmBtn)}
                onClick={viewModel.onConfirmDelete}
              >
                確認
              </Button>
              <Button
                className={clsx(styles.btn, styles.cancelBtn)}
                onClick={viewModel.onDeleteModalClose}
              >
                取消
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          open={viewModel.isReAnalysisModalOpen}
          closable={false}
          centered
          onCancel={viewModel.onReAnalysisModalClose}
          className="selectModal"
          width={422}
          footer={null}
        >
          <div className={styles.modalContent}>

            <div className={styles.description}>
              <div>
                有仍在進行的進階分析，是否仍要重新進行數據包分析
              </div>
              <div className={styles.note}>
                {'注意：確認後，將終止目前的進階分析。\n且分析完後需重新手動送出進階分析'}
              </div>
            </div>
            <div className={styles.actions}>
              <Button
                className={clsx(styles.btn, styles.confirmBtn)}
                onClick={viewModel.onStopAndReAnalysis}
              >
                確認
              </Button>
              <Button
                className={clsx(styles.btn, styles.cancelBtn)}
                onClick={viewModel.onReAnalysisModalClose}
              >
                取消
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          open={viewModel.isNoteAdvAnalysisModalOpen}
          closable={false}
          centered
          onCancel={viewModel.onNoteAdvAnalysisModalClose}
          className="selectModal"
          width={422}
          footer={null}
        >
          <div className={styles.modalContent}>
            <div className={styles.description}>
              <div>
                目前進階分析執行中，需終止進階分析才可編輯數據包
              </div>
            </div>
            <div className={styles.actions}>
              <Button
                className={clsx(styles.btn, styles.confirmBtn)}
                onClick={viewModel.onNoteAdvAnalysisModalClose}
                // ref={viewModel.agreeRef}
              >
                確認
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          open={viewModel.isReAnalysisDateModalOpen}
          closable={false}
          centered
          onCancel={viewModel.onReAnalysisDateModalClose}
          className="selectModal"
          width={500}
          footer={null}
        >
          <div className={styles.modalContent}>
            <div className={styles.modalTitle}>
              重新分析數據包
            </div>
            <div className={styles.rowItem}>
              <div className={styles.rowTitle}>
                請選擇指定時間範圍：
              </div>
              <RangeDatePicker
                value={viewModel.reAnalysisRange}
                onChange={viewModel.onReAnalysisDateSelect}
                disabledDate={(current) => {
                  return current < dayjs(viewModel.reAnalysisTarget?.validDate?.[0]).startOf('day')
                    || current > dayjs(viewModel.reAnalysisTarget?.validDate?.[1]).endOf('day');
                }}
              />
            </div>
            <div className={styles.actions}>
              <Button
                className={clsx(styles.btn, styles.confirmBtn)}
                onClick={viewModel.onReAnalysis}
              >
                確認
              </Button>
              <Button
                className={clsx(styles.btn, styles.cancelBtn)}
                onClick={viewModel.onReAnalysisDateModalClose}
              >
                取消
              </Button>
            </div>
          </div>
        </Modal>
        <ScrollTop containerName="main" />
      </>
    );
  }
}

ManageSection.propTypes = {
  viewModel: PropTypes.object,
  drawerViewModel: PropTypes.object,
  onSectionChange: PropTypes.func
};

ManageSection.defaultProps = {
  viewModel: {},
  drawerViewModel: {},
  onSectionChange: () => { }
};


export default ManageSection;
