import { request, getHost, getHeaders } from './utils';

export const getOtherResource = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/otherResources`,
    headers: getHeaders()
  };
  return request(options);
};

export const createOtherResource = (data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/otherResources`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const putOtherResource = (id, data) => {
  const options = {
    method: 'put',
    url: `${getHost()}/api/v1/otherResources/${id}`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const deleteOtherResource = (id) => {
  const options = {
    method: 'delete',
    url: `${getHost()}/api/v1/otherResources/${id}`,
    headers: getHeaders()
  };
  return request(options);
};
