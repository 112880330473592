import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { COLOR_SET, WORD_CONFIG } from 'src/consts/chart';

export default class IndustryWordCloud {
  constructor(data, prevData) {
    const colorSets = {};
    const groupSets = {};

    data.forEach((group, i) => {
      if (group.name === '全部') {
        return;
      }
      group.item.forEach((item) => {
        colorSets[`${item.id}`] = COLOR_SET[i % 20];
        groupSets[`${item.id}`] = {
          id: group.id,
          name: group.name
        };
      });
    });

    prevData.forEach((group, i) => {
      if (group.name === '全部') {
        return;
      }
      group.item.forEach((item) => {
        colorSets[`${item.id}`] = COLOR_SET[i % 20];
        groupSets[`${item.id}`] = {
          id: group.id,
          name: group.name
        };
      });
    });

    const generateFormattedData = (value) => {
      const result = value.map((group) => {
        const maxNum = Math.max(...group.item.map((item) => item.count));
        const minNum = Math.min(...group.item.map((item) => item.count));

        const getNew = (num) => {
          // FOR ONLY ONE SITUATION
          if (maxNum === minNum) {
            return WORD_CONFIG[size].basicSize * WORD_CONFIG[size].maxRatio;
          }
          return ((num - minNum) * (WORD_CONFIG[size].maxRatio / (maxNum - minNum)) + 1) * WORD_CONFIG[size].basicSize;
        };

        const size = (() => {
          const length = value[0].item.length;
          if (length <= 20) {
            return 'small';
          }
          if (length > 20 && length <= 50) {
            return 'medium';
          }
          return 'large';
        })();

        const newItem = group.item.map((el) => ({
          id: el.id,
          name: el.name,
          count: getNew(el.count),
          originCount: el.count,
          color: colorSets[el.id],
          group: groupSets[el.id]
        }));

        return {
          id: group.id.trim() ? group.id : 'all',
          name: group.name === '全部' ? '不分類' : group.name,
          item: newItem
        };
      });
      const allItem = result.find((el) => el.id === 'all');

      if (allItem) {
        result.pop();
        result.unshift(allItem);
      }

      return result;
    };

    const currentResult = generateFormattedData(data);
    const prevResult = generateFormattedData(prevData);

    const { currentDate, previousDate } = infoHeaderViewModel.multiViewModel;
    const currentTime = currentDate.map((el) => el.format('YYYY-MM-DD'));
    const prevTime = previousDate.map((el) => el.format('YYYY-MM-DD'));

    const csv = [];
    currentResult?.forEach((group) => {
      group.item.forEach((item) => {
        csv.push({
          date: currentTime.join('~'),
          group: group.name,
          word: item.name,
          count: item.originCount
        });
      });
    });
    prevResult.forEach((group) => {
      group.item.forEach((item) => {
        csv.push({
          date: prevTime.join('~'),
          group: group.name,
          word: item.name,
          count: item.originCount
        });
      });
    });

    const csvKey = [
      { key: 'date', header: '日期' },
      { key: 'group', header: '特性分類' },
      { key: 'word', header: '特性關鍵字' },
      { key: 'count', header: '提及則數' }
    ];

    this.data = {
      data: [
        {
          id: 'industry',
          name: '行業文字雲',
          item: currentResult,
          prevItem: prevResult,
          csv,
          csvKey
        }
      ],
      type: 'words'
    };
  }

  static fromRes(data, prevData) {
    return new IndustryWordCloud(data, prevData);
  }
}
