export const valueToTextSentiment = (value) => {
  if (value === 1) {
    return 'positive';
  }
  if (value === 0) {
    return 'neutral';
  }

  return 'negative';
};

export const valueToTextSentimentCT = (value) => {
  if (value === 1) {
    return '偏正面';
  }
  if (value === 0) {
    return '偏中性';
  }

  return '偏負面';
};

export const textToValueSentiment = (text) => {
  if (text === 'positive') {
    return 1;
  }
  if (text === 'neutral') {
    return 0;
  }

  return -1;
};

export const getLevelText = (level) => {
  switch (level) {
    case 'industry':
      return '行業';
    case 'brand':
      return '品牌';
    case 'series':
      return '系列';
    case 'product':
      return '產品';
    default:
      return '';
  }
};

export const delay = (ms) => {
  return new Promise((res) => {
    setTimeout(() => {
      res();
    }, ms);
  });
};


export const getHost = () => {
  // local
  if (/:\d{4,}$/.test(window.location.origin)) {
    const r = window.location.origin.split(/:\d{4,}/);
    if (r[0]) {
      return `${r[0]}:3000`;
    }
  }

  // dev
  if (/d90d217a/.test(window.location.host)) {
    return 'https://buzz-voc-d90d217a.miraclemobile.com.tw';
  }

  // pro
  return 'https://vocplus.com.tw';
};

export const separate = (string) => {
  return `${string.substring(0, 2)}-${string.substring(2)}`;
};
