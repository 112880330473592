import { request, getHost, getHeaders } from '../utils';

export const getBrandVolumeTrend = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-routine/volume/trend`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandVolumeCount = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-routine/volume/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getIndustrySourceCategoryCount = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-routine/industry/source/category/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getMainBrandSourceCategoryCount = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-routine/source/category/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getIndustrySourceChannelCount = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-routine/industry/source/channel/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getMainBrandSourceChannelCount = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-routine/source/channel/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getSeriesVolumeCount = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-routine/series/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getTopsSeriesProductVolumeCount = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-routine/series/product/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandSentimentOverview = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-routine/sentiment`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandConsumerFeature = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-routine/feature/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getIndustryTopsFeatureWord = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-routine/industry/feature/term`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandTopsFeatureWord = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-routine/feature/term`,
    headers: getHeaders(),
    data
  };
  return request(options);
};
