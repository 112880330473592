import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import packageStore from 'src/stores/packageStore';
import { message } from 'antd';

import TopicService from 'src/services/topic';
import { valueToTextSentiment } from 'src/utils';

export default class TopicSinglePageViewModel {
  @observable vocKey = '';
  @observable matchId = '';
  @observable searchId = '';
  @observable packageId = '';

  @observable sentiment = 0;
  @observable vocTitle = '';
  @observable hitPostType = '';
  @observable postUrl = '';
  @observable website = '';
  @observable channel = '';
  @observable author = '';
  @observable date = '';
  @observable viewCount = 0;
  @observable shareCount = 0;
  @observable commentCount = 0;
  @observable womCount = 0;
  @observable content = '';
  @observable hitBrand = {};
  @observable hitSeries = {};
  @observable hitProduct = {};
  @observable commonHitBrand = '';
  @observable commonHitSeries = '';
  @observable commonHitProduct = '';

  @observable commentStatistic = {
    total: 0,
    positive: 0,
    neutral: 0,
    negative: 0
  };

  @observable comments = [];
  @observable selectedComments = [];

  @observable selectedSentiment = null;
  @observable canSubmit = false;

  @observable isInit = false;

  @observable isLoading = false;

  @observable editable = true;

  @computed get showSentiment() {
    switch (this.selectedSentiment) {
      case -1:
        return 'negative';
      case 0:
        return 'neutral';
      case 1:
        return 'positive';
      default:
        return null;
    }
  }

  @computed get nextPage() {
    return this.comments.length / 50 + 1;
  }

  @computed get hasMore() {
    return this.comments.length < this.commentStatistic.total;
  }

  constructor() {
    makeObservable(this);

    this.init();
  }

  @action init = async () => {
    const params = new URL(window.location).searchParams;

    this.matchId = params.get('matchId');
    this.searchId = params.get('searchId');
    this.editable = !params.get('editable') ?? true;
    this.packageId = window.location.pathname.split('/')[2];
    this.vocKey = window.location.pathname.split('/')[3];

    try {
      const { post, comment, commentStatistic } = await TopicService.getSingleTopic(this.packageId, this.vocKey, {
        matchId: this.matchId,
        searchId: this.searchId
      });

      runInAction(() => {
        const {
          author,
          hitPostType,
          website,
          channel,
          date,
          viewCount,
          shareCount,
          commentCount,
          womCount,
          content,
          sentiment,
          title,
          url,
          hitBrand,
          hitSeries,
          hitProduct,
          commonHitBrand,
          commonHitSeries,
          commonHitProduct
        } = post;

        this.vocTitle = title;
        this.hitPostType = hitPostType;
        this.postUrl = url;
        this.website = website;
        this.channel = channel;
        this.author = author;
        this.date = date;
        this.viewCount = viewCount;
        this.shareCount = shareCount;
        this.commentCount = commentCount;
        this.womCount = womCount;
        this.content = content;
        this.sentiment = valueToTextSentiment(sentiment);
        this.hitBrand = hitBrand;
        this.hitSeries = hitSeries;
        this.hitProduct = hitProduct;
        this.commonHitBrand = commonHitBrand;
        this.commonHitSeries = commonHitSeries;
        this.commonHitProduct = commonHitProduct;

        this.comments = comment;

        this.commentStatistic = commentStatistic;

        this.isInit = true;
      });

    } catch (error) {
      console.log(error);
    }
  };

  @action onPaging = async () => {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    try {
      const { comment } = await TopicService.getSingleTopicPaging(this.packageId, this.vocKey, {
        matchId: this.matchId,
        searchId: this.searchId,
        page: this.nextPage
      });
      runInAction(() => {
        this.comments = [...this.comments, ...comment];
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action onPostSentimentChange = async (value) => {
    const sentiment = (() => {
      switch (value) {
        case 'positive':
          return 0;
        case 'neutral':
          return -1;
        default:
          return 1;
      }
    })();
    try {
      await TopicService.updateSentiment(
        this.packageId,
        [{
          vocKey: this.vocKey,
          sentiment,
          ...((this.hitBrand.id && !this.hitProduct.id) && { search: this.hitBrand.id }),
          ...((this.hitProduct.id) && { search: this.hitProduct.id }),
          ...((this.hitSeries.id && {
            tagKey: packageStore.series.find((el) => el.children.some((c) => c.id === this.hitSeries.id)).id,
            tagValue: this.hitSeries.id
          }))
        }]
      );
      runInAction(() => {
        this.sentiment = valueToTextSentiment(sentiment);
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action onSentimentSelect = (e) => {
    this.selectedSentiment = e;
    this.canSubmit = true;
  };

  @action onSentimentChange = async () => {
    const list = this.selectedComments.map((el) => ({
      vocKey: el,
      sentiment: this.selectedSentiment,
      ...((this.hitBrand.id && !this.hitProduct.id) && { search: this.hitBrand.id }),
      ...((this.hitProduct.id) && { search: this.hitProduct.id }),
      ...((this.hitSeries.id && {
        tagKey: packageStore.series.find((series) => series.children.some((c) => c.id === this.hitSeries.id)).id,
        tagValue: this.hitSeries.id
      }))
    }));

    try {
      await TopicService.updateSentiment(this.packageId, list);

      runInAction(() => {
        this.selectedComments.forEach((el) => {
          const target = this.comments.find((e) => e.vocKey === el);
          if (target) {
            target.sentiment = this.selectedSentiment;
          }
          this.canSubmit = false;
        });

        message.info(`已改動 ${this.selectedComments.length} 筆情緒，若需更新情緒統計請重整畫面`);

        this.selectedSentiment = null;
        this.selectedComments = [];
      });

    } catch (error) {
      console.log(error);
    }


  };

  @action onSelect = (record, selected, selectedRows, nativeEvent) => {
    this.selectedComments = selectedRows.map((el) => el.vocKey);
  };

  @action onSelectAll = (selected, selectedRows, changeRows) => {
    this.selectedComments = selectedRows.map((el) => el.vocKey);
    message.info(`已勾選 ${selectedRows.length} 筆${this.hasMore ? '，若需勾選更多，請捲動列表到底' : ''}`);
  };

}
