import { COLOR_SET } from 'src/consts/chart';
import { getHost } from 'src/utils';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { SOURCE_CATEGORY_TYPE } from 'src/consts';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { generateCSVKey } from 'src/utils/chart';

export default class IndustrySourceCategoryRankingModel {
  constructor(data, prevData, query) {
    if (data.length === 0 && prevData.length === 0) {
      this.data = {
        chartData: [{
          id: '',
          name: '',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }]
        }]
      };
      return;
    }

    const total = (() => {
      let sum = 0;
      data.forEach((el) => {
        sum += el.count;
      });
      return sum;
    })();

    const prevTotal = (() => {
      let sum = 0;
      prevData.forEach((el) => {
        sum += el.count;
      });
      return sum;
    })();

    if (!total && !prevTotal) {
      this.data = {
        chartData: [{
          id: '',
          name: '',
          data: [{
            type: 'nodata',
            subType: 'default'
          }]
        }]
      };
      return;
    }

    const combine = data.map((el) => ({
      name: el.name,
      count: el.count,
      prevCount: 0
    }));

    prevData.forEach((el) => {
      const target = combine.find((item) => item.name === el.name);
      if (target) {
        target.prevCount = el.count;
      } else {
        combine.push({
          name: el.name,
          count: 0,
          prevCount: el.count
        });
      }
    });

    combine.sort((a, b) => b.count - a.count);

    const { currentDate, previousDate } = infoHeaderViewModel.multiViewModel;
    const [gte, lte] = currentDate.map((el) => el.format('YYYY-MM-DD'));
    const [prevGte, prevLte] = previousDate.map((el) => el.format('YYYY-MM-DD'));

    const prototype = [
      ...(
        total
          ? [{
            date: `${gte} ~ ${lte}`
          }]
          : []
      ),
      ...(
        prevTotal
          ? [{
            date: `${prevGte} ~ ${prevLte}`
          }]
          : []
      )
    ];

    const csv = prototype.map((el, di) => {
      const row = el;
      for (let i = 0; i < combine.length; i += 1) {
        if (di === 0) {
          row[`${SOURCE_CATEGORY_TYPE[combine[i].name]}`] = combine[i].count;
        } else {
          row[`${SOURCE_CATEGORY_TYPE[combine[i].name]}`] = combine[i].prevCount;
        }
      }

      return row;
    });

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'industry_source_category_ranking',
          name: '行業來源類型排行',
          data: [
            {
              labels: combine.map((el) => SOURCE_CATEGORY_TYPE[el.name]),
              datasets: [
                ...(
                  total
                    ? [{
                      label: `${gte} ~ ${lte}`,
                      data: combine.map((el) => el.count),
                      category: combine.map((el) => el.name),
                      backgroundColor: COLOR_SET[0],
                      borderSkipped: true,
                      maxBarThickness: 50
                    }]
                    : []
                ),
                ...(
                  prevTotal
                    ? [{
                      label: `${prevGte} ~ ${prevLte}`,
                      data: combine.map((el) => el.prevCount),
                      category: combine.map((el) => el.name),
                      backgroundColor: COLOR_SET[19],
                      borderSkipped: true,
                      maxBarThickness: 50
                    }]
                    : []
                )

              ]
            }
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType
              } = query;
              const { datasetIndex, index } = elements[0];
              const category = chart.data.datasets[datasetIndex].category[index];
              const categoryName = chart.data.labels[index];
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const [g, l] = chart.data.datasets[datasetIndex].label.split('~');

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                category: [{
                  id: category,
                  name: categoryName
                }],
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/行業/來源分析/行業來源類型/行業來源類型排行'
                },
                industry: true,
                womCount,
                date: {
                  gte: g,
                  lte: l
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'bar',
          options: {
            event: ['click'],
            scales: {
              y: {
                type: 'linear',
                display: true,
                position: 'left',
                grace: '15%',
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ]
    };
  }

  static fromRes(data, prevData, query) {
    return new IndustrySourceCategoryRankingModel(data, prevData, query);
  }
}
