import { makeObservable, observable, action } from 'mobx';

import PackagesDrawerViewModel from './Drawer/viewModel';

import ManageSectionViewModel from './Sections/ManageSection/viewModel';
import DeleteSectionViewModel from './Sections/DeleteSection/viewModel';

export default class PackagesManageViewModel {
  @observable drawerViewModel = new PackagesDrawerViewModel(this);
  @observable manageSectionViewModel = new ManageSectionViewModel();
  @observable deleteSectionViewModel = new DeleteSectionViewModel();

  @observable activeSection = 'manage'; // > 'manage', 'delete'

  constructor() {
    makeObservable(this);
  }

  @action onSectionChange = (section) => {
    this.activeSection = section;
  };

}
