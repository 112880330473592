import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

export default class ChartItemViewModel {
  parent = {};
  @observable key = uuidv4();
  @observable charts = [];

  @observable isLoading = true;

  constructor(parent) {
    makeObservable(this);

    this.parent = parent;
  }

  @action onLoading = () => {
    this.isLoading = true;
  };

  @action updateChart = (charts) => {
    this.key = uuidv4();

    this.charts = charts;
    this.isLoading = false;
  };
}
