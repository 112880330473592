import { COLOR_SET } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import { generateCSVKey } from 'src/utils/chart';

export default class ProductSeriesVolumeRankingModel {
  constructor(data, prevData, query) {
    if (data.length === 0 && prevData.length === 0) {
      this.data = {
        chartData: [{
          id: 'product_series_volume_ranking',
          name: '系列',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }]
        }]
      };
      return;
    }

    const combine = ProductSeriesVolumeRankingModel.getCombineData(data, prevData);
    const currTime = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD')).join('~');
    const prevTime = infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD')).join('~');
    const csvForAll = [];
    combine.forEach((series) => {
      const targetInCurrent = data?.find((el) => series.id === el.id);
      const targetInPrevious = prevData.find((el) => series.id === el.id);
      const csv = ProductSeriesVolumeRankingModel.generateCSV(targetInCurrent, targetInPrevious);
      const csvKey = generateCSVKey(csv[0]);
      csvForAll.push({
        id: series.id,
        name: series.name,
        csv,
        csvKey
      });
    });

    this.data = {
      chartData: combine.slice(0, 10).map((series) => {
        const targetInCurrent = data?.find((el) => series.id === el.id);
        const currEmpty = targetInCurrent ? targetInCurrent?.item.every((el) => el.count === 0) : true;
        const targetInPrevious = prevData.find((el) => series.id === el.id);
        const prevEmpty = targetInPrevious ? targetInPrevious.item.every((el) => el.count === 0) : true;
        const { csv, csvKey } = csvForAll.find((el) => el.id === series.id);

        return {
          id: series.id,
          name: series.name,
          data: [
            ...(
              !(currEmpty && prevEmpty)
                ? [{
                  labels: series.item.slice(0, 20).map((c) => c.name),
                  datasets: [
                    ...(
                      !currEmpty
                        ? [{
                          label: currTime,
                          data: series.item.slice(0, 20).map((c) => c.count),
                          productId: series.item.slice(0, 20).map((c) => c.id),
                          backgroundColor: COLOR_SET[0],
                          borderSkipped: true,
                          categoryPercentage: 0.2
                        }]
                        : []
                    ),
                    ...(
                      !prevEmpty
                        ? [{
                          label: prevTime,
                          data: series.item.slice(0, 20).map((c) => c.prevCount),
                          productId: series.item.slice(0, 20).map((c) => c.id),
                          backgroundColor: COLOR_SET[19],
                          borderSkipped: true,
                          categoryPercentage: 0.2
                        }]
                        : []
                    )

                  ]
                }]
                : [{ type: 'nodata' }]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType,
                category,
                tagKey
              } = query;
              const { datasetIndex, index } = elements[0];
              const productId = chart.data.datasets[datasetIndex].productId[index];
              const productName = chart.data.labels[index];
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const [gte, lte] = chart.data.datasets[datasetIndex].label.split('~');

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category ? {
                  category: category.map((el) => ({
                    id: el,
                    name: SOURCE_CATEGORY_TYPE[el]
                  }))
                } : {
                  category: SOURCE_CATEGORY.map((el) => ({
                    id: el.value,
                    name: el.label
                  }))
                }),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/產品/聲量分析/各別系列產品/系列產品聲量排行'
                },
                series: [{
                  id: series.id,
                  name: series.name,
                  tagKey,
                  tagKeyName: packageStore.series.find((el) => el.id === tagKey).name
                }],
                product: [{
                  id: productId,
                  name: productName
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'bar',
          options: {
            event: ['click'],
            scales: {
              y: {
                title: {
                  display: true,
                  text: '聲量'
                },
                grace: '5%'
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        };
      }),
      csvForAll
    };
  }


  static getCombineData = (list, prevList) => {
    const combine = list.sort((a, b) => b.total - a.total).map((el) => ({
      ...el,
      item: el.item.map((item) => ({
        ...item,
        prevCount: 0
      }))
    }));
    prevList.forEach((el) => {
      const itemInCombine = combine.find((com) => com.id === el.id);
      if (itemInCombine) {
        el.item.forEach((prevItem) => {
          const target = itemInCombine.item.find((t) => t.name === prevItem.name);
          if (target) {
            target.prevCount = prevItem.count;
          }
        });
      }
    });

    combine.forEach((el) => {
      el.item.sort((a, b) => b.count - a.count);
    });


    return combine;
  };

  static generateCSV = (curr, prev) => {
    const csv = [];

    if (curr) {
      const time = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'));
      const row = {
        date: time.join('~')
      };
      curr.item.forEach((el) => {
        row[`${el.name}`] = el.count;
      });
      csv.push(row);
    }

    if (prev) {
      const time = infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD'));
      const row = {
        date: time.join('~')
      };
      prev.item.forEach((el) => {
        row[`${el.name}`] = el.count;
      });
      csv.push(row);
    }

    return csv;
  };

  static fromRes(data, prevData, query) {
    return new ProductSeriesVolumeRankingModel(data, prevData, query);
  }
}
