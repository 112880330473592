import { request, getHost, getHeaders } from './utils';

export const getIndustryWordRadar = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/vocab/industry/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getIndustryWordCloud = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/vocab/industry/term`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getIndustryWordCloudDiscover = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/vocab/industry/term/discover`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandWordRadar = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/vocab/brand/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandWordCloud = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/vocab/brand/term`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandWordCloudDiscover = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/vocab/brand/term/discover`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getSeriesWordRadar = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/vocab/series/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getSeriesWordCloud = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/vocab/series/term`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getSeriesWordCloudDiscover = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/vocab/series/term/discover`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getProductWordRadar = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/vocab/product/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getProductWordCloud = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/vocab/product/term`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getProductWordCloudDiscover = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/vocab/product/term/discover`,
    headers: getHeaders(),
    data
  };
  return request(options);
};
