import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import clsx from 'clsx';

import { Checkbox } from 'antd';

import styles from './styles.module.scss';

class FlatCheckItem extends React.Component {
  render() {
    const { viewModel, afterSelect = () => { }, needItem } = this.props;
    if (viewModel.children) {
      return (
        <Observer>
          {() => {
            return (
              <div className={clsx(styles.levelItem, styles.parent)}>
                <Checkbox
                  indeterminate={viewModel.groupIndeterminate}
                  checked={viewModel.groupAllSelected}
                  onChange={() => viewModel.onGroupSelect(afterSelect)}
                />
                <div className={styles.levelName}>
                  {viewModel.name}
                </div>
              </div>
            );
          }}
        </Observer>
      );
    }
    const item = needItem ? { id: viewModel.id, name: `${viewModel.parent?.name}/${viewModel.name}` } : {};
    return (
      <Observer>
        {() => {
          return (
            <div className={clsx(styles.levelItem, styles.child)}>
              <Checkbox
                checked={viewModel.checkedCache}
                onClick={(e) => viewModel.toggleChecked(e, afterSelect, item)}
              />
              <div className={styles.levelName}>
                {viewModel.name}
              </div>
            </div>
          );
        }}
      </Observer>
    );
  }
}

FlatCheckItem.propTypes = {
  viewModel: PropTypes.object,
  afterSelect: PropTypes.func,
  needItem: PropTypes.bool
};

FlatCheckItem.defaultProps = {
  viewModel: {},
  afterSelect: () => { },
  needItem: false
};

export default FlatCheckItem;

