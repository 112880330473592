import { COLOR_SET } from 'src/consts/chart';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY } from 'src/consts';
import { generateCSVKey } from 'src/utils/chart';

export default class SeriesProductVolumeCompareModel {
  constructor(data, query) {
    if (data.length === 0) {
      this.data = {
        chartData: [{
          id: 'brand_source_channel_compare',
          name: '系列品牌聲量堆疊',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }]
        }]
      };
      return;
    }

    if (data.every((el) => el.item.every((item) => item.count === 0))) {
      this.data = {
        chartData: [{
          id: 'series_brand_volume_compare',
          name: '系列品牌聲量堆疊',
          data: [{
            type: 'nodata'
          }]
        }]
      };
      return;
    }

    const colors = {};

    const formatData = data.sort((a, b) => b.total - a.total).slice(0, 10).map((series) => ({
      ...series,
      item: series.item.slice(0, 20).map((item) => {
        const color = colors[item.name];
        if (!color) {
          colors[`${item.name}`] = COLOR_SET[Object.keys(colors).length % 20];
        }
        return {
          id: item.id,
          name: item.name,
          count: item.count,
          color: colors[item.name]
        };
      })
    }));

    const product = [];
    data.forEach((el) => {
      el.item.forEach((item) => {
        const target = product.find((b) => b.id === item.id);
        if (!target) {
          product.push({
            id: item.id,
            name: item.name
          });
        }
      });
    });


    const csv = data.map((el) => {
      const row = {
        系列名稱: el.name
      };
      for (let i = 0; i < el.item.length; i += 1) {
        row[`${el.item[i].name}`] = el.item[i].count ?? 0;
      }
      return row;
    });

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'brand_source_channel_compare',
          name: '系列品牌聲量堆疊',
          data: [formatData],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType,
                tagKey,
                date
              } = query;
              const { datasetIndex, index } = elements[0];
              const productItem = chart.data.datasets[datasetIndex].item;
              const series = chart.data.datasets[datasetIndex].mainItem[index];
              const womCount = chart.data.datasets[datasetIndex].data[index];

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                category: SOURCE_CATEGORY.map((el) => ({
                  id: el.value,
                  name: el.label
                })),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '數據報告/市場差異分析/系列產品聲量堆疊'
                },
                series: [{
                  id: series.id,
                  name: series.name,
                  tagKey,
                  tagKeyName: packageStore.series.find((el) => el.id === tagKey).name
                }],
                product: [{
                  id: productItem.id,
                  name: productItem.name
                }],
                womCount,
                date
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          legend: 'topTenInAllGroup',
          type: 'customize-stack-bar',
          options: {
            event: ['click'],
            scales: {
              x: {
                stacked: true
              },
              y: {
                stacked: true,
                grace: 5,
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                display: false
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ],
      series: data.map((el) => ({ id: el.id, name: el.name })),
      product
    };
  }

  static fromRes(data, query) {
    return new SeriesProductVolumeCompareModel(data, query);
  }
}
