import { SENTIMENT_COLOR_SET, AVG_LINE_COLOR } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY } from 'src/consts';
import { generateCSVKey } from 'src/utils/chart';

export default class BrandSentimentOverviewModel {
  constructor(data, prevData, query) {
    const brand = data.map((el) => ({ id: el.id, name: el.name }));

    const {
      postType,
      womType
    } = query;

    const combineData = brand.map((el) => {
      const present = data.find((item) => item.id === el.id);
      const previous = prevData.find((item) => item.id === el.id);

      return {
        id: el.id,
        name: el.name,
        present: {
          positive: present?.['1'] ?? 0,
          neutral: present?.['0'] ?? 0,
          negative: present?.['-1'] ?? 0,
          pn: present?.pn ?? 0
        },
        previous: {
          positive: previous?.['1'] ?? 0,
          neutral: previous?.['0'] ?? 0,
          negative: previous?.['-1'] ?? 0,
          pn: previous?.pn ?? 0
        }
      };
    });

    const csv = combineData.map((el) => ({
      品牌名稱: el.name,
      當期偏正面: el.present.positive,
      當期偏中性: el.present.neutral,
      當期偏負面: el.present.negative,
      '當期P/N': el.present.pn,
      前期偏正面: el.previous.positive,
      前期偏中性: el.previous.neutral,
      前期偏負面: el.previous.negative,
      '前期P/N': el.previous.pn
    }));

    const csvKey = generateCSVKey(csv[0]);

    const [nowGte, nowLte] = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'));
    const [prevGte, prevLte] = infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD'));

    this.data = {
      chartData: [
        {
          id: 'brand_sentiment_overview',
          name: '品牌情緒總覽',
          data: [
            ...(
              !data.every((el) => el['0'] + el['1'] + el['-1'] === 0)
                ? [{
                  labels: data.slice(0, 10).map((el) => el.name),
                  datasets: [
                    {
                      label: 'p/n',
                      data: data.slice(0, 10).map((el) => el.pn),
                      backgroundColor: AVG_LINE_COLOR,
                      borderColor: AVG_LINE_COLOR,
                      type: 'line',
                      yAxisID: 'y1'
                    },
                    {
                      label: '正面',
                      data: data.slice(0, 10).map((el) => el['1']),
                      brand: data.slice(0, 10).map((el) => ({ id: el.id, name: el.name })),
                      sentiment: '1',
                      gte: nowGte,
                      lte: nowLte,
                      backgroundColor: SENTIMENT_COLOR_SET.positive,
                      borderSkipped: true,
                      maxBarThickness: 50,
                      yAxisID: 'y'
                    },
                    {
                      label: '中性',
                      data: data.slice(0, 10).map((el) => el['0']),
                      brand: data.slice(0, 10).map((el) => ({ id: el.id, name: el.name })),
                      sentiment: '0',
                      gte: nowGte,
                      lte: nowLte,
                      backgroundColor: SENTIMENT_COLOR_SET.neutral,
                      borderSkipped: true,
                      maxBarThickness: 50,
                      yAxisID: 'y'
                    },
                    {
                      label: '負面',
                      data: data.slice(0, 10).map((el) => el['-1']),
                      brand: data.slice(0, 10).map((el) => ({ id: el.id, name: el.name })),
                      sentiment: '-1',
                      gte: nowGte,
                      lte: nowLte,
                      backgroundColor: SENTIMENT_COLOR_SET.negative,
                      borderSkipped: true,
                      maxBarThickness: 50,
                      yAxisID: 'y'
                    }
                  ]
                }]
                : [{
                  type: 'nodata',
                  subType: data.length === 0 ? 'ana-error' : 'default'
                }]
            ),
            ...(
              !prevData.every((el) => el['0'] + el['1'] + el['-1'] === 0)
                ? [{
                  labels: prevData.slice(0, 10).map((el) => el.name),
                  datasets: [
                    {
                      label: 'p/n',
                      data: prevData.slice(0, 10).map((el) => el.pn),
                      backgroundColor: AVG_LINE_COLOR,
                      borderColor: AVG_LINE_COLOR,
                      type: 'line',
                      yAxisID: 'y1'
                    },
                    {
                      label: '正面',
                      data: prevData.slice(0, 10).map((el) => el['1']),
                      brand: prevData.slice(0, 10).map((el) => ({ id: el.id, name: el.name })),
                      sentiment: '1',
                      gte: prevGte,
                      lte: prevLte,
                      backgroundColor: SENTIMENT_COLOR_SET.positive,
                      borderSkipped: true,
                      maxBarThickness: 50,
                      yAxisID: 'y'
                    },
                    {
                      label: '中性',
                      data: prevData.slice(0, 10).map((el) => el['0']),
                      brand: prevData.slice(0, 10).map((el) => ({ id: el.id, name: el.name })),
                      sentiment: '0',
                      gte: prevGte,
                      lte: prevLte,
                      backgroundColor: SENTIMENT_COLOR_SET.neutral,
                      borderSkipped: true,
                      maxBarThickness: 50,
                      yAxisID: 'y'
                    },
                    {
                      label: '負面',
                      data: prevData.slice(0, 10).map((el) => el['-1']),
                      brand: prevData.slice(0, 10).map((el) => ({ id: el.id, name: el.name })),
                      sentiment: '-1',
                      gte: prevGte,
                      lte: prevLte,
                      backgroundColor: SENTIMENT_COLOR_SET.negative,
                      borderSkipped: true,
                      maxBarThickness: 50,
                      yAxisID: 'y'
                    }
                  ]
                }]
                : [{
                  type: 'nodata',
                  subType: prevData.length === 0 ? 'ana-error' : 'default'
                }]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const { sentiment, gte, lte } = chart.data.datasets[datasetIndex];
              if (!sentiment) {
                return;
              }
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const brandItem = chart.data.datasets[datasetIndex].brand[index];

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                category: SOURCE_CATEGORY.map((el) => ({
                  id: el.value,
                  name: el.label
                })),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                sentiment: [Number(sentiment)],
                chart: {
                  name: '數據報告/品牌成效分析/品牌情緒總覽'
                },
                brand: [{
                  id: brandItem.id,
                  name: brandItem.name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'bar',
          options: {
            event: ['click'],
            tension: 0.3,
            scales: {
              x: {
                stacked: true
              },
              y: {
                stacked: true,
                type: 'linear',
                display: true,
                position: 'left',
                grace: 5,
                title: {
                  display: true,
                  text: '聲量'
                }
              },
              y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grace: 5,
                title: {
                  display: true,
                  text: 'PN值'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ],
      brand
    };
  }

  static fromRes(data, prevData, query) {
    return new BrandSentimentOverviewModel(data, prevData, query);
  }
}
