import { COLOR_SET } from 'src/consts/chart';
import { SOURCE_ORDER_TC } from 'src/consts';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import dayjs from 'dayjs';

export const generateWorksheetName = (name) => {
  const removeIllegal = name.replace(/[/?*[\]]/g, '_');
  return removeIllegal.slice(0, 31);
};

export const getTotalDays = (time = 'present') => {
  const [gte, lte] = time === 'present'
    ? infoHeaderViewModel.multiViewModel.currentDateCache
    : infoHeaderViewModel.multiViewModel.previousDateCache;
  return lte.endOf().diff(gte.startOf(), 'day') + 1;
};

export const generateCompletedDayArray = (time = 'present') => {
  const [gte, _] = time === 'present'
    ? infoHeaderViewModel.multiViewModel.currentDateCache
    : infoHeaderViewModel.multiViewModel.previousDateCache;
  const totalDays = getTotalDays(time);
  if (totalDays <= 31) {
    return generateSingleDayArray(gte, totalDays);
  }
  if (totalDays >= 32 && totalDays <= 90) {
    return generateGroupingDayArray(gte, totalDays);
  }

  return generateMonthDayArray(gte, totalDays);
};

export const generateCompletedDayArrayByDate = (startAt, endAt) => {
  const totalDays = dayjs(endAt).diff(dayjs(startAt), 'day') + 1;
  if (totalDays <= 31) {
    return generateSingleDayArray(dayjs(startAt), totalDays);
  }
  if (totalDays >= 32 && totalDays <= 90) {
    return generateGroupingDayArray(dayjs(startAt), totalDays);
  }

  return generateMonthDayArray(dayjs(startAt), totalDays);
};

export const generateSingleDayArray = (gte, totalDays) => {
  const arr = [gte.format('YYYY-MM-DD')];
  for (let i = 1; i < totalDays; i += 1) {
    arr.push(gte.add(i, 'day').format('YYYY-MM-DD'));
  }
  return arr;
};

export const generateGroupingDayArray = (gte, totalDays) => {
  const arrBeforeGroup = [gte.format('YYYY-MM-DD')];
  for (let i = 1; i < totalDays; i += 1) {
    arrBeforeGroup.push(gte.add(i, 'day').format('YYYY-MM-DD'));
  }
  const slice = 5;
  const arrAfterGroup = arrBeforeGroup.reduce((acc, curr, index) => {
    const sliceIndex = Math.floor(index / slice);
    if (!acc[sliceIndex]) {
      acc[sliceIndex] = [];
    }
    acc[sliceIndex].push(curr);
    return acc;
  }, []);

  const finalGroup = arrAfterGroup.map((el) => ({
    id: `${el[0]}~${el[el.length - 1]}`,
    days: el,
    count: 0
  }));

  return finalGroup;
};

export const generateMonthDayArray = (gte, totalDays) => {
  const arrBeforeGroup = [gte.format('YYYY-MM-DD')];
  for (let i = 1; i < totalDays; i += 1) {
    arrBeforeGroup.push(gte.add(i, 'day').format('YYYY-MM-DD'));
  }
  const monthWithIndex = [gte.format('YYYY-MM')];
  for (let i = 1; i < totalDays; i += 1) {
    monthWithIndex.push(gte.add(i, 'day').format('YYYY-MM'));
  }
  const arrAfterGroup = monthWithIndex.reduce((acc, curr, index) => {
    const target = acc.find((el) => el.id === curr);

    if (target) {
      target.days.push(arrBeforeGroup[index]);
    } else {
      acc.push({
        id: curr,
        days: [arrBeforeGroup[index]],
        count: 0
      });
    }
    return acc;
  }, []);

  return arrAfterGroup;
};

export const generateGroupColor = (list = [], list2 = []) => {
  const color = {};

  list?.forEach((el) => {
    if (!color[el.id]) {
      color[`${el.id}`] = COLOR_SET[Object.keys(color).length % 20];
    }
  });

  list2?.forEach((el) => {
    if (!color[el.id]) {
      color[`${el.id}`] = COLOR_SET[Object.keys(color).length % 20];
    }
  });

  return color;
};

export const convertCSV = (csv) => {
  let headerRow = [];
  const header = Object.keys(csv[0]);
  if (header.includes('日期')) {
    const newHeader = header.filter((el) => el !== '日期');
    newHeader.unshift('日期');
    headerRow = newHeader;
  } else {
    headerRow = header;
  }

  const newCsv = csv.map((el) => {
    const arr = [];
    for (let i = 0; i < headerRow.length; i += 1) {
      arr.push(el[`${headerRow[i]}`]);
    }
    return arr;
  });

  newCsv.unshift(headerRow);
  return newCsv;
};

export const getTwo = (value) => {
  if (!value) {
    return 0;
  }
  return Math.round(value * 100) / 100;
};

export const generateCSVKey = (row) => {
  const needAtBegin = ['品牌名稱', '系列名稱', '產品名稱'];
  if (!row) {
    return [];
  }
  let hasDate = false;
  let hasCTDate = false;
  const keys = [];
  if (row.date) {
    hasDate = true;
  }
  if (row['日期']) {
    hasCTDate = true;
  }

  Object.keys(row).forEach((el) => {
    if (el === 'date' || el === '日期') {
      return;
    }
    keys.push({ key: el, header: el });
  });

  keys.sort((a, b) => {
    if (needAtBegin.includes(a.key)) {
      return -1;
    }
    if (needAtBegin.includes(b.key)) {
      return 1;
    }
    return 0;
  });

  if (hasDate) {
    keys.unshift({ key: 'date', header: '日期' });
  }

  if (hasCTDate) {
    keys.unshift({ key: '日期', header: '日期' });
  }

  return keys;
};

export const getTrendDate = (startAt, endAt) => {
  const diffDay = endAt.diff(startAt, 'day') + 1;
  const resultArr = [];
  let resultType;

  if (diffDay <= 32) {
    resultType = 'day';
    for (let i = 0; i < diffDay; i += 1) {
      resultArr.push(startAt.add(i, 'day').format('YYYY/MM/DD'));
    }
    return {
      resultType,
      resultArr
    };
  }

  if (diffDay > 32 && diffDay <= 91) {
    resultType = 'five';
    const intervals = Math.ceil(diffDay / 5);
    for (let i = 0; i < intervals; i += 1) {
      resultArr.push(`${startAt.add(i * 5, 'day').format('YYYY/MM/DD')}`);
    }
    return {
      resultType,
      resultArr
    };
  }

  resultType = 'month';
  const startMonth = startAt.startOf('month');
  const endMonth = endAt.startOf('month');
  const diffMonth = endMonth.diff(startMonth, 'month') + 1;
  for (let i = 0; i < diffMonth; i += 1) {
    resultArr.push(startAt.add(i, 'month').startOf('month').format('YYYY/MM'));
  }
  return {
    resultType,
    resultArr
  };
};

// > due to can not get id in options(don't know why), use static id
const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  if (!legendContainer) {
    return null;
  }
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'column';
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;
    listContainer.style.rowGap = '10px';

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

export const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);
    if (!ul) {
      return;
    }

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart).sort((a, b) => {
      const indexA = SOURCE_ORDER_TC.indexOf(a.text);
      const indexB = SOURCE_ORDER_TC.indexOf(b.text);
      return indexA - indexB;
    });
    items.forEach((item) => {
      const li = document.createElement('li');
      li.style.alignItems = 'center';
      li.style.cursor = 'pointer';
      li.style.display = 'flex';
      li.style.columnGap = '5px';
      li.style.flexDirection = 'row';
      li.onclick = () => {
        const { type } = chart.config;
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
        }
        chart.update();
      };
      // Color box
      const boxSpan = document.createElement('span');
      boxSpan.style.background = item.strokeStyle;
      boxSpan.style.display = 'inline-block';
      boxSpan.style.flexShrink = 0;
      boxSpan.style.height = '10px';
      boxSpan.style.width = '40px';
      // Text
      const textContainer = document.createElement('p');
      textContainer.style.color = item.fontColor;
      textContainer.style.margin = 0;
      textContainer.style.padding = 0;
      textContainer.style.fontSize = '12px';
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  }
};
