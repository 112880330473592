import React from 'react';
import { Tooltip as AntdTooltip, Button, Modal, Switch, Collapse, Table } from 'antd';
import { DownloadOutlined, SaveOutlined, CloseOutlined, StopOutlined, ReloadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import PaperIcon from 'src/assets/PaperIcon.svg';

import optionsStore from 'src/stores/optionsStore';
import i18n from 'src/i18n';

import styles from './styles.module.scss';

@observer
class Word extends React.Component {
  componentDidMount() {
    this.props.viewModel.didMount();
  }

  componentWillUnmount() {
    this.props.viewModel.willUnMount();
  }

  _renderPanelIcon = () => {
    return (
      <Switch
        defaultChecked={false}
      />
    );
  };

  render() {
    const { viewModel } = this.props;
    return (
      <>
        <div className={styles.chartContainer}>
          <div className={clsx('opinion-keyword', styles.download)}>
            <div className={styles.chartHeader}>
              <div className={styles.headerTitle}>
                {`熱詞文字雲-${viewModel.searchData.keyword}`}
              </div>
              <div className={styles.downloadActions}>
                <AntdTooltip
                  title="查看屏蔽名單"
                  placement="bottom"
                >
                  <Button
                    icon={<StopOutlined />}
                    onClick={viewModel.onBlockModalOpen}
                    style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
                  />
                </AntdTooltip>
                <AntdTooltip
                  title="下載數據"
                  placement="bottom"
                >
                  <Button
                    icon={<DownloadOutlined />}
                    onClick={viewModel.onDownloadExcel}
                    style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
                  />
                </AntdTooltip>
                <AntdTooltip
                  title="下載圖檔"
                  placement="bottom"
                >
                  <Button
                    icon={<SaveOutlined />}
                    onClick={viewModel.onDownload}
                    style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
                  />
                </AntdTooltip>
              </div>
            </div>
            <div className={styles.timeStamp}>
              {`日期範圍：${viewModel.searchData.date.gte} ~ ${viewModel.searchData.date.lte}`}
            </div>
            <div id="wordsContainerOpinion" className={styles.wordsContainer} />
            <Collapse
              collapsible="icon"
              expandIcon={this._renderPanelIcon}
              ghost
              className={styles.collapseContainer}
              style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
            >
              <Collapse.Panel
                key="table"
                className="wordsCollapse"
                header={i18n.t('wordbook_page_statistic_table_title')}
              >
                <div className={styles.tableContainer}>
                  <Table
                    key={viewModel.activeWords.length}
                    rowClassName={styles.row}
                    rowKey={(item) => item[0]}
                    dataSource={viewModel.activeWords}
                    pagination={false}
                    className="packagesTable"
                  >
                    <Table.Column
                      title="排行"
                      dataIndex="ranking"
                      key="ranking"
                      className={styles.columns}
                      width="33%"
                      render={(_, record, i) => {
                        return (
                          <div>
                            {i + 1}
                          </div>
                        );
                      }}
                    />
                    <Table.Column
                      title="熱詞詞彙"
                      dataIndex="name"
                      key="name"
                      className={styles.columns}
                      width="33%"
                      render={(_, record, i) => {
                        return (
                          <div>
                            {record[0]}
                          </div>
                        );
                      }}
                    />
                    <Table.Column
                      title="提及次數"
                      dataIndex="count"
                      key="count"
                      className={styles.columns}
                      width="33%"
                      render={(_, record, i) => {
                        return (
                          <div>
                            {record[3]}
                          </div>
                        );
                      }}
                    />
                  </Table>
                </div>
              </Collapse.Panel>
            </Collapse>
          </div>
        </div>
        <Modal
          open={viewModel.isModalOpen}
          onCancel={viewModel.onModalClose}
          width={500}
          centered
          closable={false}
          footer={null}
          className={styles.modal}
          bodyStyle={{
            height: 160,
            display: 'flex',
            flexDirection: 'column',
            rowGap: 36,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div className={styles.modalHeader}>
            <div className={styles.title}>
              {i18n.t('wordbook_page_modal_content').replace('%word%', viewModel.selectedItem?.name)}
            </div>
            <CloseOutlined className={styles.close} onClick={viewModel.onModalClose} />
          </div>
          <div className={clsx(styles.btnContainer)}>
            <Button
              className={clsx(styles.btn, styles.normalBtn)}
              onClick={viewModel.onTopicsClick}
            >
              {i18n.t('wordbook_page_modal_action_topic')}
            </Button>
            <Button
              className={clsx(styles.btn, styles.blockBtn)}
              onClick={viewModel.onBlockClick}
            >
              {i18n.t('wordbook_page_modal_action_block')}
            </Button>
          </div>
        </Modal>
        <Modal
          open={viewModel.isBlockModalOpen}
          centered
          closable={false}
          footer={null}
          className={styles.blockModal}
          onCancel={viewModel.onBlockModalClose}
          width={928}
        >
          <div className={styles.modalHeader}>
            <div className={styles.modalTitle}>
              <img src={PaperIcon} alt="paper" className={styles.titleIcon} />
              <div className={styles.modalText}>
                {i18n.t('wordbook_page_block_modal_title')}
              </div>
            </div>
            <Button
              icon={<CloseOutlined />}
              type="link"
              onClick={viewModel.onBlockModalClose}
            >
              關閉
            </Button>
          </div>
          <div className={styles.tableContainer}>
            <Table
              key={viewModel.blockSet.size}
              rowClassName={styles.row}
              rowKey={(item) => item}
              dataSource={Array.from(viewModel.blockSet)}
              pagination={false}
              className="packagesTable"
            >
              <Table.Column
                title={i18n.t('wordbook_page_table_name')}
                dataIndex="name"
                key="name"
                className={styles.columns}
                width="50%"
                render={(_, record) => {
                  return (
                    <div>
                      {record}
                    </div>
                  );
                }}
              />
              <Table.Column
                title={i18n.t('wordbook_page_table_action_title')}
                dataIndex="action"
                key="action"
                className={styles.columns}
                width="50%"
                render={(_, record) => {
                  return (
                    <Button
                      icon={<ReloadOutlined />}
                      type="text"
                      onClick={() => viewModel.onRecoverWord(record)}
                    >
                      {i18n.t('wordbook_page_table_action_recover')}
                    </Button>
                  );
                }}
              />
            </Table>
          </div>
        </Modal>
      </>
    );
  }
}

Word.propTypes = {
  viewModel: PropTypes.object
};

Word.defaultProps = {
  viewModel: {}
};

export default Word;
