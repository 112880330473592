import { COLOR_SET } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY } from 'src/consts';
import { generateCSVKey } from 'src/utils/chart';

export default class BrandProductVolumeCompareModel {
  constructor(data, query) {
    if (data.length === 0) {
      this.data = {
        chartData: [{
          id: 'brand_source_channel_compare',
          name: '系列品牌聲量堆疊',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }]
        }]
      };
      return;
    }
    const colors = {};

    if (data.every((el) => el.item.every((item) => item.count === 0))) {
      this.data = {
        chartData: [{
          id: 'series_brand_volume_compare',
          name: '系列品牌聲量堆疊',
          data: [{
            type: 'nodata'
          }]
        }]
      };
      return;
    }

    const addTotal = data.map((el) => {
      let total = 0;
      el.item.forEach((item) => {
        total += item.count;
      });
      return {
        ...el,
        total
      };
    });

    const formatData = addTotal.sort((a, b) => b.total - a.total).slice(0, 10).map((series) => ({
      ...series,
      item: series.item.slice(0, 20).map((item) => {
        const color = colors[item.name];
        if (!color) {
          colors[`${item.name}`] = COLOR_SET[Object.keys(colors).length % 20];
        }
        return {
          id: item.id,
          name: item.name,
          count: item.count,
          color: colors[item.name]
        };
      })
    }));

    const product = [];
    data.forEach((el) => {
      el.item.forEach((item) => {
        const target = product.find((b) => b.id === item.id);
        if (!target) {
          product.push({
            id: item.id,
            name: item.name
          });
        }
      });
    });

    const {
      postType,
      womType
    } = query;

    const csv = data.map((el) => {
      const row = {
        品牌名稱: el.name
      };

      el.item.forEach((item) => {
        row[`${item.name}`] = item.count;
      });

      return row;
    });

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'brand_product_volume_compare',
          name: '品牌產品聲量堆疊',
          data: [formatData],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const brandItem = chart.data.datasets[datasetIndex].mainItem[index];
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const productItem = chart.data.datasets[datasetIndex].item;
              const [gte, lte] = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'));

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                category: SOURCE_CATEGORY.map((el) => ({
                  id: el.value,
                  name: el.label
                })),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '數據報告/品牌成效分析/品牌產品聲量堆疊'
                },
                brand: [{
                  id: brandItem.id,
                  name: brandItem.name
                }],
                product: [{
                  id: productItem.id,
                  name: productItem.name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          legend: 'topTenInAllGroup',
          type: 'customize-stack-bar',
          options: {
            event: ['click'],
            scales: {
              x: {
                stacked: true
              },
              y: {
                stacked: true,
                grace: 5,
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                display: false
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ],
      brand: data.map((el) => ({ id: el.id, name: el.name })),
      product
    };
  }

  static fromRes(data, query) {
    return new BrandProductVolumeCompareModel(data, query);
  }
}
