import ProductSourceWebsiteTrendModel from './Trend';
import ProductSourceWebsiteRankingModel from './Ranking';
import ProductSourceWebsiteProportionModel from './Proportion';
import ProductSourceWebsiteCompareModel from './Compare';

export default {
  ProductSourceWebsiteTrendModel,
  ProductSourceWebsiteRankingModel,
  ProductSourceWebsiteProportionModel,
  ProductSourceWebsiteCompareModel
};
