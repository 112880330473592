import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { message } from 'antd';
import AnnouncementService from 'src/services/announcement';

export default class AnnouncementItemViewModel {
  id = '';
  @observable isShow = false;
  @observable isShowCache = false;
  @observable date = '';
  @observable dateCache = '';
  @observable text = '';
  @observable textCache = '';
  @observable isEdit = false;

  @computed get canSave() {
    return this.isShow !== this.isShowCache || this.date !== this.dateCache || this.text !== this.textCache;
  }

  constructor(data) {
    makeObservable(this);

    this.init(data);
  }

  @action init = (data) => {
    const {
      id,
      isShow,
      date,
      text
    } = data;

    this.id = id ?? 'newPost';
    this.isShow = isShow;
    this.isShowCache = isShow;
    this.date = date;
    this.dateCache = date;
    this.text = text;
    this.textCache = text;
  };

  @action onDateChange = (date) => {
    this.date = date.toISOString();
  };

  @action onTextChange = (e) => {
    this.text = e.target.value;
  };

  @action onIsShowChange = (e) => {
    this.isShow = e.target.checked;
  };

  @action onEdit = () => {
    this.isEdit = true;
  };

  @action onCancel = (disposeNew = () => { }) => {
    if (this.id !== 'newPost') {
      this.isShow = this.isShowCache;
      this.date = this.dateCache;
      this.text = this.textCache;

      this.isEdit = false;
    } else {
      disposeNew();
    }

  };

  @action onSave = async (refresh) => {
    try {
      const data = {
        text: this.text,
        date: this.date,
        isShow: this.isShow
      };

      if (!this.canSave) {
        return;
      }

      if (this.id !== 'newPost') {
        await AnnouncementService.putAnnouncement(this.id, data);
        runInAction(() => {
          this.isShowCache = this.isShow;
          this.dateCache = this.date;
          this.textCache = this.text;

          this.isEdit = false;
        });

      } else {
        await AnnouncementService.createAnnouncement(data);
        await refresh();
      }

    } catch (error) {
      if (error.response?.status === 400) {
        message.error('資料有誤或上架檢視達到上限，請重新確認！');
      }

    }
  };
}
