import { COLOR_SET, AVG_LINE_COLOR } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import { SOURCE_CATEGORY_TYPE } from 'src/consts';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';

export default class ProductSourceSocialActivityModel {
  constructor(origin, prevOrigin, query) {
    const { currentDate, previousDate } = infoHeaderViewModel.multiViewModel;
    const presentTime = currentDate.map((el) => el.format('YYYY-MM-DD'));
    const previousTime = previousDate.map((el) => el.format('YYYY-MM-DD'));

    // > filter data
    const data = origin.slice(0, 20);
    const prevData = prevOrigin.slice(0, 20);

    const csv = [];

    const currCsv = origin.map((el) => ({
      date: presentTime.join('~'),
      name: el.name,
      sns: el.snsCount,
      news: el.newsCount,
      sn: el.sn
    }));
    csv.push(...currCsv);

    const prevCsv = prevOrigin.map((el) => ({
      date: previousTime.join('~'),
      name: el.name,
      sns: el.snsCount,
      news: el.newsCount,
      sn: el.sn
    }));
    csv.push(...prevCsv);

    const csvKey = [
      { key: 'date', header: '日期' },
      { key: 'name', header: '名稱' },
      { key: 'sns', header: '社群' },
      { key: 'news', header: '新聞' },
      { key: 'sn', header: 'SN值' }
    ];

    this.data = {
      chartData: [
        {
          id: 'product_source_social',
          name: '產品社群活躍度',
          data: [
            ...(
              data.length > 0 && !data.every((el) => el.sn === 0)
                ? [{
                  labels: data.map((el) => el.name),
                  datasets: [
                    {
                      label: 'SN值',
                      data: data.map((el) => el.sn),
                      backgroundColor: AVG_LINE_COLOR,
                      borderColor: AVG_LINE_COLOR,
                      maxBarThickness: 50,
                      yAxisID: 'y1',
                      type: 'line'
                    },
                    {
                      label: '新聞',
                      data: data.map((el) => el.newsCount),
                      product: data.map((el) => ({ id: el.id, name: el.name })),
                      time: 'present',
                      backgroundColor: COLOR_SET[0],
                      maxBarThickness: 50,
                      yAxisID: 'y'
                    },
                    {
                      label: '社群',
                      data: data.map((el) => el.snsCount),
                      product: data.map((el) => ({ id: el.id, name: el.name })),
                      time: 'present',
                      backgroundColor: COLOR_SET[1],
                      maxBarThickness: 50,
                      yAxisID: 'y'
                    }
                  ]
                }]
                : [{
                  type: 'nodata'
                }]
            ),
            ...(
              prevData.length > 0 && !prevData.every((el) => el.sn === 0)
                ? [{
                  labels: prevData.map((el) => el.name),
                  datasets: [
                    {
                      label: 'SN值',
                      data: prevData.map((el) => el.sn),
                      backgroundColor: AVG_LINE_COLOR,
                      borderColor: AVG_LINE_COLOR,
                      maxBarThickness: 50,
                      yAxisID: 'y1',
                      type: 'line'
                    },
                    {
                      label: '新聞',
                      data: prevData.map((el) => el.newsCount),
                      product: prevData.map((el) => ({ id: el.id, name: el.name })),
                      time: 'previous',
                      backgroundColor: COLOR_SET[0],
                      maxBarThickness: 50,
                      yAxisID: 'y'
                    },
                    {
                      label: '社群',
                      data: prevData.map((el) => el.snsCount),
                      product: prevData.map((el) => ({ id: el.id, name: el.name })),
                      time: 'previous',
                      backgroundColor: COLOR_SET[1],
                      maxBarThickness: 50,
                      yAxisID: 'y'
                    }
                  ]
                }]
                : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{ type: 'nodata' }]
                      : []
                  )
                ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType
              } = query;
              const { datasetIndex, index } = elements[0];
              if (!datasetIndex) {
                return;
              }
              const product = chart.data.datasets[datasetIndex].product[index];
              const type = chart.data.datasets[datasetIndex].label;
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const [gte, lte] = chart.data.datasets[datasetIndex].time === 'present'
                ? presentTime
                : previousTime;

              let category = '';
              if (type === '社群') {
                category = ['forum', 'facebook', 'ig'];
              } else if (type === '新聞') {
                category = ['news'];
              } else {
                return;
              }

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category && {
                  category: category.map((c) => ({
                    id: c,
                    name: SOURCE_CATEGORY_TYPE[c]
                  }))
                }),
                SNType: type,
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/產品/來源分析/產品社群活躍度'
                },
                product: [{
                  id: product.id,
                  name: product.name
                }],
                womCount,
                date: {
                  gte,
                  lte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'bar',
          options: {
            event: ['click'],
            scales: {
              y: {
                type: 'linear',
                display: true,
                position: 'left',
                grace: 5
              },
              y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grace: 5
              }
            },
            plugins: {
              legend: {
                position: 'bottom',
                labels: {
                  filter: (item) => item.text !== 's/n'
                }
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ]
    };
  }

  static fromRes(data, prevData, query) {
    return new ProductSourceSocialActivityModel(data, prevData, query);
  }
}
