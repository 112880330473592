export default class PackageThreeLevelModel {
  constructor(data) {
    const {
      brand,
      series,
      product
    } = data;

    this.brand = brand.data.result.keywords.map((el) => ({
      id: el._id,
      name: el.name
    }));

    // TODO: 優化
    this.series = series.data.result.keywords.filter((el) => !el.parentId).map((el) => ({ id: el._id, name: el.name, children: [] }));

    series.data.result.keywords.filter((el) => el.parentId).forEach((el) => {
      const target = this.series.find((p) => p.id === el.parentId);
      target?.children.push({ id: el._id, name: el.name });
    });

    // TODO: 優化
    this.product = product.data.result.keywords;

    // this.product = this.brand.map((el) => ({
    //   id: el.id,
    //   name: el.name,
    //   children: []
    // }));

    // product.data.result.forEach((el) => {
    //   const target = this.product.find((b) => b.id === el.sid);
    //   target?.children.push({ id: el._id, name: el.name });
    // });
  }

  static fromRes(data) {
    return new PackageThreeLevelModel(data);
  }
}
