import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import i18n from 'src/i18n';

import ChartFactory from 'src/components/Charts/factory';
import ChartTimeStamp from 'src/components/ChartTimeStamp';
import NoDataChart from 'src/components/Charts/NoData';
import optionsStore from 'src/stores/optionsStore';

import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { CloseOutlined, RollbackOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { Tabs, Modal, Button, Tooltip } from 'antd';

import styles from './styles.module.scss';

@observer
class WordChartItem extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <div className={clsx(styles.chartContainer, 'download-chart')}>
        {
          viewModel.onReady
            ? (
              <div className={styles.chartContent}>
                {
                  viewModel.showDiscover
                    ? (
                      <div>
                        <Button
                          className={styles.discoverBack}
                          onClick={viewModel.onDiscoverLeave}
                          icon={<RollbackOutlined />}
                        >
                          {`返回${viewModel.showParent?.name}${viewModel.activeFeatureName}文字雲`}
                        </Button>
                        <div className={styles.chartTitle}>
                          {`「${viewModel.discoverName}」文字雲下探`}
                        </div>
                        <ChartTimeStamp isPresent={viewModel.wordsViewModel?.timeSec === 'present'} isPrevious={viewModel.wordsViewModel?.timeSec === 'previous'} />
                        <div key={viewModel.discoverWords.key}>
                          {
                            ChartFactory.generateChartView(viewModel.discoverWords)
                          }
                        </div>
                      </div>
                    )
                    : (
                      <>
                        {
                          viewModel.data.length > 1
                          && (
                            <Tabs
                              activeKey={viewModel.activeTabId}
                              items={viewModel.data.map((el) => ({ key: el.id, label: el.name }))}
                              onChange={viewModel.onTabsChange}
                              className="chartTabs"
                              style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
                            />
                          )
                        }
                        {
                          viewModel.showParent.charts?.length > 1
                          && (
                            <Tabs
                              activeKey={viewModel.activeFeatureId}
                              items={viewModel.showParent.charts?.map((el) => ({ key: el.id, label: el.name }))}
                              onChange={viewModel.onFeatureChange}
                              className="chartTabs"
                              style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
                            />
                          )
                        }
                        <div className={styles.chartTitle}>
                          <span className={styles.titleText}>
                            {viewModel.chartTitle}
                          </span>
                          <span className={styles.tooltip}>
                            <Tooltip
                              title={viewModel.chartDesc}
                              placement="right"
                            >
                              <QuestionCircleOutlined
                                style={{ display: optionsStore.isImgDownload ? 'none' : 'inline-block' }}
                              />
                            </Tooltip>
                          </span>
                        </div>
                        <ChartTimeStamp isPresent />
                        {
                          viewModel.showChart.map((el) => {
                            return (
                              <div key={el.key}>
                                {
                                  ChartFactory.generateChartView(el)
                                }
                              </div>
                            );
                          })
                        }
                        {
                          infoHeaderViewModel.multiViewModel.hasCompare
                          && (
                            <>
                              <ChartTimeStamp isPrevious />
                              {
                                viewModel.showPrevChart.map((el) => {
                                  return (
                                    <div key={el.key}>
                                      {
                                        ChartFactory.generateChartView(el)
                                      }
                                    </div>
                                  );
                                })
                              }
                            </>
                          )
                        }
                      </>
                    )
                }
              </div>
            )
            : (
              <NoDataChart subChart={ChartFactory.generateNoDataChart(null)} />
            )
        }
        <Modal
          open={viewModel.isModalOpen}
          onCancel={viewModel.onModalClose}
          width={500}
          centered
          closable={false}
          footer={null}
          className={styles.modal}
          bodyStyle={{
            height: 160,
            display: 'flex',
            flexDirection: 'column',
            rowGap: 36,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div className={styles.modalHeader}>
            <div className={styles.title}>
              {i18n.t('wordbook_page_modal_content').replace('%word%', viewModel.wordsViewModel?.selectedName)}
            </div>
            <CloseOutlined className={styles.close} onClick={viewModel.onModalClose} />
          </div>
          <div className={clsx(styles.btnContainer, viewModel.showDiscover && styles.speTwo)}>
            <Button
              className={clsx(styles.btn, styles.normalBtn)}
              onClick={viewModel.onCheckWordTopics}
            >
              {i18n.t('wordbook_page_modal_action_topic')}
            </Button>
            {
              !viewModel.showDiscover
              && (
                <Button
                  className={clsx(styles.btn, styles.normalBtn)}
                  onClick={viewModel.onDiscover}
                >
                  {i18n.t('wordbook_page_modal_action_further')}
                </Button>
              )
            }
            <Button
              className={clsx(styles.btn, styles.blockBtn)}
              onClick={viewModel.onWordBlock}
            >
              {i18n.t('wordbook_page_modal_action_block')}
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

WordChartItem.propTypes = {
  viewModel: PropTypes.object
};

WordChartItem.defaultProps = {
  viewModel: {}
};


export default WordChartItem;
