import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import { CloseOutlined, CheckOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Tabs, Radio, Tooltip } from 'antd';

import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import ChartTimeStamp from 'src/components/ChartTimeStamp';
import ChartFactory from 'src/components/Charts/factory';
import optionsStore from 'src/stores/optionsStore';

import LoadingRelated from 'src/assets/loadingRelated.svg';

import WordCloud from './WordCloud';

import styles from './styles.module.scss';


@observer
class WordsChart extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <>
        <div className={styles.container}>
          {
            viewModel.levelOptions.length > 0
            && (
              <div className={styles.actions}>
                <div className={styles.radioContainer}>
                  <Radio.Group
                    options={viewModel.levelOptions}
                    optionType="button"
                    onChange={viewModel.onLevelOptionsSelected}
                    value={viewModel.selectedLevelOption}
                    className={styles.radioGroup}
                  />
                </div>
                <Tabs
                  activeKey={viewModel.selectedType}
                  items={viewModel.typeItems}
                  onChange={viewModel.onTypeChange}
                  className="relatedTabs"
                />
              </div>
            )
          }
          <div className={clsx(styles.downloadContainer, 'download-chart')}>
            <div className={styles.chartTitle}>
              <span>
                {viewModel.chartTitle}
              </span>
              <span className={clsx(styles.tooltip, optionsStore.isImgDownload && styles.displayNone)}>
                <Tooltip
                  title={(
                    <div className={styles.descContainer}>
                      {viewModel.chartDesc}
                    </div>
                  )}
                  placement="rightTop"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            </div>
            {
              viewModel.isLoading
                ? (
                  <div className={styles.animationContainer}>
                    <img src={LoadingRelated} alt="" />
                  </div>
                )
                : (
                  <div>
                    <ChartTimeStamp isPresent />
                    {
                      viewModel.cloudViewModel?.words.length > 0 && !viewModel.cloudViewModel?.words.every((el) => el.originCount === 0)
                        ? (
                          <div style={{ width: '100%' }}>
                            <WordCloud viewModel={viewModel.cloudViewModel} />
                          </div>
                        )
                        : (
                          ChartFactory.generateChartView({ type: 'nodata', subtype: viewModel.cloudViewModel?.words.length === 0 ? 'ana-error' : 'default' })
                        )
                    }
                    {
                      infoHeaderViewModel.multiViewModel.hasCompare
                      && (
                        <ChartTimeStamp isPrevious />
                      )
                    }
                    {
                      viewModel.prevCloudViewModel?.words.length > 0 && !viewModel.prevCloudViewModel?.words.every((el) => el.originCount === 0)
                        ? (
                          <div style={{ width: '100%' }}>
                            <WordCloud viewModel={viewModel.prevCloudViewModel} />
                          </div>
                        )
                        : (
                          <div>
                            {
                              infoHeaderViewModel.multiViewModel.hasCompare
                              && (
                                ChartFactory.generateChartView({ type: 'nodata', subtype: viewModel.prevCloudViewModel?.words.length === 0 ? 'ana-error' : 'default' })
                              )
                            }
                          </div>
                        )
                    }
                  </div>

                )
            }
          </div>
        </div>
        <Modal
          open={viewModel.isModalOpen}
          onCancel={viewModel.onModalClose}
          width={500}
          centered
          closable={false}
          footer={null}
          className={styles.modal}
          bodyStyle={{
            height: 160,
            display: 'flex',
            flexDirection: 'column',
            rowGap: 36,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div className={styles.modalHeader}>
            <div className={styles.title}>
              {i18n.t('wordbook_page_modal_content').replace('%word%', viewModel.selectedWord?.name)}
            </div>
            <CloseOutlined className={styles.close} onClick={viewModel.onModalClose} />
          </div>
          <div className={styles.btnContainer}>
            <Button
              className={clsx(styles.btn, styles.normalBtn)}
              onClick={viewModel.onTopicClick}
            >
              {i18n.t('wordbook_page_modal_action_topic')}
            </Button>
            <Button
              className={clsx(styles.btn, styles.blockBtn)}
              onClick={() => viewModel.onWordBlock(viewModel.selectedWord.name)}
            >
              {i18n.t('wordbook_page_modal_action_block')}
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

WordsChart.propTypes = {
  viewModel: PropTypes.object
};

WordsChart.defaultProps = {
  viewModel: {}
};


export default WordsChart;
