import API from 'src/apis';
import dayjs from 'dayjs';
import PackageSourcesModel from 'src/models/response/PackageSources';
import packageStore from 'src/stores/packageStore';

export default class SourceService {
  static getPackageId = () => {
    return packageStore.activePackageId;
  };

  static async getPackageSources(data = {}) {
    const res = await API.source.getPackageSources(SourceService.getPackageId(), data);

    return PackageSourcesModel.fromRes(res.data.result);
  }

  static async getSourceStatistics() {
    const res = await API.source.getSourceStatistics();

    return res.data.result;
  }
}
