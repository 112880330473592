import { COLOR_SET } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import packageStore from 'src/stores/packageStore';
import { getHost } from 'src/utils';
import { encodeURI } from 'js-base64';
import { CONDITION_KEY_TC } from 'src/consts';
import { generateCSVKey } from 'src/utils/chart';

export default class AdvSearchProportionModel {
  constructor(origin, prevOrigin, query) {
    const {
      womType,
      postType,
      condition1,
      condition2,
      tagKeyName
    } = query;

    const { currentDate, previousDate } = infoHeaderViewModel.multiViewModel;
    const currentTime = currentDate.map((el) => el.format('YYYY-MM-DD'));
    const previousTime = previousDate.map((el) => el.format('YYYY-MM-DD'));

    const condition1Key = condition1.key;
    const condition2Key = condition2.key;

    const data = origin.slice(0, 20);
    const prevData = prevOrigin.slice(0, 20);

    const total = (() => {
      let sum = 0;
      data.forEach((el) => {
        sum += el.count;
      });
      return sum;
    })();

    const prevTotal = (() => {
      let sum = 0;
      prevData.forEach((el) => {
        sum += el.count;
      });
      return sum;
    })();

    const csv = origin.map((el) => {
      const [c1Name, c2Name] = el.name.split('-');
      return {
        [`交叉條件一${condition1Key}`]: c1Name,
        [`交叉條件二${condition2Key}`]: c2Name,
        聲量: el.count,
        聲量佔比: Math.floor((el.count / total) * 1000) / 10
      };
    });

    const prevCsv = prevOrigin.map((el) => {
      const [c1Name, c2Name] = el.name.split('-');
      return {
        [`交叉條件一${condition1Key}`]: c1Name,
        [`交叉條件二${condition2Key}`]: c2Name,
        聲量: el.count,
        聲量佔比: Math.floor((el.count / total) * 1000) / 10
      };
    });

    csv.push(...prevCsv);

    const csvKey = generateCSVKey(csv[0]);

    const colors = {};
    data.forEach((el) => {
      const target = colors[`${el.condition1}-${el.condition2}`];
      if (!target) {
        colors[`${el.condition1}-${el.condition2}`] = COLOR_SET[Object.keys(colors).length % 20];
      }
    });

    prevData.forEach((el) => {
      const target = colors[`${el.condition1}-${el.condition2}`];
      if (!target) {
        colors[`${el.condition1}-${el.condition2}`] = COLOR_SET[Object.keys(colors).length % 20];
      }
    });

    this.data = {
      chartData: [
        {
          id: 'adv_search_volume_proportion',
          name: '交叉分析聲量佔比',
          data: [
            ...(
              total
                ? [{
                  labels: data.map((el) => el.name),
                  datasets: [{
                    label: currentTime.join('~'),
                    data: data.map((el) => el.count),
                    condition1: data.map((el) => el.condition1),
                    condition2: data.map((el) => el.condition2),
                    backgroundColor: data.map((el) => colors[`${el.condition1}-${el.condition2}`]),
                    datalabels: {
                      color: '#fff',
                      formatter: (value) => {
                        const percentage = ((value / total) * 100).toFixed();
                        return percentage > 5 ? `${percentage}%` : null;
                      }
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const percentage = ((context.parsed / total) * 100).toFixed();
                          return ` ${context.parsed}: [${percentage}%]`;
                        }
                      }
                    }
                  }]
                }]
                : [{
                  type: 'nodata',
                  subType: data.length > 0 ? 'default' : 'ana-error'
                }]
            ),
            ...(
              prevTotal
                ? [{
                  labels: prevData.map((el) => el.name),
                  datasets: [{
                    label: previousTime.join('~'),
                    data: prevData.map((el) => el.count),
                    condition1: prevData.map((el) => el.condition1),
                    condition2: prevData.map((el) => el.condition2),
                    backgroundColor: prevData.map((el) => colors[`${el.condition1}-${el.condition2}`]),
                    datalabels: {
                      color: '#fff',
                      formatter: (value) => {
                        const percentage = ((value / prevTotal) * 100).toFixed();
                        return percentage > 5 ? `${percentage}%` : null;
                      }
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const percentage = ((context.parsed / prevTotal) * 100).toFixed();
                          return ` ${context.parsed}: [${percentage}%]`;
                        }
                      }
                    }
                  }]
                }]
                : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{ type: 'nodata', subType: prevData.length > 0 ? 'default' : 'ana-error' }]
                      : []
                  )
                ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const c1 = chart.data.datasets[datasetIndex].condition1[index];
              const c2 = chart.data.datasets[datasetIndex].condition2[index];
              const [gte, lte] = chart.data.datasets[datasetIndex].label.split('~');
              const [c1Name, c2Name] = chart.data.labels[index].split('-');
              const womCount = chart.data.datasets[datasetIndex].data[index];

              const topicsResult = {
                [`${condition1Key}`]: [{
                  id: c1,
                  ...(condition1.value.tagKey && {
                    tagKey: condition1.value.tagKey,
                    tagKeyName
                  }),
                  name: c1Name.trim()
                }],
                [`${condition2Key}`]: [{
                  id: c2,
                  ...(condition2.value.tagKey && {
                    tagKey: condition2.value.tagKey,
                    tagKeyName
                  }),
                  name: c2Name.trim()
                }],
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: `交叉分析/『${CONDITION_KEY_TC[condition1Key]}』交叉『${CONDITION_KEY_TC[condition2Key]}』/佔比圖`
                },
                womCount,
                date: {
                  gte,
                  lte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);

              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'pie',
          options: {
            event: ['click'],
            plugins: {
              legend: {
                position: 'right'
              }
            }
          },
          csv,
          csvKey
        }
      ]
    };
  }

  static fromRes(data, prevData, query) {
    return new AdvSearchProportionModel(data, prevData, query);
  }
}
