import { SENTIMENT_COLOR_SET, AVG_LINE_COLOR } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';

export default class IndustrySentimentOverviewModel {
  constructor(data, prevData, query) {
    const { currentTimeText, previousTimeText } = infoHeaderViewModel.multiViewModel;
    const csv = [];
    if (data.pn) {
      csv.push({
        date: currentTimeText,
        positive: data['1'],
        neutral: data['0'],
        negative: data['-1'],
        pn: data.pn
      });
    }

    if (prevData.pn) {
      csv.push({
        date: previousTimeText,
        positive: prevData['1'],
        neutral: prevData['0'],
        negative: prevData['-1'],
        pn: prevData.pn
      });
    }

    const csvKey = [
      { key: 'date', header: '日期' },
      { key: 'positive', header: '偏正面' },
      { key: 'neutral', header: '偏中性' },
      { key: 'negative', header: '偏負面' },
      { key: 'pn', header: 'P/N' }
    ];

    this.data = {
      chartData: [
        {
          id: 'industry_sentiment_overview',
          name: '行業情緒總覽',
          data: [
            ...(
              (data?.pn && data?.pn !== 0 && data?.['0'] !== 0 && data?.['1'] !== 0 && data?.['-1'] !== 0)
                ? [{
                  labels: ['行業'],
                  datasets: [
                    {
                      label: '偏正面',
                      data: [data['1']],
                      sentiment: '1',
                      time: 'present',
                      backgroundColor: SENTIMENT_COLOR_SET.positive,
                      borderSkipped: true,
                      maxBarThickness: 50,
                      yAxisID: 'y'
                    },
                    {
                      label: '偏中性',
                      data: [data['0']],
                      sentiment: '0',
                      time: 'present',
                      backgroundColor: SENTIMENT_COLOR_SET.neutral,
                      borderSkipped: true,
                      maxBarThickness: 50,
                      yAxisID: 'y'
                    },
                    {
                      label: '偏負面',
                      data: [data['-1']],
                      sentiment: '-1',
                      time: 'present',
                      backgroundColor: SENTIMENT_COLOR_SET.negative,
                      borderSkipped: true,
                      maxBarThickness: 50,
                      yAxisID: 'y'
                    },
                    {
                      label: 'p/n',
                      data: [data.pn],
                      backgroundColor: AVG_LINE_COLOR,
                      borderSkipped: true,
                      type: 'line',
                      yAxisID: 'y1'
                    }
                  ]
                }]
                : [{
                  type: 'nodata',
                  subType: data ? 'default' : 'ana-error'
                }]
            ),
            ...(
              (prevData?.pn && prevData?.pn !== 0 && prevData?.['0'] !== 0 && prevData?.['1'] !== 0 && prevData?.['-1'] !== 0)
                ? [{
                  labels: ['行業'],
                  datasets: [
                    {
                      label: '偏正面',
                      data: [prevData['1']],
                      sentiment: '1',
                      time: 'previous',
                      backgroundColor: SENTIMENT_COLOR_SET.positive,
                      borderSkipped: true,
                      maxBarThickness: 50,
                      yAxisID: 'y'
                    },
                    {
                      label: '偏中性',
                      data: [prevData['0']],
                      sentiment: '0',
                      time: 'previous',
                      backgroundColor: SENTIMENT_COLOR_SET.neutral,
                      borderSkipped: true,
                      maxBarThickness: 50,
                      yAxisID: 'y'
                    },
                    {
                      label: '偏負面',
                      data: [prevData['-1']],
                      sentiment: '-1',
                      time: 'previous',
                      backgroundColor: SENTIMENT_COLOR_SET.negative,
                      borderSkipped: true,
                      maxBarThickness: 50,
                      yAxisID: 'y'
                    },
                    {
                      label: 'p/n',
                      data: [prevData.pn],
                      backgroundColor: AVG_LINE_COLOR,
                      borderSkipped: true,
                      type: 'line',
                      yAxisID: 'y1'
                    }
                  ]
                }]
                : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{
                        type: 'nodata',
                        subType: prevData ? 'default' : 'ana-error'
                      }]
                      : []
                  )
                ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType,
                category
              } = query;
              const { datasetIndex, index } = elements[0];
              const sentiment = chart.data.datasets[datasetIndex].sentiment;
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const [gte, lte] = chart.data.datasets[datasetIndex].time === 'present'
                ? infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'))
                : infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD'));

              if (!sentiment) {
                return;
              }

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category ? {
                  category: category.map((el) => ({
                    id: el,
                    name: SOURCE_CATEGORY_TYPE[el]
                  }))
                } : {
                  category: SOURCE_CATEGORY.map((el) => ({
                    id: el.value,
                    name: el.label
                  }))
                }),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                sentiment: [Number(sentiment)],
                chart: {
                  name: '圖表分析/行業/情緒分析/行業情緒總覽'
                },
                industry: true,
                womCount,
                date: {
                  gte,
                  lte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'bar',
          options: {
            event: ['click'],
            scales: {
              x: {
                stacked: true
              },
              y: {
                stacked: true,
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                  display: true,
                  text: '聲量'
                }
              },
              y1: {
                type: 'linear',
                display: true,
                position: 'right',
                title: {
                  display: true,
                  text: 'PN值'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ]
    };
  }

  static fromRes(data, prevData, query) {
    return new IndustrySentimentOverviewModel(data, prevData, query);
  }
}
