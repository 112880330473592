import SeriesSourceCategoryTrendModel from './Trend';
import SeriesSourceCategoryRankingModel from './Ranking';
import SeriesSourceCategoryProportionModel from './Proportion';
import SeriesSourceCategoryCompareModel from './Compare';

export default {
  SeriesSourceCategoryTrendModel,
  SeriesSourceCategoryRankingModel,
  SeriesSourceCategoryProportionModel,
  SeriesSourceCategoryCompareModel
};
