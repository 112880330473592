import { request, getHost, getHeaders } from './utils';

export const getBrandRelated = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/assoc/brand/term`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getSeriesRelated = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/assoc/series/term`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getProductRelated = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/assoc/product/term`,
    headers: getHeaders(),
    data
  };
  return request(options);
};
