import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import { CheckOutlined, CaretDownOutlined, FilterOutlined } from '@ant-design/icons';
import { Select, Input, Button, DatePicker, Drawer, Checkbox, Tooltip } from 'antd';

import i18n from 'src/i18n';
import userStore from 'src/stores/userStore';

import RightArrowIcon from 'src/assets/RightArrowIcon.svg';
import DiamondIcon from 'src/assets/DiamondIcon.svg';
import ProjectLinkIcon from 'src/assets/ProjectLinkIcon.svg';
import LevelSelectModal from 'src/components/LevelSelectModal';

import styles from './styles.module.scss';

@observer
class PackagesDrawer extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <>
        <Drawer
          open={viewModel.isDrawerOpen}
          closable={false}
          onClose={viewModel.onDrawerClose}
          className={styles.drawerContainer}
          width={630}
          bodyStyle={{ padding: 0, display: 'grid', gridTemplateRows: '1fr auto' }}
        >
          <div className={styles.contentContainer}>
            <div className={styles.contentHeader}>
              <img src={DiamondIcon} alt="" />
              <div className={styles.headerTitle}>
                {viewModel.drawerTitle}
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.row}>
                <div className={clsx(styles.rowTitle, viewModel.ownerError && styles.errorTitle)}>
                  {i18n.t('packages_drawer_holder')}
                </div>
                <div className={styles.rowContent}>
                  <Select
                    className={clsx(styles.rowSelect, styles.single, viewModel.ownerError && styles.errorSelect)}
                    suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                    placeholder={i18n.t('packages_drawer_holder_placeholder')}
                    value={viewModel.packageOwner}
                    onChange={viewModel.onOwnerChange}
                    options={viewModel.packageOwnerList}
                    disabled={viewModel.isReadOnly || userStore.userType === 'member'}
                    showSearch
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                  />
                  {
                    viewModel.ownerError
                    && (
                      <div className={styles.errorNote}>
                        必須設定數據包擁有者
                      </div>
                    )
                  }
                </div>
              </div>

              <div className={styles.row}>
                <div className={clsx(styles.rowTitle, viewModel.packageNameError && styles.errorTitle)}>
                  {i18n.t('packages_drawer_name')}
                </div>
                <div className={clsx(styles.rowContent)}>
                  <Tooltip
                    title={!viewModel.isReadOnly
                      && (
                        <div className={styles.tooltipWrap}>
                          {"數據包名稱僅能包含中英文數字\n空格及「%, +, _(底線), '(撇), .(點) 」"}
                        </div>
                      )}
                    placement="bottomLeft"
                  >
                    <Input
                      className={clsx(styles.rowInput, viewModel.packageNameError && styles.errorInput)}
                      placeholder={i18n.t('packages_drawer_nam_placeholder')}
                      value={viewModel.packagesName}
                      onChange={viewModel.onPackagesNameChange}
                      onBlur={viewModel.onPackagesNameCheck}
                      disabled={viewModel.isReadOnly}
                    />
                  </Tooltip>
                  {
                    viewModel.packageNameError
                    && (
                      <div className={styles.errorNote}>
                        不符合規則的數據包名稱
                      </div>
                    )
                  }
                </div>
              </div>

              <div className={styles.row}>
                <div className={clsx(styles.rowTitle, viewModel.projectError && styles.errorTitle)}>
                  {i18n.t('packages_drawer_select_project')}
                </div>
                <div className={clsx(styles.salesContainer, styles.rowContent)}>
                  <Select
                    className={clsx(styles.rowSelect, styles.single, viewModel.projectError && styles.errorSelectMargin)}
                    suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                    placeholder={i18n.t('packages_drawer_select_dep_placeholder')}
                    options={viewModel.teamList}
                    onChange={viewModel.onTeamSelect}
                    value={viewModel.selectedTeam}
                    disabled={viewModel.isReadOnly || viewModel.packageId}
                  />
                  <Select
                    className={clsx(styles.rowSelect, styles.single, viewModel.projectError && styles.errorSelectMargin)}
                    suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                    placeholder={i18n.t('packages_drawer_select_sales_placeholder')}
                    disabled={!viewModel.selectedTeam || viewModel.isReadOnly || viewModel.packageId}
                    options={viewModel.salesList}
                    onChange={viewModel.onSalesSelect}
                    value={viewModel.selectedSales}
                  />
                </div>

              </div>

              <div className={styles.row}>
                <div />
                <div className={clsx(styles.projectIDContainer, styles.rowContent)}>
                  <div className={styles.projectSelectContainer}>
                    <Select
                      className={clsx(styles.rowSelect, styles.single, viewModel.projectError && styles.errorSelect)}
                      suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                      placeholder={i18n.t('packages_drawer_select_project_placeholder')}
                      disabled={!viewModel.selectedTeam || viewModel.isReadOnly || viewModel.packageId}
                      options={viewModel.projectList}
                      onChange={(e) => viewModel.onProjectSelect(e, false)}
                      value={viewModel.selectedProject}
                      showSearch
                      filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                    />
                    {
                      viewModel.projectError
                      && (
                        <div className={styles.errorNote}>
                          請選擇專案
                        </div>
                      )
                    }
                  </div>

                  <Tooltip
                    title={(
                      <div className={styles.tooltipNoWrap}>
                        至「專案管理工具」查看專案設定詳情
                      </div>
                    )}
                    placement="bottomRight"
                  >
                    <a
                      href={viewModel.projectLink}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.link}
                      type="link"
                    >
                      <img src={ProjectLinkIcon} alt="" className={styles.linkIcon} />
                    </a>
                  </Tooltip>
                </div>

              </div>

              <div className={styles.row}>
                <div className={clsx(styles.rowTitle, viewModel.timeError && styles.errorTitle)}>
                  {i18n.t('packages_drawer_valid_time')}
                </div>
                <div className={clsx(styles.rowContent)}>
                  <DatePicker.RangePicker
                    className={clsx(styles.rowPicker, viewModel.timeError && styles.errorPicker)}
                    suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                    value={viewModel.validDate}
                    onChange={viewModel.onValidTimeSelect}
                    disabled={viewModel.isReadOnly || !viewModel.selectedProject}
                    disabledDate={(current) => {
                      return current < viewModel.projectValidDate[0].startOf('day') || current > viewModel.projectValidDate[1].endOf('day');
                    }}
                  />
                  {
                    viewModel.timeError
                    && (
                      <div className={styles.errorNote}>
                        請選擇數據包開放時間
                      </div>
                    )
                  }
                </div>
              </div>
            </div>

            <div className={styles.section}>
              <div className={clsx(styles.row, styles.levelsContainer)}>
                <div className={clsx(styles.rowTitle, viewModel.levelsOpenError && styles.errorTitle)}>
                  {i18n.t('packages_drawer_levels_checkbox')}
                </div>
                <div className={styles.rowContent}>
                  <div className={clsx(styles.checkboxContainer, viewModel.levelsOpenError && styles.errorCheckboxContainer)}>
                    <Checkbox
                      className={styles.checkItem}
                      checked={viewModel.openedLevel.industry}
                      onChange={viewModel.onIndustryOpenChange}
                      disabled={viewModel.isReadOnly || !viewModel.selectedProject}
                    >
                      {i18n.t('common_industry')}
                    </Checkbox>
                    <Checkbox
                      className={styles.checkItem}
                      checked={viewModel.openedLevel.brand}
                      onChange={viewModel.onBrandOpenChange}
                      disabled={viewModel.isReadOnly || !viewModel.selectedProject}
                    >
                      {i18n.t('common_brand')}
                    </Checkbox>
                    <Checkbox
                      className={styles.checkItem}
                      checked={viewModel.openedLevel.series}
                      onChange={viewModel.onSeriesOpenChange}
                      disabled={viewModel.isReadOnly || !viewModel.selectedProject}
                    >
                      {i18n.t('common_series')}
                    </Checkbox>
                    <Checkbox
                      className={styles.checkItem}
                      checked={viewModel.openedLevel.product}
                      onChange={viewModel.onProductOpenChange}
                      disabled={viewModel.isReadOnly || !viewModel.selectedProject}
                    >
                      {i18n.t('common_product')}
                    </Checkbox>
                  </div>
                  {
                    viewModel.levelsOpenError
                    && (
                      <div className={styles.errorNote}>
                        請至少開放一個對應階層
                      </div>
                    )
                  }
                </div>
              </div>
            </div>

            <div className={clsx(styles.section, styles.levelSelectSection)}>
              <div className={clsx(styles.sectionHeader, viewModel.levelError && styles.errorHeader)}>
                {i18n.t('packages_drawer_level_data')}
              </div>
              <div className={styles.row}>
                <div className={styles.rowTitle}>
                  {i18n.t('packages_drawer_series')}
                </div>
                <div className={styles.rowContent}>
                  <Select
                    mode="multiple"
                    className={clsx(styles.rowSelect, styles.multi)}
                    suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                    placeholder={i18n.t('packages_drawer_series_placeholder')}
                    options={viewModel.seriesParentList}
                    onChange={viewModel.onParentSeriesSelect}
                    value={viewModel.selectedParent}
                    disabled={viewModel.isReadOnly || !viewModel.selectedProject}
                  />
                </div>
              </div>
              <Button
                icon={<FilterOutlined />}
                className={styles.selectBtn}
                onClick={viewModel.onLevelsFilterOpen}
                disabled={viewModel.isReadOnly || !viewModel.selectedProject}
              >
                {i18n.t('packages_drawer_select_level_btn')}
              </Button>
              <div className={styles.selectedContent}>
                <div className={styles.selectedRow}>
                  <div className={styles.selectedItem}>
                    {`${i18n.t('common_brand')}：`}
                  </div>
                  <div className={styles.selectedItem}>
                    {viewModel.selectModalViewModel.brandText}
                  </div>
                </div>
                <div className={styles.selectedRow}>
                  <div className={styles.selectedItem}>
                    {`${i18n.t('common_series')}：`}
                  </div>
                  <div className={styles.selectedItem}>
                    {viewModel.selectModalViewModel.seriesText}
                  </div>
                </div>
                <div className={styles.selectedRow}>
                  <div className={styles.selectedItem}>
                    {`${i18n.t('common_product')}：`}
                  </div>
                  <div className={styles.selectedItem}>
                    {viewModel.selectModalViewModel.productText}
                  </div>
                </div>
              </div>
              {
                viewModel.levelError
                && (
                  <div className={styles.errorNote}>
                    請至少選擇一個階層的內容
                  </div>
                )
              }
            </div>

            <div className={clsx(styles.section)}>
              <div className={clsx(styles.row, styles.wordbookContainer)}>
                <div className={clsx(styles.rowTitle)}>
                  {i18n.t('packages_drawer_wordbook_title')}
                </div>
                <div className={styles.rowContent}>
                  <Select
                    className={clsx(styles.rowSelect, styles.single)}
                    suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                    placeholder={i18n.t('packages_drawer_wordbook_placeholder')}
                    options={viewModel.featureList}
                    value={viewModel.selectedFeature}
                    onChange={viewModel.onFeatureSet}
                    disabled={viewModel.isReadOnly || !viewModel.selectedProject}
                  />
                  {/* {
                    viewModel.featureSetError
                    && (
                      <div className={styles.errorNote}>
                        請選擇分類詞庫檔案
                      </div>
                    )
                  } */}
                </div>
              </div>

              <div className={clsx(styles.row, styles.wordbookContainer)}>
                <div className={styles.rowTitle}>
                  {i18n.t('packages_drawer_share_to_title')}
                </div>
                <div className={styles.rowContent}>
                  <Select
                    className={clsx(styles.rowSelect, styles.multi)}
                    suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                    placeholder={i18n.t('packages_drawer_share_to_placeholder')}
                    mode="multiple"
                    options={viewModel.customerList}
                    value={viewModel.selectedCustomer}
                    onChange={viewModel.onCustomerSelect}
                    disabled={viewModel.isReadOnly || !viewModel.selectedProject}
                  />
                </div>
              </div>
            </div>

          </div>
          <div className={styles.submitContainer}>
            <Button
              className={styles.submitBtn}
              icon={<CheckOutlined />}
              // disabled={!viewModel.availableSubmit}
              onClick={viewModel.isReadOnly ? viewModel.onDrawerClose : viewModel.onSubmit}
            >
              {viewModel.submitBtn}
            </Button>
          </div>
          <div
            className={styles.customizeBack}
            onClick={viewModel.onDrawerClose}
          >
            <img src={RightArrowIcon} alt="" />
          </div>
        </Drawer>
        <LevelSelectModal viewModel={viewModel.selectModalViewModel} />
      </>
    );
  }
}

PackagesDrawer.propTypes = {
  viewModel: PropTypes.object
};

PackagesDrawer.defaultProps = {
  viewModel: {}
};

export default PackagesDrawer;
