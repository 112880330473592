import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import { Waypoint } from 'react-waypoint';
import { CSVLink } from 'react-csv';

import loading from 'src/assets/loading.svg';

import { CaretDownOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Layout, Select, Breadcrumb } from 'antd';

import { OPINION_SORT_TYPE, SENTIMENT_TYPE } from 'src/consts';
import ScrollTop from 'src/components/ScrollTop';

import SimpleHeader from 'src/components/SimpleHeader';
import SearchTopicCard from 'src/components/SearchTopicCard';
import TopicsResultPageTestViewModel from './viewModel';


import styles from './styles.module.scss';

@observer
class OpinionResultPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new TopicsResultPageTestViewModel();
  }

  componentDidMount() {
    this.viewModel.didMount();
  }

  render() {
    return (
      <Layout className={styles.pageContainer}>
        <SimpleHeader />
        <Layout.Content className={clsx('topicsContainer', styles.contentContainer)}>
          <div className={styles.titleContainer}>
            <div className={styles.pageTitle}>
              {this.viewModel.keyword}
            </div>
            <Breadcrumb
              className={styles.breadContainer}
              items={[
                ...this.viewModel.chartName.split('/').map((el) => ({
                  title: el
                })),
                {
                  title: '話題列表'
                }
              ]}
            />
          </div>
          <div className={styles.searchRes}>
            {/* ROW ONE */}
            <div className={styles.resContent}>
              {this.viewModel.completedDateString}
            </div>
            {/* ROW TWO */}
            <div className={styles.resContent}>
              {this.viewModel.typeText}
            </div>
            {/* ROW THREE */}
            <div className={styles.resContent}>
              {
                this.viewModel.category.length > 0
                && (
                  <span className={styles.item}>
                    {`來源類型：${this.viewModel.category.map((el) => el.name).join(', ')}`}
                  </span>
                )
              }
              {
                this.viewModel.website.id
                && (
                  <span className={styles.item}>
                    {`來源網站：${this.viewModel.website.name}`}
                  </span>
                )
              }
              {
                this.viewModel.channel.id
                && (
                  <span className={styles.item}>
                    {`來源頻道：${this.viewModel.channel.name}`}
                  </span>
                )
              }
            </div>
            {/* ROW FIVE */}
            <div className={styles.resContent}>
              {
                this.viewModel.disabledSentiment
                && (
                  <span className={styles.item}>
                    {`情緒類型：${this.viewModel.sentimentType.map((el) => SENTIMENT_TYPE.find((s) => s.value === el)?.label).join(',')}`}
                  </span>
                )
              }
              {
                this.viewModel.term.name
                && (
                  <span className={styles.item}>
                    {`關鍵字：${this.viewModel.term.name}`}
                  </span>
                )
              }
            </div>
            {/* ROW SIX */}
            <div className={styles.resContent}>
              {
                this.viewModel.term.count > 0
                && (
                  <span className={styles.item}>
                    {`提及則數：${this.viewModel.term.count}`}
                  </span>

                )
              }
              {
                this.viewModel.womCount > 0
                && (
                  <span className={styles.item}>
                    {`聲量：${this.viewModel.womCount}`}
                  </span>

                )
              }
            </div>
          </div>
          <div className={styles.title}>
            話題列表
          </div>
          <div className={styles.actionContainer}>
            <div className={styles.sorts}>
              <div className={styles.sortText}>
                {i18n.t('topic_res_count').replace('%count%', this.viewModel.totalCount)}
              </div>
              <Select
                placeholder={i18n.t('sort_type_title')}
                options={OPINION_SORT_TYPE}
                value={this.viewModel.sortType}
                onSelect={this.viewModel.onSortTypeChange}
                popupMatchSelectWidth={false}
                popupClassName={styles.sortMenu}
                className={clsx('sortSelect', styles.filterSelect)}
                suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                style={{ minWidth: 100 }}
              />
            </div>
            <Button
              icon={<DownloadOutlined />}
              className={styles.downloadButton}
              onClick={this.viewModel.onDownloadClick}
            >
              {i18n.t('topic_download')}
            </Button>
            {
              this.viewModel.isDownload
              && (
                <CSVLink
                  className="topicDownload"
                  data={this.viewModel.downloadList}
                  filename={this.viewModel.downloadFileName}
                  style={{ display: 'none' }}
                />
              )
            }
          </div>
          <div className={styles.resContainer}>
            {
              this.viewModel.topicList.map((el, i) => {
                return (
                  <SearchTopicCard
                    key={el.vocKey}
                    viewModel={el}
                    order={i}
                  />
                );
              })
            }
            {
              this.viewModel.page < this.viewModel.totalPage
              && (
                <Waypoint onEnter={this.viewModel.onScrollEnd} />
              )
            }
          </div>
        </Layout.Content>
        {
          this.viewModel.isLoading
          && (
            <div className={styles.loadingContainer}>
              <div className={styles.curtain} />
              <img src={loading} alt="" className={styles.loadingIcon} />
            </div>
          )
        }
        <ScrollTop containerName="topicsContainer" />
      </Layout>
    );
  }
}

OpinionResultPage.propTypes = {
};

OpinionResultPage.defaultProps = {
};


export default OpinionResultPage;
