import AdvSearchTrendModel from './Trend';
import AdvSearchRankingModel from './Ranking';
import AdvSearchProportionModel from './Proportion';
import AdvSearchCompareModel from './Compare';

export default {
  AdvSearchTrendModel,
  AdvSearchRankingModel,
  AdvSearchProportionModel,
  AdvSearchCompareModel
};
