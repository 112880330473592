import { COLOR_SET } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import { SOURCE_CATEGORY_TYPE } from 'src/consts';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { generateCSVKey } from 'src/utils/chart';

export default class BrandSourceChannelProportionModel {
  constructor(data, prevData, query) {
    if (data.length === 0 && prevData.length === 0) {
      this.data = {
        chartData: [{
          id: 'brand_source_category_proportion',
          name: '品牌',
          data: [
            {
              labels: [],
              datasets: [],
              type: 'nodata',
              subType: 'ana-error'
            },
            ...(
              infoHeaderViewModel.multiViewModel.hasCompare
                ? [{
                  labels: [],
                  datasets: [],
                  type: 'nodata',
                  subType: 'ana-error'
                }]
                : []
            )
          ]
        }]
      };
      return;
    }

    const dataMap = new Map();
    const prevDataMap = new Map();

    for (let i = 0; i < data.length; i += 1) {
      dataMap.set(data[i].id, data[i]);
    }

    for (let i = 0; i < prevData.length; i += 1) {
      prevDataMap.set(prevData[i].id, prevData[i]);
    }

    const brandList = BrandSourceChannelProportionModel.generateTabList(data, prevData);
    // > to fixed
    const colors = BrandSourceChannelProportionModel.generateColor(data, prevData);
    const currTime = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD')).join('~');
    const prevTime = infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD')).join('~');
    const csvForAll = [];

    // > 10個
    brandList.forEach((el) => {
      const targetInData = dataMap.get(el.id);
      const sliceItem = targetInData?.item.slice(0, 20) ?? [];
      const targetInPrevData = prevDataMap.get(el.id);
      const slicePrevItem = targetInPrevData?.item.slice(0, 20) ?? [];
      const total = (() => {
        if (!targetInData) {
          return null;
        }
        let sum = 0;
        sliceItem.forEach((c) => {
          sum += c.count;
        });
        return sum;
      })();
      const prevTotal = (() => {
        if (!targetInPrevData) {
          return null;
        }
        let sum = 0;
        slicePrevItem.forEach((c) => {
          sum += c.count;
        });
        return sum;
      })();

      const csv = BrandSourceChannelProportionModel.generateCSV(sliceItem, total, slicePrevItem, prevTotal);
      const csvKey = generateCSVKey(csv[0]);
      csvForAll.push({
        id: el.id,
        name: el.name,
        csv,
        csvKey
      });
    });

    this.data = {
      chartData: brandList.map((el) => {
        const targetInData = dataMap.get(el.id);
        const sliceItem = targetInData?.item.slice(0, 20) ?? [];
        const targetInPrevData = prevDataMap.get(el.id);
        const slicePrevItem = targetInPrevData?.item.slice(0, 20) ?? [];
        const total = (() => {
          if (!targetInData) {
            return null;
          }
          let sum = 0;
          sliceItem.forEach((c) => {
            sum += c.count;
          });
          return sum;
        })();
        const prevTotal = (() => {
          if (!targetInPrevData) {
            return null;
          }
          let sum = 0;
          slicePrevItem.forEach((c) => {
            sum += c.count;
          });
          return sum;
        })();

        const { csv, csvKey } = csvForAll.find((item) => el.id === item.id);

        return {
          id: el.id,
          name: el.name,
          data: [
            ...(
              total
                ? [{
                  labels: sliceItem.map((item) => item.name),
                  datasets: [{
                    label: currTime,
                    data: sliceItem.map((item) => item.count),
                    channel: sliceItem.map((item) => ({ id: item.id, name: item.name })),
                    backgroundColor: sliceItem.map((item) => colors.get(item.id)),
                    datalabels: {
                      color: '#fff',
                      formatter: (value) => {
                        const percentage = ((value / total) * 100).toFixed();
                        return percentage > 5 ? `${percentage}%` : null;
                      }
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const percentage = ((context.parsed / total) * 100).toFixed();
                          return ` ${context.parsed}: [${percentage}%]`;
                        }
                      }
                    }
                  }]
                }]
                : [{
                  type: 'nodata',
                  subType: targetInData ? 'pie' : 'ana-error'
                }]
            ),
            ...(
              prevTotal
                ? [{
                  labels: slicePrevItem.map((item) => item.name),
                  datasets: [{
                    label: prevTime,
                    data: slicePrevItem.map((item) => item.count),
                    channel: slicePrevItem.map((item) => item.id),
                    backgroundColor: slicePrevItem.map((item) => colors.get(item.id)),
                    datalabels: {
                      color: '#fff',
                      formatter: (value) => {
                        const percentage = ((value / prevTotal) * 100).toFixed();
                        return percentage > 5 ? `${percentage}%` : null;
                      }
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const percentage = ((context.parsed / prevTotal) * 100).toFixed();
                          return ` ${context.parsed}: [${percentage}%]`;
                        }
                      }
                    }
                  }]
                }]
                : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{
                        type: 'nodata',
                        subType: targetInPrevData ? 'pie' : 'ana-error'
                      }]
                      : []
                  )
                ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType,
                category
              } = query;
              const { datasetIndex, index } = elements[0];
              const channel = chart.data.datasets[datasetIndex].channel[index];
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const [gte, lte] = chart.data.datasets[datasetIndex].label.split('~');

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category && {
                  category: category.map((c) => ({
                    id: c,
                    name: SOURCE_CATEGORY_TYPE[c]
                  }))
                }),
                channel: [{
                  id: channel.id,
                  name: channel.name
                }],
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/品牌/來源分析/品牌來源頻道/品牌來源頻道佔比'
                },
                brand: [{
                  id: el.id,
                  name: el.name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'pie',
          options: {
            event: ['click'],
            plugins: {
              legend: {
                position: 'right'
              }
            }
          },
          csv,
          csvKey
        };
      }),
      csvForAll
    };
  }
  static generateTabList = (list, prevList) => {
    const tab = [];

    list?.slice(0, 10).forEach((el) => {
      const target = tab.find((item) => item.id === el.id);
      if (!target) {
        tab.push({ id: el.id, name: el.name });
      }
    });
    return tab;
  };

  static generateColor = (list, prevList) => {
    const colors = new Map();

    list?.forEach((el) => {
      for (let i = 0; i < el.item.length; i += 1) {
        const target = colors.get(el.item[i].id);
        if (!target) {
          colors.set(el.item[i].id, COLOR_SET[colors.size % 20]);
        }
      }
    });

    prevList?.forEach((el) => {
      for (let i = 0; i < el.item.length; i += 1) {
        const target = colors.get(el.item[i].id);
        if (!target) {
          colors.set(el.item[i].id, COLOR_SET[colors.size % 20]);
        }
      }
    });

    return colors;
  };

  static generateCSV = (curr, currTotal, prev, prevTotal) => {
    const csv = [];

    if (curr) {
      const time = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'));
      const row = {
        日期: time.join('~')
      };
      curr.forEach((el) => {
        row[`${el.name}`] = el.count;
        row[`${el.name}-佔比`] = el.count / currTotal;
      });
      csv.push(row);
    }

    if (prev) {
      const time = infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD'));
      const row = {
        日期: time.join('~')
      };
      prev.forEach((el) => {
        row[`${el.name}`] = el.count;
        row[`${el.name}-佔比`] = el.count / prevTotal;
      });
      csv.push(row);
    }

    return csv;
  };

  static fromRes(data, prevData, query) {
    return new BrandSourceChannelProportionModel(data, prevData, query);
  }
}
