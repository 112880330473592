import { makeObservable, observable, action, computed, reaction, runInAction } from 'mobx';
import { encodeURI } from 'js-base64';
import { message } from 'antd';
import * as xlsx from 'xlsx';
import html2canvas from 'html2canvas';
import WordCloud from 'wordcloud';

import optionsStore from 'src/stores/optionsStore';
import { getHost } from 'src/utils';
import { getTwo } from 'src/utils/chart';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import { COLOR_SET, WORD_CONFIG } from 'src/consts/chart';

export default class WordViewModel {
  @observable originData = null;
  @observable chartData = null;
  @observable searchData = null;

  @observable selectedItem = null;

  @observable blockSet = new Set();
  @observable cacheSet = new Set();

  @observable isDraw = false;

  @observable width = 0;
  @observable widthCache = 0;
  @observable once = null;

  @observable isModalOpen = false;

  @observable isBlockModalOpen = false;

  @computed get excelTitle() {
    return [
      { key: 'name', header: '關鍵字' },
      { key: 'count', header: '提及則數' },
      { key: 'score', header: '分數' }
    ];
  }

  @computed get excelData() {
    const excel = [];
    if (!this.originData) {
      return excel;
    }

    for (let i = 0; i < this.originData.length; i += 1) {
      const item = this.originData[i];
      excel.push({
        name: item.name,
        count: item.count,
        score: getTwo(item.score)
      });
    }

    return excel;
  }

  @computed get activeWords() {
    return this.chartData.filter((item) => !this.blockSet.has(item[0]));
  }

  @computed get config() {
    return {
      list: this.activeWords,
      fontFamily: 'Noto Sans TC',
      rotateRatio: 0,
      color: (_, __, ___, ____, _____, extraData) => extraData[0],
      fontWeight: 600,
      classes: 'words-item',
      shrinkToFit: true,
      clearCanvas: true,
      click: (item) => this.onWordClick({ name: item[0], score: item[4], count: item[3] })
    };
  }

  @computed get topicsResult() {
    const { keyword, postType, womType, category, website, channel } = this.searchData;

    return {
      keyword,
      ...(womType && {
        womType
      }),
      ...(postType && {
        postType
      }),
      ...(category ? {
        category: category.map((el) => ({
          id: el,
          name: SOURCE_CATEGORY_TYPE[el]
        }))
      } : {
        category: SOURCE_CATEGORY.map((el) => ({
          id: el.value,
          name: el.label
        }))
      }),
      ...(website && { website: { id: website[0], name: website[0] } }),
      ...(channel && { channel: { id: channel[0].id, name: channel[0].name } }),
      chart: {
        name: `輿情搜尋/摘要圖表/${keyword}文字雲`
      },
      date: {
        gte: this.searchData.date.gte,
        lte: this.searchData.date.lte
      }
    };
  }

  constructor() {
    makeObservable(this);
  }

  didMount = () => {
    const container = document.querySelector('#wordsContainerOpinion');
    const mouseover = container.addEventListener('mouseover', (e) => { this.onMouseOverWord(e.target); });
    const mouseout = container.addEventListener('mouseout', (e) => { this.onMouseOutWord(e.target); });
    const addTooltip = container.addEventListener('wordclouddrawn', this.onAddTooltip);

    runInAction(() => {
      this.mouseover = mouseover;
      this.mouseout = mouseout;
      this.addTooltip = addTooltip;
    });

    this.onDraw();
  };

  willUnMount = () => {
    const container = document.querySelector('#wordsContainerOpinion');
    if (!container) {
      return;
    }
    container.removeEventListener('mouseover', this.mouseover);
    container.removeEventListener('mouseout', this.mouseout);
    container.removeEventListener('wordclouddrawn', this.addTooltip);

    this.once?.();
  };

  onMouseOverWord = (target) => {
    const isWord = target.classList.contains('words-item');
    if (!isWord) {
      return;
    }
    target.classList.add('words-item-hover');
  };

  onMouseOutWord = (target) => {
    const isActive = target.classList.contains('words-item-hover');
    if (!isActive) {
      return;
    }
    target.classList.remove('words-item-hover');
  };

  onAddTooltip = (e) => {
    const arr = Array.prototype.slice.call(e.target.children);
    const target = arr[arr.length - 1];
    const tooltip = document.createElement('DIV');
    const times = e.detail.item[3];

    tooltip.innerText = `提及則數：${times} `;
    tooltip.classList.add('tooltip');
    target.appendChild(tooltip);
  };

  @action update = (data, searchData) => {
    this.updateContent(data, searchData);
  };

  @action updateContent = (data, searchData) => {
    this.searchData = searchData;
    this.blockSet.clear();
    this.cacheSet.clear();
    if (!data || data.length === 0) {
      message.warning('無文字雲資料，請重新確認！');
      return;
    }


    this.originData = data;

    const resizeWord = [];

    const max = Math.max(...data.map((el) => el.count));
    const min = Math.min(...data.map((el) => el.count));

    const size = (() => {
      const length = data.length;
      if (length <= 20) {
        return 'small';
      }
      if (length > 20 && length <= 50) {
        return 'medium';
      }
      return 'large';
    })();

    const getNew = (num, maxNum, minNum) => {
      // FOR ONLY ONE SITUATION
      if (maxNum === minNum) {
        return WORD_CONFIG[size].basicSize * WORD_CONFIG[size].maxRatio;
      }
      return ((num - minNum) * (WORD_CONFIG[size].maxRatio / (maxNum - minNum)) + 1) * WORD_CONFIG[size].basicSize;
    };

    for (let i = 0; i < data.length; i += 1) {
      const item = data[i];
      // > [name, newCount, color, count, score]
      resizeWord.push([item.name, getNew(item.count, max, min), COLOR_SET[i % 20], item.count, getTwo(item.score)]);
    }

    this.chartData = resizeWord;
    this.onDraw();
  };

  @action onDraw = () => {
    if (this.isDraw || !this.chartData) {
      return;
    }
    this.isDraw = true;

    const container = document.querySelector('#wordsContainerOpinion');
    if (!container) {
      this.isDraw = false;
      return;
    }
    WordCloud(container, this.config);

    this.isDraw = false;
  };

  @action onWordClick = (item) => {
    this.selectedItem = item;
    this.isModalOpen = true;
  };

  @action onModalClose = () => {
    this.selectedItem = null;
    this.isModalOpen = false;
  };

  @action onBlockClick = () => {
    this.blockSet.add(this.selectedItem.name);
    this.selectedItem = null;
    this.isModalOpen = false;

    this.onDraw();
  };

  @action onTopicsClick = () => {
    const archiveData = {
      ...this.topicsResult,
      term: {
        name: this.selectedItem.name,
        count: this.selectedItem.count
      }
    };

    const json = JSON.stringify(archiveData);
    const result = encodeURI(json);
    const url = `${getHost()}/opinion-topics?result=${result}`;

    window.open(url, '_blank');
    this.isModalOpen = false;
  };

  @action onDownloadExcel = () => {
    this.isDownload = false;
    if (!this.excelTitle || !this.excelData) {
      message.error('csv檔不存在');
      return;
    }
    const data = [...this.excelData];
    const header = this.excelTitle.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [this.excelTitle.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    const wordbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wordbook, workSheet, '關鍵字');
    xlsx.writeFile(wordbook, `輿情搜尋-${this.searchData.keyword}-關鍵字-${this.searchData.date.gte}-${this.searchData.date.lte}.xlsx`);
  };

  @action appendSheet = (wordbook) => {
    this.isDownload = false;
    if (!this.excelTitle || !this.excelData) {
      message.error('csv檔不存在');
      return;
    }
    const data = [...this.excelData];
    const header = this.excelTitle.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [this.excelTitle.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    xlsx.utils.book_append_sheet(wordbook, workSheet, '關鍵字');
  };

  @action onDownload = async () => {
    await optionsStore.onImgDownload();
    const chart = document.querySelector('.opinion-keyword');
    const canvas = await html2canvas(chart);
    const a = document.createElement('a');
    a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
    a.download = `輿情搜尋-${this.searchData.keyword}-關鍵字-${this.searchData.date.gte}-${this.searchData.date.lte}.jpg`;
    a.click();
    optionsStore.onImgDownloadEnd();
  };


  @action onBlockModalOpen = () => {
    this.cacheSet = new Set(Array.from(this.blockSet));
    this.isBlockModalOpen = true;
  };

  @action onBlockModalClose = () => {
    if (this.blockSet.size !== this.cacheSet.size) {
      this.onDraw();
    }
    this.isBlockModalOpen = false;
  };

  @action onRecoverWord = (word) => {
    this.blockSet.delete(word);
  };
}
