import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { getHost, getLevelText, separate } from 'src/utils';
import { encodeURI } from 'js-base64';
import html2canvas from 'html2canvas';
import { message } from 'antd';
import NoDataChartViewModel from 'src/components/Charts/NoData/viewModel';
import * as xlsx from 'xlsx';

import { convertCSV } from 'src/utils/chart';
import { SOURCE_CATEGORY_TYPE } from 'src/consts';
import BlockedWordsService from 'src/services/blockedwords';
import packageStore from 'src/stores/packageStore';
import optionsStore from 'src/stores/optionsStore';
import ChartAnalysisService from 'src/services/charts/chartAnalysis';
import ChartFactory from 'src/components/Charts/factory';
import ChartService from 'src/services/charts';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';

export default class WordChartItemViewModel {
  parent = {};
  notAllowAll = true;

  @observable level = '';
  @observable data = [];
  @observable activeTabId = null;
  @observable isModalOpen = false;

  @observable wordsViewModel = null;

  @observable onReady = false;

  @observable showDiscover = false;
  @observable discoverName = '';
  @observable discoverWords = null;

  @observable isImgDownload = false;
  @observable isDownload = false;
  @observable downloadList = [];
  @observable downloadFileName = '';

  @computed get showData() {
    return this.activeTabId ? this.data.find((el) => el.id === this.activeTabId) : {};
  }

  @computed get showChart() {
    return this.activeTabId ? this.data.find((el) => el.id === this.activeTabId).chart : Array.from({ length: infoHeaderViewModel.multiViewModel.hasCompare ? 2 : 1 }, (v) => {
      return ChartFactory.createChartViewModel({ id: 'nodata', name: getLevelText(this.parent.level), data: { type: 'nodata', subType: 'ana-error' } });
    });
  }

  @computed get chartTitle() {
    const selectedSubtypeInfo = this.parent.selectedSubtypeInfo;
    const selectedSubChartTypeInfo = this.parent.selectedSubChartTypeInfo;
    if (selectedSubChartTypeInfo.id) {
      if (selectedSubChartTypeInfo.replace) {
        return selectedSubChartTypeInfo.replace.replace('%replace%', `${this.showData?.name}-`);
      }
      return selectedSubChartTypeInfo.name;
    }
    if (selectedSubtypeInfo.replace) {
      return selectedSubtypeInfo.replace.replace('%replace%', `${this.showData?.name}-`);
    }
    return selectedSubtypeInfo.label;
  }

  @computed get notAllowTab() {
    return ['品牌', '系列', '產品'].includes(this.data[0].name);
  }

  constructor(data, parent) {
    makeObservable(this);

    this.init(data, parent);
  }

  @action init = async (data, parent) => {
    this.parent = parent;
    this.level = this.parent.level;
    this.data = data.map((el) => ({
      id: el.id,
      name: el.name,
      chart: null
    }));

    await this.getData(this.data[0].id);
    runInAction(() => {
      this.activeTabId = this.data[0].id;

      this.onReady = true;
    });
  };

  @action getData = async (id) => {
    try {
      optionsStore.setLoading('word');

      // > 24/04/25 updating
      const checkLatest = await packageStore.checkLatest();
      if (checkLatest.needUpdate) {
        optionsStore.setCompleted('word');
        await packageStore.syncLatest(checkLatest);
        return;
      }

      const params = {
        ...(this.parent.selectedPostType !== 'all' && { postType: this.parent.selectedPostType }),
        ...(this.parent.selectedWOMType !== 'all' && { womType: this.parent.selectedWOMType }),
        ...(this.parent.level === 'series' && {
          tagKey: this.parent.selectedTag,
          tagValue: id
        }),
        ...(this.parent.level !== 'series' && { search: id }),
        metric: this.parent.selectedIndex,
        size: this.parent.selectedCount,
        ...(this.parent.blockWords.length > 0 && {
          disableTerm: this.parent.blockWords.map((el) => el.name)
        }),
        ...(this.parent.showCategorySelect && this.parent.sourceCategoryPopover.selected.length >= 1 && {
          category: this.parent.sourceCategoryPopover.selected.map((el) => el.id)
        })
      };

      const search = {
        id: this.data.find((el) => el.id === id).id,
        name: this.data.find((el) => el.id === id).name
      };

      const { data } = await ChartAnalysisService.getGraphicData(this.parent.level, this.parent.selectedType, this.parent.selectedSubtype, this.parent.selectedSubChartType, { params, search });
      runInAction(() => {
        console.log('data', data);

        const target = this.data.find((el) => el.id === id);
        target.chart = data.chartData[0].data.map((el, i) => {
          return ChartFactory.createChartViewModel({ ...data.chartData[0], data: el, time: i === 0 ? 'present' : 'previous' }, this);
        });
      });

    } catch (error) {
      message.error('圖表資料異常，請稍後再試');
      runInAction(() => {
        const target = this.data.find((el) => el.id === id);
        target.chart = [new NoDataChartViewModel({ data: { type: 'nodata', subType: 'ana-error' } })];
      });
    } finally {
      optionsStore.setCompleted('word');
    }
  };

  @action onTabsChange = async (key) => {
    const target = this.data.find((el) => el.id === key);
    if (!target.chart) {
      await this.getData(key);
    }
    runInAction(() => {
      this.activeTabId = key;
    });
  };

  @action onModalOpen = (viewModel) => {
    this.wordsViewModel = viewModel;
    this.isModalOpen = true;
  };

  @action onModalClose = () => {
    this.isModalOpen = false;
    this.wordsViewModel?.onModalClose();

    this.wordsViewModel = null;
  };

  @action onWordBlock = async (word) => {
    try {
      await BlockedWordsService.addBlockWords(word);
      await this.parent.getBlockWords();
      if (this.showDiscover) {
        await this.onDiscoverClick();
      } else {
        await this.getData(this.activeTabId);
      }
      runInAction(() => {

        this.onModalClose();
      });
    } catch (error) {
      if (error.response?.status === 409) {
        message.error('該詞已在屏蔽名單中!');
        return;
      }
      message.error('無法新增屏蔽詞，請稍候再試');
    }
  };

  @action onWordCloudCountChange = async () => {
    this.showDiscover = false;
    this.discoverName = '';
    this.discoverWords = null;

    try {
      for (let i = 0; i < this.data.length; i += 1) {
        this.data[i].chart = null;
      }

      await this.getData(this.activeTabId);
    } catch (error) {
      // console.log(error);
    }

  };

  @action onKeywordTypeChange = async () => {
    this.onReady = false;
    this.data = this.data.map((el) => ({
      ...el,
      chart: null
    }));
    this.showDiscover = false;
    this.discoverName = '';
    this.discoverWords = null;
    this.activeTabId = this.data[0].id;
    await this.getData(this.data[0].id);
    runInAction(() => {
      this.onReady = true;
    });
  };

  @action onTopicsClick = () => {
    const wordContent = this.wordsViewModel.selectedWordContentByName;
    const json = JSON.stringify(wordContent.linkData);
    const result = encodeURI(json);
    const url = `${getHost()}/topics-result?result=${result}`;
    window.open(url, '_blank');

    this.onModalClose();
  };

  @action onDiscoverClick = async () => {
    try {
      optionsStore.setLoading('discover');
      if (!this.discoverWords) {
        this.discoverName = this.wordsViewModel.selectedName;
      }
      const [gte, lte] = this.wordsViewModel.timeSec === 'present'
        ? infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'))
        : infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD'));
      const params = {
        date: {
          gte,
          lte
        },
        ...(this.parent.level === 'series' && {
          tagKey: this.parent.selectedTag.value,
          tagValue: this.showData.id
        }),
        ...(this.parent.level !== 'series' && { search: this.showData.id }),
        metric: this.parent.selectedIndex,
        size: this.parent.selectedCount,
        term: this.discoverName,
        ...(this.parent.blockWords.length > 0 && { disableTerm: this.parent.blockWords.map((el) => el.name) }),
        ...(this.parent.selectedPostType !== 'all' && { postType: this.parent.selectedPostType }),
        ...(this.parent.selectedWOMType !== 'all' && { womType: this.parent.selectedWOMType }),
        ...(this.parent.showCategorySelect && this.parent.sourceCategoryPopover.selected.length >= 1 && {
          category: this.parent.sourceCategoryPopover.selected.map((el) => el.id)
        })
      };

      const { data } = await ChartService.getHotWordsDiscover(this.level, params, { id: this.showData.id, name: this.showData.name });
      runInAction(() => {
        this.discoverWords = ChartFactory.createChartViewModel({ ...data.chartData[0], data: data.chartData[0].data[0], time: this.wordsViewModel.timeSec }, this);

        this.showDiscover = true;
      });

      this.onModalClose();
    } catch (error) {
      console.log(error);
    } finally {
      optionsStore.setCompleted('discover');
    }
  };

  @action onDiscoverLeave = () => {
    this.discoverWords = null;
    this.showDiscover = false;
  };

  @action onDownloadExcel = () => {
    this.isDownload = false;
    const { csv, csvKey } = this.showDiscover ? this.discoverWords : this.showChart[0];
    if (!csv || !csvKey) {
      message.error('csv檔不存在');
      return;
    }
    const { currentTimeText, previousTimeText, autoPreviousTimeText, hasCompare } = infoHeaderViewModel.multiViewModel;
    const data = [...csv];
    const header = csvKey.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [csvKey.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    const wordbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wordbook, workSheet, '圖表分析');
    xlsx.writeFile(wordbook, `${packageStore.activePackageName}-${this.showDiscover ? `「${this.discoverName}」文字雲下探` : this.chartTitle}-${currentTimeText}${hasCompare ? `-${(this.parent.isTrendWord || this.parent.isNewWord) ? autoPreviousTimeText : previousTimeText}` : ''}.xlsx`);
  };

  @action onDownload = async () => {
    await optionsStore.onImgDownload();
    const chart = document.querySelector('.download-chart');
    const canvas = await html2canvas(chart);
    const a = document.createElement('a');
    const { currentTimeText, previousTimeText, autoPreviousTimeText, hasCompare } = infoHeaderViewModel.multiViewModel;
    a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
    a.download = `${packageStore.activePackageName}-${this.showDiscover ? `「${this.discoverName}」文字雲下探` : this.chartTitle}-${currentTimeText}${hasCompare ? `-${(this.parent.isTrendWord || this.parent.isNewWord) ? autoPreviousTimeText : previousTimeText}` : ''}.jpg`;
    a.click();
    optionsStore.onImgDownloadEnd();
  };
}
