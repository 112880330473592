import API from 'src/apis';
import ChartModel from 'src/models/response/charts';
import packageStore from 'src/stores/packageStore';

export default class RelatedAnalysisService {
  static getPackageId = () => {
    return packageStore.activePackageId;
  };

  static async getBrandRelated(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.related.getBrandRelated(RelatedAnalysisService.getPackageId(), data),
      prevData
        ? API.related.getBrandRelated(RelatedAnalysisService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartModel.RelatedAnalysis.BrandWordModel.fromRes(res.data.result, prevRes.data.result);
  }

  static async getSeriesRelated(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.related.getSeriesRelated(RelatedAnalysisService.getPackageId(), data),
      prevData
        ? API.related.getSeriesRelated(RelatedAnalysisService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartModel.RelatedAnalysis.SeriesWordModel.fromRes(res.data.result, prevRes.data.result);
  }

  static async getProductRelated(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.related.getProductRelated(RelatedAnalysisService.getPackageId(), data),
      prevData
        ? API.related.getProductRelated(RelatedAnalysisService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartModel.RelatedAnalysis.ProductWordModel.fromRes(res.data.result, prevRes.data.result);
  }

}
