import { makeObservable, observable, action, computed, reaction, runInAction } from 'mobx';
import html2canvas from 'html2canvas';
import packageStore from 'src/stores/packageStore';
import { message } from 'antd';
import * as xlsx from 'xlsx';

import SourceService from 'src/services/source';
import ChartService from 'src/services/charts';
import optionsStore from 'src/stores/optionsStore';
import { RELATED_WORD_TYPE } from 'src/consts';

import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import CategoryPopoverSelectViewModel from 'src/components/CategoryPopoverSelect/viewModel';
import BlockedWordsService from 'src/services/blockedwords';
import WordsChartViewModel from './components/WordsChart/viewModel';

export default class RelatedAnalysisPageViewModel {
  @observable level = null;
  @observable selectedType = 'words';
  @observable isInit = false;

  @observable brandItems = [];
  @observable seriesItems = [];
  @observable productItems = [];

  @observable selectedPostType = 'all';
  @observable selectedWOMType = 'nature';
  @observable selectedTagKey = '';

  @observable selectedIndex = 'frequency';
  @observable selectedCount = 20;

  @observable categoryPopover = new CategoryPopoverSelectViewModel();

  @observable blockWords = [];
  @observable cacheBlockWords = [];
  @observable isStopModalOpen = false;

  @observable wordsChartViewModel = new WordsChartViewModel(this);

  @observable isDownload = false;
  @observable downloadList = [];
  @observable downloadFileName = '';

  @observable once = null;

  @computed get isShowStatisticClean() {
    return this.selectedPostType !== 'all' || this.selectedWOMType !== 'nature' || this.categoryPopover.selected.length > 0;
  }

  @computed get isShowChartClean() {
    return this.selectedIndex !== 'frequency' || this.selectedCount !== 20;
  }

  @computed get selectedCategory() {
    return this.categoryPopover.selected;
  }


  constructor() {
    makeObservable(this);

    this.init();
  }

  @action init = async () => {
    if (!packageStore.isLevelProcessing) {
      await Promise.all([
        this.getBlockedWords(),
        this.getSource()
      ]);
      runInAction(() => {
        this.isInit = true;
      });
    } else {
      const once = reaction(
        () => packageStore.isLevelProcessing,
        async (bool) => {
          if (!bool) {
            await Promise.all([
              this.getBlockedWords(),
              this.getSource()
            ]);
            runInAction(() => {
              this.isInit = true;
              once();
            });
          }
        }
      );
    }
  };

  @action setReaction = () => {
    const levelOnce = reaction(
      () => this.level,
      (newLevel) => {
        if (this.isInit) {
          this.onLevelChange();
        } else {
          const once = reaction(
            () => this.isInit,
            async (bool) => {
              if (bool) {
                this.onLevelChange();
                once();
              }
            }
          );
        }
      }
    );

    this.once = levelOnce;
  };

  @action getSource = async () => {
    optionsStore.setLoading('source');
    try {
      const { source } = await SourceService.getPackageSources();
      runInAction(() => {
        this.categoryPopover.updateList(source);
        this.selectedTagKey = packageStore.series[0].id;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        optionsStore.setCompleted('source');
      });
    }
  };

  @action onLevelChange = async () => {
    await this.getLevelTops();
    runInAction(() => {
      this.selectedCount = 20;

      this.wordsChartViewModel.selectedType = 'who';
    });

    this.wordsChartViewModel.getData(this.level, this.selectedCount);
  };

  @action updateLevel = async (router) => {
    const level = router?.params?.level;
    if (level && !['brand', 'series', 'product'].includes(level)) {
      message.error('階層不存在');
      router.navigate('/');
      return;
    }
    this.level = level;
  };

  @action getLevelTops = async () => {
    if (optionsStore.loadingQueue.includes('tops')) {
      return;
    }
    optionsStore.setLoading('tops');
    // > 24/04/25 updating
    const checkLatest = await packageStore.checkLatest();
    if (checkLatest.needUpdate) {
      optionsStore.setCompleted('tops');
      await packageStore.syncLatest(checkLatest);
      return;
    }
    try {
      if (this.level === 'brand') {
        if (this.brandItems.length > 0) {
          this.wordsChartViewModel.updateLevelOptions(this.brandItems);
          return;
        }
        const res = await ChartService.getTopTenBrands({
          date: {
            gte: infoHeaderViewModel.multiViewModel.currentDate[0].format('YYYY-MM-DD'),
            lte: infoHeaderViewModel.multiViewModel.currentDate[1].format('YYYY-MM-DD')
          }
        });
        runInAction(() => {
          this.brandItems = res.map((el) => ({
            value: el.id,
            label: el.name,
            chart: [],
            prevChart: []
          }));
          this.wordsChartViewModel.updateLevelOptions(this.brandItems);
        });
      }
      if (this.level === 'series') {
        if (this.seriesItems.length > 0) {
          this.wordsChartViewModel.updateLevelOptions(this.seriesItems);
          return;
        }

        if (!this.selectedTagKey.trim()) {
          const once = reaction(
            () => this.selectedTagKey,
            async (tagKey) => {
              if (tagKey.trim()) {
                const res = await ChartService.getTopTenSeries({
                  date: {
                    gte: infoHeaderViewModel.multiViewModel.currentDate[0].format('YYYY-MM-DD'),
                    lte: infoHeaderViewModel.multiViewModel.currentDate[1].format('YYYY-MM-DD')
                  },
                  tagKey: this.selectedTagKey
                });
                runInAction(() => {
                  this.seriesItems = res.map((el) => ({
                    value: el.id,
                    label: el.name,
                    chart: [],
                    prevChart: []
                  }));
                  this.wordsChartViewModel.updateLevelOptions(this.seriesItems);
                });
                once();
              }
            }
          );
        } else {
          const res = await ChartService.getTopTenSeries({
            date: {
              gte: infoHeaderViewModel.multiViewModel.currentDate[0].format('YYYY-MM-DD'),
              lte: infoHeaderViewModel.multiViewModel.currentDate[1].format('YYYY-MM-DD')
            },
            tagKey: this.selectedTagKey
          });
          runInAction(() => {
            this.seriesItems = res.map((el) => ({
              value: el.id,
              label: el.name,
              chart: [],
              prevChart: []
            }));
            this.wordsChartViewModel.updateLevelOptions(this.seriesItems);
          });
        }
      }
      if (this.level === 'product') {
        if (this.productItems.length > 0) {
          this.wordsChartViewModel.updateLevelOptions(this.productItems);
          return;
        }
        const res = await ChartService.getTopTwentyProducts({
          date: {
            gte: infoHeaderViewModel.multiViewModel.currentDate[0].format('YYYY-MM-DD'),
            lte: infoHeaderViewModel.multiViewModel.currentDate[1].format('YYYY-MM-DD')
          }
        });
        runInAction(() => {
          this.productItems = res.map((el) => ({
            value: el.id,
            label: el.name,
            chart: [],
            prevChart: []
          }));
          this.wordsChartViewModel.updateLevelOptions(this.productItems);
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => [
        optionsStore.setCompleted('tops')
      ]);
    }
  };

  @action afterModalClose = async () => {
    optionsStore.setLoading('tops');
    // > 24/04/25 updating
    const checkLatest = await packageStore.checkLatest();
    if (checkLatest.needUpdate) {
      optionsStore.setCompleted('tops');
      await packageStore.syncLatest(checkLatest);
      return;
    }
    await this.getSource();
    try {
      if (this.level === 'brand') {
        if (this.brandItems.length > 0) {
          this.wordsChartViewModel.updateLevelOptions(this.brandItems);
          return;
        }
        const res = await ChartService.getTopTenBrands({
          date: {
            gte: infoHeaderViewModel.multiViewModel.currentDate[0].format('YYYY-MM-DD'),
            lte: infoHeaderViewModel.multiViewModel.currentDate[1].format('YYYY-MM-DD')
          }
        });
        runInAction(() => {
          this.brandItems = res.map((el) => ({
            value: el.id,
            label: el.name,
            chart: []
          }));
          this.wordsChartViewModel.updateLevelOptions(this.brandItems);
        });
      }
      if (this.level === 'series') {
        if (this.seriesItems.length > 0) {
          this.wordsChartViewModel.updateLevelOptions(this.seriesItems);
          return;
        }

        if (!this.selectedTagKey.trim()) {
          const once = reaction(
            () => this.selectedTagKey,
            async (tagKey) => {
              if (tagKey.trim()) {
                const res = await ChartService.getTopTenSeries({
                  date: {
                    gte: infoHeaderViewModel.multiViewModel.currentDate[0].format('YYYY-MM-DD'),
                    lte: infoHeaderViewModel.multiViewModel.currentDate[1].format('YYYY-MM-DD')
                  },
                  tagKey: this.selectedTagKey
                });
                runInAction(() => {
                  this.seriesItems = res.map((el) => ({
                    value: el.id,
                    label: el.name,
                    chart: [],
                    prevChart: []
                  }));
                  this.wordsChartViewModel.updateLevelOptions(this.seriesItems);
                });
                once();
              }
            }
          );
        } else {
          const res = await ChartService.getTopTenSeries({
            date: {
              gte: infoHeaderViewModel.multiViewModel.currentDate[0].format('YYYY-MM-DD'),
              lte: infoHeaderViewModel.multiViewModel.currentDate[1].format('YYYY-MM-DD')
            },
            tagKey: this.selectedTagKey
          });
          runInAction(() => {
            this.seriesItems = res.map((el) => ({
              value: el.id,
              label: el.name,
              chart: [],
              prevChart: []
            }));
            this.wordsChartViewModel.updateLevelOptions(this.seriesItems);
          });
        }
      }
      if (this.level === 'product') {
        if (this.productItems.length > 0) {
          this.wordsChartViewModel.updateLevelOptions(this.productItems);
          return;
        }
        const res = await ChartService.getTopTwentyProducts({
          date: {
            gte: infoHeaderViewModel.multiViewModel.currentDate[0].format('YYYY-MM-DD'),
            lte: infoHeaderViewModel.multiViewModel.currentDate[1].format('YYYY-MM-DD')
          }
        });
        runInAction(() => {
          this.productItems = res.map((el) => ({
            value: el.id,
            label: el.name,
            chart: [],
            prevChart: []
          }));
          this.wordsChartViewModel.updateLevelOptions(this.productItems);
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        optionsStore.setCompleted('tops');
      });
    }
  };

  @action afterDateUpdate = async () => {
    optionsStore.setLoading('tops');
    // > 24/04/25 updating
    const checkLatest = await packageStore.checkLatest();
    if (checkLatest.needUpdate) {
      optionsStore.setCompleted('tops');
      await packageStore.syncLatest(checkLatest);
      return;
    }
    await this.getSource();
    try {
      if (this.level === 'brand') {
        const res = await ChartService.getTopTenBrands({
          date: {
            gte: infoHeaderViewModel.multiViewModel.currentDate[0].format('YYYY-MM-DD'),
            lte: infoHeaderViewModel.multiViewModel.currentDate[1].format('YYYY-MM-DD')
          }
        });
        runInAction(() => {
          this.brandItems = res.map((el) => ({
            value: el.id,
            label: el.name,
            chart: []
          }));
          this.wordsChartViewModel.updateLevelOptions(this.brandItems);
        });
      }
      if (this.level === 'series') {
        if (!this.selectedTagKey.trim()) {
          const once = reaction(
            () => this.selectedTagKey,
            async (tagKey) => {
              if (tagKey.trim()) {
                const res = await ChartService.getTopTenSeries({
                  date: {
                    gte: infoHeaderViewModel.multiViewModel.currentDate[0].format('YYYY-MM-DD'),
                    lte: infoHeaderViewModel.multiViewModel.currentDate[1].format('YYYY-MM-DD')
                  },
                  tagKey: this.selectedTagKey
                });
                runInAction(() => {
                  this.seriesItems = res.map((el) => ({
                    value: el.id,
                    label: el.name,
                    chart: [],
                    prevChart: []
                  }));
                  this.wordsChartViewModel.updateLevelOptions(this.seriesItems);
                });
                once();
              }
            }
          );
        } else {
          const res = await ChartService.getTopTenSeries({
            date: {
              gte: infoHeaderViewModel.multiViewModel.currentDate[0].format('YYYY-MM-DD'),
              lte: infoHeaderViewModel.multiViewModel.currentDate[1].format('YYYY-MM-DD')
            },
            tagKey: this.selectedTagKey
          });
          runInAction(() => {
            this.seriesItems = res.map((el) => ({
              value: el.id,
              label: el.name,
              chart: [],
              prevChart: []
            }));
            this.wordsChartViewModel.updateLevelOptions(this.seriesItems);
          });
        }
      }
      if (this.level === 'product') {
        const res = await ChartService.getTopTwentyProducts({
          date: {
            gte: infoHeaderViewModel.multiViewModel.currentDate[0].format('YYYY-MM-DD'),
            lte: infoHeaderViewModel.multiViewModel.currentDate[1].format('YYYY-MM-DD')
          }
        });
        runInAction(() => {
          this.productItems = res.map((el) => ({
            value: el.id,
            label: el.name,
            chart: [],
            prevChart: []
          }));
          this.wordsChartViewModel.updateLevelOptions(this.productItems);
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        optionsStore.setCompleted('tops');
      });
    }
  };

  @action onPostTypeChange = (e) => {
    this.selectedPostType = e;
  };

  @action onWOMTypeChange = (e) => {
    this.selectedWOMType = e;
  };

  @action onSeriesParentSelect = async (e) => {
    this.selectedTagKey = e;

    // > 24/04/25 updating
    const checkLatest = await packageStore.checkLatest();
    if (checkLatest.needUpdate) {
      optionsStore.setCompleted('tops');
      await packageStore.syncLatest(checkLatest);
      return;
    }
    try {
      const res = await ChartService.getTopTenSeries({
        date: {
          gte: infoHeaderViewModel.multiViewModel.currentDate[0].format('YYYY-MM-DD'),
          lte: infoHeaderViewModel.multiViewModel.currentDate[1].format('YYYY-MM-DD')
        },
        tagKey: this.selectedTagKey
      });
      runInAction(() => {
        this.seriesItems = res.map((el) => ({
          value: el.id,
          label: el.name,
          chart: [],
          prevChart: []
        }));
        this.wordsChartViewModel.updateLevelOptions(this.seriesItems);
      });
      await this.wordsChartViewModel.getData(this.level, this.selectedCount);
    } catch (error) {
      console.log('error');
    }
  };

  @action onCleanStatisticFilter = () => {
    this.selectedPostType = 'all';
    this.selectedWOMType = 'nature';
    this.categoryPopover.onCleanAll();
  };

  @action onTypeChange = (e) => {
    this.selectedType = e;
  };

  @action onChartIndexChange = (e) => {
    this.selectedIndex = e;
  };

  @action onChartCountChange = (e) => {
    this.selectedCount = e;
  };

  @action onCleanChartFilter = () => {
    this.selectedIndex = 'frequency';
    this.selectedCount = 20;
  };

  @action onDownloadExcel = () => {
    this.isDownload = false;
    const { selectedType, selectedLevel } = this.wordsChartViewModel;
    const { currentDate, previousDate, currentTimeText, previousTimeText, hasCompare } = infoHeaderViewModel.multiViewModel;

    const csv = [];
    selectedLevel.chart.forEach((item) => {
      item.data?.forEach((word) => {
        csv.push({
          date: currentDate.map((c) => c.format('YYYY-MM-DD')).join('~'),
          type: RELATED_WORD_TYPE[item.name],
          word: word.name,
          count: word.originCount,
          score: word.score
        });
      });
      item.prevData?.forEach((word) => {
        csv.push({
          date: previousDate.map((c) => c.format('YYYY-MM-DD')).join('~'),
          type: RELATED_WORD_TYPE[item.name],
          word: word.name,
          count: word.originCount,
          score: word.score
        });
      });
    });
    const csvKey = [
      { key: 'date', header: '時間' },
      { key: 'type', header: '詞彙類型' },
      { key: 'word', header: '詞彙' },
      { key: 'count', header: '提及則數' },
      { key: 'score', header: '本期分數' }
    ];
    const data = [...csv];
    const header = csvKey.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [csvKey.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    const wordbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wordbook, workSheet, '關聯分析');
    xlsx.writeFile(wordbook, `${packageStore.activePackageName}-${selectedLevel.label}-${currentTimeText}${hasCompare ? `-${previousTimeText}` : ''}.xlsx`);
  };

  @action onDownload = async () => {
    const { selectedType, selectedLevel } = this.wordsChartViewModel;
    const { currentTimeText, previousTimeText, hasCompare } = infoHeaderViewModel.multiViewModel;
    await optionsStore.onImgDownload();
    const chart = document.querySelector('.download-chart');
    const canvas = await html2canvas(chart);
    const a = document.createElement('a');
    a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
    a.download = `${packageStore.activePackageName}-${selectedLevel.label}-${currentTimeText}${hasCompare ? `-${previousTimeText}` : ''}.jpg`;
    a.click();
    optionsStore.onImgDownloadEnd();
  };


  @action onStopModalChange = (bool) => {
    this.isStopModalOpen = bool;
  };

  @action onStopModalOpen = async () => {
    await this.getBlockedWords();
    this.onStopModalChange(true);
  };

  @action onStopModalClose = () => {
    this.onStopModalChange(false);

    if (this.blockWords.length !== this.cacheBlockWords.length) {
      this.afterDateUpdate();
    }
  };


  @action getBlockedWords = async () => {
    optionsStore.setLoading('blocked');
    try {
      const { list } = await BlockedWordsService.getBlockedWords();
      runInAction(() => {
        this.blockWords = list;
        this.cacheBlockWords = list;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        optionsStore.setCompleted('blocked');
      });
    }
  };

  @action onRemoveWord = async (id) => {
    try {
      await BlockedWordsService.removeBlockedWords(id);
      runInAction(() => {
        this.blockWords = this.blockWords.filter((el) => el.id !== id);
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action onSubmitChartFilter = () => {
    this.wordsChartViewModel.getData(this.level, this.selectedCount);
  };
}
