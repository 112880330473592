import IndustryTrend from './IndustryTrend';
import MarketDifferentiation from './MarketDifferentiation';
import BrandCompetition from './BrandCompetition';
import BrandHealth from './BrandHealth';
import BrandEffect from './BrandEffect';
import BrandRoutine from './BrandRoutine';

export default {
  IndustryTrend,
  MarketDifferentiation,
  BrandCompetition,
  BrandHealth,
  BrandEffect,
  BrandRoutine
};
