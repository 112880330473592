import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Button, Layout, Table } from 'antd';

import { SINGLE_OPINION_COMMENTS_TABLE } from 'src/consts/tables';
import ProjectLinkIconBlue from 'src/assets/ProjectLinkIconBlue.svg';

import SimpleHeader from 'src/components/SimpleHeader';

import OpinionSingleTopicPageViewModel from './viewModel';

import styles from './styles.module.scss';

@observer
class OpinionSingleTopicPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new OpinionSingleTopicPageViewModel();
  }
  render() {
    return (
      <Layout className={styles.pageContainer}>
        <SimpleHeader />
        <Layout.Content className={styles.contentContainer}>
          <div className={styles.topicContentContainer}>
            <div className={styles.header}>
              <div className={styles.headerLeft}>
                <div className={styles.infoContainer}>
                  <div className={styles.infoItem}>
                    {this.viewModel.hitPostType}
                  </div>
                </div>
              </div>
              <Button
                className={styles.urlBtn}
                icon={<img src={ProjectLinkIconBlue} alt="" className={styles.urlIcon} />}
                type="link"
                href={this.viewModel.postUrl}
                target="_blank"
              >
                {i18n.t('topic_article_url')}
              </Button>
            </div>
            <div className={styles.vocTitle}>
              {this.viewModel.vocTitle}
            </div>
            <div className={styles.vocInfo}>
              <div className={styles.infoItem}>
                {`${i18n.t('topic_card_source')}： ${this.viewModel.website}[${this.viewModel.channel}]`}
              </div>
              <div className={styles.infoItem}>
                {`${i18n.t('topic_card_author')}： ${this.viewModel.author}`}
              </div>
              <div className={styles.infoItem}>
                {`${i18n.t('topic_card_date')}： ${dayjs(this.viewModel.date).format('YYYY-MM-DD HH:mm:ss')}`}
              </div>
              <div className={styles.infoItem}>
                {`${i18n.t('topic_card_view_count')}： ${this.viewModel.viewCount}`}
              </div>
              <div className={styles.infoItem}>
                {`${i18n.t('topic_card_share_count')}： ${this.viewModel.shareCount}`}
              </div>
              <div className={styles.infoItem}>
                {`${i18n.t('topic_card_comment_count')}： ${this.viewModel.commentCount} ${i18n.t('topic_card_comment_count_unit')}`}
              </div>
            </div>
            <div className={styles.content}>
              {this.viewModel.content}
            </div>
          </div>
          <div className={styles.commentsContainer}>
            <div className={styles.statisticsContainer}>
              <div className={styles.total}>
                {`回文：${this.viewModel.totalComments}`}
              </div>
            </div>
            <div className={styles.tableContainer}>
              <Table
                key={this.viewModel.comments.length}
                rowClassName={styles.row}
                rowKey={(item) => item.order}
                dataSource={this.viewModel.comments}
                pagination={false}
                className="packagesTable"
              >
                {
                  SINGLE_OPINION_COMMENTS_TABLE.map((el) => {
                    if (el.key === 'content') {
                      return (
                        <Table.Column
                          title={el.title}
                          dataIndex={el.dataIndex}
                          key={el.key}
                          className={styles.columns}
                          render={(text, record) => {
                            return (
                              <div style={{ textAlign: 'left' }}>{text}</div>
                            );
                          }}
                        />
                      );
                    }
                    if (el.key === 'date') {
                      return (
                        <Table.Column
                          title={el.title}
                          dataIndex={el.dataIndex}
                          key={el.key}
                          className={styles.columns}
                          width={200}
                          render={(text, record) => {
                            if (!text) {
                              return <div>---</div>;
                            }
                            return <div>{dayjs(text).format('YYYY-MM-DD HH:mm:ss')}</div>;
                          }}
                        />
                      );
                    }
                    return (
                      <Table.Column
                        title={el.title}
                        dataIndex={el.dataIndex}
                        key={el.key}
                        className={styles.columns}
                        width={el.key === 'order' ? 56 : 200}
                        render={(text, record) => {
                          if (!text) {
                            return <div>---</div>;
                          }
                          return <div>{text}</div>;
                        }}
                      />
                    );
                  })
                }
              </Table>
            </div>
          </div>
        </Layout.Content>
      </Layout>
    );
  }
}

OpinionSingleTopicPage.propTypes = {

};

OpinionSingleTopicPage.defaultProps = {

};


export default OpinionSingleTopicPage;
