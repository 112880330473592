import MindShareModel from './mindShare/mindShare';
import StarProductModel from './starProduct/starProduct';
import BrandReputationModel from './reputation/brand';
import ProductReputationModel from './reputation/product';
import Radar7PModel from './marketing7P/Radar';
import WordCloud7PModel from './marketing7P/Words';

export default {
  MindShareModel,
  StarProductModel,
  BrandReputationModel,
  ProductReputationModel,
  Radar7PModel,
  WordCloud7PModel
};
