import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';

import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Annotation from 'chartjs-plugin-annotation';
import optionsStore from 'src/stores/optionsStore';

import { Collapse, Table, Switch } from 'antd';

import styles from './styles.module.scss';

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  ChartDataLabels,
  Annotation
);




@observer
class ScatterChart extends React.Component {
  _renderPanelIcon = (props) => {
    return (
      <Switch
        defaultChecked={false}
      />
    );
  };
  render() {
    const { viewModel } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.scatterContainer}>
          <Scatter
            key={viewModel.key}
            options={viewModel.options}
            data={viewModel.data}
            ref={viewModel.ref}
            plugins={viewModel.plugins}
          />
        </div>
        <Collapse
          collapsible="icon"
          expandIcon={this._renderPanelIcon}
          ghost
          className={styles.collapseContainer}
          style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
        // defaultActiveKey="table"
        >
          <Collapse.Panel
            key="table"
            className="wordsCollapse"
            header={i18n.t('wordbook_page_statistic_table_title')}
          >
            <div className={styles.tableContainer}>
              <Table
                rowClassName={styles.row}
                rowKey={(item) => item.ranking}
                dataSource={viewModel.table.data}
                pagination={false}
                className="packagesTable"
              // loading={viewModel.isProcess}
              >
                {
                  viewModel.table.template.map((el) => {
                    return (
                      <Table.Column
                        title={el.title}
                        dataIndex={el.dataIndex}
                        key={el.key}
                        className={styles.columns}
                        width={100 / viewModel.table.template.length}
                        render={(_, record) => {
                          return (
                            <div>
                              {record[el.dataIndex]}
                            </div>
                          );
                        }}
                      />
                    );
                  })
                }
              </Table>
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  }
}

ScatterChart.propTypes = {
  viewModel: PropTypes.object
};

ScatterChart.defaultProps = {
  viewModel: {}
};


export default ScatterChart;
