import API from 'src/apis';
import dataAnalysis from 'src/models/response/charts/dataAnalysis';
import packageStore from 'src/stores/packageStore';

export default class IndustryTrendService {

  static getPackageId = () => {
    return packageStore.activePackageId;
  };

  static async getIndustryVolumeTrend(params) {
    const res = await API.dataReport.industryTrend.getIndustryVolumeTrend(IndustryTrendService.getPackageId(), params);

    return dataAnalysis.IndustryTrend.IndustryVolumeTrendModel.fromRes(res.data.result, params);
  }

  static async getIndustrySourceChannelRanking(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.dataReport.industryTrend.getIndustrySourceChannelRanking(IndustryTrendService.getPackageId(), data),
      API.dataReport.industryTrend.getIndustrySourceChannelRanking(IndustryTrendService.getPackageId(), prevData)
    ]);

    return dataAnalysis.IndustryTrend.IndustrySourceChannelRankingModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getIndustryWords(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.dataReport.industryTrend.getIndustryWords(IndustryTrendService.getPackageId(), data),
      API.dataReport.industryTrend.getIndustryWords(IndustryTrendService.getPackageId(), prevData)
    ]);

    return {
      present: dataAnalysis.IndustryTrend.IndustryWords.fromRes(res.data.result, data),
      previous: dataAnalysis.IndustryTrend.IndustryWords.fromRes(prevRes.data.result, prevData)
    };
  }

  static async getConsumerIndustryFeature(data) {
    const res = await API.dataReport.industryTrend.getConsumerIndustryFeature(IndustryTrendService.getPackageId(), data);

    return dataAnalysis.IndustryTrend.ConsumerIndustryFeature.fromRes(res.data.result, data);
  }

  static async getBrandVolumeTrend(data) {
    const res = await API.dataReport.industryTrend.getBrandVolumeTrend(IndustryTrendService.getPackageId(), data);

    return dataAnalysis.IndustryTrend.BrandVolumeTrendModel.fromRes(res.data.result, data);
  }

  static async getSeriesVolumeTrend(data) {
    const res = await API.dataReport.industryTrend.getSeriesVolumeTrend(IndustryTrendService.getPackageId(), data);

    return dataAnalysis.IndustryTrend.SeriesVolumeTrendModel.fromRes(res.data.result, data);
  }

  static async getProductVolumeTrend(data) {
    const res = await API.dataReport.industryTrend.getProductVolumeTrend(IndustryTrendService.getPackageId(), data);

    return dataAnalysis.IndustryTrend.ProductVolumeTrendModel.fromRes(res.data.result, data);
  }

  static async getBrandReputation(data) {
    const res = await API.dataReport.industryTrend.getBrandReputation(IndustryTrendService.getPackageId(), data);

    return dataAnalysis.IndustryTrend.BrandReputationModel.fromRes(res.data.result, data);
  }

  static async getProductReputation(data) {
    const res = await API.dataReport.industryTrend.getProductReputation(IndustryTrendService.getPackageId(), data);

    return dataAnalysis.IndustryTrend.ProductReputationModel.fromRes(res.data.result, data);
  }

  static async getBrandSourceCategoryCompare(data) {
    const res = await API.dataReport.industryTrend.getBrandSourceCategoryCompare(IndustryTrendService.getPackageId(), data);

    return dataAnalysis.IndustryTrend.BrandSourceCategoryCompareModel.fromRes(res.data.result, data);
  }

  static async getBrandSentimentOverview(data) {
    const res = await API.dataReport.industryTrend.getBrandSentimentOverview(IndustryTrendService.getPackageId(), data);

    return dataAnalysis.IndustryTrend.BrandSentimentOverviewModel.fromRes(res.data.result, data);
  }
}
