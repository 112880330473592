import { request, getHost, getHeaders } from '../utils';

export const getIndustryVolumeTrend = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/industry/trend`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getIndustrySourceChannelRanking = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/industry/source/channel/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getIndustryWords = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/industry/feature/term`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getConsumerIndustryFeature = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/industry/feature/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandVolumeTrend = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/industry/brand/trend`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getSeriesVolumeTrend = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/industry/series/trend`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getProductVolumeTrend = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/industry/product/trend`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandReputation = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/industry/brand/reputation`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getProductReputation = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/industry/product/reputation`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandSourceCategoryCompare = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/industry/brand/source/category/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandSentimentOverview = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/industry/brand/sentiment`,
    headers: getHeaders(),
    data
  };
  return request(options);
};
