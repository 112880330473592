import BrandReputation from './BrandReputation';
import BrandVolumeTrend from './BrandVolumeTrend';
import BrandVolumeRanking from './BrandVolumeRanking';
import BrandVolumeProportion from './BrandVolumeProportion';
import BrandSeriesVolumeCompare from './BrandSeriesVolumeCompare';
import BranProductVolumeCompare from './BrandProductVolumeCompare';
import ProductVolumeRanking from './ProductVolumeRanking';
import BrandSourceCategoryCompare from './BrandSourceCategoryCompare';
import BrandSourceChannelCompare from './BrandSourceChannelCompare';
import BrandSentimentOverview from './BrandSentimentOverview';
import BrandPNWord from './BrandPNWord';
import BrandConsumerFeature from './BrandConsumerFeature';
import BrandWord from './BrandWord';

import BrandReputationViewModel from './BrandReputation/viewModel';
import BrandVolumeTrendViewModel from './BrandVolumeTrend/viewModel';
import BrandVolumeRankingViewModel from './BrandVolumeRanking/viewModel';
import BrandVolumeProportionViewModel from './BrandVolumeProportion/viewModel';
import BrandSeriesVolumeCompareViewModel from './BrandSeriesVolumeCompare/viewModel';
import BrandProductVolumeCompareViewModel from './BrandProductVolumeCompare/viewModel';
import ProductVolumeRankingViewModel from './ProductVolumeRanking/viewModel';
import BrandSourceCategoryCompareViewModel from './BrandSourceCategoryCompare/viewModel';
import BrandSourceChannelCompareViewModel from './BrandSourceChannelCompare/viewModel';
import BrandSentimentOverviewViewModel from './BrandSentimentOverview/viewModel';
import BrandPNWordViewModel from './BrandPNWord/viewModel';
import BrandConsumerFeatureViewModel from './BrandConsumerFeature/viewModel';
import BrandWordViewModel from './BrandWord/viewModel';

export default class BrandCompetitionFactory {
  static createChartViewModel(id, parent) {
    switch (id) {
      case 'brand_reputation':
        return new BrandReputationViewModel(parent);
      case 'brand_volume_trend':
        return new BrandVolumeTrendViewModel(parent);
      case 'brand_volume_ranking':
        return new BrandVolumeRankingViewModel(parent);
      case 'brand_volume_proportion':
        return new BrandVolumeProportionViewModel(parent);
      case 'brand_series_volume_compare':
        return new BrandSeriesVolumeCompareViewModel(parent);
      case 'brand_product_volume_compare':
        return new BrandProductVolumeCompareViewModel(parent);
      case 'product_volume_ranking':
        return new ProductVolumeRankingViewModel(parent);
      case 'brand_source_category_compare':
        return new BrandSourceCategoryCompareViewModel(parent);
      case 'brand_source_channel_compare':
        return new BrandSourceChannelCompareViewModel(parent);
      case 'brand_sentiment_overview':
        return new BrandSentimentOverviewViewModel(parent);
      case 'brand_top1_pn_word':
        return new BrandPNWordViewModel(parent, true);
      case 'brand_main_pn_word':
        return new BrandPNWordViewModel(parent, false);
      case 'brand_consumer_feature':
        return new BrandConsumerFeatureViewModel(parent);
      case 'brand_top1_word':
        return new BrandWordViewModel(parent, true);
      case 'brand_main_word':
        return new BrandWordViewModel(parent, false);
      default:
        return null;
    }
  }

  static createChartView(item) {
    const { id, viewModel } = item;

    switch (id) {
      case 'brand_reputation':
        return (<BrandReputation key={id} viewModel={viewModel} />);
      case 'brand_volume_trend':
        return (<BrandVolumeTrend key={id} viewModel={viewModel} />);
      case 'brand_volume_ranking':
        return (<BrandVolumeRanking key={id} viewModel={viewModel} />);
      case 'brand_volume_proportion':
        return (<BrandVolumeProportion key={id} viewModel={viewModel} />);
      case 'brand_series_volume_compare':
        return (<BrandSeriesVolumeCompare key={id} viewModel={viewModel} />);
      case 'brand_product_volume_compare':
        return (<BranProductVolumeCompare key={id} viewModel={viewModel} />);
      case 'product_volume_ranking':
        return (<ProductVolumeRanking key={id} viewModel={viewModel} />);
      case 'brand_source_category_compare':
        return (<BrandSourceCategoryCompare key={id} viewModel={viewModel} />);
      case 'brand_source_channel_compare':
        return (<BrandSourceChannelCompare key={id} viewModel={viewModel} />);
      case 'brand_sentiment_overview':
        return (<BrandSentimentOverview key={id} viewModel={viewModel} />);
      case 'brand_top1_pn_word':
      case 'brand_main_pn_word':
        return (<BrandPNWord key={id} viewModel={viewModel} />);
      case 'brand_consumer_feature':
        return (<BrandConsumerFeature key={id} viewModel={viewModel} />);
      case 'brand_top1_word':
      case 'brand_main_word':
        return (<BrandWord key={id} viewModel={viewModel} />);
      default:
        return null;
    }
  }
}
