import { COLOR_SET } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import { SOURCE_CATEGORY_TYPE } from 'src/consts';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { generateCSVKey } from 'src/utils/chart';

export default class SeriesSourceChannelRankingModel {
  constructor(data, prevData, query) {
    if (data.length === 0 && prevData.length === 0) {
      this.data = {
        chartData: [{
          id: 'series_source_channel_ranking',
          name: '系列',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }]
        }]
      };
      return;
    }

    const combine = SeriesSourceChannelRankingModel.getCombineData(data, prevData);
    const currTime = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD')).join('~');
    const prevTime = infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD')).join('~');
    const csvForAll = [];
    combine.forEach((series) => {
      const targetInCurrent = data?.find((el) => series.id === el.id);
      const targetInPrevious = prevData?.find((el) => series.id === el.id);
      const csv = SeriesSourceChannelRankingModel.generateCSV(targetInCurrent, targetInPrevious);
      const csvKey = generateCSVKey(csv[0]);
      csvForAll.push({
        id: series.id,
        name: series.name,
        csv,
        csvKey
      });
    });

    this.data = {
      chartData: combine.slice(0, 10).map((series) => {
        const targetInCurrent = data?.find((el) => series.id === el.id);
        const currentEmpty = targetInCurrent?.item.every((item) => item.count === 0) ?? true;
        const targetInPrevious = prevData?.find((el) => series.id === el.id);
        const previousEmpty = targetInPrevious?.item.every((item) => item.count === 0) ?? true;
        const { csv, csvKey } = csvForAll.find((el) => el.id === series.id);

        return {
          id: series.id,
          name: series.name,
          data: [
            ...(
              !(currentEmpty && previousEmpty)
                ? [{
                  labels: series.item.map((item) => item.name),
                  datasets: [
                    ...(
                      !currentEmpty
                        ? [{
                          label: currTime,
                          data: series.item.map((c) => c.count),
                          channel: series.item.map((item) => item.id),
                          backgroundColor: COLOR_SET[0],
                          borderSkipped: true,
                          categoryPercentage: 0.2
                        }]
                        : []
                    ),
                    ...(
                      !previousEmpty
                        ? [{
                          label: prevTime,
                          data: series.item.map((c) => c.prevCount),
                          channel: series.item.map((item) => ({ id: item.id, name: item.name })),
                          backgroundColor: COLOR_SET[19],
                          borderSkipped: true,
                          categoryPercentage: 0.2
                        }]
                        : []
                    )
                  ]
                }]
                : [{
                  type: 'nodata'
                }]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType,
                category,
                tagKey
              } = query;
              const { datasetIndex, index } = elements[0];
              const channel = chart.data.datasets[datasetIndex].channel[index];
              const [gte, lte] = chart.data.datasets[datasetIndex].label.split('~');
              const womCount = chart.data.datasets[datasetIndex].data[index];

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category && {
                  category: category.map((c) => ({
                    id: c,
                    name: SOURCE_CATEGORY_TYPE[c]
                  }))
                }),
                channel: [{
                  id: channel.id,
                  name: channel.name
                }],
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/系列/來源分析/系列來源頻道/系列來源頻道排行'
                },
                series: [{
                  id: series.id,
                  name: series.name,
                  tagKey,
                  tagKeyName: packageStore.series.find((el) => el.id === tagKey).name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'bar',
          options: {
            event: ['click'],
            scales: {
              y: {
                grace: 5,
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        };
      }),
      csvForAll
    };
  }

  static getCombineData = (list, prevList) => {
    const combine = list?.map((el) => ({
      ...el,
      item: el.item.slice(0, 20).map((item) => ({
        ...item,
        prevCount: 0
      }))
    }));
    prevList.forEach((el) => {
      const itemInCombine = combine.find((com) => com.id === el.id);
      if (itemInCombine) {
        el.item.forEach((prevItem) => {
          const target = itemInCombine.item.find((t) => t.name === prevItem.name);
          if (target) {
            target.prevCount = prevItem.count;
          }
          // else {
          //   itemInCombine.item.push({ id: prevItem.id, name: prevItem.name, count: 0, prevCount: prevItem.count });
          // }
        });
      }
      // } else {
      //   combine.push({
      //     ...el,
      //     item: el.item.map((item) => ({
      //       id: item.id,
      //       name: item.name,
      //       count: 0,
      //       prevCount: item.count
      //     }))
      //   });
      // }
    });

    combine.forEach((el) => {
      el.item.sort((a, b) => b.count - a.count);
    });

    return combine;
  };

  static generateCSV = (curr, prev) => {
    const csv = [];

    if (curr) {
      const time = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'));
      const row = {
        date: time.join('~')
      };
      curr.item.forEach((el) => {
        row[`${el.name}`] = el.count;
      });
      csv.push(row);
    }

    if (prev) {
      const time = infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD'));
      const row = {
        date: time.join('~')
      };
      prev.item.forEach((el) => {
        row[`${el.name}`] = el.count;
      });
      csv.push(row);
    }

    return csv;
  };

  static fromRes(data, prevData, query) {
    return new SeriesSourceChannelRankingModel(data, prevData, query);
  }
}
