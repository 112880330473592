import i18n from 'src/i18n';

// > {
// >  title: String 欄位名稱
// >  dataIndex: String 對應資料index
// >  key: String key
// >  render: function 客製化資料render
// > }

export const ACCOUNT_TABLE = [
  {
    title: i18n.t('user_sso_identity_title'),
    dataIndex: 'userSSOIdentity',
    key: 'userSSOIdentity'
  },
  {
    title: i18n.t('account_manage_column_title_account_name'),
    dataIndex: 'userName',
    key: 'userName'
  },
  {
    title: i18n.t('account_manage_column_title_account'),
    dataIndex: 'userAccount',
    key: 'userAccount'
  },
  {
    title: i18n.t('user_sso_status_title'),
    dataIndex: 'userSSOStatus',
    key: 'userSSOStatus'
  },
  {
    title: i18n.t('account_manage_column_title_dep_employee'),
    dataIndex: 'employeeDep',
    key: 'employeeDep'
  },
  {
    title: i18n.t('account_manage_column_title_dep_customer'),
    dataIndex: 'customerDep',
    key: 'customerDep'
  },
  {
    title: i18n.t('user_voc_login_auth_title'),
    dataIndex: 'vocLoginAuth',
    key: 'vocLoginAuth'
  },
  {
    title: i18n.t('account_manage_column_title_voc_identity'),
    dataIndex: 'vocIdentity',
    key: 'vocIdentity'
  },
  {
    title: i18n.t('account_manage_column_title_salesman'),
    dataIndex: 'salesman',
    key: 'salesman'
  },
  {
    title: i18n.t('account_manage_column_title_last_adjust_time'),
    dataIndex: 'lastAdjustTime',
    key: 'lastAdjustTime'
  },
  {
    title: i18n.t('account_manage_column_title_aciton'),
    dataIndex: 'action',
    key: 'action'
  }
];

export const PACKAGES_TABLE = [
  {
    title: i18n.t('packages_table_package_name'),
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: i18n.t('packages_table_package_holder'),
    dataIndex: 'holder',
    key: 'holder'
  },
  {
    title: i18n.t('packages_table_package_users'),
    dataIndex: 'users',
    key: 'users'
  },
  {
    title: i18n.t('packages_table_establish_time'),
    dataIndex: 'establishTime',
    key: 'establishTime'
  },
  {
    title: '專案分析狀態\n(分析執行時間)',
    dataIndex: 'projectStatus',
    key: 'projectStatus'
  },
  {
    title: '數據包分析狀態\n(分析執行時間)',
    dataIndex: 'packageStatus',
    key: 'packageStatus'
  },
  {
    title: i18n.t('packages_table_adjust_time'),
    dataIndex: 'adjustTime',
    key: 'adjustTime'
  },
  {
    title: i18n.t('packages_table_action'),
    dataIndex: 'action',
    key: 'action'
  }
];

export const DELETE_PACKAGES_TABLE = [
  {
    title: i18n.t('packages_table_package_name'),
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: i18n.t('packages_table_package_holder'),
    dataIndex: 'holder',
    key: 'holder'
  },
  {
    title: i18n.t('packages_table_package_users'),
    dataIndex: 'users',
    key: 'users'
  },
  {
    title: i18n.t('packages_table_adjust_time'),
    dataIndex: 'adjustTime',
    key: 'adjustTime'
  },
  {
    title: i18n.t('packages_table_action'),
    dataIndex: 'action',
    key: 'action'
  }
];

export const SINGLE_TOPIC_COMMENTS_TABLE = [
  {
    title: i18n.t('topic_comment_table_sentiment'),
    dataIndex: 'sentiment',
    key: 'sentiment'
  },
  {
    title: i18n.t('topic_comment_table_number'),
    dataIndex: 'order',
    key: 'order'
  },
  {
    title: i18n.t('topic_comment_table_author'),
    dataIndex: 'author',
    key: 'author'
  },
  {
    title: i18n.t('topic_comment_table_content'),
    dataIndex: 'content',
    key: 'content'
  },
  {
    title: i18n.t('topic_comment_table_date'),
    dataIndex: 'date',
    key: 'date'
  }
];

export const SINGLE_OPINION_COMMENTS_TABLE = [
  {
    title: i18n.t('topic_comment_table_number'),
    dataIndex: 'order',
    key: 'order'
  },
  {
    title: i18n.t('topic_comment_table_author'),
    dataIndex: 'author',
    key: 'author'
  },
  {
    title: i18n.t('topic_comment_table_content'),
    dataIndex: 'content',
    key: 'content'
  },
  {
    title: i18n.t('topic_comment_table_date'),
    dataIndex: 'date',
    key: 'date'
  }
];

export const ANNOUNCEMENT_SETTING_TABLE = [
  {
    title: i18n.t('home_announcement_table_is_show'),
    dataIndex: 'isShow',
    key: 'isShow'
  },
  {
    title: i18n.t('home_announcement_table_date'),
    dataIndex: 'date',
    key: 'date'
  },
  {
    title: i18n.t('home_announcement_table_content'),
    dataIndex: 'text',
    key: 'text'
  },
  {
    title: i18n.t('home_table_actions'),
    dataIndex: 'action',
    key: 'action'
  }
];

export const SERVICE_SETTING_TABLE = [
  {
    title: i18n.t('home_service_table_date'),
    dataIndex: 'date',
    key: 'date'
  },
  {
    title: i18n.t('home_service_table_name'),
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: i18n.t('home_service_table_url'),
    dataIndex: 'url',
    key: 'url'
  },
  {
    title: i18n.t('home_table_actions'),
    dataIndex: 'action',
    key: 'action'
  }
];

export const BULLETIN_TABLE = [
  {
    title: i18n.t('bulletin_table_name_title'),
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: i18n.t('bulletin_table_time_title'),
    dataIndex: 'date',
    key: 'date'
  },
  {
    title: i18n.t('bulletin_table_frequency_title'),
    dataIndex: 'frequency',
    key: 'frequency'
  },
  {
    title: i18n.t('bulletin_table_action_title'),
    dataIndex: 'action',
    key: 'action'
  }
];

export const WORDS_TABLE = [
  {
    title: i18n.t('wordbook_page_table_ranking'),
    dataIndex: 'ranking',
    key: 'ranking'
  },
  {
    title: i18n.t('wordbook_page_table_hotword_title'),
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '提及則數',
    dataIndex: 'originCount',
    key: 'originCount'
  }
];

export const WORDS_TABLE_SCORE = [
  {
    title: i18n.t('wordbook_page_table_ranking'),
    dataIndex: 'ranking',
    key: 'ranking'
  },
  {
    title: '新詞',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '新詞分數',
    dataIndex: 'newScore',
    key: 'newScore'
  },
  {
    title: '提及則數',
    dataIndex: 'originCount',
    key: 'originCount'
  }
];

export const WORDS_TABLE_COMPARE = [
  {
    title: i18n.t('wordbook_page_table_ranking'),
    dataIndex: 'ranking',
    key: 'ranking'
  },
  {
    title: i18n.t('wordbook_page_table_hotword_title'),
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: i18n.t('wordbook_page_table_hotword_frequency_title'),
    dataIndex: 'originCount',
    key: 'originCount'
  },
  {
    title: i18n.t('wordbook_page_table_hotword_prev_frequency_title'),
    dataIndex: 'originCountCompare',
    key: 'originCountCompare'
  }
];

export const BRAND_MIND_SHARE_TABLE = [
  {
    title: i18n.t('wordbook_page_table_ranking'),
    dataIndex: 'ranking',
    key: 'ranking'
  },
  {
    title: i18n.t('common_brand'),
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '擁護比',
    dataIndex: 'support',
    key: 'support'
  },
  {
    title: '提及比',
    dataIndex: 'mention',
    key: 'mention'
  }
];

export const BRAND_REPUTATION_TABLE = [
  {
    title: i18n.t('wordbook_page_table_ranking'),
    dataIndex: 'ranking',
    key: 'ranking'
  },
  {
    title: i18n.t('common_brand'),
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '聲量',
    dataIndex: 'wom',
    key: 'wom'
  },
  {
    title: 'PN值',
    dataIndex: 'pn',
    key: 'pn'
  }
];

export const PRODUCT_REPUTATION_TABLE = [
  {
    title: i18n.t('wordbook_page_table_ranking'),
    dataIndex: 'ranking',
    key: 'ranking'
  },
  {
    title: i18n.t('common_product'),
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '聲量',
    dataIndex: 'wom',
    key: 'wom'
  },
  {
    title: 'PN值',
    dataIndex: 'pn',
    key: 'pn'
  }
];

export const BRAND_SERIES_WGS = [
  {
    title: i18n.t('wordbook_page_table_ranking'),
    dataIndex: 'ranking',
    key: 'ranking'
  },
  {
    title: i18n.t('common_brand'),
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '聲量成長率',
    dataIndex: 'wgr',
    key: 'wgr'
  },
  {
    title: '聲量市占率',
    dataIndex: 'ws',
    key: 'ws'
  },
  {
    title: '聲量佔比',
    dataIndex: 'wr',
    key: 'wr'
  }
];
