import React from 'react';
import { Tooltip as AntdTooltip, Button } from 'antd';
import { DownloadOutlined, SaveOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import optionsStore from 'src/stores/optionsStore';
import i18n from 'src/i18n';

import styles from './styles.module.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

@observer
class DayTrend extends React.Component {
  render() {
    const { viewModel } = this.props;
    if (!viewModel.chartData || !viewModel.options) {
      return null;
    }
    return (
      <div className={styles.chartContainer}>
        <div className={clsx('opinion-day-trend-chart', styles.download)}>
          <div className={styles.chartHeader}>
            <div className={styles.headerTitle}>
              {i18n.t('opinion_chart_day_trend')}
            </div>
            <div className={styles.downloadActions}>
              <AntdTooltip
                title="下載數據"
                placement="bottom"
              >
                <Button
                  icon={<DownloadOutlined />}
                  onClick={viewModel.onDownloadExcel}
                  style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
                />
              </AntdTooltip>
              <AntdTooltip
                title="下載圖檔"
                placement="bottom"
              >
                <Button
                  icon={<SaveOutlined />}
                  onClick={viewModel.onDownload}
                  style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
                />
              </AntdTooltip>
            </div>
          </div>
          <div className={styles.timeStamp}>
            {`日期範圍：${viewModel.searchData.date.gte} ~ ${viewModel.searchData.date.lte}`}
          </div>
          <div
            style={{ width: '100%', display: 'flex', aspectRatio: 3 }}
          >
            <Line
              key={`${viewModel.searchData.keyword}_day_trend`}
              options={viewModel.options}
              data={viewModel.chartData}
            />
          </div>
        </div>
      </div>
    );
  }
}

DayTrend.propTypes = {
  viewModel: PropTypes.object
};

DayTrend.defaultProps = {
  viewModel: {}
};

export default DayTrend;
