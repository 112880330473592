import API from 'src/apis';
import dataAnalysis from 'src/models/response/charts/dataAnalysis';
import packageStore from 'src/stores/packageStore';

export default class BrandHealthService {

  static getPackageId = () => {
    return packageStore.activePackageId;
  };

  static async getBrandVolumeTrend(data, brand) {
    const res = await API.dataReport.brandHealth.getBrandVolumeTrend(BrandHealthService.getPackageId(), data);

    return dataAnalysis.BrandHealth.BrandVolumeTrendModel.fromRes(res.data.result, { ...data, ...brand });

  }

  static async getBrandSeriesWGS(data, brand) {
    const res = await API.dataReport.brandHealth.getBrandSeriesWGS(BrandHealthService.getPackageId(), data);

    return dataAnalysis.BrandHealth.BrandSeriesWGSModel.fromRes(res.data.result, { ...data, ...brand });

  }

  static async getBrandProductVolumeRanking(data, brand) {
    const res = await API.dataReport.brandHealth.getBrandProductVolumeRanking(BrandHealthService.getPackageId(), data);

    return dataAnalysis.BrandHealth.BrandProductVolumeRankingModel.fromRes(res.data.result, { ...data, ...brand });

  }

  static async getBrandSourceCategoryProportion(data, brand) {
    const res = await API.dataReport.brandHealth.getBrandSourceCategoryProportion(BrandHealthService.getPackageId(), data);

    return dataAnalysis.BrandHealth.BrandSourceCategoryProportionModel.fromRes(res.data.result, { ...data, ...brand });

  }

  static async getBrandSourceChannelRanking(data, brand) {
    const res = await API.dataReport.brandHealth.getBrandSourceChannelRanking(BrandHealthService.getPackageId(), data);

    return dataAnalysis.BrandHealth.BrandSourceChannelRankingModel.fromRes(res.data.result, { ...data, ...brand });

  }

  static async getBrandSentimentOverview(data, brand) {
    const res = await API.dataReport.brandHealth.getBrandSentimentOverview(BrandHealthService.getPackageId(), data);

    return dataAnalysis.BrandHealth.BrandSentimentOverviewModel.fromRes(res.data.result, { ...data, ...brand });

  }

  static async getBrandSentimentWord(data, brand) {
    const res = await API.dataReport.brandHealth.getBrandSentimentWord(BrandHealthService.getPackageId(), data);

    return dataAnalysis.BrandHealth.BrandSentimentWordModel.fromRes(res.data.result, { ...data, ...brand });

  }

  static async getBrandConsumerFeature(data, brand) {
    const res = await API.dataReport.brandHealth.getBrandConsumerFeature(BrandHealthService.getPackageId(), data);

    return dataAnalysis.BrandHealth.BrandConsumerFeature.fromRes(res.data.result, { ...data, ...brand });

  }

  static async getBrandTopsFeatureWord(data, brand) {
    const res = await API.dataReport.brandHealth.getBrandTopsFeatureWord(BrandHealthService.getPackageId(), data);

    return dataAnalysis.BrandHealth.BrandTopsFeatureWordModel.fromRes(res.data.result, { ...data, ...brand });

  }
}
