import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { message } from 'antd';
import OtherResourceService from 'src/services/otherResource';

export default class ServiceItemViewModel {
  id = '';
  @observable date = '';
  @observable dateCache = '';
  @observable name = '';
  @observable nameCache = '';
  @observable url = '';
  @observable urlCache = '';
  @observable isEdit = false;

  @computed get canSave() {
    return this.name !== this.nameCache || this.date !== this.dateCache || this.url !== this.urlCache;
  }

  constructor(data) {
    makeObservable(this);

    this.init(data);
  }

  @action init = (data) => {
    const {
      id,
      name,
      date,
      url
    } = data;

    this.id = id ?? 'newPost';
    this.name = name;
    this.nameCache = name;
    this.date = date;
    this.dateCache = date;
    this.url = url;
    this.urlCache = url;
  };

  @action onDateChange = (date) => {
    this.date = date.toISOString();
  };

  @action onNameChange = (e) => {
    this.name = e.target.value;
  };

  @action onUrlChange = (e) => {
    this.url = e.target.value;
  };

  @action onEdit = () => {
    this.isEdit = true;
  };

  @action onCancel = (disposeNew = () => { }) => {
    if (this.id !== 'newPost') {
      this.name = this.nameCache;
      this.dateCache = this.date;
      this.url = this.urlCache;

      this.isEdit = false;
    } else {
      disposeNew();
    }

  };

  @action onSave = async (refresh) => {
    try {

      if (!this.name.trim() || !this.url.trim()) {
        message.error('資料不正, 請重新確認！');
        return;
      }

      // const generalUrl = /^(?:(?:http|https):\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z.]{2,}(?:\/[a-zA-Z0-9#?=&\/.-]*)?$/;
      const videoUrl = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be|vimeo\.com|dailymotion\.com|twitch\.tv|facebook\.com\/(watch|video))\/(?:embed\/|watch\?v=|v\/|channels\/(?:\w+\/)?|videos\/|video\/|channel\/UC(?:\w+\/)?)([\w-]{11})(?:[\?&]t=\d+m?\d*s?)?$/;


      if (!(videoUrl.test(this.url))) {
        message.error('不正確的URL, 請重新確認！(請確認是否為正確的影音網址)');
        return;
      }

      const data = {
        title: this.name,
        date: this.date,
        link: this.url
      };

      if (!this.canSave) {
        return;
      }

      if (this.id !== 'newPost') {
        await OtherResourceService.putOtherResource(this.id, data);
        runInAction(() => {
          this.nameCache = this.name;
          this.dateCache = this.date;
          this.urlCache = this.url;

          this.isEdit = false;
        });

      } else {
        await OtherResourceService.createOtherResource(data);
        await refresh();

      }

    } catch (error) {
      console.log('error', error);
    }
  };
}
