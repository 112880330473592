import SeriesSourceChannelTrendModel from './Trend';
import SeriesSourceChannelRankingModel from './Ranking';
import SeriesSourceChannelProportionModel from './Proportion';
import SeriesSourceChannelCompareModel from './Compare';

export default {
  SeriesSourceChannelTrendModel,
  SeriesSourceChannelRankingModel,
  SeriesSourceChannelProportionModel,
  SeriesSourceChannelCompareModel
};
