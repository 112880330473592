import { request, getHost, getHeaders } from './utils';

export const getBlockedWords = (pid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/dataPackage/${pid}/blockedWords`,
    headers: getHeaders()
  };
  return request(options);
};

export const addBlockedWord = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/dataPackage/${pid}/blockedWords`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const removeBlockedWord = (pid, wid) => {
  const options = {
    method: 'delete',
    url: `${getHost()}/api/v1/dataPackage/${pid}/blockedWords/${wid}`,
    headers: getHeaders()
  };
  return request(options);
};

