import { COLOR_SET, WORD_CONFIG } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';

export default class ProductWordCloud {
  constructor(data, prevData) {
    const productList = data.map((el) => ({
      id: el.id,
      name: el.name
    }));

    // prevData.forEach((el) => {
    //   const target = productList.find((product) => product.id === el.id);
    //   if (!target) {
    //     productList.push({
    //       id: el.id,
    //       name: el.name
    //     });
    //   }
    // });

    const featureColorSets = {};
    data.forEach((parent) => {
      parent.item.forEach((item) => {
        const color = featureColorSets[item.id];
        if (!color) {
          featureColorSets[`${item.id}`] = COLOR_SET[Object.keys(featureColorSets).length % 20];
        }
      });
    });

    prevData.forEach((parent) => {
      parent.item.forEach((item) => {
        const color = featureColorSets[item.id];
        if (!color) {
          featureColorSets[`${item.id}`] = COLOR_SET[Object.keys(featureColorSets).length % 20];
        }
      });
    });

    const csvForAll = [];

    const res = productList.map((product) => {
      const targetInCurrent = data.find((el) => el.id === product.id);
      const targetInPrevious = prevData.find((el) => el.id === product.id);
      const colorSets = {};
      const groupSets = {};
      const prevColorSets = {};

      targetInCurrent?.item.forEach((group, i) => {
        if (group.name === '全部') {
          return;
        }
        group.item.forEach((item) => {
          colorSets[`${item.id}`] = featureColorSets[group.id];
          groupSets[`${item.id}`] = {
            id: group.id,
            name: group.name
          };
        });
      });

      targetInPrevious?.item.forEach((group, i) => {
        if (group.name === '全部') {
          return;
        }
        group.item.forEach((item) => {
          prevColorSets[`${item.id}`] = featureColorSets[group.id];
          groupSets[`${item.id}`] = {
            id: group.id,
            name: group.name
          };
        });
      });

      const generateResult = (value, colorSet) => {
        const result = value.item.map((group) => {
          const maxNum = Math.max(...group.item.map((item) => item.count));
          const minNum = Math.min(...group.item.map((item) => item.count));

          const getNew = (num) => {
            // FOR ONLY ONE SITUATION
            if (maxNum === minNum) {
              return WORD_CONFIG[size].basicSize * WORD_CONFIG[size].maxRatio;
            }
            return ((num - minNum) * (WORD_CONFIG[size].maxRatio / (maxNum - minNum)) + 1) * WORD_CONFIG[size].basicSize;
          };

          const size = (() => {
            const length = value.item[0].item.length;
            if (length <= 20) {
              return 'small';
            }
            if (length > 20 && length <= 50) {
              return 'medium';
            }
            return 'large';
          })();

          const newItem = group.item.map((el) => ({
            id: el.id,
            name: el.name,
            count: getNew(el.count),
            originCount: el.count,
            color: colorSet[el.id],
            group: groupSets[el.id]
          }));

          return {
            id: group.id.trim() ? group.id : 'all',
            name: group.name === '全部' ? '不分類' : group.name,
            item: newItem
          };
        });

        const allItem = result.find((el) => el.id === 'all');

        if (allItem) {
          result.pop();
          result.unshift(allItem);
        }

        return result;
      };

      const currentResult = targetInCurrent
        ? generateResult(targetInCurrent, colorSets)
        : null;

      const previousResult = targetInPrevious
        ? generateResult(targetInPrevious, prevColorSets)
        : null;

      const { currentDate, previousDate } = infoHeaderViewModel.multiViewModel;
      const currentTime = currentDate.map((el) => el.format('YYYY-MM-DD'));
      const prevTime = previousDate.map((el) => el.format('YYYY-MM-DD'));

      const csv = [];
      currentResult?.forEach((group) => {
        group.item.forEach((item) => {
          csv.push({
            date: currentTime.join('~'),
            group: group.name,
            word: item.name,
            count: item.originCount
          });
        });
      });
      previousResult?.forEach((group) => {
        group.item.forEach((item) => {
          csv.push({
            date: prevTime.join('~'),
            group: group.name,
            word: item.name,
            count: item.originCount
          });
        });
      });

      const csvKey = [
        { key: 'date', header: '日期' },
        { key: 'group', header: '特性分類' },
        { key: 'word', header: '特性關鍵字' },
        { key: 'count', header: '提及則數' }
      ];

      csvForAll.push({
        id: product.id,
        name: product.name,
        csv,
        csvKey
      });

      return {
        id: product.id,
        name: product.name,
        item: currentResult,
        prevItem: previousResult,
        csv,
        csvKey
      };
    });

    this.data = {
      data: res.slice(0, 20),
      type: 'words',
      csvForAll
    };
  }

  static fromRes(data, prevData) {
    return new ProductWordCloud(data, prevData);
  }
}
