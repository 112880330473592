import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { CloseOutlined, CheckOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Button, Popover, DatePicker, Select, Checkbox } from 'antd';

import { DATE_TYPE } from 'src/consts';

import styles from './styles.module.scss';

@observer
class MultiPeriodDatePicker extends React.Component {
  componentDidMount() {
    this.props.viewModel.didMount();
  }
  componentWillUnmount() {
    this.props.viewModel.willUnmount();
  }
  render() {
    const { viewModel, callback, isMulti } = this.props;
    return (
      <Popover
        placement="bottomRight"
        open={viewModel.open}
        trigger="click"
        onOpenChange={viewModel.onOpenChange}
        content={(
          <div className={styles.pickerPopover}>
            <div className={styles.selectRow}>
              <div className={styles.selectTitle}>
                日期範圍
              </div>
              <Select
                options={DATE_TYPE}
                value={viewModel.currentSelected}
                onSelect={viewModel.onCurrentSelect}
                popupMatchSelectWidth={false}
                popupClassName={styles.sortMenu}
                className={clsx('sortSelect', styles.filterSelect)}
                suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
              />
            </div>
            <DatePicker.RangePicker
              className={styles.picker}
              value={viewModel.currentDate}
              changeOnBlur
              allowClear
              onCalendarChange={viewModel.onCurrentCalendarChange}
              cellRender={isMulti && viewModel.dateRender}
              disabledDate={(current) => {
                return current < dayjs(viewModel.disabledDateRange[0]).startOf('day')
                  || current > dayjs(viewModel.disabledDateRange[1]).endOf('day')
                  || current > dayjs().endOf('day')
                  || (viewModel.currentDate?.[0] && current > viewModel.currentDate?.[0].add(548, 'day').startOf('day'))
                  || (viewModel.currentDate?.[1] && current < viewModel.currentDate?.[1].subtract(548, 'day'));
              }}
            />
            {
              isMulti
              && (
                <>
                  <div className={styles.selectRow}>
                    <Checkbox
                      checked={viewModel.hasCompare}
                      onChange={viewModel.toggleChecked}
                    />
                    <div className={styles.selectTitle}>
                      相較於
                    </div>
                    <Select
                      options={DATE_TYPE}
                      value={viewModel.previousSelected}
                      onSelect={viewModel.onPreviousSelect}
                      popupMatchSelectWidth={false}
                      popupClassName={styles.sortMenu}
                      className={clsx('sortSelect', styles.filterSelect)}
                      suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                      disabled={!viewModel.hasCompare}
                    />
                  </div>
                  <DatePicker.RangePicker
                    className={styles.picker}
                    value={viewModel.previousDate}
                    changeOnBlur
                    allowClear
                    onCalendarChange={viewModel.onPreviousCalendarChange}
                    disabled={!viewModel.hasCompare}
                    cellRender={isMulti && viewModel.dateRender}
                    disabledDate={(current) => {
                      return current < dayjs(viewModel.disabledDateRange[0]).startOf('day')
                        || current > dayjs(viewModel.disabledDateRange[1]).endOf('day')
                        || current > dayjs().endOf('day')
                        || (viewModel.previousDate?.[0] && current > viewModel.previousDate?.[0].add(548, 'day').startOf('day'))
                        || (viewModel.previousDate?.[1] && current < viewModel.previousDate?.[1].subtract(548, 'day'));
                    }}
                  />
                </>
              )
            }
            <div className={styles.actionContainer}>
              <Button
                className={clsx(styles.btn, styles.cancel)}
                onClick={viewModel.onCancel}
                icon={<CloseOutlined />}
              >
                取消
              </Button>
              <Button
                className={clsx(styles.btn, styles.submit)}
                onClick={() => viewModel.onConfirm(callback)}
                icon={<CheckOutlined />}
              >
                套用
              </Button>
            </div>
          </div>
        )}
      >
        <div className={clsx(styles.selector)}>
          <div className={styles.currentText}>
            <div className={clsx(styles.text)}>
              {`${viewModel.currentDateCache?.[0].format('YYYY年 MM月 DD日')} ~ ${viewModel.currentDateCache?.[1].format('YYYY年 MM月 DD日')} `}
            </div>
            <CaretDownOutlined className={styles.icon} />
          </div>
          {
            (viewModel.hasCompareCache && isMulti)
            && (
              <div className={clsx(styles.previousText)}>
                <div className={clsx(styles.text)}>
                  {`比較 ${viewModel.previousDateCache?.[0].format('YYYY年 MM月 DD日')} ~ ${viewModel.previousDateCache?.[1].format('YYYY年 MM月 DD日')} `}
                </div>
              </div>
            )
          }
        </div>
      </Popover>
    );
  }
}

MultiPeriodDatePicker.propTypes = {
  viewModel: PropTypes.object,
  callback: PropTypes.func,
  isMulti: PropTypes.bool
};

MultiPeriodDatePicker.defaultProps = {
  viewModel: {},
  callback: () => { },
  isMulti: false
};


export default MultiPeriodDatePicker;
