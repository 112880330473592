import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import i18n from 'src/i18n';
import { Virtuoso } from 'react-virtuoso';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Modal, Checkbox } from 'antd';

import PaperIcon from 'src/assets/PaperIcon.svg';
import SearchIcon from 'src/assets/MenuIcons/SearchIcon.svg';

import packageStore from 'src/stores/packageStore';

import CheckGroupItem from './CheckGroupItem';
import CheckItem from './CheckItem';
import styles from './styles.module.scss';

@observer
class PackageLevelSelectModal extends React.Component {
  _renderBrand = (item) => {
    return (
      <CheckItem key={item.id} viewModel={item} />
    );
  };

  _renderSeries = (item) => {
    return (
      <CheckGroupItem key={item.id} viewModel={item} />
    );
  };

  _renderProduct = (item) => {
    return (
      <CheckGroupItem key={item.id} viewModel={item} />
    );
  };


  render() {
    const { viewModel, callback } = this.props;
    return (
      <Modal
        open={viewModel.isModalOpen}
        closable={false}
        centered
        onCancel={viewModel.onClose}
        className="selectModal"
        width={viewModel.modalWidth}
        footer={null}
      >
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <div className={styles.headerTitle}>
              <img src={PaperIcon} alt="paper" className={styles.titleIcon} />
              <div className={styles.titleText}>
                {i18n.t('package_modal_title')}
              </div>
            </div>
            <div className={styles.headerClose}>
              <Button
                icon={<CloseOutlined />}
                type="link"
                className={styles.headerClose}
                onClick={viewModel.onClose}
              >
                {i18n.t('package_modal_close')}
              </Button>
            </div>
          </div>
          <div className={styles.modalContent}>
            {
              packageStore.isBrandAvailable
              && (
                <div className={styles.selectCard}>
                  <div className={styles.cardHeader}>
                    <div className={styles.cardTitle}>
                      {i18n.t('package_modal_brand_select')}
                    </div>
                    <div className={styles.cardSelectAll}>
                      <Checkbox
                        indeterminate={viewModel.brandIndeterminate}
                        checked={viewModel.isAllBrandChecked}
                        onChange={viewModel.onAllBrandSelect}

                      />
                      <div className={styles.selectAllText}>
                        {i18n.t('package_modal_select_all')}
                      </div>
                    </div>
                  </div>
                  <div className={styles.cardContent}>
                    <div className={styles.searchInputContainer}>
                      <input
                        className={styles.searchInput}
                        placeholder={i18n.t('package_modal_input_placeholder')}
                        value={viewModel.brandKeywordCache}
                        onChange={viewModel.onBrandKeywordChange}
                        onKeyDown={viewModel.onBrandKeyDown}
                      />
                      <div
                        className={styles.searchBtn}
                        onClick={viewModel.onBrandSearchSubmit}
                      >
                        <img src={SearchIcon} alt="" />
                      </div>
                    </div>
                    <div className={styles.levelCheckboxContainer}>
                      <Virtuoso
                        data={viewModel.searchBrand}
                        style={{ height: 188 }}
                        itemContent={(index, item) => this._renderBrand(item)}
                      />
                    </div>
                  </div>
                </div>
              )
            }
            {
              packageStore.isSeriesAvailable
              && (
                <div className={styles.selectCard}>
                  <div className={styles.cardHeader}>
                    <div className={styles.cardTitle}>
                      {i18n.t('package_modal_series_select')}
                    </div>
                    <div className={styles.cardSelectAll}>
                      <Checkbox
                        indeterminate={viewModel.seriesIndeterminate}
                        checked={viewModel.isAllSeriesChecked}
                        onChange={viewModel.onAllSeriesSelect}
                      />
                      <div className={styles.selectAllText}>
                        {i18n.t('package_modal_select_all')}
                      </div>
                    </div>
                  </div>
                  <div className={styles.cardContent}>
                    <div className={styles.searchInputContainer}>
                      <input
                        className={styles.searchInput}
                        placeholder={i18n.t('package_modal_input_placeholder')}
                        value={viewModel.seriesKeyword}
                        onChange={viewModel.onSeriesKeywordChange}
                        onKeyDown={viewModel.onSeriesKeyDown}
                      />
                      <div
                        className={styles.searchBtn}
                        onClick={viewModel.onSeriesSearchSubmit}
                      >
                        <img src={SearchIcon} alt="" />
                      </div>
                    </div>
                    <div className={styles.levelCheckboxContainer}>
                      <Virtuoso
                        data={viewModel.showSeries}
                        style={{ height: 188 }}
                        itemContent={(index, item) => this._renderSeries(item)}
                      />
                    </div>
                  </div>
                </div>
              )
            }
            {
              packageStore.isProductAvailable
              && (
                <div className={styles.selectCard}>
                  <div className={styles.cardHeader}>
                    <div className={styles.cardTitle}>
                      {i18n.t('package_modal_product_select')}
                    </div>
                    <div className={styles.cardSelectAll}>
                      <Checkbox
                        indeterminate={viewModel.productIndeterminate}
                        checked={viewModel.isAllProductChecked}
                        onChange={viewModel.onAllProductSelect}
                      />
                      <div className={styles.selectAllText}>
                        {i18n.t('package_modal_select_all')}
                      </div>
                    </div>
                  </div>
                  <div className={styles.cardContent}>
                    <div className={styles.searchInputContainer}>
                      <input
                        className={styles.searchInput}
                        placeholder={i18n.t('package_modal_input_placeholder')}
                        value={viewModel.productKeyword}
                        onChange={viewModel.onProductKeywordChange}
                        onKeyDown={viewModel.onProductKeyDown}
                      />
                      <div
                        className={styles.searchBtn}
                        onClick={viewModel.onProductSearchSubmit}
                      >
                        <img src={SearchIcon} alt="" />
                      </div>
                    </div>
                    <div className={styles.levelCheckboxContainer}>
                      <Virtuoso
                        data={viewModel.showProduct}
                        style={{ height: 188 }}
                        itemContent={(index, item) => this._renderProduct(item)}
                      />
                    </div>
                  </div>
                </div>
              )
            }
          </div>
          <div className={styles.modalFooter}>
            <div className={styles.btnContainer}>
              <Button
                className={clsx(styles.btn, styles.cleanBtn)}
                icon={<CloseOutlined />}
                onClick={viewModel.onCleanAll}
              >
                {i18n.t('package_modal_clean')}
              </Button>
              <Button
                className={clsx(styles.btn, styles.applyBtn)}
                icon={<CheckOutlined />}
                type="primary"
                onClick={() => viewModel.onSubmit(callback)}
              >
                {i18n.t('package_modal_apply')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

PackageLevelSelectModal.propTypes = {
  viewModel: PropTypes.object,
  callback: PropTypes.func
};

PackageLevelSelectModal.defaultProps = {
  viewModel: {},
  callback: () => { }
};

export default PackageLevelSelectModal;
