import { COLOR_SET } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import packageStore from 'src/stores/packageStore';
import { getHost } from 'src/utils';
import { encodeURI } from 'js-base64';
import { CONDITION_KEY_TC } from 'src/consts';
import { generateCSVKey } from 'src/utils/chart';

export default class AdvSearchRankingModel {
  constructor(origin, prevOrigin, query) {
    if (origin.length === 0 && prevOrigin.length === 0) {
      this.data = {
        chartData: [{
          id: 'adv_search_volume_ranking',
          name: '交叉分析聲量排行',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }]
        }]
      };
      return;
    }

    const {
      womType,
      postType,
      condition1,
      condition2,
      tagKeyName
    } = query;

    const condition1Key = condition1.key;
    const condition2Key = condition2.key;

    const { currentDate, previousDate } = infoHeaderViewModel.multiViewModel;
    const currentTime = currentDate.map((el) => el.format('YYYY-MM-DD'));
    const previousTime = previousDate.map((el) => el.format('YYYY-MM-DD'));

    const csv = origin.map((el) => {
      const [c1Name, c2Name] = el.name.split('-');
      return {
        時間: currentTime.join('~'),
        [`交叉條件一${condition1Key}`]: c1Name,
        [`交叉條件二${condition2Key}`]: c2Name,
        聲量: el.count
      };
    });

    const prevCsv = prevOrigin.map((el) => {
      const [c1Name, c2Name] = el.name.split('-');
      return {
        時間: previousTime.join('~'),
        [`交叉條件一${condition1Key}`]: c1Name,
        [`交叉條件二${condition2Key}`]: c2Name,
        聲量: el.count
      };
    });

    csv.push(...prevCsv);

    const csvKey = generateCSVKey(csv[0]);

    const data = origin.slice(0, 20);
    const prevData = prevOrigin.slice(0, 20);

    const combine = data.map((el) => ({
      id: `${el.condition1}-${el.condition2}`,
      ...el,
      prevCount: 0
    }));

    prevData.forEach((el) => {
      const target = combine.find((item) => item.id === `${el.condition1}-${el.condition2}`);
      if (target) {
        target.prevCount = el.count;
      } else {
        combine.push({
          id: `${el.condition1}-${el.condition2}`,
          condition1: el.condition1,
          condition2: el.condition2,
          name: el.name,
          count: 0,
          prevCount: el.count
        });
      }

    });


    this.data = {
      chartData: [
        {
          id: 'adv_search_volume_ranking',
          name: '交叉分析聲量排行',
          data: [
            ...(
              !(combine.every((item) => item.count === 0) && combine.every((item) => item.prevCount === 0))
                ? [{
                  labels: combine.map((el) => el.name),
                  datasets: [
                    ...(
                      !combine.every((item) => item.count === 0)
                        ? [{
                          label: currentTime.join('~'),
                          data: combine.map((el) => el.count),
                          condition1: combine.map((el) => el.condition1),
                          condition2: combine.map((el) => el.condition2),
                          backgroundColor: COLOR_SET[0],
                          borderSkipped: true,
                          categoryPercentage: 0.2
                        }]
                        : []
                    ),
                    ...(
                      !combine.every((item) => item.prevCount === 0)
                        ? [
                          {
                            label: previousTime.join('~'),
                            data: combine.map((el) => el.prevCount),
                            condition1: combine.map((el) => el.condition1),
                            condition2: combine.map((el) => el.condition2),
                            backgroundColor: COLOR_SET[19],
                            borderSkipped: true,
                            categoryPercentage: 0.2
                          }
                        ]
                        : []
                    )
                  ]
                }]
                : [{ type: 'nodata' }]
            )

          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const c1 = chart.data.datasets[datasetIndex].condition1[index];
              const c2 = chart.data.datasets[datasetIndex].condition2[index];
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const [gte, lte] = chart.data.datasets[datasetIndex].label.split('~');
              const [c1Name, c2Name] = chart.data.labels[index].split('-');

              const topicsResult = {
                [`${condition1Key}`]: [{
                  id: c1,
                  ...(condition1.value.tagKey && {
                    tagKey: condition1.value.tagKey,
                    tagKeyName
                  }),
                  name: c1Name.trim()
                }],
                [`${condition2Key}`]: [{
                  id: c2,
                  ...(condition2.value.tagKey && {
                    tagKey: condition2.value.tagKey,
                    tagKeyName
                  }),
                  name: c2Name.trim()
                }],
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: `交叉分析/『${CONDITION_KEY_TC[condition1Key]}』交叉『${CONDITION_KEY_TC[condition2Key]}』/排行圖`
                },
                womCount,
                date: {
                  gte,
                  lte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);

              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'bar',
          options: {
            event: ['click'],
            scales: {
              y: {
                beginAtZero: true,
                grace: 5,
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ]
    };
  }

  static fromRes(data, prevData, query) {
    return new AdvSearchRankingModel(data, prevData, query);
  }
}
