import dayjs from 'dayjs';
import { LOG_STATUS } from 'src/consts';

export default class PackageListModel {
  constructor(data) {
    const {
      list,
      anchor
    } = data;

    this.anchor = anchor;
    this.packageList = list.map((el) => {
      const projectStatus = el.status?.projectStatus ?? {};
      const packageStatus = el.status?.dataPackageStatus ?? {};

      return {
        id: el.id,
        projectId: el.projectId,
        name: el.name,
        holder: el.owner?.name ?? null,
        users: el.sharedUsers ?? [],
        establishTime: dayjs(el.createDate).format('YYYY/MM/DD'),
        adjustTime: dayjs(el.lastChangeDate).format('YYYY/MM/DD'),
        select: false,
        projectStatus: projectStatus.stageDesc && projectStatus.beginAt && projectStatus.endAt
          ? `${projectStatus.progress ? `${Math.floor(projectStatus.progress)}%` : ''}${projectStatus.stageDesc}\n${dayjs(projectStatus.beginAt).format('YYYY/MM/DD HH:mm')} -\n ${dayjs(projectStatus.endAt).format('YYYY/MM/DD HH:mm')}`
          : `${projectStatus.progress ? `${Math.floor(projectStatus.progress)}%` : ''}${projectStatus.stageDesc ?? '---'}`,
        projectStatusType: projectStatus?.stage,
        packageStatus: packageStatus.stage && packageStatus.beginAt && packageStatus.endAt
          ? `${packageStatus.progress && ['WAITING', 'BEGIN', 'DONE'].includes(packageStatus.stage) ? `${Math.floor(packageStatus.progress)}%` : ''}${LOG_STATUS[packageStatus.stage]}\n${dayjs(packageStatus.beginAt).format('YYYY/MM/DD HH:mm')} -\n ${dayjs(packageStatus.endAt).format('YYYY/MM/DD HH:mm')}`
          : `${packageStatus.progress && ['WAITING', 'BEGIN', 'DONE'].includes(packageStatus.stage) ? `${Math.floor(packageStatus.progress)}%` : ''}${LOG_STATUS[packageStatus.stage] ?? '---'}` ?? '---',
        packageStatusType: packageStatus?.stage
      };
    });
  }

  static fromRes(data) {
    return new PackageListModel(data);
  }
}
