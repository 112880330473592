import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getTotalDays } from 'src/utils/chart';
import ChartService from '.';

export default class ChartAnalysisService {
  static getGraphicData = async (level, type, subtype, subChartType, { params, search }) => {
    const {
      currentDate,
      previousDate,
      hasCompare
    } = infoHeaderViewModel.multiViewModel;
    const [gte, lte] = currentDate.map((el) => el.format('YYYY-MM-DD'));
    const [previousGte, previousLte] = previousDate.map((el) => el.format('YYYY-MM-DD'));
    const totalDays = getTotalDays();
    const [autoPreviousGte, autoPreviousLte] = currentDate.map((el) => el.subtract(totalDays, 'day').format('YYYY-MM-DD'));
    const data = {
      ...params,
      date: {
        gte,
        lte
      }
    };
    const prevData = {
      ...params,
      date: {
        gte: previousGte,
        lte: previousLte
      }
    };

    switch (level) {
      case 'industry':
        switch (type) {
          case 'volume':
            switch (subtype) {
              case 'industry_volume_trend':
                return ChartService.getIndustryVolumeTrend(data, hasCompare ? prevData : null);
              case 'industry_volume_proportion':
                return ChartService.getIndustryVolumeProportion(data, hasCompare ? prevData : null);
              default:
                return {};
            }
          case 'source':
            switch (subtype) {
              case 'industry_source_category':
                switch (subChartType) {
                  case 'industry_source_category_trend':
                    return ChartService.getIndustrySourceCategoryTrend(data, hasCompare ? prevData : null);
                  case 'industry_source_category_ranking':
                    return ChartService.getIndustrySourceCategoryRanking(data, hasCompare ? prevData : null);
                  case 'industry_source_category_proportion':
                    return ChartService.getIndustrySourceCategoryProportion(data, hasCompare ? prevData : null);
                  default:
                    return {};
                }
              case 'industry_source_website':
                switch (subChartType) {
                  case 'industry_source_website_trend':
                    return ChartService.getIndustrySourceWebsiteTrend(data, hasCompare ? prevData : null);
                  case 'industry_source_website_ranking':
                    return ChartService.getIndustrySourceWebsiteRanking(data, hasCompare ? prevData : null);
                  case 'industry_source_website_proportion':
                    return ChartService.getIndustrySourceWebsiteProportion(data, hasCompare ? prevData : null);
                  default:
                    return {};
                }
              case 'industry_source_channel':
                switch (subChartType) {
                  case 'industry_source_channel_trend':
                    return ChartService.getIndustrySourceChannelTrend(data, hasCompare ? prevData : null);
                  case 'industry_source_channel_ranking':
                    return ChartService.getIndustrySourceChannelRanking(data, hasCompare ? prevData : null);
                  case 'industry_source_channel_proportion':
                    return ChartService.getIndustrySourceChannelProportion(data, hasCompare ? prevData : null);
                  default:
                    return {};
                }
              case 'industry_source_social':
                return ChartService.getIndustrySourceSocialActivity(data, hasCompare ? prevData : null);
              default:
                return {};
            }
          case 'sentiment':
            switch (subtype) {
              case 'industry_sentiment_overview':
                return ChartService.getIndustrySentimentOverview(data, hasCompare ? prevData : null);
              case 'industry_sentiment_trend':
                return ChartService.getIndustrySentimentTrend(data, hasCompare ? prevData : null);
              case 'industry_sentiment_volume':
                return ChartService.getIndustrySentimentVolume(data, hasCompare ? prevData : null);
              default:
                return {};
            }
          case 'keyword':
            switch (subtype) {
              case 'industry_keyword_hot':
                return ChartService.getIndustryHotWords(data, hasCompare ? prevData : null);
              case 'industry_keyword_new':
                return ChartService.getIndustryNewWords({
                  ...params,
                  basePeriod: {
                    gte: autoPreviousGte,
                    lte: autoPreviousLte
                  },
                  currentPeriod: {
                    gte,
                    lte
                  }
                });
              case 'industry_keyword_trend':
                return ChartService.getIndustryTrendWords(
                  {
                    ...params,
                    basePeriod: {
                      gte: autoPreviousGte,
                      lte: autoPreviousLte
                    },
                    currentPeriod: {
                      gte,
                      lte
                    }
                  }
                );
              case 'industry_keyword_hot_assocs':
                return ChartService.getIndustryHotWordAssocs(data, hasCompare ? prevData : null);
              default:
                return ChartService.getIndustryVolumeTrend();
            }
          default:
            return ChartService.getIndustryVolumeTrend();
        }
      case 'brand':
        switch (type) {
          case 'volume':
            switch (subtype) {
              case 'brand_volume_trend':
                return ChartService.getBrandVolumeTrend(data, hasCompare ? prevData : null);
              case 'brand_volume_ranking':
                return ChartService.getBrandVolumeRanking(data, hasCompare ? prevData : null);
              case 'brand_volume_proportion':
                return ChartService.getBrandVolumeProportion(data, hasCompare ? prevData : null);
              default:
                return {};
            }
          case 'source':
            switch (subtype) {
              case 'brand_source_category':
                switch (subChartType) {
                  case 'brand_source_category_trend':
                    return ChartService.getBrandSourceCategoryTrend(data, hasCompare ? prevData : null);
                  case 'brand_source_category_ranking':
                    return ChartService.getBrandSourceCategoryRanking(data, hasCompare ? prevData : null);
                  case 'brand_source_category_proportion':
                    return ChartService.getBrandSourceCategoryProportion(data, hasCompare ? prevData : null);
                  case 'brand_source_category_compare':
                    return ChartService.getBrandSourceCategoryCompare(data, hasCompare ? prevData : null);
                  default:
                    return ChartService.getIndustryVolumeTrend();
                }
              case 'brand_source_website':
                switch (subChartType) {
                  case 'brand_source_website_trend':
                    return ChartService.getBrandSourceWebsiteTrend(data, hasCompare ? prevData : null);
                  case 'brand_source_website_ranking':
                    return ChartService.getBrandSourceWebsiteRanking(data, hasCompare ? prevData : null);
                  case 'brand_source_website_proportion':
                    return ChartService.getBrandSourceWebsiteProportion(data, hasCompare ? prevData : null);
                  case 'brand_source_website_compare':
                    return ChartService.getBrandSourceWebsiteCompare(data, hasCompare ? prevData : null);
                  default:
                    return ChartService.getIndustryVolumeTrend();
                }
              case 'brand_source_channel':
                switch (subChartType) {
                  case 'brand_source_channel_trend':
                    return ChartService.getBrandSourceChannelTrend(data, hasCompare ? prevData : null);
                  case 'brand_source_channel_ranking':
                    return ChartService.getBrandSourceChannelRanking(data, hasCompare ? prevData : null);
                  case 'brand_source_channel_proportion':
                    return ChartService.getBrandSourceChannelProportion(data, hasCompare ? prevData : null);
                  case 'brand_source_channel_compare':
                    return ChartService.getBrandSourceChannelCompare(data, hasCompare ? prevData : null);
                  default:
                    return ChartService.getIndustryVolumeTrend();
                }
              case 'brand_source_social':
                return ChartService.getBrandSourceSocialActivity(data, hasCompare ? prevData : null);
              default:
                return ChartService.getIndustryVolumeTrend();
            }
          case 'sentiment':
            switch (subtype) {
              case 'brand_sentiment_overview':
                return ChartService.getBrandSentimentOverview(data, hasCompare ? prevData : null);
              case 'single_brand_sentiment_trend':
                return ChartService.getSingleBrandSentimentTrend(data, hasCompare ? prevData : null);
              case 'brand_sentiment_positive_trend':
                return ChartService.getBrandPositiveSentimentTrend(data, hasCompare ? prevData : null);
              case 'brand_sentiment_negative_trend':
                return ChartService.getBrandNegativeSentimentTrend(data, hasCompare ? prevData : null);
              case 'brand_sentiment_volume':
                return ChartService.getBrandSentimentVolume(data, hasCompare ? prevData : null);
              default:
                return ChartService.getIndustryVolumeTrend();
            }
          case 'keyword':
            switch (subtype) {
              case 'brand_keyword_hot':
                return ChartService.getBrandHotWords(data, hasCompare ? prevData : null, search);
              case 'brand_keyword_new':
                return ChartService.getBrandNewWords({
                  ...params,
                  basePeriod: {
                    gte: autoPreviousGte,
                    lte: autoPreviousLte
                  },
                  currentPeriod: {
                    gte,
                    lte
                  }
                }, search);
              case 'brand_keyword_trend':
                return ChartService.getBrandTrendWords({
                  ...params,
                  basePeriod: {
                    gte: autoPreviousGte,
                    lte: autoPreviousLte
                  },
                  currentPeriod: {
                    gte,
                    lte
                  }
                }, search);
              case 'brand_keyword_hot_assocs':
                return ChartService.getBrandHotWordAssocs(data, hasCompare ? prevData : null, search);
              default:
                return ChartService.getIndustryVolumeTrend(data, hasCompare ? prevData : null);
            }
          default:
            return ChartService.getIndustryVolumeTrend();
        }
      case 'series':
        switch (type) {
          case 'volume':
            switch (subtype) {
              case 'series_volume':
                switch (subChartType) {
                  case 'series_volume_trend':
                    return ChartService.getSeriesVolumeTrend(data, hasCompare ? prevData : null);
                  case 'series_volume_ranking':
                    return ChartService.getSeriesVolumeRanking(data, hasCompare ? prevData : null);
                  case 'series_volume_proportion':
                    return ChartService.getSeriesVolumeProportion(data, hasCompare ? prevData : null);
                  default:
                    return ChartService.getIndustryVolumeTrend(data, hasCompare ? prevData : null);
                }
              case 'series_brand_volume':
                switch (subChartType) {
                  case 'series_brand_volume_trend':
                    return ChartService.getSeriesBrandVolumeTrend(data, hasCompare ? prevData : null);
                  case 'series_brand_volume_ranking':
                    return ChartService.getSeriesBrandVolumeRanking(data, hasCompare ? prevData : null);
                  case 'series_brand_volume_proportion':
                    return ChartService.getSeriesBrandVolumeProportion(data, hasCompare ? prevData : null);
                  case 'series_brand_volume_compare':
                    return ChartService.getSeriesBrandVolumeCompare(data, hasCompare ? prevData : null);
                  default:
                    return ChartService.getIndustryVolumeTrend(data, hasCompare ? prevData : null);
                }
              default:
                return ChartService.getIndustryVolumeTrend();
            }
          case 'source':
            switch (subtype) {
              case 'series_source_category':
                switch (subChartType) {
                  case 'series_source_category_trend':
                    return ChartService.getSeriesSourceCategoryTrend(data, hasCompare ? prevData : null);
                  case 'series_source_category_ranking':
                    return ChartService.getSeriesSourceCategoryRanking(data, hasCompare ? prevData : null);
                  case 'series_source_category_proportion':
                    return ChartService.getSeriesSourceCategoryProportion(data, hasCompare ? prevData : null);
                  case 'series_source_category_compare':
                    return ChartService.getSeriesSourceCategoryCompare(data, hasCompare ? prevData : null);
                  default:
                    return ChartService.getIndustryVolumeTrend();
                }
              case 'series_source_website':
                switch (subChartType) {
                  case 'series_source_website_trend':
                    return ChartService.getSeriesSourceWebsiteTrend(data, hasCompare ? prevData : null);
                  case 'series_source_website_ranking':
                    return ChartService.getSeriesSourceWebsiteRanking(data, hasCompare ? prevData : null);
                  case 'series_source_website_proportion':
                    return ChartService.getSeriesSourceWebsiteProportion(data, hasCompare ? prevData : null);
                  case 'series_source_website_compare':
                    return ChartService.getSeriesSourceWebsiteCompare(data, hasCompare ? prevData : null);
                  default:
                    return ChartService.getIndustryVolumeTrend();
                }
              case 'series_source_channel':
                switch (subChartType) {
                  case 'series_source_channel_trend':
                    return ChartService.getSeriesSourceChannelTrend(data, hasCompare ? prevData : null);
                  case 'series_source_channel_ranking':
                    return ChartService.getSeriesSourceChannelRanking(data, hasCompare ? prevData : null);
                  case 'series_source_channel_proportion':
                    return ChartService.getSeriesSourceChannelProportion(data, hasCompare ? prevData : null);
                  case 'series_source_channel_compare':
                    return ChartService.getSeriesSourceChannelCompare(data, hasCompare ? prevData : null);
                  default:
                    return ChartService.getIndustryVolumeTrend();
                }
              default:
                return ChartService.getIndustryVolumeTrend();
            }
          case 'keyword':
            switch (subtype) {
              case 'series_keyword_hot':
                return ChartService.getSeriesHotWords(data, hasCompare ? prevData : null, search);
              case 'series_keyword_new':
                return ChartService.getSeriesNewWords({
                  ...params,
                  basePeriod: {
                    gte: autoPreviousGte,
                    lte: autoPreviousLte
                  },
                  currentPeriod: {
                    gte,
                    lte
                  }
                }, search);
              case 'series_keyword_trend':
                return ChartService.getSeriesTrendWords({
                  ...params,
                  basePeriod: {
                    gte: autoPreviousGte,
                    lte: autoPreviousLte
                  },
                  currentPeriod: {
                    gte,
                    lte
                  }
                }, search);
              case 'series_keyword_hot_assocs':
                return ChartService.getSeriesHotWordAssocs(data, hasCompare ? prevData : null, search);
              default:
                return ChartService.getIndustryVolumeTrend(data, hasCompare ? prevData : null);
            }
          default:
            return ChartService.getIndustryVolumeTrend();
        }
      case 'product':
        switch (type) {
          case 'volume':
            switch (subtype) {
              case 'product_volume':
                switch (subChartType) {
                  case 'product_volume_trend':
                    return ChartService.getProductVolumeTrend(data, hasCompare ? prevData : null);
                  case 'product_volume_ranking':
                    return ChartService.getProductVolumeRanking(data, hasCompare ? prevData : null);
                  case 'product_volume_proportion':
                    return ChartService.getProductVolumeProportion(data, hasCompare ? prevData : null);
                  default:
                    return ChartService.getIndustryVolumeTrend();
                }
              case 'product_series_volume':
                switch (subChartType) {
                  case 'product_series_volume_trend':
                    return ChartService.getProductSeriesVolumeTrend(data, hasCompare ? prevData : null);
                  case 'product_series_volume_ranking':
                    return ChartService.getProductSeriesVolumeRanking(data, hasCompare ? prevData : null);
                  case 'product_series_volume_proportion':
                    return ChartService.getProductSeriesVolumeProportion(data, hasCompare ? prevData : null);
                  case 'product_series_volume_compare':
                    return ChartService.getProductSeriesVolumeCompare(data, hasCompare ? prevData : null);
                  default:
                    return ChartService.getIndustryVolumeTrend();
                }
              default:
                return ChartService.getIndustryVolumeTrend();
            }
          case 'source':
            switch (subtype) {
              case 'product_source_category':
                switch (subChartType) {
                  case 'product_source_category_trend':
                    return ChartService.getProductSourceCategoryTrend(data, hasCompare ? prevData : null);
                  case 'product_source_category_ranking':
                    return ChartService.getProductSourceCategoryRanking(data, hasCompare ? prevData : null);
                  case 'product_source_category_proportion':
                    return ChartService.getProductSourceCategoryProportion(data, hasCompare ? prevData : null);
                  case 'product_source_category_compare':
                    return ChartService.getProductSourceCategoryCompare(data, hasCompare ? prevData : null);
                  default:
                    return ChartService.getIndustryVolumeTrend();
                }
              case 'product_source_website':
                switch (subChartType) {
                  case 'product_source_website_trend':
                    return ChartService.getProductSourceWebsiteTrend(data, hasCompare ? prevData : null);
                  case 'product_source_website_ranking':
                    return ChartService.getProductSourceWebsiteRanking(data, hasCompare ? prevData : null);
                  case 'product_source_website_proportion':
                    return ChartService.getProductSourceWebsiteProportion(data, hasCompare ? prevData : null);
                  case 'product_source_website_compare':
                    return ChartService.getProductSourceWebsiteCompare(data, hasCompare ? prevData : null);
                  default:
                    return ChartService.getIndustryVolumeTrend();
                }
              case 'product_source_channel':
                switch (subChartType) {
                  case 'product_source_channel_trend':
                    return ChartService.getProductSourceChannelTrend(data, hasCompare ? prevData : null);
                  case 'product_source_channel_ranking':
                    return ChartService.getProductSourceChannelRanking(data, hasCompare ? prevData : null);
                  case 'product_source_channel_proportion':
                    return ChartService.getProductSourceChannelProportion(data, hasCompare ? prevData : null);
                  case 'product_source_channel_compare':
                    return ChartService.getProductSourceChannelCompare(data, hasCompare ? prevData : null);
                  default:
                    return ChartService.getIndustryVolumeTrend();
                }
              case 'product_source_social':
                return ChartService.getProductSourceSocialActivity(data, hasCompare ? prevData : null);
              default:
                return ChartService.getIndustryVolumeTrend();
            }
          case 'sentiment':
            switch (subtype) {
              case 'product_sentiment_overview':
                return ChartService.getProductSentimentOverview(data, hasCompare ? prevData : null);
              case 'single_product_sentiment_trend':
                return ChartService.getSingleProductSentimentTrend(data, hasCompare ? prevData : null);
              case 'product_sentiment_positive_trend':
                return ChartService.getProductPositiveSentimentTrend(data, hasCompare ? prevData : null);
              case 'product_sentiment_negative_trend':
                return ChartService.getProductNegativeSentimentTrend(data, hasCompare ? prevData : null);
              case 'product_sentiment_volume':
                return ChartService.getProductSentimentVolume(data, hasCompare ? prevData : null);
              default:
                return ChartService.getIndustryVolumeTrend();
            }
          case 'keyword':
            switch (subtype) {
              case 'product_keyword_hot':
                return ChartService.getProductHotWords(data, hasCompare ? prevData : null, search);
              case 'product_keyword_new':
                return ChartService.getProductNewWords({
                  ...params,
                  basePeriod: {
                    gte: autoPreviousGte,
                    lte: autoPreviousLte
                  },
                  currentPeriod: {
                    gte,
                    lte
                  }
                }, search);
              case 'product_keyword_trend':
                return ChartService.getProductTrendWords({
                  ...params,
                  basePeriod: {
                    gte: autoPreviousGte,
                    lte: autoPreviousLte
                  },
                  currentPeriod: {
                    gte,
                    lte
                  }
                }, search);
              case 'product_keyword_hot_assocs':
                return ChartService.getProductHotWordAssocs(data, hasCompare ? prevData : null, search);
              default:
                return ChartService.getIndustryVolumeTrend(data, hasCompare ? prevData : null);
            }
          default:
            return ChartService.getIndustryVolumeTrend();
        }
      default:
        return ChartService.getIndustryVolumeTrend();
    }
  };

  static getGraphDesc = (level, type, subtype, subChartType) => {
    switch (level) {
      case 'industry':
        switch (type) {
          case 'volume':
            switch (subtype) {
              case 'industry_volume_trend':
                return '在時間區間內，專案中所有品牌聲量加總。';
              case 'industry_volume_proportion':
                return '在時間區間內，專案中各系列聲量的總量佔比。';
              default:
                return '';
            }
          case 'source':
            switch (subtype) {
              case 'industry_source_category':
                switch (subChartType) {
                  case 'industry_source_category_trend':
                    return '各來源類型中，在時間區間內每日之所有品牌聲量趨勢分佈。';
                  case 'industry_source_category_ranking':
                    return '各來源類型中，在時間區間內每日之所有品牌聲量加總。';
                  case 'industry_source_category_proportion':
                    return '各來源類型中，在時間區間內所有品牌聲量的總量佔比。';
                  default:
                    return '';
                }
              case 'industry_source_website':
                switch (subChartType) {
                  case 'industry_source_website_trend':
                    return '在時間區間內，各TOP20來源網站中所有品牌聲量的趨勢分佈。';
                  case 'industry_source_website_ranking':
                    return '在時間區間內，各TOP20來源網站中所有品牌聲量加總。';
                  case 'industry_source_website_proportion':
                    return '在時間區間內，各TOP20來源網站中所有品牌聲量的總量佔比。';
                  default:
                    return '';
                }
              case 'industry_source_channel':
                switch (subChartType) {
                  case 'industry_source_channel_trend':
                    return '在時間區間內，各TOP20來源頻道中所有品牌聲量的趨勢分佈。';
                  case 'industry_source_channel_ranking':
                    return '在時間區間內，各TOP20來源頻道中所有品牌聲量加總。';
                  case 'industry_source_channel_proportion':
                    return '在時間區間內，各TOP20來源頻道中所有品牌聲量的總量佔比。';
                  default:
                    return '';
                }
              case 'industry_source_social':
                return '指定時間區間內，於專案所有品牌聲量總和中展示S(來源類型為論壇及Facebook、IG、Threads者)和N(來源類型為新聞者)的聲量加總以及S/N比例。';
              default:
                return '';
            }
          case 'sentiment':
            switch (subtype) {
              case 'industry_sentiment_overview':
                return '在時間區間內，各情緒分類中所有品牌聲量加總。';
              case 'industry_sentiment_trend':
                return '在時間區間內，各情緒分類中所有品牌聲量的總量佔比。';
              case 'industry_sentiment_volume':
                return '在時間區間內，各情緒分類中所有品牌聲量加總。';
              default:
                return '';
            }
          case 'keyword':
            switch (subtype) {
              case 'industry_keyword_hot':
                return '在時間區間內，專案所有品牌話題中提及頻率高的自然詞彙。分數為提及則數。';
              case 'industry_keyword_new':
                return '在時間區間內，專案所有品牌話題與指定區間等長的前期話題相比，撈取前期無但指定區間有的詞彙。分數為提及則數。';
              case 'industry_keyword_trend':
                return '在時間區間內，專案所有品牌話題與指定區間等長的前期話題相比有正成長趨勢的詞彙。分數為趨勢指數。';
              case 'industry_keyword_hot_assocs':
                return '在時間區間內，專案所有品牌話題的高相關詞彙及與該詞彙高相關的子詞彙。';
              default:
                return '';
            }
          default:
            return '';
        }
      case 'brand':
        switch (type) {
          case 'volume':
            switch (subtype) {
              case 'brand_volume_trend':
                return '在時間區間內，專案中各品牌聲量的趨勢分佈。';
              case 'brand_volume_ranking':
                return '在時間區間內，專案中各品牌聲量的排行。';
              case 'brand_volume_proportion':
                return '在時間區間內，專案中各品牌聲量的總量佔比。';
              default:
                return '';
            }
          case 'source':
            switch (subtype) {
              case 'brand_source_category':
                switch (subChartType) {
                  case 'brand_source_category_trend':
                    return '專案各品牌中，來源類型在時間區間內每日之品牌聲量加總的趨勢分佈。';
                  case 'brand_source_category_ranking':
                    return '專案各品牌中，來源類型在時間區間內每日之品牌聲量加總排行。';
                  case 'brand_source_category_proportion':
                    return '專案各品牌中，來源類型在時間區間內之品牌聲量的總量佔比。';
                  case 'brand_source_category_compare':
                    return '專案各品牌中，在時間區間內每日之各來源類型的聲量排行分佈。';
                  default:
                    return '';
                }
              case 'brand_source_website':
                switch (subChartType) {
                  case 'brand_source_website_trend':
                    return '專案各品牌中，在時間區間內之TOP20來源網站的聲量趨勢分佈。';
                  case 'brand_source_website_ranking':
                    return '專案各品牌中，在時間區間內之TOP20來源網站的聲量排行。';
                  case 'brand_source_website_proportion':
                    return '專案各品牌中，在時間區間內之TOP20來源網站的聲量總量佔比。';
                  case 'brand_source_website_compare':
                    return '專案各品牌中，在時間區間內之TOP20來源網站的聲量排行分佈。';
                  default:
                    return '';
                }
              case 'brand_source_channel':
                switch (subChartType) {
                  case 'brand_source_channel_trend':
                    return '專案各品牌中，在時間區間內之TOP20來源頻道的聲量趨勢分佈。';
                  case 'brand_source_channel_ranking':
                    return '專案各品牌中，在時間區間內之TOP20來源頻道的聲量排行。';
                  case 'brand_source_channel_proportion':
                    return '專案各品牌中，在時間區間內之TOP20來源頻道的聲量總量佔比。';
                  case 'brand_source_channel_compare':
                    return '專案各品牌中，在時間區間內之TOP20來源頻道的聲量排行分佈。';
                  default:
                    return '';
                }
              case 'brand_source_social':
                return '指定時間區間內，專案各品牌聲量總和中展示S(來源類型為論壇及Facebook、IG者)和N(來源類型為新聞者)的聲量加總以及S/N比例。';
              default:
                return '';
            }
          case 'sentiment':
            switch (subtype) {
              case 'brand_sentiment_overview':
                return '比較時間區間內各情緒分類之專案各品牌聲量總量。';
              case 'single_brand_sentiment_trend':
                return '專案各品牌在時間區間內，各情緒類型之品牌聲量加總的趨勢分佈。';
              case 'brand_sentiment_positive_trend':
                return '在時間區間內，正面情緒中專案各品牌聲量加總的趨勢分佈。';
              case 'brand_sentiment_negative_trend':
                return '在時間區間內，負面情緒中專案各品牌聲量加總的趨勢分佈。';
              case 'brand_sentiment_volume':
                return '各情緒分類中，專案各品牌在時間區間內之品牌聲量的總量佔比排行。';
              default:
                return '';
            }
          case 'keyword':
            switch (subtype) {
              case 'brand_keyword_hot':
                return '在時間區間內，專案各品牌話題中提及頻率高的自然詞彙。分數為提及則數。';
              case 'brand_keyword_new':
                return '在時間區間內，專案各品牌話題與指定區間等長的前期話題相比，撈取前期無但指定區間有的詞彙。分數為提及則數。';
              case 'brand_keyword_trend':
                return '在時間區間內，專案各品牌話題與指定區間等長的前期話題相比有正成長趨勢的詞彙。分數為趨勢指數。';
              case 'brand_keyword_hot_assocs':
                return '在時間區間內，專案品牌話題的高相關詞彙及與該詞彙高相關的子詞彙。';
              default:
                return '';
            }
          default:
            return '';
        }
      case 'series':
        switch (type) {
          case 'volume':
            switch (subtype) {
              case 'series_volume':
                switch (subChartType) {
                  case 'series_volume_trend':
                    return '在時間區間內，專案中各系列聲量加總的趨勢分佈。';
                  case 'series_volume_ranking':
                    return '在時間區間內，專案中各系列聲量加總的排行。';
                  case 'series_volume_proportion':
                    return '在時間區間內，專案中各系列聲量的總量佔比。';
                  default:
                    return '';
                }
              case 'series_brand_volume':
                switch (subChartType) {
                  case 'series_brand_volume_trend':
                    return '專案各系列中，各品牌在時間區間內每日之聲量的趨勢分佈。';
                  case 'series_brand_volume_ranking':
                    return '專案各系列中，各品牌在時間區間內之聲量的排行。';
                  case 'series_brand_volume_proportion':
                    return '專案各系列中，各品牌在時間區間內之聲量的總量佔比。';
                  case 'series_brand_volume_compare':
                    return '專案各系列中，各品牌在時間區間內之聲量的排行分佈。';
                  default:
                    return '';
                }
              default:
                return '';
            }
          case 'source':
            switch (subtype) {
              case 'series_source_category':
                switch (subChartType) {
                  case 'series_source_category_trend':
                    return '專案各系列中，來源類型在時間區間內每日之聲量加總的趨勢分佈。';
                  case 'series_source_category_ranking':
                    return '專案各系列中，來源類型在時間區間內每日之聲量加總的排行。';
                  case 'series_source_category_proportion':
                    return '專案各系列中，來源類型在時間區間內之聲量的總量佔比。';
                  case 'series_source_category_compare':
                    return '專案各系列中，來源類型在時間區間內每日之聲量加總的排行分佈。';
                  default:
                    return '';
                }
              case 'series_source_website':
                switch (subChartType) {
                  case 'series_source_website_trend':
                    return '專案各系列中，TOP10來源網站在時間區間內每日之聲量加總的趨勢分佈。';
                  case 'series_source_website_ranking':
                    return '專案各系列中，TOP10來源網站在時間區間內之聲量的排行。';
                  case 'series_source_website_proportion':
                    return '專案各系列中，TOP10來源網站在時間區間內之聲量的總量佔比。';
                  case 'series_source_website_compare':
                    return '專案各系列中，TOP10來源網站在時間區間內每日之聲量加總的排行分佈。';
                  default:
                    return '';
                }
              case 'series_source_channel':
                switch (subChartType) {
                  case 'series_source_channel_trend':
                    return '專案各系列中，TOP10來源頻道在時間區間內之聲量的趨勢分佈。';
                  case 'series_source_channel_ranking':
                    return '專案各系列中，TOP10來源頻道在時間區間內之聲量的排行。';
                  case 'series_source_channel_proportion':
                    return '專案各系列中，TOP10來源頻道在時間區間內之聲量的總量佔比。';
                  case 'series_source_channel_compare':
                    return '專案各系列中，TOP10來源頻道在時間區間內之聲量的排行分佈。';
                  default:
                    return '';
                }
              default:
                return '';
            }
          case 'keyword':
            switch (subtype) {
              case 'series_keyword_hot':
                return '在時間區間內，專案各系列話題中提及頻率高的自然詞彙。分數為提及則數。';
              case 'series_keyword_new':
                return '在時間區間內，專案各系列話題與指定區間等長的前期話題相比，撈取前期無但指定區間有的詞彙。分數為提及則數。';
              case 'series_keyword_trend':
                return '在時間區間內，專案各系列話題與指定區間等長的前期話題相比有正成長趨勢的詞彙。分數為趨勢指數。';
              case 'series_keyword_hot_assocs':
                return '在時間區間內，專案各系列話題的高相關詞彙及與該詞彙高相關的子詞彙。';
              default:
                return '';
            }
          default:
            return '';
        }
      case 'product':
        switch (type) {
          case 'volume':
            switch (subtype) {
              case 'product_volume':
                switch (subChartType) {
                  case 'product_volume_trend':
                    return '在時間區間內，專案中各產品聲量加總的趨勢分佈。';
                  case 'product_volume_ranking':
                    return '在時間區間內，專案中各產品聲量加總的排行。';
                  case 'product_volume_proportion':
                    return '在時間區間內，專案中各產品聲量的總量佔比。';
                  default:
                    return '';
                }
              case 'product_series_volume':
                switch (subChartType) {
                  case 'product_series_volume_trend':
                    return '專案各系列中，各項產品在時間區間內每日之聲量的趨勢分佈。';
                  case 'product_series_volume_ranking':
                    return '專案各系列中，各項產品在時間區間內每日之聲量的排行。';
                  case 'product_series_volume_proportion':
                    return '專案各系列中，各項產品在時間區間內之聲量的總量佔比。';
                  case 'product_series_volume_compare':
                    return '專案各系列中，各項產品在時間區間內之聲量的排行分佈。';
                  default:
                    return '';
                }
              default:
                return '';
            }
          case 'source':
            switch (subtype) {
              case 'product_source_category':
                switch (subChartType) {
                  case 'product_source_category_trend':
                    return '專案各系列的各項產品中，來源類型在時間區間內之聲量的趨勢分佈。';
                  case 'product_source_category_ranking':
                    return '專案各系列的各項產品中，來源類型在時間區間內之聲量的排行。';
                  case 'product_source_category_proportion':
                    return '專案各系列的各項產品中，來源類型在時間區間內之聲量的總量佔比。';
                  case 'product_source_category_compare':
                    return '專案各系列的各項產品中，來源類型在時間區間內之聲量排行分佈。';
                  default:
                    return '';
                }
              case 'product_source_website':
                switch (subChartType) {
                  case 'product_source_website_trend':
                    return '專案各系列的各項產品中，TOP20來源網站在時間區間內之聲量的趨勢分佈。';
                  case 'product_source_website_ranking':
                    return '專案各系列的各項產品中，TOP20來源網站在時間區間內之聲量的排行。';
                  case 'product_source_website_proportion':
                    return '專案各系列的各項產品中，TOP20來源網站在時間區間內之聲量的總量佔比。';
                  case 'product_source_website_compare':
                    return '專案各系列的各項產品中，TOP20來源網站在時間區間內之聲量排行分佈。';
                  default:
                    return '';
                }
              case 'product_source_channel':
                switch (subChartType) {
                  case 'product_source_channel_trend':
                    return '專案各系列的各項產品中，TOP20來源頻道在時間區間內之聲量的趨勢分佈。';
                  case 'product_source_channel_ranking':
                    return '專案各系列的各項產品中，TOP20來源頻道在時間區間內之聲量的排行。';
                  case 'product_source_channel_proportion':
                    return '專案各系列的各項產品中，TOP20來源頻道在時間區間內之聲量的總量佔比。';
                  case 'product_source_channel_compare':
                    return '專案各系列的各項產品中，TOP20來源頻道在時間區間內之聲量的排行分佈。';
                  default:
                    return '';
                }
              case 'product_source_social':
                return '指定時間區間內，於專案各產品聲量總和中展示S(來源類型為論壇及Facebook、IG者)和N(來源類型為新聞者)的聲量加總以及S/N比例。';
              default:
                return '';
            }
          case 'sentiment':
            switch (subtype) {
              case 'product_sentiment_overview':
                return '比較時間區間內各情緒分類之TOP20各產品聲量總量。';
              case 'single_product_sentiment_trend':
                return '在時間區間內，情緒類型中所TOP20各產品聲量加總的趨勢分佈。';
              case 'product_sentiment_positive_trend':
                return '在時間區間內，正面情緒中所TOP20各產品聲量加總的趨勢分佈。';
              case 'product_sentiment_negative_trend':
                return '在時間區間內，負面情緒中所TOP20各產品聲量加總的趨勢分佈。';
              case 'product_sentiment_volume':
                return '比較時間區間內各情緒分類之TOP20各產品聲量總量。';
              default:
                return '';
            }
          case 'keyword':
            switch (subtype) {
              case 'product_keyword_hot':
                return '在時間區間內，TOP20各產品話題中提及頻率高的自然詞彙。分數為提及則數。';
              case 'product_keyword_new':
                return '在時間區間內，TOP20各產品話題與指定區間等長的前期話題相比，撈取前期無但指定區間有的詞彙。分數為提及則數。';
              case 'product_keyword_trend':
                return '在時間區間內，TOP20各產品話題與指定區間等長的前期話題相比有正成長趨勢的詞彙。分數為趨勢指數。';
              case 'product_keyword_hot_assocs':
                return '在時間區間內，TOP20各產品話題的高相關詞彙及與該詞彙高相關的子詞彙。';
              default:
                return '';
            }
          default:
            return '';
        }
      default:
        return '';
    }
  };

  static getNeedTabs = (level, type, subtype, subChartType) => {
    switch (level) {
      case 'industry':
        return false;
      case 'brand':
        switch (type) {
          case 'source':
            switch (subtype) {
              case 'brand_source_category':
                switch (subChartType) {
                  case 'brand_source_category_compare':
                    return false;
                  default:
                    return true;
                }
              case 'brand_source_website':
                switch (subChartType) {
                  case 'brand_source_website_compare':
                    return false;
                  default:
                    return true;
                }
              case 'brand_source_channel':
                switch (subChartType) {
                  case 'brand_source_channel_compare':
                    return false;
                  default:
                    return true;
                }
              case 'brand_source_social':
                return false;
              default:
                return true;
            }
          case 'sentiment':
            switch (subtype) {
              case 'single_brand_sentiment_trend':
                return true;
              default:
                return false;
            }
          case 'keyword':
            return true;
          default:
            return false;
        }
      case 'series':
        switch (type) {
          case 'volume':
            switch (subtype) {
              case 'series_volume':
                return false;
              case 'series_brand_volume':
                switch (subChartType) {
                  case 'series_brand_volume_compare':
                    return false;
                  default:
                    return true;
                }
              default:
                return true;
            }
          case 'source':
            switch (subtype) {
              case 'series_source_category':
                switch (subChartType) {
                  case 'series_source_category_compare':
                    return false;
                  default:
                    return true;
                }
              case 'series_source_website':
                switch (subChartType) {
                  case 'series_source_website_compare':
                    return false;
                  default:
                    return true;
                }
              case 'series_source_channel':
                switch (subChartType) {
                  case 'series_source_channel_compare':
                    return false;
                  default:
                    return true;
                }
              default:
                return true;
            }
          default:
            return true;
        }
      case 'product':
        switch (type) {
          case 'volume':
            switch (subtype) {
              case 'product_volume':
                return false;
              case 'product_series_volume':
                switch (subChartType) {
                  case 'product_series_volume_compare':
                    return false;
                  default:
                    return true;
                }
              default:
                return true;
            }
          case 'source':
            switch (subtype) {
              case 'product_source_category':
                switch (subChartType) {
                  case 'product_source_category_compare':
                    return false;
                  default:
                    return true;
                }
              case 'product_source_website':
                switch (subChartType) {
                  case 'product_source_website_compare':
                    return false;
                  default:
                    return true;
                }
              case 'product_source_channel':
                switch (subChartType) {
                  case 'product_source_channel_compare':
                    return false;
                  default:
                    return true;
                }
              case 'product_source_social':
                return false;
              default:
                return true;
            }
          case 'sentiment':
            switch (subtype) {
              case 'single_product_sentiment_trend':
                return true;
              default:
                return false;
            }
          case 'keyword':
            return true;
          default:
            return false;
        }
      default:
        return false;
    }
  };
}
