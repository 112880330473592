import { COLOR_SET } from 'src/consts/chart';
import { getHost } from 'src/utils';
import { generateCompletedDayArray, getTotalDays, generateCSVKey } from 'src/utils/chart';
import { SOURCE_CATEGORY_TYPE } from 'src/consts';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';

export default class IndustrySourceChannelTrendModel {
  constructor(origin, prevOrigin, query) {
    const data = (() => {
      if (origin.length === 0) {
        return null;
      }
      if (origin.every((item) => item.series.every((date) => date.count === 0))) {
        return null;
      }
      const totalDays = getTotalDays();
      return origin.slice(0, 20).map((ori) => {
        if (totalDays >= 32 && totalDays <= 90) {
          const dayArr = generateCompletedDayArray();
          ori.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...ori,
            series: dayArr.map((el) => ({
              date: el.id,
              count: el.count
            }))
          };
        }

        if (totalDays >= 91) {
          const dayArr = generateCompletedDayArray();
          ori.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...ori,
            series: dayArr.map((el) => ({
              date: el.id,
              accurate: `${el.days[0]}~${el.days[el.days.length - 1]}`,
              count: el.count
            }))
          };
        }

        const dayArr = generateCompletedDayArray();
        return {
          ...ori,
          series: dayArr.map((d) => ({
            date: d,
            count: ori.series.find((s) => s.date === d)?.count ?? 0
          }))
        };
      });
    })();

    const previousData = (() => {
      if (prevOrigin.length === 0) {
        return null;
      }
      if (prevOrigin.every((item) => item.series.every((date) => date.count === 0))) {
        return null;
      }
      const totalDays = getTotalDays('previous');
      return prevOrigin.slice(0, 20).map((ori) => {
        if (totalDays >= 32 && totalDays <= 90) {
          const dayArr = generateCompletedDayArray('previous');
          ori.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...ori,
            series: dayArr.map((el) => ({
              date: el.id,
              count: el.count
            }))
          };
        }

        if (totalDays >= 91) {
          const dayArr = generateCompletedDayArray('previous');
          ori.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...ori,
            series: dayArr.map((el) => ({
              date: el.id,
              accurate: `${el.days[0]}~${el.days[el.days.length - 1]}`,
              count: el.count
            }))
          };
        }

        const dayArr = generateCompletedDayArray('previous');
        return {
          ...ori,
          series: dayArr.map((d) => ({
            date: d,
            count: ori.series.find((s) => s.date === d)?.count ?? 0
          }))
        };
      });
    })();

    const csv = [];

    if (data) {
      const presentTime = data[0].series.map((el) => ({
        date: el.date
      }));

      presentTime.forEach((el, di) => {
        const row = el;
        for (let i = 0; i < data.length; i += 1) {
          row[`${data[i].name}`] = data[i].series[di].count;
        }
        csv.push(row);
      });
    }

    if (previousData) {
      const previousTime = previousData[0].series.map((el) => ({
        date: el.date
      }));

      previousTime.forEach((el, di) => {
        const row = el;
        for (let i = 0; i < previousData.length; i += 1) {
          row[`${previousData[i].name}`] = previousData[i].series[di].count;
        }
        csv.push(row);
      });
    }

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'industry_source_channel_trend',
          name: '行業來源頻道趨勢',
          data: [
            ...(
              data && !data.every((el) => el.series.every((date) => date.count === 0))
                ? [{
                  labels: data[0].series.map((el) => el.date),
                  datasets: data.map((el, i) => ({
                    label: el.name,
                    accurate: data[0].series.map((d) => d.accurate ?? d.date),
                    channelId: el.id,
                    data: el.series.map((s) => s.count),
                    borderColor: COLOR_SET[i % 20]
                  })),
                  type: 'line'
                }]
                : [{
                  type: 'nodata',
                  subType: data?.length > 0 ? 'default' : 'ana-error'
                }]
            ),
            ...(
              previousData && !previousData.every((el) => el.series.every((date) => date.count === 0))
                ? [{
                  labels: previousData[0].series.map((el) => el.date),
                  datasets: previousData.map((el, i) => ({
                    label: el.name,
                    accurate: previousData[0].series.map((d) => d.accurate ?? d.date),
                    channelId: el.id,
                    data: el.series.map((s) => s.count),
                    borderColor: COLOR_SET[i % 20]
                  })),
                  type: 'line'
                }] : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{
                        type: 'nodata',
                        subType: previousData?.length > 0 ? 'default' : 'ana-error'
                      }]
                      : []
                  )
                ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType,
                category
              } = query;
              const { datasetIndex, index } = elements[0];
              const [gte, lte] = chart.data.datasets[datasetIndex].accurate[index].split('~');
              const channelId = chart.data.datasets[datasetIndex].channelId;
              const channelName = chart.data.datasets[datasetIndex].label;
              const womCount = chart.data.datasets[datasetIndex].data[index];

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category && {
                  category: category.map((el) => ({
                    id: el,
                    name: SOURCE_CATEGORY_TYPE[el]
                  }))
                }),
                channel: [{
                  id: channelId,
                  name: channelName
                }],
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/行業/來源分析/行業來源頻道/行業來源頻道趨勢'
                },
                industry: true,
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'line',
          options: {
            responsive: true,
            event: ['click'],
            tension: 0.3,
            scales: {
              y: {
                beginAtZero: true,
                grace: '15%',
                title: {
                  display: true,
                  text: '聲量'
                }
              },
              x: {
                title: {
                  display: true,
                  text: '日期'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ]
    };
  }

  static fromRes(data, prevOrigin, query) {
    return new IndustrySourceChannelTrendModel(data, prevOrigin, query);
  }
}
