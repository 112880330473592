import { makeObservable, observable, action, computed, reaction } from 'mobx';
import { diff } from 'deep-diff';

import CheckItemViewModel from './CheckItem/viewModel';

export default class SinglePopoverSelectViewModel {
  @observable list = [];
  @observable open = false;
  @observable keyword = '';
  @observable cacheData = [];

  @computed get searchList() {
    return this.list.filter((el) => el.name.toLowerCase().includes(this.keyword.toLowerCase()));
  }


  @computed get indeterminate() {
    return this.searchList.some((el) => el.checkedCache) && !this.isAllChecked;
  }

  @computed get isAllChecked() {
    return this.searchList.every((el) => el.checkedCache);
  }

  @computed get selected() {
    return this.searchList.filter((el) => el.checked);
  }

  constructor() {
    makeObservable(this);

  }

  @action didMount = () => {

  };

  @action willUnmount = () => {
    const mainContainer = document.querySelector('.main');
    mainContainer.removeEventListener('scroll', this.onCancel);
  };

  @action updateList = (list) => {
    this.list = list.map((el) => new CheckItemViewModel(el));
  };

  @action onKeywordChange = (e) => {
    this.keyword = e.target.value;
  };

  @action onOpenChange = (bool, cb) => {
    this.open = bool;

    if (this.open) {
      const mainContainer = document.querySelector('.main');
      mainContainer.addEventListener('scroll', this.onCancel);
    }

    if (!this.open) {
      this.list.forEach((el) => el.confirmSelect());
      this.keyword = '';
      this.keywordCache = '';
      const mainContainer = document.querySelector('.main');
      mainContainer.removeEventListener('scroll', this.onCancel);

      if (diff(this.selected, this.cacheData)) {
        this.cacheData = this.selected;
        cb();
      }
    }
  };

  @action onCancel = () => {
    this.open = false;
    this.keyword = '';
    this.keywordCache = '';
    this.list.forEach((el) => el.onDisposeSelect());

    const mainContainer = document.querySelector('.main');
    mainContainer.removeEventListener('scroll', this.onCancel);
  };

  @action onAllSelect = () => {
    if (!this.isAllChecked) {
      this.searchList.forEach((el) => el.setSelect(true));
    } else {
      this.searchList.forEach((el) => el.setSelect(false));
    }
  };

  @action onCleanAll = () => {
    this.searchList.forEach((el) => {
      el.setSelect(false);
      el.confirmSelect();
    });
  };
}
