import { userSSOIdentity, userSSOStatus, userVOCLoginAuth, userVocIdentity } from 'src/consts';
import dayjs from 'dayjs';

export default class UserListModel {
  constructor(data) {
    // TODO: 優化
    this.userList = data.map((el) => {
      const {
        assignedSalesName,
        customerBelong,
        email,
        id,
        name,
        status,
        teamName,
        type,
        vocPermission,
        vocStatus,
        lastChangeDate
      } = el;

      return ({
        uid: id,
        userSSOIdentity: userSSOIdentity.find((e) => e.value === type).label,
        userName: name,
        userAccount: email,
        userSSOStatus: userSSOStatus.find((e) => e.value === status).label,
        employeeDep: teamName !== '客戶' ? teamName : null,
        customerDep: customerBelong[0] ? [
          ...customerBelong
        ] : null,
        vocLoginAuth: userVOCLoginAuth.find((e) => e.value === vocStatus)?.label,
        vocIdentity: userVocIdentity.find((e) => e.value === vocPermission)?.label,
        salesman: assignedSalesName,
        lastAdjustTime: lastChangeDate ? dayjs(lastChangeDate).format('YYYY-MM-DD') : '---'
      });
    });
  }

  static fromRes(data) {
    return new UserListModel(data);
  }
}
