import React from 'react';
import { Tooltip as AntdTooltip, Button, Collapse } from 'antd';
import { DownloadOutlined, SaveOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import optionsStore from 'src/stores/optionsStore';
import i18n from 'src/i18n';

import styles from './styles.module.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
);

@observer
class MonthTrend extends React.Component {



  render() {
    const { viewModel } = this.props;
    if (!viewModel.chartData || !viewModel.options) {
      return null;
    }
    return (
      <div className={styles.chartContainer}>
        <div className={clsx('opinion-month-trend-chart', styles.download)}>
          <Collapse
            activeKey={viewModel.isCollapsed ? [] : ['1']}
            expandIcon={() => null}
            onChange={() => { }}
            ghost
            items={[
              {
                key: '1',
                label: (
                  <div className={styles.chartHeader}>
                    <div className={styles.headerTitle}>
                      {i18n.t('opinion_chart_month_trend')}
                    </div>
                    <div className={styles.downloadActions}>
                      <AntdTooltip
                        title={viewModel.isCollapsed ? '展開圖表' : '收合圖表'}
                        placement="bottom"
                      >
                        <Button
                          icon={viewModel.isCollapsed ? <PlusOutlined /> : <MinusOutlined />}
                          onClick={viewModel.toggleCollapse}
                          style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
                        />
                      </AntdTooltip>
                      {
                        !viewModel.isCollapsed
                        && (
                          <>
                            <AntdTooltip
                              title="下載數據"
                              placement="bottom"
                            >
                              <Button
                                icon={<DownloadOutlined />}
                                onClick={viewModel.onDownloadExcel}
                                style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
                              />
                            </AntdTooltip>
                            <AntdTooltip
                              title="下載圖檔"
                              placement="bottom"
                            >
                              <Button
                                icon={<SaveOutlined />}
                                onClick={viewModel.onDownload}
                                style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
                              />
                            </AntdTooltip>
                          </>
                        )
                      }
                    </div>
                  </div>
                ),
                children: (
                  <>
                    <div className={styles.timeStamp}>
                      {`日期範圍：${viewModel.searchData.date.gte} ~ ${viewModel.searchData.date.lte}`}
                    </div>
                    <div
                      style={{ width: '100%', display: 'flex', aspectRatio: 3 }}
                    >
                      <Bar
                        key={`${viewModel.searchData.keyword}_month_trend`}
                        options={viewModel.options}
                        data={viewModel.chartData}
                      />
                    </div>
                  </>
                )
              }
            ]}
          />
        </div>
      </div>
    );
  }
}

MonthTrend.propTypes = {
  viewModel: PropTypes.object
};

MonthTrend.defaultProps = {
  viewModel: {}
};

export default MonthTrend;
