import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { getHost } from 'src/apis/utils';

import kv from 'src/assets/Login/kv.svg';
import kvbg from 'src/assets/Login/kv_bg.svg';

import { RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import styles from './styles.module.scss';

@observer
class LoginPage extends React.Component {
  render() {
    return (
      <div className={styles.pageContainer}>
        <img src={kvbg} alt="" className={styles.bg} />
        <img src={kv} alt="" className={styles.pic} />
        <div className={styles.loginContainer}>
          <div className={styles.title}>
            i-Buzz VOC+
          </div>
          <div className={styles.content}>
            {'最懂行銷人的大數據工具\n提升工作效率加深分析說服力'}
          </div>
          <Button
            icon={<RightOutlined />}
            className={styles.btn}
            href={`${getHost()}/api/v1/auth/login`}
          >
            點此開始登入
          </Button>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {

};

LoginPage.defaultProps = {

};


export default LoginPage;
