import SeriesSourceWebsiteTrendModel from './Trend';
import SeriesSourceWebsiteRankingModel from './Ranking';
import SeriesSourceWebsiteProportionModel from './Proportion';
import SeriesSourceWebsiteCompareModel from './Compare';

export default {
  SeriesSourceWebsiteTrendModel,
  SeriesSourceWebsiteRankingModel,
  SeriesSourceWebsiteProportionModel,
  SeriesSourceWebsiteCompareModel
};
