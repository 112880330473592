import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';

import { RollbackOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ROUTES } from 'src/consts';

import withRouter from 'src/components/withRouter';
import userStore from 'src/stores/userStore';
import PackagesDrawer from './Drawer';

import PackagesManageViewModel from './viewModel';

import ManageSection from './Sections/ManageSection';
import DeleteSection from './Sections/DeleteSection';

import styles from './styles.module.scss';

@observer
class PackagesManagementPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new PackagesManageViewModel();
  }

  componentWillUnmount() {
    userStore.updatePackages();
  }

  render() {
    const { router } = this.props;
    return (
      <>
        <div className={styles.pageContainer}>
          <div className={styles.pageTitleContainer}>
            <div className={styles.textContainer}>
              <div className={styles.titlePrefix} />
              <div className={styles.pageTitle}>
                {i18n.t('packages_page_name')}
              </div>
            </div>
            <Button
              className={styles.backHomeBtn}
              icon={<RollbackOutlined />}
              type="link"
              onClick={() => router.navigate(ROUTES.Home)}
            >
              {i18n.t('packages_back_home')}
            </Button>
          </div>
          {
            this.viewModel.activeSection === 'manage'
              ? (
                <ManageSection
                  viewModel={this.viewModel.manageSectionViewModel}
                  drawerViewModel={this.viewModel.drawerViewModel}
                  onSectionChange={this.viewModel.onSectionChange}
                />
              )
              : (
                <DeleteSection
                  viewModel={this.viewModel.deleteSectionViewModel}
                  drawerViewModel={this.viewModel.drawerViewModel}
                  onSectionChange={this.viewModel.onSectionChange}
                />
              )
          }
        </div>
        <PackagesDrawer viewModel={this.viewModel.drawerViewModel} />
      </>
    );
  }
}

PackagesManagementPage.propTypes = {
  router: PropTypes.object
};

PackagesManagementPage.defaultProps = {
  router: {}
};

export default withRouter(PackagesManagementPage);
