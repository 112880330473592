import * as industryTrend from './industryTrend';
import * as marketDifferentiation from './marketDifferentiation';
import * as brandCompetition from './brandComp';
import * as brandHealth from './brandHealth';
import * as brandEffect from './brandEffect';
import * as brandRoutine from './brandRoutine';

export default {
  industryTrend,
  marketDifferentiation,
  brandCompetition,
  brandHealth,
  brandEffect,
  brandRoutine
};
