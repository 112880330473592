import diff from 'deep-diff';

import { Action } from './actions';

export const reducer = (state, action) => {

  switch (action.type) {
    case Action.UpdateProfile: {
      if (diff(state.profile, action.payload)) {
        return {
          ...state,
          profile: action.payload
        };
      }

      return state;
    }

    default:
      return state;
  }
};
