export default class AnnouncementModel {
  constructor(data) {
    const {
      _id,
      text,
      date,
      isShow
    } = data;

    this.id = _id;
    this.text = text;
    this.date = date;
    this.isShow = isShow;
  }

  static fromRes(data) {
    return new AnnouncementModel(data);
  }
}
