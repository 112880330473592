import BrandSourceCategoryTrendModel from './Trend';
import BrandSourceCategoryRankingModel from './Ranking';
import BrandSourceCategoryProportionModel from './Proportion';
import BrandSourceCategoryCompareModel from './Compare';

export default {
  BrandSourceCategoryTrendModel,
  BrandSourceCategoryRankingModel,
  BrandSourceCategoryProportionModel,
  BrandSourceCategoryCompareModel
};
