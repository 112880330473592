import { Empty } from 'antd';

import LineChart from './LineChart';
import LineChartViewModel from './LineChart/viewModel';

import PieChart from './PieChart';
import PieChartViewModel from './PieChart/viewModel';

import BarChart from './BarChart';
import BarChartViewModel from './BarChart/viewModel';

import CustomizeBarChart from './CustomizeBarChart';
import CustomizeBarChartModel from './CustomizeBarChart/viewModel';

import HorizontalBarChart from './HorizontalBarChart ';
import HorizontalBarChartViewModel from './HorizontalBarChart /viewModel';

import CustomizeStackBarChart from './CustomizeStackBarChart';
import CustomizeStackBarChartViewModel from './CustomizeStackBarChart/viewModel';

import RadarChart from './RadarChart';
import RadarChartViewModel from './RadarChart/viewModel';

import ScatterChart from './ScatterChart';
import ScatterChartViewModel from './ScatterChart/viewModel';

import WordCloud from './WordCloud';
import WordCloudViewModel from './WordCloud/viewModel';

import WordAssocs from './WordAssocs';
import WordAssocsViewModel from './WordAssocs/viewModel';

import NoDataChart from './NoData';
import NoDataChartViewModel from './NoData/viewModel';

// > NODATA CHART
import NoDataPie from './NoData/components/NodataPie';

export default class ChartFactory {
  static createChartViewModel(data, parent = {}) {
    switch (data?.data?.type ?? data?.type) {
      case 'line':
        return new LineChartViewModel(data);
      case 'pie':
        return new PieChartViewModel(data);
      case 'bar':
        return new BarChartViewModel(data);
      case 'horizontal-bar':
        return new HorizontalBarChartViewModel(data);
      case 'customize-bar':
        return new CustomizeBarChartModel(data);
      case 'customize-stack-bar':
        return new CustomizeStackBarChartViewModel(data);
      case 'radar':
        return new RadarChartViewModel(data);
      case 'customize-scatter':
        return new ScatterChartViewModel(data, parent);
      case 'words':
        return new WordCloudViewModel(data, parent);
      case 'word-assocs':
        return new WordAssocsViewModel(data, parent);
      default:
        return new NoDataChartViewModel(data);
    }
  }

  static generateChartView(viewModel) {
    switch (viewModel?.type) {
      case 'line':
        return <LineChart viewModel={viewModel} />;
      case 'pie':
        return <PieChart viewModel={viewModel} />;
      case 'bar':
        return <BarChart viewModel={viewModel} />;
      case 'horizontal-bar':
        return <HorizontalBarChart viewModel={viewModel} />;
      case 'customize-bar':
        return <CustomizeBarChart viewModel={viewModel} />;
      case 'customize-stack-bar':
        return <CustomizeStackBarChart viewModel={viewModel} />;
      case 'radar':
        return <RadarChart viewModel={viewModel} />;
      case 'scatter':
        return <ScatterChart viewModel={viewModel} />;
      case 'words':
        return <WordCloud viewModel={viewModel} />;
      case 'word-assocs':
        return <WordAssocs viewModel={viewModel} />;
      default:
        return <NoDataChart viewModel={viewModel} subChart={ChartFactory.generateNoDataChart(viewModel.subtype)} />;
    }
  }

  static generateNoDataChart(subtype) {
    switch (subtype) {
      case 'pie':
        return (
          <NoDataPie />
        );
      case 'ana-error':
        return (
          <Empty
            description={(
              <div style={{ color: 'red' }}>
                暫無分析結果
              </div>
            )}
          />
        );
      default:
        return (
          <Empty
            description={(
              <div>
                無相關聲量數據
              </div>
            )}
          />
        );
    }
  }
}
