import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import { Waypoint } from 'react-waypoint';
import { CaretDownOutlined, RollbackOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';

import ScrollTop from 'src/components/ScrollTop';
import RangeDatePicker from 'src/components/datepicker';

import { PACKAGE_SORT_TYPE } from 'src/consts';
import { DELETE_PACKAGES_TABLE } from 'src/consts/tables';
import SearchIcon from 'src/assets/MenuIcons/SearchIcon.svg';

import styles from './styles.module.scss';

@observer
class DeleteSection extends React.Component {

  componentDidMount() {
    this.props.viewModel.init();
  }

  render() {
    const { viewModel, drawerViewModel, onSectionChange } = this.props;
    return (
      <>
        <div className={styles.contentContainer}>
          <div className={styles.actionContainer}>
            <div className={styles.filterContainer}>
              <div className={styles.filterDateText}>
                {i18n.t('packages_filter_delete_date_title')}
              </div>
              <RangeDatePicker
                className={styles.dateSelector}
                suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                allowEmpty={[true, true]}
                value={viewModel.filterDate}
                onChange={viewModel.onDateChange}
                onOpenChange={viewModel.onPickerChange}
              />
            </div>
            <div className={styles.btnContainer}>
              <Button
                className={styles.backBtn}
                icon={<RollbackOutlined />}
                onClick={() => onSectionChange('manage')}
              >
                {i18n.t('packages_back_manage')}
              </Button>
            </div>
          </div>
          <div className={styles.resContainer}>
            <div className={styles.tableActionsContainer}>
              <div className={styles.searchContainer}>
                <div className={styles.searchInputContainer}>
                  <input
                    className={styles.searchInput}
                    placeholder={i18n.t('packages_search_placeholder')}
                    value={viewModel.keyword}
                    onChange={viewModel.onChange}
                    onKeyDown={viewModel.onKeyDown}
                  />
                  <div
                    className={styles.searchBtn}
                    onClick={viewModel.onSubmit}
                  >
                    <img src={SearchIcon} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.tableContainer}>
              <Table
                key={viewModel.packages.length}
                rowClassName={styles.row}
                rowKey={(item) => item.id}
                dataSource={viewModel.packages}
                pagination={false}
                className="packagesTable"
                loading={viewModel.isProcess}
              >
                {
                  DELETE_PACKAGES_TABLE.map((el) => {
                    if (el.key === 'action') {
                      return (
                        <Table.Column
                          title={el.title}
                          dataIndex={el.dataIndex}
                          key={el.key}
                          className={styles.columns}
                          width="25%"
                          render={(_, record) => {
                            return (
                              <Button
                                type="link"
                                className={styles.actionBtn}
                                onClick={() => viewModel.onCheckPackage(record.id, drawerViewModel.onReadOnlyDrawerOpen)}
                              >
                                {i18n.t('packages_table_action_check_package')}
                              </Button>
                            );
                          }}
                        />
                      );
                    }
                    if (el.key === 'users') {
                      return (
                        <Table.Column
                          title={el.title}
                          dataIndex={el.dataIndex}
                          key={el.key}
                          className={styles.columns}
                          width="25%"
                          render={(text, record) => {
                            if (record.users.length === 4) {
                              const content = record.users.slice(0, 3).map((user) => {
                                return user.name;
                              }).join(',  ');
                              return <div>{`${content}, ...`}</div>;
                            }

                            const content = record.users.map((user) => {
                              return user.name;
                            }).join(',  ');
                            return <div>{content}</div>;
                          }}
                        />
                      );
                    }
                    if (el.key === 'adjustTime') {
                      return (
                        <Table.Column
                          title={(
                            <div
                              className={styles.tableTitleContainer}
                              onClick={viewModel.onSortChange}
                            >
                              <div>
                                {el.title}
                              </div>
                              <div className={styles.sortIconContainer}>
                                <CaretUpOutlined className={clsx(styles.up, viewModel.sortedBy === PACKAGE_SORT_TYPE.LastChangeDateAsc && styles.activeIcon)} />
                                <CaretDownOutlined className={clsx(styles.down, viewModel.sortedBy === PACKAGE_SORT_TYPE.LastChangeDateDesc && styles.activeIcon)} />
                              </div>
                            </div>
                          )}
                          dataIndex={el.dataIndex}
                          key={el.key}
                          className={styles.columns}
                          width="25%"
                          render={(text, record) => {
                            if (!text) {
                              return <div>---</div>;
                            }
                            return <div>{text}</div>;
                          }}
                        />
                      );
                    }
                    return (
                      <Table.Column
                        title={el.title}
                        dataIndex={el.dataIndex}
                        key={el.key}
                        className={styles.columns}
                        width="12.5%"
                        render={(text, record) => {
                          if (!text) {
                            return <div>---</div>;
                          }
                          return <div>{text}</div>;
                        }}
                      />
                    );
                  })
                }
              </Table>
            </div>
            {
              viewModel.anchor
              && (
                <Waypoint
                  onEnter={viewModel.getPackages}
                />
              )
            }
          </div>
        </div>
        <ScrollTop containerName="main" />
      </>
    );
  }
}

DeleteSection.propTypes = {
  viewModel: PropTypes.object,
  drawerViewModel: PropTypes.object,
  onSectionChange: PropTypes.func
};

DeleteSection.defaultProps = {
  viewModel: {},
  drawerViewModel: {},
  onSectionChange: () => { }
};


export default DeleteSection;
