import Cookies from 'js-cookie';

import { AUTH_TOKEN_KEY } from 'src/constants';


export default class Auth {
  static hasToken() {
    if (Cookies.get(AUTH_TOKEN_KEY) === 'undefined') {
      Auth.removeToken();
    }
    return !!Cookies.get(AUTH_TOKEN_KEY);
  }

  static getToken() {
    return Cookies.get(AUTH_TOKEN_KEY);
  }

  static removeToken() {
    Cookies.remove(AUTH_TOKEN_KEY);
  }

  static hasJWT() {
    return !!Cookies.get('jwt');
  }

  static setToken() {
    return new Promise((res) => {
      const jwt = Cookies.get('jwt');
      Cookies.set(AUTH_TOKEN_KEY, jwt);
      Cookies.remove('jwt');
      res();
    });
  }
}
