import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import { Checkbox } from 'antd';

import styles from './styles.module.scss';

@observer
class CheckItem extends React.Component {
  render() {
    const { viewModel, afterBrandSelect, disabled } = this.props;
    return (
      <div className={styles.levelItem}>
        <Checkbox
          checked={viewModel.checkedCache}
          onChange={(e) => viewModel.toggleChecked(e, afterBrandSelect)}
          disabled={disabled}
        />
        <div className={styles.levelName}>
          {viewModel.name}
        </div>
      </div>
    );
  }
}

CheckItem.propTypes = {
  viewModel: PropTypes.object,
  afterBrandSelect: PropTypes.func,
  disabled: PropTypes.bool
};

CheckItem.defaultProps = {
  viewModel: {},
  afterBrandSelect: () => {},
  disabled: false
};

export default CheckItem;

