import { makeObservable, observable, action, computed, runInAction, reaction } from 'mobx';
import html2canvas from 'html2canvas';
import { message } from 'antd';
import { REPUTATION_OPTIONS } from 'src/consts';
import * as xlsx from 'xlsx';
import { COLOR_SET } from 'src/consts/chart';

import AdvanceAnalysisService from 'src/services/advanceAnalysis';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import packageStore from 'src/stores/packageStore';
import SinglePopoverSelectViewModel from 'src/components/SinglePopoverSelect/viewModel';
import optionsStore from 'src/stores/optionsStore';

import ChartItemViewModel from '../components/ChartItem/viewModel';

export default class ReputationPageViewModel {
  @observable selectedChart = 'brand';

  @observable selectedPostType = 'all';
  @observable selectedWOM = 'all';

  @observable brandPopover = new SinglePopoverSelectViewModel();
  @observable productPopover = new SinglePopoverSelectViewModel();

  @observable brandRes = {};
  @observable brandChart = {};
  @observable brandList = [];

  @observable brandPreviousRes = {};
  @observable brandPreviousChart = {};
  @observable brandPreviousList = [];

  @observable productRes = {};
  @observable productChart = {};
  @observable productList = [];

  @observable productPreviousRes = {};
  @observable productPreviousChart = {};
  @observable productPreviousList = [];

  @observable isDownload = false;
  @observable downloadList = [];
  @observable downloadFileName = '';

  @computed get isShowStatisticClean() {
    return this.selectedPostType !== 'all' || this.selectedWOM !== 'all'
      || (this.selectedChart === 'brand' && this.brandPopover.selected.length > 0)
      || (this.selectedChart === 'product' && this.productPopover.selected.length > 0);
  }

  @computed get chartTitle() {
    return REPUTATION_OPTIONS.find((el) => el.id === this.selectedChart)?.name;
  }

  @computed get chartDesc() {
    return `i-Buzz VOC+提供獨家聲譽定位分析圖，透過正負評價聲量比率的P/Ｎ值與${this.selectedChart === 'brand' ? '品牌' : '產品'}聲量，一圖了解品牌在市場上的定位。`;
  }

  @computed get combineBrandList() {
    const brand = [];
    brand.push(...this.brandList);
    if (infoHeaderViewModel.multiViewModel.hasCompare) {
      this.brandPreviousList.forEach((el) => {
        const target = brand.find((brandItem) => brandItem.id === el.id);
        if (!target) {
          brand.push(el);
        }
      });
    }
    return brand.map((el, i) => ({
      ...el,
      color: COLOR_SET[i % 20]
    }));
  }

  @computed get combineProductList() {
    const product = [];
    product.push(...this.productList);
    if (infoHeaderViewModel.multiViewModel.hasCompare) {
      this.productPreviousList.forEach((el) => {
        const target = product.find((productItem) => productItem.id === el.id);
        if (!target) {
          product.push(el);
        }
      });
    }
    return product.map((el, i) => ({
      ...el,
      color: COLOR_SET[i % 20]
    }));
  }

  constructor() {
    makeObservable(this);

    this.init();
  }

  @action init = () => {
    if (packageStore.isLevelProcessing) {
      const once = reaction(
        () => packageStore.isLevelProcessing,
        (bool) => {
          if (!bool) {
            this.checkLevelOpen();
            this.getGraphicData();
            once();
          }
        }
      );
    } else {
      this.checkLevelOpen();
      this.getGraphicData();
    }
  };

  @action checkLevelOpen = () => {
    if (!packageStore.opened.includes('brand')) {
      this.selectedChart = 'product';
    }
  };

  @action onChartSelect = (e, router) => {
    this.selectedChart = e;
    if (!packageStore.opened.includes(e)) {
      message.error('未開放此功能，請與業務確認權限');
      router.navigate('/home', { replace: true });
    }

    this.getGraphicData();
  };

  @action onPostTypeChange = (e) => {
    this.selectedPostType = e;
  };

  @action onWOMChange = (e) => {
    this.selectedWOM = e;
  };

  @action onCleanStatisticFilter = () => {
    this.selectedPostType = 'all';
    this.selectedWOM = 'all';
    if (this.selectedChart === 'brand') {
      this.brandPopover.onCleanAll();
    }

    if (this.selectedChart === 'product') {
      this.productPopover.onCleanAll();
    }

    this.onSubmit();
  };

  @action onDateSelect = async () => {
    this.brandChart.onReady = false;
    this.brandPreviousChart.onReady = false;
    this.productChart.onReady = false;
    this.productPreviousChart.onReady = false;

    this.brandPopover.updateList([]);
    this.productPopover.updateList([]);

    this.getGraphicData();
  };

  @action onSubmit = () => {
    if (this.selectedChart === 'brand') {
      this.brandChart.onReady = false;
      this.brandPreviousChart.onReady = false;
    }

    if (this.selectedChart === 'product') {
      this.productChart.onReady = false;
      this.productPreviousChart.onReady = false;
    }

    this.getGraphicData();
  };

  @action getGraphicData = async () => {
    optionsStore.setLoading('reputation');
    if (this.selectedChart === 'brand') {
      await Promise.all([
        this.getBrandReputationChart(),
        ...(infoHeaderViewModel.multiViewModel.hasCompare ? [this.getBrandReputationChart('previous')] : [])
      ]);
      runInAction(() => {
        if (this.brandPopover.list.length === 0) {
          this.brandPopover.updateList(packageStore.brand);
        }
        this.brandRes.chartData[0].data[0].datasets = this.brandRes.chartData[0].data[0].datasets.map((el) => ({
          ...el,
          backgroundColor: this.combineBrandList.find((brand) => brand.id === el.brandId).color
        }));
        this.brandChart = new ChartItemViewModel(this.brandRes, this);
        if (infoHeaderViewModel.multiViewModel.hasCompare) {
          this.brandPreviousRes.chartData[0].data[0].datasets = this.brandPreviousRes.chartData[0].data[0].datasets.map((el) => ({
            ...el,
            backgroundColor: this.combineBrandList.find((brand) => brand.id === el.brandId).color
          }));
          this.brandPreviousChart = new ChartItemViewModel(this.brandPreviousRes, this);
        }
      });
    } else {
      await Promise.all([
        this.getProductReputationChart(),
        ...(infoHeaderViewModel.multiViewModel.hasCompare ? [this.getProductReputationChart('previous')] : [])
      ]);
      runInAction(() => {
        if (this.productPopover.list.length === 0) {
          this.productPopover.updateList(packageStore.flatProduct);
        }
        this.productRes.chartData[0].data[0].datasets = this.productRes.chartData[0].data[0].datasets?.map((el) => ({
          ...el,
          backgroundColor: this.combineProductList.find((p) => p.id === el.productId).color
        })) ?? [];
        this.productChart = new ChartItemViewModel(this.productRes, this);
        if (infoHeaderViewModel.multiViewModel.hasCompare) {
          this.productPreviousRes.chartData[0].data[0].datasets = this.productPreviousRes.chartData[0].data[0].datasets?.map((el) => ({
            ...el,
            backgroundColor: this.combineProductList.find((p) => p.id === el.productId).color
          })) ?? [];
          this.productPreviousChart = new ChartItemViewModel(this.productPreviousRes, this);
        }
      });
    }

    runInAction(() => {
      optionsStore.setCompleted('reputation');
    });
  };

  @action getBrandReputationChart = async (time = 'present') => {
    try {

      if (time === 'present' && this.brandChart.onReady) {
        return;
      }
      if (time === 'previous' && this.brandPreviousChart.onReady) {
        return;
      }
      const [gte, lte] = time === 'present'
        ? infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'))
        : infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD'));

      const { data } = await AdvanceAnalysisService.getBrandReputation({
        date: { gte, lte },
        ...(this.selectedPostType !== 'all' && { postType: this.selectedPostType }),
        ...(this.selectedWOM !== 'all' && { womType: this.selectedWOM }),
        ...(this.brandPopover.selected.length > 0 && { search: this.brandPopover.selected.map((el) => el.id) })
      });

      runInAction(() => {
        if (time === 'present') {
          this.brandRes = data;
          this.brandList = data.brand ?? [];
        } else {
          this.brandPreviousRes = data;
          this.brandPreviousList = data.brand ?? [];
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action getProductReputationChart = async (time = 'present') => {
    try {
      if (time === 'present' && this.productChart.onReady) {
        return;
      }
      if (time === 'previous' && this.productPreviousChart.onReady) {
        return;
      }

      const [gte, lte] = time === 'present'
        ? infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'))
        : infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD'));

      const { data } = await AdvanceAnalysisService.getProductReputation({
        date: { gte, lte },
        ...(this.selectedPostType !== 'all' && { postType: this.selectedPostType }),
        ...(this.selectedWOM !== 'all' && { womType: this.selectedWOM }),
        ...(this.productPopover.selected.length > 0 && { search: this.productPopover.selected.map((el) => el.id) })
      });

      runInAction(() => {
        if (time === 'present') {
          this.productRes = data;
          this.productList = data.product ?? [];
        } else {
          this.productPreviousRes = data;
          this.productPreviousList = data.product ?? [];
        }
      });
    } catch (error) {
      console.log(error);
    }
  };


  @action onDownloadCsv = () => {
    const { hasCompare, currentTimeText, previousTimeText } = infoHeaderViewModel.multiViewModel;
    const targetChart = this.selectedChart === 'brand' ? this.brandChart : this.productChart;
    const targetPrevChart = this.selectedChart === 'brand' ? this.brandPreviousChart : this.productPreviousChart;
    const { csv, csvKey } = targetChart.data[0].chart[0];
    const prevCsv = targetPrevChart?.data[0]?.chart?.[0].csv ?? [];

    if (!csv || !csvKey || (hasCompare && !prevCsv)) {
      message.info('聲量數據不存在，無法下載檔案');
      return;
    }
    const data = [...csv, ...(hasCompare ? prevCsv : [])];
    const header = csvKey.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [csvKey.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    const wordbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wordbook, workSheet, '定位圖');
    xlsx.writeFile(wordbook, `${packageStore.activePackageName}-${this.selectedChart === 'brand' ? '品牌聲譽定位' : '產品聲譽定位'}-${currentTimeText}${hasCompare ? `-${previousTimeText}` : ''}.xlsx`);
  };

  @action onDownload = async () => {
    await optionsStore.onImgDownload();
    const chart = document.querySelector('.download-chart');
    const canvas = await html2canvas(chart);
    const a = document.createElement('a');
    const { hasCompare, currentTimeText, previousTimeText } = infoHeaderViewModel.multiViewModel;
    a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
    a.download = `${packageStore.activePackageName}-${this.selectedChart === 'brand' ? '品牌聲譽定位' : '產品聲譽定位'}-${currentTimeText}${hasCompare ? `-${previousTimeText}` : ''}.jpg`;
    a.click();
    optionsStore.onImgDownloadEnd();
  };
}
