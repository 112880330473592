import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';

import SearchIcon from 'src/assets/MenuIcons/SearchIcon.svg';

import { CaretDownOutlined } from '@ant-design/icons';
import { Popover, Empty } from 'antd';

import styles from './styles.module.scss';

@observer
class PopoverSelect extends React.Component {
  componentDidMount() {
    this.props.viewModel.didMount();
  }
  componentWillUnmount() {
    this.props.viewModel.willUnmount();
  }
  render() {
    const { placeholder, viewModel, disabled } = this.props;
    return (
      <Popover
        placement="bottomLeft"
        showArrow={false}
        open={viewModel.open}
        trigger="click"
        onOpenChange={(bool) => viewModel.onOpenChange(bool, disabled)}
        content={(
          <div className={styles.switchProjectPopover}>
            <div className={styles.search}>
              <div className={styles.searchContainer}>
                <input
                  className={styles.searchInput}
                  placeholder={placeholder}
                  value={viewModel.keyword}
                  onChange={viewModel.onKeywordChange}
                  onKeyDown={viewModel.onKeyDown}
                />
                <div
                  className={styles.searchBtn}
                  onClick={viewModel.onSubmit}
                >
                  <img src={SearchIcon} alt="" />
                </div>
              </div>
            </div>
            <div className={styles.customerList}>
              {
                viewModel.searchRes.length > 0
                  ? (
                    <>
                      {
                        viewModel.searchRes.map((el) => {
                          return (
                            <div
                              key={el.id}
                              className={clsx(
                                styles.customerCard,
                                el.id === viewModel.selected.id && styles.isActive
                              )}
                              onClick={() => viewModel.onSelect(el)}
                            >
                              <div className={styles.name}>
                                {el.name}
                              </div>
                            </div>
                          );
                        })
                      }
                    </>
                  )
                  : (
                    <div className={styles.emptyContainer}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  )
              }

            </div>
          </div>
        )}
      >
        <div className={clsx(styles.selector, disabled && styles.inactive)}>
          <div className={clsx(styles.text, styles.active)}>
            {placeholder}
          </div>
          <CaretDownOutlined className={styles.icon} />
        </div>
      </Popover>
    );
  }
}

PopoverSelect.propTypes = {
  viewModel: PropTypes.object,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
};

PopoverSelect.defaultProps = {
  viewModel: {},
  placeholder: '請選擇',
  disabled: false
};


export default PopoverSelect;
