import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import i18n from 'src/i18n';
import dayjs from 'dayjs';

import { Drawer, Switch, Select, Button, Input, InputNumber, DatePicker, Collapse, Modal } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { userVOCLoginAuth, userVocIdentity } from 'src/consts';
import userStore from 'src/stores/userStore';

import RightArrowIcon from 'src/assets/RightArrowIcon.svg';
import PaperIcon from 'src/assets/PaperIcon.svg';
import DiamondIcon from 'src/assets/DiamondIcon.svg';

import styles from './styles.module.scss';

@observer
class EditDrawer extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <>
        <Drawer
          open={viewModel.isDrawerOpen}
          closable={false}
          onClose={viewModel.onDrawerClose}
          className={clsx('editDrawer', styles.drawerContainer)}
          bodyStyle={{ padding: 0, display: 'grid', gridTemplateRows: '1fr auto' }}
          width={576}
        >
          <div className={styles.contentContainer}>
            <div className={styles.section}>
              <div className={styles.settingHeader}>
                <img src={DiamondIcon} alt="" />
                <div className={styles.settingTitle}>
                  {i18n.t('account_manage_basic_setting_title')}
                </div>
              </div>
              <div className={styles.ssoBlock}>
                <div className={styles.ssoRow}>
                  <div className={clsx(styles.rowTitle, styles.disabledTitle)}>
                    {i18n.t('account_manage_setting_identity')}
                  </div>
                  <div className={styles.rowContent}>
                    <Input
                      className={clsx(styles.inputStyle, styles.disabledInput)}
                      disabled
                      value={viewModel.userSSOIdentity}
                    />
                  </div>
                </div>

                <div className={styles.ssoRow}>
                  <div className={clsx(styles.rowTitle, styles.disabledTitle)}>
                    {i18n.t('account_manage_setting_department')}
                  </div>
                  <div className={styles.rowContent}>
                    <Input
                      className={clsx(styles.inputStyle, styles.disabledInput)}
                      disabled
                      value={viewModel.userDepartment}
                    />
                  </div>
                </div>

                <div className={styles.ssoRow}>
                  <div className={clsx(styles.rowTitle, styles.disabledTitle)}>
                    {i18n.t('account_manage_setting_user_name')}
                  </div>
                  <div className={styles.rowContent}>
                    <Input
                      className={clsx(styles.inputStyle, styles.disabledInput)}
                      disabled
                      value={viewModel.userName}
                    />
                  </div>
                </div>

                <div className={styles.ssoRow}>
                  <div className={clsx(styles.rowTitle, styles.disabledTitle)}>
                    {i18n.t('account_manage_setting_user_account')}
                  </div>
                  <div className={styles.rowContent}>
                    <Input
                      className={clsx(styles.inputStyle, styles.disabledInput)}
                      disabled
                      value={viewModel.userAccount}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.vocBlock}>
                <div className={styles.vocRow}>
                  <div className={styles.rowTitle}>
                    {i18n.t('account_manage_setting_voc_login_auth')}
                  </div>
                  <div className={styles.rowContent}>
                    <Select
                      className={clsx(styles.rowSelect)}
                      suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                      options={userVOCLoginAuth}
                      value={viewModel.vocLoginAuth}
                      onSelect={viewModel.onVOCLoginAuthChange}
                      disabled={viewModel.isMyData}
                    />
                  </div>
                </div>

                <div className={styles.vocRow}>
                  <div className={styles.rowTitle}>
                    {i18n.t('account_manage_setting_voc_identity')}
                  </div>
                  <div className={styles.rowContent}>
                    <Select
                      className={clsx(styles.rowSelect)}
                      suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                      value={viewModel.vocIdentity}
                      onSelect={viewModel.onVOCIdentityChange}
                      disabled={viewModel.isMyData}
                    >
                      {
                        userVocIdentity.map((el) => {
                          return (
                            <Select.Option key={el.value} value={el.value} title={el.label} disabled={!viewModel.availableIdentity.includes(el.value)}>
                              {el.label}
                            </Select.Option>
                          );
                        })
                      }
                    </Select>
                  </div>
                </div>

                {
                  viewModel.vocIdentity === 'customer'
                  && (
                    <>
                      <div className={clsx(styles.vocRow, viewModel.errorSales && styles.errorRow)}>
                        <div className={styles.rowTitle}>
                          {i18n.t('account_manage_setting_salesman')}
                        </div>
                        <div className={styles.rowContent}>
                          <div className={styles.salesmanContainer}>
                            <Select
                              className={clsx(styles.rowSelect)}
                              suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                              options={viewModel.departmentList}
                              value={viewModel.salesmanDep}
                              onSelect={viewModel.onSalesmanDepartmentChange}
                              placeholder={i18n.t('account_manage_setting_dep_placeholder')}
                              disabled={userStore.userType === 'member' || userStore.userType === 'teamLeader'}
                            />
                            <Select
                              className={clsx(styles.rowSelect)}
                              suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                              options={viewModel.showSales}
                              value={viewModel.salesman}
                              disabled={!viewModel.salesmanDep}
                              onSelect={viewModel.onSalesmanChange}
                              placeholder={i18n.t('account_manage_setting_salesman_placeholder')}
                            />
                          </div>
                          <div className={styles.errorNote}>
                            請選擇指定業務
                          </div>
                        </div>
                      </div>
                      <div className={clsx(styles.vocRow, viewModel.errorTime && styles.errorRow)}>
                        <div className={styles.rowTitle}>
                          {i18n.t('account_manage_setting_account_valid')}
                        </div>
                        <div className={styles.rowContent}>
                          <DatePicker.RangePicker
                            className={clsx(styles.rowPicker)}
                            placeholder={['起始時間', '結束時間']}
                            value={viewModel.accountValid}
                            onCalendarChange={viewModel.onAccountValidChange}
                            disabledDate={(current) => {
                              return current < dayjs().startOf('day');
                            }}
                          />
                          <div className={styles.errorNote}>
                            請選擇帳戶起訖時間
                          </div>
                        </div>
                      </div>
                      <div className={clsx(styles.vocRow, viewModel.errorPackages && styles.errorRow)}>
                        <div className={styles.rowTitle}>
                          {i18n.t('account_manage_setting_open_packages')}
                        </div>
                        <div className={styles.rowContent}>
                          <InputNumber
                            className={clsx(styles.rowInput)}
                            value={viewModel.openPackages}
                            onChange={viewModel.onOpenPackagesChange}
                          />
                          <div className={styles.errorNote}>
                            請輸入開放數據包數量
                          </div>
                        </div>
                      </div>
                    </>
                  )
                }

              </div>
            </div>
            <div className={styles.section}>
              <div className={styles.settingHeader}>
                <img src={DiamondIcon} alt="" />
                <div className={styles.settingTitle}>
                  {i18n.t('account_manage_auth_setting_title')}
                </div>
              </div>

              <div className={clsx(styles.switchSection, styles.borderBottom)}>
                <div className={styles.switchRow}>
                  <div className={styles.switchItem}>
                    <Switch
                      className={styles.switch}
                      onChange={viewModel.onSearchToggle}
                      checked={viewModel.search}
                    />
                    <div className={styles.switchTitle}>
                      {i18n.t('search_name')}
                    </div>
                  </div>
                  <div className={styles.switchItem}>
                    <Switch
                      className={styles.switch}
                      onChange={viewModel.onTopicToggle}
                      checked={viewModel.topic}
                    />
                    <div className={styles.switchTitle}>
                      {i18n.t('topic_page_name')}
                    </div>
                  </div>
                </div>

                <div className={styles.switchRow}>
                  <div className={styles.switchItem}>
                    <Switch
                      className={styles.switch}
                      onChange={viewModel.onChartToggle}
                      checked={viewModel.chart}
                    />
                    <div className={styles.switchTitle}>
                      {i18n.t('chart_page_name')}
                    </div>
                  </div>
                  <div className={styles.switchItem}>
                    <Switch
                      className={styles.switch}
                      onChange={viewModel.onWordBookToggle}
                      checked={viewModel.wordbook}
                    />
                    <div className={styles.switchTitle}>
                      {i18n.t('wordbook_page_name')}
                    </div>
                  </div>
                </div>

                <div className={styles.switchRow}>
                  <div className={styles.switchItem}>
                    <Switch
                      className={styles.switch}
                      onChange={viewModel.onAdvanceSearchToggle}
                      checked={viewModel.advanceSearch}
                    />
                    <div className={styles.switchTitle}>
                      {i18n.t('advance_search_page_name')}
                    </div>
                  </div>
                  <div className={styles.switchItem}>
                    <Switch
                      className={styles.switch}
                      onChange={viewModel.onRelatedToggle}
                      checked={viewModel.related}
                    />
                    <div className={styles.switchTitle}>
                      {i18n.t('related_page_name')}
                    </div>
                  </div>
                </div>
                {
                  viewModel.vocIdentity !== 'customer'
                  && (
                    <div className={styles.switchRow}>
                      <div className={styles.switchItem}>
                        <Switch
                          className={styles.switch}
                          onChange={viewModel.onBulletinToggle}
                          checked={viewModel.notification}
                        />
                        <div className={styles.switchTitle}>
                          {i18n.t('bulletin_page_name')}
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
              <Collapse
                bordered={false}
                defaultActiveKey={['data-report', 'advance']}
                className={styles.collapse}
              >
                <Collapse.Panel
                  header={
                    (
                      <div className={styles.panelTitle}>
                        <img src={PaperIcon} alt="" className={styles.panelIcon} />
                        <div className={styles.panelText}>
                          {i18n.t('data_report_page_name')}
                        </div>
                        <CaretDownOutlined />
                      </div>
                    )
                  }
                  key="data-report"
                  showArrow={false}
                  className={styles.collapsePanel}
                >
                  <div className={styles.switchSection}>
                    <div className={styles.switchRow}>
                      <div className={styles.switchItem}>
                        <Switch
                          className={styles.switch}
                          onChange={viewModel.onIndustryTrendToggle}
                          checked={viewModel.industryTrend}
                        />
                        <div className={styles.switchTitle}>
                          {i18n.t('data_report_type_industry_trend')}
                        </div>
                      </div>
                      <div className={styles.switchItem}>
                        <Switch
                          className={styles.switch}
                          onChange={viewModel.onMarketDifferentiationToggle}
                          checked={viewModel.marketDifferentiation}
                        />
                        <div className={styles.switchTitle}>
                          {i18n.t('data_report_type_market_differentiation')}
                        </div>
                      </div>
                    </div>
                    <div className={styles.switchRow}>
                      <div className={styles.switchItem}>
                        <Switch
                          className={styles.switch}
                          onChange={viewModel.onBrandCompetitionToggle}
                          checked={viewModel.brandCompetition}
                        />
                        <div className={styles.switchTitle}>
                          {i18n.t('data_report_type_brand_competition')}
                        </div>
                      </div>
                      <div className={styles.switchItem}>
                        <Switch
                          className={styles.switch}
                          onChange={viewModel.onBrandHealthToggle}
                          checked={viewModel.brandHealth}
                        />
                        <div className={styles.switchTitle}>
                          {i18n.t('data_report_type_brand_health')}
                        </div>
                      </div>
                    </div>
                    <div className={styles.switchRow}>
                      <div className={styles.switchItem}>
                        <Switch
                          className={styles.switch}
                          onChange={viewModel.onBrandEffectToggle}
                          checked={viewModel.brandEffect}
                        />
                        <div className={styles.switchTitle}>
                          {i18n.t('data_report_type_brand_effect')}
                        </div>
                      </div>
                      <div className={styles.switchItem}>
                        <Switch
                          className={styles.switch}
                          onChange={viewModel.onBrandRoutineToggle}
                          checked={viewModel.brandRoutine}
                        />
                        <div className={styles.switchTitle}>
                          {i18n.t('data_report_type_brand_routine')}
                        </div>
                      </div>
                    </div>
                  </div>
                </Collapse.Panel>

                <Collapse.Panel
                  header={
                    (
                      <div className={styles.panelTitle}>
                        <img src={PaperIcon} alt="" className={styles.panelIcon} />
                        <div className={styles.panelText}>
                          {i18n.t('advance_page_name')}
                        </div>
                        <CaretDownOutlined />
                      </div>
                    )
                  }
                  key="advance"
                  showArrow={false}
                  className={styles.collapsePanel}
                >
                  <div className={styles.switchSection}>
                    <div className={styles.switchRow}>
                      <div className={styles.switchItem}>
                        <Switch
                          className={styles.switch}
                          onChange={viewModel.onMarketing7PToggle}
                          checked={viewModel.marketing7P}
                        />
                        <div className={styles.switchTitle}>
                          {i18n.t('advance_type_marketing_7p')}
                        </div>
                      </div>
                      <div className={styles.switchItem}>
                        <Switch
                          className={styles.switch}
                          onChange={viewModel.onMindShareToggle}
                          checked={viewModel.mindShare}
                        />
                        <div className={styles.switchTitle}>
                          {i18n.t('advance_type_mind_share')}
                        </div>
                      </div>
                    </div>
                    <div className={styles.switchRow}>
                      <div className={styles.switchItem}>
                        <Switch
                          className={styles.switch}
                          onChange={viewModel.onStarProductionToggle}
                          checked={viewModel.starProduction}
                        />
                        <div className={styles.switchTitle}>
                          {i18n.t('advance_type_star_product')}
                        </div>
                      </div>
                      <div className={styles.switchItem}>
                        <Switch
                          className={styles.switch}
                          onChange={viewModel.onReputationToggle}
                          checked={viewModel.reputation}
                        />
                        <div className={styles.switchTitle}>
                          {i18n.t('advance_type_reputation')}
                        </div>
                      </div>
                    </div>
                  </div>
                </Collapse.Panel>
              </Collapse>



            </div>
          </div>
          <div className={styles.submitContainer}>
            <Button
              className={styles.submitBtn}
              disabled={!viewModel.availableSubmit}
              onClick={viewModel.onSubmit}
            >
              儲存
            </Button>
          </div>

          <div
            className={styles.customizeBack}
            onClick={viewModel.onDrawerClose}
          >
            <img src={RightArrowIcon} alt="" />
          </div>
        </Drawer>
        <Modal
          open={viewModel.showErrorModal}
          onCancel={viewModel.onModalClose}
          centered
          closable={false}
          footer={null}
          bodyStyle={{
            height: 220,
            display: 'flex',
            flexDirection: 'column',
            rowGap: 20,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div>
            {viewModel.errorContent}
          </div>
          <Button
            onClick={viewModel.onModalClose}
          >
            我知道了
          </Button>
        </Modal>
      </>
    );
  }
}

EditDrawer.propTypes = {
  viewModel: PropTypes.object
};

EditDrawer.defaultProps = {
  viewModel: {}
};


export default EditDrawer;
