import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';

import { CaretDownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Popover, Empty, Breadcrumb, DatePicker, Tooltip } from 'antd';

import packageStore from 'src/stores/packageStore';
import SearchIcon from 'src/assets/MenuIcons/SearchIcon.svg';
import withRouter from '../withRouter';

import infoHeaderViewModel from './viewModel';
import MultiPeriodDatePicker from '../MultiPeriodDatePicker';

import styles from './styles.module.scss';

@observer
class InfoHeader extends React.Component {
  render() {
    const { router, breadcrumb, showPicker, isMultiPeriod, multiViewModel, callback, customizeRight, tooltip } = this.props;
    const activeMultiViewModel = multiViewModel ?? infoHeaderViewModel.multiViewModel;
    return (
      <div className={styles.packageSelector}>
        <div className={styles.selectorContainer}>
          <Popover
            placement="bottomLeft"
            showArrow={false}
            onOpenChange={infoHeaderViewModel.onProjectSelectOpenChange}
            content={(
              <div className={styles.switchProjectPopover}>
                <div className={styles.search}>
                  <div className={styles.searchContainer}>
                    <input
                      className={styles.searchInput}
                      placeholder={i18n.t('common_search_placeholder')}
                      value={infoHeaderViewModel.projectKeyword}
                      onChange={infoHeaderViewModel.onProjectSearchChange}
                      onKeyDown={infoHeaderViewModel.onProjectSearchKeyDown}
                    />
                    <div
                      className={styles.searchBtn}
                      onClick={infoHeaderViewModel.onProjectSearchSubmit}
                    >
                      <img src={SearchIcon} alt="" />
                    </div>
                  </div>
                </div>
                <div className={styles.customerList}>
                  {
                    infoHeaderViewModel.searchProjectsRes.length > 0
                      ? (
                        <>
                          {
                            infoHeaderViewModel.searchProjectsRes.map((el) => {
                              return (
                                <div
                                  key={el.id}
                                  className={clsx(
                                    styles.customerCard,
                                    el.id === packageStore.activePackageId && styles.isActive
                                  )}
                                  onClick={() => packageStore.onPackageSelect(el, router)}
                                >
                                  <div className={styles.name}>
                                    {el.name}
                                  </div>
                                </div>
                              );
                            })
                          }
                        </>
                      )
                      : (
                        <div className={styles.emptyContainer}>
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </div>
                      )
                  }

                </div>
              </div>
            )}
          >
            <div className={styles.selector}>
              <div className={styles.text}>
                {packageStore.activePackageName}
              </div>
              <CaretDownOutlined className={styles.icon} />
            </div>
          </Popover>
          <div className={styles.breadContainer}>
            <Breadcrumb
              items={breadcrumb.map((el) => ({
                title: el
              }))}
            />
            {
              tooltip
              && (
                <Tooltip
                  title={tooltip}
                  placement="right"
                >
                  <QuestionCircleOutlined
                    className={styles.qicon}
                  />
                </Tooltip>
              )
            }
          </div>
        </div>
        {
          showPicker
          && (
            <div className={styles.dateFilter}>
              <div className={styles.text}>
                {i18n.t('common_date_filter')}
              </div>
              <MultiPeriodDatePicker
                viewModel={activeMultiViewModel}
                callback={callback}
                isMulti={isMultiPeriod}
              />
            </div>
          )
        }
        {customizeRight}
      </div>
    );
  }
}

InfoHeader.propTypes = {
  router: PropTypes.object,
  breadcrumb: PropTypes.array,
  showPicker: PropTypes.bool,
  isMultiPeriod: PropTypes.bool,
  multiViewModel: PropTypes.object,
  callback: PropTypes.func,
  customizeRight: PropTypes.any,
  tooltip: PropTypes.string
};

InfoHeader.defaultProps = {
  router: {},
  breadcrumb: [],
  showPicker: true,
  isMultiPeriod: false,
  multiViewModel: null,
  callback: () => { },
  customizeRight: null,
  tooltip: null
};


export default withRouter(InfoHeader);
