import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';

import { CloseOutlined, CheckOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, Select, Tooltip, DatePicker } from 'antd';

import styles from './styles.module.scss';

@observer
class BulletinContent extends React.Component {
  render() {
    const { viewModel, onDelete, isDeletable } = this.props;
    return (
      <div className={clsx(styles.bulletinItem, viewModel.hasError && styles.errorItem)}>
        <div className={styles.nameContainer}>
          {
            viewModel.isEdit
              ? (
                <Input
                  value={viewModel.title}
                  onChange={viewModel.onTitleChange}
                />
              )
              : (
                <div className={styles.nameContent}>
                  {viewModel.title}
                </div>
              )
          }

          <div className={styles.actionContainer}>
            {
              viewModel.isEdit
                ? (
                  <>
                    <Tooltip
                      title="儲存"
                      placement="bottom"
                    >
                      <Button
                        icon={<CheckOutlined />}
                        onClick={viewModel.onSubmitTitleChange}
                      />
                    </Tooltip>
                    <Tooltip
                      title="取消修改"
                      placement="bottom"
                    >
                      <Button
                        icon={<CloseOutlined />}
                        onClick={viewModel.onDisposeTitleChange}
                      />
                    </Tooltip>
                  </>
                )
                : (
                  <>
                    <Tooltip
                      title="編輯通報內容名稱"
                      placement="bottom"
                    >
                      <Button
                        icon={<EditOutlined />}
                        onClick={viewModel.onEditChange}
                      />
                    </Tooltip>
                    {
                      isDeletable
                      && (
                        <Tooltip
                          title={`刪除${viewModel.title}`}
                          placement="bottom"
                        >
                          <Button icon={<DeleteOutlined />} onClick={() => onDelete(viewModel.title)} />
                        </Tooltip>
                      )
                    }
                  </>
                )
            }

          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.selectorContainer}>
          <Button
            className={styles.selectorBtn}
            onClick={() => viewModel.onLevelsModalOpen()}
          >
            {i18n.t('bulletin_drawer_levels_title')}
          </Button>
          <Button
            className={styles.selectorBtn}
            onClick={() => viewModel.onSourceModalOpen()}
          >
            {i18n.t('bulletin_drawer_sources_title')}
          </Button>
          <Button
            className={styles.selectorBtn}
            onClick={() => viewModel.onSentimentModalOpen()}
          >
            {i18n.t('bulletin_drawer_sentiment_title')}
          </Button>
        </div>
        <div className={styles.selectedContentContainer}>
          <div className={styles.selectedTitle}>
            {i18n.t('bulletin_drawer_selected_title')}
          </div>
          <div className={styles.selectedContent}>
            <div>
              <span>
                {i18n.t('bulletin_drawer_levels')}
              </span>
              <span>
                ：
              </span>
              <span>
                {viewModel.selectedLevelText}
              </span>
            </div>
            <div>
              <span>
                {i18n.t('bulletin_drawer_sources')}
              </span>
              <span>
                ：
              </span>
              <span>
                {viewModel.selectedSourceText}
              </span>
            </div>
            <div>
              <span>
                {i18n.t('bulletin_drawer_sentiment')}
              </span>
              <span>
                ：
              </span>
              <span>
                {viewModel.selectedSentimentText}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BulletinContent.propTypes = {
  viewModel: PropTypes.object,
  onDelete: PropTypes.func,
  isDeletable: PropTypes.bool
};

BulletinContent.defaultProps = {
  viewModel: {},
  onDelete: () => { },
  isDeletable: false
};

export default BulletinContent;
