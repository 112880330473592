import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Virtuoso } from 'react-virtuoso';

import i18n from 'src/i18n';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Modal, Checkbox, Radio } from 'antd';

import PaperIcon from 'src/assets/PaperIcon.svg';
import SearchIcon from 'src/assets/MenuIcons/SearchIcon.svg';

import packageStore from 'src/stores/packageStore';

import styles from './styles.module.scss';

@observer
class BulletinLevelSelectModal extends React.Component {
  _renderRadioItem = (item) => {
    return (
      <Radio
        key={item.id}
        value={item.id}
        className={styles.radioContent}
      >
        {item.name}
      </Radio>
    );
  };

  _renderGroupRadioItem = (item) => {
    return (
      <div
        className={styles.productRatioGroup}
        key={item.id}
      >
        <div className={styles.groupName}>
          {item.name}
        </div>
        {
          item.children.filter((c) => c.name.toLowerCase().includes(this.props.viewModel.productKeyword.toLowerCase())).map((c) => {
            return (
              <Radio
                key={c.id}
                value={c.id}
                className={styles.radioContent}
              >
                {c.name}
              </Radio>
            );
          })
        }
      </div>
    );
  };

  render() {
    const { viewModel, onSubmitCallback } = this.props;
    return (
      <Modal
        open={viewModel.isModalOpen}
        closable={false}
        centered
        onCancel={viewModel.onClose}
        className="selectModal"
        width={viewModel.modalWidth}
        footer={null}
      >
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <div className={styles.headerTitle}>
              <img src={PaperIcon} alt="paper" className={styles.titleIcon} />
              <div className={styles.titleText}>
                {i18n.t('package_modal_title')}
              </div>
            </div>
            <div className={styles.headerClose}>
              <Button
                icon={<CloseOutlined />}
                type="link"
                className={styles.headerClose}
                onClick={viewModel.onClose}
              >
                {i18n.t('package_modal_close')}
              </Button>
            </div>
          </div>
          <div className={styles.modalContent}>
            {
              packageStore.isBrandAvailable
              && (
                <div className={styles.selectCard}>
                  <div className={styles.cardHeader}>
                    <div className={styles.cardTitle}>
                      {i18n.t('package_modal_brand_select')}
                    </div>
                  </div>
                  <div className={styles.cardContent}>
                    <div className={styles.searchInputContainer}>
                      <input
                        className={styles.searchInput}
                        placeholder={i18n.t('package_modal_input_placeholder')}
                        value={viewModel.brandKeywordCache}
                        onChange={viewModel.onBrandKeywordChange}
                        onKeyDown={viewModel.onBrandKeyDown}
                      />
                      <div
                        className={styles.searchBtn}
                        onClick={viewModel.onBrandSearchSubmit}
                      >
                        <img src={SearchIcon} alt="" />
                      </div>
                    </div>
                    <Radio.Group
                      className={styles.levelCheckboxContainer}
                      buttonStyle="solid"
                      onChange={viewModel.onBrandSelect}
                      value={viewModel.selectedBrand}
                    >
                      <Virtuoso
                        style={{ height: 188 }}
                        data={viewModel.keywordBrand ?? []}
                        itemContent={(index, item) => this._renderRadioItem(item)}
                      />
                    </Radio.Group>
                  </div>
                </div>
              )
            }
            {
              packageStore.isSeriesAvailable
              && (
                <div className={styles.selectCard}>
                  <div className={styles.cardHeader}>
                    <div className={styles.cardTitle}>
                      {i18n.t('package_modal_series_select')}
                    </div>
                  </div>
                  <div className={styles.cardContent}>
                    <div className={styles.searchInputContainer}>
                      <input
                        className={styles.searchInput}
                        placeholder={i18n.t('package_modal_input_placeholder')}
                        value={viewModel.seriesKeywordCache}
                        onChange={viewModel.onSeriesKeywordChange}
                        onKeyDown={viewModel.onSeriesKeyDown}
                      />
                      <div
                        className={styles.searchBtn}
                        onClick={viewModel.onSeriesSearchSubmit}
                      >
                        <img src={SearchIcon} alt="" />
                      </div>
                    </div>
                    <Radio.Group
                      className={styles.levelCheckboxContainer}
                      buttonStyle="solid"
                      onChange={viewModel.onSeriesSelect}
                      value={viewModel.selectedSeries}
                    >
                      <Virtuoso
                        style={{ height: 188 }}
                        data={viewModel.keywordSeries ?? []}
                        itemContent={(index, item) => this._renderRadioItem(item)}
                      />
                    </Radio.Group>
                  </div>
                </div>
              )
            }
            {
              packageStore.isProductAvailable
              && (
                <div className={styles.selectCard}>
                  <div className={styles.cardHeader}>
                    <div className={styles.cardTitle}>
                      {i18n.t('package_modal_product_select')}
                    </div>
                  </div>
                  <div className={styles.cardContent}>
                    <div className={styles.searchInputContainer}>
                      <input
                        className={styles.searchInput}
                        placeholder={i18n.t('package_modal_input_placeholder')}
                        value={viewModel.productKeywordCache}
                        onChange={viewModel.onProductKeywordChange}
                        onKeyDown={viewModel.onProductKeyDown}
                      />
                      <div
                        className={styles.searchBtn}
                        onClick={viewModel.onProductSearchSubmit}
                      >
                        <img src={SearchIcon} alt="" />
                      </div>
                    </div>
                    <Radio.Group
                      className={styles.levelCheckboxContainer}
                      buttonStyle="solid"
                      onChange={viewModel.onProductSelect}
                      value={viewModel.selectedProduct}
                    >
                      <Virtuoso
                        style={{ height: 188 }}
                        data={viewModel.keywordProduct ?? []}
                        itemContent={(index, item) => this._renderGroupRadioItem(item)}
                      />
                    </Radio.Group>
                  </div>
                </div>
              )
            }
          </div>
          <div className={styles.modalFooter}>
            <div className={styles.btnContainer}>
              <Button
                className={clsx(styles.btn, styles.cleanBtn)}
                icon={<CloseOutlined />}
                onClick={viewModel.onCleanAll}
              >
                {i18n.t('package_modal_clean')}
              </Button>
              <Button
                className={clsx(styles.btn, styles.applyBtn)}
                icon={<CheckOutlined />}
                type="primary"
                onClick={() => viewModel.onSubmit(onSubmitCallback)}
              >
                {i18n.t('package_modal_apply')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

BulletinLevelSelectModal.propTypes = {
  viewModel: PropTypes.object,
  onSubmitCallback: PropTypes.func
};

BulletinLevelSelectModal.defaultProps = {
  viewModel: {},
  onSubmitCallback: () => {}
};

export default BulletinLevelSelectModal;
