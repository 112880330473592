import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { message } from 'antd';
import { getHost, getLevelText } from 'src/utils';

import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import BlockedWordsService from 'src/services/blockedwords';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import { encodeURI } from 'js-base64';
import packageStore from 'src/stores/packageStore';

import WordbookService from 'src/services/wordbook';
import ChartFactory from 'src/components/Charts/factory';

import optionsStore from 'src/stores/optionsStore';

export default class WordChartItemViewModel {

  parent = {};

  @observable data = [];
  @observable activeTabId = null;
  @observable activeFeatureId = null;
  @observable isModalOpen = false;

  @observable wordsViewModel = null;

  @observable onReady = false;

  @observable showDiscover = false;
  @observable discoverId = '';
  @observable discoverName = '';
  @observable discoverWords = null;

  @computed get showParent() {
    return this.activeTabId ? this.data.find((el) => el.id === this.activeTabId) : {};
  }

  @computed get activeFeatureName() {
    return this.activeFeatureId
      ? this.showParent.charts.find((el) => el.id === this.activeFeatureId).name
      : '';
  }

  @computed get showChart() {
    return this.activeFeatureId
      ? this.showParent.charts?.find((el) => el.id === this.activeFeatureId)?.chart ?? [ChartFactory.createChartViewModel({
        data: { type: 'nodata', subType: 'ana-error' },
        type: 'nodata'
      }, this)]
      : [ChartFactory.createChartViewModel({
        data: { type: 'nodata', subType: 'ana-error' },
        type: 'nodata'
      }, this)];
  }

  @computed get showPrevChart() {
    return this.activeFeatureId
      ? this.showParent.prevCharts?.find((el) => el.id === this.activeFeatureId)?.chart ?? [ChartFactory.createChartViewModel({
        data: { type: 'nodata', subType: 'ana-error' },
        type: 'nodata'
      }, this)]
      : [ChartFactory.createChartViewModel({
        data: { type: 'nodata', subType: 'ana-error' },
        type: 'nodata'
      }, this)];
  }

  @computed get chartTitle() {
    return this.activeFeatureId === 'all'
      ? `${this.parent.level === 'industry' ? getLevelText(this.parent.level) : this.showParent.name}-不分類文字雲`
      : `${this.parent.level === 'industry' ? getLevelText(this.parent.level) : this.showParent.name}-${this.activeFeatureName}文字雲`;
  }

  @computed get allCSVTitle() {
    return `${getLevelText(this.parent.level)}-文字雲`;
  }

  @computed get chartDesc() {
    return this.activeFeatureId !== 'all'
      ? `呈現時間區間內，${this.parent.level === 'industry' ? '專案所有品牌' : `選擇之${getLevelText(this.parent.level)}中`}「${this.activeFeatureName}」的文字雲。`
      : `呈現時間區間內，${this.parent.level === 'industry' ? '專案所有品牌' : `選擇之${getLevelText(this.parent.level)}中`}全部特性分類的聲量分佈。`;
  }

  constructor(data, parent) {
    makeObservable(this);

    this.init(data, parent);
  }

  @action init = ({ data }, parent) => {
    this.parent = parent;
    this.data = data.map((el) => ({
      id: el.id,
      name: el.name,
      charts: el.item?.map((element) => ({
        id: element.id,
        name: element.name,
        chart: [ChartFactory.createChartViewModel({
          data: element.item.length > 0 && !element.item.every((item) => item.originCount === 0) ? element.item : { type: 'nodata', subType: element.item.length === 0 ? 'ana-error' : 'default' },
          type: element.item.length > 0 && !element.item.every((item) => item.originCount === 0) ? 'words' : 'nodata',
          time: 'present',
          csv: el.csv,
          csvKey: el.csvKey
        }, this)]
      })),
      prevCharts: el.prevItem?.map((element) => ({
        id: element.id,
        name: element.name,
        chart: [ChartFactory.createChartViewModel({
          data: element.item.length > 0 && !element.item.every((item) => item.originCount === 0) ? element.item : { type: 'nodata', subType: element.item.length === 0 ? 'ana-error' : 'default' },
          type: element.item.length > 0 && !element.item.every((item) => item.originCount === 0) ? 'words' : 'nodata',
          time: 'previous',
          csv: el.csv,
          csvKey: el.csvKey
        }, this)]
      }))
    }));

    const targetTab = this.data.find((item) => item.id === this.parent.reserverActiveTab.tabId);
    const targetFeature = this.data[0].charts?.find((feature) => feature.id === this.parent.reserverActiveTab.featureId);

    this.activeTabId = targetTab ? targetTab.id : this.data[0].id;
    this.activeFeatureId = targetFeature ? targetFeature.id : this.data[0].charts[0].id;

    this.onReady = true;
  };

  @action onTabsChange = (key) => {
    this.activeTabId = key;
  };

  @action onFeatureChange = (key) => {
    this.activeFeatureId = key;
  };

  @action onModalOpen = (viewModel) => {
    this.wordsViewModel = viewModel;

    this.isModalOpen = true;
  };

  @action onModalClose = () => {
    this.isModalOpen = false;
    this.wordsViewModel.onModalClose();
  };

  @action onCheckWordTopics = () => {
    const { selectedWOMType, selectedTagKey, selectedPostType, selectedCategory, level } = this.parent;

    const [gte, lte] = this.wordsViewModel.timeSec === 'present'
      ? infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'))
      : infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD'));

    const topicsResult = {
      ...(selectedWOMType !== 'all' && {
        womType: selectedWOMType
      }),
      ...(selectedPostType !== 'all' && {
        postType: selectedPostType
      }),
      ...(selectedCategory.length > 0 ? {
        category: selectedCategory.map((el) => ({
          id: el,
          name: SOURCE_CATEGORY_TYPE[el]
        }))
      } : {
        category: SOURCE_CATEGORY.map((el) => ({
          id: el.value,
          name: el.label
        }))
      }),
      packageContent: {
        id: packageStore.activePackageId,
        name: packageStore.activePackageName
      },
      chart: {
        name: `分類詞庫/${getLevelText(this.parent.level)}/文字雲`
      },
      ...(
        !this.showDiscover
          ? {
            feature: {
              id: this.wordsViewModel.selectedId,
              name: this.wordsViewModel.selectedName,
              count: this.wordsViewModel.selectedWordContentById?.originCount
            },
            featureCategory: this.wordsViewModel.selectedWordContentById?.group,
            ...(level === 'industry' && {
              industry: true
            })
          }
          : {
            term: {
              name: this.discoverName,
              score: this.wordsViewModel.selectedWordContentById?.score,
              count: this.wordsViewModel.selectedWordContentById?.originCount
            }
          }
      ),
      ...(level !== 'industry' && {
        [`${level}`]: [{
          id: this.showParent.id,
          name: this.showParent.name,
          ...(
            level === 'series'
              ? {
                tagKey: selectedTagKey,
                tagKeyName: packageStore.series.find((el) => el.id === selectedTagKey)?.name ?? '系列'
              }
              : {}
          )
        }]
      }),
      date: {
        gte,
        lte: lte ?? gte
      }
    };

    const json = JSON.stringify(topicsResult);
    const result = encodeURI(json);
    const url = `${getHost()}/topics-result?result=${result}`;
    window.open(url, '_blank');

    this.onModalClose();
  };

  @action onDiscover = async () => {
    if (!this.showDiscover) {
      this.discoverId = this.wordsViewModel.selectedId;
      this.discoverName = this.wordsViewModel.selectedName;
    }
    optionsStore.setLoading('discover');

    const { selectedWOMType, selectedTagKey, selectedPostType, level, selectedCategory, selectedIndex, selectedCount } = this.parent;
    const [gte, lte] = this.wordsViewModel.timeSec === 'present'
      ? infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'))
      : infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD'));
    const params = {
      date: {
        gte,
        lte
      },
      ...(selectedPostType !== 'all' && { postType: selectedPostType }),
      ...(selectedWOMType !== 'all' && { womType: selectedWOMType }),
      ...(selectedCategory.length >= 1 && { category: selectedCategory }),
      ...(level === 'brand' && { search: this.activeTabId }),
      ...(level === 'series' && { tagKey: selectedTagKey, tagValue: this.activeTabId }),
      ...(level === 'product' && { search: this.activeTabId }),
      feature: this.discoverId,
      metric: selectedIndex,
      size: selectedCount,
      ...(this.parent.blockWords.length > 0 && { disableTerm: this.parent.blockWords.map((el) => el.name) })

    };

    try {
      const { data } = await WordbookService.getDiscover(params, level);
      runInAction(() => {
        this.discoverWords = ChartFactory.createChartViewModel({ ...data.chartData[0], data: data.chartData[0].data[0], time: this.wordsViewModel.timeSec }, this);
        this.showDiscover = true;
        this.onModalClose();
      });

    } catch (error) {
      console.log(error);
    } finally {
      optionsStore.setCompleted('discover');
    }
  };

  @action onDiscoverLeave = () => {
    this.discoverWords = null;
    this.showDiscover = false;
  };

  @action onWordBlock = async () => {
    try {
      await BlockedWordsService.addBlockWords(this.wordsViewModel.selectedName);
      await this.parent.getBlockedWords();
      runInAction(() => {
        if (this.showDiscover) {
          this.onDiscover();
        } else {
          this.parent.reserveIds();
          this.parent.getGraphicData();
        }
        this.onModalClose();
      });
    } catch (error) {
      if (error.response?.status === 409) {
        message.error('該詞已在屏蔽名單中!');
        return;
      }
      message.error('無法新增屏蔽詞，請稍候再試');
    }
  };

}
