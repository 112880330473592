import { makeObservable, observable, action, computed } from 'mobx';
import i18n from 'src/i18n';

export default class SingleClassModalViewModel {
  @observable isModalOpen = false;
  @observable keyword = '';
  @observable keywordCache = '';

  @observable type = '';

  @observable isGroup = false;

  @observable items = [];

  @computed get indeterminate() {
    return this.items.some((el) => el.checkedCache) && !this.isAllChecked;
  }

  @computed get isAllChecked() {
    return this.items.every((el) => el.checkedCache);
  }

  @computed get groupIndeterminate() {
    return this.items.some((g) => {
      return g.keywordChildren.some((el) => el.checkedCache);
    }) && !this.isGroupAllChecked;
  }

  @computed get isGroupAllChecked() {
    return this.items.every((g) => {
      return g.keywordChildren.every((el) => el.checkedCache);
    });
  }

  @computed get searchItems() {
    return this.items.filter((el) => el.name.toLowerCase().includes(this.keywordCache.toLowerCase()));
  }

  @computed get selectedItems() {
    return this.items.filter((el) => el.checked).map((el) => el.id);
  }

  @computed get cacheSelectedLength() {
    return this.items.filter((el) => el.checkedCache).length;
  }

  @computed get text() {
    const isSelected = this.items.filter((el) => el.checked).map((el) => el.name);
    return isSelected.length >= 1 ? isSelected.join(', ') : i18n.t('advance_item_no_data').replace('%type%', i18n.t('advance_analysis_brand_item_title'));
  }

  @computed get modalTitle() {
    return i18n.t('package_modal_brand_select');

  }

  constructor() {
    makeObservable(this);
  }

  @action setItems = (type, items) => {
    this.type = type;
    this.items = items;
    this.keyword = '';
    this.keywordCache = '';
  };

  @action onModalOpen = () => {
    this.isModalOpen = true;
  };

  @action onModalClose = () => {
    this.isModalOpen = false;

    this.keyword = '';
    this.keywordCache = '';
  };

  @action onAllSelect = () => {
    if (!this.isAllChecked) {
      this.items.forEach((el) => {
        el.setSelect(true);
      });
    } else {
      this.items.forEach((el) => {
        el.setSelect(false);
      });
    }

  };

  @action onCleanAll = () => {
    this.items.forEach((el) => {
      el.setSelect(false);
      el.confirmSelect();
    });
  };

  @action onSubmit = () => {
    this.items.forEach((el) => {
      el.confirmSelect();
    });

    this.onModalClose();
  };

  @action onClose = () => {
    this.items.forEach((el) => {
      el.onDisposeSelect();
    });

    this.onModalClose();
  };

  @action onKeywordChange = (e) => {
    this.keyword = e.target.value;
    if (!e.target.value.trim()) {
      this.onSearchSubmit();
    }
  };

  @action onSearchSubmit = () => {
    if (this.isGroup) {
      this.items.forEach((el) => el.setKeyword(this.keyword));
    }
    this.keywordCache = this.keyword;
  };

  @action onKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onSearchSubmit();
    }
  };
}
