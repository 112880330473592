import ProductSeriesVolumeTrendModel from './Trend';
import ProductSeriesVolumeRankingModel from './Ranking';
import ProductSeriesVolumeProportionModel from './Proportion';
import ProductSeriesVolumeCompareModel from './Compare';

export default {
  ProductSeriesVolumeTrendModel,
  ProductSeriesVolumeRankingModel,
  ProductSeriesVolumeProportionModel,
  ProductSeriesVolumeCompareModel
};
