import { COLOR_SET, WORD_CONFIG } from 'src/consts/chart';
import { SOURCE_CATEGORY } from 'src/consts';
import packageStore from 'src/stores/packageStore';
import { generateCSVKey } from 'src/utils/chart';

export default class IndustryWords {
  constructor(data, query) {
    if (data.length === 0) {
      this.data = {
        chartData: [{
          id: 'brand_volume_proportion',
          name: '品牌聲量佔比',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }],
          type: 'nodata'
        }]
      };
      return;
    }

    if (data.every((el) => el.count === 0)) {
      this.data = {
        chartData: [{
          id: 'brand_volume_proportion',
          name: '品牌聲量佔比',
          data: [{
            type: 'nodata'
          }],
          type: 'nodata'
        }]
      };
      return;
    }

    const maxNum = Math.max(...data.map((el) => el.count));
    const minNum = Math.min(...data.map((el) => el.count));

    const size = (() => {
      const length = data.length;
      if (length <= 20) {
        return 'small';
      }
      if (length > 20 && length <= 50) {
        return 'medium';
      }
      return 'large';
    })();

    const getNew = (num) => {
      // FOR ONLY ONE SITUATION
      if (maxNum === minNum) {
        return WORD_CONFIG[size].basicSize * WORD_CONFIG[size].maxRatio;
      }
      return ((num - minNum) * (WORD_CONFIG[size].maxRatio / (maxNum - minNum)) + 1) * WORD_CONFIG[size].basicSize;
    };

    const featureSetList = [...(new Set(data.map((el) => el.featureCategory)))].map((el, i) => ({
      ...(packageStore.featureSetList.find((f) => f.id === el))
    }));

    const {
      postType,
      date
    } = query;

    const linkData = {
      ...(postType && {
        postType
      }),
      category: SOURCE_CATEGORY.map((el) => ({
        id: el.value,
        name: el.label
      })),
      packageContent: {
        id: packageStore.activePackageId,
        name: packageStore.activePackageName
      },
      industry: true,
      chart: {
        name: '數據報告/品牌成效分析/行業不分類文字雲'
      },
      date
    };

    const res = data.map((el, i) => ({
      id: el.id ?? el.name,
      name: el.name,
      count: getNew(el.count),
      originCount: el.count,
      color: featureSetList.find((f) => f.id === el.featureCategory)?.color,
      linkData: {
        ...linkData,
        featureCategory: {
          ...(packageStore.featureSetList.find((f) => f.id === el.featureCategory))
        },
        feature: {
          id: el.id,
          name: el.name,
          count: el.count
        }
      }
    }));

    const csv = res.map((el) => ({
      熱詞: el.name,
      提及則數: el.originCount
    }));

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [{
        id: 'industry_keyword_hot',
        name: '行業熱詞文字雲',
        data: [res],
        type: 'words',
        csv,
        csvKey
      }]
    };
  }

  static fromRes(data, query) {
    return new IndustryWords(data, query);
  }
}
