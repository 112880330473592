import { request, getHost, getHeaders } from '../utils';

export const getBrandVolumeTrend = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-effect/volume/trend`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandVolumeCount = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-effect/volume/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getSeriesBrandVolumeCompare = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-effect/series/volume/stack`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getProductBrandVolumeCompare = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-effect/product/volume/stack`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getProductVolumeRanking = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-effect/product/volume/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getIndustrySourceCategoryProportion = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-effect/industry/source/category/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandSourceCategoryProportion = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-effect/source/category/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getIndustrySourceChannelRanking = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-effect/industry/source/channel/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandSourceChannelRanking = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-effect/source/channel/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getIndustryWord = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-effect/industry/feature/term`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandWord = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-effect/feature/term`,
    headers: getHeaders(),
    data
  };
  return request(options);
};


export const getBrandSentimentOverview = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-effect/sentiment`,
    headers: getHeaders(),
    data
  };
  return request(options);
};
