import { request, getHost, getHeaders } from '../utils';

export const volume = {
  getSeriesVolumeTrend: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/series/volume/trend`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getSeriesVolumeCount: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/series/volume/count`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getBrandSeriesVolumeTrend: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/series/brand/trend`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getBrandSeriesVolumeCount: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/series/brand/count`,
      headers: getHeaders(),
      data
    };
    return request(options);
  }
};

export const source = {
  category: {
    getSourceCategoryTrend: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/series/source/category/trend`,
        headers: getHeaders(),
        data
      };
      return request(options);
    },
    getSourceCategoryCount: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/series/source/category/count`,
        headers: getHeaders(),
        data
      };
      return request(options);
    }
  },
  website: {
    getSourceWebsiteTrend: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/series/source/website/trend`,
        headers: getHeaders(),
        data
      };
      return request(options);
    },
    getSourceWebsiteCount: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/series/source/website/count`,
        headers: getHeaders(),
        data
      };
      return request(options);
    }
  },
  channel: {
    getSourceChannelTrend: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/series/source/channel/trend`,
        headers: getHeaders(),
        data
      };
      return request(options);
    },
    getSourceChannelCount: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/series/source/channel/count`,
        headers: getHeaders(),
        data
      };
      return request(options);
    }
  }
};

export const keyword = {
  getSeriesHotWord: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/series/term/hot`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getSeriesHotWordDiscover: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/series/term/discover`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getSeriesNewWord: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/series/term/new`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getSeriesTrendWord: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/series/term/trending`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getSeriesHotWordAssoc: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/series/term/assoc`,
      headers: getHeaders(),
      data
    };
    return request(options);
  }
};
