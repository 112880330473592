import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import { CSVLink } from 'react-csv';
import { DownloadOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';

import Loading from 'src/assets/loading.svg';

import ChartFactory from 'src/components/Charts/factory';
import ChartTimeStamp from 'src/components/ChartTimeStamp';
import optionsStore from 'src/stores/optionsStore';

import BrandSentimentWordCloud from './Words';
import styles from './styles.module.scss';

@observer
class BrandSentimentWord extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <div
        id={viewModel.chartId}
        className={styles.chartItem}
      >
        <div className={styles.chartTitle}>
          <div className={styles.title}>
            {viewModel.chartName}
          </div>
          <div
            className={clsx(
              styles.actions,
              optionsStore.isImgDownload && styles.displayNone
            )}
          >
            <Tooltip
              title="下載數據"
              placement="bottom"
            >
              <Button
                icon={<DownloadOutlined />}
                onClick={viewModel.onDownloadExcel}
              />
            </Tooltip>
            <Tooltip
              title="下載圖表"
              placement="bottom"
            >
              <Button
                icon={<SaveOutlined />}
                onClick={viewModel.onDownload}
              />
            </Tooltip>
            {
              viewModel.isDownload
              && (
                <CSVLink
                  className={viewModel.chartId}
                  data={viewModel.downloadList}
                  filename={viewModel.downloadFileName}
                  style={{ display: 'none' }}
                />
              )
            }
          </div>
        </div>
        <div className={styles.timeStampContainer}>
          <ChartTimeStamp isPresent />
        </div>
        {
          (viewModel.isInit)
          && (
            <div
              key={viewModel.key}
              className={styles.chartContainer}
            >
              {
                viewModel.chart.type === 'nodata'
                  ? (
                    <>
                      {ChartFactory.generateChartView(viewModel.chart)}
                    </>
                  )
                  : (
                    <BrandSentimentWordCloud viewModel={viewModel.chart} />
                  )
              }
            </div>
          )
        }
        {
          !viewModel.isReady
          && (
            <div className={styles.curtain}>
              <div className={styles.color} />
              <div className={styles.absolute}>
                <img src={Loading} alt="" style={{ borderRadius: 8 }} />
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

BrandSentimentWord.propTypes = {
  viewModel: PropTypes.object
};

BrandSentimentWord.defaultProps = {
  viewModel: {}
};


export default BrandSentimentWord;
