export default class TopicDetailCommentsModel {
  constructor(data, pages) {
    const { comment } = data;

    this.comment = comment.map((el, i) => ({
      order: (pages - 1) * 50 + i + 1,
      vocKey: el.vocKey,
      replyId: el.replyId < 99999 ? el.replyId : '---',
      author: el.author,
      date: el.date,
      sentiment: el.sentiment,
      content: el.content,
      commonHit: {
        level1: (el.commonHit.level1.length >= 1 && el.commonHit.level1[0].trim()) ? el.commonHit.level1.join(', ') : '無',
        tag: (el.commonHit.tag.length >= 1 && el.commonHit.tag[0].trim()) ? el.commonHit.tag.join(', ') : '無',
        level3: (el.commonHit.level3.length && el.commonHit.level3[0].trim()) >= 1 ? el.commonHit.level3.join(', ') : '無'
      }
    }));
  }

  static fromRes(data, pages) {
    return new TopicDetailCommentsModel(data, pages);
  }
}
