import ProductSourceChannelTrendModel from './Trend';
import ProductSourceChannelRankingModel from './Ranking';
import ProductSourceChannelProportionModel from './Proportion';
import ProductSourceChannelCompareModel from './Compare';

export default {
  ProductSourceChannelTrendModel,
  ProductSourceChannelRankingModel,
  ProductSourceChannelProportionModel,
  ProductSourceChannelCompareModel
};
