import { COLOR_SET } from 'src/consts/chart';
import { generateCompletedDayArray, getTotalDays, generateCSVKey } from 'src/utils/chart';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';

export default class ProductNegativeSentimentTrendModel {
  constructor(origin, prevOrigin, query) {
    if (origin.length === 0 && prevOrigin.length === 0) {
      this.data = {
        chartData: [{
          id: 'product',
          name: '產品',
          data: [
            {
              labels: [],
              datasets: [],
              type: 'nodata',
              subType: 'ana-error'
            },
            ...(
              infoHeaderViewModel.multiViewModel.hasCompare
                ? [{
                  labels: [],
                  datasets: [],
                  type: 'nodata',
                  subType: 'ana-error'
                }]
                : []
            )
          ]
        }]
      };
      return;
    }

    const data = ProductNegativeSentimentTrendModel.generateData(origin);
    const dataEmpty = data ? ProductNegativeSentimentTrendModel.checkHasData(data) : true;
    const prevData = ProductNegativeSentimentTrendModel.generateData(prevOrigin, 'previous');
    const prevDataEmpty = prevData ? ProductNegativeSentimentTrendModel.checkHasData(prevData) : true;
    const csv = ProductNegativeSentimentTrendModel.generateCSV(origin, prevOrigin);
    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'product_sentiment_negative_trend',
          name: '總體產品負面情緒趨勢',
          data: [
            ...(
              !dataEmpty
                ? [{
                  labels: data[0].item[0].series.map((el) => el.date),
                  datasets: data.map((el, i) => ({
                    label: el.name,
                    productId: el.id,
                    accurate: data[0].item[0].series.map((d) => d.accurate ?? d.date),
                    data: el.item.find((s) => s.name === '-1').series.map((p) => p.count),
                    borderColor: COLOR_SET[i % 20]
                  }))
                }]
                : [{
                  type: 'nodata',
                  subType: data ? 'default' : 'ana-error'
                }]
            ),
            ...(
              !prevDataEmpty
                ? [{
                  labels: prevData[0].item[0].series.map((el) => el.date),
                  datasets: prevData.map((el, i) => ({
                    label: el.name,
                    productId: el.id,
                    accurate: prevData[0].item[0].series.map((d) => d.accurate ?? d.date),
                    data: el.item.find((s) => s.name === '-1').series.map((p) => p.count),
                    borderColor: COLOR_SET[i % 20]
                  }))
                }]
                : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{
                        type: 'nodata',
                        subType: prevData ? 'default' : 'ana-error'
                      }]
                      : []
                  )
                ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType,
                category
              } = query;
              const { datasetIndex, index } = elements[0];
              const [gte, lte] = chart.data.datasets[datasetIndex].accurate[index].split('~');
              const productId = chart.data.datasets[datasetIndex].productId;
              const productName = chart.data.datasets[datasetIndex].label;
              const womCount = chart.data.datasets[datasetIndex].data[index];

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category ? {
                  category: category.map((el) => ({
                    id: el,
                    name: SOURCE_CATEGORY_TYPE[el]
                  }))
                } : {
                  category: SOURCE_CATEGORY.map((el) => ({
                    id: el.value,
                    name: el.label
                  }))
                }),
                sentiment: [-1],
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/產品/情緒分析/總體產品負面情緒趨勢'
                },
                product: [{
                  id: productId,
                  name: productName
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'line',
          options: {
            responsive: true,
            event: ['click'],
            tension: 0.3,
            scales: {
              y: {
                beginAtZero: true,
                grace: 5,
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ]
    };
  }

  static generateData = (list, time = 'present') => {
    if (list.length === 0) {
      return null;
    }
    const totalDays = getTotalDays(time);
    return list.slice(0, 20).map((ori) => {
      const items = ori.item.map((item) => {
        if (totalDays >= 32 && totalDays <= 90) {
          const dayArr = generateCompletedDayArray(time);
          item.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...item,
            series: dayArr.map((el) => ({
              date: el.id,
              count: el.count
            }))
          };
        }

        if (totalDays >= 91) {
          const dayArr = generateCompletedDayArray(time);
          item.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...item,
            series: dayArr.map((el) => ({
              date: el.id,
              accurate: `${el.days[0]}~${el.days[el.days.length - 1]}`,
              count: el.count
            }))
          };
        }

        const dayArr = generateCompletedDayArray(time);
        return {
          ...item,
          series: dayArr.map((d) => ({
            date: d,
            count: item.series.find((s) => s.date === d)?.count ?? 0
          }))
        };
      });
      return {
        ...ori,
        item: items
      };
    });
  };

  static generateCSV = (data, prevData) => {
    const csv = [];
    if (data.length > 0) {
      const currCsv = data[0].item[0].series.map((el) => {
        const row = {
          date: el.date
        };
        for (let i = 0; i < data.length; i += 1) {
          row[`${data[i].name}`] = data[i].item.find((item) => item.name === '-1')?.series.find((s) => s.date === el.date)?.count ?? 0;
        }
        return row;
      });

      csv.push(...currCsv);
    }

    if (prevData.length > 0) {
      const previousCsv = prevData[0].item[0].series.map((el) => {
        const row = {
          date: el.date
        };
        for (let i = 0; i < prevData.length; i += 1) {
          row[`${prevData[i].name}`] = prevData[i].item.find((item) => item.name === '-1')?.series.find((s) => s.date === el.date)?.count ?? 0;
        }
        return row;
      });

      csv.push(...previousCsv);
    }

    return csv;
  };

  static checkHasData = (data) => {
    return data.every((el) => {
      const target = el.item.find((item) => item.name === '-1');
      return target?.series?.every((date) => date.count === 0);
    });
  };

  static fromRes(data, prevOrigin, query) {
    return new ProductNegativeSentimentTrendModel(data, prevOrigin, query);
  }
}
