import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import dayjs from 'dayjs';
import { message } from 'antd';

import UserService from 'src/services/user';

import userStore from 'src/stores/userStore';

export default class EditDrawerViewModel {
  @observable isDrawerOpen = false;
  @observable parent = {};

  // > user data
  @observable uid = '';
  @observable userSSOIdentity = '';
  @observable userDepartment = '';
  @observable userName = '';
  @observable userAccount = '';

  // > user VOC data
  @observable vocLoginAuth = '';
  @observable vocLoginAuthCache = '';

  @observable vocIdentity = '';
  @observable vocIdentityCache = '';

  @observable salesmanDep = '';
  @observable salesmanDepCache = '';
  @observable departmentList = [];

  @observable salesman = '';
  @observable salesmanCache = '';
  @observable salesList = [];

  @observable accountValid = ['', ''];
  @observable accountValidCache = ['', ''];
  @observable openPackages = 0;
  @observable openPackagesCache = 0;

  // > user voc auth section
  @observable search = true;
  @observable searchCache = true;
  @observable topic = true;
  @observable topicCache = true;
  @observable chart = true;
  @observable chartCache = true;
  @observable wordbook = true;
  @observable wordbookCache = true;
  @observable related = true;
  @observable relatedCache = true;
  @observable advanceSearch = true;
  @observable advanceSearchCache = true;
  @observable notification = true;
  @observable notificationCache = true;

  // > user data report auth
  @observable industryTrend = true;
  @observable industryTrendCache = true;
  @observable marketDifferentiation = true;
  @observable marketDifferentiationCache = true;
  @observable brandCompetition = true;
  @observable brandCompetitionCache = true;
  @observable brandHealth = true;
  @observable brandHealthCache = true;
  @observable brandEffect = true;
  @observable brandEffectCache = true;
  @observable brandRoutine = true;
  @observable brandRoutineCache = true;

  // > user advance auth
  @observable marketing7P = true;
  @observable marketing7PCache = true;
  @observable mindShare = true;
  @observable mindShareCache = true;
  @observable starProduction = true;
  @observable starProductionCache = true;
  @observable reputation = true;
  @observable reputationCache = true;

  @observable inProcess = false;

  @observable activePackages = 0;
  @observable showErrorModal = false;

  @observable isValidate = false;
  @observable validation = {
    sales: false,
    time: false,
    packages: false
  };

  @computed get isMyData() {
    return this.uid === userStore.userId;
  }

  @computed get errorSales() {
    return this.isValidate && !this.validation.sales;
  }

  @computed get errorTime() {
    return this.isValidate && !this.validation.time;
  }

  @computed get errorPackages() {
    return this.isValidate && !this.validation.packages;
  }

  @computed get availableIdentity() {
    if (this.userSSOIdentity === '客戶') {
      return ['customer'];
    }
    switch (userStore.userType) {
      case 'admin':
        return ['admin', 'teamLeader', 'member', 'customer'];
      case 'teamLeader':
        return ['teamLeader', 'member', 'customer'];
      default:
        return ['customer'];
    }
  }

  @computed get availableSubmit() {
    return this.vocLoginAuth !== this.vocLoginAuthCache || this.vocIdentity !== this.vocIdentityCache
      || this.salesmanDep !== this.salesmanDepCache || this.salesman !== this.salesmanCache
      || this.accountValid?.[0] !== this.accountValidCache?.[0] || this.accountValid?.[1] !== this.accountValidCache?.[1]
      || this.openPackages !== this.openPackagesCache || this.search !== this.searchCache || this.chart !== this.chartCache || this.topic !== this.topicCache
      || this.wordbook !== this.wordbookCache || this.related !== this.relatedCache || this.advanceSearch !== this.advanceSearchCache
      || this.notification !== this.notificationCache || this.industryTrend !== this.industryTrendCache || this.marketDifferentiation !== this.marketDifferentiationCache
      || this.brandCompetition !== this.brandCompetitionCache || this.brandHealth !== this.brandHealthCache || this.brandEffect !== this.brandEffectCache
      || this.brandRoutine !== this.brandRoutineCache || this.marketing7P !== this.marketing7PCache || this.mindShare !== this.mindShareCache
      || this.starProduction !== this.starProductionCache || this.reputation !== this.reputationCache;
  }

  @computed get showSales() {
    if (userStore.userType === 'member') {
      return [{ value: userStore.userId, label: userStore.userName }];
    }
    return this.salesList.filter((el) => el.teamId === this.salesmanDep && el.id !== this.uid).map((el) => ({ value: el.id, label: el.name }));
  }

  @computed get errorContent() {
    switch (this.showErrorModal) {
      case 'packages':
        return '使用者現有數據包數量大於數據包上限，請重新設定';
      default:
        return null;
    }
  }

  constructor(parent) {
    makeObservable(this);

    this.init(parent);
  }

  @action init = (parent) => {
    this.parent = parent;
  };

  @action onDrawerOpen = async (data) => {
    const {
      uid,
      userSSOIdentity,
      userDepartment,
      userName,
      userAccount
    } = data;

    const depText = userDepartment?.map?.((el) => {
      return this.parent.departmentList.find((d) => d.value === el)?.label;
    }).join(', ') ?? userDepartment;

    this.uid = uid;
    this.userSSOIdentity = userSSOIdentity;
    this.userDepartment = depText;
    this.userName = userName;
    this.userAccount = userAccount;

    await this.getUserInfo();
    runInAction(() => {
      this.isDrawerOpen = true;
    });
  };

  @action onDrawerClose = () => {
    this.isDrawerOpen = false;
  };

  @action updateDepSelect = (list) => {
    this.departmentList = [...list];
  };

  @action updateSalesSelect = (list) => {
    this.salesList = [...list];
  };

  @action getUserInfo = async () => {
    this.inProcess = true;
    try {
      const res = await UserService.getUserInfoAuth(this.uid);
      runInAction(() => {
        const {
          vocLoginAuth,
          vocIdentity,
          salesman,
          accountValid,
          openPackages,
          search,
          topic,
          chart,
          wordbook,
          related,
          advanceSearch,
          notification,
          dataReport,
          advance,
          activePackages
        } = res;


        const {
          industryTrend,
          marketDifferentiation,
          brandCompetition,
          brandHealth,
          brandEffect,
          brandRoutine
        } = dataReport;

        const {
          marketing7P,
          mindShare,
          starProduction,
          reputation
        } = advance;

        this.vocLoginAuth = vocLoginAuth;
        this.vocLoginAuthCache = vocLoginAuth;
        this.vocIdentity = vocIdentity;
        this.vocIdentityCache = vocIdentity;
        this.salesmanDep = this.salesList.find((el) => el.id === salesman)?.teamId ?? userStore.userTeamId;
        this.salesmanDepCache = this.salesmanDep;
        this.salesman = salesman;
        this.salesmanCache = salesman;
        this.accountValid = accountValid;
        this.accountValidCache = accountValid;
        this.openPackages = openPackages;
        this.openPackagesCache = openPackages;

        this.search = search;
        this.searchCache = search;
        this.topic = topic;
        this.topicCache = topic;
        this.chart = chart;
        this.chartCache = chart;
        this.wordbook = wordbook;
        this.wordbookCache = wordbook;
        this.related = related;
        this.relatedCache = related;
        this.advanceSearch = advanceSearch;
        this.advanceSearchCache = advanceSearch;
        this.notification = notification;
        this.notificationCache = notification;

        this.industryTrend = industryTrend;
        this.industryTrendCache = industryTrend;
        this.marketDifferentiation = marketDifferentiation;
        this.marketDifferentiationCache = marketDifferentiation;
        this.brandCompetition = brandCompetition;
        this.brandCompetitionCache = brandCompetition;
        this.brandHealth = brandHealth;
        this.brandHealthCache = brandHealth;
        this.brandEffect = brandEffect;
        this.brandEffectCache = brandEffect;
        this.brandRoutine = brandRoutine;
        this.brandRoutineCache = brandRoutine;

        this.marketing7P = marketing7P;
        this.marketing7PCache = marketing7P;
        this.mindShare = mindShare;
        this.mindShareCache = mindShare;
        this.starProduction = starProduction;
        this.starProductionCache = starProduction;
        this.reputation = reputation;
        this.reputationCache = reputation;

        this.activePackages = activePackages;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => {
        this.vocLoginAuth = 'disable';
        this.vocLoginAuthCache = 'disable';
        this.vocIdentity = 'customer';
        this.vocIdentityCache = 'customer';
        if (userStore.userType === 'member' || userStore.userType === 'teamLeader') {
          this.salesmanDep = userStore.userTeamId;
          this.salesmanDepCache = userStore.userTeamId;
        } else {
          this.salesmanDep = null;
          this.salesmanDepCache = null;
        }
        if (userStore.userType === 'member') {
          this.salesman = userStore.userId;
          this.salesmanCache = userStore.userId;
        } else {
          this.salesman = null;
          this.salesmanCache = null;
        }
        this.accountValid = [];
        this.accountValidCache = [];
        this.openPackages = 0;
        this.openPackagesCache = 0;

        this.search = false;
        this.searchCache = false;
        this.topic = false;
        this.topicCache = false;
        this.chart = false;
        this.chartCache = false;
        this.wordbook = false;
        this.wordbookCache = false;
        this.related = false;
        this.relatedCache = false;
        this.advanceSearch = false;
        this.advanceSearchCache = false;
        this.notification = false;
        this.notificationCache = false;

        this.industryTrend = false;
        this.industryTrendCache = false;
        this.marketDifferentiation = false;
        this.marketDifferentiationCache = false;
        this.brandCompetition = false;
        this.brandCompetitionCache = false;
        this.brandHealth = false;
        this.brandHealthCache = false;
        this.brandEffect = false;
        this.brandEffectCache = false;
        this.brandRoutine = false;
        this.brandRoutineCache = false;

        this.marketing7P = false;
        this.marketing7PCache = false;
        this.mindShare = false;
        this.mindShareCache = false;
        this.starProduction = false;
        this.starProductionCache = false;
        this.reputation = false;
        this.reputationCache = false;

        this.activePackages = 0;
      });
    } finally {
      runInAction(() => {
        this.inProcess = false;
      });
    }
  };

  @action onVOCLoginAuthChange = (e) => {
    this.vocLoginAuth = e;
  };

  @action onVOCIdentityChange = (e) => {
    this.vocIdentity = e;
  };

  @action onSalesmanDepartmentChange = (e) => {
    this.salesmanDep = e;
    this.salesman = null;
  };

  @action onSalesmanChange = (e) => {
    this.salesman = e;

    if (!this.validation.sales) {
      this.validation.sales = true;
    }
  };

  @action onAccountValidChange = (date, __, info) => {
    if (info.range !== 'end') {
      return;
    }
    this.accountValid = date;

    if (!this.validation.time) {
      this.validation.time = true;
    }
  };

  @action onOpenPackagesChange = (e) => {
    this.openPackages = e;

    if (!this.validation.packages) {
      this.validation.packages = true;
    }
  };


  @action onSearchToggle = (e) => {
    this.search = e;
  };

  @action onTopicToggle = (e) => {
    this.topic = e;
  };

  @action onChartToggle = (e) => {
    this.chart = e;
  };

  @action onWordBookToggle = (e) => {
    this.wordbook = e;
  };

  @action onRelatedToggle = (e) => {
    this.related = e;
  };

  @action onAdvanceSearchToggle = (e) => {
    this.advanceSearch = e;
  };

  @action onBulletinToggle = (e) => {
    this.notification = e;
  };

  @action onIndustryTrendToggle = (e) => {
    this.industryTrend = e;
  };

  @action onMarketDifferentiationToggle = (e) => {
    this.marketDifferentiation = e;
  };

  @action onBrandCompetitionToggle = (e) => {
    this.brandCompetition = e;
  };

  @action onBrandHealthToggle = (e) => {
    this.brandHealth = e;
  };

  @action onBrandEffectToggle = (e) => {
    this.brandEffect = e;
  };

  @action onBrandRoutineToggle = (e) => {
    this.brandRoutine = e;
  };

  @action onMarketing7PToggle = (e) => {
    this.marketing7P = e;
  };

  @action onMindShareToggle = (e) => {
    this.mindShare = e;
  };

  @action onStarProductionToggle = (e) => {
    this.starProduction = e;
  };

  @action onReputationToggle = (e) => {
    this.reputation = e;
  };

  @action onCheckData = () => {
    if (this.vocIdentity !== 'customer') {
      return true;
    }

    if (!this.isValidate) {
      this.isValidate = true;
    }

    if (!this.salesman) {
      this.validation.sales = false;
    } else {
      this.validation.sales = true;
    }

    if (!this.accountValid[0] || !this.accountValid[1]) {
      this.validation.time = false;
    } else {
      this.validation.time = true;
    }

    if (this.openPackages > 0) {
      this.validation.packages = true;
    } else {
      this.validation.packages = false;
    }

    if (Object.values(this.validation).includes(false)) {
      message.error('資料有誤，請重新確認！');
      return false;
    }

    return true;

  };

  @action onSubmit = async () => {

    if (!this.availableSubmit) {
      return;
    }

    const valid = this.onCheckData();
    if (!valid) {
      return;
    }

    const permissions = {
      publicOpinionSearch: this.search,
      topic: this.topic,
      analysisCharts: this.chart,
      analysisLexicon: this.wordbook,
      relatedSearch: this.related,
      advancedSearch: this.advanceSearch,
      notification: this.notification,
      marketingTrend: this.industryTrend,
      marketDifference: this.marketDifferentiation,
      brandCompetitiveness: this.brandCompetition,
      brandHealthCheck: this.brandHealth,
      brandEffect: this.brandEffect,
      brandReport: this.brandRoutine,
      market7p: this.marketing7P,
      mindShare: this.mindShare,
      starProduct: this.starProduction,
      reputationAnalysis: this.reputation
    };

    const data = {
      vocStatus: this.vocLoginAuth,
      vocPermission: this.vocIdentity,
      ...(this.vocIdentity === 'customer' && { assignedSalesId: this.salesman }),
      ...(this.vocIdentity === 'customer' && { startDate: dayjs(this.accountValid[0]).startOf('day').toISOString() }),
      ...(this.vocIdentity === 'customer' && { endDate: dayjs(this.accountValid[1]).endOf('day').toISOString() }),
      ...(this.vocIdentity === 'customer' && { dataPackageAmount: this.openPackages }),
      accessibleFeatures: permissions
    };

    try {
      await UserService.updateUserInfo(this.uid, data);
      runInAction(() => {
        message.success('使用者資料更新成功！');
        this.onDrawerClose();
        this.parent.afterAccountEdit();
      });
    } catch (error) {
      if (error.response?.status === 412) {
        this.showErrorModal = 'packages';
      }
      console.log('error', error);
    }
  };

  @action onModalClose = () => {
    this.showErrorModal = null;
  };
}

