import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import Tree from 'react-d3-tree';
import optionsStore from 'src/stores/optionsStore';

import { Button, Modal, Segmented } from 'antd';

import styles from './styles.module.scss';

@observer
class WordAssocs extends React.Component {
  _renderNode = ({ nodeDatum, toggleNode, hierarchyPointNode }) => {
    const { name, score } = nodeDatum;
    const { parent } = hierarchyPointNode;
    const isFist = parent?.data.name === this.props.viewModel.selectedMainWord;

    const textStyle = (() => {
      if (!parent) {
        return styles.parentText;
      }
      if (isFist) {
        return styles.firstText;
      }
      return styles.nodeText;
    })();

    const circleSize = 12 / (this.props.viewModel.scale * 2);
    const totalText = Math.ceil(name.length * 20 * 1.1) * -1 - circleSize / 2 - 5;

    return (
      <g
        data-tippy-content={parent ? `${name}： ${score}` : null}
        onClick={() => {
          if (!parent) {
            toggleNode();
            this.props.viewModel.onClick();
          } else if (isFist) {
            this.props.viewModel.onFirstClick(toggleNode, name, score);
          } else {
            this.props.viewModel.onTopic(name, score);
          }
        }}
        style={{ pointerEvents: 'auto' }}
      >
        <circle
          r={circleSize}
          fill="#F1F5F9"
        />
        <text
          className={clsx(textStyle, (isFist || !parent) ? name : '')}
          x={(parent && !isFist) ? 10 : totalText}
          y={7}
        >
          {name}
        </text>
      </g>
    );
  };

  componentDidMount() {
    this.props.viewModel.didMount();
  }

  componentWillUnmount() {
    this.props.viewModel.once?.();
  }

  render() {
    const { viewModel } = this.props;
    return (
      <>
        <div
          className={styles.tabContainer}
          style={{ display: optionsStore.isImgDownload ? 'none' : 'flex' }}
        >
          <div className={styles.mainText}>
            選擇母詞
          </div>
          <Segmented
            options={viewModel.mainWordList}
            value={viewModel.selectedMainWord}
            onChange={viewModel.onMainWordChange}
            className={styles.segment}
          />
        </div>
        <div ref={viewModel.containerRef} style={{ width: '100%', height: viewModel.containerHeight, position: 'relative' }}>
          <Tree
            data={viewModel.assocs}
            zoom={viewModel.scale}
            translate={viewModel.translate}
            draggable={false}
            zoomable={false}
            scaleExtent={{ min: 0.1, max: 2 }}
            nodeSize={{ x: 400, y: 25 }}
            renderCustomNodeElement={this._renderNode}
            rootNodeClassName="branchNode"
            branchNodeClassName="branchNode"
            leafNodeClassName="branchNode"
            onUpdate={viewModel.centerTree}
          />
        </div>
        <Modal
          open={viewModel.isModalOpen}
          closable={false}
          centered
          onCancel={viewModel.onClose}
          className="selectModal"
          width={400}
          footer={null}
        >
          <div className={styles.modalContainer}>
            <div className={styles.title}>
              {`選擇第一階層詞彙-${viewModel.targetName}`}
            </div>
            <div className={styles.btnContainer}>
              <Button
                onClick={viewModel.onTopicModalTopicClick}
              >
                查看話題列表
              </Button>
              <Button
                onClick={viewModel.onModalToggleClick}
              >
                打開/收合下一層
              </Button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

WordAssocs.propTypes = {
  viewModel: PropTypes.object
};

WordAssocs.defaultProps = {
  viewModel: {}
};


export default WordAssocs;
