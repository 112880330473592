import dayjs from 'dayjs';

export default class PackageInfoModel {
  constructor(data) {
    const {
      owner,
      name,
      projectId,
      projectMeta,
      startDate,
      endDate,
      industry,
      brand,
      tag,
      product,
      searchKeywordLevel1Ids,
      searchTags,
      searchKeywordLevel3Ids,
      featureSetId,
      sharedUsers,
      lastChangeDate
    } = data;

    this.owner = owner;
    this.name = name;
    this.selectedTeam = projectMeta.owner?.team.id ?? null;
    this.selectedSales = projectMeta.owner?.user.id ?? null;
    this.projectId = projectId;
    this.validDate = [dayjs(startDate), dayjs(endDate)];
    this.openedLevel = {
      industry,
      brand,
      series: tag,
      product
    };
    this.searchKeywordLevel1Ids = searchKeywordLevel1Ids;
    this.searchTags = searchTags;
    this.searchKeywordLevel3Ids = searchKeywordLevel3Ids;
    this.featureSetId = featureSetId;
    this.sharedUsers = sharedUsers.map((el) => el.id);
    this.lastChangeDate = lastChangeDate;
  }
  static fromRes(data) {
    return new PackageInfoModel(data);
  }
}
