import { CATEGORY_COLOR_SET } from 'src/consts/chart';
import { SOURCE_CATEGORY_TYPE, SOURCE_ORDER_TC } from 'src/consts';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { generateCSVKey } from 'src/utils/chart';

export default class ProductSourceCategoryProportionModel {
  constructor(data, prevData, query) {
    if (data.length === 0 && prevData.length === 0) {
      this.data = {
        chartData: [{
          id: 'product_source_category_proportion',
          name: '產品',
          data: [
            {
              labels: [],
              datasets: [],
              type: 'nodata',
              subType: 'ana-error'
            },
            ...(
              infoHeaderViewModel.multiViewModel.hasCompare
                ? [{
                  labels: [],
                  datasets: [],
                  type: 'nodata',
                  subType: 'ana-error'
                }]
                : []
            )
          ]
        }]
      };
      return;
    }

    const productList = ProductSourceCategoryProportionModel.generateTabList(data, prevData);
    const currTime = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD')).join('~');
    const prevTime = infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD')).join('~');
    const csvForAll = [];
    productList.forEach((product) => {
      const targetInData = data.find((el) => product.id === el.id);
      targetInData.item.sort((a, b) => b.count - a.count);
      const targetInPrevData = prevData?.find((el) => product.id === el.id);
      targetInPrevData?.item.sort((a, b) => b.count - a.count);
      const total = (() => {
        if (!targetInData) {
          return null;
        }
        let sum = 0;
        targetInData.item.forEach((c) => {
          sum += c.count;
        });
        return sum;
      })();
      const prevTotal = (() => {
        if (!targetInPrevData) {
          return null;
        }
        let sum = 0;
        targetInPrevData.item.forEach((c) => {
          sum += c.count;
        });
        return sum;
      })();
      const csv = ProductSourceCategoryProportionModel.generateCSV(targetInData, total, targetInPrevData, prevTotal);
      const csvKey = generateCSVKey(csv[0]);
      csvForAll.push({
        id: product.id,
        name: product.name,
        csv,
        csvKey
      });
    });

    this.data = {
      chartData: productList.slice(0, 20).map((product) => {
        const targetInData = data.find((el) => product.id === el.id);
        targetInData.item.sort((a, b) => b.count - a.count);
        const targetInPrevData = prevData?.find((el) => product.id === el.id);
        targetInPrevData?.item.sort((a, b) => b.count - a.count);
        const total = (() => {
          if (!targetInData) {
            return null;
          }
          let sum = 0;
          targetInData.item.forEach((c) => {
            sum += c.count;
          });
          return sum;
        })();
        const prevTotal = (() => {
          if (!targetInPrevData) {
            return null;
          }
          let sum = 0;
          targetInPrevData.item.forEach((c) => {
            sum += c.count;
          });
          return sum;
        })();
        const { csv, csvKey } = csvForAll.find((el) => el.id === product.id);

        return {
          id: product.id,
          name: product.name,
          data: [
            ...(total
              ? [{
                labels: targetInData.item.map((item) => SOURCE_CATEGORY_TYPE[item.name]),
                datasets: [{
                  label: currTime,
                  data: targetInData.item.map((item) => item.count),
                  categoryId: targetInData.item.map((item) => item.name),
                  backgroundColor: targetInData.item.map((item) => CATEGORY_COLOR_SET[item.name]),
                  datalabels: {
                    color: '#fff',
                    formatter: (value) => {
                      const percentage = ((value / total) * 100).toFixed();
                      return percentage > 5 ? `${percentage}%` : null;
                    }
                  },
                  tooltip: {
                    callbacks: {
                      label: (context) => {
                        const percentage = ((context.parsed / total) * 100).toFixed();
                        return ` ${context.parsed}: [${percentage}%]`;
                      }
                    }
                  }
                }]
              }]
              : [{
                type: 'nodata',
                subType: targetInData ? 'pie' : 'default'
              }]
            ),
            ...(prevTotal
              ? [{
                labels: targetInPrevData.item.map((item) => SOURCE_CATEGORY_TYPE[item.name]),
                datasets: [{
                  label: prevTime,
                  data: targetInPrevData.item.map((item) => item.count),
                  categoryId: targetInPrevData.item.map((item) => item.name),
                  backgroundColor: targetInPrevData.item.map((item) => CATEGORY_COLOR_SET[item.name]),
                  datalabels: {
                    color: '#fff',
                    formatter: (value) => {
                      const percentage = ((value / prevTotal) * 100).toFixed();
                      return percentage > 5 ? `${percentage}%` : null;
                    }
                  },
                  tooltip: {
                    callbacks: {
                      label: (context) => {
                        const percentage = ((context.parsed / prevTotal) * 100).toFixed();
                        return ` ${context.parsed}: [${percentage}%]`;
                      }
                    }
                  }
                }]
              }]
              : [
                ...(
                  infoHeaderViewModel.multiViewModel.hasCompare
                    ? [{
                      type: 'nodata',
                      subType: targetInPrevData ? 'pie' : 'ana-error'
                    }]
                    : []
                )
              ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType
              } = query;
              const { datasetIndex, index } = elements[0];
              const category = chart.data.datasets[datasetIndex].categoryId[index];
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const [gte, lte] = chart.data.datasets[datasetIndex].label.split('~');

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                category: [{
                  id: category,
                  name: SOURCE_CATEGORY_TYPE[category]
                }],
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/產品/來源分析/產品來源類型/產品來源類型佔比'
                },
                product: [{
                  id: product.id,
                  name: product.name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'pie',
          options: {
            event: ['click'],
            plugins: {
              legend: {
                position: 'right',
                labels: {
                  sort: (a, b) => {
                    const indexA = SOURCE_ORDER_TC.indexOf(a.text);
                    const indexB = SOURCE_ORDER_TC.indexOf(b.text);
                    return indexA - indexB;
                  }
                }
              }
            }
          },
          csv,
          csvKey
        };
      }),
      csvForAll
    };
  }

  static generateTabList = (list, prevList) => {
    const tab = [];

    list?.forEach((el) => {
      const target = tab.find((item) => item.id === el.id);
      if (!target) {
        tab.push({ id: el.id, name: el.name });
      }
    });
    // prevList.forEach((el) => {
    //   const target = tab.find((item) => item.id === el.id);
    //   if (!target) {
    //     tab.push({ id: el.id, name: el.name });
    //   }
    // });

    return tab;
  };

  static generateCSV = (curr, currTotal, prev, prevTotal) => {
    const csv = [];

    if (curr) {
      const time = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'));
      const row = {
        date: time.join('~')
      };
      curr.item.forEach((el) => {
        row[`${el.name}`] = el.count;
        row[`${el.name}-佔比`] = el.count / currTotal;
      });
      csv.push(row);
    }

    if (prev) {
      const time = infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD'));
      const row = {
        date: time.join('~')
      };
      prev.item.forEach((el) => {
        row[`${el.name}`] = el.count;
        row[`${el.name}-佔比`] = el.count / prevTotal;
      });
      csv.push(row);
    }

    return csv;
  };

  static fromRes(data, prevData, query) {
    return new ProductSourceCategoryProportionModel(data, prevData, query);
  }
}
