import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import styles from './styles.module.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
);


@observer
class CustomizeStackBarChart extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.chartContainer}>
          <Bar key={viewModel.key} options={viewModel.options} data={viewModel.chartData} ref={viewModel.ref} />
        </div>
        <div className={styles.tagContainer}>
          {
            viewModel.legend.map((el) => {
              return (
                <div
                  key={el.id}
                  className={styles.tag}
                  onClick={() => viewModel.onBlock(el.id)}
                >
                  <div
                    className={styles.tagColorBlock}
                    style={{
                      backgroundColor: el.color
                    }}
                  />
                  <div
                    className={clsx(
                      styles.tagText,
                      viewModel.blocks.includes(el.id) && styles.block
                    )}
                  >
                    {el.name}
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }
}

CustomizeStackBarChart.propTypes = {
  viewModel: PropTypes.object
};

CustomizeStackBarChart.defaultProps = {
  viewModel: {}
};


export default CustomizeStackBarChart;
