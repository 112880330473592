import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ChartDataLabels
);


@observer
class RadarChart extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', columnGap: 20 }}>
        <div>
          <Radar width={400} key={viewModel.key} options={viewModel.options} data={viewModel.data} ref={viewModel.ref} plugins={viewModel.plugins} />
        </div>
        <div id={viewModel.id} />
      </div>
    );
  }
}

RadarChart.propTypes = {
  viewModel: PropTypes.object
};

RadarChart.defaultProps = {
  viewModel: {}
};


export default RadarChart;
