import { request, getHost, getHeaders } from './utils';

export const logout = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/auth/logout`,
    headers: getHeaders()
  };
  return request(options);
};

export const checkMaintenance = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/common/maintenance`,
    headers: getHeaders()
  };
  return request(options);
};
