import API from 'src/apis';
import AnnouncementModel from 'src/models/response/Announcement';

export default class AnnouncementService {
  static async getAnnouncementList() {
    const res = await API.announcement.getAnnouncement();

    return { list: res.data.result.map((el) => AnnouncementModel.fromRes(el)) };
  }

  static async createAnnouncement(data) {
    const res = await API.announcement.createAnnouncement(data);

    return AnnouncementModel.fromRes(res.data.result);
  }

  static async putAnnouncement(id, data) {
    await API.announcement.putAnnouncement(id, data);
  }

  static async deleteAnnouncement(id) {
    await API.announcement.deleteAnnouncement(id);
  }
}
