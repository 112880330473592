import { getHost } from 'src/utils';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { getTwo } from 'src/utils/chart';

export default class TrendWordsModel {
  constructor(data, stats, query, search) {
    if (data.length === 0) {
      this.data = {
        chartData: [{
          id: 'industry_sentiment_overview',
          name: '品牌趨勢詞',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }]
        }]
      };
      return;
    }
    const { avg, std } = stats;

    const newData = data.map((el) => ({
      name: el.name,
      count: el.count,
      score: getTwo(el.score),
      showScore: Math.max(getTwo(el.score), 0)
    }));

    const levels = [0, avg + std, avg + std * 2, avg + std * 3];


    const bgc = ['rgba(76, 131, 161, 0.3)', 'rgba(153, 174, 107, 0.3)', 'rgba(223, 186, 54, 0.3)', 'rgba(210, 125, 58, 0.3)'];

    const levelLines = {
      id: 'levelLines',
      beforeDatasetsDraw(chart) {
        const { ctx, chartArea: { top, height },
          scales: { x } } = chart;
        ctx.save();
        const levelArr = [...levels];

        if (levelArr[levelArr.length - 1] > x.max) {
          levelArr.pop();
        }

        if (levelArr[levelArr.length - 1] > x.max) {
          levelArr.pop();
        }

        levelArr.push(x.max);

        levelArr.forEach((level, i) => {
          if (i === levelArr.length - 1) {
            return;
          }

          ctx.beginPath();
          ctx.fillStyle = bgc[i];
          ctx.fillRect(x.getPixelForValue(level), top, (x.getPixelForValue(levelArr[i + 1]) - x.getPixelForValue(level)), height);
        });
      }
    };

    const csv = newData.map((el) => ({
      word: el.name,
      count: el.count,
      score: el.score
    }));

    const csvKey = [
      { key: 'word', header: '趨勢詞' },
      { key: 'count', header: '提及則數' },
      { key: 'score', header: '本期趨勢指數' }
    ];

    this.data = {
      chartData: [
        {
          id: 'industry_source_category_ranking',
          name: '品牌趨勢詞',
          data: [
            ...(
              newData && !newData.every((el) => el.count === 0)
                ? [{
                  labels: newData.map((el) => el.name),
                  datasets: [
                    {
                      label: '',
                      data: newData.map((el) => el.showScore),
                      score: newData.map((el) => el.score),
                      count: newData.map((el) => el.count),
                      backgroundColor: '#1B2B67',
                      borderSkipped: true,
                      maxBarThickness: 50
                    }
                  ]
                }]
                : [{ type: 'nodata' }]
            )

          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType,
                category
              } = query;
              const { datasetIndex, index } = elements[0];
              const [gte, lte] = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'));
              const score = chart.data.datasets[datasetIndex].score[index];
              const name = chart.data.labels[index];

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category ? {
                  category: category.map((el) => ({
                    id: el,
                    name: SOURCE_CATEGORY_TYPE[el]
                  }))
                } : {
                  category: SOURCE_CATEGORY.map((el) => ({
                    id: el.value,
                    name: el.label
                  }))
                }),
                term: {
                  name,
                  score,
                  trendScore: score
                },
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                brand: [{
                  id: search.id,
                  name: search.name
                }],
                chart: {
                  name: '圖表分析/品牌/關鍵字分析/品牌趨勢詞'
                },
                date: {
                  gte,
                  lte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'horizontal-bar',
          options: {
            indexAxis: 'y',
            interaction: {
              intersect: false,
              mode: 'nearest',
              axis: 'y'
            },
            event: ['click'],
            scales: {
              x: {
                beginAtZero: true
              }
            },
            plugins: {
              legend: {
                display: false
              },
              datalabels: {
                display: false
              },
              tooltip: {
                displayColors: false,
                callbacks: {
                  label: (context) => {
                    const { dataset, parsed } = context;
                    const { score } = dataset;
                    const { y } = parsed;
                    return `本期趨勢指數：${score[y]}(成長比例)`;
                  },
                  afterLabel: (context) => {
                    const { dataset, parsed } = context;
                    const { count } = dataset;
                    const { y } = parsed;
                    return `提及則數：${count[y]}`;
                  }
                }
              }
            }
          },
          plugins: [levelLines],
          csv,
          csvKey
        }
      ]
    };
  }

  static fromRes(data, stats, query, search) {
    return new TrendWordsModel(data, stats, query, search);
  }
}
