import React, { useEffect, useRef } from 'react';

import { DatePicker } from 'antd';
import { observer } from 'mobx-react';


const RangeDatePicker = observer((props) => {
  const containerName = props.containerName ?? 'main';
  const ref = useRef();
  const blurFunction = () => {
    if (ref.current) {
      ref.current.blur();
    }
  };

  useEffect(() => {
    const mainContainer = document.querySelector(`.${containerName}`);
    mainContainer.addEventListener('scroll', blurFunction);

    return () => {
      mainContainer.removeEventListener('scroll', blurFunction);
    };

  });

  return (
    <DatePicker.RangePicker ref={ref} {...props} />
  );
});

export default RangeDatePicker;
