import { makeObservable, observable, action, computed, runInAction, reaction } from 'mobx';
import React from 'react';
import html2canvas from 'html2canvas';
import { message } from 'antd';
import * as xlsx from 'xlsx';

import { getLevelText } from 'src/utils';
import { generateWorksheetName } from 'src/utils/chart';
import packageStore from 'src/stores/packageStore';
import SourceService from 'src/services/source';
import BlockedWordsService from 'src/services/blockedwords';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import optionsStore from 'src/stores/optionsStore';
import CategoryPopoverSelectViewModel from 'src/components/CategoryPopoverSelect/viewModel';
import WordbookService from 'src/services/wordbook';

import WordChartItemViewModel from './WordChartItem/viewModel';
import ChartItemViewModel from './ChartItem/viewModel';

export default class WordbookPageViewModel {
  @observable level = null;
  @observable selectedType = 'words';

  @observable selectedPostType = 'all';
  @observable selectedWOMType = 'all';
  @observable selectedTagKey = null;

  @observable categoryPopover = new CategoryPopoverSelectViewModel();

  @observable selectedIndex = 'frequency';
  @observable selectedCount = 20;

  @observable chartItems = {};

  @observable isActive = false;
  @observable xAxis = 0;
  @observable yAxis = 0;

  @observable blockWords = [];
  @observable cacheBlockWords = [];
  @observable isBlockedWordInit = false;

  @observable isDownload = false;
  @observable downloadList = [];
  @observable downloadFileName = '';

  @observable allCsv = [];

  @observable isInit = false;
  @observable once = null;

  reserverActiveTab = {
    tabId: '',
    featureId: ''
  };

  ref = React.createRef();

  @observable isStopModalOpen = false;

  @computed get isShowStatisticClean() {
    return this.selectedPostType !== 'all' || this.selectedWOMType !== 'all' || this.categoryPopover.selected.length > 0;
  }

  @computed get isShowChartClean() {
    return this.selectedIndex !== 'frequency' || this.selectedCount !== 20;
  }

  @computed get selectedCategory() {
    return this.categoryPopover.selected.map((el) => el.id);
  }

  @computed get chartTypeText() {
    return this.selectedType === 'radar' ? '雷達圖' : '文字雲';
  }

  @computed get imgTitle() {
    if (this.selectedType === 'words') {
      if (this.isDiscover) {
        return `「${this.chartItems.discoverName}」文字雲下探`;
      }
      return this.chartItems.chartTitle;
    }

    return this.radarChartTitle;
  }

  @computed get allCSVTitle() {
    return `${getLevelText(this.level)}-文字雲`;
  }

  @computed get radarChartTitle() {
    return `${getLevelText(this.level)}-雷達圖`;
  }

  @computed get radarChartDesc() {
    return this.level === 'industry'
      ? '呈現時間區間內，專案所有品牌各特性分類的聲量分佈。'
      : `呈現時間區間內，選擇之${getLevelText(this.level)}中各特性分類的聲量分佈。`;
  }

  @computed get isDiscover() {
    return this.selectedType === 'words' && this.chartItems.showDiscover;
  }

  constructor() {
    makeObservable(this);

    this.init();
  }

  @action getSource = async () => {
    optionsStore.setLoading('source');
    try {
      const { source } = await SourceService.getPackageSources({
        date: {
          gte: infoHeaderViewModel.multiViewModel.currentDate[0].format('YYYY-MM-DD'),
          lte: infoHeaderViewModel.multiViewModel.currentDate[1].format('YYYY-MM-DD')
        },
        ...(this.selectedPostType !== 'all' && { postType: this.selectedPostType }),
        ...(this.selectedWOMType !== 'all' && { womType: this.selectedWOMType })
      });
      runInAction(() => {
        this.categoryPopover.updateList(source);
        this.selectedTagKey = packageStore.series[0]?.id ?? null;
        this.isInit = true;
      });
    } catch (error) {
      console.log(error);
    } finally {
      optionsStore.setCompleted('source');
    }
  };

  @action init = async () => {
    try {
      if (!packageStore.isLevelProcessing) {
        this.getSource();
      } else {
        const once = reaction(
          () => packageStore.isLevelProcessing,
          async (bool) => {
            if (!bool) {
              this.getSource();
              once();
            }
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  @action setUpdateLevelReaction = () => {
    const levelOnce = reaction(
      () => this.level,
      (level) => {
        if (this.isInit) {
          if (this.level === 'series') {
            this.selectedTagKey = packageStore.series[0].id;
          }
          this.selectedType = 'words';
          this.reserverActiveTab = {
            tabId: '',
            featureId: ''
          };
          this.getGraphicData();
        } else {
          const once = reaction(
            () => this.isInit,
            async (bool) => {
              if (bool) {
                if (this.level === 'series') {
                  this.selectedTagKey = packageStore.series[0].id;
                }
                this.selectedType = 'words';
                this.reserverActiveTab = {
                  tabId: '',
                  featureId: ''
                };
                this.getGraphicData();
                once();
              }
            }
          );
        }
      }
    );
    this.once = levelOnce;
  };

  @action updateLevel = (router) => {
    const level = router?.params?.level;
    if (level && !['industry', 'series', 'brand', 'product'].includes(level)) {
      message.error('階層不存在');
      router.navigate('/home');
      return;
    }
    this.level = level;
  };

  @action onFilterSubmit = () => {
    this.reserveIds();

    this.getGraphicData();
  };

  @action reserveIds = () => {
    // > 保存剛才看到的tab ids
    if (this.selectedType !== 'words') {
      return;
    }

    this.reserverActiveTab = {
      tabId: this.chartItems.activeTabId,
      featureId: this.chartItems.activeFeatureId
    };
  };

  @action onTypeChange = (key) => {
    this.selectedType = key;
    if (this.selectedType === 'radar') {
      this.reserverActiveTab = {
        tabId: '',
        featureId: ''
      };
    }

    this.getGraphicData();
  };

  @action onPostTypeChange = (e) => {
    this.selectedPostType = e;
  };

  @action onWOMTypeChange = (e) => {
    this.selectedWOMType = e;
  };

  @action onSourceCategoryChange = (e) => {
    this.selectedSourceCategoryType = e;
  };

  @action onChartIndexChange = (e) => {
    this.selectedIndex = e;
  };

  @action onChartCountChange = (e) => {
    this.selectedCount = e;
  };

  @action onSeriesParentSelect = (e) => {
    this.selectedTagKey = e;

    this.getGraphicData();
  };

  @action onCleanStatisticFilter = () => {
    this.selectedPostType = 'all';
    this.selectedWOMType = 'all';
    this.categoryPopover.onCleanAll();
  };

  @action onCleanChartFilter = () => {
    this.selectedIndex = 'frequency';
    this.selectedCount = 20;
  };

  @action getGraphicData = async () => {
    optionsStore.setLoading('getChartData');

    this.chartItems = {};
    if (!this.isBlockedWordInit) {
      await this.getBlockedWords();
    }

    const checkLatest = await packageStore.checkLatest();
    if (checkLatest.needUpdate) {
      optionsStore.setCompleted('getChartData');
      await packageStore.syncLatest(checkLatest);
      return;
    }

    try {
      const data = {
        ...(this.selectedPostType !== 'all' && { postType: this.selectedPostType }),
        ...(this.selectedWOMType !== 'all' && { womType: this.selectedWOMType }),
        ...(this.categoryPopover.selected.length > 0 && { category: this.categoryPopover.selected.map((el) => el.id) }),
        ...(this.level === 'series' && { tagKey: this.selectedTagKey }),
        ...(this.selectedType === 'words' && {
          ...(this.blockWords.length > 0 && { disableTerm: this.blockWords.map((el) => el.name) }),
          metric: this.selectedIndex,
          size: this.selectedCount
        })
      };

      const res = await WordbookService.getWordBookChart(this.level, this.selectedType, data);

      runInAction(() => {
        if (this.selectedType === 'radar') {
          this.chartItems = new ChartItemViewModel(res.data, this);
        } else {
          this.chartItems = new WordChartItemViewModel(res.data, this);
          this.allCsv = res.data.csvForAll;
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        optionsStore.setCompleted('getChartData');
      });
    }
  };

  @action onDataDownload = () => {
    this.isDownload = false;
    const { csv, csvKey } = this.isDiscover ? this.chartItems.discoverWords : this.chartItems.showChart[0];
    if (!csv || !csvKey) {
      console.log('csv檔不存在');
      return;
    }
    const { currentTimeText, previousTimeText, hasCompare } = infoHeaderViewModel.multiViewModel;
    const data = [...csv];
    const header = csvKey.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [csvKey.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    const wordbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wordbook, workSheet, '分類詞庫');
    xlsx.writeFile(wordbook, `${packageStore.activePackageName}-${this.imgTitle}-${currentTimeText}${hasCompare ? `-${previousTimeText}` : ''}.xlsx`);
  };

  @action onDownload = async () => {
    await optionsStore.onImgDownload();
    const chart = document.querySelector('.download-chart');
    const canvas = await html2canvas(chart);
    const a = document.createElement('a');
    const { currentTimeText, previousTimeText, hasCompare } = infoHeaderViewModel.multiViewModel;
    a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
    a.download = `${packageStore.activePackageName}-${this.imgTitle}-${currentTimeText}${hasCompare ? `-${previousTimeText}` : ''}.jpg`;
    a.click();
    optionsStore.onImgDownloadEnd();
  };

  @action onStopModalChange = (bool) => {
    this.isStopModalOpen = bool;
  };

  @action onStopModalOpen = async () => {
    await this.getBlockedWords();
    this.onStopModalChange(true);
  };

  @action onStopModalClose = () => {
    this.onStopModalChange(false);

    if (this.blockWords.length !== this.cacheBlockWords.length) {
      this.getGraphicData();
    }
  };

  @action onCSVForAllDownload = () => {
    this.isDownload = false;
    if (!this.allCsv) {
      console.log('csv檔不存在');
      return;
    }
    const { currentTimeText, previousTimeText, hasCompare } = infoHeaderViewModel.multiViewModel;
    const wordbook = xlsx.utils.book_new();
    this.allCsv.forEach((el) => {
      const data = [...el.csv];
      const header = el.csvKey.map((key) => key.key);
      const workSheet = xlsx.utils.json_to_sheet(data, { header });
      xlsx.utils.sheet_add_aoa(workSheet, [el.csvKey.map((key) => key.header)], { origin: 'A1' });
      workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
      xlsx.utils.book_append_sheet(wordbook, workSheet, generateWorksheetName(el.name));
    });

    xlsx.writeFile(wordbook, `${packageStore.activePackageName}-${this.allCSVTitle}-${currentTimeText}${hasCompare ? `-${previousTimeText}` : ''}.xlsx`);
  };

  @action getBlockedWords = async () => {
    try {
      const { list } = await BlockedWordsService.getBlockedWords();
      runInAction(() => {
        this.blockWords = list;
        this.cacheBlockWords = list;
        this.isBlockedWordInit = true;
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action onRemoveWord = async (id) => {
    try {
      await BlockedWordsService.removeBlockedWords(id);
      runInAction(() => {
        this.blockWords = this.blockWords.filter((el) => el.id !== id);
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action onDateSelect = async () => {
    this.selectedCategory.onCleanAll();
    await Promise.all([
      this.getGraphicData(),
      this.getSource()
    ]);
  };
}
