import IndustryRadarModel from './industry/IndustryRadar';
import IndustryWordCloud from './industry/IndustryWords';
import IndustryWordDiscoverCloud from './industry/IndustryWordsDiscover';

import BrandRadarModel from './brand/BrandRadar';
import BrandWordCloud from './brand/BrandWords';
import BrandWordDiscoverCloud from './brand/BrandWordsDiscover';

import SeriesRadarModel from './series/SeriesRadar';
import SeriesWordCloud from './series/SeriesWords';
import SeriesWordDiscoverCloud from './series/SeriesWordsDiscover';

import ProductRadarModel from './product/ProductRadar';
import ProductWordCloud from './product/ProductWords';
import ProductWordDiscoverCloud from './product/ProductWordsDiscover';

export default {
  IndustryRadarModel,
  IndustryWordCloud,
  IndustryWordDiscoverCloud,
  BrandRadarModel,
  BrandWordCloud,
  BrandWordDiscoverCloud,
  SeriesRadarModel,
  SeriesWordCloud,
  SeriesWordDiscoverCloud,
  ProductRadarModel,
  ProductWordCloud,
  ProductWordDiscoverCloud
};
