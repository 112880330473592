import { COLOR_SET, WORD_CONFIG } from 'src/consts/chart';
import { generateCSVKey } from 'src/utils/chart';

export default class WordCloud7PModel {
  constructor(data) {
    if (!data.length >= 1) {
      this.data = {
        chartData: [{
          id: 'product_word_radar',
          name: '產品雷達圖',
          data: [{
            labels: [],
            datasets: []
          }],
          type: 'nodata'
        }]
      };
      return;
    }

    const colorSets = {};
    const groupSets = {};

    data.forEach((group, i) => {
      if (group.name === '全部') {
        return;
      }

      group.item.forEach((item) => {
        colorSets[`${item.id}`] = COLOR_SET[(i - 1) % 20];
        groupSets[`${item.id}`] = {
          id: group.id,
          name: group.name
        };
      });
    });

    // TODO: 優化
    const res = data.map((group, i) => {
      const maxNum = Math.max(...group.item.map((item) => item.count));
      const minNum = Math.min(...group.item.map((item) => item.count));

      const getNew = (num) => {
        // FOR ONLY ONE SITUATION
        if (maxNum === minNum) {
          return WORD_CONFIG[size].basicSize * WORD_CONFIG[size].maxRatio;
        }
        return ((num - minNum) * (WORD_CONFIG[size].maxRatio / (maxNum - minNum)) + 1) * WORD_CONFIG[size].basicSize;
      };


      const size = (() => {
        const length = data.length;
        if (length <= 20) {
          return 'small';
        }
        if (length > 20 && length <= 50) {
          return 'medium';
        }
        return 'large';
      })();

      const newItem = group.item.map((el) => ({
        id: el.id,
        name: el.name,
        count: getNew(el.count),
        originCount: el.count,
        color: colorSets[el.id],
        group: groupSets[el.id]
      }));

      return {
        id: group.id.trim() ? group.id : 'all',
        name: group.name === '全部' ? '不分類' : group.name,
        item: newItem
      };
    });

    const csv = [];
    res.forEach((group) => {
      group.item.forEach((item) => {
        csv.push({
          特性分類: item.group.name,
          特性關鍵字: item.name,
          提及則數: item.originCount
        });
      });
    });

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      data: res,
      type: 'words',
      csv,
      csvKey
    };
  }

  static fromRes(data) {
    return new WordCloud7PModel(data);
  }
}
