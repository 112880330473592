import HotWordsModel from './HotWords';
import NewWordsModel from './NewWords';
import TrendWordsModel from './TrendWords';
import HotWordAssocsModel from './HotWordAssocs';
import TopTenBrandModel from './TopTen';

export default {
  HotWordsModel,
  NewWordsModel,
  TrendWordsModel,
  HotWordAssocsModel,
  TopTenBrandModel
};
