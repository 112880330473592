import { COLOR_SET } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import { SOURCE_CATEGORY_TYPE } from 'src/consts';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { generateCSVKey } from 'src/utils/chart';

export default class ProductSourceChannelCompareModel {
  constructor(data, prevData, query) {
    const { formatData, prevFormatData } = ProductSourceChannelCompareModel.generateFormattedData(data, prevData);
    const csv = ProductSourceChannelCompareModel.generateCSV(data, prevData);
    const csvKey = generateCSVKey(csv[0]);
    this.data = {
      chartData: [
        {
          id: 'product_source_channel_compare',
          name: '產品來源頻道堆疊',
          data: [
            ...(formatData.length > 0 && !formatData.every((el) => el.item.every((item) => item.count === 0))
              ? [formatData]
              : [{
                type: 'nodata',
                subType: formatData.length === 0 ? 'ana-error' : 'default'
              }]
            ),
            ...(prevFormatData.length > 0 && !prevFormatData.every((el) => el.item.every((item) => item.count === 0))
              ? [prevFormatData]
              : [
                ...(
                  infoHeaderViewModel.multiViewModel.hasCompare
                    ? [{
                      type: 'nodata',
                      subType: prevFormatData.length === 0 ? 'ana-error' : 'default'
                    }]
                    : []
                )
              ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType,
                category
              } = query;
              const { datasetIndex, index } = elements[0];
              const channel = chart.data.datasets[datasetIndex].item;
              const product = chart.data.datasets[datasetIndex].mainItem[index];
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const [gte, lte] = chart.data.datasets[datasetIndex].time === 'present'
                ? infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'))
                : infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD'));

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category && {
                  category: category.map((c) => ({
                    id: c,
                    name: SOURCE_CATEGORY_TYPE[c]
                  }))
                }),
                channel: [{
                  id: channel.id,
                  name: channel.name
                }],
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/產品/來源分析/產品來源頻道/產品來源頻道比較'
                },
                product: [{
                  id: product.id,
                  name: product.name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          onPointHover: (elements, ref) => {
            const chart = ref.current;
            if (elements.length > 0) {
              const { datasetIndex } = elements[0];
              const hoverTarget = chart.data.datasets[datasetIndex].customName;
              chart.hovered = hoverTarget;
            } else {
              chart.hovered = null;
            }
            chart.update();
          },
          legend: 'topTenInAllGroup',
          type: 'customize-stack-bar',
          options: {
            event: ['click'],
            scales: {
              x: {
                stacked: true
              },
              y: {
                stacked: true,
                grace: 5,
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                display: false
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ]
    };
  }

  static generateFormattedData = (list, prevList) => {
    const colors = {};
    const getData = (rows) => {
      return rows?.slice(0, 20).map((brand) => ({
        ...brand,
        item: brand.item.slice(0, 20).map((item) => {
          const color = colors[item.id];
          if (!color) {
            colors[`${item.id}`] = COLOR_SET[Object.keys(colors).length % 20];
          }
          return {
            id: item.id,
            name: item.name,
            count: item.count,
            color: colors[item.id]
          };
        })
      }));
    };

    return { formatData: getData(list), prevFormatData: getData(prevList) };
  };

  static generateCSV = (list, prevList) => {
    const currTime = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD')).join('~');
    const prevTime = infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD')).join('~');
    const csv = [];
    const currCsv = list.map((el) => {
      const row = {
        date: currTime,
        產品名稱: el.name
      };
      for (let i = 0; i < el.item.length; i += 1) {
        row[`${el.item[i].name}`] = el.item[i].count;
      }
      return row;
    });
    csv.push(...currCsv);
    const prevCsv = prevList.map((el) => {
      const row = {
        date: prevTime,
        產品名稱: el.name
      };
      for (let i = 0; i < el.item.length; i += 1) {
        row[`${el.item[i].name}`] = el.item[i].count;
      }
      return row;
    });

    csv.push(...prevCsv);

    return csv;
  };

  static fromRes(data, prevData, query) {
    return new ProductSourceChannelCompareModel(data, prevData, query);
  }
}
