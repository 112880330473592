import { makeObservable, observable, action, computed, reaction } from 'mobx';

export default class CustomizeSourceSelectViewModel {
  @observable list = [];
  @observable selectedParent = [];

  @observable keyword = '';

  @observable open = false;

  @observable onSelect = () => {};

  @computed get disabled() {
    return this.selectedParent.length === 0;
  }

  @computed get indeterminate() {
    return this.showContent.some((g) => {
      return g.keywordChildren.some((el) => el.checkedCache);
    }) && !this.isAllChecked;
  }

  @computed get isAllChecked() {
    if (this.selected.length === 0) {
      return false;
    }
    return this.showContent.every((g) => {
      return g.keywordChildren.every((el) => el.checkedCache);
    });
  }

  @computed get selected() {
    return this.list.map((el) => el.selectedItem).filter((el) => el.length > 0).flat();
  }

  @computed get searchContent() {
    return this.list.filter((el) => el.keywordChildren.length > 0);
  }

  @computed get showContent() {
    return this.searchContent.filter((el) => this.selectedParent.includes(el.id));
  }

  @computed get selectedChannelsByWebsite() {
    return this.channel.map((el) => {
      return ({
        id: el.id,
        name: el.name,
        count: el.children.filter((c) => c.checked).length
      });
    }).filter((el) => el.count > 0);
  }

  constructor(onSelect) {
    makeObservable(this);

    this.init(onSelect);
  }

  @action init = (onSelect) => {
    this.onSelect = onSelect;
  };

  @action updateList = (list) => {
    this.list = list;
  };

  @action updateSelectedParent = (parent) => {
    this.selectedParent = parent;
  };

  @action onOpenChange = (bool, cb) => {
    if (this.disabled) {
      return;
    }
    this.open = bool;
    if (!bool) {
      cb(this.selected);
    }
  };

  @action onAllSelect = () => {
    if (!this.isAllChecked) {
      this.showContent.forEach((el) => {
        el.children.forEach((w) => {
          w.setSelect(true);
        });
      });
    } else {
      this.showContent.forEach((el) => {
        el.children.forEach((w) => {
          w.setSelect(false);
        });
      });
    }
  };

  @action onCleanAll = () => {
    this.list.forEach((el) => {
      el.children.forEach((w) => {
        w.setSelect(false);
      });
    });
  };

  @action onKeywordChange = (e) => {
    this.keyword = e.target.value;
    if (!e.target.value.trim()) {
      this.onSubmit();
    }
  };

  @action onSubmit = () => {
    this.list.forEach((el) => el.setKeyword(this.keyword));
  };

  @action onKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onSubmit();
    }
  };
}
