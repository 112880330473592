import { makeObservable, observable, action, computed, reaction } from 'mobx';

import CheckItemViewModel from './CheckItem/viewModel';

export default class CategoryPopoverSelectViewModel {
  @observable list = [];
  @observable keyword = '';
  @observable cacheKeyword = '';
  @observable open = false;

  @observable listenTo = '';

  @computed get indeterminate() {
    return this.list.some((el) => el.checkedCache) && !this.isAllChecked;
  }

  @computed get isAllChecked() {
    return this.list.every((el) => el.checkedCache);
  }

  @computed get selected() {
    return this.list.filter((el) => el.checked);
  }

  @computed get searchContent() {
    return this.list.filter((el) => el.name?.toLowerCase().includes(this.cacheKeyword.toLowerCase()));
  }

  constructor(listenTo) {
    makeObservable(this);

    this.init(listenTo);
  }

  @action init = (listenTo = 'main') => {
    this.listenTo = listenTo;
  };

  @action didMount = () => {
    const container = document.querySelector(`.${this.listenTo}`);
    container.addEventListener('scroll', this.onCancel);
  };

  @action willUnmount = () => {
    const container = document.querySelector(`.${this.listenTo}`);
    container.removeEventListener('scroll', this.onCancel);
  };

  @action updateList = (list) => {
    this.list = list.map((el) => new CheckItemViewModel(el));
  };

  @action onOpenChange = (bool) => {
    this.open = bool;

    if (!this.open) {
      this.list.forEach((el) => el.confirmSelect());
      this.keyword = '';
      this.cacheKeyword = '';
    }
  };

  @action onCancel = () => {
    this.open = false;
    this.list.forEach((el) => {
      el.onDisposeSelect();
    });
    this.keyword = '';
    this.cacheKeyword = '';
  };

  @action onAllSelect = () => {
    if (!this.isAllChecked) {
      this.list.forEach((el) => el.setSelect(true));
    } else {
      this.list.forEach((el) => el.setSelect(false));
    }
  };

  @action onCleanAll = () => {
    this.list.forEach((el) => {
      el.setSelect(false);
      el.confirmSelect();
    });
  };

  @action onKeywordChange = (e) => {
    this.keyword = e.target.value;
    if (!e.target.value.trim()) {
      this.onSubmit();
    }
  };

  @action onSubmit = () => {
    this.cacheKeyword = this.keyword;
  };

  @action onKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onSubmit();
    }
  };
}
