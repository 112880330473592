import React from 'react';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import { Button, Switch, Collapse, Table } from 'antd';

import { WORDS_TABLE } from 'src/consts/tables';
import optionsStore from 'src/stores/optionsStore';

import styles from './styles.module.scss';

@observer
class WordCloud extends React.Component {
  _renderPanelIcon = (props) => {
    return (
      <Switch
        defaultChecked={false}
      />
    );
  };

  componentDidMount() {
    this.props.viewModel.didMount();
  }

  componentWillUnmount() {
    this.props.viewModel.willUnmount();
  }

  render() {
    const { viewModel } = this.props;
    return (
      <div className={styles.container}>
        <div id={`wordsContainer${viewModel.timeSec}`} className={styles.wordsContainer} />
        <Collapse
          collapsible="icon"
          expandIcon={this._renderPanelIcon}
          ghost
          className={styles.collapseContainer}
          style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
        >
          <Collapse.Panel
            key="table"
            className="wordsCollapse"
            header={i18n.t('wordbook_page_statistic_table_title')}
          >
            <div className={styles.tableContainer}>
              <Table
                key={viewModel.sortWords.length}
                rowClassName={styles.row}
                rowKey={(item) => item.id}
                dataSource={viewModel.sortWords}
                pagination={false}
                className="packagesTable"
              >
                {
                  WORDS_TABLE.map((el) => {
                    return (
                      <Table.Column
                        title={el.title}
                        dataIndex={el.dataIndex}
                        key={el.key}
                        className={styles.columns}
                        width="33%"
                        render={(_, record, i) => {
                          if (el.dataIndex === 'ranking') {
                            return (
                              <div>
                                {i + 1}
                              </div>
                            );
                          }
                          return (
                            <div>
                              {record[el.dataIndex]}
                            </div>
                          );
                        }}
                      />
                    );
                  })
                }
              </Table>
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  }
}

WordCloud.propTypes = {
  viewModel: PropTypes.object
};

WordCloud.defaultProps = {
  viewModel: {}
};


export default WordCloud;
