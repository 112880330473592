import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { encodeURI } from 'js-base64';
import html2canvas from 'html2canvas';
import { message } from 'antd';
import * as xlsx from 'xlsx';

import { generateWorksheetName } from 'src/utils/chart';
import { getHost, getLevelText } from 'src/utils';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import BlockedWordsService from 'src/services/blockedwords';
import packageStore from 'src/stores/packageStore';
import ChartService from 'src/services/charts';
import ChartFactory from 'src/components/Charts/factory';

import optionsStore from 'src/stores/optionsStore';

export default class ChartItemViewModel {

  parent = {};

  @observable data = [];
  @observable csvForAll = [];
  @observable activeTabId = null;
  @observable isModalOpen = false;

  @observable wordsViewModel = null;

  @observable onReady = false;

  @observable showDiscover = false;
  @observable discoverName = '';
  @observable discoverWords = null;

  @observable isImgDownload = false;
  @observable isDownload = false;
  @observable downloadList = [];
  @observable downloadFileName = '';

  @computed get showData() {
    return this.activeTabId ? this.data.find((el) => el.id === this.activeTabId) : {};
  }

  @computed get showChart() {
    return this.activeTabId ? this.data.find((el) => el.id === this.activeTabId).chart : Array.from({ length: infoHeaderViewModel.multiViewModel.hasCompare ? 2 : 1 }, (v) => {
      return ChartFactory.createChartViewModel({ id: 'nodata', name: getLevelText(this.parent.level), data: { type: 'nodata', subType: 'ana-error' } });
    });
  }

  @computed get notAllowTab() {
    return ['品牌', '系列', '產品'].includes(this.data[0].name);
  }

  @computed get chartTitle() {
    const selectedSubtypeInfo = this.parent.selectedSubtypeInfo;
    const selectedSubChartTypeInfo = this.parent.selectedSubChartTypeInfo;
    if (selectedSubChartTypeInfo.id) {
      if (selectedSubChartTypeInfo.replace) {
        return selectedSubChartTypeInfo.replace.replace('%replace%', `${this.showData?.name ?? getLevelText(this.parent.level)}-`);
      }
      return (selectedSubChartTypeInfo.name);
    }
    if (selectedSubtypeInfo.replace) {
      return selectedSubtypeInfo.replace.replace('%replace%', `${this.showData?.name ?? getLevelText(this.parent.level)}-`);
    }
    return (selectedSubtypeInfo.label);
  }

  @computed get chartTitleForAllCSV() {
    const selectedSubtypeInfo = this.parent.selectedSubtypeInfo;
    const selectedSubChartTypeInfo = this.parent.selectedSubChartTypeInfo;
    if (selectedSubChartTypeInfo.id) {
      if (selectedSubChartTypeInfo.replace) {
        return selectedSubChartTypeInfo.replace.replace('%replace%', `${getLevelText(this.parent.level)}-`);
      }
      return (selectedSubChartTypeInfo.name);
    }
    if (selectedSubtypeInfo.replace) {
      return selectedSubtypeInfo.replace.replace('%replace%', `${getLevelText(this.parent.level)}-`);
    }
    return (selectedSubtypeInfo.label);
  }

  constructor(data, parent, previousItem) {
    makeObservable(this);

    this.init(data, parent, previousItem);
  }

  @action init = (data, parent, previousItem) => {
    this.parent = parent;
    this.data = data.chartData.map((el) => ({
      id: el.id,
      name: el.name,
      chart: el.data.map((d, i) => ChartFactory.createChartViewModel({ ...el, data: d, type: d.type ?? el.type, time: i === 0 ? 'present' : 'previous' }, this))
    }));

    this.csvForAll = data.csvForAll;
    this.csvForAllKey = data.csvForAllKey;

    const previousTabItem = this.data.find((el) => el.id === previousItem);

    if (previousTabItem) {
      this.activeTabId = previousItem;
    } else {
      this.activeTabId = this.data[0].id;
    }

    this.onReady = true;
  };

  @action onTabsChange = (key) => {
    this.activeTabId = key;
  };

  @action onModalOpen = (viewModel) => {
    this.wordsViewModel = viewModel;

    this.isModalOpen = true;
  };

  @action onModalClose = () => {
    this.isModalOpen = false;
    this.wordsViewModel.onModalClose();

    this.wordsViewModel = null;
  };

  @action onWordBlock = async (word) => {
    try {
      await BlockedWordsService.addBlockWords(word);
      runInAction(() => {
        this.parent.getGraphicData();
        this.parent.getBlockWords();

        this.onModalClose();
      });
    } catch (error) {
      if (error.response?.status === 409) {
        message.error('該詞已在屏蔽名單中!');
        return;
      }
      message.error('無法新增屏蔽詞，請稍候再試');
    }
  };

  @action onTopicsClick = () => {
    const wordContent = this.wordsViewModel.selectedWordContentByName;
    const json = JSON.stringify(wordContent.linkData);
    const result = encodeURI(json);
    const url = `${getHost()}/topics-result?result=${result}`;
    window.open(url, '_blank');

    this.onModalClose();
  };

  @action onDiscoverClick = async () => {
    this.discoverName = this.wordsViewModel.selectedName;
    this.onModalClose();
    optionsStore.setLoading('discover');
    const params = {
      date: {
        gte: infoHeaderViewModel.multiViewModel.currentDate[0].format('YYYY-MM-DD'),
        lte: infoHeaderViewModel.multiViewModel.currentDate[1].format('YYYY-MM-DD')
      },
      term: this.discoverName,
      metric: this.parent.selectedIndex,
      size: this.parent.selectedCount,
      ...(this.parent.blockWords.length > 0 && { disableTerm: this.parent.blockWords.map((el) => el.name) }),
      ...(this.parent.selectedPostType !== 'all' && { postType: this.parent.selectedPostType }),
      ...(this.parent.selectedWOMType !== 'all' && { womType: this.parent.selectedWOMType }),
      ...(this.parent.showCategorySelect && this.parent.sourceCategoryPopover.selected.length >= 1 && {
        category: this.parent.sourceCategoryPopover.selected.map((el) => el.id)
      })
    };

    try {
      const { data } = await ChartService.getIndustryHotWordsDiscover(params);
      runInAction(() => {
        this.discoverWords = ChartFactory.createChartViewModel({ ...data.chartData[0], data: data.chartData[0].data[0] }, this);

        this.showDiscover = true;
      });

    } catch (error) {
      console.log(error);
    } finally {
      optionsStore.setCompleted('discover');
    }
  };

  @action onDiscoverLeave = () => {
    this.discoverWords = null;
    this.showDiscover = false;
  };

  @action onCSVForAllDownload = () => {
    this.isDownload = false;
    if (!this.csvForAll) {
      message.error('聲量數據不存在');
      return;
    }

    const { currentTimeText, previousTimeText, autoPreviousTimeText, hasCompare } = infoHeaderViewModel.multiViewModel;
    const wordbook = xlsx.utils.book_new();
    this.csvForAll.forEach((el) => {
      const data = [...el.csv];
      const header = el.csvKey.map((key) => key.key);
      const workSheet = xlsx.utils.json_to_sheet(data, { header });
      xlsx.utils.sheet_add_aoa(workSheet, [el.csvKey.map((key) => key.header)], { origin: 'A1' });
      workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
      xlsx.utils.book_append_sheet(wordbook, workSheet, generateWorksheetName(el.name));
    });

    xlsx.writeFile(wordbook, `${packageStore.activePackageName}-${this.chartTitleForAllCSV}-${currentTimeText}${hasCompare ? `-${(this.parent.isTrendWord || this.parent.isNewWord) ? autoPreviousTimeText : previousTimeText}` : ''}.xlsx`);
  };

  @action onDownloadExcel = () => {
    this.isDownload = false;
    const { csv, csvKey } = this.showDiscover ? this.discoverWords : this.showChart[0];
    if (!csv || !csvKey) {
      console.log('csv檔不存在');
      return;
    }

    const { currentTimeText, previousTimeText, autoPreviousTimeText, hasCompare } = infoHeaderViewModel.multiViewModel;

    const data = [...csv];
    const header = csvKey.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [csvKey.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    const wordbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wordbook, workSheet, '圖表分析');
    xlsx.writeFile(wordbook, `${packageStore.activePackageName}-${this.showDiscover ? `「${this.discoverName}」文字雲下探` : this.chartTitle}-${currentTimeText}${hasCompare ? `-${(this.parent.isTrendWord || this.parent.isNewWord) ? autoPreviousTimeText : previousTimeText}` : ''}.xlsx`);
  };

  @action onDownload = async () => {
    await optionsStore.onImgDownload();
    const chart = document.querySelector('.download-chart');
    const canvas = await html2canvas(chart);
    const a = document.createElement('a');
    const { currentTimeText, previousTimeText, autoPreviousTimeText, hasCompare } = infoHeaderViewModel.multiViewModel;
    a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
    a.download = `${packageStore.activePackageName}-${this.showDiscover ? `「${this.discoverName}」文字雲下探` : this.chartTitle}-${currentTimeText}${hasCompare ? `-${(this.parent.isTrendWord || this.parent.isNewWord) ? autoPreviousTimeText : previousTimeText}` : ''}.jpg`;
    a.click();
    optionsStore.onImgDownloadEnd();
  };
}
