import BrandVolumeTrendViewModel from './BrandVolumeTrend/viewModel';
import BrandVolumeRankingViewModel from './BrandVolumeRanking/viewModel';
import BrandVolumeProportionViewModel from './BrandVolumeProportion/viewModel';
import IndustrySourceCategoryProportionViewModel from './IndustrySourceCategoryProportion/viewModel';
import BrandSourceCategoryProportionViewModel from './BrandSourceCategoryProportion/viewModel';
import IndustrySourceChannelRankingViewModel from './IndustrySourceChannelRanking/viewModel';
import BrandSourceChannelRankingViewModel from './BrandSourceChannelRanking/viewModel';
import SeriesVolumeProportionViewModel from './SeriesVolumeProportion/viewModel';
import TopsSeriesProductVolumeRankingViewModel from './TopsSeriesProductVolumeRanking/viewModel';
import BrandSentimentOverviewViewModel from './BrandSentimentOverview/viewModel';
import BrandConsumerFeatureViewModel from './BrandConsumerFeature/viewModel';
import IndustryTopsFeatureWordViewModel from './IndustryTopsFeatureWord/viewModel';
import BrandTopsFeatureWordViewModel from './BrandTopsFeatureWord/viewModel';

import BrandVolumeTrend from './BrandVolumeTrend';
import BrandVolumeRanking from './BrandVolumeRanking';
import BrandVolumeProportion from './BrandVolumeProportion';
import IndustrySourceCategoryProportion from './IndustrySourceCategoryProportion';
import BrandSourceCategoryProportion from './BrandSourceCategoryProportion';
import IndustrySourceChannelRanking from './IndustrySourceChannelRanking';
import BrandSourceChannelRanking from './BrandSourceChannelRanking';
import SeriesVolumeProportion from './SeriesVolumeProportion';
import TopsSeriesProductVolumeRanking from './TopsSeriesProductVolumeRanking';
import BrandSentimentOverview from './BrandSentimentOverview';
import BrandConsumerFeature from './BrandConsumerFeature';
import IndustryTopsFeatureWord from './IndustryTopsFeatureWord';
import BrandTopsFeatureWord from './BrandTopsFeatureWord';

export default class BrandRoutineFactory {
  static createChartViewModel(id, parent) {
    switch (id) {
      case 'brand_volume_trend':
        return new BrandVolumeTrendViewModel(parent);
      case 'brand_volume_ranking':
        return new BrandVolumeRankingViewModel(parent);
      case 'brand_volume_proportion':
        return new BrandVolumeProportionViewModel(parent);
      case 'industry_source_category_proportion':
        return new IndustrySourceCategoryProportionViewModel(parent);
      case 'brand_source_category_proportion':
        return new BrandSourceCategoryProportionViewModel(parent);
      case 'industry_source_channel_ranking':
        return new IndustrySourceChannelRankingViewModel(parent);
      case 'brand_source_channel_ranking':
        return new BrandSourceChannelRankingViewModel(parent);
      case 'series_volume_proportion':
        return new SeriesVolumeProportionViewModel(parent);
      case 'top1_series_product_volume_ranking':
        return new TopsSeriesProductVolumeRankingViewModel(parent, 'top1');
      case 'top2_series_product_volume_ranking':
        return new TopsSeriesProductVolumeRankingViewModel(parent, 'top2');
      case 'top3_series_product_volume_ranking':
        return new TopsSeriesProductVolumeRankingViewModel(parent, 'top3');
      case 'brand_sentiment_overview':
        return new BrandSentimentOverviewViewModel(parent);
      case 'brand_consumer_feature':
        return new BrandConsumerFeatureViewModel(parent);
      case 'industry_top1_feature_word':
        return new IndustryTopsFeatureWordViewModel(parent, 'top1');
      case 'main_brand_top1_feature_word':
        return new BrandTopsFeatureWordViewModel(parent, 'top1');
      case 'industry_top2_feature_word':
        return new IndustryTopsFeatureWordViewModel(parent, 'top2');
      case 'main_brand_top2_feature_word':
        return new BrandTopsFeatureWordViewModel(parent, 'top2');
      case 'industry_top3_feature_word':
        return new IndustryTopsFeatureWordViewModel(parent, 'top3');
      case 'main_brand_top3_feature_word':
        return new BrandTopsFeatureWordViewModel(parent, 'top3');
      default:
        return null;
    }
  }

  static createChartView(item) {
    const { id, viewModel } = item;

    switch (id) {
      case 'brand_volume_trend':
        return (<BrandVolumeTrend key={id} viewModel={viewModel} />);
      case 'brand_volume_ranking':
        return (<BrandVolumeRanking key={id} viewModel={viewModel} />);
      case 'brand_volume_proportion':
        return (<BrandVolumeProportion key={id} viewModel={viewModel} />);
      case 'industry_source_category_proportion':
        return (<IndustrySourceCategoryProportion key={id} viewModel={viewModel} />);
      case 'brand_source_category_proportion':
        return (<BrandSourceCategoryProportion key={id} viewModel={viewModel} />);
      case 'industry_source_channel_ranking':
        return (<IndustrySourceChannelRanking key={id} viewModel={viewModel} />);
      case 'brand_source_channel_ranking':
        return (<BrandSourceChannelRanking key={id} viewModel={viewModel} />);
      case 'series_volume_proportion':
        return (<SeriesVolumeProportion key={id} viewModel={viewModel} />);
      case 'top1_series_product_volume_ranking':
      case 'top2_series_product_volume_ranking':
      case 'top3_series_product_volume_ranking':
        return (<TopsSeriesProductVolumeRanking key={id} viewModel={viewModel} />);
      case 'brand_sentiment_overview':
        return (<BrandSentimentOverview key={id} viewModel={viewModel} />);
      case 'brand_consumer_feature':
        return (<BrandConsumerFeature key={id} viewModel={viewModel} />);
      case 'industry_top1_feature_word':
      case 'industry_top2_feature_word':
      case 'industry_top3_feature_word':
        return (<IndustryTopsFeatureWord key={id} viewModel={viewModel} />);
      case 'main_brand_top1_feature_word':
      case 'main_brand_top2_feature_word':
      case 'main_brand_top3_feature_word':
        return (<BrandTopsFeatureWord key={id} viewModel={viewModel} />);
      default:
        return null;
    }
  }
}
