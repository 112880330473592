import React from 'react';
import * as d3 from 'd3';
import { makeObservable, observable, action, computed, runInAction, reaction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { encodeURI } from 'js-base64';
import { getHost } from 'src/utils';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';


export default class WordAssocsViewModel {
  type = 'word-assocs';
  key = uuidv4();
  @observable name = '';
  @observable csv = [];
  @observable csvKey = [];
  @observable timeSec = '';

  containerRef = React.createRef();

  @observable assocs = {};
  @observable origin = [];

  @observable isShowChart = false;

  @observable selectedMainWord = null;
  @observable selectedWord = null;

  @observable canDraw = false;
  @observable translate = { x: 0, y: 0 };
  @observable scale = 1;
  @observable containerHeight = 0;
  @observable centered = false;
  @observable linkData = {};

  @observable isModalOpen = false;
  @observable targetToggleAction = null;
  @observable targetName = '';
  @observable targetScore = '';

  @observable isSettingTippy = false;

  @observable once = null;

  @computed get mainWordList() {
    return this.origin.map((el) => el.name);
  }

  constructor(content) {
    makeObservable(this);

    this.init(content);
  }

  // > for test function
  random = (maxLength) => {
    return Math.min((Math.random() * 10).toFixed(), maxLength);
  };

  @action centerTree = () => {
    if (this.centered) {
      return;
    }
    const { width: containerWidth } = this.containerRef.current.getBoundingClientRect();
    const targetElementClassName = this.containerRef.current?.childNodes?.[0]?.childNodes?.[1]?.childNodes?.[0]?.classList?.[1];
    const gElement = d3.select(`g.${targetElementClassName}`);
    const { width, height, x, y } = gElement.node().getBBox();
    const adjustWidth = (() => {
      let w = width;
      if (w < 400) {
        w += 400 * 2;
        return w;
      }
      if (w >= 400 && w < 800) {
        w += 400;
        return w;
      }
      return w;
    })();

    this.scale = Math.floor((((containerWidth * 0.9) / adjustWidth) * 100)) / 100;
    this.containerHeight = Math.ceil(height * Math.ceil(this.scale * 10)) / 10;
    this.translate = { x: (Math.abs(x) * this.scale * 1.1), y: (Math.abs(y) * this.scale) + 4 };

    this.centered = true;
    tippy('[data-tippy-content]');

  };

  @action onClick = () => {
    this.centered = false;
  };

  @action init = (content) => {
    const { data, name, csv, linkData, time, csvKey } = content;
    this.origin = data;
    this.csv = csv;
    this.csvKey = csvKey;
    this.name = name;
    this.selectedMainWord = this.origin[0].name;
    this.linkData = linkData;
    this.timeSec = time;

    this.updateList(this.origin[0]);
  };

  @action didMount = () => {
    const once = reaction(
      () => this.selectedMainWord,
      (word) => {
        const target = this.origin.find((el) => el.name === word);
        this.selectedWord = null;
        if (target) {
          this.updateList(target);
        }
      }
    );

    this.once = once;
  };

  @action updateList = (data) => {
    this.assocs = data;
    this.centered = false;
    this.isShowChart = true;
  };

  @action onMainWordChange = (e) => {
    this.selectedMainWord = e;
    this.isShowChart = false;
  };

  @action onFirstClick = (toggleFunc, name, score) => {
    this.targetToggleAction = toggleFunc;
    this.targetName = name;
    this.targetScore = score;

    this.isModalOpen = true;
  };

  @action onTopicModalTopicClick = () => {
    this.onTopic(this.targetName, this.targetScore);
    this.isModalOpen = false;
  };

  @action onModalToggleClick = () => {
    this.targetToggleAction();
    this.targetToggleAction = null;
    this.centered = false;
    this.isModalOpen = false;
  };

  @action onTopic = (name, score) => {
    const [gte, lte] = this.timeSec === 'present'
      ? infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'))
      : infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD'));
    const topicsResult = {
      ...this.linkData,
      term: {
        name,
        score,
        important: score
      },
      date: {
        gte,
        lte
      }
    };

    const json = JSON.stringify(topicsResult);
    const result = encodeURI(json);
    const url = `${getHost()}/topics-result?result=${result}`;
    window.open(url, '_blank');
  };

  @action onClose = () => {
    this.isModalOpen = false;
  };
}
