import React from 'react';
import { makeObservable, observable, action, computed } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

export default class HorizontalBarChartViewModel {
  type = 'horizontal-bar';
  ref = React.createRef();
  @observable name = '';
  @observable csv = [];
  @observable csvKey = [];
  @observable key = '';
  @observable options = {};
  @observable data = {
    labels: [],
    datasets: []
  };
  @observable plugins = [];

  constructor(data) {
    makeObservable(this);

    this.init(data);
  }

  @action init = (content) => {
    const {
      id,
      name,
      data,
      onPointClick,
      onPointHover,
      options,
      plugins,
      csv,
      csvKey
    } = content;

    this.key = uuidv4();
    this.name = name;
    this.data = data;
    this.plugins = plugins;
    this.options = {
      ...options,
      plugins: {
        ...options.plugins,
        legend: {
          ...options.plugins.legend,
          labels: {
            ...options.plugins.legend?.labels,
            color: '#000'
          }
        }
      },
      scales: {
        ...options.scales,
        x: {
          ...options.scales.x,
          title: {
            ...options.scales.x?.title,
            color: '#000'
          },
          ticks: {
            ...options.scales.x?.ticks,
            color: '#000'
          }
        },
        y: {
          ...options.scales.y,
          title: {
            ...options.scales.y?.title,
            color: '#000'
          },
          ticks: {
            ...options.scales.y?.ticks,
            color: '#000'
          }
        }
      },
      maintainAspectRatio: false,
      onClick: (_, elements) => onPointClick(elements, this.ref.current),
      ...(onPointHover ? {
        onHover: (event, elements) => {
          onPointHover(elements, this.ref);
          const target = event.native.target;
          if (elements.length > 0) {
            target.style.cursor = 'pointer';
          } else {
            target.style.cursor = 'default';
          }
        }
      } : {
        onHover: (event, elements) => {
          const target = event.native.target;
          if (elements.length > 0) {
            target.style.cursor = 'pointer';
          } else {
            target.style.cursor = 'default';
          }
        }
      })
    };
    this.csv = csv;
    this.csvKey = csvKey;
  };
}

