export default class SeriesModel {
  constructor(data) {
    this.parents = data.filter((el) => !el.parentId).map((el) => ({
      value: el._id,
      label: el.name
    }));

    this.series = this.parents.map((el) => ({
      id: el.value,
      name: el.label,
      children: []
    }));

    // TODO: 優化
    data.filter((el) => el.parentId).forEach((el) => {
      const parent = this.series.find((p) => p.id === el.parentId);
      if (parent) {
        parent.children.push({ id: el._id, name: el.name, checked: false });
      }
    });
  }

  static fromRes(data) {
    return new SeriesModel(data);
  }
}
