import { COLOR_SET } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import { generateCSVKey } from 'src/utils/chart';

export default class SeriesVolumeRankingModel {
  constructor(data, prevData, query) {
    if (data.length === 0 && prevData.length === 0) {
      this.data = {
        chartData: [{
          id: '',
          name: '',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }]
        }]
      };
      return;
    }
    const total = (() => {
      let sum = 0;
      data.forEach((el) => {
        sum += el.count;
      });
      return sum;
    })();

    const prevTotal = (() => {
      let sum = 0;
      prevData.forEach((el) => {
        sum += el.count;
      });
      return sum;
    })();

    if (!total && !prevTotal) {
      this.data = {
        chartData: [{
          id: '',
          name: '',
          data: [{
            type: 'nodata',
            subType: 'default'
          }]
        }]
      };
      return;
    }

    const combine = data.map((el) => ({
      ...el,
      prevCount: 0
    }));

    prevData.forEach((el) => {
      const target = combine.find((item) => item.id === el.id);
      if (target) {
        target.prevCount = el.count;
      }
      // else {
      //   combine.push({
      //     id: el.id,
      //     name: el.name,
      //     count: 0,
      //     prevCount: el.count
      //   });
      // }
    });

    combine.sort((a, b) => b.count - a.count);

    const { currentDate, previousDate } = infoHeaderViewModel.multiViewModel;

    const prototype = [
      {
        date: `${currentDate[0].format('YYYY-MM-DD')} ~ ${currentDate[1].format('YYYY-MM-DD')}`
      },
      ...(
        prevTotal
          ? [{
            date: `${previousDate[0].format('YYYY-MM-DD')} ~ ${previousDate[1].format('YYYY-MM-DD')}`
          }]
          : [])
    ];

    const csv = prototype.map((el, di) => {
      const row = el;
      for (let i = 0; i < combine.length; i += 1) {
        if (di === 0) {
          row[`${combine[i].name}`] = combine[i].count;
        } else {
          row[`${combine[i].name}`] = combine[i].prevCount;
        }
      }

      return row;
    });

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [{
        id: 'series_volume_ranking',
        name: '系列聲量排行',
        data: [{
          labels: combine.slice(0, 10).map((el) => el.name),
          datasets: [
            ...(
              total
                ? [{
                  label: `${currentDate[0].format('YYYY-MM-DD')} ~ ${currentDate[1].format('YYYY-MM-DD')}`,
                  seriesId: combine.slice(0, 10).map((el) => el.id),
                  data: combine.slice(0, 10).map((el) => el.count),
                  backgroundColor: COLOR_SET[0],
                  borderSkipped: true,
                  categoryPercentage: 0.2
                }]
                : []
            ),
            ...(
              prevTotal
                ? [{
                  label: `${previousDate[0].format('YYYY-MM-DD')} ~ ${previousDate[1].format('YYYY-MM-DD')}`,
                  seriesId: combine.slice(0, 10).map((el) => el.id),
                  data: combine.slice(0, 10).map((el) => el.prevCount),
                  backgroundColor: COLOR_SET[19],
                  borderSkipped: true,
                  categoryPercentage: 0.2
                }]
                : []
            )
          ]
        }],
        onPointClick: (elements, chart, params) => {
          if (elements.length > 0) {
            const {
              postType,
              womType,
              category,
              tagKey
            } = query;
            const { datasetIndex, index } = elements[0];
            const seriesId = chart.data.datasets[datasetIndex].seriesId[index];
            const seriesName = chart.data.labels[index];
            const womCount = chart.data.datasets[datasetIndex].data[index];
            const [gte, lte] = chart.data.datasets[datasetIndex].label.split('~');

            const topicsResult = {
              ...(womType && {
                womType
              }),
              ...(postType && {
                postType
              }),
              ...(category ? {
                category: category.map((el) => ({
                  id: el,
                  name: SOURCE_CATEGORY_TYPE[el]
                }))
              } : {
                category: SOURCE_CATEGORY.map((el) => ({
                  id: el.value,
                  name: el.label
                }))
              }),
              packageContent: {
                id: packageStore.activePackageId,
                name: packageStore.activePackageName
              },
              chart: {
                name: '圖表分析/系列/聲量分析/系列聲量/系列聲量排行'
              },
              series: [{
                id: seriesId,
                name: seriesName,
                tagKey,
                tagKeyName: packageStore.series.find((el) => el.id === tagKey).name
              }],
              womCount,
              date: {
                gte,
                lte
              }
            };

            const json = JSON.stringify(topicsResult);
            const result = encodeURI(json);
            const url = `${getHost()}/topics-result?result=${result}`;
            window.open(url, '_blank');
          }
        },
        type: 'bar',
        options: {
          event: ['click'],
          scales: {
            y: {
              title: {
                display: true,
                text: '聲量'
              }
            }
          },
          plugins: {
            legend: {
              position: 'bottom'
            },
            datalabels: {
              display: false
            }
          }
        },
        csv,
        csvKey
      }]
    };
  }

  static fromRes(data, prevData, query) {
    return new SeriesVolumeRankingModel(data, prevData, query);
  }
}
