import IndustrySourceCategoryProportionModel from './CategoryProportion';
import IndustrySourceCategoryRankingModel from './CategoryRanking';
import IndustrySourceCategoryTrendModel from './CategoryTrend';
import IndustrySourceWebsiteTrendModel from './WebsiteTrend';
import IndustrySourceWebsiteRankingModel from './WebsiteRanking';
import IndustrySourceWebsiteProportionModel from './WebsiteProportion';
import IndustrySourceChannelTrendModel from './ChannelTrend';
import IndustrySourceChannelRankingModel from './ChannelRanking';
import IndustrySourceChannelProportionModel from './ChannelProportion';
import IndustrySourceSocialActivityModel from './SocialActivity';

export default {
  IndustrySourceCategoryProportionModel,
  IndustrySourceCategoryRankingModel,
  IndustrySourceCategoryTrendModel,
  IndustrySourceWebsiteProportionModel,
  IndustrySourceWebsiteRankingModel,
  IndustrySourceWebsiteTrendModel,
  IndustrySourceChannelProportionModel,
  IndustrySourceChannelRankingModel,
  IndustrySourceChannelTrendModel,
  IndustrySourceSocialActivityModel
};
