import { makeObservable, observable, action, computed } from 'mobx';
import { encodeURI } from 'js-base64';
import { message } from 'antd';
import * as xlsx from 'xlsx';
import html2canvas from 'html2canvas';

import optionsStore from 'src/stores/optionsStore';
import { getHost } from 'src/utils';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY, SOURCE_ORDER_TC } from 'src/consts';
import { CATEGORY_COLOR_SET_WITH_BLOG } from 'src/consts/chart';

export default class CategoryViewModel {
  @observable originData = null;
  @observable chartData = null;
  @observable searchData = null;
  @observable options = null;

  @computed get excelTitle() {
    return [
      { key: 'category', header: '來源類型' },
      { key: 'volume', header: '聲量' }
    ];
  }

  @computed get excelData() {
    const excel = [];
    if (!this.originData) {
      return excel;
    }

    for (let i = 0; i < this.originData.length; i += 1) {
      const item = this.originData[i];
      excel.push({
        category: SOURCE_CATEGORY_TYPE[item.name],
        volume: item.count
      });
    }

    return excel;
  }

  constructor() {
    makeObservable(this);
  }

  @action update = (data, searchData) => {
    this.updateContent(data, searchData);
    this.updateOptions(searchData);
  };

  @action updateContent = (data, searchData) => {
    if (!data || data.length === 0) {
      return;
    }

    this.originData = data;

    let total = 0;
    const labels = [];
    const datas = [];
    const category = [];
    const backgroundColor = [];

    for (let i = 0; i < data.length; i += 1) {
      const item = data[i];
      labels.push(SOURCE_CATEGORY_TYPE[item.name]);
      datas.push(item.count);
      total += item.count;
      category.push(item.name);
      backgroundColor.push(CATEGORY_COLOR_SET_WITH_BLOG[item.name]);
    }

    this.chartData = {
      labels,
      datasets: [{
        label: searchData.keyword,
        data: datas,
        category,
        backgroundColor,
        datalabels: {
          color: '#fff',
          formatter: (value) => {
            const percentage = ((value / total) * 100).toFixed();
            return percentage > 5 ? `${percentage}%` : null;
          }
        },
        tooltip: {
          callbacks: {
            label: (context) => {
              const percentage = ((context.parsed / total) * 100).toFixed();
              return ` ${context.parsed}: [${percentage}%]`;
            }
          }
        }
      }]
    };
  };

  @action updateOptions = (searchData) => {
    this.searchData = searchData;
    const onPointClick = (elements, chart) => {
      if (elements.length === 0) {
        return;
      }
      const { datasetIndex, index } = elements[0];
      const womCount = chart.data.datasets[datasetIndex].data[index];
      const category = chart.data.datasets[datasetIndex].category[index];

      const { keyword, postType, womType, date: { gte, lte } } = searchData;

      const archiveData = {
        keyword,
        ...(womType && {
          womType
        }),
        ...(postType && {
          postType
        }),
        category: [{ id: category, name: SOURCE_CATEGORY_TYPE[category] }],
        chart: {
          name: '輿情搜尋/摘要圖表/來源類型佔比'
        },
        date: {
          gte,
          lte: lte ?? gte
        },
        womCount
      };

      console.log('archiveData', archiveData);

      const json = JSON.stringify(archiveData);
      const result = encodeURI(json);
      const url = `${getHost()}/opinion-topics?result=${result}`;

      window.open(url, '_blank');
    };

    this.options = {
      responsive: true,
      event: ['click'],
      onHover: (event, elements) => {
        const native = event.native;
        if (!native) {
          return;
        }
        const target = native.target;
        if (!target) {
          return;
        }
        if (elements.length > 0) {
          target.style.cursor = 'pointer';
        } else {
          target.style.cursor = 'default';
        }
      },
      onClick: (ev, elements, chart) => onPointClick(elements, chart),
      plugins: {
        legend: {
          position: 'right',
          labels: {
            color: '#000',
            sort: (a, b) => {
              const indexA = SOURCE_ORDER_TC.indexOf(a.text);
              const indexB = SOURCE_ORDER_TC.indexOf(b.text);
              return indexA - indexB;
            }
          }
        }
      }
    };
  };

  @action onDownloadExcel = () => {
    this.isDownload = false;
    if (!this.excelTitle || !this.excelData) {
      message.error('csv檔不存在');
      return;
    }
    const data = [...this.excelData];
    const header = this.excelTitle.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [this.excelTitle.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    const wordbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wordbook, workSheet, '來源類型佔比');
    xlsx.writeFile(wordbook, `輿情搜尋-${this.searchData.keyword}-來源類型佔比-${this.searchData.date.gte}-${this.searchData.date.lte}.xlsx`);
  };

  @action appendSheet = (wordbook) => {
    this.isDownload = false;
    if (!this.excelTitle || !this.excelData) {
      message.error('csv檔不存在');
      return;
    }
    const data = [...this.excelData];
    const header = this.excelTitle.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [this.excelTitle.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    xlsx.utils.book_append_sheet(wordbook, workSheet, '來源類型佔比');
  };

  @action onDownload = async () => {
    await optionsStore.onImgDownload();
    const chart = document.querySelector('.opinion-category-proportion');
    const canvas = await html2canvas(chart);
    const a = document.createElement('a');
    a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
    a.download = `輿情搜尋-${this.searchData.keyword}-來源類型佔比-${this.searchData.date.gte}-${this.searchData.date.lte}.jpg`;
    a.click();
    optionsStore.onImgDownloadEnd();
  };
}
