import { COLOR_SET } from 'src/consts/chart';
import packageStore from 'src/stores/packageStore';
import { getHost } from 'src/utils';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { generateCSVKey, htmlLegendPlugin } from 'src/utils/chart';

export default class Radar7PModel {
  constructor(origin, query) {
    if (!origin.length >= 1) {
      this.data = {
        chartData: [{
          id: 'product_word_radar',
          name: '產品雷達圖',
          data: [{
            labels: [],
            datasets: []
          }],
          type: 'nodata'
        }]
      };
      return;
    }

    const {
      postType,
      womType,
      category
    } = query;

    const features = [];

    origin.forEach((el) => {
      el.item.forEach((item) => {
        const target = features.find((feature) => feature.id === item.id);
        if (!target) {
          features.push({ id: item.id, name: item.name });
        }
      });
    });

    // TODO: 優化
    const data = origin.map((el) => {
      const row = {
        id: el.id,
        name: el.name,
        item: []
      };
      features.forEach((feature) => {
        const target = el.item.find((item) => item.id === feature.id);
        row.item.push({ id: feature.id, name: feature.name, count: target?.count ?? 0 });
      });

      return row;
    });

    // TODO: 優化
    const csv = data.map((el) => {
      const row = {
        名稱: el.name
      };
      features.forEach((feature) => {
        const target = el.item.find((item) => item.id === feature.id);
        row[`${feature.name}`] = target?.count ?? 0;
      });

      return row;
    });

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'product_word_radar',
          name: '產品雷達圖',
          data: [{
            labels: features.map((el) => el.name),
            datasets: data.map((el, i) => ({
              label: el.name,
              product: { id: el.id, name: el.name },
              features,
              data: el.item.map((f) => f.count),
              borderColor: COLOR_SET[i % 20],
              tooltip: {
                callbacks: {
                  label: (context) => {
                    const { formattedValue, parsed, dataset } = context;
                    const { r } = parsed;
                    if (r === 0) {
                      return '';
                    }
                    return `${dataset.label}: ${formattedValue}`;

                  }
                }
              }
            }))
          }],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const feature = chart.data.datasets[datasetIndex].features[index];
              const product = chart.data.datasets[datasetIndex].product;
              const [gte, lte] = infoHeaderViewModel.multiViewModel.currentDate;
              const womCount = chart.data.datasets[datasetIndex].data[index];

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category ? {
                  category: category.map((el) => ({
                    id: el,
                    name: SOURCE_CATEGORY_TYPE[el]
                  }))
                } : {
                  category: SOURCE_CATEGORY.map((el) => ({
                    id: el.value,
                    name: el.label
                  }))
                }),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '進階分析/行銷7P分析/雷達圖'
                },
                featureCategory: {
                  id: feature.id,
                  name: feature.name
                },
                product: [{
                  id: product.id,
                  name: product.name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'radar',
          options: {
            responsive: true,
            event: ['click'],
            scales: {
              r: {
                angleLines: {
                  display: false
                },
                ticks: {
                  display: false
                }
              }
            },
            plugins: {
              legend: {
                display: false
              },
              datalabels: {
                display: false
              }
            }
          },
          plugins: [htmlLegendPlugin],
          csv,
          csvKey
        }
      ]
    };
  }

  static fromRes(data, query) {
    return new Radar7PModel(data, query);
  }
}
