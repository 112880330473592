import { SENTIMENT_COLOR_SET, AVG_LINE_COLOR } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY } from 'src/consts';

export default class BrandSentimentOverviewModel {
  constructor(origin, query) {
    if (origin.length === 0) {
      this.data = {
        chartData: [{
          id: 'brand_sentiment_overview',
          name: '品牌情緒總覽',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }]
        }]
      };
      return;
    }

    const data = origin;

    if (data.every((el) => el['1'] + el['0'] + el['-1'] === 0)) {
      this.data = {
        chartData: [{
          id: 'brand_sentiment_overview',
          name: '品牌情緒總覽',
          data: [{
            type: 'nodata'
          }]
        }]
      };
      return;
    }

    const csv = data.map((el) => ({
      name: el.name,
      positive: el['1'],
      neutral: el['0'],
      negative: el['-1'],
      pn: el.pn
    }));

    const csvKey = [
      { key: 'name', header: '名稱' },
      { key: 'positive', header: '偏正面' },
      { key: 'neutral', header: '偏中性' },
      { key: 'negative', header: '偏負面' },
      { key: 'pn', header: 'P/N' }
    ];

    this.data = {
      chartData: [
        {
          id: 'brand_sentiment_overview',
          name: '品牌情緒總覽',
          data: [{
            labels: data.slice(0, 10).map((el) => el.name),
            datasets: [
              {
                label: 'p/n',
                data: data.slice(0, 10).map((el) => el.pn),
                backgroundColor: AVG_LINE_COLOR,
                borderColor: AVG_LINE_COLOR,
                type: 'line',
                yAxisID: 'y1'
              },
              {
                label: '正面',
                data: data.slice(0, 10).map((el) => el['1']),
                brand: data.slice(0, 10).map((el) => ({ id: el.id, name: el.name })),
                sentiment: '1',
                backgroundColor: SENTIMENT_COLOR_SET.positive,
                borderSkipped: true,
                maxBarThickness: 50,
                yAxisID: 'y'
              },
              {
                label: '中性',
                data: data.slice(0, 10).map((el) => el['0']),
                brand: data.slice(0, 10).map((el) => ({ id: el.id, name: el.name })),
                sentiment: '0',
                backgroundColor: SENTIMENT_COLOR_SET.neutral,
                borderSkipped: true,
                maxBarThickness: 50,
                yAxisID: 'y'
              },
              {
                label: '負面',
                data: data.slice(0, 10).map((el) => el['-1']),
                brand: data.slice(0, 10).map((el) => ({ id: el.id, name: el.name })),
                sentiment: '-1',
                backgroundColor: SENTIMENT_COLOR_SET.negative,
                borderSkipped: true,
                maxBarThickness: 50,
                yAxisID: 'y'
              }
            ]
          }],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType
              } = query;
              const { datasetIndex, index } = elements[0];
              const sentiment = chart.data.datasets[datasetIndex].sentiment;
              if (!sentiment) {
                return;
              }
              const brand = chart.data.datasets[datasetIndex].brand[index];
              const [gte, lte] = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'));
              const womCount = chart.data.datasets[datasetIndex].data[index];

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                category: SOURCE_CATEGORY.map((el) => ({
                  id: el.value,
                  name: el.label
                })),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                sentiment: [Number(sentiment)],
                chart: {
                  name: '數據報告/行業趨勢觀察/品牌情緒總覽'
                },
                brand: [{
                  id: brand.id,
                  name: brand.name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'bar',
          options: {
            event: ['click'],
            tension: 0.3,
            scales: {
              x: {
                stacked: true
              },
              y: {
                stacked: true,
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                  display: true,
                  text: '聲量'
                }
              },
              y1: {
                type: 'linear',
                display: true,
                position: 'right',
                title: {
                  display: true,
                  text: 'PN值'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ],
      brand: data.map((el) => ({ id: el.id, name: el.name }))
    };
  }

  static fromRes(data, query) {
    return new BrandSentimentOverviewModel(data, query);
  }
}
