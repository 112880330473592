import { COLOR_SET } from 'src/consts/chart';
import { generateCompletedDayArray, getTotalDays, generateCSVKey } from 'src/utils/chart';
import { getHost } from 'src/utils';
import { SOURCE_CATEGORY_TYPE } from 'src/consts';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';

export default class BrandSourceChannelTrendModel {
  constructor(origin, prevOrigin, query) {
    const data = BrandSourceChannelTrendModel.generateData(origin);
    const previousData = BrandSourceChannelTrendModel.generateData(prevOrigin, 'previous');
    const brandList = BrandSourceChannelTrendModel.generateList(data, previousData);
    const colors = BrandSourceChannelTrendModel.generateColor(data, previousData);
    const csvForAll = [];
    const csvForAllMap = new Map();

    // > FOR NO DATA
    if (!data && !previousData) {
      this.data = {
        chartData: [{
          id: 'brand_source_category_ranking',
          name: '品牌',
          data: [{
            labels: [],
            datasets: [],
            type: 'nodata',
            subType: 'ana-error'
          },
          ...(
            infoHeaderViewModel.multiViewModel.hasCompare
              ? [{
                labels: [],
                datasets: [],
                type: 'nodata',
                subType: 'ana-error'
              }]
              : []
          )]
        }]
      };
      return;
    }

    const dataMap = new Map();
    const previousDataMap = new Map();

    if (data) {
      for (let i = 0; i < data.length; i += 1) {
        dataMap.set(data[i].id, data[i]);
      }
    }

    if (previousData) {
      for (let i = 0; i < previousData.length; i += 1) {
        previousDataMap.set(previousData[i].id, previousData[i]);
      }
    }

    for (let i = 0; i < brandList.length; i += 1) {
      const targetInCurrent = dataMap.get(brandList[i].id);
      const targetInPrev = previousDataMap.get(brandList[i].id);
      const csv = [];
      BrandSourceChannelTrendModel.pushCSV(targetInCurrent, csv);
      BrandSourceChannelTrendModel.pushCSV(targetInPrev, csv);
      const csvKey = generateCSVKey(csv[0]);
      csvForAll.push({
        id: brandList[i].id,
        name: brandList[i].name,
        csv,
        csvKey
      });
      csvForAllMap.set(brandList[i].id, { csv, csvKey });
    }

    this.data = {
      chartData: brandList.slice(0, 10).map((brand) => {
        const targetInCurrent = dataMap.get(brand.id);
        const currentEmpty = targetInCurrent ? BrandSourceChannelTrendModel.emptyCheck(targetInCurrent) : true;
        const targetInPrev = previousDataMap.get(brand.id);
        const prevEmpty = targetInPrev ? BrandSourceChannelTrendModel.emptyCheck(targetInPrev) : true;
        const { csv, csvKey } = csvForAllMap.get(brand.id) ?? { csv: [], csvKey: [] };

        return {
          id: brand.id,
          name: brand.name,
          data: [
            ...(
              !currentEmpty
                ? [{
                  labels: targetInCurrent.item[0].series.map((d) => d.date),
                  datasets: targetInCurrent.item.map((c, i) => ({
                    label: c.name,
                    accurate: targetInCurrent.item[0].series.map((d) => d.accurate ?? d.date),
                    channelId: c.id,
                    data: c.series.map((s) => s.count),
                    borderColor: colors[c.id]
                  }))
                }]
                : [{
                  type: 'nodata',
                  subType: targetInCurrent ? 'default' : 'ana-error'
                }]
            ),
            ...(
              !prevEmpty
                ? [{
                  labels: targetInPrev.item[0].series.map((d) => d.date),
                  datasets: targetInPrev.item.map((c, i) => ({
                    label: c.name,
                    accurate: targetInPrev.item[0].series.map((d) => d.accurate ?? d.date),
                    channelId: c.id,
                    data: c.series.map((s) => s.count),
                    borderColor: colors[c.id]
                  }))
                }]
                : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{
                        type: 'nodata',
                        subType: targetInPrev ? 'default' : 'ana-error'
                      }]
                      : []
                  )
                ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType,
                category
              } = query;
              const { datasetIndex, index } = elements[0];
              const [gte, lte] = chart.data.datasets[datasetIndex].accurate[index].split('~');
              const channelId = chart.data.datasets[datasetIndex].channelId;
              const channelName = chart.data.datasets[datasetIndex].label;
              const womCount = chart.data.datasets[datasetIndex].data[index];

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category && {
                  category: category.map((c) => ({
                    id: c,
                    name: SOURCE_CATEGORY_TYPE[c]
                  }))
                }),
                channel: [{
                  id: channelId,
                  name: channelName
                }],
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/品牌/來源分析/品牌來源頻道/品牌來源頻道趨勢'
                },
                brand: [{
                  id: brand.id,
                  name: brand.name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'line',
          options: {
            responsive: true,
            event: ['click'],
            tension: 0.3,
            scales: {
              x: {
                title: {
                  display: true,
                  text: '日期'
                }
              },
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        };
      }),
      csvForAll
    };
  }

  static generateData = (list, time = 'present') => {
    if (list.length === 0) {
      return null;
    }
    const totalDays = getTotalDays(time);
    return list.map((ori) => {
      const items = ori.item.slice(0, 20).map((item) => {
        if (totalDays >= 32 && totalDays <= 90) {
          const dayArr = generateCompletedDayArray(time);
          item.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...item,
            series: dayArr.map((el) => ({
              date: el.id,
              count: el.count
            }))
          };
        }

        if (totalDays >= 91) {
          const dayArr = generateCompletedDayArray(time);
          item.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...item,
            series: dayArr.map((el) => ({
              date: el.id,
              accurate: `${el.days[0]}~${el.days[el.days.length - 1]}`,
              count: el.count
            }))
          };
        }

        const dayArr = generateCompletedDayArray(time);
        return {
          ...item,
          series: dayArr.map((d) => ({
            date: d,
            count: item.series.find((s) => s.date === d)?.count ?? 0
          }))
        };
      });
      return {
        ...ori,
        item: items
      };
    });
  };

  static generateColor = (list, prevList) => {
    const colors = {};

    list?.forEach((el) => {
      el.item.forEach((item) => {
        const target = colors[item.id];
        if (!target) {
          colors[`${item.id}`] = COLOR_SET[Object.keys(colors).length % 20];
        }
      });
    });

    prevList?.forEach((el) => {
      el.item.forEach((item) => {
        const target = colors[item.id];
        if (!target) {
          colors[`${item.id}`] = COLOR_SET[Object.keys(colors).length % 20];
        }
      });
    });

    return colors;
  };

  static generateList = (list) => {
    const brandList = [];
    list?.forEach((el) => {
      const target = brandList.find((brand) => brand.id === el.id);
      if (!target) {
        brandList.push({ id: el.id, name: el.name });
      }
    });

    return brandList;
  };

  static pushCSV = (data, csv) => {
    if (data) {
      const rows = data.item[0]?.series?.map((d) => {
        const row = {
          日期: d.date
        };
        for (let i = 0; i < data.item.length; i += 1) {
          row[`${data.item[i].name}`] = data.item[i].series.find((s) => s.date === d.date).count;
        }
        return row;
      }) ?? [];
      csv.push(...rows);
    }
  };

  static emptyCheck = (data) => {
    return data.item.every((el) => el.series.every((date) => date.count === 0));
  };

  static fromRes(data, prevData, query) {
    return new BrandSourceChannelTrendModel(data, prevData, query);
  }
}
