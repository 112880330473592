import React from 'react';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import clsx from 'clsx';

import { Checkbox } from 'antd';

import styles from './styles.module.scss';


class CheckItem extends React.Component {
  render() {
    const { viewModel, afterBrandSelect } = this.props;
    return (
      // > https://github.com/mobxjs/mobx-react/issues/484
      <Observer>
        {() => (
          <div className={styles.levelItem}>
            <Checkbox
              checked={viewModel.checkedCache}
              onChange={(e) => viewModel.toggleChecked(e, afterBrandSelect)}
            />
            <div className={styles.levelName}>
              {viewModel.name}
            </div>
          </div>
        )}
      </Observer>
    );
  }
}

CheckItem.propTypes = {
  viewModel: PropTypes.object,
  afterBrandSelect: PropTypes.func
};

CheckItem.defaultProps = {
  viewModel: {},
  afterBrandSelect: () => { }
};

export default CheckItem;

