import API from 'src/apis';
import dataAnalysis from 'src/models/response/charts/dataAnalysis';
import packageStore from 'src/stores/packageStore';

export default class BrandRoutineService {

  static getPackageId = () => {
    return packageStore.activePackageId;
  };

  static async getBrandVolumeTrend(data) {
    const res = await API.dataReport.brandRoutine.getBrandVolumeTrend(BrandRoutineService.getPackageId(), data);

    return dataAnalysis.BrandRoutine.BrandVolumeTrendModel.fromRes(res.data.result, data);
  }

  static async getBrandVolumeRanking(data) {
    const res = await API.dataReport.brandRoutine.getBrandVolumeCount(BrandRoutineService.getPackageId(), data);

    return dataAnalysis.BrandRoutine.BrandVolumeRankingModel.fromRes(res.data.result, data);
  }

  static async getBrandVolumeProportion(data) {
    const res = await API.dataReport.brandRoutine.getBrandVolumeCount(BrandRoutineService.getPackageId(), data);

    return dataAnalysis.BrandRoutine.BrandVolumeProportionModel.fromRes(res.data.result, data);
  }

  static async getIndustrySourceCategoryProportion(data) {
    const res = await API.dataReport.brandRoutine.getIndustrySourceCategoryCount(BrandRoutineService.getPackageId(), data);

    return dataAnalysis.BrandRoutine.IndustrySourceCategoryProportionModel.fromRes(res.data.result, data);
  }

  static async getMainBrandSourceCategoryProportion(data, brand) {
    const res = await API.dataReport.brandRoutine.getMainBrandSourceCategoryCount(BrandRoutineService.getPackageId(), data);

    return dataAnalysis.BrandRoutine.BrandSourceCategoryProportionModel.fromRes(res.data.result, { ...data, ...brand });
  }

  static async getIndustrySourceChannelRanking(data) {
    const res = await API.dataReport.brandRoutine.getIndustrySourceChannelCount(BrandRoutineService.getPackageId(), data);

    return dataAnalysis.BrandRoutine.IndustrySourceChannelRankingModel.fromRes(res.data.result, data);
  }

  static async getMainBrandSourceChannelRanking(data, brand) {
    const res = await API.dataReport.brandRoutine.getMainBrandSourceChannelCount(BrandRoutineService.getPackageId(), data);

    return dataAnalysis.BrandRoutine.BrandSourceChannelRankingModel.fromRes(res.data.result, { ...data, ...brand });
  }

  static async getSeriesVolumeProportion(data) {
    const res = await API.dataReport.brandRoutine.getSeriesVolumeCount(BrandRoutineService.getPackageId(), data);

    return dataAnalysis.BrandRoutine.SeriesVolumeProportionModel.fromRes(res.data.result, data);
  }

  static async getTopsSeriesProductVolumeRanking(data) {
    const res = await API.dataReport.brandRoutine.getTopsSeriesProductVolumeCount(BrandRoutineService.getPackageId(), data);

    return dataAnalysis.BrandRoutine.TopsSeriesProductVolumeRankingModel.fromRes(res.data.result, data);
  }

  static async getBrandSentimentOverview(data) {
    const res = await API.dataReport.brandRoutine.getBrandSentimentOverview(BrandRoutineService.getPackageId(), data);

    return dataAnalysis.BrandRoutine.BrandSentimentOverviewModel.fromRes(res.data.result, data);
  }

  static async getBrandConsumerFeature(data, brand) {
    const res = await API.dataReport.brandRoutine.getBrandConsumerFeature(BrandRoutineService.getPackageId(), data);

    return dataAnalysis.BrandRoutine.BrandConsumerFeatureModel.fromRes(res.data.result, { ...data, ...brand });
  }

  static async getIndustryTopsFeatureWord(data) {
    const res = await API.dataReport.brandRoutine.getIndustryTopsFeatureWord(BrandRoutineService.getPackageId(), data);

    return dataAnalysis.BrandRoutine.IndustryTopsFeatureWordModel.fromRes(res.data.result, data);
  }

  static async getBrandTopsFeatureWord(data) {
    const res = await API.dataReport.brandRoutine.getBrandTopsFeatureWord(BrandRoutineService.getPackageId(), data);

    return dataAnalysis.BrandRoutine.BrandTopsFeatureWordModel.fromRes(res.data.result, data);
  }

}
