import API from 'src/apis';
import dataAnalysis from 'src/models/response/charts/dataAnalysis';
import packageStore from 'src/stores/packageStore';

export default class MarketDifferentiationService {

  static getPackageId = () => {
    return packageStore.activePackageId;
  };

  static async getSeriesVolumeTrend(params) {
    const res = await API.dataReport.marketDifferentiation.getSeriesVolumeTrend(MarketDifferentiationService.getPackageId(), params);

    return dataAnalysis.MarketDifferentiation.SeriesVolumeTrendModel.fromRes(res.data.result, params);
  }

  static async getSeriesVolumeProportion(params) {
    const res = await API.dataReport.marketDifferentiation.getSeriesVolumeProportion(MarketDifferentiationService.getPackageId(), params);

    return dataAnalysis.MarketDifferentiation.SeriesVolumeProportionModel.fromRes(res.data.result, params);
  }

  static async getSeriesBrandVolumeCompare(params) {
    const res = await API.dataReport.marketDifferentiation.getSeriesBrandVolumeCompare(MarketDifferentiationService.getPackageId(), params);

    return dataAnalysis.MarketDifferentiation.SeriesBrandVolumeCompareModel.fromRes(res.data.result, params);
  }

  static async getSeriesProductVolumeCompare(params) {
    const res = await API.dataReport.marketDifferentiation.getSeriesProductVolumeCompare(MarketDifferentiationService.getPackageId(), params);

    return dataAnalysis.MarketDifferentiation.SeriesProductVolumeCompareModel.fromRes(res.data.result, params);
  }

  static async getSeriesSourceCategoryCompare(params) {
    const res = await API.dataReport.marketDifferentiation.getSeriesSourceCategoryCompare(MarketDifferentiationService.getPackageId(), params);

    return dataAnalysis.MarketDifferentiation.SeriesSourceCategoryCompareModel.fromRes(res.data.result, params);
  }

  static async getTopsSeriesBrandVolumeProportion(params) {
    const res = await API.dataReport.marketDifferentiation.getTopsSeriesBrandVolumeProportion(MarketDifferentiationService.getPackageId(), params);

    return dataAnalysis.MarketDifferentiation.TopsSeriesBrandVolumeProportionModel.fromRes(res.data.result, params);
  }

  static async getTopsSeriesProductVolumeRanking(params) {
    const res = await API.dataReport.marketDifferentiation.getTopsSeriesProductVolumeRanking(MarketDifferentiationService.getPackageId(), params);

    return dataAnalysis.MarketDifferentiation.TopsSeriesProductVolumeRankingModel.fromRes(res.data.result, params);
  }

  static async getTopsSeriesWord(params) {
    const res = await API.dataReport.marketDifferentiation.getTopsSeriesWord(MarketDifferentiationService.getPackageId(), params);

    return dataAnalysis.MarketDifferentiation.TopsSeriesWord.fromRes(res.data.result, params);
  }

}
