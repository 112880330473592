import API from 'src/apis';
import SeriesModel from 'src/models/response/Series';
import BrandModel from 'src/models/response/Brand';
import ProductModel from 'src/models/response/Product';
import FeatureSetModel from 'src/models/response/FeatureSet';
import CustomerListModel from 'src/models/response/CustomerList';
import PackageListModel from 'src/models/response/PackageList';
import PackageInfoModel from 'src/models/response/PackageInfo';
import PackageThreeLevelModel from 'src/models/response/PackageThreeLevel';
import FeatureSetContentModel from 'src/models/response/FeatureSetContent';
import PackageLogsModel from 'src/models/response/PackageLogs';

export default class PackageService {
  static async getProjects(params) {
    const res = await API.packages.getProjects(params);

    return res.data.result;
  }

  static async getProjectsById(projectId) {
    const res = await API.packages.getProjectsById(projectId);

    return res.data.result;
  }

  static async getSeries(projectId) {
    const res = await API.packages.getSeries(projectId);

    return SeriesModel.fromRes(res.data.result);
  }

  static async getBrand(projectId, data) {
    const res = await API.packages.getBrand(projectId, data);

    return { brandList: res.data.result.keywords.map((el) => BrandModel.fromRes(el)), anchor: res.data.result.anchor };
  }

  static async getProduct(projectId, data) {
    const res = await API.packages.getProduct(projectId, data);

    return { productList: res.data.result.keywords.map((el) => ProductModel.fromRes(el)), anchor: res.data.result.anchor };
  }

  static async getAnalysisLog(pid) {
    const res = await API.packages.getAnalysisLog(pid);

    return PackageLogsModel.fromRes(res.data.result);
  }

  static async getFeatureSet(id) {
    const res = await API.packages.getFeatureSet(id);

    return FeatureSetModel.fromRes(res.data.result);
  }

  static async getMyCustomers() {
    const res = await API.packages.getMyCustomers();

    return CustomerListModel.fromRes(res.data.result);
  }

  static async createPackage(data) {
    const res = await API.packages.createPackage(data);

    return res;
  }

  static async updatePackage(id, data) {
    const res = await API.packages.updatePackage(id, data);

    return res;
  }

  static async getPackages(params) {
    const res = await API.packages.getPackages(params);

    return PackageListModel.fromRes(res.data);
  }

  static async getAnalysisLogs(pids) {
    const res = await API.packages.getAnalysisLog(pids);

    return PackageLogsModel.fromRes(res.data.result);
  }

  static async pinPackage(id) {
    await API.packages.pinPackage(id);
  }

  static async deletePackage(id) {
    await API.packages.deletePackage(id);
  }

  static async getPackageInfo(id) {
    const res = await API.packages.getPackageInfo(id);

    return PackageInfoModel.fromRes(res.data.result);
  }

  static async getDeletedPackages(params) {
    const res = await API.packages.getDeletedPackages(params);

    return PackageListModel.fromRes({ list: res.data.result.dataPackages, anchor: res.data.result.anchor });
  }

  static async getDeletedPackageInfo(id) {
    const res = await API.packages.getDeletedPackageInfo(id);

    return PackageInfoModel.fromRes(res.data.result);
  }

  static async getPackageLevelsContent(id) {
    const [brand, series, product] = await Promise.all([
      API.packages.getPackageBrand(id),
      API.packages.getPackageSeries(id),
      API.packages.getPackageProduct(id)
    ]);

    return PackageThreeLevelModel.fromRes({ brand, series, product });
  }

  static async getFeatureSetContent(projectId, featureSetId) {
    const res = await API.packages.getFeatureSetContent(projectId, featureSetId);

    return FeatureSetContentModel.fromRes(res.data.result);
  }

  static async onReAnalysis(pid, date) {
    await API.packages.onReAnalysis(pid, date);
  }
}
