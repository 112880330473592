import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';



import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import styles from './styles.module.scss';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  ChartDataLabels
);



@observer
class PieChart extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <div className={styles.container}>
        <div className={clsx(
          styles.pieContainer
          // viewModel.legendContainerId === 'legend-container' && styles.normal
        )}
        >
          <Doughnut
            key={viewModel.key}
            options={viewModel.options}
            data={viewModel.data}
            ref={viewModel.ref}
            plugins={viewModel.plugins}
          />
        </div>
        {/* <div id={viewModel.legendContainerId} /> */}
      </div>
    );
  }
}

PieChart.propTypes = {
  viewModel: PropTypes.object
};

PieChart.defaultProps = {
  viewModel: {}
};


export default PieChart;
