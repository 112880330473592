import { COLOR_SET } from 'src/consts/chart';
import { generateCompletedDayArray, getTotalDays, generateCSVKey } from 'src/utils/chart';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY } from 'src/consts';

export default class BrandVolumeTrendModel {
  constructor(origin, query) {
    if (origin.length === 0) {
      this.data = {
        chartData: [{
          id: 'brand_source_channel_compare',
          name: '系列品牌聲量堆疊',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }]
        }]
      };
      return;
    }

    const {
      postType,
      womType
    } = query;

    const data = (() => {
      const totalDays = getTotalDays();
      return origin.map((ori) => {
        if (totalDays >= 32 && totalDays <= 90) {
          const dayArr = generateCompletedDayArray();
          ori.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...ori,
            series: dayArr.map((el) => ({
              date: el.id,
              count: el.count
            }))
          };
        }

        if (totalDays >= 91) {
          const dayArr = generateCompletedDayArray();
          ori.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...ori,
            series: dayArr.map((el) => ({
              date: el.id,
              accurate: `${el.days[0]}~${el.days[el.days.length - 1]}`,
              count: el.count
            }))
          };
        }

        const dayArr = generateCompletedDayArray();
        return {
          ...ori,
          series: dayArr.map((d) => ({
            date: d,
            count: ori.series.find((s) => s.date === d)?.count ?? 0
          }))
        };
      });
    })();

    if (data.every((el) => el.series.every((date) => date.count === 0))) {
      this.data = {
        chartData: [{
          id: 'brand_volume_trend',
          name: '品牌聲量趨勢',
          data: [{
            type: 'nodata'
          }]
        }]
      };
      return;
    }

    const csv = data[0].series.map((el) => {
      const row = {
        date: el.date
      };
      for (let i = 0; i < data.length; i += 1) {
        row[`${data[i].name}`] = data[i].series.find((s) => s.date === el.date).count;
      }

      return row;
    });

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'brand_volume_trend',
          name: '品牌聲量趨勢',
          data: [{
            labels: data[0].series.map((el) => el.date),
            datasets: data.slice(0, 10).map((el, i) => ({
              label: el.name,
              brandId: el.id,
              accurate: data[0].series.map((d) => d.accurate ?? d.date),
              data: el.series.map((s) => s.count),
              borderColor: COLOR_SET[i % 20]
            }))
          }],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const [gte, lte] = chart.data.datasets[datasetIndex].accurate[index].split('~');
              const brandId = chart.data.datasets[datasetIndex].brandId;
              const brandName = chart.data.datasets[datasetIndex].label;
              const womCount = chart.data.datasets[datasetIndex].data[index];

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                category: SOURCE_CATEGORY.map((el) => ({
                  id: el.value,
                  name: el.label
                })),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '數據報告/品牌競爭分析/品牌聲量趨勢'
                },
                brand: [{
                  id: brandId,
                  name: brandName
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'line',
          options: {
            responsive: true,
            event: ['click'],
            tension: 0.3,
            scales: {
              x: {
                title: {
                  display: true,
                  text: '日期'
                }
              },
              y: {
                beginAtZero: true,
                grace: 5,
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ],
      brand: data.map((el) => ({ id: el.id, name: el.name }))
    };
  }

  static fromRes(data, query) {
    return new BrandVolumeTrendModel(data, query);
  }
}
