import { COLOR_SET } from 'src/consts/chart';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { generateCompletedDayArray, getTotalDays, generateCSVKey } from 'src/utils/chart';
import { SOURCE_CATEGORY } from 'src/consts';

export default class BrandVolumeTrendModel {
  constructor(origin, query) {
    if (origin.length === 0) {
      this.data = {
        chartData: [{
          id: 'brand_volume_trend',
          name: '品牌聲量趨勢',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }]
        }]
      };
      return;
    }

    if (origin.every((el) => el.count === 0)) {
      this.data = {
        chartData: [{
          id: 'brand_volume_trend',
          name: '品牌聲量趨勢',
          data: [{
            type: 'nodata'
          }]
        }]
      };
      return;
    }

    const data = (() => {
      const totalDays = getTotalDays();
      if (totalDays >= 32 && totalDays <= 90) {
        const dayArr = generateCompletedDayArray();
        origin.forEach((el) => {
          const target = dayArr.find((d) => d.days.includes(el.date));
          if (target) {
            target.count += el.count;
          }
        });
        return dayArr.map((el) => ({
          date: el.id,
          count: el.count
        }));
      }

      if (totalDays >= 91) {
        const dayArr = generateCompletedDayArray();
        origin.forEach((el) => {
          const target = dayArr.find((d) => d.days.includes(el.date));
          if (target) {
            target.count += el.count;
          }
        });
        return dayArr.map((el) => ({
          date: el.id,
          accurate: `${el.days[0]}~${el.days[el.days.length - 1]}`,
          count: el.count
        }));
      }

      const dayArr = generateCompletedDayArray();
      return dayArr.map((el) => ({
        date: el,
        count: origin.find((s) => s.date === el)?.count ?? 0
      }));
    })();

    const {
      id,
      name,
      womType,
      postType
    } = query;

    const csv = data.map((el) => ({
      date: el.date,
      聲量: el.count
    }));

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'brand_volume_trend',
          name: '品牌聲量趨勢',
          data: [{
            labels: data.map((el) => el.date),
            datasets: [
              {
                label: name,
                data: data.map((el) => el.count),
                accurate: data.map((el) => el.accurate ?? el.date),
                borderColor: COLOR_SET[0]
              }
            ]
          }],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const [gte, lte] = chart.data.datasets[datasetIndex].accurate[index].split('~');
              const womCount = chart.data.datasets[datasetIndex].data[index];

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                category: SOURCE_CATEGORY.map((el) => ({
                  id: el.value,
                  name: el.label
                })),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '數據報告/品牌健檢分析/品牌聲量趨勢'
                },
                brand: [{
                  id,
                  name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'line',
          options: {
            responsive: true,
            event: ['click'],
            tension: 0.3,
            scales: {
              x: {
                title: {
                  display: true,
                  text: '日期'
                }
              },
              y: {
                beginAtZero: true,
                grace: 5,
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ]
    };
  }

  static fromRes(data, query) {
    return new BrandVolumeTrendModel(data, query);
  }
}
