import { makeObservable, observable, action, computed } from 'mobx';

export default class CheckItemViewModel {
  parent = {};
  @observable id = '';
  @observable name = '';
  @observable checked = false;
  @observable checkedCache = false;

  constructor(data, parent) {
    makeObservable(this);

    this.init(data, parent);
  }

  @action init = (data, parent) => {
    const {
      id,
      name,
      checked
    } = data;

    this.id = id;
    this.name = name;
    this.checked = checked;
    this.checkedCache = checked;

    this.parent = parent ?? {};
  };

  @action toggleChecked = (e, afterSelect, item) => {
    this.checkedCache = e.target.checked;
    afterSelect(item ?? { id: this.id, name: this.name }, this.checkedCache);

  };

  @action setSelect = (bool) => {
    this.checkedCache = bool;
  };

  @action confirmSelect = () => {
    this.checked = this.checkedCache;
  };

  @action onDisposeSelect = () => {
    this.checkedCache = this.checked;
  };

  @action setInit = () => {
    this.checked = true;
    this.checkedCache = true;
  };
}
