import { makeObservable, observable, action, computed, runInAction, reaction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import html2canvas from 'html2canvas';
import { message } from 'antd';
import { encodeURI } from 'js-base64';
import * as xlsx from 'xlsx';

import BrandHealthService from 'src/services/charts/DataAnalysis/brandHealth';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import ChartFactory from 'src/components/Charts/factory';
import { getHost } from 'src/utils';
import { generateWorksheetName } from 'src/utils/chart';
import packageStore from 'src/stores/packageStore';
import optionsStore from 'src/stores/optionsStore';
import NoDataChartViewModel from 'src/components/Charts/NoData/viewModel';
import FeatureWordCloudViewModel from './Words/viewModel';

export default class BrandTopsFeatureWordViewModel {
  parent = {};
  @observable key = uuidv4();
  @observable chart = {};
  @observable tops = '';
  @observable feature = {};
  @observable csv = [];
  @observable csvKey = [];

  @observable isLoading = true;
  @observable selectedWOM = 'all';

  @observable isInit = false;
  @observable isReady = false;

  @computed get chartName() {
    return this.feature ? `主品牌(${this.parent.selectedBrandName})${this.tops.toUpperCase()}分類(${this.feature?.name ?? '無資料'})文字雲` : null;
  }

  @computed get chartId() {
    return this.feature ? `brand_${this.tops}_feature_word` : null;
  }

  constructor(parent, tops) {
    makeObservable(this);

    this.deserialize(parent, tops);
  }

  @action deserialize = (parent, tops) => {
    this.parent = parent;
    this.tops = tops;
  };

  @action getFeature = () => {
    this.feature = (() => {
      switch (this.tops) {
        case 'top1':
          return this.parent.topFeatures[0];
        case 'top2':
          return this.parent.topFeatures[1];
        case 'top3':
          return this.parent.topFeatures[2];
        default:
          return null;
      }
    })();
  };

  @action init = async () => {
    this.feature = {};
    if (this.parent.topFeatures) {
      this.getFeature();
    } else {
      const featureOnce = reaction(
        () => this.parent.topFeatures,
        (array) => {
          if (array) {
            this.getFeature();
            featureOnce();
          }
        }
      );
    }

    if (!!this.feature?.id && this.parent.initBlockedWords) {
      await this.getChart();
      runInAction(() => {
        this.isInit = true;
      });
    } else {
      const once = reaction(
        () => !!this.feature?.id && this.parent.initBlockedWords,
        async (boolean) => {
          if (boolean) {
            await this.getChart();
            runInAction(() => {
              this.isInit = true;
              once();
            });
          }
        }
      );
    }
  };

  @action onWOMChange = async (e) => {
    this.selectedWOM = e;

    await this.getChart();
  };

  @action getChart = async () => {
    try {
      this.isReady = false;
      const params = {
        ...(this.parent.postType !== 'all' && { postType: this.parent.postType }),
        ...(this.selectedWOM !== 'all' && { womType: this.selectedWOM }),
        ...(this.parent.blockedWords.length > 0 && { disableTerm: this.parent.blockedWords.map((el) => el.name) }),
        main: this.parent.selectedBrand,
        featureCategory: this.feature.id,
        date: {
          gte: infoHeaderViewModel.multiViewModel.currentDate[0].format('YYYY-MM-DD'),
          lte: infoHeaderViewModel.multiViewModel.currentDate[1].format('YYYY-MM-DD')
        }
      };

      const res = await BrandHealthService.getBrandTopsFeatureWord(params, this.parent.selectedBrandItem);
      runInAction(() => {
        if (res.data.chartData[0].type !== 'nodata') {
          this.chart = new FeatureWordCloudViewModel({ ...res.data.chartData[0], data: res.data.chartData[0].data[0], id: this.tops }, this);
          this.csv = res.data.chartData[0].csv;
          this.csvKey = res.data.chartData[0].csvKey;
        } else {
          this.chart = ChartFactory.createChartViewModel({ ...res.data.chartData[0], data: res.data.chartData[0].data[0] });
        }

        this.key = uuidv4();
        this.isReady = true;
      });
    } catch (error) {
      this.chart = new NoDataChartViewModel({ data: { type: 'nodata', subType: 'ana-error' } });
      this.isReady = true;
    }
  };

  @action onDownload = async () => {
    if (!this.feature) {
      return;
    }
    try {
      await optionsStore.onImgDownload();
      const chart = document.querySelector(`#${this.chartId}`);
      const canvas = await html2canvas(chart);
      const a = document.createElement('a');
      a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
      a.download = `${packageStore.activePackageName}-${this.chartName}-${infoHeaderViewModel.multiViewModel.currentTimeText}.jpg`;
      a.click();
    } catch (error) {
      //
    } finally {
      optionsStore.onImgDownloadEnd();
    }

  };

  @action onAllDownload = async () => {
    if (!this.feature) {
      return;
    }
    const chart = document.querySelector(`#${this.chartId}`);
    const canvas = await html2canvas(chart);
    const a = document.createElement('a');
    a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
    a.download = `${packageStore.activePackageName}-${this.chartName}-${infoHeaderViewModel.multiViewModel.currentTimeText}.jpg`;
    a.click();
  };

  @action onModalOpen = (word) => {
    const { name, linkData } = word;

    const json = JSON.stringify({
      ...linkData,
      featureCategory: {
        id: this.feature.id,
        name: this.feature.name
      }
    });
    const result = encodeURI(json);
    const url = `${getHost()}/topics-result?result=${result}`;
    this.parent.onModalOpen({ name, url });
  };

  @action onDownloadExcel = () => {
    this.isDownload = false;
    if (!this.csv || !this.csvKey) {
      message.error('csv檔不存在');
      return;
    }

    const data = [...this.csv];
    const header = this.csvKey.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [this.csvKey.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    const wordbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wordbook, workSheet, generateWorksheetName(this.chartName));
    xlsx.writeFile(wordbook, `${packageStore.activePackageName}-${this.chartName}-${infoHeaderViewModel.multiViewModel.currentTimeText}.xlsx`);
  };

  @action onAllExcelDownload = (book) => {
    this.isDownload = false;
    if (!this.feature) {
      return;
    }
    if (!this.csv || !this.csvKey) {
      return;
    }

    const data = [...this.csv];
    const header = this.csvKey.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [this.csvKey.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    xlsx.utils.book_append_sheet(book, workSheet, generateWorksheetName(this.chartName));
  };
}
