import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Tooltip } from 'antd';

import i18n from 'src/i18n';
import SentimentSwitchArrowIcon from 'src/assets/SentimentSwitchArrow.svg';

import SentimentItemViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class SentimentItem extends React.Component {
  constructor(props) {
    super();

    this.viewModel = new SentimentItemViewModel(props.sentiment);
  }
  render() {
    const { prefix, onSentimentChange, canChangeSentiment } = this.props;
    return (
      <div className={clsx(styles.postInfo, canChangeSentiment && styles.allow)}>
        <Tooltip title={canChangeSentiment ? i18n.t('common_sentiment_tooltip') : null}>
          <div
            className={clsx(styles.postTypeContainer)}
            onClick={() => this.viewModel.onSentimentClick(onSentimentChange, canChangeSentiment)}
          >
            <div
              className={clsx(
                styles.postTypeCard,
                this.viewModel.sentiment === 'positive' && styles.positive,
                this.viewModel.sentiment === 'negative' && styles.negative,
                this.viewModel.sentiment === 'neutral' && styles.neutral
              )}
            >
              {`${prefix}： ${this.viewModel.sentimentText}`}
            </div>
            {
              canChangeSentiment
              && (
                <div
                  className={clsx(
                    styles.nextTypeCard,
                    this.viewModel.sentiment === 'positive' && styles.neutral,
                    this.viewModel.sentiment === 'negative' && styles.positive,
                    this.viewModel.sentiment === 'neutral' && styles.negative,
                    this.viewModel.isAnimation && styles.animation,
                    !this.viewModel.inProcess && styles.notProcess
                  )}
                >
                  <div className={styles.nextTypeCardText}>
                    {`${prefix}： ${this.viewModel.nextSentimentText}`}
                  </div>
                  <img
                    src={SentimentSwitchArrowIcon}
                    alt=""
                    className={clsx(
                      styles.arrow,
                      this.viewModel.sentiment === 'positive' && styles.neutral,
                      this.viewModel.sentiment === 'negative' && styles.positive,
                      this.viewModel.sentiment === 'neutral' && styles.negative,
                      this.viewModel.isAnimation && styles.animation
                    )}
                  />
                </div>
              )
            }
          </div>
        </Tooltip>
        {
          this.viewModel.inProcess && (
            <div className={styles.postMask} />
          )
        }
      </div>
    );
  }
}

SentimentItem.propTypes = {
  prefix: PropTypes.string,
  sentiment: PropTypes.string,
  onSentimentChange: PropTypes.func,
  canChangeSentiment: PropTypes.bool
};

SentimentItem.defaultProps = {
  prefix: '情緒',
  sentiment: 'positive',
  onSentimentChange: () => { },
  canChangeSentiment: true
};

export default SentimentItem;
