import { makeObservable, observable, action, computed } from 'mobx';
import { encodeURI } from 'js-base64';
import { message } from 'antd';
import * as xlsx from 'xlsx';
import html2canvas from 'html2canvas';
import dayjs from 'dayjs';

import optionsStore from 'src/stores/optionsStore';
import { getHost } from 'src/utils';
import { generateCompletedDayArray, getTotalDays } from 'src/utils/chart';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import { COLOR_SET } from 'src/consts/chart';

export default class MonthTrendViewModel {
  @observable originData = null;
  @observable chartData = null;
  @observable searchData = null;
  @observable options = null;

  @observable isCollapsed = false;

  @computed get excelTitle() {
    return [
      { key: 'month', header: '月份' },
      { key: 'volume', header: '聲量' }
    ];
  }

  @computed get excelData() {
    const excel = [];
    if (!this.originData) {
      return excel;
    }

    for (let i = 0; i < this.originData.length; i += 1) {
      const item = this.originData[i];
      excel.push({
        month: item.name,
        volume: item.count
      });
    }

    return excel;
  }

  constructor() {
    makeObservable(this);
  }

  @action toggleCollapse = () => {
    this.isCollapsed = !this.isCollapsed;
  };

  @action update = (data, searchData) => {
    this.updateContent(data, searchData);
    this.updateOptions(searchData);
  };

  @action updateContent = (data, searchData) => {
    if (!data || data.length === 0) {
      return;
    }

    this.originData = data;

    const labels = [];
    const datas = [];

    for (let i = 0; i < data.length; i += 1) {
      const item = data[i];
      labels.push(item.name);
      datas.push(item.count);
    }

    this.chartData = {
      labels,
      datasets: [{
        label: searchData.keyword,
        data: datas,
        borderColor: COLOR_SET[0],
        backgroundColor: COLOR_SET[0]
      }]
    };
  };

  @action updateOptions = (searchData) => {
    this.searchData = searchData;
    const onPointClick = (elements, chart) => {
      if (elements.length === 0) {
        return;
      }
      const { datasetIndex, index } = elements[0];
      const month = chart.data.labels[index];
      const womCount = chart.data.datasets[datasetIndex].data[index];

      const { keyword, postType, womType, category, website, channel, date } = searchData;

      const archiveData = {
        keyword,
        ...(womType && {
          womType
        }),
        ...(postType && {
          postType
        }),
        ...(category ? {
          category: category.map((el) => ({
            id: el,
            name: SOURCE_CATEGORY_TYPE[el]
          }))
        } : {
          category: SOURCE_CATEGORY.map((el) => ({
            id: el.value,
            name: el.label
          }))
        }),
        ...(website && { website: { id: website[0], name: website[0] } }),
        ...(channel && { channel: { id: channel[0].id, name: channel[0].name } }),
        chart: {
          name: '輿情搜尋/摘要圖表/日聲量趨勢'
        },
        date: {
          gte: dayjs(month).startOf('month').valueOf() < dayjs(date.gte).valueOf() ? dayjs(date.gte).format('YYYY-MM-DD') : dayjs(month).startOf('month').format('YYYY-MM-DD'),
          lte: dayjs(month).endOf('month').valueOf() > dayjs(date.lte).valueOf() ? dayjs(date.lte).format('YYYY-MM-DD') : dayjs(month).endOf('month').format('YYYY-MM-DD')
        },
        womCount
      };


      const json = JSON.stringify(archiveData);
      const result = encodeURI(json);
      const url = `${getHost()}/opinion-topics?result=${result}`;

      window.open(url, '_blank');
    };

    this.options = {
      responsive: true,
      event: ['click'],
      tension: 0.3,
      maintainAspectRatio: false,
      scales: {
        y: {
          title: {
            display: true,
            text: '聲量',
            color: '#000'
          },
          beginAtZero: true,
          grace: '5%',
          ticks: {
            color: '#000'
          }
        },
        x: {
          title: {
            display: true,
            text: '日期',
            color: '#000'
          },
          ticks: {
            color: '#000'
          }
        }
      },
      onHover: (event, elements) => {
        const native = event.native;
        if (!native) {
          return;
        }
        const target = native.target;
        if (!target) {
          return;
        }
        if (elements.length > 0) {
          target.style.cursor = 'pointer';
        } else {
          target.style.cursor = 'default';
        }
      },
      onClick: (ev, elements, chart) => onPointClick(elements, chart),
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            color: '#000'
          }
        },
        datalabels: {
          display: false
        }
      }
    };
  };

  @action onDownloadExcel = () => {
    this.isDownload = false;
    if (!this.excelTitle || !this.excelData) {
      message.error('csv檔不存在');
      return;
    }
    const data = [...this.excelData];
    const header = this.excelTitle.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [this.excelTitle.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    const wordbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wordbook, workSheet, '月聲量趨勢');
    xlsx.writeFile(wordbook, `輿情搜尋-${this.searchData.keyword}-月聲量趨勢-${this.searchData.date.gte}-${this.searchData.date.lte}.xlsx`);
  };

  @action appendSheet = (wordbook) => {
    this.isDownload = false;
    if (!this.excelTitle || !this.excelData) {
      message.error('csv檔不存在');
      return;
    }
    const data = [...this.excelData];
    const header = this.excelTitle.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [this.excelTitle.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    xlsx.utils.book_append_sheet(wordbook, workSheet, '月聲量趨勢');
  };

  @action onDownload = async () => {
    await optionsStore.onImgDownload();
    const chart = document.querySelector('.opinion-month-trend-chart');
    const canvas = await html2canvas(chart);
    const a = document.createElement('a');
    a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
    a.download = `輿情搜尋-${this.searchData.keyword}-月聲量趨勢-${this.searchData.date.gte}-${this.searchData.date.lte}.jpg`;
    a.click();
    optionsStore.onImgDownloadEnd();
  };
}
