import React from 'react';
import { Tooltip as AntdTooltip, Button } from 'antd';
import { DownloadOutlined, SaveOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import optionsStore from 'src/stores/optionsStore';
import i18n from 'src/i18n';

import styles from './styles.module.scss';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  ChartDataLabels
);

@observer
class Category extends React.Component {
  render() {
    const { viewModel } = this.props;
    if (!viewModel.chartData || !viewModel.options) {
      return null;
    }
    return (
      <div className={styles.chartContainer}>
        <div className={clsx('opinion-category-proportion', styles.download)}>
          <div className={styles.chartHeader}>
            <div className={styles.headerTitle}>
              {i18n.t('opinion_chart_category_proportion')}
            </div>
            <div className={styles.downloadActions}>
              <AntdTooltip
                title="下載數據"
                placement="bottom"
              >
                <Button
                  icon={<DownloadOutlined />}
                  onClick={viewModel.onDownloadExcel}
                  style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
                />
              </AntdTooltip>
              <AntdTooltip
                title="下載圖檔"
                placement="bottom"
              >
                <Button
                  icon={<SaveOutlined />}
                  onClick={viewModel.onDownload}
                  style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
                />
              </AntdTooltip>
            </div>
          </div>
          <div className={styles.timeStamp}>
            {`日期範圍：${viewModel.searchData.date.gte} ~ ${viewModel.searchData.date.lte}`}
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div
              style={{ maxWidth: 600, flexGrow: 1 }}
            >
              <Doughnut
                key={`${viewModel.searchData.keyword}_category_proportion`}
                options={viewModel.options}
                data={viewModel.chartData}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Category.propTypes = {
  viewModel: PropTypes.object
};

Category.defaultProps = {
  viewModel: {}
};

export default Category;
