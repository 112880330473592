import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';

import styles from './styles.module.scss';

@observer
class NoDataChart extends React.Component {
  render() {
    const { subChart } = this.props;
    return (
      <div className={styles.container}>
        {subChart}
      </div>
    );
  }
}

NoDataChart.propTypes = {
  subChart: PropTypes.element
};

NoDataChart.defaultProps = {
  subChart: null
};


export default NoDataChart;
