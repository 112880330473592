import { makeObservable, observable, action, computed, reaction } from 'mobx';
import packageStore from 'src/stores/packageStore';

import i18n from 'src/i18n';

export default class BulletinLevelSelectModalViewModel {
  @observable isModalOpen = false;
  @observable projectId = '';

  @observable brand = [];
  @observable selectedBrand = null;
  @observable selectedBrandCache = null;
  @observable brandKeyword = '';
  @observable brandKeywordCache = '';

  @observable series = [];
  @observable selectedSeries = null;
  @observable selectedSeriesCache = null;
  @observable seriesKeyword = '';
  @observable seriesKeywordCache = '';

  @observable product = [];
  @observable selectedProduct = null;
  @observable selectedProductCache = null;
  @observable productKeyword = '';
  @observable productKeywordCache = '';

  @computed get getSeriesFlat() {
    return this.series?.map((el) => el.children.map((c) => ({ parentId: el.id, id: c.id, name: c.name }))).flat() ?? [];
  }

  @computed get selectedSeriesParent() {
    return this.series?.find((el) => el.children.some((c) => c.id === this.selectedSeries))?.id ?? null;
  }

  @computed get keywordBrand() {
    return this.brand?.filter((el) => el.name.toLowerCase().includes(this.brandKeyword.toLowerCase())) ?? [];
  }

  @computed get keywordSeries() {
    return this.getSeriesFlat.filter((el) => el.name.toLowerCase().includes(this.seriesKeyword.toLowerCase()));
  }

  @computed get filterBrandProduct() {
    return this.selectedBrand
      ? this.product.filter((el) => el.id === this.selectedBrand)
      : this.product;
  }

  @computed get filterSeriesProduct() {
    return this.selectedSeries
      ? this.filterBrandProduct.map((el) => ({
        ...el,
        children: el.children.filter((c) => c.extraIds.includes(this.selectedSeries))
      })).filter((el) => el.children.length >= 1)
      : this.filterBrandProduct;
  }

  @computed get keywordProduct() {
    return this.filterSeriesProduct?.filter((el) => {
      return el.name.toLowerCase().includes(this.productKeyword.toLowerCase()) || el.children?.some((item) => item.name.toLowerCase().includes(this.productKeyword.toLowerCase()));
    }) ?? [];
  }

  @computed get selectedBrandName() {
    return this.brand.find((el) => el.id === this.selectedBrandCache)?.name ?? '';
  }

  @computed get selectedSeriesName() {
    return this.getSeriesFlat.find((el) => el.id === this.selectedSeriesCache)?.name ?? '';
  }

  @computed get selectedProductName() {
    return this.product.map((el) => el.children.map((c) => ({ id: c.id, name: c.name }))).flat().find((el) => el.id === this.selectedProductCache)?.name ?? '';
  }

  @computed get modalWidth() {
    switch (packageStore.opened.filter((el) => el !== 'industry').length) {
      case 2:
        return 776;
      case 3:
        return 1130;
      default:
        return 422;
    }
  }

  constructor() {
    makeObservable(this);

    this.init();
  }

  @action init = () => {
    if (packageStore.brand.length > 0) {
      const {
        brand,
        series,
        product
      } = packageStore;

      this.brand = brand;
      this.series = series;
      this.product = product;
    } else {
      const once = reaction(
        () => packageStore.isLevelProcessing,
        async (bool) => {
          if (!bool) {
            const {
              brand,
              series,
              product
            } = packageStore;

            this.brand = brand;
            this.series = series;
            this.product = product;
            once();
          }
        }
      );
    }
  };

  @action updateInfo = (data) => {
    const {
      brand,
      series,
      product
    } = data;
    this.selectedBrand = brand;
    this.selectedBrandCache = this.selectedBrand;
    this.selectedSeries = series;
    this.selectedSeriesCache = this.selectedSeries;
    this.selectedProduct = product;
    this.selectedProductCache = this.selectedProduct;
  };

  // > modal related

  @action onModalOpen = () => {
    this.isModalOpen = true;
  };

  @action onModalClose = () => {
    this.isModalOpen = false;

    this.brandKeyword = '';
    this.brandKeywordCache = '';
    this.seriesKeyword = '';
    this.seriesKeywordCache = '';
    this.productKeyword = '';
    this.productKeywordCache = '';
  };

  // > actions related

  @action initContent = ({ brand, series, product }) => {
    this.selectedBrandCache = brand;
    this.selectedSeriesCache = series;
    this.selectedProductCache = product;

    let text = '';

    const brandText = this.selectedBrandName ? ` ${i18n.t('common_brand')}: ${this.selectedBrandName}` : '';
    const seriesText = this.selectedSeriesName ? `${i18n.t('common_series')}: ${this.selectedSeriesName}` : '';
    const productText = this.selectedProductName ? `${i18n.t('common_product')}: ${this.selectedProductName}` : '';

    if (!this.selectedBrandName && !this.selectedSeriesName && !this.selectedProductName) {
      text = '「尚未選擇」';
    } else {
      text = `「${brandText} ${seriesText}  ${productText}」`;
    }

    return {
      text,
      seriesParent: this.series?.find((el) => el.children.some((c) => c.id === series))?.id ?? null
    };
  };

  @action onCleanAll = () => {
    this.selectedBrand = null;
    this.selectedSeries = null;
    this.selectedProduct = null;
  };

  @action onSubmit = (onSubmitCallback = () => { }) => {
    this.selectedBrandCache = this.selectedBrand;
    this.selectedSeriesCache = this.selectedSeries;
    this.selectedProductCache = this.selectedProduct;

    let text = '';

    const brandText = this.selectedBrandName ? ` ${i18n.t('common_brand')}: ${this.selectedBrandName}` : '';
    const seriesText = this.selectedSeriesName ? `${i18n.t('common_series')}: ${this.selectedSeriesName}` : '';
    const productText = this.selectedProductName ? `${i18n.t('common_product')}: ${this.selectedProductName}` : '';

    if (!this.selectedBrandName && !this.selectedSeriesName && !this.selectedProductName) {
      text = '「尚未選擇」';
    } else {
      text = `「${brandText} ${seriesText}  ${productText}」`;
    }

    onSubmitCallback({
      brand: this.selectedBrand,
      seriesParent: this.selectedSeriesParent,
      series: this.selectedSeries,
      product: this.selectedProduct,
      text
    });

    this.onModalClose();
  };

  @action onClose = () => {
    this.selectedBrand = this.selectedBrandCache;
    this.selectedSeries = this.selectedSeriesCache;
    this.selectedProduct = this.selectedProductCache;

    this.onModalClose();
  };

  // > brand related

  @action onBrandSelect = (e) => {
    this.selectedBrand = e.target.value;
    if (this.selectedProduct) {
      this.selectedProduct = null;
    }
  };

  // > search

  @action onBrandKeywordChange = (e) => {
    this.brandKeywordCache = e.target.value;
    if (!e.target.value.trim()) {
      this.onBrandSearchSubmit();
    }
  };

  @action onBrandSearchSubmit = () => {
    this.brandKeyword = this.brandKeywordCache;
  };

  @action onBrandKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onBrandSearchSubmit();
    }
  };

  // > series related

  @action onSeriesSelect = (e) => {
    this.selectedSeries = e.target.value;
    if (this.selectedProduct) {
      this.selectedProduct = null;
    }
  };

  // > search

  @action onSeriesKeywordChange = (e) => {
    this.seriesKeywordCache = e.target.value;
    if (!e.target.value.trim()) {
      this.onSeriesSearchSubmit();
    }
  };

  @action onSeriesSearchSubmit = () => {
    // ? note: use computed instead of call api to reduce api calling times.
    this.seriesKeyword = this.seriesKeywordCache;
  };

  @action onSeriesKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onSeriesSearchSubmit();
    }
  };


  // > product related

  @action onProductSelect = (e) => {
    this.selectedProduct = e.target.value;
  };

  // > search

  @action onProductKeywordChange = (e) => {
    this.productKeywordCache = e.target.value;
    if (!e.target.value.trim()) {
      this.onProductSearchSubmit();
    }
  };

  @action onProductSearchSubmit = () => {
    // ? note: use computed instead of call api to reduce api calling times.
    this.productKeyword = this.productKeywordCache;
  };

  @action onProductKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onProductSearchSubmit();
    }
  };

  // > new function
  @action onLevelOneOrTagClick = () => {
    this.selectedProduct = null;
  };
}
