import { request, getHost, getCacheHeaders } from './utils';

export const getArticle = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/asiaIndex`,
    headers: getCacheHeaders(),
    params: {
      type: 'article'
    }
  };
  return request(options);
};
