import { makeObservable, observable, action, computed } from 'mobx';

import CheckItemViewModel from '../CheckItem/viewModel';

export default class CheckGroupItemViewModel {
  @observable id = '';
  @observable name = '';
  @observable children = [];

  @observable keyword = '';

  @computed get groupIndeterminate() {
    return this.keywordChildren.some((el) => el.checkedCache) && !this.groupAllSelected;
  }

  @computed get groupAllSelected() {
    return this.keywordChildren.every((el) => el.checkedCache);
  }

  @computed get checkedText() {
    const isSelected = [];
    for (let i = 0; i < this.children.length; i += 1) {
      if (this.children[i].checked) {
        isSelected.push(this.children[i].name);
      }
    }

    return isSelected.length >= 1 ? `${this.name}「${isSelected.join(',')}」` : '';
  }

  @computed get selectedItem() {
    const result = [];
    for (let i = 0; i < this.children.length; i += 1) {
      if (this.children[i].checkedCache) {
        result.push(this.children[i].id);
      }
    }
    return result;
  }

  @computed get SelectedWithParent() {
    const result = [];
    for (let i = 0; i < this.children.length; i += 1) {
      if (this.children[i].checkedCache) {
        result.push({
          parentId: this.id,
          id: this.children[i].id
        });
      }
    }
    return result;
  }

  @computed get keywordChildren() {
    if (this.name.toLowerCase().includes(this.keyword.toLowerCase())) {
      return this.children;
    }
    return this.children.filter((el) => el.name.toLowerCase().includes(this.keyword.toLowerCase()));
  }

  constructor(data) {
    makeObservable(this);

    this.init(data);
  }

  @action init = (data) => {
    const {
      id,
      name,
      children
    } = data;

    this.id = id;
    this.name = name;
    this.children = children.map((el) => {
      return new CheckItemViewModel(el, this);
    });
  };

  @action onGroupSelect = (afterSelect) => {
    if (!this.groupAllSelected) {
      this.keywordChildren.forEach((el) => {
        el.setSelect(true);
        afterSelect({ id: el.id, name: `${this.name}/${el.name}` });
      });
    } else {
      this.keywordChildren.forEach((el) => {
        el.setSelect(false);
        afterSelect({ id: el.id, name: `${this.name}/${el.name}` });
      });
    }
  };

  @action onAllClean = () => {
    for (let i = 0; i < this.keywordChildren.length; i += 1) {
      this.keywordChildren[i].setSelect(false);
    }
  };

  @action setKeyword = (e) => {
    this.keyword = e;
  };
}
