/* eslint-disable */
export default {
  "ID": "zh_TW",
  "": "",
  "common_industry": "行業",
  "common_brand": "品牌",
  "common_series": "系列",
  "common_product": "產品",
  "home_page_name": "首頁",
  "home_newest_announcement": "最新公告",
  "home_website_article": "官網文章連結",
  "home_more_article": "查看更多官網文章",
  "home_source_type_statistics": "來源類型頻道數",
  "home_other_service": "其他服務資源",
  "search_name": "輿情搜尋",
  "topic_page_name": "話題列表",
  "chart_page_name": "圖表分析",
  "wordbook_page_name": "分類詞庫",
  "data_report_page_name": "數據報告"
};
