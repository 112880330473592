import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import clsx from 'clsx';

import { Checkbox } from 'antd';

import styles from './styles.module.scss';


class CheckItem extends React.Component {
  render() {
    const { viewModel, afterBrandSelect } = this.props;
    return (
      <Observer>
        {() => {
          return (
            <div className={styles.levelItem}>
              <Checkbox
                checked={viewModel.checkedCache}
                onChange={(e) => viewModel.toggleChecked(e, afterBrandSelect)}
              />
              <div className={styles.levelName}>
                {viewModel.name}
              </div>
            </div>
          );
        }}
      </Observer>
    );
  }
}

CheckItem.propTypes = {
  viewModel: PropTypes.object,
  afterBrandSelect: PropTypes.func
};

CheckItem.defaultProps = {
  viewModel: {},
  afterBrandSelect: () => { }
};

export default CheckItem;

