import { CATEGORY_COLOR_SET } from 'src/consts/chart';
import { SOURCE_CATEGORY_TYPE, SOURCE_ORDER_TC } from 'src/consts';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { generateCSVKey } from 'src/utils/chart';

export default class BrandSourceCategoryProportionModel {
  constructor(origin, query) {
    if (origin.length === 0) {
      this.data = {
        chartData: [{
          id: 'brand_source_category_proportion',
          name: '品牌來源類型佔比',
          data: [{
            type: 'nodata',
            subType: 'ana-errpr'
          }]
        }]
      };
      return;
    }

    const data = origin.slice(0, 10);

    const total = (() => {
      let sum = 0;
      data.forEach((el) => {
        sum += el.count;
      });
      return sum;
    })();

    const {
      id,
      name,
      postType,
      womType
    } = query;

    const [gte, lte] = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'));

    const csv = [
      {
        date: `${gte} ~ ${lte}`
      }
    ];

    data.forEach((el) => {
      const row = csv[0];

      row[`${SOURCE_CATEGORY_TYPE[el.name]}`] = el.count;
      row[`${SOURCE_CATEGORY_TYPE[el.name]}-佔比`] = `${Math.floor((el.count / total) * 1000) / 10}%`;
    });

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'brand_source_category_proportion',
          name: '品牌來源類型佔比',
          data: [
            ...(
              total
                ? [{
                  labels: data.map((el) => SOURCE_CATEGORY_TYPE[el.name]),
                  datasets: [{
                    label: `${gte} ~ ${lte}`,
                    data: data.map((el) => el.count),
                    category: data.map((el) => el.name),
                    backgroundColor: data.map((el) => CATEGORY_COLOR_SET[el.name]),
                    datalabels: {
                      color: '#fff',
                      formatter: (value) => {
                        const percentage = ((value / total) * 100).toFixed();
                        return percentage > 5 ? `${percentage}%` : null;
                      }
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const percentage = ((context.parsed / total) * 100).toFixed();
                          return ` ${context.parsed}: [${percentage}%]`;
                        }
                      }
                    }
                  }]
                }]
                : [{ type: 'nodata' }]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const category = chart.data.datasets[datasetIndex].category[index];
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                category: [{
                  id: category,
                  name: SOURCE_CATEGORY_TYPE[category]
                }],
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '數據報告/品牌成效分析/品牌來源類型佔比'
                },
                brand: [{
                  id,
                  name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'pie',
          options: {
            event: ['click'],
            plugins: {
              legend: {
                position: 'right',
                labels: {
                  sort: (a, b) => {
                    const indexA = SOURCE_ORDER_TC.indexOf(a.text);
                    const indexB = SOURCE_ORDER_TC.indexOf(b.text);
                    return indexA - indexB;
                  }
                }
              }
            }
          },
          csv,
          csvKey
        }
      ],
      channel: data.map((el) => ({ id: el.id, name: el.name }))
    };
  }

  static fromRes(data, query) {
    return new BrandSourceCategoryProportionModel(data, query);
  }
}
