export default class FeatureSetContentModel {
  constructor(data) {
    this.feature = data.map((el) => ({
      id: el._id,
      name: el.name
    }));
  }

  static fromRes(data) {
    return new FeatureSetContentModel(data);
  }
}
