import { COLOR_SET } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY } from 'src/consts';
import { generateCSVKey } from 'src/utils/chart';

export default class BrandVolumeProportionModel {
  constructor(origin, prevOrigin, query) {
    const data = origin.slice(0, 10);
    const prevData = prevOrigin.slice(0, 10);

    const total = (() => {
      let sum = 0;
      data.forEach((el) => {
        sum += el.count;
      });
      return sum;
    })();

    const prevTotal = (() => {
      let sum = 0;
      prevData.forEach((el) => {
        sum += el.count;
      });
      return sum;
    })();

    const combine = data.map((el) => ({
      name: el.name,
      count: el.count,
      prevCount: 0
    }));

    prevData.forEach((el) => {
      const target = combine.find((item) => item.name === el.name);
      if (target) {
        target.prevCount = el.count;
      } else {
        combine.push({
          name: el.name,
          count: 0,
          prevCount: el.count
        });
      }
    });

    const brand = origin.map((el) => ({ id: el.id, name: el.name }));
    prevOrigin.forEach((el) => {
      const target = brand.find((item) => item.id === el.id);
      if (!target) {
        brand.push({
          id: el.id,
          name: el.name
        });
      }
    });

    combine.sort((a, b) => b.count - a.count);

    const { currentDate, autoPrevious } = infoHeaderViewModel.multiViewModel;

    const prototype = [
      {
        date: `${currentDate[0].format('YYYY-MM-DD')} ~ ${currentDate[1].format('YYYY-MM-DD')}`
      },
      {
        date: `${autoPrevious[0].format('YYYY-MM-DD')} ~ ${autoPrevious[1].format('YYYY-MM-DD')}`
      }
    ];

    const csv = prototype.map((el, di) => {
      const row = el;
      for (let i = 0; i < combine.length; i += 1) {
        if (di === 0) {
          row[`${combine[i].name}`] = combine[i].count;
          row[`${combine[i].name}-佔比`] = `${Math.floor((combine[i].count / total) * 1000) / 10}%`;
        } else {
          row[`${combine[i].name}`] = combine[i].prevCount;
          row[`${combine[i].name}-佔比`] = `${Math.floor((combine[i].prevCount / prevTotal) * 1000) / 10}%`;
        }
      }

      return row;
    });

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'brand_volume_proportion',
          name: '品牌聲量佔比',
          data: [
            ...(
              total
                ? [{
                  labels: data.map((el) => el.name),
                  datasets: [{
                    label: `${currentDate[0].format('YYYY-MM-DD')} ~ ${currentDate[1].format('YYYY-MM-DD')}`,
                    brandId: data.map((el) => el.id),
                    data: data.map((el) => el.count),
                    backgroundColor: data.map((el, i) => COLOR_SET[i % 20]),
                    datalabels: {
                      color: '#fff',
                      formatter: (value) => {
                        const percentage = ((value / total) * 100).toFixed();
                        return percentage > 5 ? `${percentage}%` : null;
                      }
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const percentage = ((context.parsed / total) * 100).toFixed();
                          return ` ${context.parsed}: [${percentage}%]`;
                        }
                      }
                    }
                  }]
                }]
                : [{
                  type: 'nodata',
                  subType: data.length > 0 ? 'default' : 'ana-error'
                }]
            ),
            ...(
              prevTotal
                ? [{
                  labels: prevData.map((el) => el.name),
                  datasets: [{
                    label: `${autoPrevious[0].format('YYYY-MM-DD')} ~ ${autoPrevious[1].format('YYYY-MM-DD')}`,
                    brandId: prevData.map((el) => el.id),
                    data: prevData.map((el) => el.count),
                    backgroundColor: prevData.map((el, i) => COLOR_SET[i % 20]),
                    datalabels: {
                      color: '#fff',
                      formatter: (value) => {
                        const percentage = ((value / prevTotal) * 100).toFixed();
                        return percentage > 5 ? `${percentage}%` : null;
                      }
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const percentage = ((context.parsed / prevTotal) * 100).toFixed();
                          return ` ${context.parsed}: [${percentage}%]`;
                        }
                      }
                    }
                  }]
                }]
                : [{
                  type: 'nodata',
                  subType: prevData.length > 0 ? 'default' : 'ana-error'
                }]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType
              } = query;
              const { datasetIndex, index } = elements[0];
              const brandName = chart.data.labels[index];
              const brandId = chart.data.datasets[datasetIndex].brandId[index];
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const [gte, lte] = chart.data.datasets[datasetIndex].label.split('~');

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                category: SOURCE_CATEGORY.map((el) => ({
                  id: el.value,
                  name: el.label
                })),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '數據報告/品牌成效分析/品牌聲量佔比'
                },
                brand: [{
                  id: brandId,
                  name: brandName
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'pie',
          options: {
            event: ['click'],
            plugins: {
              legend: {
                position: 'right'
              }
            }
          },
          csv,
          csvKey
        }
      ],
      brand
    };
  }

  static fromRes(data, prevData, query) {
    return new BrandVolumeProportionModel(data, prevData, query);
  }
}
