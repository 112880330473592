import { makeObservable, observable, action, computed } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

export default class NoDataChartViewModel {
  type = 'nodata';

  @observable id = '';
  @observable key = '';
  @observable subtype = 'default';

  constructor(content) {
    makeObservable(this);
    this.init(content.data);
  }

  @action init = (data) => {
    this.key = uuidv4();
    const { subType } = data;
    this.subtype = subType ?? 'default';
  };
}
