import { COLOR_SET } from 'src/consts/chart';
import packageStore from 'src/stores/packageStore';
import { getHost } from 'src/utils';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY } from 'src/consts';
import { generateCSVKey, htmlLegendPlugin } from 'src/utils/chart';

export default class BrandConsumerFeature {
  constructor(data, query) {
    if (!data.length >= 1) {
      this.data = {
        chartData: [{
          id: 'brand_consumer_feature',
          name: '品牌雷達圖',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }]
        }]
      };
      return;
    }

    const total = (() => {
      let sum = 0;
      data.forEach((el) => {
        sum += el.count;
      });

      return sum;
    })();

    if (!total) {
      this.data = {
        chartData: [{
          id: 'brand_consumer_feature',
          name: '產品雷達圖',
          data: [{
            type: 'nodata'
          }]
        }]
      };
      return;
    }

    const {
      id,
      name,
      postType,
      womType
    } = query;

    const csv = {
      主品牌: name
    };

    data.forEach((el) => {
      csv[`${el.name}`] = el.count;
    });

    for (let i = data.length; i < 5; i += 1) {
      data.push({
        id: '',
        name: '',
        count: 0
      });
    }

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'brand_consumer_feature',
          name: '品牌消費者關注',
          data: [{
            labels: data.map((el) => el.name),
            datasets: [{
              label: name,
              data: data.map((f) => f.count),
              feature: data.map((el) => ({ id: el.id, name: el.name })),
              total,
              borderColor: COLOR_SET[0],
              tooltip: {
                callbacks: {
                  label: (context) => {
                    const { formattedValue, dataset, label: featureName, raw } = context;
                    const { total: t, label } = dataset;
                    const percentage = t ? Math.round((raw / t) * 1000) / 10 : 0;
                    if (!featureName.trim()) {
                      return '';
                    }
                    return `${label} - ${featureName}: ${formattedValue}, 佔比: ${percentage}%`;
                  }
                }
              }
            }]
          }],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const feature = chart.data.datasets[datasetIndex].feature[index];
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const [gte, lte] = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'));

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                category: SOURCE_CATEGORY.map((el) => ({
                  id: el.value,
                  name: el.label
                })),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '數據報告/品牌例行性報告/品牌消費者關注'
                },
                featureCategory: {
                  id: feature.id,
                  name: feature.name
                },
                brand: [{
                  id,
                  name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'radar',
          options: {
            responsive: true,
            event: ['click'],
            scales: {
              r: {
                angleLines: {
                  display: false
                },
                ticks: {
                  display: false
                }
              }
            },
            plugins: {
              legend: {
                display: false
              },
              datalabels: {
                display: false
              }
            }
          },
          plugins: [htmlLegendPlugin],
          csv: [csv],
          csvKey
        }
      ],
      origin: data
    };
  }

  static fromRes(data, query) {
    return new BrandConsumerFeature(data, query);
  }
}
