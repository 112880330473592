import { request, getHost, getHeaders } from '../utils';

export const getSeriesVolumeTrend = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/marketing/volume/trend`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getSeriesVolumeProportion = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/marketing/volume/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getSeriesBrandVolumeCompare = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/marketing/brand/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getSeriesSourceCategoryCompare = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/marketing/source/category/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getSeriesProductVolumeCompare = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/marketing/product/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getTopsSeriesBrandVolumeProportion = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/marketing/series/brand/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getTopsSeriesProductVolumeRanking = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/marketing/series/product/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getTopsSeriesWord = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/marketing/series/feature/term`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

