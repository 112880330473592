import API from 'src/apis';

import packageStore from 'src/stores/packageStore';
import BulletinListModel from 'src/models/response/BulletinList';
import BulletinInfoModel from 'src/models/response/BulletinInfo';

export default class BulletinService {

  static getPackageId = () => {
    return packageStore.activePackageId;
  };

  static async getBulletins(params) {
    const res = await API.bulletin.getBulletins(this.getPackageId(), params);

    return BulletinListModel.fromRes(res.data);
  }

  static async createBulletins(data) {
    const res = await API.bulletin.createBulletins(this.getPackageId(), data);

    return res.data?.result._id;
  }

  static async getBulletinInfo(bid) {
    const res = await API.bulletin.getBulletinInfo(this.getPackageId(), bid);

    return BulletinInfoModel.fromRes(res.data.result);
  }

  static async updateBulletin(bid, data) {
    await API.bulletin.updateBulletin(this.getPackageId(), bid, data);
  }

  static async deleteBulletin(bid) {
    await API.bulletin.deleteBulletin(this.getPackageId(), bid);
  }

  static async sendBulletin(bid, frequency) {
    await API.bulletin.sendBulletin(this.getPackageId(), bid, frequency);
  }
}
