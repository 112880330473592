import { makeObservable, observable, action, computed, runInAction, reaction } from 'mobx';

import { CHART_ANALYSIS_TYPE } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import i18n from 'src/i18n';
import { message } from 'antd';
import { getLevelText } from 'src/utils';

import SourceService from 'src/services/source';
import ChartService from 'src/services/charts';
import optionsStore from 'src/stores/optionsStore';
import BlockedWordsService from 'src/services/blockedwords';
import packageStore from 'src/stores/packageStore';
import ChartAnalysisService from 'src/services/charts/chartAnalysis';
import CategoryPopoverSelectViewModel from 'src/components/CategoryPopoverSelect/viewModel';

import ChartItemViewModel from './ChartItem/viewModel';
import WordChartItemViewModel from './WordChartItem/viewModel';

export default class ChartAnalysisPageViewModel {
  @observable level = null;
  @observable analysisTypes = CHART_ANALYSIS_TYPE.industry;
  @observable selectedType = null;
  @observable subTypes = [];
  @observable selectedSubtype = null;
  @observable subChartTypes = [];
  @observable selectedSubChartType = null;

  @observable sourceCategoryPopover = new CategoryPopoverSelectViewModel();
  @observable selectedPostType = 'all';
  @observable selectedWOMType = 'all';
  @observable tagList = [];
  @observable selectedTag = null;
  @observable selectedTagCache = null;

  @observable selectedIndex = 'frequency';
  @observable selectedCount = 20;

  @observable chartItems = {};
  @observable chartDesc = '';
  @observable needChartTabs = false;

  @observable isBlockModalOpen = false;
  @observable blockWords = [];
  @observable cacheBlockWords = [];

  @observable isInit = false;

  // > test use
  @observable router = null;


  @computed get isShowStatisticClean() {
    return this.isAssocs
      ? this.selectedPostType !== 'post' || this.selectedWOMType !== 'all' || (this.sourceCategoryPopover.selected.length > 0 && this.showCategorySelect)
      : this.selectedPostType !== 'all' || this.selectedWOMType !== 'all' || (this.sourceCategoryPopover.selected.length > 0 && this.showCategorySelect);
  }

  @computed get isShowChartClean() {
    return this.selectedIndex !== 'frequency' || this.selectedCount !== 20;
  }

  @computed get selectedTypeInfo() {
    return this.analysisTypes.find((el) => el.id === this.selectedType) ?? {};
  }

  @computed get selectedSubtypeInfo() {
    return this.subTypes.find((el) => el.value === this.selectedSubtype) ?? {};
  }

  @computed get selectedSubChartTypeInfo() {
    return this.subChartTypes.find((el) => el.id === this.selectedSubChartType) ?? {};
  }

  @computed get breadcrumb() {
    return [
      '圖表分析',
      this.level ? i18n.t(`common_${this.level}`) : '階層',
      this.selectedTypeInfo?.name ?? '分析方法',
      this.selectedSubtypeInfo?.label ?? '',
      ...(
        this.selectedSubChartType
          ? [this.selectedSubChartTypeInfo?.name]
          : []
      )];
  }

  @computed get showSubtypes() {
    return this.subTypes.slice().filter((el) => {
      switch (el.additionRule) {
        case 'industry':
          return packageStore.isIndustryOpen;
        case 'brand':
          return packageStore.isBrandAvailable;
        case 'series':
          return packageStore.isSeriesAvailable;
        case 'product':
          return packageStore.isProductAvailable;
        default:
          return true;
      }
    });
  }

  @computed get showCategorySelect() {
    return !(this.selectedSubtype?.includes('source_category') || this.selectedSubtype?.includes('source_social'));
  }

  @computed get isAssocs() {
    return this.selectedSubtype?.includes('assocs');
  }

  @computed get isSpecialWordItem() {
    return this.level !== 'industry' && this.selectedType === 'keyword';
  }

  @computed get isHotWord() {
    return this.selectedSubtype?.includes('hot') && this.selectedType === 'keyword';
  }

  @computed get isTrendWord() {
    return this.selectedSubtype?.includes('trend') && this.selectedType === 'keyword';
  }

  @computed get isNewWord() {
    return this.selectedSubtype?.includes('new') && this.selectedType === 'keyword';
  }

  @computed get needTagKey() {
    return this.selectedSubtype === 'industry_volume_proportion' || this.selectedSubtype === 'product_series_volume' || this.level === 'series';
  }


  constructor() {
    makeObservable(this);

    this.init();
  }

  @action init = async () => {
    try {
      if (!packageStore.isLevelProcessing) {
        await this.getData();
        runInAction(() => {
          this.isInit = true;
        });
      } else {
        const once = reaction(
          () => packageStore.isLevelProcessing,
          async (bool) => {
            if (!bool) {
              await this.getData();
              runInAction(() => {
                this.isInit = true;
              });
              once();
            }
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  @action getData = async () => {
    await Promise.all([
      this.getSource(),
      this.getBlockWords()
    ]);
    runInAction(() => {
      this.tagList = packageStore.series.map((el) => ({ value: el.id, label: el.name }));
      this.selectedTag = this.tagList?.[0]?.value ?? null;
      this.selectedTagCache = this.selectedTag;
    });
  };

  @action updateSearchURL = () => {
    this.router.searchParams[0].set('type', this.selectedType);
    this.router.searchParams[0].set('sub', this.selectedSubtype);
    if (this.selectedSubChartType) {
      this.router.searchParams[0].set('chart', this.selectedSubChartType);
    }

    this.router.navigate({ search: this.router.searchParams[0].toString() });
  };

  @action updateLevel = (prev, curr) => {
    if (prev.router.params.level === curr.params.level && this.level) {
      return;
    }
    this.router = curr;

    if (!['industry', 'brand', 'series', 'product'].includes(curr.params.level)) {
      message.error('錯誤的階層, 請重新確認！');

      this.router.navigate('/home');
      return;
    }

    const searchParams = curr.searchParams[0];

    const type = searchParams.get('type');
    const subType = searchParams.get('sub');
    const chart = searchParams.get('chart');

    this.level = curr.params.level;
    // > update type
    this.analysisTypes = CHART_ANALYSIS_TYPE[curr.params.level];
    const targetType = type ? this.analysisTypes.find((el) => el.id === type) : null;
    this.selectedType = targetType?.id ?? this.analysisTypes[0].id;
    // > update sub type
    const subTypeList = this.analysisTypes.find((el) => el.id === type) ?? this.analysisTypes[0];
    this.subTypes = subTypeList.children.map((el) => ({ value: el.id, label: el.name, ...el }));
    const targetSubType = subType ? this.subTypes.find((el) => el.id === subType) : null;
    this.selectedSubtype = targetSubType?.value ?? this.subTypes[0].value;

    // > update sub chart type
    if (this.selectedSubtypeInfo.children) {
      this.subChartTypes = [...this.selectedSubtypeInfo.children];
      const targetSubChartType = chart ? this.subChartTypes.find((el) => el.id === chart) : null;
      this.selectedSubChartType = targetSubChartType?.id ?? this.subChartTypes[0].id;
    } else {
      this.selectedSubChartType = null;
    }

    this.updateSearchURL();

    if (this.isAssocs) {
      this.selectedPostType = 'post';
    }

    if (!this.isInit) {
      const once = reaction(
        () => this.isInit,
        (bool) => {
          if (bool) {
            this.getGraphicData();
            once();
          }
        }
      );
    } else {
      this.getGraphicData();
    }
  };
  @action onTypeChange = (type) => {
    this.selectedType = type;
    this.subTypes = this.analysisTypes.find((el) => el.id === type).children.map((el) => ({
      ...(el.replace && { replace: el.replace }),
      ...(el.children && { children: el.children }),
      value: el.id,
      label: el.name
    }));
    this.selectedSubtype = this.subTypes[0].value;

    if (this.selectedSubtypeInfo.children) {
      this.subChartTypes = [...this.selectedSubtypeInfo.children];
      this.selectedSubChartType = this.subChartTypes[0].id;
    } else {
      this.subChartTypes = [];
      this.selectedSubChartType = null;
    }

    if (this.isAssocs) {
      this.selectedPostType = 'post';
    }

    this.getGraphicData();
    this.updateSearchURL();
  };

  @action onSubtypeChange = (e) => {
    this.selectedSubtype = e.target.value;

    if (this.selectedSubtypeInfo.children) {
      this.subChartTypes = [...this.selectedSubtypeInfo.children];
      this.selectedSubChartType = this.subChartTypes[0].id;
    } else {
      this.subChartTypes = [];
      this.selectedSubChartType = null;
    }

    if (this.isAssocs) {
      this.selectedPostType = 'post';
    }

    this.getGraphicData();
    this.updateSearchURL();
  };

  @action onTagChange = (e) => {
    this.selectedTag = e;
    this.getGraphicData();
  };

  @action onSubChartTypeChange = (e) => {
    this.selectedSubChartType = e;

    this.getGraphicData(true);
    this.updateSearchURL();
  };

  @action getSource = async () => {
    try {
      optionsStore.setLoading('source');
      const { source } = await SourceService.getPackageSources({
        date: {
          gte: infoHeaderViewModel.multiViewModel.currentDate[0].format('YYYY-MM-DD'),
          lte: infoHeaderViewModel.multiViewModel.currentDate[1].format('YYYY-MM-DD')
        },
        ...(this.selectedPostType !== 'all' && { postType: this.selectedPostType }),
        ...(this.selectedWOMType !== 'all' && { womType: this.selectedWOMType })
      });

      runInAction(() => {
        this.sourceCategoryPopover.updateList(source);
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        optionsStore.setCompleted('source');
      });
    }
  };

  // > filter function
  // > statistic filter
  @action onPostTypeChange = (e) => {
    this.selectedPostType = e;
  };

  @action onWOMTypeChange = (e) => {
    this.selectedWOMType = e;
  };

  @action onCleanStatisticFilter = () => {
    this.selectedPostType = this.isAssocs ? 'post' : 'all';
    this.selectedWOMType = 'all';
    this.sourceCategoryPopover.onCleanAll();
  };

  // > chart filter

  @action onChartIndexChange = (e) => {
    this.selectedIndex = e;
  };

  @action onChartCountChange = (e) => {
    this.selectedCount = e;
  };

  @action onCleanChartFilter = () => {
    this.selectedIndex = 'frequency';
    this.selectedCount = 20;
  };

  @action onWordCloudShowCountChange = () => {
    if (this.isSpecialWordItem) {
      this.chartItems.onWordCloudCountChange();
    } else {
      this.getGraphicData();
    }
  };


  @action getGraphicData = async (needPreserveTabId = false) => {
    try {
      if (optionsStore.isLoading) {
        return;
      }
      optionsStore.setLoading('chartData');
      this.chartItems.onReady = false;
      this.chartDesc = ChartAnalysisService.getGraphDesc(this.level, this.selectedType, this.selectedSubtype, this.selectedSubChartType);
      this.needChartTabs = ChartAnalysisService.getNeedTabs(this.level, this.selectedType, this.selectedSubtype, this.selectedSubChartType);
      // > 24/04/25 updating
      const checkLatest = await packageStore.checkLatest();
      if (checkLatest.needUpdate) {
        optionsStore.setCompleted('chartData');
        await packageStore.syncLatest(checkLatest);
        return;
      }
      const params = {
        ...(this.selectedPostType !== 'all' && { postType: this.selectedPostType }),
        ...(this.selectedWOMType !== 'all' && { womType: this.selectedWOMType }),
        ...(this.showCategorySelect && this.sourceCategoryPopover.selected.length >= 1 && {
          category: this.sourceCategoryPopover.selected.map((el) => el.id)
        }),
        ...(this.needTagKey && { tagKey: this.selectedTag }),
        ...((this.selectedType === 'keyword' && !this.isAssocs && this.level === 'industry') && {
          metric: this.selectedIndex,
          size: this.selectedCount,
          ...(this.blockWords.length > 0 && {
            disableTerm: this.blockWords.map((el) => el.name)
          })
        }),
        ...(this.isTrendWord && {
          metric: 'frequency',
          size: 20
        })
      };

      const date = {
        gte: infoHeaderViewModel.multiViewModel.currentDate[0].format('YYYY-MM-DD'),
        lte: infoHeaderViewModel.multiViewModel.currentDate[1].format('YYYY-MM-DD')
      };

      if (this.isSpecialWordItem) {
        if (this.level === 'brand') {
          if (this.level !== this.chartItems.level) {
            const { data } = await ChartService.getTopTenBrand({ ...params, date });
            runInAction(() => {
              this.chartItems = new WordChartItemViewModel(data, this);
            });
          } else {
            this.chartItems.onKeywordTypeChange();
          }
        }

        if (this.level === 'series') {
          if (this.level !== this.chartItems.level || this.selectedTag !== this.selectedTagCache) {
            const { data } = await ChartService.getTopTenSeriesForWords({ ...params, date });
            runInAction(() => {
              this.selectedTagCache = this.selectedTag;
              this.chartItems = new WordChartItemViewModel(data, this);
            });
          } else {
            this.chartItems.onKeywordTypeChange();
          }
        }

        if (this.level === 'product') {
          if (this.level !== this.chartItems.level) {
            const { data } = await ChartService.getTopTwentyProduct({ ...params, date });
            runInAction(() => {
              this.chartItems = new WordChartItemViewModel(data, this);
            });
          } else {
            this.chartItems.onKeywordTypeChange();
          }
        }
      } else {
        const { data } = await ChartAnalysisService.getGraphicData(this.level, this.selectedType, this.selectedSubtype, this.selectedSubChartType, { params });
        runInAction(() => {
          this.chartItems = new ChartItemViewModel(data, this, needPreserveTabId ? this.chartItems.activeTabId : null);
        });
      }

    } catch (error) {
      console.log('error', error);
      message.error('圖表資料異常，請稍後再試');
      runInAction(() => {
        this.chartItems = new ChartItemViewModel({ chartData: [{ id: 'nodata', name: getLevelText(this.level), data: [{ type: 'nodata', subType: 'ana-error' }] }] }, this);
      });
    } finally {
      runInAction(() => {
        optionsStore.setCompleted('chartData');
      });
    }
  };

  @action getBlockWords = async () => {
    optionsStore.setLoading('blockedWord');
    try {
      const { list } = await BlockedWordsService.getBlockedWords();
      runInAction(() => {
        this.blockWords = list;
        this.cacheBlockWords = list;
      });
    } catch (error) {
      console.log(error);
    } finally {
      optionsStore.setCompleted('blockedWord');
    }
  };

  @action onBlockModalOpen = async () => {
    await this.getBlockWords();
    runInAction(() => {
      this.isBlockModalOpen = true;
    });
  };

  @action onBlockModalClose = () => {
    this.isBlockModalOpen = false;
    if (this.blockWords.length !== this.cacheBlockWords.length) {
      if (this.isSpecialWordItem) {
        this.chartItems.getData(this.chartItems.activeTabId);
      } else {
        this.getGraphicData();
      }
    }
  };

  @action onRemoveWord = async (id) => {
    try {
      await BlockedWordsService.removeBlockedWords(id);
      runInAction(() => {
        this.blockWords = this.blockWords.filter((el) => el.id !== id);
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action onDateSelect = async () => {
    this.sourceCategoryPopover.onCleanAll();

    await Promise.all([
      this.getGraphicData(),
      this.getSource()
    ]);

  };
}
