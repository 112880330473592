import { makeObservable, observable, action, computed, reaction } from 'mobx';
import packageStore from 'src/stores/packageStore';

import CheckItemViewModel from './CheckItem/viewModel';

export default class SingleSelectModalViewModel {
  @observable isModalOpen = false;

  @observable series = [];
  @observable selectedParent = null;
  @observable selectedParentCache = null;
  @observable seriesKeyword = '';
  @observable seriesKeywordCache = '';

  @computed get selectedSeriesParentText() {
    return this.selectedParent ? `${this.seriesTarget.name}「${this.selectedChildren.map((el) => el.name).join(', ')}」` : '尚未選擇';
  }

  @computed get selectedChildren() {
    return this.seriesTarget?.children.filter((el) => el.checked);
  }

  @computed get disabledConfirm() {
    return !this.selectedParent || !this.seriesTarget?.children.some((el) => el.checkedCache);
  }

  @computed get seriesTarget() {
    return this.series.find((el) => el.id === this.selectedParent);
  }

  @computed get searchSeries() {
    return this.series.filter((el) => el.name.toLowerCase().includes(this.seriesKeyword.toLowerCase()) || el.children.some((item) => item.name.toLowerCase().includes(this.seriesKeyword.toLowerCase())));
  }

  constructor() {
    makeObservable(this);

    this.init();
  }

  @action init = () => {
    if (packageStore.series.length > 0) {
      const {
        series
      } = packageStore;

      this.series = series.map((el) => ({
        ...el,
        children: el.children.map((c) => new CheckItemViewModel(c))
      }));

    } else {
      const once = reaction(
        () => packageStore.isLevelProcessing,
        async (bool) => {
          if (!bool) {
            const {
              series
            } = packageStore;

            this.series = series.map((el) => ({
              ...el,
              children: el.children.map((c) => new CheckItemViewModel(c))
            }));
            once();
          }
        }
      );
    }
  };

  // > modal related

  @action onModalOpen = () => {
    this.isModalOpen = true;
  };

  @action onModalClose = () => {
    this.isModalOpen = false;
  };

  // > actions related

  @action onCleanAll = () => {
    if (this.seriesTarget) {
      this.seriesTarget.children.forEach((el) => el.setSelect(false));
    }

    this.selectedParent = null;
  };

  @action onSubmit = () => {
    this.seriesTarget.children.forEach((el) => {
      el.confirmSelect();
    });

    this.selectedParentCache = this.selectedParent;

    this.onModalClose();
  };

  @action onClose = () => {
    const prev = this.series.find((el) => el.id === this.selectedParentCache);
    if (prev) {
      prev.children.forEach((el) => el.onDisposeSelect());
    }

    if (this.selectedParent !== this.selectedParentCache && this.seriesTarget) {
      this.seriesTarget.children.forEach((el) => el.setSelect(false));
    }
    this.selectedParent = this.selectedParentCache;

    this.onModalClose();
  };


  // > series related

  @action onParentSelect = (id) => {
    if (id === this.selectedParent) {
      return;
    }
    if (this.seriesTarget) {
      this.seriesTarget.children.forEach((el) => el.setSelect(false));
    }

    this.selectedParent = id;
  };

  @action onSeriesSelect = (e) => {
    this.selectedSeries = e.target.value;
  };

  // > search

  @action onSeriesKeywordChange = (e) => {
    this.seriesKeywordCache = e.target.value;
    if (!e.target.value.trim()) {
      this.onSeriesSearchSubmit();
    }
  };

  @action onSeriesSearchSubmit = () => {
    this.seriesKeyword = this.seriesKeywordCache;
  };

  @action onSeriesKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onSeriesSearchSubmit();
    }
  };
}
