import { COLOR_SET } from 'src/consts/chart';
import { generateCompletedDayArray, getTotalDays, generateCSVKey } from 'src/utils/chart';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';


export default class SeriesBrandVolumeTrendModel {
  constructor(origin, prevOrigin, query) {
    if (origin.length === 0 && prevOrigin.length === 0) {
      this.data = {
        chartData: [{
          id: '',
          name: '系列',
          data: [
            {
              labels: [],
              datasets: [],
              type: 'nodata',
              subType: 'ana-error'
            },
            ...(
              infoHeaderViewModel.multiViewModel.hasCompare
                ? [{
                  labels: [],
                  datasets: [],
                  type: 'nodata',
                  subType: 'ana-error'
                }]
                : []
            )
          ]
        }]
      };
      return;
    }

    const data = SeriesBrandVolumeTrendModel.generateData(origin);
    const previousData = SeriesBrandVolumeTrendModel.generateData(prevOrigin, 'previous');
    const seriesList = SeriesBrandVolumeTrendModel.generateList(data, previousData);
    const csvForAll = [];

    seriesList.forEach((series) => {
      const targetInCurrent = data?.find((el) => series.id === el.id);
      const targetInPrev = previousData?.find((el) => series.id === el.id);
      const csv = [];
      SeriesBrandVolumeTrendModel.pushCSV(targetInCurrent, csv);
      SeriesBrandVolumeTrendModel.pushCSV(targetInPrev, csv);
      const csvKey = generateCSVKey(csv[0]);

      csvForAll.push({
        id: series.id,
        name: series.name,
        csv,
        csvKey
      });
    });

    this.data = {
      chartData: seriesList.slice(0, 10).map((series) => {
        const targetInCurrent = data?.find((el) => series.id === el.id);
        const currentEmpty = targetInCurrent ? SeriesBrandVolumeTrendModel.emptyCheck(targetInCurrent) : true;
        const targetInPrev = previousData?.find((el) => series.id === el.id);
        const prevEmpty = targetInPrev ? SeriesBrandVolumeTrendModel.emptyCheck(targetInPrev) : true;
        const { csv, csvKey } = csvForAll.find((el) => el.id === series.id);

        return {
          id: series.id,
          name: series.name,
          data: [
            ...(
              !currentEmpty
                ? [{
                  labels: targetInCurrent.item[0].series.map((d) => d.date),
                  datasets: targetInCurrent.item.slice(0, 10).map((b, i) => ({
                    label: b.name,
                    accurate: targetInCurrent.item[0].series.map((d) => d.accurate ?? d.date),
                    brand: { id: b.id, name: b.name },
                    data: b.series.map((s) => s.count),
                    borderColor: COLOR_SET[i % 20]
                  }))
                }]
                : [{
                  type: 'nodata',
                  subType: targetInCurrent ? 'default' : 'ana-error'
                }]
            ),
            ...(
              !prevEmpty
                ? [{
                  labels: targetInPrev.item[0].series.map((d) => d.date),
                  datasets: targetInPrev.item.slice(0, 10).map((b, i) => ({
                    label: b.name,
                    accurate: targetInPrev.item[0].series.map((d) => d.accurate ?? d.date),
                    brand: { id: b.id, name: b.name },
                    data: b.series.map((s) => s.count),
                    borderColor: COLOR_SET[i % 20]
                  }))
                }]
                : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{
                        type: 'nodata',
                        subType: targetInPrev ? 'default' : 'ana-error'
                      }]
                      : []
                  )
                ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType,
                category,
                tagKey
              } = query;
              const { datasetIndex, index } = elements[0];
              const [gte, lte] = chart.data.datasets[datasetIndex].accurate[index].split('~');
              const brand = chart.data.datasets[datasetIndex].brand;
              const womCount = chart.data.datasets[datasetIndex].data[index];

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category ? {
                  category: category.map((el) => ({
                    id: el,
                    name: SOURCE_CATEGORY_TYPE[el]
                  }))
                } : {
                  category: SOURCE_CATEGORY.map((el) => ({
                    id: el.value,
                    name: el.label
                  }))
                }),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/系列/聲量分析/各別系列品牌/系列品牌聲量趨勢'
                },
                brand: [{
                  id: brand.id,
                  name: brand.name
                }],
                series: [{
                  id: series.id,
                  name: series.name,
                  tagKey,
                  tagKeyName: packageStore.series.find((el) => el.id === tagKey).name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'line',
          options: {
            responsive: true,
            event: ['click'],
            tension: 0.3,
            scales: {
              x: {
                title: {
                  display: true,
                  text: '日期'
                }
              },
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: '聲量'
                },
                grace: 5
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        };
      }),
      csvForAll
    };
  }

  static generateData = (list, time = 'present') => {
    if (list.length === 0) {
      return null;
    }
    const totalDays = getTotalDays(time);
    return list.map((ori) => {
      const items = ori.item.map((item) => {
        if (totalDays >= 32 && totalDays <= 90) {
          const dayArr = generateCompletedDayArray(time);
          item.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...item,
            series: dayArr.map((el) => ({
              date: el.id,
              count: el.count
            }))
          };
        }

        if (totalDays >= 91) {
          const dayArr = generateCompletedDayArray(time);
          item.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...item,
            series: dayArr.map((el) => ({
              date: el.id,
              accurate: `${el.days[0]}~${el.days[el.days.length - 1]}`,
              count: el.count
            }))
          };
        }

        const dayArr = generateCompletedDayArray(time);
        return {
          ...item,
          series: dayArr.map((d) => ({
            date: d,
            count: item.series.find((s) => s.date === d)?.count ?? 0
          }))
        };
      });
      return {
        ...ori,
        item: items
      };
    });
  };

  static generateList = (list, prevList) => {
    const brandList = [];
    list?.forEach((el) => {
      const target = brandList.find((brand) => brand.id === el.id);
      if (!target) {
        brandList.push({ id: el.id, name: el.name });
      }
    });
    // prevList?.forEach((el) => {
    //   const target = brandList.find((brand) => brand.id === el.id);
    //   if (!target) {
    //     brandList.push({ id: el.id, name: el.name });
    //   }
    // });
    return brandList;
  };

  static pushCSV = (data, csv) => {
    if (data) {
      const rows = data.item[0]?.series?.map((d) => {
        const row = {
          date: d.date
        };
        for (let i = 0; i < data.item.length; i += 1) {
          row[`${data.item[i].name}`] = data.item[i].series.find((s) => s.date === d.date).count;
        }
        return row;
      }) ?? [];
      csv.push(...rows);
    }
  };

  static emptyCheck = (data) => {
    return data.item.every((el) => el.series.every((date) => date.count === 0));
  };

  static fromRes(data, prevOrigin, query) {
    return new SeriesBrandVolumeTrendModel(data, prevOrigin, query);
  }
}
