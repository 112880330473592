import { makeObservable, observable, action, computed } from 'mobx';

import CheckItemViewModel from '../CheckItem/viewModel';

export default class CheckGroupItemViewModel {
  parent = {};
  @observable id = '';
  @observable level = '';
  @observable name = '';
  @observable children = [];

  @observable keyword = '';

  @computed get groupIndeterminate() {
    return this.keywordChildren.some((el) => el.checkedCache) && !this.groupAllSelected;
  }

  @computed get groupAllSelected() {
    return this.keywordChildren.every((el) => el.checkedCache);
  }

  @computed get checkedText() {
    // TODO: 優化
    const isSelected = this.children.filter((el) => el.checked).map((el) => el.name);
    return isSelected.length >= 1 ? `${this.name}「${isSelected.join(',')}」` : '';
  }

  @computed get selectedItem() {
    // TODO: 優化
    return this.children.filter((el) => el.checkedCache).map((el) => el.id);
  }

  @computed get SelectedWithParent() {
    // TODO: 優化
    return this.children.filter((el) => el.checkedCache).map((el) => ({ parentId: this.id, id: el.id }));
  }

  @computed get filterSeriesChildren() {
    // TODO: 優化
    return (this.level === 'product' && this.parent.selectedSeries.length > 0)
      ? this.children.filter((el) => el.extraIds.some((id) => this.parent.selectedSeries.map((s) => s.id).includes(id)))
      : this.children;
  }

  @computed get keywordChildren() {
    if (this.name.toLowerCase().includes(this.keyword.toLowerCase())) {
      return this.filterSeriesChildren;
    }
    return this.filterSeriesChildren.filter((el) => el.name.toLowerCase().includes(this.keyword.toLowerCase()));
  }

  constructor(data, parent) {
    makeObservable(this);

    this.init(data, parent);
  }

  @action init = (data, parent) => {
    const {
      id,
      name,
      level,
      children
    } = data;

    this.id = id;
    this.name = name;
    this.level = level;
    this.parent = parent;
    this.children = children.map((el) => {
      return new CheckItemViewModel(el);
    });
  };

  @action onGroupSelect = (_, cb) => {
    if (!this.groupAllSelected) {
      this.keywordChildren.forEach((el) => {
        el.setSelect(true);
      });
      cb(this.id);
    } else {
      this.keywordChildren.forEach((el) => {
        el.setSelect(false);
      });
      cb(this.id, false);
    }
  };

  @action setKeyword = (e) => {
    this.keyword = e;
  };
}
