import { SOURCE_CATEGORY_TYPE, SOURCE_ORDER } from 'src/consts';

export default class PackageSourcesModel {
  constructor(data) {
    const sortData = data.sort((a, b) => {
      const indexA = SOURCE_ORDER.indexOf(a.category);
      const indexB = SOURCE_ORDER.indexOf(b.category);
      return indexA - indexB;
    });
    // TODO: 優化
    this.source = sortData.map((el) => ({
      id: el.category,
      name: SOURCE_CATEGORY_TYPE[el.category],
      children: el.website.map((w) => ({
        id: w.name,
        name: w.name,
        children: w.channel.map((c) => ({
          id: c.id,
          name: c.name
        }))
      }))
    }));
  }

  static fromRes(data) {
    return new PackageSourcesModel(data);
  }
}
