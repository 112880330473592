export default class ProductModel {
  constructor(data) {
    const { _id, name, checked } = data.keyword;
    const tagValueIds = data.tags?.map((el) => el.value) ?? [];

    this.id = _id;
    this.name = name;
    this.checked = checked ?? false;
    this.extraIds = tagValueIds;
  }

  static fromRes(data) {
    return new ProductModel(data);
  }
}
