import { COLOR_SET } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY } from 'src/consts';
import { generateCSVKey } from 'src/utils/chart';

export default class BrandSeriesVolumeCompareModel {
  constructor(data, query) {
    if (data.length === 0) {
      this.data = {
        chartData: [{
          id: 'series_brand_volume_compare',
          name: '系列品牌聲量堆疊',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }]
        }]
      };
      return;
    }

    if (data.every((el) => el.item.every((item) => item.count === 0))) {
      this.data = {
        chartData: [{
          id: 'brand_product_volume_compare',
          name: '系列品牌聲量堆疊',
          data: [{
            type: 'nodata'
          }]
        }]
      };
      return;
    }

    const colors = {};

    const formatData = data.slice(0, 10).map((brand) => ({
      ...brand,
      item: brand.item.slice(0, 10).map((item) => {
        const color = colors[item.name];
        if (!color) {
          colors[`${item.name}`] = COLOR_SET[Object.keys(colors).length % 20];
        }
        return {
          id: item.id,
          name: item.name,
          count: item.count,
          color: colors[item.name]
        };
      })
    }));

    const {
      postType,
      womType,
      tagKey
    } = query;

    const csv = data.map((el) => {
      const row = {
        系列名稱: el.name
      };
      for (let i = 0; i < el.item.length; i += 1) {
        row[`${el.item[i].name}`] = el.item[i].count ?? 0;
      }
      return row;
    });

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'brand_series_volume_compare',
          name: '品牌系列聲量堆疊',
          data: [formatData],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const series = chart.data.datasets[datasetIndex].item;
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const brandItem = chart.data.datasets[datasetIndex].mainItem[index];
              const [gte, lte] = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'));

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                category: SOURCE_CATEGORY.map((el) => ({
                  id: el.value,
                  name: el.label
                })),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '數據報告/品牌競爭分析/品牌系列聲量堆疊'
                },
                brand: [{
                  id: brandItem.id,
                  name: brandItem.name
                }],
                series: [{
                  id: series.id,
                  name: series.name,
                  tagKey,
                  tagKeyName: packageStore.series.find((el) => el.id === tagKey).name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          onPointHover: (elements, ref) => {
            const chart = ref.current;
            if (elements.length > 0) {
              const { datasetIndex } = elements[0];
              const hoverTarget = chart.data.datasets[datasetIndex].customName;
              chart.hovered = hoverTarget;
            } else {
              chart.hovered = null;
            }
            chart.update();
          },
          legend: 'topTenInAllGroup',
          type: 'customize-stack-bar',
          options: {
            event: ['click'],
            scales: {
              x: {
                stacked: true
              },
              y: {
                stacked: true,
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                display: false
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ],
      brand: data.map((el) => ({ id: el.id, name: el.name })),
      series: data[0].item.map((el) => ({ id: el.id, name: el.name }))
    };
  }

  static fromRes(data, query) {
    return new BrandSeriesVolumeCompareModel(data, query);
  }
}
