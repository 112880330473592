import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import i18n from 'src/i18n';
import { Tabs } from 'antd';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';

import ChartFactory from 'src/components/Charts/factory';
import ChartTimeStamp from 'src/components/ChartTimeStamp';
import NoDataChart from 'src/components/Charts/NoData';

import styles from './styles.module.scss';

@observer
class ChartItem extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <div className={clsx(styles.chartContainer)}>
        {
          viewModel.onReady
            ? (
              <>
                {
                  viewModel.data.length > 1
                  && (
                    <Tabs
                      activeKey={viewModel.activeTabId}
                      items={viewModel.data.map((el) => ({ key: el.id, label: el.name }))}
                      onChange={viewModel.onTabsChange}
                      className="chartTabs"
                    />
                  )
                }
                {
                  viewModel.showChart.map((el, i) => {
                    return (
                      <div key={el.key}>
                        <ChartTimeStamp isPresent={i === 0} isPrevious={i === 1} />
                        {
                          ChartFactory.generateChartView(el)
                        }
                      </div>
                    );
                  })
                }
              </>
            )
            : (
              <NoDataChart subChart={ChartFactory.generateNoDataChart(null)} />
            )
        }
      </div>
    );
  }
}

ChartItem.propTypes = {
  viewModel: PropTypes.object
};

ChartItem.defaultProps = {
  viewModel: {}
};


export default ChartItem;
