import { makeObservable, observable, action, computed } from 'mobx';

export default class FloatingScrollToViewModel {
  @observable parent = {};

  @observable keyword = '';
  @observable keywordCache = '';

  @observable selected = {};

  @observable open = false;

  @computed get searchRes() {
    return !this.keywordCache ? this.list : this.list.filter((el) => el.name.toLowerCase().includes(this.keyword.toLowerCase()));
  }

  @computed get list() {
    return this.parent.chartItems.filter((el) => el.viewModel.chartName).map((el) => ({
      id: el.id,
      name: el.viewModel?.chartName
    }));
  }

  constructor(parent) {
    makeObservable(this);

    this.init(parent);
  }

  @action init = (parent) => {
    this.parent = parent;
  };

  @action didMount = () => {
    const mainContainer = document.querySelector('.main');
    mainContainer.addEventListener('scroll', this.onCancel);
  };

  @action willUnmount = () => {
    const mainContainer = document.querySelector('.main');
    mainContainer.removeEventListener('scroll', this.onCancel);
  };

  @action onCancel = () => {
    this.open = false;
  };

  @action onOpenChange = (bool) => {
    this.open = bool;
  };

  @action onSelect = (e) => {
    this.selected = e;
    this.open = false;

    this.parent.onSelectCallback(this.selected);
  };

  @action onKeywordChange = (e) => {
    this.keyword = e.target.value;
  };

  @action onSubmit = () => {
    this.keywordCache = this.keyword;
  };

  @action onKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onSubmit();
    }
  };
}
