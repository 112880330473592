import { userVOCLoginAuth, userVocIdentity } from 'src/consts';
import dayjs from 'dayjs';

export default class UserInfoAuthModel {
  constructor(data) {
    const {
      user,
      dataPackages
    } = data;
    const {
      accessibleFeatures,
      dataPackageAmount,
      assignedSalesId,
      startDate,
      endDate,
      status,
      permission
    } = user;
    const {
      advancedSearch,
      analysisCharts,
      analysisLexicon,
      brandCompetitiveness,
      brandEffect,
      brandHealthCheck,
      brandReport,
      notification,
      market7p,
      marketDifference,
      marketingTrend,
      mindShare,
      publicOpinionSearch,
      relatedSearch,
      reputationAnalysis,
      starProduct,
      topic
    } = accessibleFeatures;

    //  > Auth related.
    this.search = publicOpinionSearch;
    this.topic = topic;
    this.chart = analysisCharts;
    this.wordbook = analysisLexicon;
    this.related = relatedSearch;
    this.advanceSearch = advancedSearch;
    this.notification = notification;

    this.dataReport = {
      industryTrend: marketingTrend,
      marketDifferentiation: marketDifference,
      brandCompetition: brandCompetitiveness,
      brandHealth: brandHealthCheck,
      brandEffect,
      brandRoutine: brandReport
    };

    this.advance = {
      marketing7P: market7p,
      mindShare,
      starProduction: starProduct,
      reputation: reputationAnalysis
    };

    // > voc
    this.vocLoginAuth = userVOCLoginAuth.find((e) => e.value === status).value;
    this.vocIdentity = userVocIdentity.find((e) => e.value === permission).value;
    this.salesman = assignedSalesId;
    this.accountValid = startDate ? [dayjs(startDate), dayjs(endDate)] : [];
    this.openPackages = dataPackageAmount;

    this.activePackages = dataPackages.length;

  }

  static fromRes(data) {
    return new UserInfoAuthModel(data);
  }
}
