import SeriesBrandVolumeTrendModel from './Trend';
import SeriesBrandVolumeRankingModel from './Ranking';
import SeriesBrandVolumeProportionModel from './Proportion';
import SeriesBrandVolumeCompareModel from './Compare';

export default {
  SeriesBrandVolumeTrendModel,
  SeriesBrandVolumeRankingModel,
  SeriesBrandVolumeProportionModel,
  SeriesBrandVolumeCompareModel
};
