import API from 'src/apis';
import ArticleModel from 'src/models/response/Article';

export default class AsiaService {
  static async getArticle() {
    const res = await API.asia.getArticle();

    return ArticleModel.fromRes(res.data);
  }
}
