import * as user from './user';
import * as announcement from './announcement';
import * as otherResource from './otherResource';
import * as packages from './package';
import * as bulletin from './bulletin';
import * as asia from './asia';
import * as source from './source';
import * as blockedwords from './blockedwords';
import * as advanceAnalysis from './advanceAnalysis';
import * as auth from './auth';
import * as opinion from './opinion';
import * as topic from './topic';
import * as wordbook from './wordbook';
import * as related from './relatedAnalysis';
import * as advancedSearch from './advanceSearch';

import dataReport from './dataReport';
import chartAnalysis from './chartAnalysis';

export default {
  user,
  announcement,
  otherResource,
  packages,
  bulletin,
  asia,
  source,
  blockedwords,
  advanceAnalysis,
  auth,
  opinion,
  topic,
  related,
  advancedSearch,
  chartAnalysis,
  wordbook,
  dataReport
};
