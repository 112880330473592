import React from 'react';
import { makeObservable, observable, action, computed } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

export default class CustomizeStackBarChartViewModel {
  type = 'customize-stack-bar';
  ref = React.createRef();
  @observable key = '';
  @observable name = '';
  @observable csv = [];
  @observable csvKey = [];
  @observable options = {};
  @observable origin = [];
  @observable blocks = [];
  @observable legendType = null;
  @observable time = '';

  @observable data = {
    labels: [],
    datasets: []
  };

  @computed get legend() {
    switch (this.legendType) {
      case 'topOneItemInGroup':
        return this.topOneItemInGroup;
      case 'topTenInAllGroup':
        return this.topTenInAllGroup;
      default:
        return [];
    }
  }

  @computed get itemList() {
    const list = [];
    let hasCus = false;
    this.origin.forEach((set) => {
      set.item.forEach((item) => {
        if (item.id === 'cusOthers') {
          hasCus = true;
          return;
        }
        const exist = list.find((i) => i.name === item.name);
        if (exist) {
          exist.count += item.count;
        } else {
          list.push({ id: item.id?.trim() ? item.id : item.name, name: item.name, count: item.count, color: item.color });
        }
      });
    });

    if (hasCus) {
      list.push({ id: 'cusOthers', name: '其他', color: '#ababab' });
    } else if (list.find((el) => el.id === '其他')) {
      const others = list.find((el) => el.name === '其他');
      const othersIndex = list.findIndex((el) => el.name === '其他');
      list.splice(othersIndex, 1);
      list.push({ ...others, color: '#ababab' });
    }

    return list;
  }

  @computed get topTenInAllGroup() {
    return this.itemList.slice().filter((el) => el.id !== 'cusOthers').sort((a, b) => b.count - a.count).slice(0, 10);
  }

  @computed get topOneItemInGroup() {
    return this.origin
      .map((el) => el.item.slice().filter((item) => item.name !== '其他').sort((a, b) => b.count - a.count)[0])
      .reduce((pv, cv) => {
        if (pv.find((value) => value.id === cv?.id)) {
          return pv;
        }
        if (cv) {
          pv.push(cv);
        }
        return pv;
      }, []);
  }

  @computed get chartData() {
    const blockSet = new Set(this.blocks);
    const mainItem = this.origin.map((item) => ({ id: item.id, name: item.name }));
    // > 以下以 品牌 x 頻道為例
    // > itemList -> 頻道總列表
    // > origin -> 品牌列表
    // > origin.item -> 該品牌的前二十頻道列表

    return {
      labels: this.origin.map((el) => el.name),
      datasets: this.itemList.map((el, i) => {
        const data = this.origin.map((origin) => {
          const itemMap = new Map(origin.item.map((subItem) => [subItem.name, subItem.count]));
          if (blockSet.has(el.id)) {
            return 0;
          }

          return itemMap.get(el.name) || 0;
        });

        return {
          label: '',
          data,
          customName: el.name,
          mainItem,
          item: { id: el.id, name: el.name },
          time: this.time,
          backgroundColor: (context) => {
            const { chart, datasetIndex } = context;
            const { color, name } = this.itemList[datasetIndex];
            if (!chart.hovered) {
              return color;
            }
            return chart.hovered === name ? color : '#d9d9d9';
          },
          borderSkipped: true,
          maxBarThickness: 50,
          tooltip: {
            callbacks: {
              label: (context) => {
                const { datasetIndex, formattedValue } = context;
                return `${this.itemList[datasetIndex].name}: ${formattedValue}`;
              }
            }
          }
        };
      })
    };
  }

  constructor(content) {
    makeObservable(this);

    this.init(content);
  }

  @action init = (content) => {
    const { name, data, time, onPointClick, onPointHover, options, legend, csv, csvKey } = content;
    this.key = uuidv4();
    this.origin = data.map((el) => ({
      ...el,
      item: el.item.slice().sort((a, b) => b.count - a.count),
      total: el.item.reduce((accumulator, item) => accumulator + item.count, 0)
    })).sort((a, b) => b.total - a.total);
    this.time = time;
    this.options = {
      ...options,
      plugins: {
        ...options.plugins,
        legend: {
          ...options.plugins.legend,
          labels: {
            ...options.plugins.legend?.labels,
            color: '#000'
          }
        }
      },
      scales: {
        ...options.scales,
        x: {
          ...options.scales.x,
          title: {
            ...options.scales.x?.title,
            color: '#000'
          },
          ticks: {
            ...options.scales.x?.ticks,
            color: '#000'
          }
        },
        y: {
          ...options.scales.y,
          title: {
            ...options.scales.y?.title,
            color: '#000'
          },
          ticks: {
            ...options.scales.y?.ticks,
            color: '#000'
          }
        }
      },
      maintainAspectRatio: false,
      onClick: (_, elements) => onPointClick(elements, this.ref.current),
      ...(onPointHover ? {
        onHover: (event, elements) => {
          onPointHover(elements, this.ref);
          const target = event.native.target;
          if (elements.length > 0) {
            target.style.cursor = 'pointer';
          } else {
            target.style.cursor = 'default';
          }
        }
      } : {
        onHover: (event, elements) => {
          const target = event.native.target;
          if (elements.length > 0) {
            target.style.cursor = 'pointer';
          } else {
            target.style.cursor = 'default';
          }
        }
      })
    };
    this.legendType = legend;
    this.name = name;
    this.csv = csv;
    this.csvKey = csvKey;
  };

  @action onBlock = (id) => {
    if (this.blocks.includes(id)) {
      this.blocks = this.blocks.filter((el) => el !== id);
    } else {
      this.blocks.push(id);
    }
  };
}

