import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import { CSVLink } from 'react-csv';

import { REPUTATION_OPTIONS, POST_TYPE, WORD_OF_MOUTH_TYPE } from 'src/consts';

import { CloseOutlined, CheckOutlined, CaretDownFilled, DownloadOutlined, SaveOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import Select from 'src/components/select';
import InfoHeader from 'src/components/InfoHeader';
import optionsStore from 'src/stores/optionsStore';
import SinglePopoverSelect from 'src/components/SinglePopoverSelect';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import ChartTimeStamp from 'src/components/ChartTimeStamp';
import withRouter from 'src/components/withRouter';
import packageStore from 'src/stores/packageStore';

import ChartItem from '../components/ChartItem';
import ReputationPageViewModel from './viewModel';

import styles from './styles.module.scss';


@observer
class ReputationPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new ReputationPageViewModel();
  }

  render() {
    const { router } = this.props;
    return (
      <div className={styles.pageContainer}>
        <InfoHeader
          breadcrumb={['進階分析', '聲譽定位分析']}
          callback={this.viewModel.onDateSelect}
          isMultiPeriod
        />
        <div className={styles.segmentContainer}>
          {
            REPUTATION_OPTIONS.filter((el) => packageStore.opened.includes(el.id)).map((el) => {
              return (
                <div
                  key={el.id}
                  className={clsx(
                    styles.segmentItem,
                    this.viewModel.selectedChart === el.id && styles.active
                  )}
                  onClick={() => this.viewModel.onChartSelect(el.id, router)}
                >
                  {el.name}
                </div>
              );
            })
          }
        </div>
        <div className={clsx(styles.resContainer)}>
          <div className={styles.actionsContainer}>
            <div className={styles.filter}>
              <div className={styles.filterTitle}>
                {i18n.t('chart_page_statistic_filter')}
              </div>
              <Select
                placeholder={i18n.t('filter_post_type_all')}
                options={POST_TYPE}
                value={this.viewModel.selectedPostType}
                onSelect={this.viewModel.onPostTypeChange}
                popupMatchSelectWidth={false}
                popupClassName={styles.sortMenu}
                className={clsx('sortSelect', styles.filterSelect)}
                suffixIcon={<CaretDownFilled style={{ pointerEvents: 'none' }} />}
              />
              <Select
                placeholder={i18n.t('filter_word_of_mouth_title')}
                options={WORD_OF_MOUTH_TYPE}
                value={this.viewModel.selectedWOM}
                onSelect={this.viewModel.onWOMChange}
                popupMatchSelectWidth={false}
                popupClassName={styles.sortMenu}
                className={clsx('sortSelect', styles.filterSelect)}
                suffixIcon={<CaretDownFilled style={{ pointerEvents: 'none' }} />}
              />
              <SinglePopoverSelect
                viewModel={this.viewModel.selectedChart === 'brand' ? this.viewModel.brandPopover : this.viewModel.productPopover}
                title={this.viewModel.selectedChart === 'brand' ? '選擇品牌' : '選擇產品'}
              />
              <Button
                className={clsx(styles.btn, styles.submitBtn)}
                icon={<CheckOutlined />}
                onClick={this.viewModel.onSubmit}
              >
                {i18n.t('btn_filter_submit')}
              </Button>
              {
                this.viewModel.isShowStatisticClean
                && (
                  <Button
                    className={clsx(styles.btn, styles.cleanBtn)}
                    icon={<CloseOutlined />}
                    type="link"
                    onClick={this.viewModel.onCleanStatisticFilter}
                  >
                    {i18n.t('btn_filter_clean_all')}
                  </Button>
                )
              }
            </div>
            <div className={styles.btnContainer}>
              <Tooltip
                title="下載數據"
                placement="bottom"
              >
                <Button
                  icon={<DownloadOutlined />}
                  onClick={this.viewModel.onDownloadCsv}
                />
              </Tooltip>
              <Tooltip
                title="下載圖表"
                placement="bottom"
              >
                <Button
                  icon={<SaveOutlined />}
                  onClick={this.viewModel.onDownload}
                />
              </Tooltip>
              {
                this.viewModel.isDownload
                && (
                  <CSVLink
                    className="chartDataDownload"
                    data={this.viewModel.downloadList}
                    filename={this.viewModel.downloadFileName}
                    style={{ display: 'none' }}
                  />
                )
              }
            </div>
          </div>
          <div className="download-chart">
            <div className={styles.chartTitle}>
              <span>
                {this.viewModel.chartTitle}
              </span>
              <span
                className={styles.tooltip}
                style={{ display: optionsStore.isImgDownload ? 'none' : 'inline-block' }}
              >
                <Tooltip
                  title={(
                    <div className={styles.descContainer}>
                      {this.viewModel.chartDesc}
                    </div>
                  )}
                  placement="rightTop"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            </div>
            <div ref={this.viewModel.ref}>
              <div className={styles.timeContainer}>
                <ChartTimeStamp isPresent />
              </div>
              <ChartItem
                viewModel={this.viewModel.selectedChart === 'brand' ? this.viewModel.brandChart : this.viewModel.productChart}
              />
              {
                infoHeaderViewModel.multiViewModel.hasCompareCache
                && (
                  <>
                    <div className={styles.timeContainer}>
                      <ChartTimeStamp isPrevious />
                    </div>
                    <ChartItem
                      viewModel={this.viewModel.selectedChart === 'brand' ? this.viewModel.brandPreviousChart : this.viewModel.productPreviousChart}
                    />
                  </>
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReputationPage.propTypes = {
  router: PropTypes.object
};

ReputationPage.defaultProps = {
  router: {}
};


export default withRouter(ReputationPage);
