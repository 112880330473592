import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import i18n from 'src/i18n';
import { Tabs, Modal, Button, Tooltip } from 'antd';
import { CloseOutlined, RollbackOutlined, DownloadOutlined, SaveOutlined, StopOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';

import ChartTimeStamp from 'src/components/ChartTimeStamp';
import ChartFactory from 'src/components/Charts/factory';
import { TREND_ITEMS } from 'src/consts/chart';
import NoDataChart from 'src/components/Charts/NoData';
import optionsStore from 'src/stores/optionsStore';

import styles from './styles.module.scss';

@observer
class WordChartItem extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <>
        {
          viewModel?.onReady
            ? (
              <div className={clsx(styles.chartContainer)}>
                {
                  viewModel.showDiscover
                    ? (
                      <div className={clsx(styles.downloadContainer, 'download-chart')}>
                        <Button
                          className={styles.discoverBack}
                          onClick={viewModel.onDiscoverLeave}
                          icon={<RollbackOutlined />}
                        >
                          {`返回${viewModel.showData?.name}文字雲`}
                        </Button>
                        <div className={clsx(styles.chartHeader)}>
                          <div className={styles.chartTitle}>
                            {`「${viewModel.discoverName}」文字雲下探`}
                          </div>
                          <div className={styles.downloadBtns}>
                            {
                              viewModel.isDownload
                              && (
                                <CSVLink
                                  className="chartDataDownload"
                                  data={viewModel.downloadList}
                                  filename={viewModel.downloadFileName}
                                  style={{ display: 'none' }}
                                />
                              )
                            }
                            {
                              (!viewModel.parent.isAssocs && !viewModel.parent.isTrendWord)
                              && (
                                <Button
                                  icon={<StopOutlined />}
                                  onClick={viewModel.parent.onBlockModalOpen}
                                  style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
                                />
                              )
                            }
                            <Button
                              icon={<DownloadOutlined />}
                              onClick={viewModel.onDownloadExcel}
                              style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
                            />
                            <Button
                              icon={<SaveOutlined />}
                              onClick={viewModel.onDownload}
                              style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
                            />
                          </div>
                        </div>
                        <ChartTimeStamp isPresent={viewModel.discoverWords?.timeSec === 'present'} isPrevious={viewModel.discoverWords?.timeSec === 'previous'} />
                        <div key={viewModel.discoverWords.key}>
                          {
                            ChartFactory.generateChartView(viewModel.discoverWords)
                          }
                        </div>
                      </div>
                    )
                    : (
                      <>
                        {
                          (viewModel.data.length > 0 && viewModel.parent.needChartTabs && !viewModel.notAllowTab)
                          && (
                            <div className={styles.tabContainer}>
                              <Tabs
                                activeKey={viewModel.activeTabId}
                                items={viewModel.data.map((el) => ({ key: el.id, label: el.name }))}
                                onChange={viewModel.onTabsChange}
                                className="chartTabs"
                              />
                            </div>
                          )
                        }
                        <div className={clsx(styles.downloadContainer, 'download-chart')}>
                          <div className={clsx(styles.chartHeader)}>
                            <div className={styles.chartTitle}>
                              <span className={styles.titleText}>
                                {viewModel.chartTitle}
                              </span>
                              {

                              }
                              <span
                                className={styles.tooltip}
                                style={{ display: optionsStore.isImgDownload ? 'none' : 'inline-block' }}
                              >
                                <Tooltip
                                  title={viewModel.parent.chartDesc}
                                  placement="right"
                                >
                                  <QuestionCircleOutlined />
                                </Tooltip>
                              </span>
                            </div>
                            <div className={styles.downloadBtns}>
                              {
                                (viewModel.parent.selectedType === 'keyword' && !viewModel.parent.isAssocs && !viewModel.parent.isTrendWord)
                                && (
                                  <Tooltip
                                    title="查看屏蔽名單"
                                    placement="bottom"
                                  >
                                    <Button
                                      icon={<StopOutlined />}
                                      onClick={viewModel.parent.onBlockModalOpen}
                                      style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
                                    />
                                  </Tooltip>
                                )
                              }
                              <Tooltip
                                title="下載數據"
                                placement="bottom"
                              >
                                <Button
                                  icon={<DownloadOutlined />}
                                  onClick={viewModel.onDownloadExcel}
                                  style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
                                />
                              </Tooltip>
                              <Tooltip
                                title="下載圖檔"
                                placement="bottom"
                              >
                                <Button
                                  icon={<SaveOutlined />}
                                  onClick={viewModel.onDownload}
                                  style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
                                />
                              </Tooltip>
                            </div>
                            {
                              viewModel.parent.selectedSubtypeInfo.children
                              && (
                                <div className={styles.chartTypeContainer}>
                                  {
                                    viewModel.parent.selectedSubtypeInfo.children.map((el) => {
                                      switch (el.type) {
                                        case 'line':
                                          return (
                                            <Tooltip
                                              title="趨勢圖"
                                              placement="bottom"
                                              key={el.id}
                                            >
                                              <Button
                                                key={el.id}
                                                icon={<div className={styles.line} />}
                                                onClick={() => viewModel.parent.onSubChartTypeChange(el.id)}
                                                className={viewModel.parent.selectedSubChartType === el.id ? styles.active : styles.inactive}
                                              />
                                            </Tooltip>
                                          );
                                        case 'bar':
                                          return (
                                            <Tooltip
                                              title="長條圖"
                                              placement="bottom"
                                              key={el.id}
                                            >
                                              <Button
                                                key={el.id}
                                                icon={<div className={styles.bar} />}
                                                onClick={() => viewModel.parent.onSubChartTypeChange(el.id)}
                                                className={viewModel.parent.selectedSubChartType === el.id ? styles.active : styles.inactive}
                                              />
                                            </Tooltip>
                                          );
                                        case 'pie':
                                          return (
                                            <Tooltip
                                              title="圓餅圖"
                                              placement="bottom"
                                              key={el.id}
                                            >
                                              <Button
                                                key={el.id}
                                                icon={<div className={styles.pie} />}
                                                onClick={() => viewModel.parent.onSubChartTypeChange(el.id)}
                                                className={viewModel.parent.selectedSubChartType === el.id ? styles.active : styles.inactive}
                                              />
                                            </Tooltip>
                                          );
                                        case 'compare':
                                          return (
                                            <Tooltip
                                              title="堆疊圖"
                                              placement="bottom"
                                              key={el.id}
                                            >
                                              <Button
                                                key={el.id}
                                                icon={<div className={styles.compare} />}
                                                onClick={() => viewModel.parent.onSubChartTypeChange(el.id)}
                                                className={clsx(viewModel.parent.selectedSubChartType === el.id ? styles.active : styles.inactive, styles.compare)}
                                              />
                                            </Tooltip>
                                          );
                                        default:
                                          return null;
                                      }
                                    })
                                  }
                                </div>
                              )
                            }
                          </div>
                          {
                            viewModel.showChart?.map((el, i) => {
                              return (
                                <div key={el.key}>
                                  <ChartTimeStamp isPresent={i === 0} isPrevious={i === 1} />
                                  {
                                    viewModel.parent?.isTrendWord && el.type !== 'nodata'
                                    && (
                                      <div className={styles.trendTagContainer}>
                                        {
                                          TREND_ITEMS.map((trend) => {
                                            return (
                                              <div
                                                className={styles.trendTag}
                                                key={trend.id}
                                              >
                                                <div
                                                  className={clsx(styles.tagColor)}
                                                  style={{
                                                    background: trend.color
                                                  }}
                                                />
                                                <div>
                                                  {trend.name}
                                                </div>
                                              </div>
                                            );
                                          })
                                        }
                                      </div>
                                    )
                                  }
                                  {
                                    ChartFactory.generateChartView(el)
                                  }
                                </div>
                              );
                            })
                          }
                        </div>
                      </>
                    )
                }
              </div>
            )
            : (
              <NoDataChart subChart={ChartFactory.generateNoDataChart(null)} />
            )
        }
        <Modal
          open={viewModel.isModalOpen}
          onCancel={viewModel.onModalClose}
          width={500}
          centered
          closable={false}
          footer={null}
          className={styles.modal}
          bodyStyle={{
            height: 160,
            display: 'flex',
            flexDirection: 'column',
            rowGap: 36,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div className={styles.modalHeader}>
            <div className={styles.title}>
              {i18n.t('wordbook_page_modal_content').replace('%word%', viewModel.wordsViewModel?.selectedName)}
            </div>
            <CloseOutlined className={styles.close} onClick={viewModel.onModalClose} />
          </div>
          <div className={clsx(styles.btnContainer, (viewModel.parent?.isHotWord && !viewModel.showDiscover) && styles.isHotWord)}>
            <Button
              className={clsx(styles.btn, styles.normalBtn)}
              onClick={viewModel.onTopicsClick}
            >
              {i18n.t('wordbook_page_modal_action_topic')}
            </Button>
            {
              viewModel.parent?.isHotWord && !viewModel.showDiscover
              && (
                <Button
                  className={clsx(styles.btn, styles.normalBtn)}
                  onClick={viewModel.onDiscoverClick}
                >
                  {i18n.t('wordbook_page_modal_action_further')}
                </Button>
              )
            }
            <Button
              className={clsx(styles.btn, styles.blockBtn)}
              onClick={() => viewModel.onWordBlock(viewModel.wordsViewModel?.selectedName)}
            >
              {i18n.t('wordbook_page_modal_action_block')}
            </Button>
          </div>
        </Modal>
      </>

    );
  }
}

WordChartItem.propTypes = {
  viewModel: PropTypes.object
};

WordChartItem.defaultProps = {
  viewModel: {}
};


export default WordChartItem;
