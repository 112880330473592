import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import dayjs from 'dayjs';

import AnnouncementService from 'src/services/announcement';
import OtherResourceService from 'src/services/otherResource';
import { HOME_PAGE_DRAWER, SOURCE_CATEGORY_TYPE } from 'src/consts';

import AsiaService from 'src/services/asia';
import SourceService from 'src/services/source';
import AnnouncementItemViewModel from './components/AnnouncementItem/viewModel';
import ServiceItemViewModel from './components/ServiceItem/viewModel';

export default class HomePageViewModel {
  @observable announcementList = [];
  @observable isDrawerOpen = false;
  @observable drawerType = HOME_PAGE_DRAWER.Announcement; // > enum: 'announcement', 'service'
  @observable serviceList = [];
  @observable articles = [];

  @observable sourceStatics = [];

  @observable isShowDeleteModal = false;
  @observable aboutDeleteType = null;
  @observable aboutDeleteId = null;

  @observable isBrowserTabVisible = false;

  @observable readyStatus = {
    source: false,
    article: false,
    service: false
  };


  @computed get showAnnouncement() {
    return this.announcementList.filter((el) => el.id !== 'newPost' && el.isShowCache);
  }

  @computed get onNewAnnouncement() {
    return this.announcementList.find((el) => el.id === 'newPost');
  }

  @computed get showService() {
    return this.serviceList.filter((el) => el.id !== 'newPost');
  }

  constructor() {
    makeObservable(this);

    this.init();
  }

  @action init = async () => {
    await Promise.all([
      this.getAnnouncements(),
      this.getServices(),
      this.getArticle(),
      this.getSourceStatistics()
    ]);
  };

  @action getAnnouncements = async () => {
    try {
      const { list } = await AnnouncementService.getAnnouncementList();

      runInAction(() => {
        this.announcementList = list.map((el) => new AnnouncementItemViewModel(el));
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action getServices = async () => {
    try {
      const { list } = await OtherResourceService.getOtherResource();
      runInAction(() => {
        this.serviceList = list.map((el) => new ServiceItemViewModel(el));
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action onDrawerOpen = () => {
    this.isDrawerOpen = true;
  };

  @action onDrawerClose = () => {
    this.isDrawerOpen = false;
  };

  @action onAnnouncementSetting = () => {
    this.drawerType = HOME_PAGE_DRAWER.Announcement;
    this.onDrawerOpen();
  };

  @action onServiceSetting = () => {
    this.drawerType = HOME_PAGE_DRAWER.Service;
    this.onDrawerOpen();
  };

  @action onDeleteModal = (type, id) => {
    this.aboutDeleteType = type;
    this.aboutDeleteId = id;

    this.isShowDeleteModal = true;
  };

  @action onDeleteContent = async () => {
    switch (this.aboutDeleteType) {
      case 'announcement':
        await this.onDeleteAnnouncement(this.aboutDeleteId);
        break;
      case 'service':
        await this.onDeleteService(this.aboutDeleteId);
        break;
      default:
        break;
    }

    runInAction(() => {
      this.isShowDeleteModal = false;
    });
  };

  @observable onCancelDelete = () => {
    this.isShowDeleteModal = false;

    this.aboutDeleteType = null;
    this.aboutDeleteId = null;
  };

  // > announcement

  @action onAddAnnouncement = () => {
    const target = new AnnouncementItemViewModel({ isShow: false, date: dayjs().toISOString(), text: '' });
    this.announcementList.unshift(target);
    target.onEdit();
  };

  @action onRefreshAnnouncement = async () => {
    await this.getAnnouncements();
  };

  @action onDisposeNewAnnouncement = () => {
    this.announcementList.shift();
  };

  @action onDeleteAnnouncement = async (id) => {
    try {
      await AnnouncementService.deleteAnnouncement(id);

      runInAction(() => {
        this.announcementList = this.announcementList.filter((el) => el.id !== id);
      });
    } catch (error) {
      console.log(error);
    }
  };

  // > article

  @action getArticle = async () => {
    try {
      const { articles } = await AsiaService.getArticle();

      runInAction(() => {
        this.articles = [...articles];
        this.readyStatus.article = true;
      });

    } catch (error) {
      console.log(error);
    }
  };

  // > source
  @action getSourceStatistics = async () => {
    try {
      const res = await SourceService.getSourceStatistics();
      runInAction(() => {
        this.sourceStatics = Object.keys(res).map((el) => ({
          id: el,
          title: SOURCE_CATEGORY_TYPE[el],
          count: res[el]
        }));
        this.readyStatus.source = true;
      });

    } catch (error) {
      console.log(error);
    }
  };

  // > service

  @action onAddService = () => {
    const target = new ServiceItemViewModel({ name: '', date: dayjs().toISOString(), text: '' });
    this.serviceList.unshift(target);
    target.onEdit();
  };

  @action onRefreshService = async () => {
    await this.getServices();
  };

  @action onDisposeService = () => {
    this.serviceList.shift();
  };

  @action onDeleteService = async (id) => {
    try {
      await OtherResourceService.deleteOtherResource(id);

      runInAction(() => {
        this.serviceList = this.serviceList.filter((el) => el.id !== id);
      });
    } catch (error) {
      console.log(error);
    }
  };

}
