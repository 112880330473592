export default class BlockedWordsModel {
  constructor(data) {
    this.list = data.map((el) => ({
      id: el._id,
      name: el.text
    }));
  }

  static fromRes(data) {
    return new BlockedWordsModel(data);
  }
}
