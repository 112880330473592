import MainLogo from 'src/assets/Logo.png';
import useMaintenance from './hooks/useMaintenance';

import styles from './styles.module.scss';

export default function MaintenancePage() {

  const { start, end } = useMaintenance();

  return (
    <div className={styles.pageContainer}>
      <img src={MainLogo} className={styles.logo} alt="logo" />
      <div className={styles.mainText}>
        系統維護中
      </div>
      <div className={styles.desc}>
        <div className={styles.subText}>
          系統維護時間：
        </div>
        <div className={styles.subText}>
          {`${start} ~ ${end} (UTC+8)`}
        </div>
      </div>
    </div>
  );
}
