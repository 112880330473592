const filteredItems = ['000000000000000000000001', '200000000000000000000000'];

export default class TeamListModel {
  constructor(data) {

    this.teamList = [];

    data.forEach((el) => {
      if (!filteredItems.includes(el.id)) {
        this.teamList.push({
          value: el.id,
          label: el.name
        });
      }
    });
  }

  static fromRes(data) {
    return new TeamListModel(data);
  }
}
