import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Waypoint } from 'react-waypoint';

import { SENTIMENT_TYPE } from 'src/consts';
import { SINGLE_TOPIC_COMMENTS_TABLE } from 'src/consts/tables';

import { CheckOutlined, CaretDownFilled } from '@ant-design/icons';
import { Button, Layout, Select, Table, Tooltip, Skeleton } from 'antd';

import ProjectLinkIconBlue from 'src/assets/ProjectLinkIconBlue.svg';
import Loading from 'src/assets/loading.svg';

import SimpleHeader from 'src/components/SimpleHeader';
import SentimentItem from 'src/components/SentimentItem';

import TopicSinglePageViewModel from './viewModel';

import styles from './styles.module.scss';

@observer
class TopicSinglePage extends React.Component {
  constructor() {
    super();

    this.viewModel = new TopicSinglePageViewModel();
  }
  render() {
    return (
      <Layout className={styles.pageContainer}>
        <SimpleHeader />
        <Layout.Content className={styles.contentContainer}>
          <div className={styles.topicContentContainer}>
            {
              this.viewModel.isInit
                ? (
                  <>
                    <div className={styles.header}>
                      <div className={styles.headerLeft}>
                        <SentimentItem
                          sentiment={this.viewModel.sentiment}
                          prefix={i18n.t('topic_comment_table_sentiment')}
                          onSentimentChange={this.viewModel.onPostSentimentChange}
                          canChangeSentiment={this.viewModel.editable}
                        />
                        <div className={styles.infoContainer}>
                          <div className={styles.infoItem}>
                            {this.viewModel.hitPostType}
                          </div>
                          <div className={styles.infoItem}>
                            {`${i18n.t('topic_word_of_mouth_title')}：${this.viewModel.womCount}`}
                          </div>
                        </div>
                      </div>
                      <Button
                        className={styles.urlBtn}
                        icon={<img src={ProjectLinkIconBlue} alt="" className={styles.urlIcon} />}
                        type="link"
                        href={this.viewModel.postUrl}
                        target="_blank"
                      >
                        {i18n.t('topic_article_url')}
                      </Button>
                    </div>
                    <div className={styles.vocTitle}>
                      {this.viewModel.vocTitle}
                    </div>
                    <div className={styles.vocInfo}>
                      <div className={styles.infoItem}>
                        {`${i18n.t('topic_card_source')}： ${this.viewModel.website}[${this.viewModel.channel}]`}
                      </div>
                      <div className={styles.infoItem}>
                        {`${i18n.t('topic_card_author')}： ${this.viewModel.author}`}
                      </div>
                      <div className={styles.infoItem}>
                        {`${i18n.t('topic_card_date')}： ${dayjs(this.viewModel.date).format('YYYY-MM-DD HH:mm:ss')}`}
                      </div>
                      <div className={styles.infoItem}>
                        {`${i18n.t('topic_card_view_count')}： ${this.viewModel.viewCount}`}
                      </div>
                      <div className={styles.infoItem}>
                        {`${i18n.t('topic_card_share_count')}： ${this.viewModel.shareCount}`}
                      </div>
                      <div className={styles.infoItem}>
                        {`${i18n.t('topic_card_comment_count')}： ${this.viewModel.commentCount} ${i18n.t('topic_card_comment_count_unit')}`}
                      </div>
                    </div>
                    <div className={styles.content}>
                      {this.viewModel.content}
                    </div>
                    <div className={styles.hitContainer}>
                      <div className={styles.hitHeader}>
                        <div className={styles.hitHeaderItem}>
                          {`${i18n.t('common_brand')}：${this.viewModel.hitBrand.name}`}
                        </div>
                        <div className={styles.hitHeaderItem}>
                          {`${i18n.t('common_series')}：${this.viewModel.hitSeries.name}`}
                        </div>
                        <div className={styles.hitHeaderItem}>
                          {`${i18n.t('common_product')}：${this.viewModel.hitProduct.name}`}
                        </div>
                      </div>
                      <div className={styles.commonHitContainer}>
                        <div className={styles.commonHitItem}>
                          {`${i18n.t('topic_common_hit_brand')}：${this.viewModel.commonHitBrand}`}
                        </div>
                        <div className={styles.commonHitItem}>
                          {`${i18n.t('topic_common_hit_seres')}：${this.viewModel.commonHitSeries}`}
                        </div>
                        <div className={styles.commonHitItem}>
                          {`${i18n.t('topic_common_hit_product')}：${this.viewModel.commonHitProduct}`}
                        </div>
                      </div>
                    </div>
                  </>
                )
                : (<Skeleton />)
            }

          </div>
          <div className={styles.commentsContainer}>
            {
              this.viewModel.isInit
                ? (
                  <>
                    <div className={styles.statisticsContainer}>
                      <div className={styles.total}>
                        {`${i18n.t('topic_comments_total_count')}：${this.viewModel.commentStatistic.total}`}
                      </div>
                      <div className={styles.statisticSentimentsContainer}>
                        <div className={styles.sentimentItem}>
                          <div className={styles.positiveDot} />
                          <div className={styles.staText}>
                            {`${i18n.t('filter_post_type_comment')}：${i18n.t('filter_sentiment_positive')}, ${this.viewModel.commentStatistic.positive}${i18n.t('topic_card_comment_count_unit')}`}
                          </div>
                        </div>
                        <div className={styles.sentimentItem}>
                          <div className={styles.neutralDot} />
                          <div className={styles.staText}>
                            {`${i18n.t('filter_post_type_comment')}：${i18n.t('filter_sentiment_neutral')}, ${this.viewModel.commentStatistic.neutral}${i18n.t('topic_card_comment_count_unit')}`}
                          </div>
                        </div>
                        <div className={styles.sentimentItem}>
                          <div className={styles.negativeDot} />
                          <div className={styles.staText}>
                            {`${i18n.t('filter_post_type_comment')}：${i18n.t('filter_sentiment_negative')}, ${this.viewModel.commentStatistic.negative}${i18n.t('topic_card_comment_count_unit')}`}
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      this.viewModel.editable
                      && (
                        <div className={styles.changeSentimentContainer}>
                          <div className={styles.changeTitle}>
                            {i18n.t('topic_comment_change_sentiment_title')}
                          </div>
                          <Select
                            options={SENTIMENT_TYPE}
                            placeholder={i18n.t('topic_comment_change_sentiment_placeholder')}
                            className={clsx('select', styles.select, this.viewModel.selectedComments.length >= 1 && styles.activate)}
                            disabled={this.viewModel.selectedComments.length === 0}
                            value={this.viewModel.selectedSentiment}
                            onChange={this.viewModel.onSentimentSelect}
                            suffixIcon={<CaretDownFilled style={{ pointerEvents: 'none' }} />}
                          />
                          <Button
                            className={styles.submitBtn}
                            icon={<CheckOutlined />}
                            disabled={!this.viewModel.canSubmit}
                            onClick={this.viewModel.onSentimentChange}
                          >
                            {i18n.t('btn_filter_submit')}
                          </Button>

                        </div>
                      )
                    }
                    <div className={styles.tableContainer}>
                      <Table
                        rowClassName={styles.row}
                        rowKey={(item) => item.vocKey}
                        dataSource={this.viewModel.comments}
                        pagination={false}
                        className="resTable"
                        rowSelection={
                          this.viewModel.editable
                            ? {
                              columnWidth: 56,
                              selectedRowKeys: this.viewModel.selectedComments,
                              onSelect: this.viewModel.onSelect,
                              onSelectAll: this.viewModel.onSelectAll
                            }
                            : null
                        }
                      >
                        {
                          SINGLE_TOPIC_COMMENTS_TABLE.map((el) => {
                            if (el.key === 'content') {
                              return (
                                <Table.Column
                                  title={el.title}
                                  dataIndex={el.dataIndex}
                                  key={el.key}
                                  className={styles.columns}
                                  render={(text, record) => {
                                    return (
                                      <Tooltip
                                        title={(
                                          <div className={styles.hitTooltip}>
                                            <div className={styles.hitHeader}>
                                              <div className={styles.hitHeaderItem}>
                                                {`${i18n.t('common_brand')}：${this.viewModel.hitBrand.name}`}
                                              </div>
                                              <div className={styles.hitHeaderItem}>
                                                {`${i18n.t('common_series')}：${this.viewModel.hitSeries.name}`}
                                              </div>
                                              <div className={styles.hitHeaderItem}>
                                                {`${i18n.t('common_product')}：${this.viewModel.hitProduct.name}`}
                                              </div>
                                            </div>
                                            <div className={styles.commonHitContainer}>
                                              <div className={styles.commonHitItem}>
                                                {`${i18n.t('topic_common_hit_brand')}：${record.commonHit.level1}`}
                                              </div>
                                              <div className={styles.commonHitItem}>
                                                {`${i18n.t('topic_common_hit_seres')}：${record.commonHit.tag}`}
                                              </div>
                                              <div className={styles.commonHitItem}>
                                                {`${i18n.t('topic_common_hit_product')}：${record.commonHit.level3}`}
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        placement="topRight"
                                        arrow={{ pointAtCenter: true }}
                                        overlayInnerStyle={{ width: 'max-content' }}
                                      >
                                        <div style={{ textAlign: 'left' }}>{text}</div>
                                      </Tooltip>
                                    );
                                  }}
                                />
                              );
                            }
                            if (el.key === 'sentiment') {
                              return (
                                <Table.Column
                                  title={el.title}
                                  dataIndex={el.dataIndex}
                                  key={el.key}
                                  className={styles.columns}
                                  width={56}
                                  render={(text, record) => {
                                    return (
                                      <div className={styles.dotContainer}>
                                        <div
                                          className={clsx(
                                            styles.sentimentDot,
                                            record.sentiment === 1 && styles.positiveDot,
                                            record.sentiment === 0 && styles.neutralDot,
                                            record.sentiment === -1 && styles.negativeDot
                                          )}
                                        />
                                      </div>
                                    );
                                  }}
                                />
                              );
                            }
                            if (el.key === 'date') {
                              return (
                                <Table.Column
                                  title={el.title}
                                  dataIndex={el.dataIndex}
                                  key={el.key}
                                  className={styles.columns}
                                  width={135}
                                  render={(text, record, index) => {
                                    if (index === this.viewModel.comments.length - 1 && this.viewModel.hasMore) {
                                      return (
                                        <Waypoint
                                          onEnter={this.viewModel.onPaging}
                                        >
                                          {
                                            !text
                                              ? (
                                                <div>---</div>
                                              )
                                              : (
                                                <div>{dayjs(text).format('YYYY-MM-DD HH:mm:ss')}</div>
                                              )
                                          }
                                        </Waypoint>
                                      );
                                    }
                                    if (!text) {
                                      return <div>---</div>;
                                    }
                                    return <div>{dayjs(text).format('YYYY-MM-DD HH:mm:ss')}</div>;
                                  }}
                                />
                              );
                            }
                            return (
                              <Table.Column
                                title={el.title}
                                dataIndex={el.dataIndex}
                                key={el.key}
                                className={styles.columns}
                                width={el.key === 'order' ? 56 : 200}
                                render={(text, record) => {
                                  if (!text) {
                                    return <div>---</div>;
                                  }
                                  return <div>{text}</div>;
                                }}
                              />
                            );
                          })
                        }
                      </Table>
                    </div>
                  </>
                )
                : (
                  <Skeleton />
                )
            }
          </div>
        </Layout.Content>
        {
          this.viewModel.isLoading
          && (
            <div className={styles.curtain}>
              <img src={Loading} alt="" style={{ borderRadius: 8 }} />
            </div>
          )
        }
      </Layout>
    );
  }
}

TopicSinglePage.propTypes = {

};

TopicSinglePage.defaultProps = {

};


export default TopicSinglePage;
