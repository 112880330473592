import { COLOR_SET } from 'src/consts/chart';
import { BRAND_REPUTATION_TABLE } from 'src/consts/tables';
import packageStore from 'src/stores/packageStore';
import { getHost } from 'src/utils';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY } from 'src/consts';

export default class BrandReputationModel {
  constructor(origin, query) {
    const data = origin.slice(0, 10);

    const countAvg = (() => {
      let total = 0;

      data.forEach((el) => {
        total += el.womCount;
      });

      return total / data.length;
    })();

    const PNAvg = (() => {
      let total = 0;

      data.forEach((el) => {
        total += el.pn;
      });

      return total / data.length;
    })();


    const avgLine = {
      id: 'avgLine',
      beforeDraw(chart, args, options) {
        const { ctx, chartArea: { top, right, bottom, left, width, height },
          scales: { x, y } } = chart;
        ctx.save();

        ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
        ctx.strokeRect(left, y.getPixelForValue(PNAvg), width, 0);
        ctx.restore();

        ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
        ctx.strokeRect(x.getPixelForValue(countAvg), top, 0, height);
        ctx.restore();
      }
    };

    const {
      postType,
      womType,
      date
    } = query;

    const csv = origin.map((el) => ({
      date: `${date.gte}-${date.lte}`,
      name: el.name,
      count: el.womCount,
      pn: el.pn
    }));

    const csvKey = [
      { key: 'date', header: '日期' },
      { key: 'name', header: '名稱' },
      { key: 'count', header: '聲量' },
      { key: 'pn', header: 'PN值' }
    ];

    this.data = {
      chartData: [
        {
          id: 'brand_reputation',
          name: '品牌聲譽定位',
          data: [
            ...(
              data.length > 0 && !data.every((el) => el.womCount === 0) && !data.every((el) => el.pn === 0)
                ? [{
                  labels: '',
                  datasets: data.map((el, i) => ({
                    label: el.name,
                    brandId: el.id,
                    data: [{
                      x: el.womCount,
                      y: el.pn
                    }],
                    backgroundColor: COLOR_SET[i % 20],
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const { dataset, parsed } = context;
                          const { x, y } = parsed;
                          const { label } = dataset;
                          return `${label} - 聲量:${x}, PN值:${y} `;
                        }
                      }
                    }
                  }))
                }]
                : [{ type: 'nodata', subType: data.length === 0 ? 'ana-error' : 'default' }]
            )],
          type: 'customize-scatter',
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const brandId = chart.data.datasets[datasetIndex].brandId;
              const brandName = chart.data.datasets[datasetIndex].label;
              const womCount = chart.data.datasets[datasetIndex].data[index].x;

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                category: SOURCE_CATEGORY.map((el) => ({
                  id: el.value,
                  name: el.label
                })),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '進階分析/品牌聲譽定位'
                },
                brand: [{
                  id: brandId,
                  name: brandName
                }],
                womCount,
                date: {
                  gte: date.gte,
                  lte: date.lte ?? date.gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          options: {
            event: ['click'],
            scales: {
              y: {
                title: {
                  display: true,
                  text: 'PN值'
                },
                beginAtZero: true,
                ticks: {
                  callback: (v, i, t) => {
                    return `${v}`;
                  }
                },
                grace: '5%'
              },
              x: {
                title: {
                  display: true,
                  text: '聲量'
                },
                grace: '15%',
                beginAtZero: true,
                ticks: {
                  callback: (v, i, t) => {
                    return `${v}`;
                  }
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: 'auto',
                color: '#000',
                formatter: (value, context) => {
                  return context.chart.data.datasets[context.datasetIndex].label;
                },
                align: 'top'
              }
            }
          },
          csv,
          csvKey,
          plugins: [avgLine],
          table: {
            template: BRAND_REPUTATION_TABLE,
            data: data.slice().sort((a, b) => b.womCount - a.womCount).map((el, i) => ({
              ranking: i + 1,
              name: el.name,
              wom: `${el.womCount}則`,
              pn: el.pn
            }))
          }
        }
      ],
      brand: origin.map((el) => ({ id: el.id, name: el.name }))
    };

    // ? ref: https://www.youtube.com/watch?v=glSJZEoKs1M
    // let rect;
    // const area = {
    //   id: 'area',
    //   events: ['mousemove'],
    //   beforeDatasetsDraw(chart, args, plugins) {
    //     if (rect) {

    //     }
    //   },
    //   afterEvent(chart, args) {
    //     console.log(args);
    //   }
    // };
  }

  static fromRes(data, query) {
    return new BrandReputationModel(data, query);
  }
}
