export default class TopTwentyProductModel {
  constructor(data) {
    this.data = data.slice(0, 20).map((el) => ({
      id: el.id,
      name: el.name
    }));
  }

  static fromRes(data) {
    return new TopTwentyProductModel(data);
  }
}
