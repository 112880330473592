import { SENTIMENT_COLOR_SET } from 'src/consts/chart';
import { generateCompletedDayArray, getTotalDays } from 'src/utils/chart';
import { getHost } from 'src/utils';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';

export default class SingleBrandSentimentTrendModel {
  constructor(origin, prevOrigin, query) {
    if (origin.length === 0 && prevOrigin.length === 0) {
      this.data = {
        chartData: [{
          id: 'brand',
          name: '品牌',
          data: [
            {
              labels: [],
              datasets: [],
              type: 'nodata',
              subType: 'ana-error'
            },
            ...(
              infoHeaderViewModel.multiViewModel.hasCompare
                ? [{
                  labels: [],
                  datasets: [],
                  type: 'nodata',
                  subType: 'ana-error'
                }]
                : []
            )
          ]
        }]
      };
      return;
    }

    const data = SingleBrandSentimentTrendModel.generateData(origin);
    const prevData = SingleBrandSentimentTrendModel.generateData(prevOrigin, 'previous');
    const brandList = SingleBrandSentimentTrendModel.generateList(data, prevData);
    const csvKey = [
      { key: 'date', header: '日期' },
      { key: 'positive', header: '偏正面' },
      { key: 'neutral', header: '偏中性' },
      { key: 'negative', header: '偏負面' }
    ];
    const csvForAll = [];

    brandList.forEach((el) => {
      const targetInCurrentTime = data?.find((brand) => brand.id === el.id);
      const targetInPreviousTime = prevData?.find((brand) => brand.id === el.id);
      const csv = SingleBrandSentimentTrendModel.generateCsv(targetInCurrentTime, targetInPreviousTime);
      csvForAll.push({
        id: el.id,
        name: el.name,
        csv,
        csvKey
      });
    });

    this.data = {
      chartData: brandList.slice(0, 10).map((el) => {
        const targetInCurrentTime = data?.find((brand) => brand.id === el.id);
        const currentEmpty = targetInCurrentTime ? SingleBrandSentimentTrendModel.emptyCheck(targetInCurrentTime) : true;
        const targetInPreviousTime = prevData?.find((brand) => brand.id === el.id);
        const prevEmpty = targetInPreviousTime ? SingleBrandSentimentTrendModel.emptyCheck(targetInPreviousTime) : true;
        const { csv } = csvForAll.find((brand) => brand.id === el.id);

        return {
          id: el.id,
          name: el.name,
          data: [
            ...(
              !currentEmpty
                ? [{
                  labels: targetInCurrentTime.item[0].series.map((d) => d.date),
                  datasets: targetInCurrentTime.item.map((senti) => ({
                    label: (() => {
                      switch (senti.name) {
                        case '0': return '中性';
                        case '1': return '正面';
                        case '-1': return '負面';
                        default: return null;
                      }
                    })(),
                    data: senti.series.map((d) => d.count),
                    accurate: targetInCurrentTime.item[0].series.map((d) => d.accurate ?? d.date),
                    sentiment: senti.name,
                    borderColor: (() => {
                      switch (senti.name) {
                        case '0': return SENTIMENT_COLOR_SET.neutral;
                        case '1': return SENTIMENT_COLOR_SET.positive;
                        case '-1': return SENTIMENT_COLOR_SET.negative;
                        default: return null;
                      }
                    })()
                  }))
                }]
                : [{
                  type: 'nodata',
                  subType: targetInCurrentTime ? 'default' : 'ana-error'
                }]
            ),
            ...(
              !prevEmpty
                ? [{
                  labels: targetInPreviousTime.item[0].series.map((d) => d.date),
                  datasets: targetInPreviousTime.item.map((senti) => ({
                    label: (() => {
                      switch (senti.name) {
                        case '0': return '中性';
                        case '1': return '正面';
                        case '-1': return '負面';
                        default: return null;
                      }
                    })(),
                    data: senti.series.map((d) => d.count),
                    accurate: targetInPreviousTime.item[0].series.map((d) => d.accurate ?? d.date),
                    sentiment: senti.name,
                    borderColor: (() => {
                      switch (senti.name) {
                        case '0': return SENTIMENT_COLOR_SET.neutral;
                        case '1': return SENTIMENT_COLOR_SET.positive;
                        case '-1': return SENTIMENT_COLOR_SET.negative;
                        default: return null;
                      }
                    })()
                  }))
                }]
                : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{
                        type: 'nodata',
                        subType: targetInPreviousTime ? 'default' : 'ana-error'
                      }]
                      : []
                  )
                ]
            )
          ],
          onPointClick: (elements, chart) => {
            if (elements.length > 0) {
              const {
                postType,
                womType,
                category
              } = query;
              const { datasetIndex, index } = elements[0];
              const [gte, lte] = chart.data.datasets[datasetIndex].accurate[index].split('~');
              const sentiment = chart.data.datasets[datasetIndex].sentiment;
              const womCount = chart.data.datasets[datasetIndex].data[index];

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category ? {
                  category: category.map((c) => ({
                    id: c,
                    name: SOURCE_CATEGORY_TYPE[c]
                  }))
                } : {
                  category: SOURCE_CATEGORY.map((c) => ({
                    id: c.value,
                    name: c.label
                  }))
                }),
                sentiment: [Number(sentiment)],
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/品牌/情緒分析/各別品牌情緒趨勢'
                },
                brand: [{
                  id: el.id,
                  name: el.name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'line',
          options: {
            responsive: true,
            event: ['click'],
            tension: 0.3,
            scales: {
              x: {
                title: {
                  display: true,
                  text: '日期'
                }
              },
              y: {
                beginAtZero: true,
                grace: '15%',
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        };
      }),
      csvForAll
    };
  }

  static generateData = (list, time = 'present') => {
    if (list.length === 0) {
      return null;
    }
    const totalDays = getTotalDays(time);
    return list.map((ori) => {
      const items = ori.item.map((item) => {
        if (totalDays >= 32 && totalDays <= 90) {
          const dayArr = generateCompletedDayArray(time);
          item.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...item,
            series: dayArr.map((el) => ({
              date: el.id,
              count: el.count
            }))
          };
        }

        if (totalDays >= 91) {
          const dayArr = generateCompletedDayArray(time);
          item.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...item,
            series: dayArr.map((el) => ({
              date: el.id,
              accurate: `${el.days[0]}~${el.days[el.days.length - 1]}`,
              count: el.count
            }))
          };
        }

        const dayArr = generateCompletedDayArray(time);
        return {
          ...item,
          series: dayArr.map((d) => ({
            date: d,
            count: item.series.find((s) => s.date === d)?.count ?? 0
          }))
        };
      });
      return {
        ...ori,
        item: items
      };
    });
  };

  static generateList = (list, prevList) => {
    const brandList = [];
    list?.forEach((el) => {
      const target = brandList.find((brand) => brand.id === el.id);
      if (!target) {
        brandList.push({ id: el.id, name: el.name });
      }
    });
    // prevList?.forEach((el) => {
    //   const target = brandList.find((brand) => brand.id === el.id);
    //   if (!target) {
    //     brandList.push({ id: el.id, name: el.name });
    //   }
    // });
    return brandList;
  };

  static generateCsv = (data, prevData) => {
    const csv = [];
    if (data) {
      const presentCsv = data.item[0].series.map((d) => ({
        date: d.date,
        positive: data.item.find((item) => item.name === '1')?.series.find((s) => s.date === d.date)?.count ?? 0,
        neutral: data.item.find((item) => item.name === '0')?.series.find((s) => s.date === d.date)?.count ?? 0,
        negative: data.item.find((item) => item.name === '-1')?.series.find((s) => s.date === d.date)?.count ?? 0
      }));
      csv.push(...presentCsv);
    }
    if (prevData) {
      const previousCsv = prevData.item[0].series.map((d) => ({
        date: d.date,
        positive: prevData.item.find((item) => item.name === '1')?.series.find((s) => s.date === d.date)?.count ?? 0,
        neutral: prevData.item.find((item) => item.name === '0')?.series.find((s) => s.date === d.date)?.count ?? 0,
        negative: prevData.item.find((item) => item.name === '-1')?.series.find((s) => s.date === d.date)?.count ?? 0
      }));
      csv.push(...previousCsv);
    }

    return csv;
  };

  static emptyCheck = (data) => {
    return data.item.every((el) => el.series.every((date) => date.count === 0));
  };

  static fromRes(data, prevData, query) {
    return new SingleBrandSentimentTrendModel(data, prevData, query);
  }
}
