import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import Cookies from 'js-cookie';
import { message } from 'antd';

import PackageService from 'src/services/package';
import { COLOR_SET } from 'src/consts/chart';

import optionsStore from './optionsStore';

class PackageStore {
  @observable packages = [];
  @observable activePackageId = '';
  @observable activePackageName = '';
  @observable activeProjectId = '';
  @observable activeFeatureSetId = '';

  @observable isLevelProcessing = true;
  @observable brand = [];
  @observable series = [];
  @observable product = [];

  @observable packageStartDate = '';
  @observable packageEndDate = '';

  @observable isIndustryOpen = false;
  @observable isBrandOpen = false;
  @observable isSeriesOpen = false;
  @observable isProductOpen = false;

  @observable searchTime = [];
  @observable previousTime = [];

  @observable featureSetList = [];

  // > 24/04/25 sync package every time when get charts/topics
  @observable lastChangeDate = '';

  @computed get noAvailablePackages() {
    return this.packages.length === 0;
  }

  @computed get isBrandAvailable() {
    return packageStore.isBrandOpen && packageStore.brand.length >= 1;
  }

  @computed get isSeriesAvailable() {
    return packageStore.isSeriesOpen && packageStore.series.length >= 1;
  }

  @computed get isProductAvailable() {
    return packageStore.isProductOpen && packageStore.product.length >= 1;
  }

  @computed get opened() {
    const arr = [];
    if (this.isIndustryOpen) {
      arr.push('industry');
    }
    if (this.isBrandAvailable) {
      arr.push('brand');
    }
    if (this.isSeriesAvailable) {
      arr.push('series');
    }
    if (this.isProductAvailable) {
      arr.push('product');
    }
    return arr;
  }

  @computed get flatProduct() {
    return this.product.map((el) => el.children).flat().map((el) => ({ ...el, id: el.id, name: el.name }));
  }

  constructor() {
    makeObservable(this);

  }

  @action updatePackages = (packages) => {
    this.packages = packages;
    const previousPackage = Cookies.get('activePackage');
    const packageInList = this.packages.find((el) => el.id === previousPackage);

    if (packageInList) {
      this.updatePackage(packageInList);
    } else if (packages.length > 0) {
      this.updatePackage(this.packages[0]);
    }
  };

  @action onPackageSelect = (p) => {
    Cookies.set('activePackage', p.id);
    this.updatePackage(p);
  };

  @action checkLatest = async () => {
    if (optionsStore.isInQueue('checkLatest')) {
      return {
        needUpdate: false
      };
    }
    optionsStore.setLoading('checkLatest');
    try {
      const {
        projectId,
        featureSetId,
        openedLevel,
        validDate: [startDate, endDate],
        lastChangeDate
      } = await PackageService.getPackageInfo(this.activePackageId);
      if (lastChangeDate === this.lastChangeDate) {
        return {
          needUpdate: false
        };
      }

      message.info('發現數據包資料更動，更新中！');
      return {
        needUpdate: true,
        projectId,
        featureSetId,
        openedLevel,
        startDate,
        endDate,
        lastChangeDate
      };

    } catch (error) {
      return {
        needUpdate: false
      };
    } finally {
      optionsStore.setCompleted('checkLatest');
    }
  };

  @action syncLatest = async (data) => {
    if (optionsStore.isInQueue('syncLatest')) {
      return;
    }
    optionsStore.setLoading('syncLatest');
    const {
      projectId,
      featureSetId,
      openedLevel,
      startDate,
      endDate,
      lastChangeDate
    } = data;

    this.isLevelProcessing = true;

    try {
      const { brand, series, product } = await PackageService.getPackageLevelsContent(this.activePackageId);
      const brandIdsByProduct = [...new Set(product.map((el) => el.sid))];
      const { brandList } = await PackageService.getBrand(projectId);
      const products = brandList.filter((el) => brandIdsByProduct.includes(el.id)).map((el) => ({
        id: el.id,
        name: el.name,
        children: []
      }));

      product.forEach((el) => {
        const target = products.find((b) => b.id === el.sid);
        target?.children.push({ id: el._id, name: el.name, extraIds: el.tags?.map((t) => t.subTid) ?? [] });
      });

      if (featureSetId) {
        const { feature } = await PackageService.getFeatureSetContent(projectId, featureSetId);
        runInAction(() => {
          this.featureSetList = feature?.map((el, i) => ({ ...el, color: COLOR_SET[i] })) ?? [];
        });
      }

      runInAction(() => {
        this.activeProjectId = projectId;
        this.activeFeatureSetId = featureSetId;
        this.isIndustryOpen = openedLevel.industry;
        this.isBrandOpen = openedLevel.brand;
        this.isSeriesOpen = openedLevel.series;
        this.isProductOpen = openedLevel.product;
        this.packageStartDate = startDate;
        this.packageEndDate = endDate;
        this.brand = brand;
        this.series = series;
        this.product = products;
        this.lastChangeDate = lastChangeDate;
      });

    } catch (error) {
      // return false;
    } finally {
      runInAction(() => {
        this.isLevelProcessing = false;
        optionsStore.setCompleted('syncLatest');
      });
    }
  };

  @action updatePackage = async (p) => {
    this.activePackageId = p.id;
    this.activePackageName = p.name;
    this.isLevelProcessing = true;
    optionsStore.setLoading('packageInfo');

    try {
      const [
        {
          projectId,
          featureSetId,
          openedLevel,
          validDate: [startDate, endDate],
          lastChangeDate
        },
        {
          brand,
          series,
          product
        }
      ] = await Promise.all([
        PackageService.getPackageInfo(p.id),
        PackageService.getPackageLevelsContent(p.id)
      ]);

      const brandIdsByProduct = [...new Set(product.map((el) => el.sid))];
      const { brandList } = await PackageService.getBrand(projectId);
      const products = brandList.filter((el) => brandIdsByProduct.includes(el.id)).map((el) => ({
        id: el.id,
        name: el.name,
        children: []
      }));

      product.forEach((el) => {
        const target = products.find((b) => b.id === el.sid);
        target?.children.push({ id: el._id, name: el.name, extraIds: el.tags?.map((t) => t.subTid) ?? [] });
      });

      if (featureSetId) {
        const { feature } = await PackageService.getFeatureSetContent(projectId, featureSetId);
        runInAction(() => {
          this.featureSetList = feature?.map((el, i) => ({ ...el, color: COLOR_SET[i] })) ?? [];
        });
      }

      runInAction(() => {
        this.activeProjectId = projectId;
        this.activeFeatureSetId = featureSetId;
        this.isIndustryOpen = openedLevel.industry;
        this.isBrandOpen = openedLevel.brand;
        this.isSeriesOpen = openedLevel.series;
        this.isProductOpen = openedLevel.product;
        this.packageStartDate = startDate;
        this.packageEndDate = endDate;
        this.brand = brand;
        this.series = series;
        this.product = products;
        this.lastChangeDate = lastChangeDate;
      });


    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.isLevelProcessing = false;
        optionsStore.setCompleted('packageInfo');
      });
    }
  };
}

const packageStore = new PackageStore();
export default packageStore;
