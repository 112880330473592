import { SENTIMENT_COLOR_SET } from 'src/consts/chart';
import { generateCompletedDayArray, getTotalDays } from 'src/utils/chart';
import { getHost } from 'src/utils';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';

export default class IndustrySentimentTrendModel {
  constructor(origin, prevOrigin, query) {
    const data = (() => {
      if (origin.length === 0) {
        return null;
      }
      if (origin.every((el) => el.series.every((item) => item.count === 0))) {
        return null;
      }
      const totalDays = getTotalDays();
      return origin.map((ori) => {
        if (totalDays >= 32 && totalDays <= 90) {
          const dayArr = generateCompletedDayArray();
          ori.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...ori,
            series: dayArr.map((el) => ({
              date: el.id,
              count: el.count
            }))
          };
        }

        if (totalDays >= 91) {
          const dayArr = generateCompletedDayArray();
          ori.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...ori,
            series: dayArr.map((el) => ({
              date: el.id,
              accurate: `${el.days[0]}~${el.days[el.days.length - 1]}`,
              count: el.count
            }))
          };
        }

        const dayArr = generateCompletedDayArray();
        return {
          ...ori,
          series: dayArr.map((d) => ({
            date: d,
            count: ori.series.find((s) => s.date === d)?.count ?? 0
          }))
        };
      });
    })();

    const previousData = (() => {
      if (prevOrigin.length === 0) {
        return null;
      }
      if (prevOrigin.every((el) => el.series.every((item) => item.count === 0))) {
        return null;
      }
      const totalDays = getTotalDays('previous');
      return prevOrigin.map((ori) => {
        if (totalDays >= 32 && totalDays <= 90) {
          const dayArr = generateCompletedDayArray('previous');
          ori.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...ori,
            series: dayArr.map((el) => ({
              date: el.id,
              count: el.count
            }))
          };
        }

        if (totalDays >= 91) {
          const dayArr = generateCompletedDayArray('previous');
          ori.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...ori,
            series: dayArr.map((el) => ({
              date: el.id,
              accurate: `${el.days[0]}~${el.days[el.days.length - 1]}`,
              count: el.count
            }))
          };
        }

        const dayArr = generateCompletedDayArray('previous');
        return {
          ...ori,
          series: dayArr.map((d) => ({
            date: d,
            count: ori.series.find((s) => s.date === d)?.count ?? 0
          }))
        };
      });
    })();

    const csv = [];
    const convertData = data ? [
      {
        id: 'positive',
        key: '1',
        name: '正面',
        series: data.find((el) => el.name === '1').series
      },
      {
        id: 'neutral',
        key: '0',
        name: '中性',
        series: data.find((el) => el.name === '0').series
      },
      {
        id: 'negative',
        key: '-1',
        name: '負面',
        series: data.find((el) => el.name === '-1').series
      }
    ] : null;

    if (convertData) {
      data[0].series.forEach((el, i) => {
        csv.push({
          date: el.date,
          positive: convertData[0].series[i].count,
          neutral: convertData[1].series[i].count,
          negative: convertData[2].series[i].count
        });
      });
    }

    const convertPreviousData = previousData ? [
      {
        id: 'positive',
        key: '1',
        name: '正面',
        series: previousData.find((el) => el.name === '1').series
      },
      {
        id: 'neutral',
        key: '0',
        name: '中性',
        series: previousData.find((el) => el.name === '0').series
      },
      {
        id: 'negative',
        key: '-1',
        name: '負面',
        series: previousData.find((el) => el.name === '-1').series
      }
    ] : null;

    if (convertPreviousData) {
      previousData[0].series.forEach((el, i) => {
        csv.push({
          date: el.date,
          positive: convertPreviousData[0].series[i].count,
          neutral: convertPreviousData[1].series[i].count,
          negative: convertPreviousData[2].series[i].count
        });
      });
    }

    const csvKey = [
      { key: 'date', header: '日期' },
      { key: 'positive', header: '偏正面' },
      { key: 'neutral', header: '偏中性' },
      { key: 'negative', header: '偏負面' }
    ];

    this.data = {
      chartData: [
        {
          id: 'industry_sentiment_trend',
          name: '行業正負面情緒趨勢',
          data: [
            ...(
              convertData && !convertData.every((el) => el.series.every((date) => date.count === 0))
                ? [{
                  labels: convertData[0].series.map((el) => el.date),
                  datasets: convertData.map((el) => ({
                    label: el.name,
                    accurate: convertData[0].series.map((d) => d.accurate ?? d.date),
                    data: el.series.map((s) => s.count),
                    sentiment: el.key,
                    borderColor: SENTIMENT_COLOR_SET[el.id]
                  }))
                }]
                : [{
                  type: 'nodata',
                  subType: convertData?.length > 0 ? 'default' : 'ana-error'
                }]
            ),
            ...(
              convertPreviousData && !convertPreviousData.every((el) => el.series.every((date) => date.count === 0))
                ? [{
                  labels: convertPreviousData[0].series.map((el) => el.date),
                  datasets: convertPreviousData.map((el) => ({
                    label: el.name,
                    accurate: convertPreviousData[0].series.map((d) => d.accurate ?? d.date),
                    data: el.series.map((s) => s.count),
                    sentiment: el.key,
                    borderColor: SENTIMENT_COLOR_SET[el.id]
                  }))
                }]
                : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{
                        type: 'nodata',
                        subType: convertPreviousData?.length > 0 ? 'default' : 'ana-error'
                      }]
                      : []
                  )
                ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType,
                category
              } = query;
              const { datasetIndex, index } = elements[0];
              const [gte, lte] = chart.data.datasets[datasetIndex].accurate[index].split('~');
              const sentiment = chart.data.datasets[datasetIndex].sentiment;
              const womCount = chart.data.datasets[datasetIndex].data[index];

              if (!sentiment) {
                return;
              }

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category ? {
                  category: category.map((el) => ({
                    id: el,
                    name: SOURCE_CATEGORY_TYPE[el]
                  }))
                } : {
                  category: SOURCE_CATEGORY.map((el) => ({
                    id: el.value,
                    name: el.label
                  }))
                }),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                sentiment: [Number(sentiment)],
                chart: {
                  name: '圖表分析/行業/情緒分析/行業正負面情緒趨勢'
                },
                industry: true,
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'line',
          options: {
            responsive: true,
            event: ['click'],
            tension: 0.3,
            scales: {
              x: {
                title: {
                  display: true,
                  text: '日期'
                }
              },
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ]
    };
  }

  static fromRes(data, prevOrigin, query) {
    return new IndustrySentimentTrendModel(data, prevOrigin, query);
  }
}
