import { COLOR_SET } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY } from 'src/consts';
import { generateCSVKey } from 'src/utils/chart';

export default class BrandConsumerFeatureModel {
  constructor(origin, query) {
    if (origin.length === 0) {
      this.data = {
        chartData: [{
          id: 'brand_source_channel_compare',
          name: '系列品牌聲量堆疊',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }]
        }]
      };
      return;
    }

    const data = origin;

    if (data.every((el) => el.item.every((item) => item.count === 0))) {
      this.data = {
        chartData: [{
          id: 'brand_source_channel_compare',
          name: '系列品牌聲量堆疊',
          data: [{
            type: 'nodata'
          }]
        }]
      };
      return;
    }
    const {
      postType,
      womType
    } = query;

    const feature = [];
    data.forEach((el) => {
      el.item.forEach((item) => {
        const target = feature.find((f) => f.id === item.id);
        if (!target) {
          feature.push({ id: item.id, name: item.name, color: COLOR_SET[feature.length % 20] });
        }
      });
    });

    const formatData = data.map((brand) => ({
      ...brand,
      item: feature.map((featureItem) => {
        const brandItem = brand.item.find((item) => item.id === featureItem.id);
        return {
          ...featureItem,
          count: brandItem.count ?? 0
        };
      })
    }));

    const csv = formatData.map((el) => {
      const row = {
        品牌名稱: el.name
      };

      el.item.forEach((item) => {
        row[`${item.name}`] = item.count;
      });

      return row;
    });

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'brand_source_channel_compare',
          name: '品牌消費者關注堆疊',
          data: [formatData.slice(0, 10)],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const brandItem = chart.data.datasets[datasetIndex].mainItem[index];
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const featureItem = chart.data.datasets[datasetIndex].item;
              const [gte, lte] = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'));

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                category: SOURCE_CATEGORY.map((el) => ({
                  id: el.value,
                  name: el.label
                })),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                featureCategory: {
                  id: featureItem.id,
                  name: featureItem.name
                },
                chart: {
                  name: '數據報告/品牌競爭分析/品牌消費者關注堆疊'
                },
                brand: [{
                  id: brandItem.id,
                  name: brandItem.name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          onPointHover: (elements, ref) => {
            const chart = ref.current;
            if (elements.length > 0) {
              const { datasetIndex } = elements[0];
              const hoverTarget = chart.data.datasets[datasetIndex].customName;
              chart.hovered = hoverTarget;
            } else {
              chart.hovered = null;
            }
            chart.update();
          },
          legend: 'topTenInAllGroup',
          type: 'customize-stack-bar',
          options: {
            event: ['click'],
            scales: {
              x: {
                stacked: true
              },
              y: {
                stacked: true,
                grace: 5,
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                display: false
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ],
      brand: data.map((el) => ({ id: el.id, name: el.name })),
      feature
    };
  }

  static fromRes(data, query) {
    return new BrandConsumerFeatureModel(data, query);
  }
}
