import React from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { observer, Observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import { Virtuoso } from 'react-virtuoso';

import SearchIcon from 'src/assets/MenuIcons/SearchIcon.svg';

import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Popover, Empty, Checkbox } from 'antd';

import CheckItem from './CheckItem';

import styles from './styles.module.scss';

@observer
class SinglePopoverSelect extends React.Component {
  componentDidMount() {
    this.props.viewModel.didMount();
  }
  componentWillUnmount() {
    this.props.viewModel.willUnmount();
  }

  _renderContent = (item) => {
    if (!item) {
      return null;
    }
    return <CheckItem key={item.id} viewModel={item} />;
  };

  render() {
    const { viewModel, callback, title } = this.props;
    return (
      <Popover
        placement="bottomLeft"
        showArrow={false}
        open={viewModel.open}
        trigger="click"
        onOpenChange={(bool) => viewModel.onOpenChange(bool, callback)}
        content={(
          <div className={styles.switchProjectPopover}>
            <div className={styles.selectCard}>
              <div className={styles.cardHeader}>
                <div className={styles.cardTitle}>
                  {`${title}`}
                </div>
                <div className={styles.cardSelectAll}>
                  <Checkbox
                    indeterminate={viewModel.indeterminate}
                    checked={viewModel.isAllChecked}
                    onChange={viewModel.onAllSelect}
                  />
                  <div className={styles.selectAllText}>
                    {i18n.t('package_modal_select_all')}
                  </div>
                </div>
              </div>
              <div className={styles.search}>
                <div className={styles.searchContainer}>
                  <input
                    className={styles.searchInput}
                    placeholder="請輸入關鍵字"
                    value={viewModel.keyword}
                    onChange={viewModel.onKeywordChange}
                  />
                  <div
                    className={styles.searchBtn}
                  >
                    <img src={SearchIcon} alt="" />
                  </div>
                </div>
              </div>
              <div className={styles.cardContent}>
                <div className={styles.levelCheckboxContainer}>
                  <Virtuoso
                    data={viewModel.searchList}
                    initialItemCount={6}
                    overscan={20}
                    totalCount={viewModel.searchList.length}
                    style={{ height: 188 }}
                    itemContent={(index, item) => this._renderContent(item)}
                    fixedItemHeight={34}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      >
        <div className={clsx(styles.selector)}>
          <div className={clsx(styles.text, viewModel.selected.length > 0 && styles.active)}>
            {`${title}${viewModel.selected.length > 0 ? `(${viewModel.selected.length})` : ''}`}
          </div>
          <CaretDownOutlined className={styles.icon} />
        </div>
      </Popover>
    );
  }
}

SinglePopoverSelect.propTypes = {
  viewModel: PropTypes.object,
  callback: PropTypes.func,
  title: PropTypes.string
};

SinglePopoverSelect.defaultProps = {
  viewModel: {},
  callback: () => { },
  title: ''
};


export default SinglePopoverSelect;
