export default class SalesListModel {
  constructor(data, teamId) {

    // TODO: 優化
    this.salesList = data.map((el) => {
      return ({
        id: el.id,
        name: el.name,
        teamId: el.teamId,
        teamName: el.teamName,
        customers: [...el.customers]
      });
    });

    if (teamId) {
      this.salesList = this.salesList.filter((el) => el.teamId === teamId);
    }
  }

  static fromRes(data, teamId) {
    return new SalesListModel(data, teamId);
  }
}
