import React from 'react';
import { makeObservable, observable, action, computed } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

export default class RadarChartViewModel {
  type = 'radar';
  ref = React.createRef();
  @observable key = '';
  @observable id = '';
  @observable name = '';
  @observable options = {};
  @observable plugins = [];
  @observable data = {
    labels: [],
    datasets: []
  };

  @observable csv = [];
  @observable csvKey = [];

  constructor(data) {
    makeObservable(this);

    this.init(data);
  }


  @action init = (content) => {
    const {
      id,
      name,
      data,
      onPointClick,
      options,
      csv,
      csvKey,
      plugins
    } = content;
    const timeSet = data.datasets[0].timeSet ?? 'present';

    this.id = `${id}_${timeSet}`;
    this.key = uuidv4();
    this.name = name;
    this.data = data;
    this.timeSet = data.datasets[0].timeSet;
    this.plugins = plugins;
    this.options = {
      ...options,
      plugins: {
        ...options.plugins,
        legend: {
          ...options.plugins.legend,
          labels: {
            ...options.plugins.legend?.labels,
            color: '#000'
          }
        },
        htmlLegend: {
          containerID: this.id
        }
      },
      scales: {
        ...options.scales,
        r: {
          ...options.scales.r,
          pointLabels: {
            color: '#000'
          }
        }
      },
      onClick: (_, elements) => onPointClick(elements, this.ref.current),
      onHover: (event, elements) => {
        const target = event.native.target;
        if (elements.length > 0) {
          target.style.cursor = 'pointer';
        } else {
          target.style.cursor = 'default';
        }
      }
    };
    this.csv = csv;
    this.csvKey = csvKey;
  };
}

