import { makeObservable, observable, action, computed, runInAction, reaction } from 'mobx';
import html2canvas from 'html2canvas';
import dayjs from 'dayjs';
import { message } from 'antd';
import * as xlsx from 'xlsx';

import packageStore from 'src/stores/packageStore';
import userStore from 'src/stores/userStore';
import optionsStore from 'src/stores/optionsStore';

import PackageService from 'src/services/package';
import SourceService from 'src/services/source';
import AdvanceAnalysisService from 'src/services/advanceAnalysis';

import SourceSelectModalViewModel from 'src/components/SourceSelectModal/viewModel';
import MultiPeriodDatePickerViewModel from 'src/components/MultiPeriodDatePicker/viewModel';
import SingleClassModalViewModel from '../components/SingleClassModal/viewModel';

import ChartItemViewModel from '../components/ChartItem/viewModel';
import CheckItemViewModel from '../components/SingleClassModal/CheckItem/viewModel';


export default class StarProductPageViewModel {
  @observable selectedSec = 'setting';
  @observable date = [dayjs(), dayjs()];

  @observable selectedWOM = null;

  @observable seriesList = [];
  @observable selectedSeries = null;

  @observable brandSelectViewModel = new SingleClassModalViewModel();
  @observable sourceSelectModalViewModel = new SourceSelectModalViewModel();

  @observable brandCache = [];
  @observable channelCache = [];

  @observable showCharts = [];

  @observable status = {};
  @observable jobId = '';

  @observable multipleDatePickerViewModel = new MultiPeriodDatePickerViewModel('range');

  @observable isDownload = false;
  @observable downloadList = [];
  @observable downloadFileName = '';

  @observable once = null;

  // > static show for result section.
  @observable cacheContent = {
    date: [dayjs(), dayjs()],
    brand: '',
    source: '',
    sourceTotal: '',
    vom: ''
  };

  @computed get selectedBrand() {
    return this.brandSelectViewModel.items.filter((el) => el.checked).map((el) => el.id);
  }

  @computed get sourceSelectedIncomplete() {
    return this.sourceSelectModalViewModel.selectedChannels.length === 0;
  }

  @computed get dateString() {
    return `${this.date[0].format('YYYY-MM-DD')} - ${this.date[1].format('YYYY-MM-DD')}`;
  }

  @computed get isShowStatisticClean() {
    return this.selectedPostType !== 'all' || this.selectedSourceCategoryType;
  }

  @computed get totalChannels() {
    let count = 0;
    this.sourceSelectModalViewModel.selectedChannelsByCategory.forEach((el) => {
      count += el.count;
    });
    return count;
  }

  @computed get disabledAdjustSetting() {
    return !['DONE', 'CANCEL', 'FAIL'].includes(this.status.stage) && this.jobId;
  }

  @computed get progressText() {
    switch (this.status.stage) {
      case 'DONE':
        return '分析成功';
      case 'FAIL':
        return '分析失敗';
      case 'CANCEL':
        return '取消分析';
      default:
        return `請稍候，模組分析中...(${this.status.progress}%)`;
    }
  }

  constructor() {
    makeObservable(this);

    this.init();
  }

  @action init = async () => {
    if (!packageStore.isLevelProcessing) {
      await this.getInfo();
      await this.updateSection();
    } else {
      const once = reaction(
        () => packageStore.isLevelProcessing,
        async (bool) => {
          if (!bool) {
            await this.getInfo();
            await this.updateSection();
            once();
          }
        }
      );
    }
  };

  @action setReaction = () => {
    const reactionOnce = reaction(
      () => this.selectedSec,
      async (section) => {
        if (section === 'result') {
          this.getGraphicData();
        }
      }
    );

    this.once = reactionOnce;
  };

  @action updateSection = async () => {
    if (userStore.activeUserType === 'customer') {
      this.selectedSec = 'result';
      await this.getGraphicData();
    }
  };

  @action getInfo = async () => {
    optionsStore.setLoading('star');
    try {
      const res = await AdvanceAnalysisService.getStarProductSetting();
      const { starProductStatus } = await PackageService.getAnalysisLog(packageStore.activePackageId);

      runInAction(() => {
        const {
          date,
          selectedBrand,
          selectedChannel,
          womType,
          searchTagId,
          jobId
        } = res;

        const endDateValue = Math.min(dayjs(packageStore.packageEndDate).valueOf(), dayjs().valueOf());
        this.date = jobId ? date : [dayjs(endDateValue).subtract(7, 'day'), dayjs(endDateValue)];
        this.selectedWOM = womType;
        this.brandCache = selectedBrand;
        this.channelCache = selectedChannel;
        this.selectedSeries = searchTagId;
        this.jobId = jobId;
        this.status = starProductStatus;
        this.multipleDatePickerViewModel.updateDisabledDateRange(this.date);
      });

      this.setLevels();
      await this.setSource();
      runInAction(() => {
        this.sourceSelectModalViewModel.setIsShow('channel');
        this.sourceSelectModalViewModel.setInit(this.channelCache);
        this.cacheContent = {
          date: this.date,
          dateString: this.dateString,
          brand: this.brandSelectViewModel.text,
          source: this.sourceSelectModalViewModel.selectedChannelsByCategory.map((el) => ({ id: el.id, name: el.name, count: el.count })),
          sourceTotal: this.totalChannels,
          vom: this.selectedWOM
        };
      });
    } catch (error) {
      console.log(error);
    } finally {
      optionsStore.setCompleted('star');
    }
  };

  @action onSecChange = (e) => {
    this.selectedSec = e;
  };

  @action onDateSelect = (e) => {
    this.date = e;
  };

  @action onVOMSelect = (e) => {
    this.selectedWOM = e;
  };

  @action onSeriesSelect = (e) => {
    this.selectedSeries = e;
  };


  @action onBrandSelectButtonClick = () => {
    this.brandSelectViewModel.onModalOpen();
  };

  @action setLevels = () => {
    if (packageStore.brand.length > 0) {
      const brand = packageStore.brand.map((el) => new CheckItemViewModel(el));
      this.seriesList = packageStore.series.map((el) => ({ value: el.id, label: el.name }));
      this.brandSelectViewModel.setItems('brand', brand);
      this.brandSelectViewModel.items.forEach((b) => {
        if (this.brandCache.includes(b.id)) {
          b.setInit();
        }
      });
    } else {
      const once = reaction(
        () => packageStore.isLevelProcessing,
        (bool) => {
          if (!bool) {
            const brand = packageStore.brand.map((el) => new CheckItemViewModel(el));
            this.seriesList = packageStore.series.map((el) => ({ value: el.id, label: el.name }));
            this.brandSelectViewModel.setItems('brand', brand);
            this.brandSelectViewModel.items.forEach((b) => {
              if (this.brandCache.includes(b.id)) {
                b.setInit();
              }
            });
            once();
          }
        }
      );
    }
  };

  @action onSourceSelectButtonClick = () => {
    this.sourceSelectModalViewModel.onOpenWithType('channel');
  };

  @action setSource = async () => {
    try {
      const { source } = await SourceService.getPackageSources();

      runInAction(() => {
        this.sourceSelectModalViewModel.updateSources(source);
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action onClean = () => {
    this.date = [];
    this.selectedWOM = null;
    this.selectedFeatureSets = null;
    this.brandSelectViewModel.onCleanAll();
    this.sourceSelectModalViewModel.onCleanAll();
    this.sourceSelectModalViewModel.onSubmit();
  };

  @action onSubmit = async () => {
    try {
      const { packageStatus, starProductStatus } = await PackageService.getAnalysisLog(packageStore.activePackageId);
      if (
        !((!packageStatus.jobId || ['DONE', 'CANCEL', 'FAIL'].includes(packageStatus.stage))
          && (!starProductStatus.jobId || ['DONE', 'CANCEL', 'FAIL'].includes(starProductStatus.stage)))
      ) {
        message.error('數據包/進階分析執行中，無法編輯進階分析設定');
        return;
      }
      const data = {
        startDate: this.date[0].startOf('day').toISOString(),
        endDate: this.date[1].endOf('day').toISOString(),
        searchKeywordLevel1Ids: this.brandSelectViewModel.selectedItems,
        channelIds: this.sourceSelectModalViewModel.selectedChannels,
        womType: this.selectedWOM,
        searchTagId: this.selectedSeries
      };

      const jobId = await AdvanceAnalysisService.postStarProductSetting(data);
      runInAction(() => {
        this.jobId = jobId;
        this.status.stage = 'WAITING';
        this.status.progress = 0;
        this.cacheContent = {
          date: this.date,
          dateString: this.dateString,
          brand: this.brandSelectViewModel.text,
          source: this.sourceSelectModalViewModel.selectedChannelsByCategory.map((el) => ({ id: el.id, name: el.name, count: el.count })),
          sourceTotal: this.totalChannels,
          vom: this.selectedWOM
        };
      });
    } catch (error) {
      message.error('設定失敗，請重新確認設定內容');
    }
  };

  @action getGraphicData = async () => {
    try {
      optionsStore.setLoading('star');
      const { volumeTotal, top3Product, top3Series } = await AdvanceAnalysisService.getStarProduct(
        {
          start_date: this.multipleDatePickerViewModel.currentDate[0].format('YYYY-MM-DD'),
          finish_date: this.multipleDatePickerViewModel.currentDate[1].format('YYYY-MM-DD')
        },
        {
          womType: this.selectedWOM,
          tagKey: this.selectedSeries
        }
      );

      runInAction(() => {
        const charts = [volumeTotal, top3Product, top3Series];
        this.showCharts = charts.map((el) => ({
          title: el.chartData[0].name,
          showChart: new ChartItemViewModel(el, this)
        }));
      });
    } catch (error) {
      message.error('發生異常，請稍候再試');
    } finally {
      runInAction(() => {
        optionsStore.setCompleted('star');
      });
    }

  };

  @action onDownloadCsv = (order) => {
    this.isDownload = true;
    const { title, showChart } = this.showCharts[order];
    const { csv, csvKey } = showChart.data[0].chart[0];
    if (!csv || !csvKey) {
      message.info('聲量數據不存在，無法下載檔案');
      return;
    }

    const data = [...csv];
    const header = csvKey.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [csvKey.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    const wordbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wordbook, workSheet, title);
    xlsx.writeFile(wordbook, `${packageStore.activePackageName}- 明星商品/${title}-${this.multipleDatePickerViewModel.currentTimeText}.xlsx`);
  };

  @action onDownload = async (order) => {
    await optionsStore.onImgDownload();
    const charts = document.querySelectorAll('.download-chart');
    const { title } = this.showCharts[order];
    const canvas = await html2canvas(charts[order]);
    const a = document.createElement('a');
    a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
    a.download = `${packageStore.activePackageName} - 明星商品/${title} : ${this.multipleDatePickerViewModel.currentDate[0].format('YYYY-MM-DD')} ~ ${this.multipleDatePickerViewModel.currentDate[1].format('YYYY-MM-DD')}.jpg`;
    a.click();
    optionsStore.onImgDownloadEnd();
  };

  @action stopAnalysis = async () => {
    try {
      await AdvanceAnalysisService.stopAnalysis(this.jobId);
      runInAction(() => {
        this.status.stage = 'CANCEL';
      });
    } catch (error) {
      console.log(error);
    }
  };
}
