import React from 'react';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import dayjs from 'dayjs';
import ReactPlayer from 'react-player/youtube';
import CountUp from 'react-countup';
import { v4 as uuidv4 } from 'uuid';

import { RightOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, Table, Checkbox, DatePicker, Input, Statistic, Modal, Skeleton } from 'antd';

import PaperIcon from 'src/assets/PaperIcon.svg';
import AnnouncementIcon from 'src/assets/HomeIcons/AnnouncementIcon.svg';
import DiamondIcon from 'src/assets/DiamondIcon.svg';

import NewsIcon from 'src/assets/HomeIcons/NewsIcon.svg';
import FacebookIcon from 'src/assets/HomeIcons/FacebookIcon.svg';
import InstagramIcon from 'src/assets/HomeIcons/InstagramIcon.svg';
import YoutubeIcon from 'src/assets/HomeIcons/YoutubeIcon.svg';
import ForumIcon from 'src/assets/HomeIcons/ForumIcon.svg';
import ThreadsIcon from 'src/assets/HomeIcons/Threads.svg';

import OthersIcon from 'src/assets/HomeIcons/OthersIcon.svg';
import RightArrowIcon from 'src/assets/RightArrowIcon.svg';
import { HOME_PAGE_DRAWER } from 'src/consts';
import { ANNOUNCEMENT_SETTING_TABLE, SERVICE_SETTING_TABLE } from 'src/consts/tables';

import userStore from 'src/stores/userStore';

import HomePageViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class HomePage extends React.Component {
  constructor() {
    super();

    this.viewModel = new HomePageViewModel();
  }

  _generateIcon = (type) => {
    switch (type) {
      case 'news':
        return (
          <div className={clsx(styles.iconContainer, styles.news)}>
            <img src={NewsIcon} alt="" />
          </div>
        );
      case 'facebook':
        return (
          <div className={clsx(styles.iconContainer, styles.fb)}>
            <img src={FacebookIcon} alt="" />
          </div>
        );
      case 'ig':
        return (
          <div className={clsx(styles.iconContainer, styles.ig)}>
            <img src={InstagramIcon} alt="" />
          </div>
        );
      case 'threads':
        return (
          <div className={clsx(styles.iconContainer, styles.threads)}>
            <img src={ThreadsIcon} alt="" />
          </div>
        );
      case 'video':
        return (
          <div className={clsx(styles.iconContainer, styles.videos)}>
            <img src={YoutubeIcon} alt="" />
          </div>
        );
      case 'forum':
        return (
          <div className={clsx(styles.iconContainer, styles.forum)}>
            <img src={ForumIcon} alt="" />
          </div>
        );
      default:
        return (
          <div className={clsx(styles.iconContainer, styles.others)}>
            <img src={OthersIcon} alt="" />
          </div>
        );
    }
  };

  _formatter = (value) => <CountUp end={value} separator="," duration={5} />;

  render() {
    return (
      <>
        <div className={styles.pageContainer}>
          <div className={styles.headerContainer}>
            <div className={styles.pageTitle}>
              {i18n.t('home_page_name')}
            </div>
            {
              userStore.activeUserType === 'admin'
              && (
                <Button
                  className={styles.announcementSettingBtn}
                  onClick={this.viewModel.onAnnouncementSetting}
                >
                  {i18n.t('home_announcement_setting')}
                </Button>
              )
            }
          </div>
          <div className={styles.announcementContainer}>
            {
              this.viewModel.showAnnouncement.map((el, i) => {
                return (
                  <div className={styles.announcementItem} key={el.id}>
                    <div className={styles.contentContainer}>
                      <img src={AnnouncementIcon} alt="" className={styles.icon} style={{ opacity: 1 - (0.2 * i) }} />
                      <div className={styles.text}>
                        {el.text}
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>

          <div className={styles.article}>
            <div className={styles.titleContainer}>
              <img src={PaperIcon} alt="" />
              <div className={styles.titleText}>
                {i18n.t('home_website_article')}
              </div>
            </div>
            <div className={styles.articleContainer}>
              {
                this.viewModel.readyStatus.article
                  ? (
                    <>
                      <div className={styles.cardContainer}>
                        {
                          this.viewModel.articles.slice(0, 5).map((el) => {
                            return (
                              <a
                                key={el.id}
                                className={styles.card}
                                target="_blank"
                                href={el.url}
                                rel="noreferrer"
                              >
                                <div className={styles.thumbnailContainer}>
                                  <img src={el.thumbnail} alt="" className={styles.thumbnail} />
                                </div>
                                <div className={styles.date}>
                                  {dayjs(el.date).format('YYYY-MM-DD')}
                                </div>
                                <div className={styles.text}>
                                  {el.content}
                                </div>
                              </a>
                            );
                          })
                        }
                      </div>
                      <Button
                        icon={<RightOutlined />}
                        className={styles.moreBtn}
                        type="link"
                        href="https://www.i-buzz.com.tw/brandranking/?n=e0e08fb981888147dc9f4f2480fc393f"
                        target="_blank"
                      >
                        {i18n.t('home_more_article')}
                      </Button>
                    </>
                  )
                  : (
                    <div className={styles.cardContainer}>
                      {
                        [0, 1, 2, 3, 4].map((el) => {
                          return (
                            <div
                              className={styles.card}
                              key={uuidv4()}
                            >
                              <div className={styles.thumbnailContainer}>
                                <Skeleton.Image
                                  className={styles.thumbnail}
                                  active
                                />
                              </div>
                              <Skeleton
                                active
                                paragraph={{
                                  rows: 2
                                }}
                              />
                            </div>
                          );
                        })
                      }
                    </div>
                  )
              }
            </div>
          </div>
          <div className={styles.titleContainer}>
            <img src={PaperIcon} alt="" />
            <div className={styles.titleText}>
              {i18n.t('home_source_type_statistics')}
            </div>
          </div>
          <div className={clsx(styles.sourceContainer)}>
            {
              this.viewModel.readyStatus.source
                ? (
                  <>
                    {
                      this.viewModel.sourceStatics.map((el) => {
                        return (
                          <div
                            key={el.id}
                            className={styles.sourceItem}
                          >
                            {this._generateIcon(el.id)}
                            <div className={styles.sourceCount}>
                              <Statistic value={el.count} formatter={this._formatter} />
                            </div>
                            <div className={styles.sourceType}>
                              {el.title}
                            </div>
                          </div>
                        );
                      })
                    }
                  </>
                )
                : (
                  <>
                    {
                      [0, 1, 2, 3, 4, 5, 6].map((el) => {
                        return (
                          <div
                            className={styles.skeletonContainer}
                            key={uuidv4()}
                          >
                            <Skeleton.Avatar
                              size={56}
                              active
                            />
                            <Skeleton
                              active
                              title={false}
                              paragraph={{
                                rows: 2
                              }}
                            />
                          </div>
                        );
                      })
                    }
                  </>

                )
            }
          </div>
          {
            (this.viewModel.showService.length !== 0 || userStore.activeUserType === 'admin')
            && (
              <div className={styles.serviceTitleContainer}>
                <div className={styles.serviceTitle}>
                  <img src={PaperIcon} alt="" />
                  <div className={styles.titleText}>
                    {i18n.t('home_other_service')}
                  </div>
                </div>
                {
                  userStore.activeUserType === 'admin'
                  && (
                    <Button
                      className={styles.serviceSettingBtn}
                      onClick={this.viewModel.onServiceSetting}
                    >
                      {i18n.t('home_service_setting')}
                    </Button>
                  )
                }
              </div>
            )
          }
          <div className={styles.serviceContainer}>
            {
              this.viewModel.showService.map((el) => {
                return (
                  <div
                    key={el.id}
                    className={styles.serviceItem}
                  >
                    <div className={styles.videoContainer}>
                      <ReactPlayer
                        url={el.url}
                        width="100%"
                        height="100%"
                        className={styles.video}
                        light
                      />
                    </div>
                    <div className={styles.itemTitle}>
                      {el.name}
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
        {
          userStore.activeUserType === 'admin'
          && (
            <>
              <Drawer
                open={this.viewModel.isDrawerOpen}
                onClose={this.viewModel.onDrawerClose}
                width={800}
                closable={false}
                className={clsx('homeDrawer', styles.drawerContainer)}
                bodyStyle={{ padding: 0 }}
              >
                {
                  this.viewModel.drawerType === HOME_PAGE_DRAWER.Announcement
                    ? (
                      <div className={styles.contentContainer}>
                        <div className={styles.titleContainer}>
                          <img src={DiamondIcon} alt="" />
                          <div className={styles.text}>
                            {i18n.t('home_announcement_setting')}
                          </div>
                        </div>
                        <Button
                          className={styles.addBtn}
                          icon={<PlusOutlined />}
                          onClick={this.viewModel.onAddAnnouncement}
                          disabled={this.viewModel.onNewAnnouncement}
                        >
                          {i18n.t('home_announcement_add')}
                        </Button>
                        <div className={clsx('homeTable', styles.tableContainer)}>
                          <Table
                            rowClassName={styles.row}
                            rowKey={(item) => item.id}
                            dataSource={this.viewModel.announcementList}
                            pagination={false}
                            key={this.viewModel.announcementList.length}
                          >
                            {
                              ANNOUNCEMENT_SETTING_TABLE.map((el) => {
                                if (el.key === 'isShow') {
                                  return (
                                    <Table.Column
                                      title={(
                                        <div className={styles.headerRow}>
                                          {el.title}
                                        </div>
                                      )}
                                      dataIndex={el.dataIndex}
                                      key={el.key}
                                      className={styles.columns}
                                      width={86}
                                      render={(text, record) => {
                                        return (
                                          <Observer>
                                            {() => {
                                              return (
                                                <Checkbox
                                                  checked={record.isEdit ? record.isShow : record.isShowCache}
                                                  disabled={!record.isEdit}
                                                  onChange={record.onIsShowChange}
                                                />
                                              );
                                            }}
                                          </Observer>
                                        );
                                      }}
                                    />
                                  );
                                }
                                if (el.key === 'date') {
                                  return (
                                    <Table.Column
                                      title={(
                                        <div className={styles.headerRow}>
                                          {el.title}
                                        </div>
                                      )}
                                      dataIndex={el.dataIndex}
                                      key={el.key}
                                      className={styles.columns}
                                      width={120}
                                      render={(text, record) => {
                                        return (
                                          <Observer>
                                            {() => {
                                              return (
                                                <div>
                                                  {
                                                    record.isEdit
                                                      ? (
                                                        <DatePicker
                                                          value={dayjs(record.date)}
                                                          suffixIcon={null}
                                                          allowClear={false}
                                                          className="tablePicker"
                                                          onChange={record.onDateChange}
                                                        />
                                                      )
                                                      : (
                                                        <div className={styles.dateText}>
                                                          {dayjs(record.dateCache).format('YYYY-MM-DD')}
                                                        </div>
                                                      )
                                                  }
                                                </div>
                                              );
                                            }}
                                          </Observer>
                                        );
                                      }}
                                    />
                                  );
                                }
                                if (el.key === 'action') {
                                  return (
                                    <Table.Column
                                      title={(
                                        <div className={styles.headerRow}>
                                          {el.title}
                                        </div>
                                      )}
                                      dataIndex={el.dataIndex}
                                      key={el.key}
                                      className={styles.columns}
                                      width={120}
                                      render={(_, record) => {
                                        return (
                                          <Observer>
                                            {() => {
                                              return (
                                                <div className={styles.actionRow}>
                                                  {
                                                    record.isEdit
                                                      ? (
                                                        <>
                                                          <div
                                                            className={styles.editBtn}
                                                            onClick={() => record.onSave(this.viewModel.onRefreshAnnouncement)}
                                                          >
                                                            {i18n.t('home_table_save')}
                                                          </div>
                                                          <div
                                                            className={styles.deleteBtn}
                                                            onClick={() => record.onCancel(this.viewModel.onDisposeNewAnnouncement)}
                                                          >
                                                            {i18n.t('home_table_cancel')}
                                                          </div>
                                                        </>
                                                      )
                                                      : (
                                                        <>
                                                          <div
                                                            className={styles.editBtn}
                                                            onClick={record.onEdit}
                                                          >
                                                            {i18n.t('home_table_edit')}
                                                          </div>
                                                          <div
                                                            className={styles.deleteBtn}
                                                            onClick={() => this.viewModel.onDeleteModal('announcement', record.id)}
                                                          >
                                                            {i18n.t('home_table_delete')}
                                                          </div>
                                                        </>
                                                      )
                                                  }
                                                </div>
                                              );
                                            }}
                                          </Observer>
                                        );
                                      }}
                                    />
                                  );
                                }
                                return (
                                  <Table.Column
                                    title={(
                                      <div className={styles.headerRow}>
                                        {el.title}
                                      </div>
                                    )}
                                    dataIndex={el.dataIndex}
                                    key={el.key}
                                    className={styles.columns}
                                    render={(text, record) => {
                                      return (
                                        <Observer>
                                          {() => {
                                            return (
                                              <div>
                                                {
                                                  record.isEdit
                                                    ? (
                                                      <Input.TextArea
                                                        autoSize
                                                        value={record.text}
                                                        onChange={record.onTextChange}
                                                        placeholder={i18n.t('home_announcement_text_placeholder')}
                                                        className={styles.textContent}
                                                      />
                                                    )
                                                    : (
                                                      <div className={styles.textContainer}>
                                                        {record.textCache}
                                                      </div>
                                                    )
                                                }
                                              </div>
                                            );
                                          }}
                                        </Observer>
                                      );
                                    }}
                                  />
                                );
                              })
                            }
                          </Table>
                        </div>
                      </div>
                    )
                    : (
                      <div className={styles.contentContainer}>
                        <div className={styles.titleContainer}>
                          <img src={DiamondIcon} alt="" />
                          <div className={styles.text}>
                            {i18n.t('home_service_setting')}
                          </div>
                        </div>
                        <Button
                          className={styles.addBtn}
                          icon={<PlusOutlined />}
                          onClick={this.viewModel.onAddService}
                        >
                          {i18n.t('home_service_add')}
                        </Button>
                        <div className={clsx('homeTable', styles.tableContainer)}>
                          <Table
                            rowClassName={styles.row}
                            rowKey={(item) => item.id}
                            dataSource={this.viewModel.serviceList}
                            pagination={false}
                            key={this.viewModel.serviceList.length}
                          >
                            {
                              SERVICE_SETTING_TABLE.map((el) => {
                                if (el.key === 'date') {
                                  return (
                                    <Table.Column
                                      title={(
                                        <div className={styles.headerRow}>
                                          {el.title}
                                        </div>
                                      )}
                                      dataIndex={el.dataIndex}
                                      key={el.key}
                                      className={styles.columns}
                                      width={110}
                                      render={(_, record) => {
                                        return (
                                          <Observer>
                                            {() => {
                                              return (
                                                <div>
                                                  {
                                                    record.isEdit
                                                      ? (
                                                        <DatePicker
                                                          value={dayjs(record.date)}
                                                          suffixIcon={null}
                                                          allowClear={false}
                                                          className="tablePicker"
                                                          onChange={record.onDateChange}
                                                        />
                                                      )
                                                      : (
                                                        <div className={styles.dateText}>
                                                          {dayjs(record.dateCache).format('YYYY-MM-DD')}
                                                        </div>
                                                      )
                                                  }
                                                </div>
                                              );
                                            }}
                                          </Observer>
                                        );
                                      }}
                                    />
                                  );
                                }
                                if (el.key === 'name') {
                                  return (
                                    <Table.Column
                                      title={(
                                        <div className={styles.headerRow}>
                                          {el.title}
                                        </div>
                                      )}
                                      dataIndex={el.dataIndex}
                                      key={el.key}
                                      className={styles.columns}
                                      width={150}
                                      render={(_, record) => {
                                        return (
                                          <Observer>
                                            {() => {
                                              return (
                                                <div>
                                                  {
                                                    record.isEdit
                                                      ? (
                                                        <Input
                                                          className={styles.tableInput}
                                                          value={record.name}
                                                          onChange={record.onNameChange}
                                                          placeholder={i18n.t('home_service_table_name_placeholder')}
                                                          maxLength={200}
                                                        />
                                                      )
                                                      : (
                                                        <div className={styles.textContainer}>
                                                          {record.nameCache}
                                                        </div>
                                                      )
                                                  }
                                                </div>
                                              );
                                            }}
                                          </Observer>
                                        );
                                      }}
                                    />
                                  );
                                }
                                if (el.key === 'action') {
                                  return (
                                    <Table.Column
                                      title={(
                                        <div className={styles.headerRow}>
                                          {el.title}
                                        </div>
                                      )}
                                      dataIndex={el.dataIndex}
                                      key={el.key}
                                      className={styles.columns}
                                      width={120}
                                      render={(_, record) => {
                                        return (
                                          <Observer>
                                            {() => {
                                              return (
                                                <div className={styles.actionRow}>
                                                  {
                                                    record.isEdit
                                                      ? (
                                                        <>
                                                          <div
                                                            className={styles.editBtn}
                                                            onClick={() => record.onSave(this.viewModel.onRefreshService)}
                                                          >
                                                            {i18n.t('home_table_save')}
                                                          </div>
                                                          <div
                                                            className={styles.deleteBtn}
                                                            onClick={() => record.onCancel(this.viewModel.onDisposeService)}
                                                          >
                                                            {i18n.t('home_table_cancel')}
                                                          </div>
                                                        </>
                                                      )
                                                      : (
                                                        <>
                                                          <div
                                                            className={styles.editBtn}
                                                            onClick={record.onEdit}
                                                          >
                                                            {i18n.t('home_table_edit')}
                                                          </div>
                                                          <div
                                                            className={styles.deleteBtn}
                                                            onClick={() => this.viewModel.onDeleteModal('service', record.id)}
                                                          >
                                                            {i18n.t('home_table_delete')}
                                                          </div>
                                                        </>
                                                      )
                                                  }
                                                </div>
                                              );
                                            }}
                                          </Observer>
                                        );
                                      }}
                                    />
                                  );
                                }
                                return (
                                  <Table.Column
                                    title={(
                                      <div className={styles.headerRow}>
                                        {el.title}
                                      </div>
                                    )}
                                    dataIndex={el.dataIndex}
                                    key={el.key}
                                    className={styles.columns}
                                    render={(text, record) => {
                                      return (
                                        <Observer>
                                          {() => {
                                            return (
                                              <div>
                                                {
                                                  record.isEdit
                                                    ? (
                                                      <Input
                                                        className={styles.tableInput}
                                                        value={record.url}
                                                        onChange={record.onUrlChange}
                                                        placeholder={i18n.t('home_service_table_url_placeholder')}
                                                      />
                                                    )
                                                    : (
                                                      <div className={styles.textContainer}>
                                                        {record.urlCache}
                                                      </div>
                                                    )
                                                }
                                              </div>
                                            );
                                          }}
                                        </Observer>
                                      );
                                    }}
                                  />
                                );
                              })
                            }
                          </Table>
                        </div>
                      </div>
                    )
                }
                <div
                  className={styles.customizeBack}
                  onClick={this.viewModel.onDrawerClose}
                >
                  <img src={RightArrowIcon} alt="" />
                </div>
              </Drawer>
              <Modal
                open={this.viewModel.isShowDeleteModal}
                onCancel={this.viewModel.onCancelDelete}
                footer={null}
                centered
                closable={false}
                className={styles.deleteModal}
              >
                <div className={styles.deleteContent}>
                  <div className={styles.deleteText}>
                    確定要刪除內容嗎？
                  </div>
                  <div className={styles.actions}>
                    <Button
                      className={styles.confirmBtn}
                      onClick={this.viewModel.onDeleteContent}
                    >
                      確定
                    </Button>
                    <Button
                      className={styles.cancelBtn}
                      onClick={this.viewModel.onCancelDelete}
                    >
                      取消
                    </Button>
                  </div>
                </div>
              </Modal>
            </>
          )
        }
      </>
    );
  }
}

export default HomePage;
