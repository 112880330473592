import { generateCompletedDayArray, getTotalDays, generateGroupColor, generateCSVKey } from 'src/utils/chart';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';

export default class ProductVolumeTrendModel {
  constructor(origin, prevOrigin, query) {
    const data = ProductVolumeTrendModel.generateData(origin);
    const previousData = ProductVolumeTrendModel.generateData(prevOrigin, 'previous');
    const colors = generateGroupColor(data, previousData);
    const csv = ProductVolumeTrendModel.generateCSV(origin, prevOrigin);
    const csvKey = generateCSVKey(csv[0]);
    this.data = {
      chartData: [
        {
          id: 'product_volume_trend',
          name: '產品聲量趨勢',
          data: [
            ...(
              data && !data.every((el) => el.series.every((date) => date.count === 0))
                ? [{
                  labels: data[0].series.map((el) => el.date),
                  datasets: data.map((el, i) => ({
                    label: el.name,
                    productId: el.id,
                    accurate: data[0].series.map((d) => d.accurate ?? d.date),
                    data: el.series.map((s) => s.count),
                    borderColor: colors[el.id]
                  }))
                }]
                : [{
                  type: 'nodata',
                  subType: data ? 'default' : 'ana-error'
                }]
            ),
            ...(
              previousData
                ? [{
                  labels: previousData[0].series.map((el) => el.date),
                  datasets: previousData.map((el, i) => ({
                    label: el.name,
                    productId: el.id,
                    accurate: previousData[0].series.map((d) => d.accurate ?? d.date),
                    data: el.series.map((s) => s.count),
                    borderColor: colors[el.id]
                  }))
                }]
                : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{
                        type: 'nodata',
                        subType: previousData ? 'default' : 'ana-error'
                      }]
                      : []
                  )
                ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType,
                category
              } = query;
              const { datasetIndex, index } = elements[0];
              const [gte, lte] = chart.data.datasets[datasetIndex].accurate[index].split('~');
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const productId = chart.data.datasets[datasetIndex].productId;
              const productName = chart.data.datasets[datasetIndex].label;

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category ? {
                  category: category.map((el) => ({
                    id: el,
                    name: SOURCE_CATEGORY_TYPE[el]
                  }))
                } : {
                  category: SOURCE_CATEGORY.map((el) => ({
                    id: el.value,
                    name: el.label
                  }))
                }),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/產品/聲量分析/產品聲量/產品聲量趨勢'
                },
                product: [{
                  id: productId,
                  name: productName
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'line',
          options: {
            responsive: true,
            event: ['click'],
            tension: 0.3,
            scales: {
              x: {
                title: {
                  display: true,
                  text: '日期'
                }
              },
              y: {
                beginAtZero: true,
                grace: 5,
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ]
    };
  }

  static generateData = (list, time = 'present') => {
    if (list.length === 0) {
      return null;
    }
    if (list.every((el) => el.series.every((item) => item.count === 0))) {
      return null;
    }
    const totalDays = getTotalDays(time);
    return list.slice(0, 20).map((ori) => {
      if (totalDays >= 32 && totalDays <= 90) {
        const dayArr = generateCompletedDayArray(time);
        ori.series.forEach((el) => {
          const target = dayArr.find((d) => d.days.includes(el.date));
          if (target) {
            target.count += el.count;
          }
        });
        return {
          ...ori,
          series: dayArr.map((el) => ({
            date: el.id,
            count: el.count
          }))
        };
      }

      if (totalDays >= 91) {
        const dayArr = generateCompletedDayArray(time);
        ori.series.forEach((el) => {
          const target = dayArr.find((d) => d.days.includes(el.date));
          if (target) {
            target.count += el.count;
          }
        });
        return {
          ...ori,
          series: dayArr.map((el) => ({
            date: el.id,
            accurate: `${el.days[0]}~${el.days[el.days.length - 1]}`,
            count: el.count
          }))
        };
      }

      const dayArr = generateCompletedDayArray(time);
      return {
        ...ori,
        series: dayArr.map((d) => ({
          date: d,
          count: ori.series.find((s) => s.date === d)?.count ?? 0
        }))
      };
    });
  };

  static generateCSV = (data, previousData) => {
    const csv = [];
    if (data.length > 0) {
      const currentCsv = data[0].series.map((el) => {
        const row = {
          date: el.date
        };
        for (let i = 0; i < data.length; i += 1) {
          row[`${data[i].name}`] = data[i].series.find((s) => s.date === el.date).count;
        }

        return row;
      });
      csv.push(...currentCsv);
    }

    if (previousData.length > 0) {
      const prevCsv = previousData[0].series.map((el) => {
        const row = {
          date: el.date
        };
        for (let i = 0; i < previousData.length; i += 1) {
          row[`${previousData[i].name}`] = previousData[i].series.find((s) => s.date === el.date).count;
        }

        return row;
      });
      csv.push(...prevCsv);
    }

    return csv;
  };

  static fromRes(data, prevData, query) {
    return new ProductVolumeTrendModel(data, prevData, query);
  }
}
