import { CATEGORY_COLOR_SET } from 'src/consts/chart';
import { SOURCE_CATEGORY_TYPE, SOURCE_ORDER_TC } from 'src/consts';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { generateCSVKey } from 'src/utils/chart';

export default class ProductSourceCategoryCompareModel {
  constructor(origin, prevOrigin, query) {
    const data = ProductSourceCategoryCompareModel.generateFormattedData(origin);
    const prevData = ProductSourceCategoryCompareModel.generateFormattedData(prevOrigin);
    const csv = ProductSourceCategoryCompareModel.generateCSV(origin, prevOrigin);
    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'product_source_category_compare',
          name: '產品來源類型堆疊',
          data: [
            ...(
              data?.length > 0 && !data.every((el) => el.item.every((item) => item.count === 0))
                ? [{
                  labels: data.map((el) => el.name),
                  datasets: data[0].item.map((el, i) => ({
                    label: SOURCE_CATEGORY_TYPE[el.name],
                    data: data.map((d) => d.item[i].count),
                    product: data.map((d) => ({ id: d.id, name: d.name })),
                    category: el.name,
                    backgroundColor: (context) => {
                      const chart = context.chart;
                      if (!chart.hovered) {
                        return CATEGORY_COLOR_SET[el.name];
                      }
                      return chart.hovered - 1 === context.datasetIndex ? CATEGORY_COLOR_SET[el.name] : '#a1a1a1';
                    },
                    borderSkipped: true,
                    maxBarThickness: 50
                  }))
                }]
                : [{
                  type: 'nodata',
                  subType: data?.length > 0 ? 'default' : 'ana-error'
                }]
            ),
            ...(
              prevData?.length > 0 && !prevData.every((el) => el.item.every((item) => item.count === 0))
                ? [{
                  labels: prevData.map((el) => el.name),
                  datasets: prevData[0].item.map((el, i) => ({
                    label: SOURCE_CATEGORY_TYPE[el.name],
                    time: 'previous',
                    data: prevData.map((d) => d.item[i].count),
                    product: data.map((d) => ({ id: d.id, name: d.name })),
                    category: el.name,
                    backgroundColor: (context) => {
                      const chart = context.chart;
                      if (!chart.hovered) {
                        return CATEGORY_COLOR_SET[el.name];
                      }
                      return chart.hovered - 1 === context.datasetIndex ? CATEGORY_COLOR_SET[el.name] : '#a1a1a1';
                    },
                    borderSkipped: true,
                    maxBarThickness: 50
                  }))
                }]
                : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{
                        type: 'nodata',
                        subType: prevData?.length > 0 ? 'default' : 'ana-error'
                      }]
                      : []
                  )
                ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType
              } = query;
              const { datasetIndex, index } = elements[0];
              const product = chart.data.datasets[datasetIndex].product[index];
              const category = chart.data.datasets[datasetIndex].category;
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const { currentDate, previousDate } = infoHeaderViewModel.multiViewModel;
              const currentTime = currentDate.map((el) => el.format('YYYY-MM-DD'));
              const previousTime = previousDate.map((el) => el.format('YYYY-MM-DD'));
              const [gte, lte] = chart.data.datasets[datasetIndex].time === 'present'
                ? currentTime
                : previousTime;

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                category: [{
                  id: category,
                  name: SOURCE_CATEGORY_TYPE[category]
                }],
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/產品/來源分析/產品來源類型/產品來源類型堆疊'
                },
                product: [{
                  id: product.id,
                  name: product.name
                }],
                womCount,
                date: {
                  gte,
                  lte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          onPointHover: (elements, ref) => {
            const chart = ref.current;
            if (elements.length > 0) {
              const datasetIndex = elements[0].datasetIndex;
              chart.hovered = datasetIndex + 1;
            } else {
              chart.hovered = null;
            }
            chart.update();
          },
          type: 'bar',
          options: {
            event: ['click'],
            scales: {
              x: {
                stacked: true
              },
              y: {
                stacked: true,
                grace: 5,
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom',
                labels: {
                  sort: (a, b) => {
                    const indexA = SOURCE_ORDER_TC.indexOf(a.text);
                    const indexB = SOURCE_ORDER_TC.indexOf(b.text);
                    return indexA - indexB;
                  }
                }
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ]
    };
  }
  static generateFormattedData = (list) => {
    return list?.slice(0, 20).map((el) => ({
      id: el.id,
      name: el.name,
      item: [
        {
          name: 'news',
          count: el.item.find((item) => item.name === 'news')?.count ?? 0
        },
        {
          name: 'forum',
          count: el.item.find((item) => item.name === 'forum')?.count ?? 0
        },
        {
          name: 'facebook',
          count: el.item.find((item) => item.name === 'facebook')?.count ?? 0
        },
        {
          name: 'ig',
          count: el.item.find((item) => item.name === 'ig')?.count ?? 0
        },
        {
          name: 'threads',
          count: el.item.find((item) => item.name === 'threads')?.count ?? 0
        },
        {
          name: 'video',
          count: el.item.find((item) => item.name === 'video')?.count ?? 0
        },
        {
          name: 'others',
          count: el.item.find((item) => item.name === 'others')?.count ?? 0
        }
      ]
    }));
  };

  static generateCSV = (list, prevList) => {
    const currTime = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD')).join('~');
    const prevTime = infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD')).join('~');
    const csv = [];
    const currCsv = list.map((el) => {
      const row = {
        date: currTime,
        產品名稱: el.name
      };
      for (let i = 0; i < el.item.length; i += 1) {
        row[`${SOURCE_CATEGORY_TYPE[el.item[i].name]}`] = el.item[i].count;
      }
      return row;
    });
    csv.push(...currCsv);
    const prevCsv = prevList.map((el) => {
      const row = {
        date: prevTime,
        產品名稱: el.name
      };
      for (let i = 0; i < el.item.length; i += 1) {
        row[`${SOURCE_CATEGORY_TYPE[el.item[i].name]}`] = el.item[i].count;
      }
      return row;
    });

    csv.push(...prevCsv);

    return csv;
  };

  static fromRes(data, query) {
    return new ProductSourceCategoryCompareModel(data, query);
  }
}
