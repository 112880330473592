import React from 'react';
import { makeObservable, observable, action, computed } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { AVG_LINE_COLOR } from 'src/consts/chart';

export default class CustomizeBarChartModel {
  type = 'customize-bar';
  ref = React.createRef();
  @observable key = '';
  @observable options = {};
  @observable origin = [];
  @observable blocks = [];

  @observable data = {
    labels: [],
    datasets: []
  };

  @computed get sum() {
    let count = 0;
    this.origin.forEach((el) => {
      count += el.count;
    });
    return count;
  }

  @computed get filterData() {
    return this.origin.filter((el) => !this.blocks.includes(el.brand.id));
  }

  @computed get chartData() {
    return {
      labels: this.filterData.map((el) => el.brand.name),
      datasets: [
        {
          label: '',
          data: this.filterData.map((el) => el.count / this.sum),
          type: 'line',
          yAxisID: 'y1',
          backgroundColor: AVG_LINE_COLOR,
          borderColor: AVG_LINE_COLOR
        },
        {
          label: '',
          data: this.filterData.map((el) => el.count),
          backgroundColor: this.filterData.map((el) => el.color),
          borderSkipped: true
        }
      ]
    };
  }

  constructor(data) {
    makeObservable(this);

    this.init(data);
  }

  @action init = (data) => {
    const { options } = data;
    this.key = uuidv4();
    this.origin = data.data.data;
    this.options = {
      ...data.options,
      plugins: {
        ...options.plugins,
        legend: {
          ...options.plugins.legend,
          labels: {
            ...options.plugins.legend?.labels,
            color: '#000'
          }
        }
      },
      scales: {
        ...options.scales,
        x: {
          ...options.scales.x,
          title: {
            ...options.scales.x?.title,
            color: '#000'
          },
          ticks: {
            ...options.scales.x?.ticks,
            color: '#000'
          }
        },
        y: {
          ...options.scales.y,
          title: {
            ...options.scales.y?.title,
            color: '#000'
          },
          ticks: {
            ...options.scales.y?.ticks,
            color: '#000'
          }
        },
        ...(options.scales.y1 && {
          y1: {
            ...options.scales.y1,
            title: {
              ...options.scales.y1?.title,
              color: '#000'
            },
            ticks: {
              ...options.scales.y1?.ticks,
              color: '#000'
            }
          }
        })
      },
      maintainAspectRatio: false,
      onClick: () => this.onBarClick(),
      onHover: (event, elements) => {
        const target = event.native.target;
        if (elements.length > 0) {
          target.style.cursor = 'pointer';
        } else {
          target.style.cursor = 'default';
        }
      }
    };
  };

  @action onBlock = (id) => {
    if (this.blocks.includes(id)) {
      this.blocks = this.blocks.filter((el) => el !== id);
    } else {
      this.blocks.push(id);
    }
  };

  @action onBarClick = (e) => {
    const activePoints = this.ref.current.getActiveElements();
    if (activePoints.length === 0) {
      return;
    }
    window.open(`${window.location.origin}/topic/${uuidv4()}`, '_blank');
  };
}

