import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import i18n from 'src/i18n';

import ChartFactory from 'src/components/Charts/factory';
import NoDataChart from 'src/components/Charts/NoData';

import { CloseOutlined } from '@ant-design/icons';

import { Tabs, Modal, Button } from 'antd';

import styles from './styles.module.scss';

@observer
class ChartItem extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <div className={clsx(styles.chartContainer)}>
        {
          viewModel.onReady
            ? (
              <>
                {
                  viewModel.data.length > 1
                  && (
                    <Tabs
                      activeKey={viewModel.activeTabId}
                      items={viewModel.data.map((el) => ({ key: el.id, label: el.name }))}
                      onChange={viewModel.onTabsChange}
                      className="chartTabs"
                    />
                  )
                }
                {
                  viewModel.showChart.map((el) => {
                    return (
                      <div key={el.key}>
                        {
                          ChartFactory.generateChartView(el)
                        }
                      </div>
                    );
                  })
                }
              </>
            )
            : (
              <NoDataChart subChart={ChartFactory.generateNoDataChart(null)} />
            )
        }
        <Modal
          open={viewModel.isModalOpen}
          onCancel={viewModel.onModalClose}
          width={500}
          centered
          closable={false}
          footer={null}
          className={styles.modal}
          bodyStyle={{
            height: 160,
            display: 'flex',
            flexDirection: 'column',
            rowGap: 36,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div className={styles.modalHeader}>
            <div className={styles.title}>
              {i18n.t('wordbook_page_modal_content').replace('%word%', viewModel.wordsViewModel?.selectedName)}
            </div>
            <CloseOutlined className={styles.close} onClick={viewModel.onModalClose} />
          </div>
          <div className={styles.btnContainer}>
            <Button
              className={clsx(styles.btn, styles.normalBtn)}
            >
              {i18n.t('wordbook_page_modal_action_topic')}
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

ChartItem.propTypes = {
  viewModel: PropTypes.object
};

ChartItem.defaultProps = {
  viewModel: {}
};


export default ChartItem;
