import { makeObservable, observable, action, computed, runInAction, reaction } from 'mobx';
import BlockedWordsService from 'src/services/blockedwords';
import { v4 as uuidv4 } from 'uuid';
import { message } from 'antd';

import WordCloud from 'wordcloud';

export default class FeatureWordCloudViewModel {
  type = 'words';
  key = uuidv4();
  @observable tops = '';
  @observable showItems = 20;
  @observable words = [];

  @observable selectedId = null;
  @observable selectedName = null;

  @observable isModalOpen = false;

  @observable parent = null;

  @observable mouseover = null;
  @observable mouseout = null;
  @observable resizeObserver = null;
  @observable width = 0;
  @observable widthCache = 0;

  @observable isDraw = false;
  @observable once = null;

  @computed get showWords() {
    return this.words.slice(0, this.showItems);
  }

  @computed get sortWords() {
    return this.showWords.sort((a, b) => b.count - a.count);
  }

  @computed get wordsArr() {
    return this.sortWords.map((el) => [el.name, el.count, el.color, el.id]);
  }

  @computed get config() {
    return {
      list: this.wordsArr,
      fontFamily: 'Noto Sans TC',
      rotateRatio: 0,
      color: (_, __, ___, ____, _____, extraData) => extraData[0],
      fontWeight: 600,
      classes: 'words-item',
      shrinkToFit: true,
      click: (item) => this.onWordClick({ id: item[3], name: item[0] })
    };
  }

  @computed get getWordById() {
    return this.words.find((el) => el.id === this.selectedId);
  }

  constructor(words, parent) {
    makeObservable(this);

    this.init(words, parent);
  }

  @action init = (words, parent) => {
    this.parent = parent;
    this.updateContent(words);
  };

  @action updateContent = (words) => {
    this.words = words.data;

    this.tops = words.id;
  };

  didMount = () => {
    const resizeObserver = new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;
      runInAction(() => {
        this.width = width;
      });
    });
    const container = document.querySelector(`#BrandFeatureWord${this.tops}`);
    const mouseover = container.addEventListener('mouseover', (e) => { this.onMouseOverWord(e.target); });
    const mouseout = container.addEventListener('mouseout', (e) => { this.onMouseOutWord(e.target); });

    const once = reaction(
      () => this.width,
      (number) => {
        if (this.widthCache === 0) {
          this.widthCache = this.width;
        }

        if (Math.abs(this.width - this.widthCache) > 50) {
          this.widthCache = number;
          this.onDraw();
        }
      }
    );

    runInAction(() => {
      this.mouseover = mouseover;
      this.mouseout = mouseout;
      this.resizeObserver = resizeObserver;
      this.resizeObserver.observe(container);
      this.once = once;
    });

    this.onDraw();
  };

  @action onDraw = (count) => {
    if (this.isDraw || this.words.length < 1) {
      return;
    }
    this.isDraw = true;

    this.showItems = count;
    const container = document.querySelector(`#BrandFeatureWord${this.tops}`);
    WordCloud(container, this.config);

    container.addEventListener('wordclouddrawn', (e) => {
      const name = e.detail.item[0];
      const arr = Array.prototype.slice.call(e.target.children);
      const target = arr[arr.length - 1];
      const tooltip = document.createElement('DIV');
      const times = this.words.find((el) => el.name === name).originCount;

      tooltip.innerText = `「${name}」 - 提及則數：${times} `;
      tooltip.classList.add('tooltip');
      target.appendChild(tooltip);
    });

    this.isDraw = false;
  };

  willUnmount = () => {
    const container = document.querySelector(`#BrandFeatureWord${this.tops}`);
    container.removeEventListener('mouseover', this.mouseover);
    container.removeEventListener('mouseout', this.mouseout);
    this.resizeObserver.unobserve(container);
    this.once?.();
  };

  onMouseOverWord = (target) => {
    const isWord = target.classList.contains('words-item');
    if (!isWord) {
      return;
    }
    target.classList.add('words-item-hover');
  };

  onMouseOutWord = (target) => {
    const isActive = target.classList.contains('words-item-hover');
    if (!isActive) {
      return;
    }
    target.classList.remove('words-item-hover');
  };

  @action updateWords = () => {

  };

  @action onWordClick = ({ id }) => {
    this.selectedId = id;
    this.parent.onModalOpen(this.getWordById);
  };

  @action onModalClose = () => {
    this.selectedId = null;
    this.selectedName = null;
  };

  @action onRemoveItem = async (e, id, text) => {
    e.stopPropagation();
    try {
      await BlockedWordsService.addBlockWords(text);
      runInAction(() => {
        this.words = this.words.filter((el) => el.id !== id);
      });
    } catch (error) {
      if (error.response?.status === 409) {
        message.error('該詞已在屏蔽名單中!');
        return;
      }
      message.error('無法新增屏蔽詞，請稍候再試');
    } finally {
      this.onMouseLeave();
    }
  };

  @action updateShowItems = (count) => {
    this.showItems = count;
  };
}
