import API from 'src/apis';
import packageStore from 'src/stores/packageStore';
import ChartsModel from 'src/models/response/charts';

export default class ChartService {

  static getPackageId = () => {
    return packageStore.activePackageId;
  };

  static async getTopTenBrands(data) {
    const res = await API.chartAnalysis.brand.volume.getBrandVolumeCount(ChartService.getPackageId(), data);

    return res.data.result.slice(0, 10).sort((a, b) => b.count - a.count);
  }

  static async getTopTenSeries(data) {
    const res = await API.chartAnalysis.series.volume.getSeriesVolumeCount(ChartService.getPackageId(), data);

    return res.data.result.slice(0, 10).sort((a, b) => b.count - a.count);
  }

  static async getTopTwentyProducts(data) {
    const res = await API.chartAnalysis.product.volume.getProductVolumeCount(ChartService.getPackageId(), data);

    return res.data.result.slice(0, 20).sort((a, b) => b.count - a.count);
  }

  // > industry
  static async getIndustryVolumeTrend(data, prevData) {
    const [res, previousRes] = await Promise.all([
      API.chartAnalysis.industry.volume.getIndustryVolumeTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.industry.volume.getIndustryVolumeTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Industry.Volume.IndustryVolumeTrendModel.fromRes(res.data.result, previousRes.data.result, data);
  }

  static async getIndustryVolumeProportion(data, prevData) {
    const [res, previousRes] = await Promise.all([
      API.chartAnalysis.industry.volume.getIndustryVolumeProportion(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.industry.volume.getIndustryVolumeProportion(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Industry.Volume.IndustryVolumeProportionModel.fromRes(res.data.result, previousRes.data.result, data);
  }

  static async getIndustrySourceCategoryTrend(data, prevData) {
    const [res, previousRes] = await Promise.all([
      API.chartAnalysis.industry.source.category.getIndustrySourceCategoryTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.industry.source.category.getIndustrySourceCategoryTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Industry.Source.IndustrySourceCategoryTrendModel.fromRes(res.data.result, previousRes.data.result, data);
  }

  static async getIndustrySourceCategoryRanking(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.industry.source.category.getIndustrySourceCategoryCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.industry.source.category.getIndustrySourceCategoryCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Industry.Source.IndustrySourceCategoryRankingModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getIndustrySourceCategoryProportion(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.industry.source.category.getIndustrySourceCategoryCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.industry.source.category.getIndustrySourceCategoryCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Industry.Source.IndustrySourceCategoryProportionModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getIndustrySourceWebsiteTrend(data, prevData) {
    const [res, previousRes] = await Promise.all([
      API.chartAnalysis.industry.source.website.getIndustrySourceWebsiteTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.industry.source.website.getIndustrySourceWebsiteTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Industry.Source.IndustrySourceWebsiteTrendModel.fromRes(res.data.result, previousRes.data.result, data);
  }

  static async getIndustrySourceWebsiteRanking(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.industry.source.website.getIndustrySourceWebsiteCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.industry.source.website.getIndustrySourceWebsiteCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Industry.Source.IndustrySourceWebsiteRankingModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getIndustrySourceWebsiteProportion(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.industry.source.website.getIndustrySourceWebsiteCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.industry.source.website.getIndustrySourceWebsiteCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Industry.Source.IndustrySourceWebsiteProportionModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getIndustrySourceChannelTrend(data, prevData) {
    const [res, previousRes] = await Promise.all([
      API.chartAnalysis.industry.source.channel.getIndustrySourceChannelTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.industry.source.channel.getIndustrySourceChannelTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Industry.Source.IndustrySourceChannelTrendModel.fromRes(res.data.result, previousRes.data.result, data);
  }

  static async getIndustrySourceChannelRanking(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.industry.source.channel.getIndustrySourceChannelCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.industry.source.channel.getIndustrySourceChannelCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Industry.Source.IndustrySourceChannelRankingModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getIndustrySourceChannelProportion(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.industry.source.channel.getIndustrySourceChannelCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.industry.source.channel.getIndustrySourceChannelCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Industry.Source.IndustrySourceChannelProportionModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getIndustrySourceSocialActivity(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.industry.source.getIndustrySocialActivity(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.industry.source.getIndustrySocialActivity(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Industry.Source.IndustrySourceSocialActivityModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getIndustrySentimentOverview(data, prevData) {
    const [res, previousRes] = await Promise.all([
      API.chartAnalysis.industry.sentiment.getIndustrySentimentCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.industry.sentiment.getIndustrySentimentCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: {} } }); })
    ]);

    return ChartsModel.ChartAnalysis.Industry.Sentiment.IndustrySentimentOverviewModel.fromRes(res.data.result, previousRes.data.result, data);
  }

  static async getIndustrySentimentTrend(data, prevData) {
    const [res, previousRes] = await Promise.all([
      API.chartAnalysis.industry.sentiment.getIndustrySentimentTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.industry.sentiment.getIndustrySentimentTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Industry.Sentiment.IndustrySentimentTrendModel.fromRes(res.data.result, previousRes.data.result, data);
  }

  static async getIndustrySentimentVolume(data, prevData) {
    const [res, previousRes] = await Promise.all([
      API.chartAnalysis.industry.sentiment.getIndustrySentimentCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.industry.sentiment.getIndustrySentimentCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: {} } }); })
    ]);

    return ChartsModel.ChartAnalysis.Industry.Sentiment.IndustrySentimentVolumeModel.fromRes(res.data.result, previousRes.data.result, data);
  }

  static async getIndustryHotWords(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.industry.keyword.getIndustryHotWord(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.industry.keyword.getIndustryHotWord(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Industry.Keyword.HotWordsModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getIndustryHotWordsDiscover(data) {
    const res = await API.chartAnalysis.industry.keyword.getIndustryHotWordDiscover(ChartService.getPackageId(), data);

    return ChartsModel.ChartAnalysis.Industry.Keyword.HotWordsModel.fromRes(res.data.result, [], data);
  }

  static async getIndustryNewWords(data) {
    if (!data) {
      return ChartsModel.ChartAnalysis.Industry.Keyword.NewWordsModel.fromRes([], data);
    }
    const res = await API.chartAnalysis.industry.keyword.getIndustryNewWord(ChartService.getPackageId(), data);

    return ChartsModel.ChartAnalysis.Industry.Keyword.NewWordsModel.fromRes(res.data.result, data);
  }

  static async getIndustryTrendWords(data) {
    if (!data) {
      return ChartsModel.ChartAnalysis.Industry.Keyword.TrendWordsModel.fromRes([], data);
    }
    const res = await API.chartAnalysis.industry.keyword.getIndustryTrendWord(ChartService.getPackageId(), data);

    return ChartsModel.ChartAnalysis.Industry.Keyword.TrendWordsModel.fromRes(res.data.result, res.data.stats, data);
  }

  static async getIndustryHotWordAssocs(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.industry.keyword.getIndustryHotWordAssoc(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.industry.keyword.getIndustryHotWordAssoc(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Industry.Keyword.HotWordAssocsModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  // > brand

  static async getBrandVolumeTrend(data, prevData) {
    const [res, previousRes] = await Promise.all([
      API.chartAnalysis.brand.volume.getBrandVolumeTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.brand.volume.getBrandVolumeTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Brand.Volume.BrandVolumeTrendModel.fromRes(res.data.result, previousRes.data.result, data);
  }

  static async getBrandVolumeRanking(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.brand.volume.getBrandVolumeCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.brand.volume.getBrandVolumeCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Brand.Volume.BrandVolumeRankingModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getBrandVolumeProportion(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.brand.volume.getBrandVolumeCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.brand.volume.getBrandVolumeCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Brand.Volume.BrandVolumeProportionModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getBrandSourceCategoryTrend(data, prevData) {
    const [res, previousRes] = await Promise.all([
      API.chartAnalysis.brand.source.category.getBrandSourceCategoryTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.brand.source.category.getBrandSourceCategoryTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Brand.Source.Category.BrandSourceCategoryTrendModel.fromRes(res.data.result, previousRes.data.result, data);
  }

  static async getBrandSourceCategoryRanking(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.brand.source.category.getBrandSourceCategoryCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.brand.source.category.getBrandSourceCategoryCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Brand.Source.Category.BrandSourceCategoryRankingModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getBrandSourceCategoryProportion(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.brand.source.category.getBrandSourceCategoryCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.brand.source.category.getBrandSourceCategoryCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Brand.Source.Category.BrandSourceCategoryProportionModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getBrandSourceCategoryCompare(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.brand.source.category.getBrandSourceCategoryCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.brand.source.category.getBrandSourceCategoryCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Brand.Source.Category.BrandSourceCategoryCompareModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getBrandSourceWebsiteTrend(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.brand.source.website.getBrandSourceWebsiteTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.brand.source.website.getBrandSourceWebsiteTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Brand.Source.Website.BrandSourceWebsiteTrendModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getBrandSourceWebsiteRanking(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.brand.source.website.getBrandSourceWebsiteCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.brand.source.website.getBrandSourceWebsiteCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Brand.Source.Website.BrandSourceWebsiteRankingModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getBrandSourceWebsiteProportion(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.brand.source.website.getBrandSourceWebsiteCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.brand.source.website.getBrandSourceWebsiteCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Brand.Source.Website.BrandSourceWebsiteProportionModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getBrandSourceWebsiteCompare(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.brand.source.website.getBrandSourceWebsiteCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.brand.source.website.getBrandSourceWebsiteCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Brand.Source.Website.BrandSourceWebsiteCompareModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getBrandSourceChannelTrend(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.brand.source.channel.getBrandSourceChannelTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.brand.source.channel.getBrandSourceChannelTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Brand.Source.Channel.BrandSourceChannelTrendModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getBrandSourceChannelRanking(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.brand.source.channel.getBrandSourceChannelCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.brand.source.channel.getBrandSourceChannelCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Brand.Source.Channel.BrandSourceChannelRankingModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getBrandSourceChannelProportion(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.brand.source.channel.getBrandSourceChannelCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.brand.source.channel.getBrandSourceChannelCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Brand.Source.Channel.BrandSourceChannelProportionModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getBrandSourceChannelCompare(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.brand.source.channel.getBrandSourceChannelCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.brand.source.channel.getBrandSourceChannelCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Brand.Source.Channel.BrandSourceChannelCompareModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getBrandSourceSocialActivity(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.brand.source.getBrandSocialActivity(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.brand.source.getBrandSocialActivity(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Brand.Source.BrandSourceSocialActivityModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getBrandSentimentOverview(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.brand.sentiment.getSentimentCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.brand.sentiment.getSentimentCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Brand.Sentiment.BrandSentimentOverviewModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getSingleBrandSentimentTrend(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.brand.sentiment.getSentimentTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.brand.sentiment.getSentimentTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Brand.Sentiment.SingleBrandSentimentTrendModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getBrandPositiveSentimentTrend(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.brand.sentiment.getSentimentTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.brand.sentiment.getSentimentTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Brand.Sentiment.BrandPositiveSentimentTrendModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getBrandNegativeSentimentTrend(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.brand.sentiment.getSentimentTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.brand.sentiment.getSentimentTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Brand.Sentiment.BrandNegativeSentimentTrendModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getBrandSentimentVolume(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.brand.sentiment.getSentimentCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.brand.sentiment.getSentimentCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Brand.Sentiment.BrandSentimentVolumeModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  // > get top 10 brands first before get HotWords;

  static async getTopTenBrand(data) {
    const res = await API.chartAnalysis.brand.volume.getBrandVolumeCount(ChartService.getPackageId(), data);

    return ChartsModel.ChartAnalysis.Brand.Keyword.TopTenBrandModel.fromRes(res.data.result);
  }

  static async getBrandHotWords(data, prevData, search) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.brand.keyword.getBrandHotWords(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.brand.keyword.getBrandHotWords(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Brand.Keyword.HotWordsModel.fromRes(res.data.result, prevRes.data.result, data, search);
  }

  static async getBrandHotWordsDiscover(data, search) {
    const res = await API.chartAnalysis.brand.keyword.getBrandHotWordsDiscover(ChartService.getPackageId(), data);

    return ChartsModel.ChartAnalysis.Brand.Keyword.HotWordsModel.fromRes(res.data.result, [], data, search);
  }

  static async getBrandNewWords(data, search) {
    const res = await API.chartAnalysis.brand.keyword.getBrandNewWords(ChartService.getPackageId(), data);

    return ChartsModel.ChartAnalysis.Brand.Keyword.NewWordsModel.fromRes(res.data.result, data, search);
  }

  static async getBrandTrendWords(data, search) {
    const res = await API.chartAnalysis.brand.keyword.getBrandTrendWords(ChartService.getPackageId(), data);

    return ChartsModel.ChartAnalysis.Brand.Keyword.TrendWordsModel.fromRes(res.data.result, res.data.stats, data, search);
  }

  static async getBrandHotWordAssocs(data, prevData, search) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.brand.keyword.getBrandHotWordsAssoc(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.brand.keyword.getBrandHotWordsAssoc(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Brand.Keyword.HotWordAssocsModel.fromRes(res.data.result, prevRes.data.result, data, search);
  }

  // > series

  static async getSeriesVolumeTrend(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.series.volume.getSeriesVolumeTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.series.volume.getSeriesVolumeTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Series.Volume.Series.SeriesVolumeTrendModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getSeriesVolumeRanking(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.series.volume.getSeriesVolumeCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.series.volume.getSeriesVolumeCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Series.Volume.Series.SeriesVolumeRankingModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getSeriesVolumeProportion(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.series.volume.getSeriesVolumeCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.series.volume.getSeriesVolumeCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Series.Volume.Series.SeriesVolumeProportionModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getSeriesBrandVolumeTrend(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.series.volume.getBrandSeriesVolumeTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.series.volume.getBrandSeriesVolumeTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Series.Volume.Brand.SeriesBrandVolumeTrendModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getSeriesBrandVolumeRanking(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.series.volume.getBrandSeriesVolumeCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.series.volume.getBrandSeriesVolumeCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Series.Volume.Brand.SeriesBrandVolumeRankingModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getSeriesBrandVolumeProportion(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.series.volume.getBrandSeriesVolumeCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.series.volume.getBrandSeriesVolumeCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Series.Volume.Brand.SeriesBrandVolumeProportionModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getSeriesBrandVolumeCompare(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.series.volume.getBrandSeriesVolumeCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.series.volume.getBrandSeriesVolumeCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Series.Volume.Brand.SeriesBrandVolumeCompareModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getSeriesSourceCategoryTrend(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.series.source.category.getSourceCategoryTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.series.source.category.getSourceCategoryTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Series.Source.Category.SeriesSourceCategoryTrendModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getSeriesSourceCategoryRanking(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.series.source.category.getSourceCategoryCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.series.source.category.getSourceCategoryCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Series.Source.Category.SeriesSourceCategoryRankingModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getSeriesSourceCategoryProportion(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.series.source.category.getSourceCategoryCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.series.source.category.getSourceCategoryCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Series.Source.Category.SeriesSourceCategoryProportionModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getSeriesSourceCategoryCompare(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.series.source.category.getSourceCategoryCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.series.source.category.getSourceCategoryCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Series.Source.Category.SeriesSourceCategoryCompareModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getSeriesSourceWebsiteTrend(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.series.source.website.getSourceWebsiteTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.series.source.website.getSourceWebsiteTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Series.Source.Website.SeriesSourceWebsiteTrendModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getSeriesSourceWebsiteRanking(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.series.source.website.getSourceWebsiteCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.series.source.website.getSourceWebsiteCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Series.Source.Website.SeriesSourceWebsiteRankingModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getSeriesSourceWebsiteProportion(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.series.source.website.getSourceWebsiteCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.series.source.website.getSourceWebsiteCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Series.Source.Website.SeriesSourceWebsiteProportionModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getSeriesSourceWebsiteCompare(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.series.source.website.getSourceWebsiteCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.series.source.website.getSourceWebsiteCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Series.Source.Website.SeriesSourceWebsiteCompareModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getSeriesSourceChannelTrend(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.series.source.channel.getSourceChannelTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.series.source.channel.getSourceChannelTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Series.Source.Channel.SeriesSourceChannelTrendModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getSeriesSourceChannelRanking(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.series.source.channel.getSourceChannelCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.series.source.channel.getSourceChannelCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Series.Source.Channel.SeriesSourceChannelRankingModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getSeriesSourceChannelProportion(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.series.source.channel.getSourceChannelCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.series.source.channel.getSourceChannelCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Series.Source.Channel.SeriesSourceChannelProportionModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getSeriesSourceChannelCompare(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.series.source.channel.getSourceChannelCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.series.source.channel.getSourceChannelCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Series.Source.Channel.SeriesSourceChannelCompareModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  // > get top 10 series first before get HotWords;

  static async getTopTenSeriesForWords(data) {
    const res = await API.chartAnalysis.series.volume.getSeriesVolumeCount(ChartService.getPackageId(), data);

    return ChartsModel.ChartAnalysis.Series.Keyword.TopTenSeriesModel.fromRes(res.data.result);
  }

  static async getSeriesHotWords(data, prevData, search) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.series.keyword.getSeriesHotWord(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.series.keyword.getSeriesHotWord(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Series.Keyword.HotWordsModel.fromRes(res.data.result, prevRes.data.result, data, search);
  }

  static async getSeriesHotWordsDiscover(data, search) {
    const res = await API.chartAnalysis.series.keyword.getSeriesHotWordDiscover(ChartService.getPackageId(), data);

    return ChartsModel.ChartAnalysis.Series.Keyword.HotWordsModel.fromRes(res.data.result, [], data, search);
  }

  static async getSeriesNewWords(data, search) {
    const res = await API.chartAnalysis.series.keyword.getSeriesNewWord(ChartService.getPackageId(), data);

    return ChartsModel.ChartAnalysis.Series.Keyword.NewWordsModel.fromRes(res.data.result, data, search);
  }

  static async getSeriesTrendWords(data, search) {
    const res = await API.chartAnalysis.series.keyword.getSeriesTrendWord(ChartService.getPackageId(), data);

    return ChartsModel.ChartAnalysis.Series.Keyword.TrendWordsModel.fromRes(res.data.result, res.data.stats, data, search);
  }

  static async getSeriesHotWordAssocs(data, prevData, search) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.series.keyword.getSeriesHotWordAssoc(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.series.keyword.getSeriesHotWordAssoc(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Series.Keyword.HotWordAssocsModel.fromRes(res.data.result, prevRes.data.result, data, search);
  }

  // > product

  static async getProductVolumeTrend(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.volume.getProductVolumeTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.volume.getProductVolumeTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Volume.Product.ProductVolumeTrendModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getProductVolumeRanking(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.volume.getProductVolumeCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.volume.getProductVolumeCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Volume.Product.ProductVolumeRankingModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getProductVolumeProportion(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.volume.getProductVolumeCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.volume.getProductVolumeCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Volume.Product.ProductVolumeProportionModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getProductSeriesVolumeTrend(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.volume.getSeriesProductVolumeTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.volume.getSeriesProductVolumeTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Volume.Series.ProductSeriesVolumeTrendModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getProductSeriesVolumeRanking(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.volume.getSeriesProductVolumeCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.volume.getSeriesProductVolumeCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Volume.Series.ProductSeriesVolumeRankingModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getProductSeriesVolumeProportion(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.volume.getSeriesProductVolumeCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.volume.getSeriesProductVolumeCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Volume.Series.ProductSeriesVolumeProportionModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getProductSeriesVolumeCompare(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.volume.getSeriesProductVolumeCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.volume.getSeriesProductVolumeCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Volume.Series.ProductSeriesVolumeCompareModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getProductSourceCategoryTrend(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.source.category.getSourceCategoryTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.source.category.getSourceCategoryTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Source.Category.ProductSourceCategoryTrendModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getProductSourceCategoryRanking(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.source.category.getSourceCategoryCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.source.category.getSourceCategoryCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Source.Category.ProductSourceCategoryRankingModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getProductSourceCategoryProportion(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.source.category.getSourceCategoryCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.source.category.getSourceCategoryCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Source.Category.ProductSourceCategoryProportionModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getProductSourceCategoryCompare(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.source.category.getSourceCategoryCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.source.category.getSourceCategoryCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Source.Category.ProductSourceCategoryCompareModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getProductSourceWebsiteTrend(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.source.website.getSourceWebsiteTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.source.website.getSourceWebsiteTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Source.Website.ProductSourceWebsiteTrendModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getProductSourceWebsiteRanking(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.source.website.getSourceWebsiteCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.source.website.getSourceWebsiteCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Source.Website.ProductSourceWebsiteRankingModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getProductSourceWebsiteProportion(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.source.website.getSourceWebsiteCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.source.website.getSourceWebsiteCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Source.Website.ProductSourceWebsiteProportionModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getProductSourceWebsiteCompare(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.source.website.getSourceWebsiteCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.source.website.getSourceWebsiteCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Source.Website.ProductSourceWebsiteCompareModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getProductSourceChannelTrend(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.source.channel.getSourceChannelTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.source.channel.getSourceChannelTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Source.Channel.ProductSourceChannelTrendModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getProductSourceChannelRanking(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.source.channel.getSourceChannelCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.source.channel.getSourceChannelCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Source.Channel.ProductSourceChannelRankingModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getProductSourceChannelProportion(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.source.channel.getSourceChannelCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.source.channel.getSourceChannelCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Source.Channel.ProductSourceChannelProportionModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getProductSourceChannelCompare(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.source.channel.getSourceChannelCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.source.channel.getSourceChannelCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Source.Channel.ProductSourceChannelCompareModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getProductSourceSocialActivity(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.source.getSourceActivity(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.source.getSourceActivity(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Source.ProductSourceSocialActivityModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getProductSentimentOverview(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.sentiment.getSentimentCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.sentiment.getSentimentCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Sentiment.ProductSentimentOverviewModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getSingleProductSentimentTrend(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.sentiment.getSentimentTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.sentiment.getSentimentTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Sentiment.SingleProductSentimentTrendModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getProductPositiveSentimentTrend(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.sentiment.getSentimentTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.sentiment.getSentimentTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Sentiment.ProductPositiveSentimentTrendModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getProductNegativeSentimentTrend(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.sentiment.getSentimentTrend(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.sentiment.getSentimentTrend(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Sentiment.ProductNegativeSentimentTrendModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getProductSentimentVolume(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.sentiment.getSentimentCount(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.sentiment.getSentimentCount(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Sentiment.ProductSentimentVolumeModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  // > get top 20 product first before get HotWords;

  static async getTopTwentyProduct(data) {
    const res = await API.chartAnalysis.product.volume.getProductVolumeCount(ChartService.getPackageId(), data);

    return ChartsModel.ChartAnalysis.Product.Keyword.TopTwentyProductModel.fromRes(res.data.result, data);
  }

  static async getProductHotWords(data, prevData, search) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.keyword.getProductHotWord(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.keyword.getProductHotWord(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Keyword.HotWordsModel.fromRes(res.data.result, prevRes.data.result, data, search);
  }

  static async getProductHotWordsDiscover(data, search) {
    const res = await API.chartAnalysis.product.keyword.getProductHotWordDiscover(ChartService.getPackageId(), data);

    return ChartsModel.ChartAnalysis.Product.Keyword.HotWordsModel.fromRes(res.data.result, [], data, search);
  }

  static async getProductNewWords(data, search) {
    const res = await API.chartAnalysis.product.keyword.getProductNewWord(ChartService.getPackageId(), data);

    return ChartsModel.ChartAnalysis.Product.Keyword.NewWordsModel.fromRes(res.data.result, data, search);
  }

  static async getProductTrendWords(data, search) {
    const res = await API.chartAnalysis.product.keyword.getProductTrendWord(ChartService.getPackageId(), data);

    return ChartsModel.ChartAnalysis.Product.Keyword.TrendWordsModel.fromRes(res.data.result, res.data.stats, data, search);
  }

  static async getProductHotWordAssocs(data, prevData, search) {
    const [res, prevRes] = await Promise.all([
      API.chartAnalysis.product.keyword.getProductHotWordAssoc(ChartService.getPackageId(), data),
      prevData
        ? API.chartAnalysis.product.keyword.getProductHotWordAssoc(ChartService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.ChartAnalysis.Product.Keyword.HotWordAssocsModel.fromRes(res.data.result, prevRes.data.result, data, search);
  }

  static async getHotWordsDiscover(level, params, search) {
    switch (level) {
      case 'brand':
        return ChartService.getBrandHotWordsDiscover(params, search);
      case 'series':
        return ChartService.getSeriesHotWordsDiscover(params, search);
      case 'product':
        return ChartService.getProductHotWordsDiscover(params, search);
      default:
        return null;
    }
  }
}
