import { request, getHost, getHeaders } from './utils';

export const getMarketing7PSetting = (pid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/dataPackage/${pid}/advancedAnalysis/marketing7p`,
    headers: getHeaders()
  };
  return request(options);
};

export const postMarketing7PSetting = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/dataPackage/${pid}/advancedAnalysis/marketing7p`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getMindShareSetting = (pid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/dataPackage/${pid}/advancedAnalysis/mindShare`,
    headers: getHeaders()
  };
  return request(options);
};

export const postMindShareSetting = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/dataPackage/${pid}/advancedAnalysis/mindShare`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getStarProductSetting = (pid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/dataPackage/${pid}/advancedAnalysis/starProduct`,
    headers: getHeaders()
  };
  return request(options);
};

export const postStarProductSetting = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/dataPackage/${pid}/advancedAnalysis/starProduct`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getMindShare = (pid, params) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/vocService/package/${pid}/adv-analysis/mindShare`,
    headers: getHeaders(),
    params
  };
  return request(options);
};

export const getStarProduct = (pid, params) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/vocService/package/${pid}/adv-analysis/starProduct`,
    headers: getHeaders(),
    params
  };
  return request(options);
};

export const getMarket7pCount = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/adv-analysis/7p/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getMarket7pWord = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/adv-analysis/7p/term`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandReputation = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/adv-analysis/brand/reputation`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getProductReputation = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/adv-analysis/product/reputation`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const stopAnalysis = (pid, jid) => {
  const options = {
    method: 'delete',
    url: `${getHost()}/api/vocService/package/${pid}/adv-analysis/job/${jid}`,
    headers: getHeaders()
  };
  return request(options);
};
