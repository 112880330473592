export default class BrandModel {
  constructor(data) {
    const { _id, name, checked } = data.keyword;
    this.id = _id;
    this.name = name;
    this.checked = checked ?? false;
  }

  static fromRes(data) {
    return new BrandModel(data);
  }
}
