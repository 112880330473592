import SeriesVolumeTrend from './SeriesVolumeTrend';
import SeriesVolumeProportion from './SeriesVolumeProportion';
import SeriesBrandVolumeCompare from './SeriesBrandVolumeCompare';
import SeriesProductVolumeCompare from './SeriesProductVolumeCompare';
import SeriesSourceCategoryCompare from './SeriesSourceCategoryCompare';
import TopsSeriesBrandVolumeProportion from './TopsSeriesBrandVolumeProportion';
import TopsSeriesProductVolumeRanking from './TopsSeriesProductVolumeRanking';
import TopsSeriesWord from './TopsSeriesWords';


import SeriesVolumeTrendViewModel from './SeriesVolumeTrend/viewModel';
import SeriesVolumeProportionViewModel from './SeriesVolumeProportion/viewModel';
import SeriesBrandVolumeCompareViewModel from './SeriesBrandVolumeCompare/viewModel';
import SeriesProductVolumeCompareViewModel from './SeriesProductVolumeCompare/viewModel';
import SeriesSourceCategoryCompareViewModel from './SeriesSourceCategoryCompare/viewModel';
import TopsSeriesBrandVolumeProportionViewModel from './TopsSeriesBrandVolumeProportion/viewModel';
import TopsSeriesProductVolumeRankingViewModel from './TopsSeriesProductVolumeRanking/viewModel';
import TopsSeriesWordViewModel from './TopsSeriesWords/viewModel';

export default class MarketDifferentiationFactory {
  static createChartViewModel(id, parent) {
    switch (id) {
      case 'series_volume_trend':
        return new SeriesVolumeTrendViewModel(parent);
      case 'series_volume_proportion':
        return new SeriesVolumeProportionViewModel(parent);
      case 'series_brand_volume_compare':
        return new SeriesBrandVolumeCompareViewModel(parent);
      case 'series_product_volume_compare':
        return new SeriesProductVolumeCompareViewModel(parent);
      case 'series_source_category_compare':
        return new SeriesSourceCategoryCompareViewModel(parent);
      case 'series_top1_brand_volume_proportion':
        return new TopsSeriesBrandVolumeProportionViewModel(parent, 'top1');
      case 'series_top2_brand_volume_proportion':
        return new TopsSeriesBrandVolumeProportionViewModel(parent, 'top2');
      case 'series_top3_brand_volume_proportion':
        return new TopsSeriesBrandVolumeProportionViewModel(parent, 'top3');
      case 'series_top1_product_volume_ranking':
        return new TopsSeriesProductVolumeRankingViewModel(parent, 'top1');
      case 'series_top2_product_volume_ranking':
        return new TopsSeriesProductVolumeRankingViewModel(parent, 'top2');
      case 'series_top3_product_volume_ranking':
        return new TopsSeriesProductVolumeRankingViewModel(parent, 'top3');
      case 'series_top1_word':
        return new TopsSeriesWordViewModel(parent, 'top1');
      case 'series_top2_word':
        return new TopsSeriesWordViewModel(parent, 'top2');
      case 'series_top3_word':
        return new TopsSeriesWordViewModel(parent, 'top3');
      default:
        return null;
    }
  }

  static createChartView(item) {
    const { id, viewModel } = item;

    switch (id) {
      case 'series_volume_trend':
        return (<SeriesVolumeTrend key={id} viewModel={viewModel} />);
      case 'series_volume_proportion':
        return (<SeriesVolumeProportion key={id} viewModel={viewModel} />);
      case 'series_brand_volume_compare':
        return (<SeriesBrandVolumeCompare key={id} viewModel={viewModel} />);
      case 'series_product_volume_compare':
        return (<SeriesProductVolumeCompare key={id} viewModel={viewModel} />);
      case 'series_source_category_compare':
        return (<SeriesSourceCategoryCompare key={id} viewModel={viewModel} />);
      case 'series_top1_brand_volume_proportion':
      case 'series_top2_brand_volume_proportion':
      case 'series_top3_brand_volume_proportion':
        return (<TopsSeriesBrandVolumeProportion key={id} viewModel={viewModel} />);
      case 'series_top1_product_volume_ranking':
      case 'series_top2_product_volume_ranking':
      case 'series_top3_product_volume_ranking':
        return (<TopsSeriesProductVolumeRanking key={id} viewModel={viewModel} />);
      case 'series_top1_word':
      case 'series_top2_word':
      case 'series_top3_word':
        return (<TopsSeriesWord key={id} viewModel={viewModel} />);
      default:
        return null;
    }
  }
}
