import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';

import SearchIcon from 'src/assets/MenuIcons/SearchIcon.svg';

import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Popover, Empty, Checkbox } from 'antd';

import CheckGroupItem from '../CheckGroupItem';

import styles from './styles.module.scss';

@observer
class CustomizeSourceSelect extends React.Component {
  render() {
    const { viewModel, callback, title } = this.props;
    return (
      <Popover
        placement="bottomLeft"
        showArrow={false}
        open={viewModel.open}
        trigger="click"
        onOpenChange={(bool) => viewModel.onOpenChange(bool, callback)}
        content={(
          <div className={styles.switchProjectPopover}>
            <div className={styles.selectCard}>
              <div className={styles.cardHeader}>
                <div className={styles.cardTitle}>
                  {`選擇${title}`}
                </div>
                <div className={styles.cardSelectAll}>
                  <Checkbox
                    indeterminate={viewModel.indeterminate}
                    checked={viewModel.isAllChecked}
                    onChange={viewModel.onAllSelect}
                  />
                  <div className={styles.selectAllText}>
                    {i18n.t('package_modal_select_all')}
                  </div>
                </div>
              </div>
              <div className={styles.cardContent}>
                <div className={styles.searchInputContainer}>
                  <input
                    className={styles.searchInput}
                    placeholder={i18n.t('package_modal_input_placeholder')}
                    value={viewModel.keyword}
                    onChange={viewModel.onKeywordChange}
                    onKeyDown={viewModel.onKeyDown}
                  />
                  <div
                    className={styles.searchBtn}
                    onClick={viewModel.onSubmit}
                  >
                    <img src={SearchIcon} alt="" />
                  </div>
                </div>
                <div className={styles.levelCheckboxContainer}>
                  {
                    viewModel.showContent.map((el) => {
                      return <CheckGroupItem key={el.id} viewModel={el} />;
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        )}
      >
        <div className={clsx(styles.selector, viewModel.disabled && styles.inactive)}>
          <div className={clsx(styles.text, viewModel.selected.length > 0 && styles.active)}>
            {title}
          </div>
          <CaretDownOutlined className={styles.icon} />
        </div>
      </Popover>
    );
  }
}

CustomizeSourceSelect.propTypes = {
  viewModel: PropTypes.object,
  callback: PropTypes.func,
  title: PropTypes.string
};

CustomizeSourceSelect.defaultProps = {
  viewModel: {},
  callback: () => {},
  title: ''
};


export default CustomizeSourceSelect;
