import { COLOR_SET } from 'src/consts/chart';
import packageStore from 'src/stores/packageStore';
import { getHost } from 'src/utils';
import { encodeURI } from 'js-base64';
import { generateCompletedDayArray, getTotalDays } from 'src/utils/chart';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';

export default class IndustryVolumeTrendModel {
  constructor(origin, prevOrigin, query) {
    const data = (() => {
      if (origin.length === 0) {
        return null;
      }
      const totalDays = getTotalDays();
      if (totalDays >= 32 && totalDays <= 90) {
        const dayArr = generateCompletedDayArray();
        origin.forEach((el) => {
          const target = dayArr.find((d) => d.days.includes(el.date));
          if (target) {
            target.count += el.count;
          }
        });
        return dayArr.map((el) => ({
          date: el.id,
          count: el.count
        }));
      }

      if (totalDays >= 91) {
        const dayArr = generateCompletedDayArray();
        origin.forEach((el) => {
          const target = dayArr.find((d) => d.days.includes(el.date));
          if (target) {
            target.count += el.count;
          }
        });
        return dayArr.map((el) => ({
          date: el.id,
          accurate: `${el.days[0]}~${el.days[el.days.length - 1]}`,
          count: el.count
        }));
      }

      const dayArr = generateCompletedDayArray();
      return dayArr.map((el) => ({
        date: el,
        count: origin.find((s) => s.date === el)?.count ?? 0
      }));
    })();

    const previousData = (() => {
      if (prevOrigin.length === 0) {
        return null;
      }
      const totalDays = getTotalDays('previous');
      if (totalDays >= 32 && totalDays <= 90) {
        const dayArr = generateCompletedDayArray('previous');
        prevOrigin.forEach((el) => {
          const target = dayArr.find((d) => d.days.includes(el.date));
          if (target) {
            target.count += el.count;
          }
        });
        return dayArr.map((el) => ({
          date: el.id,
          count: el.count
        }));
      }

      if (totalDays >= 91) {
        const dayArr = generateCompletedDayArray('previous');
        prevOrigin.forEach((el) => {
          const target = dayArr.find((d) => d.days.includes(el.date));
          if (target) {
            target.count += el.count;
          }
        });
        return dayArr.map((el) => ({
          date: el.id,
          accurate: `${el.days[0]}~${el.days[el.days.length - 1]}`,
          count: el.count
        }));
      }

      const dayArr = generateCompletedDayArray('previous');
      return dayArr.map((el) => ({
        date: el,
        count: prevOrigin.find((s) => s.date === el)?.count ?? 0
      }));
    })();

    const {
      postType,
      womType,
      category
    } = query;

    const csv = [];
    if (data) {
      data.forEach((el) => {
        csv.push({
          date: el.date,
          volume: el.count
        });
      });
    }
    if (previousData) {
      previousData.forEach((el) => {
        csv.push({
          date: el.date,
          volume: el.count
        });
      });
    }

    const csvKey = [
      { key: 'date', header: '日期' },
      { key: 'volume', header: '行業聲量' }
    ];

    this.data = {
      chartData: [
        {
          id: 'industry_volume_trend',
          name: '行業聲量趨勢',
          data: [
            ...(
              data && !data.every((el) => el.count === 0)
                ? [{
                  labels: data.map((el) => el.date),
                  datasets: [{
                    label: '行業',
                    data: data.map((el) => el.count),
                    accurate: data.map((el) => el.accurate ?? el.date),
                    borderColor: COLOR_SET[0],
                    backgroundColor: COLOR_SET[19]
                  }],
                  type: 'line'
                }]
                : [{
                  type: 'nodata',
                  subType: data?.length > 0 ? 'default' : 'ana-error'
                }]
            ),
            ...(
              previousData && !previousData.every((el) => el.count === 0)
                ? [{
                  labels: previousData.map((el) => el.date),
                  datasets: [{
                    label: '行業',
                    data: previousData.map((el) => el.count),
                    accurate: previousData.map((el) => el.accurate ?? el.date),
                    borderColor: COLOR_SET[0],
                    backgroundColor: COLOR_SET[19]
                  }],
                  type: 'line'
                }]
                : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{
                        type: 'nodata',
                        subType: previousData?.length > 0 ? 'default' : 'ana-error'
                      }]
                      : []
                  )
                ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const [gte, lte] = chart.data.datasets[datasetIndex].accurate[index].split('~');
              const womCount = chart.data.datasets[datasetIndex].data[index];

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category ? {
                  category: category.map((el) => ({
                    id: el,
                    name: SOURCE_CATEGORY_TYPE[el]
                  }))
                } : {
                  category: SOURCE_CATEGORY.map((el) => ({
                    id: el.value,
                    name: el.label
                  }))
                }),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/行業/聲量分析/行業聲量趨勢'
                },
                industry: true,
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'line',
          options: {
            responsive: true,
            event: ['click'],
            tension: 0.3,
            scales: {
              y: {
                title: {
                  display: true,
                  text: '聲量'
                },
                stacked: true,
                beginAtZero: true,
                grace: '5%'
              },
              x: {
                title: {
                  display: true,
                  text: '日期'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          plugins: null,
          csv,
          csvKey
        }
      ]
    };
  }

  static fromRes(data, prevOrigin, query) {
    return new IndustryVolumeTrendModel(data, prevOrigin, query);
  }
}
