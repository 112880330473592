import BrandVolumeTrendViewModel from './BrandVolumeTrend/viewModel';
import BrandVolumeRankingViewModel from './BrandVolumeRanking/viewModel';
import BrandVolumeProportionViewModel from './BrandVolumeProportion/viewModel';
import BrandSeriesVolumeCompareViewModel from './BrandSeriesVolumeCompare/viewModel';
import BrandProductVolumeCompareViewModel from './BrandProductVolumeCompare/viewModel';
import ProductVolumeRankingViewModel from './ProductVolumeRanking/viewModel';
import IndustrySourceCategoryProportionViewModel from './IndustrySourceCategoryProportion/viewModel';
import BrandSourceCategoryProportionViewModel from './BrandSourceCategoryProportion/viewModel';
import IndustrySourceChannelRankingViewModel from './IndustrySourceChannelRanking/viewModel';
import BrandSourceChannelRankingViewModel from './BrandSourceChannelRanking/viewModel';
import IndustryWordViewModel from './IndustryWord/viewModel';
import BrandWordViewModel from './BrandWord/viewModel';
import BrandSentimentOverviewViewModel from './BrandSentimentOverview/viewModel';

import BrandVolumeTrend from './BrandVolumeTrend';
import BrandVolumeRanking from './BrandVolumeRanking';
import BrandVolumeProportion from './BrandVolumeProportion';
import BrandSeriesVolumeCompare from './BrandSeriesVolumeCompare';
import BrandProductVolumeCompare from './BrandProductVolumeCompare';
import ProductVolumeRanking from './ProductVolumeRanking';
import IndustrySourceCategoryProportion from './IndustrySourceCategoryProportion';
import BrandSourceCategoryProportion from './BrandSourceCategoryProportion';
import IndustrySourceChannelRanking from './IndustrySourceChannelRanking';
import BrandSourceChannelRanking from './BrandSourceChannelRanking';
import IndustryWord from './IndustryWord';
import BrandWord from './BrandWord';
import BrandSentimentOverview from './BrandSentimentOverview';

export default class BrandEffectFactory {
  static createChartViewModel(id, parent) {
    switch (id) {
      case 'brand_volume_trend':
        return new BrandVolumeTrendViewModel(parent);
      case 'brand_volume_ranking':
        return new BrandVolumeRankingViewModel(parent);
      case 'brand_volume_proportion':
        return new BrandVolumeProportionViewModel(parent);
      case 'series_brand_volume_compare':
        return new BrandSeriesVolumeCompareViewModel(parent);
      case 'product_brand_volume_compare':
        return new BrandProductVolumeCompareViewModel(parent);
      case 'product_volume_ranking':
        return new ProductVolumeRankingViewModel(parent);
      case 'industry_source_category_proportion':
        return new IndustrySourceCategoryProportionViewModel(parent);
      case 'main_product_source_category_proportion':
        return new BrandSourceCategoryProportionViewModel(parent);
      case 'industry_source_channel_ranking':
        return new IndustrySourceChannelRankingViewModel(parent);
      case 'main_product_source_channel_ranking':
        return new BrandSourceChannelRankingViewModel(parent);
      case 'industry_word':
        return new IndustryWordViewModel(parent);
      case 'main_brand_word':
        return new BrandWordViewModel(parent);
      case 'brand_sentiment_overview':
        return new BrandSentimentOverviewViewModel(parent);
      default:
        return null;
    }
  }

  static createChartView(item) {
    const { id, viewModel } = item;

    switch (id) {
      case 'brand_volume_trend':
        return (<BrandVolumeTrend key={id} viewModel={viewModel} />);
      case 'brand_volume_ranking':
        return (<BrandVolumeRanking key={id} viewModel={viewModel} />);
      case 'brand_volume_proportion':
        return (<BrandVolumeProportion key={id} viewModel={viewModel} />);
      case 'series_brand_volume_compare':
        return (<BrandSeriesVolumeCompare key={id} viewModel={viewModel} />);
      case 'product_brand_volume_compare':
        return (<BrandProductVolumeCompare key={id} viewModel={viewModel} />);
      case 'product_volume_ranking':
        return (<ProductVolumeRanking key={id} viewModel={viewModel} />);
      case 'industry_source_category_proportion':
        return (<IndustrySourceCategoryProportion key={id} viewModel={viewModel} />);
      case 'main_product_source_category_proportion':
        return (<BrandSourceCategoryProportion key={id} viewModel={viewModel} />);
      case 'industry_source_channel_ranking':
        return (<IndustrySourceChannelRanking key={id} viewModel={viewModel} />);
      case 'main_product_source_channel_ranking':
        return (<BrandSourceChannelRanking key={id} viewModel={viewModel} />);
      case 'industry_word':
        return (<IndustryWord key={id} viewModel={viewModel} />);
      case 'main_brand_word':
        return (<BrandWord key={id} viewModel={viewModel} />);
      case 'brand_sentiment_overview':
        return (<BrandSentimentOverview key={id} viewModel={viewModel} />);
      default:
        return null;
    }
  }
}
