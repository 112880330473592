import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import { Waypoint } from 'react-waypoint';
import { CSVLink } from 'react-csv';
import is from 'is_js';

import loading from 'src/assets/loading.svg';

import { CaretDownOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Layout, Select, Breadcrumb } from 'antd';

import { SORT_TYPE, SENTIMENT_TYPE } from 'src/consts';
import ScrollTop from 'src/components/ScrollTop';

import withRouter from 'src/components/withRouter';
import SimpleHeader from 'src/components/SimpleHeader';
import ListTopicCard from 'src/components/ListTopicCard';
import TopicsResultPageTestViewModel from './viewModel';


import styles from './styles.module.scss';

@observer
class TopicsResultPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new TopicsResultPageTestViewModel();
  }

  componentDidMount() {
    this.viewModel.didMount();
  }

  render() {
    return (
      <Layout className={styles.pageContainer}>
        <SimpleHeader />
        <Layout.Content className={clsx('topicsContainer', styles.contentContainer)}>
          <div className={styles.titleContainer}>
            <div className={styles.pageTitle}>
              {this.viewModel.package?.name}
            </div>
            <Breadcrumb
              className={styles.breadContainer}
              items={[
                ...this.viewModel.chartName.split('/').map((el) => ({
                  title: el
                })),
                {
                  title: '話題列表'
                }
              ]}
            />
          </div>
          <div className={styles.searchRes}>
            {/* ROW ONE */}
            <div className={styles.resContent}>
              {this.viewModel.completedDateString}
            </div>
            {/* ROW TWO */}
            <div className={styles.resContent}>
              {this.viewModel.typeText}
            </div>
            {/* ROW THREE */}
            {
              !this.viewModel.SNType
              && (
                <div className={styles.resContent}>
                  {
                    this.viewModel.category.length > 0
                    && (
                      <span className={styles.item}>
                        {`來源類型：${this.viewModel.category.map((el) => el.name).join(', ')}`}
                      </span>
                    )
                  }
                  {
                    this.viewModel.website.length > 0
                    && (
                      <span className={styles.item}>
                        {`來源網站：${this.viewModel.website.map((el) => el.name).join(', ')}`}
                      </span>
                    )
                  }
                  {
                    this.viewModel.channel.length > 0
                    && (
                      <span className={styles.item}>
                        {`來源頻道：${this.viewModel.channel.map((el) => el.name).join(', ')}`}
                      </span>
                    )
                  }
                </div>
              )
            }
            {/* ROW FOUR */}
            <div className={styles.resContent}>
              {
                this.viewModel.series.length > 0
                && (
                  <span className={styles.item}>
                    {`系列群組：${this.viewModel.series.map((el) => el.kayName).join(', ')}`}
                  </span>
                )
              }
            </div>
            {/* ROW FIVE */}
            <div className={styles.resContent}>
              {
                this.viewModel.brand.length > 0
                && (
                  <span className={styles.item}>
                    {`品牌：${this.viewModel.brand.map((el) => el.name).join(', ')}`}
                  </span>
                )
              }
              {
                this.viewModel.series.length > 0
                && (
                  <span className={styles.item}>
                    {`系列：${this.viewModel.series.map((el) => el.name).join(', ')}`}
                  </span>
                )
              }
              {
                this.viewModel.product.length > 0
                && (
                  <span className={styles.item}>
                    {`產品：${this.viewModel.product.map((el) => el.name).join(', ')}`}
                  </span>
                )
              }
              {
                this.viewModel.SNType
                && (
                  <span className={styles.item}>
                    {this.viewModel.SNType}
                  </span>
                )
              }
              {
                this.viewModel.disabledSentiment
                && (
                  <span className={styles.item}>
                    {`情緒類型：${this.viewModel.sentimentType.map((el) => SENTIMENT_TYPE.find((s) => s.value === el)?.label).join(',')}`}
                  </span>
                )
              }
              {
                this.viewModel.term.name
                && (
                  <span className={styles.item}>
                    {`關鍵字：${this.viewModel.term.name}`}
                  </span>
                )
              }
              {
                this.viewModel.featureCategory.id
                && (
                  <span className={styles.item}>
                    {`特性群組：${this.viewModel.featureCategory.name}`}
                  </span>
                )
              }
              {
                this.viewModel.feature.id
                && (
                  <span className={styles.item}>
                    {`特性關鍵字：${this.viewModel.feature.name}`}
                  </span>
                )
              }
            </div>
            {/* ROW SIX */}
            <div className={styles.resContent}>
              {
                this.viewModel.mindShare.mentionRatio
                && (
                  <span className={styles.item}>
                    {`提及比：${this.viewModel.mindShare.mentionRatio}%`}
                  </span>
                )
              }
              {
                this.viewModel.mindShare.supportRatio
                && (
                  <span className={styles.item}>
                    {`擁護比：${this.viewModel.mindShare.supportRatio}%`}
                  </span>
                )
              }
              {
                this.viewModel.term.count > 0
                && (
                  <span className={styles.item}>
                    {`提及則數：${this.viewModel.term.count}`}
                  </span>

                )
              }
              {
                this.viewModel.feature?.count > 0
                && (
                  <span className={styles.item}>
                    {`提及則數：${this.viewModel.feature?.count}`}
                  </span>

                )
              }
              {
                is.number(this.viewModel.term.newScore)
                && (
                  <span className={styles.item}>
                    {`新詞分數：${this.viewModel.term.newScore}`}
                  </span>

                )
              }
              {
                is.number(this.viewModel.term.trendScore)
                && (
                  <span className={styles.item}>
                    {`趨勢指數：${this.viewModel.term.trendScore}`}
                  </span>

                )
              }
              {
                is.number(this.viewModel.term.important)
                && (
                  <span className={styles.item}>
                    {`重要度：${this.viewModel.term.important}`}
                  </span>

                )
              }
              {
                this.viewModel.womCount > 0
                && (
                  <span className={styles.item}>
                    {`聲量：${this.viewModel.womCount}`}
                  </span>

                )
              }
            </div>
          </div>
          <div className={styles.title}>
            話題列表
          </div>
          <div className={styles.actionContainer}>
            <div className={styles.sorts}>
              <div className={styles.sortText}>
                {i18n.t('topic_res_count').replace('%count%', this.viewModel.totalCount)}
              </div>
              <Select
                placeholder={i18n.t('sort_type_title')}
                options={SORT_TYPE}
                value={this.viewModel.sortType}
                onSelect={this.viewModel.onSortTypeChange}
                popupMatchSelectWidth={false}
                popupClassName={styles.sortMenu}
                className={clsx('sortSelect', styles.filterSelect)}
                suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                style={{ minWidth: 100 }}
              />
              <Select
                placeholder={i18n.t('filter_sentiment_title')}
                options={SENTIMENT_TYPE}
                value={this.viewModel.sentimentType}
                onChange={this.viewModel.onSentimentTypeChange}
                disabled={this.viewModel.disabledSentiment}
                popupMatchSelectWidth={false}
                popupClassName={styles.sortMenu}
                className={clsx('sortSelect', styles.filterSelect)}
                suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                mode="multiple"
                onBlur={this.viewModel.onSentimentChangeComplete}
                removeIcon={null}
                style={{ minWidth: 100 }}
              />

            </div>
            <Button
              icon={<DownloadOutlined />}
              className={styles.downloadButton}
              onClick={this.viewModel.onDownload}
            >
              {i18n.t('topic_download')}
            </Button>
            {
              this.viewModel.isDownload
              && (
                <CSVLink
                  className="topicDownload"
                  data={this.viewModel.downloadList}
                  filename={this.viewModel.downloadFileName}
                  style={{ display: 'none' }}
                />
              )
            }
          </div>
          <div className={styles.resContainer}>
            {
              this.viewModel.topicList.map((el, i) => {
                return (
                  <ListTopicCard
                    key={el.vocKey}
                    viewModel={el}
                    order={i}
                    canChangeSentiment={false}
                  />
                );
              })
            }
            {
              this.viewModel.activePage < this.viewModel.totalPage
              && (
                <Waypoint onEnter={this.viewModel.onPaging} />
              )
            }
          </div>
        </Layout.Content>
        {
          this.viewModel.isLoading
          && (
            <div className={styles.loadingContainer}>
              <div className={styles.curtain} />
              <img src={loading} alt="" className={styles.loadingIcon} />
            </div>
          )
        }
        <ScrollTop containerName="topicsContainer" />
      </Layout>
    );
  }
}

TopicsResultPage.propTypes = {
};

TopicsResultPage.defaultProps = {
};


export default TopicsResultPage;
