import { COLOR_SET, WORD_CONFIG } from 'src/consts/chart';
import { WORDS_TABLE_SCORE } from 'src/consts/tables';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import packageStore from 'src/stores/packageStore';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getTwo } from 'src/utils/chart';

export default class NewWordsModel {
  constructor(data, query, search) {
    if (data.length === 0 || (data.length > 0 && data.every((el) => el.count === 0))) {
      this.data = {
        chartData: [{
          id: 'industry_sentiment_trend',
          name: '行業正負面情緒趨勢',
          data: [{
            type: 'nodata',
            subType: data.length === 0 ? 'ana-error' : 'default'
          }]
        }]
      };
      return;
    }

    const maxNum = Math.max(...data.map((el) => el.count));
    const minNum = Math.min(...data.map((el) => el.count));

    const size = (() => {
      const length = data.length;
      if (length <= 20) {
        return 'small';
      }
      if (length > 20 && length <= 50) {
        return 'medium';
      }
      return 'large';
    })();

    const getNew = (num) => {
      // FOR ONLY ONE SITUATION
      if (maxNum === minNum) {
        return WORD_CONFIG[size].basicSize * WORD_CONFIG[size].maxRatio;
      }
      return ((num - minNum) * (WORD_CONFIG[size].maxRatio / (maxNum - minNum)) + 1) * WORD_CONFIG[size].basicSize;
    };

    const { currentDateCache, autoPrevious } = infoHeaderViewModel.multiViewModel;
    const gte = autoPrevious[0].format('YYYY-MM-DD');
    const lte = currentDateCache[1].format('YYYY-MM-DD');

    const {
      postType,
      womType,
      category
    } = query;

    const topicsResult = {
      ...(womType && {
        womType
      }),
      ...(postType && {
        postType
      }),
      ...(category ? {
        category: category.map((el) => ({
          id: el,
          name: SOURCE_CATEGORY_TYPE[el]
        }))
      } : {
        category: SOURCE_CATEGORY.map((el) => ({
          id: el.value,
          name: el.label
        }))
      }),
      packageContent: {
        id: packageStore.activePackageId,
        name: packageStore.activePackageName
      },
      brand: [{
        id: search.id,
        name: search.name
      }],
      chart: {
        name: '圖表分析/品牌/關鍵字分析/品牌新詞文字雲'
      },
      date: {
        gte,
        lte
      }
    };

    const res = data.map((el, i) => ({
      id: el.name,
      name: el.name,
      count: getNew(el.count),
      originCount: el.count,
      newScore: getTwo(el.score),
      color: COLOR_SET[i % 20],
      linkData: {
        ...topicsResult,
        term: {
          name: el.name,
          newScore: getTwo(el.score),
          score: getTwo(el.score)
        }
      }
    }));

    const csv = res.map((el) => ({
      word: el.name,
      count: el.originCount,
      score: el.newScore
    }));

    const csvKey = [
      { key: 'word', header: '新詞' },
      { key: 'count', header: '提及則數' },
      { key: 'score', header: '新詞分數' }
    ];

    this.data = {
      chartData: [{
        id: 'industry_keyword_new',
        name: '行業新詞文字雲',
        data: [res],
        type: 'words',
        csv,
        csvKey,
        table: WORDS_TABLE_SCORE
      }]
    };
  }

  static fromRes(data, query, search) {
    return new NewWordsModel(data, query, search);
  }
}
