import { CATEGORY_COLOR_SET } from 'src/consts/chart';
import { getHost } from 'src/utils';
import { SOURCE_CATEGORY_TYPE, SOURCE_ORDER_TC } from 'src/consts';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { encodeURI } from 'js-base64';
import packageStore from 'src/stores/packageStore';
import { generateCSVKey } from 'src/utils/chart';

export default class IndustrySourceCategoryProportionModel {
  constructor(data, prevData, query) {
    const total = (() => {
      let sum = 0;
      data.forEach((el) => {
        sum += el.count;
      });
      return sum;
    })();

    const prevTotal = (() => {
      let sum = 0;
      prevData.forEach((el) => {
        sum += el.count;
      });
      return sum;
    })();

    const combine = data.map((el) => ({
      name: el.name,
      count: el.count,
      prevCount: 0
    }));

    prevData.forEach((el) => {
      const target = combine.find((item) => item.name === el.name);
      if (target) {
        target.prevCount = el.count;
      } else {
        combine.push({
          name: el.name,
          count: 0,
          prevCount: el.count
        });
      }
    });

    combine.sort((a, b) => b.count - a.count);

    const { currentDate, previousDate } = infoHeaderViewModel.multiViewModel;
    const [gte, lte] = currentDate.map((el) => el.format('YYYY-MM-DD'));
    const [prevGte, prevLte] = previousDate.map((el) => el.format('YYYY-MM-DD'));

    const prototype = [
      ...(
        data.length > 0
          ? [{
            date: `${gte} ~ ${lte}`
          }]
          : []
      ),
      ...(
        prevData.length > 0
          ? [{
            date: `${prevGte} ~ ${prevLte}`
          }]
          : []
      )
    ];

    const csv = prototype.map((el, di) => {
      const row = el;
      for (let i = 0; i < combine.length; i += 1) {
        if (di === 0) {
          row[`${SOURCE_CATEGORY_TYPE[combine[i].name]}`] = combine[i].count;
          row[`${SOURCE_CATEGORY_TYPE[combine[i].name]}-佔比`] = `${Math.floor((combine[i].count / total) * 1000) / 10}%`;
        } else {
          row[`${SOURCE_CATEGORY_TYPE[combine[i].name]}`] = combine[i].prevCount;
          row[`${SOURCE_CATEGORY_TYPE[combine[i].name]}-佔比`] = `${Math.floor((combine[i].prevCount / prevTotal) * 1000) / 10}%`;
        }
      }

      return row;
    });

    const csvKey = generateCSVKey(csv[0]);

    const {
      postType,
      womType
    } = query;

    this.data = {
      chartData: [
        {
          id: 'industry_source_category_proportion',
          name: '行業來源類型佔比',
          data: [
            ...(
              total
                ? [{
                  labels: data.map((el) => SOURCE_CATEGORY_TYPE[el.name]),
                  datasets: [{
                    label: `${gte} ~ ${lte}`,
                    data: data.map((el) => el.count),
                    category: data.map((el) => el.name),
                    backgroundColor: data.map((el) => CATEGORY_COLOR_SET[el.name]),
                    datalabels: {
                      color: '#fff',
                      formatter: (value) => {
                        const percentage = ((value / total) * 100).toFixed();
                        return percentage > 5 ? `${percentage}%` : null;
                      }
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const percentage = ((context.parsed / total) * 100).toFixed();
                          return ` ${context.parsed}: [${percentage}%]`;
                        }
                      }
                    }
                  }],
                  type: 'pie'
                }]
                : [{
                  type: 'nodata',
                  subType: data.length > 0 ? 'pie' : 'ana-error'
                }]
            ),
            ...(
              prevTotal
                ? [{
                  labels: prevData.map((el) => SOURCE_CATEGORY_TYPE[el.name]),
                  datasets: [{
                    label: `${prevGte} ~ ${prevLte}`,
                    data: prevData.map((el) => el.count),
                    category: prevData.map((el) => el.name),
                    backgroundColor: prevData.map((el) => CATEGORY_COLOR_SET[el.name]),
                    datalabels: {
                      color: '#fff',
                      formatter: (value) => {
                        const percentage = ((value / prevTotal) * 100).toFixed();
                        return percentage > 5 ? `${percentage}%` : null;
                      }
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const percentage = ((context.parsed / prevTotal) * 100).toFixed();
                          return ` ${context.parsed}: [${percentage}%]`;
                        }
                      }
                    }
                  }],
                  type: 'pie'
                }
                ]
                : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{
                        type: 'nodata',
                        subType: prevData.length > 0 ? 'pie' : 'ana-error'
                      }]
                      : []
                  )
                ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const category = chart.data.datasets[datasetIndex].category[index];
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const categoryName = SOURCE_CATEGORY_TYPE[category];
              const [g, l] = chart.data.datasets[datasetIndex].label.split('~');

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                category: [{
                  id: category,
                  name: categoryName
                }],
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/行業/來源分析/行業來源類型/行業來源類型佔比'
                },
                industry: true,
                womCount,
                date: {
                  gte: g,
                  lte: l
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'pie',
          options: {
            event: ['click'],
            plugins: {
              legend: {
                position: 'bottom',
                labels: {
                  sort: (a, b) => {
                    const indexA = SOURCE_ORDER_TC.indexOf(a.text);
                    const indexB = SOURCE_ORDER_TC.indexOf(b.text);
                    return indexA - indexB;

                  }
                }
              }
            }
          },
          csv,
          csvKey
        }
      ]
    };
  }

  static fromRes(data, prevData, query) {
    return new IndustrySourceCategoryProportionModel(data, prevData, query);
  }
}
