import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import clsx from 'clsx';

import { Checkbox } from 'antd';

import styles from './styles.module.scss';

class CheckItem extends React.Component {
  render() {
    const { viewModel, afterSelect = () => { }, item } = this.props;
    return (
      <Observer>
        {() => {
          return (
            <div className={styles.levelItem}>
              <Checkbox
                checked={viewModel.checkedCache}
                onClick={(e) => viewModel.toggleChecked(e, afterSelect, item)}
              />
              <div className={styles.levelName}>
                {viewModel.name}
              </div>
            </div>
          );
        }}
      </Observer>

    );
  }
}

CheckItem.propTypes = {
  viewModel: PropTypes.object,
  afterSelect: PropTypes.func,
  item: PropTypes.object
};

CheckItem.defaultProps = {
  viewModel: {},
  afterSelect: () => { },
  item: null
};

export default CheckItem;

