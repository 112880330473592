import { makeObservable, observable, action, computed } from 'mobx';
import { encodeURI } from 'js-base64';
import { message } from 'antd';
import * as xlsx from 'xlsx';
import html2canvas from 'html2canvas';

import optionsStore from 'src/stores/optionsStore';
import { getHost } from 'src/utils';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import { SENTIMENT_COLOR_SET, AVG_LINE_COLOR } from 'src/consts/chart';

export default class SentimentViewModel {
  @observable originData = null;
  @observable chartData = null;
  @observable searchData = null;
  @observable options = null;

  @observable isCollapsed = false;

  @computed get excelTitle() {
    return [
      { key: 'keyword', header: '關鍵字' },
      { key: 'positive', header: '偏正面' },
      { key: 'neutral', header: '偏中性' },
      { key: 'negative', header: '偏負面' },
      { key: 'pn', header: 'P/N' }
    ];
  }

  @computed get excelData() {
    const excel = [];
    if (!this.originData) {
      return excel;
    }

    excel.push({
      keyword: this.searchData.keyword,
      positive: this.originData.positive_count,
      neutral: this.originData.neutral_count,
      negative: this.originData.negative_count,
      pn: this.originData.pn
    });

    return excel;
  }

  constructor() {
    makeObservable(this);
  }

  @action toggleCollapse = () => {
    this.isCollapsed = !this.isCollapsed;
  };

  @action update = (data, searchData) => {
    this.updateContent(data, searchData);
    this.updateOptions(searchData);
  };

  @action updateContent = (data, searchData) => {
    if (!data || data.length === 0) {
      return;
    }

    this.originData = data;

    this.chartData = {
      labels: [searchData.keyword],
      datasets: [
        {
          label: 'p/n',
          data: [data.pn],
          backgroundColor: AVG_LINE_COLOR,
          borderSkipped: true,
          type: 'line',
          yAxisID: 'y1'
        },
        {
          label: '偏正面',
          data: [data.positive_count],
          sentiment: '1',
          backgroundColor: SENTIMENT_COLOR_SET.positive,
          borderSkipped: true,
          maxBarThickness: 50,
          yAxisID: 'y'
        },
        {
          label: '偏中性',
          data: [data.neutral_count],
          sentiment: '0',
          backgroundColor: SENTIMENT_COLOR_SET.neutral,
          borderSkipped: true,
          maxBarThickness: 50,
          yAxisID: 'y'
        },
        {
          label: '偏負面',
          data: [data.negative_count],
          sentiment: '-1',
          backgroundColor: SENTIMENT_COLOR_SET.negative,
          borderSkipped: true,
          maxBarThickness: 50,
          yAxisID: 'y'
        }
      ]
    };
  };

  @action updateOptions = (searchData) => {
    this.searchData = searchData;
    const onPointClick = (elements, chart) => {
      if (elements.length === 0) {
        return;
      }
      const { datasetIndex, index } = elements[0];
      const womCount = chart.data.datasets[datasetIndex].data[index];
      if (datasetIndex === 0) {
        return;
      }

      const sentiment = (() => {
        if (datasetIndex === 1) {
          return [1];
        }
        if (datasetIndex === 2) {
          return [0];
        }
        return [-1];
      })();

      const { keyword, postType, womType, date: { gte, lte }, category, website, channel } = searchData;

      const archiveData = {
        keyword,
        ...(womType && {
          womType
        }),
        ...(postType && {
          postType
        }),
        ...(category ? {
          category: category.map((el) => ({
            id: el,
            name: SOURCE_CATEGORY_TYPE[el]
          }))
        } : {
          category: SOURCE_CATEGORY.map((el) => ({
            id: el.value,
            name: el.label
          }))
        }),
        ...(website && { website: { id: website[0], name: website[0] } }),
        ...(channel && { channel: { id: channel[0].id, name: channel[0].name } }),
        chart: {
          name: '輿情搜尋/摘要圖表/情緒總覽'
        },
        sentiment,
        date: {
          gte,
          lte: lte ?? gte
        },
        womCount
      };

      const json = JSON.stringify(archiveData);
      const result = encodeURI(json);
      const url = `${getHost()}/opinion-topics?result=${result}`;

      window.open(url, '_blank');
    };

    this.options = {
      responsive: true,
      event: ['click'],
      tension: 0.3,
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: true
        },
        y: {
          stacked: true,
          type: 'linear',
          display: true,
          position: 'left',
          title: {
            display: true,
            text: '聲量'
          }
        },
        y1: {
          type: 'linear',
          display: true,
          position: 'right',
          title: {
            display: true,
            text: 'PN值'
          }
        }
      },
      onHover: (event, elements) => {
        const native = event.native;
        if (!native) {
          return;
        }
        const target = native.target;
        if (!target) {
          return;
        }
        if (elements.length > 0) {
          target.style.cursor = 'pointer';
        } else {
          target.style.cursor = 'default';
        }
      },
      onClick: (ev, elements, chart) => onPointClick(elements, chart),
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            color: '#000'
          }
        },
        datalabels: {
          display: false
        }
      }
    };
  };

  @action onDownloadExcel = () => {
    this.isDownload = false;
    if (!this.excelTitle || !this.excelData) {
      message.error('csv檔不存在');
      return;
    }
    const data = [...this.excelData];
    const header = this.excelTitle.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [this.excelTitle.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    const wordbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wordbook, workSheet, '情緒總覽');
    xlsx.writeFile(wordbook, `輿情搜尋-${this.searchData.keyword}-情緒總覽-${this.searchData.date.gte}-${this.searchData.date.lte}.xlsx`);
  };

  @action appendSheet = (wordbook) => {
    this.isDownload = false;
    if (!this.excelTitle || !this.excelData) {
      message.error('csv檔不存在');
      return;
    }
    const data = [...this.excelData];
    const header = this.excelTitle.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [this.excelTitle.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    xlsx.utils.book_append_sheet(wordbook, workSheet, '情緒總覽');
  };

  @action onDownload = async () => {
    await optionsStore.onImgDownload();
    const chart = document.querySelector('.opinion-sentiment');
    const canvas = await html2canvas(chart);
    const a = document.createElement('a');
    a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
    a.download = `輿情搜尋-${this.searchData.keyword}-情緒總覽-${this.searchData.date.gte}-${this.searchData.date.lte}.jpg`;
    a.click();
    optionsStore.onImgDownloadEnd();
  };
}
