import { request, getHost, getHeaders } from './utils';

export const myProfile = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/user/me`,
    headers: getHeaders()
  };
  return request(options);
};


export const getUserList = (params, anchor) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/user`,
    headers: getHeaders(),
    params: {
      order: 'department',
      isAccountManagement: true,
      anchor,
      ...params
    }
  };
  return request(options);
};

export const getSwitchUserList = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/user`,
    headers: getHeaders(),
    params: {
      order: 'department',
      isAccountManagement: false,
      limit: 10000
    }
  };
  return request(options);
};

export const getUserInfo = (uid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/user/${uid}`,
    headers: getHeaders()
  };
  return request(options);
};

export const updateUserInfo = (uid, data) => {
  const options = {
    method: 'put',
    url: `${getHost()}/api/v1/user/${uid}`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getTeamList = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/team`,
    headers: getHeaders()
  };
  return request(options);
};

export const getTeamUsers = (teamId) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/team/${teamId}/user`,
    headers: getHeaders()
  };
  return request(options);
};

export const getSalesList = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/user/sales`,
    headers: getHeaders()
  };
  return request(options);
};

export const createAccount = (data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/user`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

