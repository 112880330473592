import API from 'src/apis';
import dataAnalysis from 'src/models/response/charts/dataAnalysis';
import packageStore from 'src/stores/packageStore';

export default class BrandEffectService {

  static getPackageId = () => {
    return packageStore.activePackageId;
  };

  static async getBrandVolumeTrend(data) {
    const res = await API.dataReport.brandEffect.getBrandVolumeTrend(BrandEffectService.getPackageId(), data);

    return dataAnalysis.BrandEffect.BrandVolumeTrendModel.fromRes(res.data.result, data);
  }

  static async getBrandVolumeRanking(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.dataReport.brandEffect.getBrandVolumeCount(BrandEffectService.getPackageId(), data),
      API.dataReport.brandEffect.getBrandVolumeCount(BrandEffectService.getPackageId(), prevData)
    ]);

    return dataAnalysis.BrandEffect.BrandVolumeRankingModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getBrandVolumeProportion(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.dataReport.brandEffect.getBrandVolumeCount(BrandEffectService.getPackageId(), data),
      API.dataReport.brandEffect.getBrandVolumeCount(BrandEffectService.getPackageId(), prevData)
    ]);

    return dataAnalysis.BrandEffect.BrandVolumeProportionModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getSeriesBrandVolumeCompare(data) {
    const res = await API.dataReport.brandEffect.getSeriesBrandVolumeCompare(BrandEffectService.getPackageId(), data);

    return dataAnalysis.BrandEffect.BrandSeriesVolumeCompareModel.fromRes(res.data.result, data);
  }

  static async getProductBrandVolumeCompare(data) {
    const res = await API.dataReport.brandEffect.getProductBrandVolumeCompare(BrandEffectService.getPackageId(), data);

    return dataAnalysis.BrandEffect.BrandProductVolumeCompareModel.fromRes(res.data.result, data);
  }

  static async getProductVolumeRanking(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.dataReport.brandEffect.getProductVolumeRanking(BrandEffectService.getPackageId(), data),
      API.dataReport.brandEffect.getProductVolumeRanking(BrandEffectService.getPackageId(), prevData)
    ]);

    return dataAnalysis.BrandEffect.ProductVolumeRankingModel.fromRes(res.data.result, prevRes.data.result, data);
  }

  static async getIndustrySourceCategoryProportion(data) {
    const res = await API.dataReport.brandEffect.getIndustrySourceCategoryProportion(BrandEffectService.getPackageId(), data);

    return dataAnalysis.BrandEffect.IndustrySourceCategoryProportionModel.fromRes(res.data.result, data);
  }

  static async getBrandSourceCategoryProportion(data, brand) {
    const res = await API.dataReport.brandEffect.getBrandSourceCategoryProportion(BrandEffectService.getPackageId(), data);

    return dataAnalysis.BrandEffect.BrandSourceCategoryProportionModel.fromRes(res.data.result, { ...data, ...brand });
  }

  static async getIndustrySourceChannelRanking(data) {
    const res = await API.dataReport.brandEffect.getIndustrySourceChannelRanking(BrandEffectService.getPackageId(), data);

    return dataAnalysis.BrandEffect.IndustrySourceChannelRankingModel.fromRes(res.data.result, data);
  }

  static async getBrandSourceChannelRanking(data, brand) {
    const res = await API.dataReport.brandEffect.getBrandSourceChannelRanking(BrandEffectService.getPackageId(), data);

    return dataAnalysis.BrandEffect.BrandSourceChannelRankingModel.fromRes(res.data.result, { ...data, ...brand });
  }

  static async getIndustryWord(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.dataReport.brandEffect.getIndustryWord(BrandEffectService.getPackageId(), data),
      API.dataReport.brandEffect.getIndustryWord(BrandEffectService.getPackageId(), prevData)
    ]);

    return {
      present: dataAnalysis.BrandEffect.IndustryWords.fromRes(res.data.result, data),
      previous: dataAnalysis.BrandEffect.IndustryWords.fromRes(prevRes.data.result, data)
    };
  }

  static async getBrandWord(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.dataReport.brandEffect.getBrandWord(BrandEffectService.getPackageId(), data),
      API.dataReport.brandEffect.getBrandWord(BrandEffectService.getPackageId(), prevData)
    ]);

    return {
      present: dataAnalysis.BrandEffect.BrandWords.fromRes(res.data.result, data),
      previous: dataAnalysis.BrandEffect.BrandWords.fromRes(prevRes.data.result, data)
    };
  }

  static async getBrandSentimentOverview(data, prevData) {
    const [res, prevRes] = await Promise.all([
      API.dataReport.brandEffect.getBrandSentimentOverview(BrandEffectService.getPackageId(), data),
      API.dataReport.brandEffect.getBrandSentimentOverview(BrandEffectService.getPackageId(), prevData)
    ]);

    return dataAnalysis.BrandEffect.BrandSentimentOverviewModel.fromRes(res.data.result, prevRes.data.result, data);
  }

}
