import { COLOR_SET } from 'src/consts/chart';
import { BRAND_SERIES_WGS } from 'src/consts/tables';
import { getHost } from 'src/utils';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY } from 'src/consts';
import { generateCSVKey } from 'src/utils/chart';

/**
 * wgr 聲量成長率 - y 軸
 * ws 聲量市佔率 - x 軸
 * wr 聲量佔比 - size
 */

export default class BrandSeriesWGSModel {
  constructor(origin, query) {
    if (origin.length === 0) {
      this.data = {
        chartData: [{
          id: 'brand_series_WGS',
          name: '品牌WGS定位',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }]
        }]
      };
      return;
    }

    const data = origin.slice(0, 10);

    if (data.every((el) => el.wgr === 0) && data.every((el) => el.es === 0) && data.every((el) => el.wr === 0)) {
      this.data = {
        chartData: [{
          id: 'brand_series_WGS',
          name: '品牌WGS定位',
          data: [{
            type: 'nodata',
            subType: 'default'
          }]
        }]
      };
      return;
    }

    const wgrAvg = (() => {
      let total = 0;

      data.forEach((el) => {
        total += el.wgr;
      });

      return total / data.length;
    })();

    const wsAvg = (() => {
      let total = 0;

      data.forEach((el) => {
        total += el.ws;
      });

      return total / data.length;
    })();

    const minNum = Math.min(...data.map((el) => el.wr));
    const maxNum = Math.max(...data.map((el) => el.wr));

    const getNew = (num) => {
      // FOR ONLY ONE SITUATION
      if (minNum === maxNum) {
        return 5;
      }
      return ((num - minNum) * (3 / (maxNum - minNum)) + 1) * 5;
    };


    const avgLine = {
      id: 'avgLine',
      beforeDraw(chart, args, options) {
        const { ctx, chartArea: { top, right, bottom, left, width, height },
          scales: { x, y } } = chart;
        ctx.save();

        ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
        ctx.strokeRect(left, y.getPixelForValue(wgrAvg), width, 0);
        ctx.restore();

        ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
        ctx.strokeRect(x.getPixelForValue(wsAvg), top, 0, height);
        ctx.restore();
      }
    };

    const csv = origin.map((el) => ({
      品牌名稱: el.name,
      聲量成長率: el.wgr,
      聲量市占率: el.ws,
      聲量佔比: el.wr
    }));

    const csvKey = generateCSVKey(csv[0]);

    const {
      id,
      name,
      womType,
      postType,
      tagKey
    } = query;

    const [_, lte] = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'));
    const [gte, __] = infoHeaderViewModel.multiViewModel.autoPrevious.map((el) => el.format('YYYY-MM-DD'));

    this.data = {
      chartData: [
        {
          id: 'brand_series_WGS',
          name: '主品牌系列WGS定位',
          data: [
            {
              labels: '',
              datasets: data.map((el, i) => ({
                label: el.name,
                seriesId: el.id,
                data: [{
                  x: el.ws,
                  y: el.wgr
                }],
                pointRadius: [getNew(el.wr)],
                backgroundColor: COLOR_SET[i % 20],
                tooltip: {
                  callbacks: {
                    label: (context) => {
                      const { dataset, parsed } = context;
                      const { x, y } = parsed;
                      const { label } = dataset;
                      return `${label} - 聲量市占率:${x}, 聲量成長率:${y}% `;
                    }
                  }
                }
              }))
            }
          ],
          type: 'customize-scatter',
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const seriesId = chart.data.datasets[datasetIndex].seriesId;
              const seriesName = chart.data.datasets[datasetIndex].label;
              const womCount = chart.data.datasets[datasetIndex].data[index];

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                category: SOURCE_CATEGORY.map((el) => ({
                  id: el.value,
                  name: el.label
                })),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '數據報告/品牌健檢分析/主品牌系列WGS定位'
                },
                brand: [{
                  id,
                  name
                }],
                series: [{
                  id: seriesId,
                  name: seriesName,
                  tagKey,
                  tagKeyName: packageStore.series.find((el) => el.id === tagKey).name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          options: {
            event: ['click'],
            scales: {
              y: {
                title: {
                  display: true,
                  text: '聲量成長率(wgr)'
                },
                grace: '15%',
                ticks: {
                  callback: (v, i, t) => {
                    return `${Math.ceil(v * 100) / 100}%`;
                  }
                }
              },
              x: {
                title: {
                  display: true,
                  text: '聲量市佔率(ws)'
                },
                grace: '15%',
                ticks: {
                  callback: (v, i, t) => {
                    return `${v}%`;
                  }
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: 'auto',
                color: '#000',
                formatter: (value, context) => {
                  return context.chart.data.datasets[context.datasetIndex].label;
                },
                align: 'top'
              }
            }
          },
          csv,
          csvKey,
          plugins: [avgLine],
          table: {
            template: BRAND_SERIES_WGS,
            data: data.slice().sort((a, b) => b.wgr - a.wgr).map((el, i) => ({
              ranking: i + 1,
              name: el.name,
              wgr: `${el.wgr}%`,
              ws: `${el.ws}%`,
              wr: `${el.wr}%`
            }))
          }
        }
      ],
      series: origin.map((el) => ({ id: el.id, name: (el.name).toString() }))
    };

    // ? ref: https://www.youtube.com/watch?v=glSJZEoKs1M
    // let rect;
    // const area = {
    //   id: 'area',
    //   events: ['mousemove'],
    //   beforeDatasetsDraw(chart, args, plugins) {
    //     if (rect) {

    //     }
    //   },
    //   afterEvent(chart, args) {
    //     console.log(args);
    //   }
    // };
  }

  static fromRes(data, query) {
    return new BrandSeriesWGSModel(data, query);
  }
}
