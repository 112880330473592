import { COLOR_SET, WORD_CONFIG } from 'src/consts/chart';

export default class SeriesWordModel {
  constructor(data, prevData) {
    const levels = ['who', 'event', 'when', 'where', 'what'];

    const chartData = levels.map((level) => {
      const targetLevelInCurrent = data.find((el) => el.name === level);
      const targetLevelInPrevious = prevData.find((el) => el.name === level);
      const generateContent = (value) => {
        const maxNum = Math.max(...value.item.map((el) => el.count));
        const minNum = Math.min(...value.item.map((el) => el.count));

        const size = (() => {
          const length = value.item.length;
          if (length <= 20) {
            return 'small';
          }
          if (length > 20 && length <= 50) {
            return 'medium';
          }
          return 'large';
        })();

        const getNew = (num) => {
          // FOR ONLY ONE SITUATION
          if (maxNum === minNum) {
            return WORD_CONFIG[size].basicSize * WORD_CONFIG[size].maxRatio;
          }
          return ((num - minNum) * (WORD_CONFIG[size].maxRatio / (maxNum - minNum)) + 1) * WORD_CONFIG[size].basicSize;
        };
        const res = value.item.map((el, i) => ({
          id: el.name,
          name: el.name,
          score: el.score,
          count: getNew(el.count),
          originCount: el.count,
          color: COLOR_SET[i % 20]
        }));

        return res;
      };

      const currentRes = targetLevelInCurrent ? generateContent(targetLevelInCurrent) : [];
      const previousRes = targetLevelInPrevious ? generateContent(targetLevelInPrevious) : [];

      return {
        id: level,
        name: level,
        data: currentRes,
        prevData: previousRes,
        type: 'words'
      };
    });



    this.data = {
      chartData
    };
  }

  static fromRes(data, prevData) {
    return new SeriesWordModel(data, prevData);
  }
}
