import { SENTIMENT_COLOR_SET, AVG_LINE_COLOR } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY } from 'src/consts';
import { generateCSVKey } from 'src/utils/chart';

export default class BrandSentimentOverviewModel {
  constructor(data, query) {
    if (!data['0'] && !data['1'] && !data['-1'] && !data.pn) {
      this.data = {
        chartData: [{
          id: 'brand_sentiment_overview',
          name: '品牌情緒總覽',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }]
        }]
      };
      return;
    }

    if (data['0'] + data['1'] + data['-1'] === 0) {
      this.data = {
        chartData: [{
          id: 'brand_sentiment_overview',
          name: '品牌情緒總覽',
          data: [{
            type: 'nodata'
          }]
        }]
      };
      return;
    }

    const {
      id,
      name,
      postType,
      womType
    } = query;

    const csv = [{
      名稱: data.name,
      偏正面: data['1'],
      偏中性: data['0'],
      偏負面: data['-1'],
      'P/N': data.pn
    }];

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'brand_sentiment_overview',
          name: '品牌情緒總覽',
          data: [{
            labels: [data.name],
            datasets: [
              {
                label: '正面',
                data: [data['1']],
                sentiment: '1',
                backgroundColor: SENTIMENT_COLOR_SET.positive,
                borderSkipped: true,
                maxBarThickness: 50,
                yAxisID: 'y'
              },
              {
                label: '中性',
                data: [data['0']],
                sentiment: '0',
                backgroundColor: SENTIMENT_COLOR_SET.neutral,
                borderSkipped: true,
                maxBarThickness: 50,
                yAxisID: 'y'
              },
              {
                label: '負面',
                data: [data['-1']],
                sentiment: '-1',
                backgroundColor: SENTIMENT_COLOR_SET.negative,
                borderSkipped: true,
                maxBarThickness: 50,
                yAxisID: 'y'
              },
              {
                label: 'p/n',
                data: [data.pn],
                backgroundColor: AVG_LINE_COLOR,
                borderColor: AVG_LINE_COLOR,
                type: 'line',
                yAxisID: 'y1'
              }
            ]
          }],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const sentiment = chart.data.datasets[datasetIndex].sentiment;
              if (!sentiment) {
                return;
              }
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const [gte, lte] = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'));

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                category: SOURCE_CATEGORY.map((el) => ({
                  id: el.value,
                  name: el.label
                })),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '數據報告/品牌健檢分析/品牌情緒總覽'
                },
                sentiment: [Number(sentiment)],
                brand: [{
                  id,
                  name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'bar',
          options: {
            event: ['click'],
            tension: 0.3,
            scales: {
              x: {
                stacked: true
              },
              y: {
                stacked: true,
                type: 'linear',
                display: true,
                position: 'left',
                grace: 5,
                title: {
                  display: true,
                  text: '聲量'
                }
              },
              y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grace: 5,
                title: {
                  display: true,
                  text: 'PN值'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ]
    };
  }

  static fromRes(data, query) {
    return new BrandSentimentOverviewModel(data, query);
  }
}
