export default class UserPackagesListModel {
  constructor(data) {
    this.packages = data.dataPackages.map((el) => ({
      id: el._id,
      name: el.name,
      industry: el.industry,
      brand: el.brand,
      tag: el.tag,
      product: el.product,
      startDate: el.startDate,
      endDate: el.endDate,
      projectId: el.projectId,
      featureSetId: el.featureSetId
    }));
  }

  static fromRes(data) {
    return new UserPackagesListModel(data);
  }
}
