import { request, getHost, getHeaders } from '../utils';

export const getBrandReputation = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-comp/reputation`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandVolumeTrend = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-comp/volume/trend`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandVolumeCount = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-comp/volume/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandSeriesVolumeCompare = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-comp/series/volume/stack`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandProductVolumeCompare = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-comp/product/volume/stack`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getProductVolumeRanking = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-comp/product/volume/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandSourceCategoryCompare = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-comp/source/category/stack`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandSourceChannelCompare = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-comp/source/channel/stack`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandSentimentOverview = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-comp/sentiment`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandSentimentWords = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-comp/sentiment/term`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandConsumerFeature = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-comp/feature/stack`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandWords = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-comp/feature/term`,
    headers: getHeaders(),
    data
  };
  return request(options);
};
