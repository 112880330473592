import { makeObservable, observable, action, computed, runInAction, reaction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import html2canvas from 'html2canvas';
import { encodeURI } from 'js-base64';
import * as xlsx from 'xlsx';

import BrandCompetitionService from 'src/services/charts/DataAnalysis/brandCompetition';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import ChartFactory from 'src/components/Charts/factory';
import { getHost } from 'src/utils';
import { generateWorksheetName } from 'src/utils/chart';
import packageStore from 'src/stores/packageStore';
import optionsStore from 'src/stores/optionsStore';
import NoDataChartViewModel from 'src/components/Charts/NoData/viewModel';

import BrandWordPNCloudViewModel from './Words/viewModel';

export default class BrandPNWordViewModel {
  isTop = false;
  parent = {};
  @observable key = uuidv4();
  @observable chart = {};
  @observable csv = [];
  @observable csvKey = [];

  @observable isLoading = true;
  @observable selectedWOM = 'all';

  @observable isInit = false;
  @observable isReady = false;

  @observable isDownload = false;
  @observable downloadFileName = '';
  @observable downloadList = [];

  @computed get chartName() {
    return this.isTop ? `TOP1品牌(${this.parent.topOneBrand?.name ?? '無資料'})正負面文字雲` : `主品牌(${this.parent.selectedBrandName})正負面文字雲`;
  }

  @computed get chartId() {
    return this.isTop ? 'brand_top1_pn_word' : 'brand_main_pn_word';
  }

  constructor(parent, isTop) {
    makeObservable(this);

    this.parent = parent;
    this.isTop = isTop;
  }

  @action init = async () => {
    if (this.isTop) {
      if (this.parent.topOneBrand?.id && this.parent.initBlockedWords) {
        await this.getChart();
        runInAction(() => {
          this.isInit = true;
        });
      } else {
        const once = reaction(
          () => this.parent.topOneBrand?.id && this.parent.initBlockedWords,
          async (boolean) => {
            if (boolean) {
              await this.getChart();
              runInAction(() => {
                this.isInit = true;
                once();
              });
            }
          }
        );
      }
    }

    if (!this.isTop) {
      if (this.parent.initBlockedWords) {
        await this.getChart();
        runInAction(() => {
          this.isInit = true;
        });
      } else {
        const once = reaction(
          () => this.parent.initBlockedWords,
          async (boolean) => {
            if (boolean) {
              await this.getChart();
              runInAction(() => {
                this.isInit = true;
                once();
              });
            }
          }
        );
      }
    }
  };

  @action onWOMChange = async (e) => {
    this.selectedWOM = e;

    await this.getChart();
  };

  @action getChart = async () => {
    try {
      this.isReady = false;
      const params = {
        ...(this.parent.postType !== 'all' && { postType: this.parent.postType }),
        ...(this.parent.blockedWords.length > 0 && { disableTerm: this.parent.blockedWords.map((el) => el.name) }),
        search: this.isTop ? this.parent.topOneBrand?.id : this.parent.selectedBrand,
        date: {
          gte: infoHeaderViewModel.multiViewModel.currentDate[0].format('YYYY-MM-DD'),
          lte: infoHeaderViewModel.multiViewModel.currentDate[1].format('YYYY-MM-DD')
        }
      };

      const res = await BrandCompetitionService.getBrandSentimentWords(params);
      runInAction(() => {
        if (res.data.chartData[0].type !== 'nodata') {
          this.chart = new BrandWordPNCloudViewModel({ ...res.data.chartData[0], data: res.data.chartData[0].data[0], isTop: this.isTop }, this);
          this.csv = res.data.chartData[0].csv;
          this.csvKey = res.data.chartData[0].csvKey;
        } else {
          this.chart = ChartFactory.createChartViewModel({ ...res.data.chartData[0], data: res.data.chartData[0].data[0] });
        }

        this.key = uuidv4();
        this.isReady = true;
      });
    } catch (error) {
      this.chart = new NoDataChartViewModel({ data: { type: 'nodata', subType: 'ana-error' } });
      this.isReady = true;
    }
  };

  @action onDownload = async () => {
    await optionsStore.onImgDownload();
    const chart = document.querySelector(`#${this.chartId}`);
    const canvas = await html2canvas(chart);
    const a = document.createElement('a');
    a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
    a.download = `${packageStore.activePackageName}-${this.chartName}-${infoHeaderViewModel.multiViewModel.currentTimeText}.jpg`;
    a.click();
    optionsStore.onImgDownloadEnd();
  };

  @action onAllDownload = async () => {
    const chart = document.querySelector(`#${this.chartId}`);
    const canvas = await html2canvas(chart);
    const a = document.createElement('a');
    a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
    a.download = `${packageStore.activePackageName}-${this.chartName}-${infoHeaderViewModel.multiViewModel.currentTimeText}.jpg`;
    a.click();
  };
  @action onModalOpen = (word) => {
    const { name, linkData } = word;

    const json = JSON.stringify(linkData);
    const result = encodeURI(json);
    const url = `${getHost()}/topics-result?result=${result}`;
    this.parent.onModalOpen({ name, url });
  };

  @action onDownloadExcel = () => {
    this.isDownload = false;
    if (!this.csv || !this.csvKey) {
      console.log('csv檔不存在');
      return;
    }

    const data = [...this.csv];
    const header = this.csvKey.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [this.csvKey.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    const wordbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wordbook, workSheet, generateWorksheetName(this.chartName));
    xlsx.writeFile(wordbook, `${packageStore.activePackageName}-${this.chartName}-${infoHeaderViewModel.multiViewModel.currentTimeText}.xlsx`);
  };

  @action onAllExcelDownload = (book) => {
    this.isDownload = false;
    if (!this.csv || !this.csvKey) {
      return;
    }

    const data = [...this.csv];
    const header = this.csvKey.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [this.csvKey.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    xlsx.utils.book_append_sheet(book, workSheet, generateWorksheetName(this.chartName));
  };
}
