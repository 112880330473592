import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';

@observer
class PresentIndustryWord extends React.Component {
  componentDidMount() {
    this.props.viewModel.didMount?.();
  }

  componentWillUnmount() {
    this.props.viewModel.willUnmount?.();
  }

  render() {
    return (
      <div className={styles.container}>
        <div id="presentIndustryWord" className={styles.wordsContainer} />
      </div>
    );
  }
}

PresentIndustryWord.propTypes = {
  viewModel: PropTypes.object
};

PresentIndustryWord.defaultProps = {
  viewModel: {
    didMount: () => {},
    willUnmount: () => {}
  }
};


export default PresentIndustryWord;
