import API from 'src/apis';
import TeamListModel from 'src/models/response/TeamList';
import SalesListModel from 'src/models/response/SalesList';
import UserListModel from 'src/models/response/UserList';
import SwitchUserListModel from 'src/models/response/SwitchUserList';
import UserInfoAuthModel from 'src/models/response/UserInfoAuth';
import UserPackagesListModel from 'src/models/response/UserPackagesList';
import UserInfoWithPackagesModel from 'src/models/response/UserInfoWithPackages';
import MyInfoModel from 'src/models/response/MyInfo';

export default class UserService {
  static async myProfile() {
    const res = await API.user.myProfile();

    return MyInfoModel.fromRes(res.data.result);
  }

  static async getUserList(anchor, params = {}) {
    const res = await API.user.getUserList(params, anchor);

    return { userList: UserListModel.fromRes(res.data.result.users).userList, anchor: res.data.result.anchor };
  }

  static async getSwitchUserList() {
    const res = await API.user.getSwitchUserList();

    return SwitchUserListModel.fromRes(res.data.result);
  }

  static async getUserInfoAuth(uid) {
    const res = await API.user.getUserInfo(uid);

    return UserInfoAuthModel.fromRes(res.data.result);
  }

  static async updateUserInfo(uid, data) {
    const res = await API.user.updateUserInfo(uid, data);

    return res;
  }

  static async getTeamList() {
    const res = await API.user.getTeamList();

    return TeamListModel.fromRes(res.data.result);
  }

  static async getTeamUsers(teamId) {
    const res = await API.user.getTeamUsers(teamId);

    return res.data.result;
  }

  static async getSalesList(teamId = null) {
    const res = await API.user.getSalesList();

    return SalesListModel.fromRes(res.data.result, teamId);
  }

  static async getUserPackages(uid) {
    const res = await API.user.getUserInfo(uid);

    return UserPackagesListModel.fromRes(res.data.result);
  }

  static async getUserInfoWithPackages(uid) {
    const res = await API.user.getUserInfo(uid);

    return UserInfoWithPackagesModel.fromRes(res.data.result);
  }

  static async createAccount(data) {
    const res = await API.user.createAccount(data);

    return res.data.result.result.id;
  }
}
