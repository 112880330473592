import API from 'src/apis';
import packageStore from 'src/stores/packageStore';

import BlockedWordsModel from 'src/models/response/BlockedWords';

export default class BlockedWordsService {
  static getPackageId = () => {
    return packageStore.activePackageId;
  };

  static async getBlockedWords() {
    const res = await API.blockedwords.getBlockedWords(BlockedWordsService.getPackageId());

    return BlockedWordsModel.fromRes(res.data.result);
  }

  static async addBlockWords(text) {
    await API.blockedwords.addBlockedWord(BlockedWordsService.getPackageId(), { text });
  }

  static async removeBlockedWords(wid) {
    await API.blockedwords.removeBlockedWord(BlockedWordsService.getPackageId(), wid);
  }
}
