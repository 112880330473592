import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { message } from 'antd';

import UserService from 'src/services/user';
import userStore from 'src/stores/userStore';

export default class CreateModalViewModel {
  @observable open = false;
  @observable parent = {};

  @observable type = '客戶';
  @observable department = null;
  @observable userName = '';
  @observable email = '';
  @observable password = '';
  @observable passwordConfirm = '';

  @observable isValidate = false;
  @observable validation = {
    name: false,
    account: false,
    password: false,
    confirm: false
  };

  @computed get errorName() {
    return this.isValidate && !this.validation.name;
  }

  @computed get errorAccount() {
    return this.isValidate && !this.validation.account;
  }

  @computed get errorPassword() {
    return this.isValidate && !this.validation.password;
  }

  @computed get errorConfirm() {
    return this.isValidate && !this.validation.confirm;
  }

  @computed get canCreate() {
    if (!this.department) {
      return false;
    }
    if (!this.userName.trim()) {
      return false;
    }
    if (!this.email.trim()) {
      return false;
    }
    if (!this.password.trim()) {
      return false;
    }
    if (!this.passwordConfirm.trim()) {
      return false;
    }
    return true;
  }

  constructor(parent) {
    makeObservable(this);

    this.init(parent);
  }

  @action init = (parent) => {
    this.parent = parent;
  };

  @action onOpen = () => {
    this.open = true;

    this.department = userStore.userTeamId;
  };

  @action onClose = () => {
    this.open = false;
  };

  @action onDepartmentSelect = (e) => {
    this.department = e;
  };

  @action onUserNameChange = (e) => {
    this.userName = e.target.value;

    if (!this.validation.name) {
      this.validation.name = true;
    }
  };

  @action onEmailChange = (e) => {
    this.email = e.target.value;

    if (!this.validation.account) {
      this.validation.account = true;
    }
  };

  @action onPasswordChange = (e) => {
    this.password = e.target.value;

    if (!this.validation.password) {
      this.validation.password = true;
    }
  };

  @action onPasswordConfirmChange = (e) => {
    this.passwordConfirm = e.target.value;

    if (!this.validation.confirm) {
      this.validation.confirm = true;
    }
  };

  @action onCheckData = () => {
    if (!this.isValidate) {
      this.isValidate = true;
    }

    if (!this.userName.trim()) {
      this.validation.name = false;
    } else {
      this.validation.name = true;
    }


    if (!this.email.trim() || !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email))) {
      this.validation.account = false;
    } else {
      this.validation.account = true;
    }

    if (this.password.trim().length < 8) {
      this.validation.password = false;
    } else {
      this.validation.password = true;
    }

    if (this.password !== this.passwordConfirm || this.passwordConfirm.trim().length < 8) {
      this.validation.confirm = false;
    } else {
      this.validation.confirm = true;
    }

    if (Object.values(this.validation).includes(false)) {
      return false;
    }

    return true;

  };

  @action onSubmit = async () => {
    const valid = this.onCheckData();
    if (!valid) {
      return;
    }
    try {
      const data = {
        name: this.userName,
        nickname: this.userName,
        email: this.email,
        password: this.password,
        customerBelong: [
          this.department
        ]
      };
      const id = await UserService.createAccount(data);

      console.log('id', id);

      runInAction(() => {
        this.onClose();
      });

      await this.parent.cleanAllFilter();

      runInAction(() => {
        const userData = {
          uid: id,
          userSSOIdentity: '客戶',
          userDepartment: [this.department],
          userName: this.userName,
          userAccount: this.email
        };
        console.log('userData', userData);
        this.parent.editDrawerViewModel.onDrawerOpen(userData);

        this.department = null;
        this.userName = '';
        this.email = '';
        this.password = '';
        this.passwordConfirm = '';
      });

    } catch (error) {
      if (error.response?.status === 409) {
        message.error('客戶帳號已存在，請通知 VOC 管理者協助處理。');
      }
      console.log(error);
    }
  };
}
