import { makeObservable, observable, action, computed } from 'mobx';

export default class CheckItemViewModel {
  @observable id = '';
  @observable name = '';
  @observable extraIds = [];
  @observable checked = false;
  @observable checkedCache = false;

  constructor(data) {
    makeObservable(this);

    this.init(data);
  }

  @action init = (data) => {
    const {
      id,
      extraIds,
      name,
      checked
    } = data;

    this.id = id;
    this.extraIds = extraIds ?? []; // > for series filter.
    this.name = name;
    this.checked = checked;
    this.checkedCache = checked;
  };

  @action toggleChecked = (e, cb) => {
    this.checkedCache = e.target.checked;
    cb(this.checkedCache, { id: this.id, name: this.name });
  };

  @action setSelect = (bool) => {
    this.checkedCache = bool;
  };

  @action confirmSelect = () => {
    this.checked = this.checkedCache;
  };

  @action onDisposeSelect = () => {
    this.checkedCache = this.checked;
  };

  @action setInit = () => {
    this.checked = true;
    this.checkedCache = true;
  };
}
