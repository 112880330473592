import { makeObservable, observable, action, computed } from 'mobx';

export default class ScrollTopViewModel {
  @observable isHover = false;

  constructor() {
    makeObservable(this);
  }

  @action onClick = (className) => {
    if (className) {
      const container = document.querySelector(`.${className}`);
      container.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  @action onHover = () => {
    this.isHover = true;
  };

  @action onLeave = () => {
    this.isHover = false;
  };
}
