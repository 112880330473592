import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { message } from 'antd';

import LevelSelectModalViewModel from 'src/components/LevelSelectModal/viewModel';
import UserService from 'src/services/user';
import PackageService from 'src/services/package';
import userStore from 'src/stores/userStore';
import i18n from 'src/i18n';
import dayjs from 'dayjs';
import optionsStore from 'src/stores/optionsStore';

export default class PackagesDrawerViewModel {
  @observable isDrawerOpen = false;
  @observable parent = {};

  @observable packageId = null;

  @observable selectModalViewModel = new LevelSelectModalViewModel();

  @observable packageOwnerList = [];
  @observable packageOwner = null;
  @observable packageOwnerCache = null;

  @observable packagesName = '';
  @observable packagesNameCache = '';

  @observable teamList = [];
  @observable selectedTeam = null;
  @observable selectedTeamCache = null;

  @observable salesList = [];
  @observable selectedSales = null;
  @observable selectedSalesCache = null;

  @observable projectList = [];
  @observable selectedProject = null;
  @observable selectedProjectCache = null;

  @observable validDate = [];
  @observable validDateCache = [];

  @observable projectValidDate = [dayjs(), dayjs()];

  @observable openedLevel = {
    industry: false,
    brand: false,
    series: false,
    product: false
  };

  @observable openedLevelCache = {
    industry: false,
    brand: false,
    series: false,
    product: false
  };

  @observable seriesParentList = [];
  @observable allSeriesList = [];
  @observable selectedParent = [];
  @observable selectedParentCache = [];

  @observable featureList = [];
  @observable selectedFeature = null;
  @observable selectedFeatureCache = null;

  @observable customerList = [];
  @observable selectedCustomer = [];
  @observable selectedCustomerCache = [];
  @observable lastSelected = [];

  @observable isReadOnly = false;

  @observable isValidate = false;
  @observable validation = {
    owner: true,
    packageName: false,
    project: false,
    time: false,
    levelsOpen: false,
    levels: false
  };


  @computed get addedUsers() {
    return this.selectedCustomer.filter((el) => !this.lastSelected.includes(el));
  }

  @computed get removeUsers() {
    return this.lastSelected.filter((el) => !this.selectedCustomer.includes(el));
  }

  @computed get submitBtn() {
    if (this.isReadOnly) {
      return i18n.t('package_modal_close');
    }

    if (this.packageId) {
      return i18n.t('package_edit_submit');
    }
    return i18n.t('package_create_submit');
  }

  @computed get projectLink() {
    const url = (() => {
      // local
      if (/:\d{4,}$/.test(window.location.origin)) {
        return 'https://buzz-pm-d90d217a.miraclemobile.com.tw/project/settings/';
      }

      // dev
      if (/d90d217a/.test(window.location.host)) {
        return 'https://buzz-pm-d90d217a.miraclemobile.com.tw/project/settings/';
      }

      // pro
      return 'https://pm.i-buzz-system.com/project/settings/';
    })();

    return `${url}${this.selectedProject}`;
  }

  @computed get ownerError() {
    return this.isValidate && !this.validation.owner;
  }

  @computed get packageNameError() {
    return this.isValidate && !this.validation.packageName;
  }

  @computed get projectError() {
    return this.isValidate && !this.validation.project;
  }

  @computed get timeError() {
    return this.isValidate && !this.validation.time;
  }

  @computed get levelsOpenError() {
    return this.isValidate && !this.validation.levelsOpen;
  }

  @computed get levelError() {
    return this.isValidate && !this.validation.levels;
  }

  @computed get drawerTitle() {
    if (this.isReadOnly) {
      return '檢視數據包設定';
    }

    if (this.packageId) {
      return '編輯數據包設定';
    }

    return i18n.t('packages_drawer_title');
  }

  constructor(parent) {
    makeObservable(this);

    this.init(parent);
  }

  @action init = async (parent) => {
    this.parent = parent;

    await Promise.all([
      this.getTeamList(),
      this.getAllSales(),
      this.getCustomer()
    ]);

    runInAction(() => {
      this.packageOwner = userStore.userId;
    });
  };

  @action clean = async () => {
    this.packageId = null;
    this.selectModalViewModel.brand = [];
    this.selectModalViewModel.series = [];
    this.selectModalViewModel.product = [];
    this.packageOwner = userStore.userId;
    this.packageOwnerCache = userStore.userId;
    this.packagesName = '';
    this.packagesNameCache = '';
    if (this.teamList.some((el) => el.value === '633d45412f7975001edccc29')) {
      this.selectedTeam = '633d45412f7975001edccc29';
      this.selectedTeamCache = '633d45412f7975001edccc29';
    }
    if (this.teamList.some((el) => el.value === '6297249f6a46f1001cd54311')) {
      this.selectedTeam = '6297249f6a46f1001cd54311';
      this.selectedTeamCache = '6297249f6a46f1001cd54311';
    }
    this.selectedSales = null;
    this.selectedSalesCache = null;
    this.selectedProject = null;
    this.selectedProjectCache = null;
    this.validDate = [];
    this.validDateCache = [];
    this.openedLevel = {
      industry: false,
      brand: false,
      series: false,
      product: false
    };
    this.openedLevelCache = {
      industry: false,
      brand: false,
      series: false,
      product: false
    };
    this.seriesParentList = [];
    this.allSeriesList = [];
    this.selectedParent = [];
    this.selectedParentCache = [];
    this.featureList = [];
    this.selectedFeature = null;
    this.selectedFeatureCache = null;
    this.selectedCustomer = [];
    this.selectedCustomerCache = [];

    await this.getSalesList();
    await this.getProjects({ teamId: this.selectedTeam });
  };

  // > drawer func

  @action onDrawerOpen = async (id) => {
    if (id) {
      this.packageId = id;
      this.selectModalViewModel.brand = [];
      this.selectModalViewModel.series = [];
      this.selectModalViewModel.product = [];
      await this.getPackageInfo();
    } else {
      this.clean();
    }
    runInAction(() => {
      this.isDrawerOpen = true;
    });
  };

  @action onReadOnlyDrawerOpen = async (id, deleted = true) => {
    if (this.packageId !== id) {
      this.packageId = id;
      await this.getPackageInfo(deleted);
    }
    runInAction(() => {
      this.isReadOnly = true;
      this.isDrawerOpen = true;
    });
  };

  @action getPackageInfo = async (isDeleted = false) => {
    try {
      const {
        owner,
        name,
        projectId,
        selectedTeam,
        selectedSales,
        validDate,
        openedLevel,
        searchKeywordLevel1Ids,
        searchTags,
        searchKeywordLevel3Ids,
        featureSetId,
        sharedUsers
      } = !isDeleted ? await PackageService.getPackageInfo(this.packageId) : await PackageService.getDeletedPackageInfo(this.packageId);

      runInAction(() => {
        this.packageOwner = owner?.id;
        this.packageOwnerCache = owner?.id;
        this.packagesName = name;
        this.packagesNameCache = name;
        this.selectedTeam = selectedTeam;
        this.selectedTeamCache = selectedTeam;
        this.selectedSales = selectedSales;
        this.selectedSalesCache = selectedSales;
        this.selectedProject = projectId;
        this.selectedProjectCache = projectId;
        this.validDate = validDate;
        this.validDateCache = validDate;
        this.openedLevel = { ...openedLevel };
        this.openedLevelCache = { ...openedLevel };
        this.selectedFeature = featureSetId;
        this.selectedFeatureCache = featureSetId;
        this.selectedCustomer = [...sharedUsers];
        this.selectedCustomerCache = [...sharedUsers];
        this.lastSelected = [...sharedUsers];

        const filterSeriesParent = (() => {
          const res = [];
          searchTags.forEach((el) => {
            if (!res.includes(el.parentId)) {
              res.push(el.parentId);
            }
          });
          return res.filter((el) => el);
        })();
        this.selectedParent = [...filterSeriesParent];
        this.selectedParentCache = [...filterSeriesParent];
        this.customerList = this.packageOwnerList.find((el) => el.value === this.packageOwner)?.customers.map((el) => ({ value: el.id, label: el.name })) ?? [];
      });

      await Promise.all([
        this.getSalesList(),
        this.getProjects({ teamId: this.selectedTeam, userId: this.selectedSales }),
        this.onProjectSelect(this.selectedProject, true)
      ]);

      runInAction(() => {
        this.onParentSeriesSelect(this.selectedParent);
        this.selectModalViewModel.brand.forEach((el) => {
          if (searchKeywordLevel1Ids.includes(el.id)) {
            el.setInit();
          }
        });
        this.selectModalViewModel.series.forEach((el) => {
          el.children.forEach((c) => {
            const target = searchTags.find((t) => t.id === c.id);
            if (target) {
              c.setInit();
            }
          });
        });
      });

      await this.selectModalViewModel.getProductOnce();
      runInAction(() => {
        this.selectModalViewModel.product.forEach((el) => {
          el.children.forEach((c) => {
            if (searchKeywordLevel3Ids.includes(c.id)) {
              c.setInit();
            }
          });
        });
      });

    } catch (error) {
      console.log(error);
    }
  };

  @action onDrawerClose = () => {
    this.isDrawerOpen = false;
    this.isReadOnly = false;
    this.isValidate = false;

    this.validation = {
      owner: true,
      packageName: false,
      project: false,
      time: false,
      levelsOpen: false,
      levels: false
    };
  };

  @action onAddBtnClick = () => {
    this.onDrawerOpen();
  };

  // > package owner related
  @action getAllSales = async () => {
    const { salesList } = await UserService.getSalesList(userStore.userType !== 'admin' ? userStore.userTeamId : null);
    runInAction(() => {
      this.packageOwnerList = salesList.map((el) => ({
        value: el.id,
        label: el.name,
        teamId: el.teamId,
        customers: el.customers
      }));
    });
  };

  @action onOwnerChange = (e) => {
    this.packageOwner = e;
    this.selectedCustomer = [];
    this.customerList = this.packageOwnerList.find((el) => el.value === e)?.customers.map((el) => ({ value: el.id, label: el.name })) ?? [];
  };

  // > package name related

  @action onPackagesNameChange = (e) => {
    this.packagesName = e.target.value;
    if (!this.validation.packageName) {
      this.validation.packageName = true;
    }
  };

  @action onPackagesNameCheck = () => {
    if (!this.packagesName.trim()) {
      this.validation.packageName = false;
      return;
    }
    const regex = /[^\u4e00-\u9fa5a-zA-Z0-9+_'.% ]/g;
    if (!regex.test(this.packagesName)) {
      this.validation.packageName = true;
    } else {
      this.validation.packageName = false;
    }
  };

  // > team related.

  @action getTeamList = async () => {
    try {
      const { teamList } = await UserService.getTeamList();
      runInAction(() => {
        this.teamList = teamList;
        // 633d45412f7975001edccc29
      });

    } catch (error) {
      console.log(error);
    }
  };

  @action onTeamSelect = async (e) => {
    this.selectedTeam = e;
    this.selectedSales = null;
    this.selectedProject = null;
    await this.getSalesList();
    await this.getProjects({ teamId: this.selectedTeam });
  };

  // > sales related
  @action getSalesList = async () => {
    const res = await UserService.getTeamUsers(this.selectedTeam);

    runInAction(() => {
      this.salesList = res.map((el) => ({
        value: el.id,
        label: el.name
      }));
    });
  };

  @action onSalesSelect = async (e) => {
    this.selectedSales = e;
    this.selectedProject = null;
    await this.getProjects({ teamId: this.selectedTeam, userId: this.selectedSales });
  };

  // > project related

  @action getProjects = async (params) => {
    try {
      const res = await PackageService.getProjects(params);

      runInAction(() => {
        this.projectList = res.map((el) => ({
          value: el._id,
          label: `${el.meta?.name}-${el._id}`,
          name: el.meta?.name,
          owner: el.owner?.user?.id
        }));
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action onProjectSelect = async (e, isInit = false) => {
    this.selectedProject = e;

    if (!this.validation.project) {
      this.validation.project = true;
    }

    if (!this.selectedSales) {
      this.selectedSales = this.projectList.find((el) => el.value === e).owner;
    }

    if (!isInit) {
      this.cleanAfterProjectChange();
    }

    await Promise.all([
      this.selectModalViewModel.updateProjectId(e),
      this.getSeries(),
      this.getFeatureList(),
      this.getProjectsById()
    ]);
  };

  @action getProjectsById = async () => {
    try {
      const projectData = await PackageService.getProjectsById(this.selectedProject);

      runInAction(() => {
        this.projectValidDate = [dayjs(projectData.startDate), dayjs(projectData.endDate)];
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action cleanAfterProjectChange = () => {

    this.selectModalViewModel.brand = [];
    this.selectModalViewModel.series = [];
    this.selectModalViewModel.product = [];

    this.openedLevel = {
      industry: false,
      brand: false,
      series: false,
      product: false
    };
    this.seriesParentList = [];
    this.allSeriesList = [];
    this.selectedParent = [];
    this.selectedParentCache = [];
    this.featureList = [];
    this.selectedFeature = null;
    this.selectedFeatureCache = null;
  };

  // > valid date related

  @action onValidTimeSelect = (e) => {
    this.validDate = e;

    if (!this.validation.time) {
      this.validation.time = true;
    }
  };

  // > opened level related
  @action onIndustryOpenChange = (e) => {
    this.openedLevel.industry = e.target.checked;

    if (!this.validation.levelsOpen) {
      this.validation.levelsOpen = true;
    }
  };

  @action onBrandOpenChange = (e) => {
    this.openedLevel.brand = e.target.checked;

    if (!this.validation.levelsOpen) {
      this.validation.levelsOpen = true;
    }
  };

  @action onSeriesOpenChange = (e) => {
    this.openedLevel.series = e.target.checked;

    if (!this.validation.levelsOpen) {
      this.validation.levelsOpen = true;
    }
  };

  @action onProductOpenChange = (e) => {
    this.openedLevel.product = e.target.checked;

    if (!this.validation.levelsOpen) {
      this.validation.levelsOpen = true;
    }
  };


  // > series
  // > call here due to need parent series.

  @action getSeries = async () => {
    try {
      optionsStore.setLoading('getSeries');
      const { parents, series } = await PackageService.getSeries(this.selectedProject);
      runInAction(() => {
        this.seriesParentList = parents;
        this.selectedParent = this.selectedParent?.filter((tag) => parents.map((item) => item.value).includes(tag)) ?? [];

        this.allSeriesList = series;
      });
    } catch (error) {
      console.log(error);
    } finally {
      optionsStore.setCompleted('getSeries');
    }
  };

  @action onParentSeriesSelect = (e) => {
    this.selectedParent = e;
    this.seriesList = this.allSeriesList.filter((el) => this.selectedParent.some((p) => p === el.id));
    this.selectModalViewModel.updateSeries(this.seriesList);
  };

  // > level select

  @action onLevelsFilterOpen = () => {
    if (!this.validation.levels) {
      this.validation.levels = true;
    }

    this.selectModalViewModel.onModalOpen();
  };

  // > feature set related

  @action getFeatureList = async () => {
    try {
      const { featureSetList } = await PackageService.getFeatureSet(this.selectedProject);

      runInAction(() => {
        this.featureList = [...featureSetList];
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action onFeatureSet = (e) => {
    this.selectedFeature = e;
  };

  // > customer related

  @action getCustomer = async () => {
    try {
      const { customerList } = await PackageService.getMyCustomers();

      runInAction(() => {
        this.customerList = [...customerList];
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action onCustomerSelect = (e) => {
    this.selectedCustomer = e;
  };

  // > submit func

  @action checkData = () => {
    return new Promise((res, rej) => {
      if (!this.isValidate) {
        this.isValidate = true;
      }

      // > 選擇專案
      if (!this.selectedProject) {
        this.validation.project = false;
      } else {
        this.validation.project = true;
      }

      // > 數據包名稱
      this.onPackagesNameCheck();

      // > 時間範圍設定

      if (!this.validDate[0] || !this.validDate[1]) {
        this.validation.time = false;
      } else {
        this.validation.time = true;
      }

      // > 四個選項必須有一個開放
      if (!this.openedLevel.brand && !this.openedLevel.industry && !this.openedLevel.product && !this.openedLevel.series) {
        this.validation.levelsOpen = false;
      } else {
        this.validation.levelsOpen = true;
      }

      // > 沒有設定內容

      if (
        this.selectModalViewModel.selectedBrands.length === 0
        && this.selectModalViewModel.selectedProduct.length === 0
        && this.selectModalViewModel.selectedSeries.length === 0
      ) {
        this.validation.levels = false;
      } else {
        this.validation.levels = true;
      }

      if (Object.values(this.validation).includes(false)) {
        message.error('資料有誤, 請重新確認');
        rej();
      }

      res();
    });
  };

  @action onSubmit = async () => {
    try {
      optionsStore.setLoading('datapackage');
      await this.checkData();

      if (this.packageId) {
        await this.onEditSubmit();
      } else {
        await this.onCreate();
      }
    } catch (error) {
      console.log(error);
    } finally {
      optionsStore.setCompleted('datapackage');
    }
  };

  @action onCreate = async () => {
    try {
      const data = {
        name: this.packagesName,
        projectId: this.selectedProject,
        startDate: this.validDate[0].startOf('day').toISOString(),
        endDate: this.validDate[1].endOf('day').toISOString(),
        ownerId: this.packageOwner,
        searchKeywordLevel1Ids: this.selectModalViewModel.selectedBrands,
        searchKeywordLevel3Ids: this.selectModalViewModel.selectedProduct,
        searchTags: [...this.selectModalViewModel.selectedSeriesParent, ...this.selectModalViewModel.selectedSeries],
        featureSetId: this.selectedFeature,
        sharedUserIds: this.selectedCustomer,
        industry: this.openedLevel.industry,
        brand: this.openedLevel.brand,
        tag: this.openedLevel.series,
        product: this.openedLevel.product
      };

      await PackageService.createPackage(data);

      runInAction(() => {
        message.success('設定數據包成功！');
        this.onDrawerClose();
        this.parent.manageSectionViewModel.afterUpdate();
      });
    } catch (error) {
      if (error.response?.status === 400) {
        message.error('資料有誤, 請重新確認');
        return;
      }

      if (error.response?.status === 409) {
        message.error('數據包名稱重複，請重新確認！');
        runInAction(() => {
          this.validation.packageName = false;
        });
        return;
      }

      if (error.response?.status === 404) {
        if (error.response?.data?.message === 'project not start') {
          message.error('專案尚未分析因此無任何數據，請至專案管理工具確認專案分析狀態');
          return;
        }
      }

      message.error('目前無法新增數據包, 請稍後再試');
    }
  };

  @action onEditSubmit = async () => {
    try {
      const data = {
        name: this.packagesName,
        projectId: this.selectedProject,
        startDate: this.validDate[0].startOf('day').toISOString(),
        endDate: this.validDate[1].endOf('day').toISOString(),
        ownerId: this.packageOwner,
        searchKeywordLevel1Ids: this.selectModalViewModel.selectedBrands,
        searchKeywordLevel3Ids: this.selectModalViewModel.selectedProduct,
        searchTags: [...this.selectModalViewModel.selectedSeriesParent, ...this.selectModalViewModel.selectedSeries],
        featureSetId: this.selectedFeature,
        addSharedUserIds: this.addedUsers,
        removeSharedUserIds: this.removeUsers,
        industry: this.openedLevel.industry,
        brand: this.openedLevel.brand,
        tag: this.openedLevel.series,
        product: this.openedLevel.product
      };

      const res = await PackageService.updatePackage(this.packageId, data);

      runInAction(() => {
        const { newlyAddSharedUserIds } = res.data.result;
        if (newlyAddSharedUserIds) {
          const successCount = newlyAddSharedUserIds.length;
          const addCount = this.addedUsers.length;
          if (addCount > successCount) {
            const failed = this.addedUsers.filter((el) => !newlyAddSharedUserIds.includes(el));
            this.selectedCustomer = this.selectedCustomer.filter((el) => !failed.includes(el));
            message.warning(`設定數據包成功，新增${successCount}位客戶， ${addCount - successCount}位客戶新增失敗，請確認客戶可用的數據包數量 ！`);
          } else if (addCount > 0 && addCount === successCount) {
            message.success(`設定數據包成功，新增${successCount}位客戶！`);
            this.onDrawerClose();
          } else {
            message.success('設定數據包成功！');
            this.onDrawerClose();
          }
          this.parent.manageSectionViewModel.afterUpdate();
        }
      });
    } catch (error) {
      if (error.response?.status === 400) {
        message.error('資料有誤, 請重新確認');
        return;
      }

      if (error.response?.status === 409) {
        message.error('數據包名稱重複，請重新確認！');
        runInAction(() => {
          this.validation.packageName = false;
        });
        return;
      }

      message.error('目前無法修改數據包, 請稍後再試');
    }
  };

}
