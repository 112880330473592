import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';

import { CloseOutlined, CheckOutlined, CaretDownFilled, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Button, Modal, Select, Input } from 'antd';

import PaperIcon from 'src/assets/PaperIcon.svg';

import userStore from 'src/stores/userStore';

import styles from './styles.module.scss';

@observer
class CreateModal extends React.Component {
  _renderVisible = (visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />);

  render() {
    const { viewModel } = this.props;
    return (
      <Modal
        open={viewModel.open}
        onCancel={viewModel.onClose}
        centered
        closable={false}
        footer={null}
        width={700}
      >
        <div className={styles.modalContainer}>
          <div className={styles.modalHeader}>
            <div className={styles.headerLeft}>
              <img src={PaperIcon} alt="" className={styles.paperIcon} />
              <div className={styles.headerText}>
                {i18n.t('account_manage_create_modal_title')}
              </div>
            </div>
            <Button
              icon={<CloseOutlined />}
              type="link"
              onClick={viewModel.onClose}
            >
              關閉
            </Button>
          </div>
          <div className={styles.modalBody}>
            <div className={styles.rowItem}>
              <div className={styles.rowTitle}>
                {i18n.t('account_manage_create_modal_identity_title')}
              </div>
              <div className={styles.rowContent}>
                <Select
                  className={clsx(styles.rowSelect)}
                  value={viewModel.type}
                  disabled
                  suffixIcon={<CaretDownFilled style={{ pointerEvents: 'none' }} />}
                  placeholder={i18n.t('account_manage_create_modal_identity_placeholder')}
                />
              </div>
            </div>

            <div className={styles.rowItem}>
              <div className={styles.rowTitle}>
                {i18n.t('account_manage_create_modal_department_title')}
              </div>
              <div className={styles.rowContent}>
                <Select
                  className={styles.rowSelect}
                  value={viewModel.department}
                  disabled={userStore.userType !== 'admin'}
                  suffixIcon={<CaretDownFilled style={{ pointerEvents: 'none' }} />}
                  placeholder={i18n.t('account_manage_create_modal_department_placeholder')}
                  options={viewModel.parent.departmentList}
                  onChange={viewModel.onDepartmentSelect}
                />
              </div>
            </div>

            <div className={clsx(styles.rowItem, viewModel.errorName && styles.errorItem)}>
              <div className={clsx(styles.rowTitle)}>
                {i18n.t('account_manage_create_modal_name_title')}
              </div>
              <div className={styles.rowContent}>
                <Input
                  className={clsx(styles.rowInput)}
                  value={viewModel.userName}
                  onChange={viewModel.onUserNameChange}
                  placeholder={i18n.t('account_manage_create_modal_name_placeholder')}
                />
                <div className={styles.errorNote}>
                  使用者名稱輸入錯誤，請重新確認。
                </div>
              </div>
            </div>

            <div className={clsx(styles.rowItem, viewModel.errorAccount && styles.errorItem)}>
              <div className={styles.rowTitle}>
                {i18n.t('account_manage_create_modal_account_title')}
              </div>
              <div className={styles.rowContent}>
                <Input
                  className={clsx(styles.rowInput)}
                  value={viewModel.email}
                  onChange={viewModel.onEmailChange}
                  placeholder={i18n.t('account_manage_create_modal_account_placeholder')}
                />
                <div className={styles.errorNote}>
                  電子信箱輸入錯誤或格式有誤，請重新確認。
                </div>
              </div>
            </div>

            <div className={clsx(styles.rowItem, viewModel.errorPassword && styles.errorItem)}>
              <div className={styles.rowTitle}>
                {i18n.t('account_manage_create_modal_password_title')}
              </div>
              <div className={styles.rowContent}>
                <Input.Password
                  className={clsx(styles.rowInput)}
                  value={viewModel.password}
                  onChange={viewModel.onPasswordChange}
                  placeholder={i18n.t('account_manage_create_modal_password_placeholder')}
                  iconRender={this._renderVisible}
                />
                <div className={styles.errorNote}>
                  密碼必須至少八碼
                </div>
              </div>
            </div>

            <div className={clsx(styles.rowItem, viewModel.errorConfirm && styles.errorItem)}>
              <div className={styles.rowTitle}>
                {i18n.t('account_manage_create_modal_password_confirm_title')}
              </div>
              <div className={styles.rowContent}>
                <Input.Password
                  className={clsx(styles.rowInput)}
                  value={viewModel.passwordConfirm}
                  onChange={viewModel.onPasswordConfirmChange}
                  placeholder={i18n.t('account_manage_create_modal_password_confirm_placeholder')}
                  iconRender={this._renderVisible}
                />
                <div className={styles.errorNote}>
                  密碼不相符，請重新輸入
                </div>
              </div>
            </div>
          </div>
          <div className={styles.modalFooter}>
            <Button
              className={clsx(styles.btn, viewModel.canCreate && styles.actionBtn)}
              icon={<CheckOutlined />}
              disabled={!viewModel.canCreate}
              onClick={viewModel.onSubmit}
            >
              {i18n.t('account_manage_create_modal_submit')}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

CreateModal.propTypes = {
  viewModel: PropTypes.object
};

CreateModal.defaultProps = {
  viewModel: {}
};


export default CreateModal;
