import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import html2canvas from 'html2canvas';
import * as xlsx from 'xlsx';

import BrandCompetitionService from 'src/services/charts/DataAnalysis/brandCompetition';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import ChartFactory from 'src/components/Charts/factory';
import SinglePopoverSelectViewModel from 'src/components/SinglePopoverSelect/viewModel';
import packageStore from 'src/stores/packageStore';
import optionsStore from 'src/stores/optionsStore';
import NoDataChartViewModel from 'src/components/Charts/NoData/viewModel';
import { generateWorksheetName } from 'src/utils/chart';

export default class BrandVolumeRankingViewModel {
  chartName = '品牌聲量排行';
  parent = {};
  @observable key = uuidv4();
  @observable chart = {};

  @observable isLoading = true;
  @observable selectedWOM = 'all';
  @observable popoverViewModel = new SinglePopoverSelectViewModel();

  @observable isInit = false;
  @observable isReady = false;

  @observable isDownload = false;
  @observable downloadFileName = '';
  @observable downloadList = [];

  constructor(parent) {
    makeObservable(this);

    this.parent = parent;
  }

  @action updateList = () => {
    this.popoverViewModel.updateList(packageStore.brand);
  };

  @action init = async () => {
    await this.getChart();
    this.updateList();

    runInAction(() => {
      this.isInit = true;
    });
  };

  @action onWOMChange = async (e) => {
    this.selectedWOM = e;

    await this.getChart();
  };

  @action getChart = async () => {
    try {
      this.isReady = false;
      const params = {
        ...(this.parent.postType !== 'all' && { postType: this.parent.postType }),
        ...(this.selectedWOM !== 'all' && { womType: this.selectedWOM }),
        ...(this.popoverViewModel.selected.length > 0 && { search: this.popoverViewModel.selected.map((el) => el.id) }),
        date: {
          gte: infoHeaderViewModel.multiViewModel.currentDate[0].format('YYYY-MM-DD'),
          lte: infoHeaderViewModel.multiViewModel.currentDate[1].format('YYYY-MM-DD')
        }
      };

      const res = await BrandCompetitionService.getBrandVolumeRanking(params);
      runInAction(() => {
        this.chart = ChartFactory.createChartViewModel({ ...res.data.chartData[0], data: res.data.chartData[0].data[0] });
        if (this.popoverViewModel.list.length === 0 && this.chart.type !== 'nodata') {
          const first = res.data.brand.slice(0, res.data.brand.length).sort((a, b) => b.count - a.count)[0];
          this.parent.updateTopOneBrand({ id: first.id, name: first.name });
        }

        this.key = uuidv4();
        this.isReady = true;
      });
    } catch (error) {
      this.chart = new NoDataChartViewModel({ data: { type: 'nodata', subType: 'ana-error' } });
      this.isReady = true;
    }
  };
  @action onDownload = async () => {
    await optionsStore.onImgDownload();
    const chart = document.querySelector('#brand_volume_ranking');
    const canvas = await html2canvas(chart);
    const a = document.createElement('a');
    a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
    a.download = `${packageStore.activePackageName}-${this.chartName}-${infoHeaderViewModel.multiViewModel.currentTimeText}.jpg`;
    a.click();
    optionsStore.onImgDownloadEnd();
  };

  @action onAllDownload = async () => {
    const chart = document.querySelector('#brand_volume_ranking');
    const canvas = await html2canvas(chart);
    const a = document.createElement('a');
    a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
    a.download = `${packageStore.activePackageName}-${this.chartName}-${infoHeaderViewModel.multiViewModel.currentTimeText}.jpg`;
    a.click();
  };

  @action onDownloadExcel = () => {
    this.isDownload = false;
    const { csv, csvKey } = this.chart;
    if (!csv || !csvKey) {
      console.log('csv檔不存在');
      return;
    }

    const data = [...csv];
    const header = csvKey.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [csvKey.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    const wordbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wordbook, workSheet, generateWorksheetName(this.chartName));
    xlsx.writeFile(wordbook, `${packageStore.activePackageName}-${this.chartName}-${infoHeaderViewModel.multiViewModel.currentTimeText}.xlsx`);
  };

  @action onAllExcelDownload = (book) => {
    this.isDownload = false;
    const { csv, csvKey } = this.chart;
    if (!csv || !csvKey) {
      return;
    }

    const data = [...csv];
    const header = csvKey.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [csvKey.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    xlsx.utils.book_append_sheet(book, workSheet, generateWorksheetName(this.chartName));
  };
}
