import { makeObservable, observable, action, computed, runInAction, reaction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import html2canvas from 'html2canvas';
import { encodeURI } from 'js-base64';
import * as xlsx from 'xlsx';

import BrandEffectService from 'src/services/charts/DataAnalysis/brandEffect';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import ChartFactory from 'src/components/Charts/factory';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import optionsStore from 'src/stores/optionsStore';
import NoDataChartViewModel from 'src/components/Charts/NoData/viewModel';
import { generateWorksheetName } from 'src/utils/chart';

import PresentBrandWordViewModel from './WordCloud/Present/viewModel';
import PreviousBrandWordViewModel from './WordCloud/Previous/viewModel';

export default class BrandWordViewModel {
  parent = {};
  @observable presentKey = uuidv4();
  @observable previousKey = uuidv4();
  @observable chart = {};
  @observable prevChart = {};
  @observable tableContent = [];

  @observable isLoading = true;

  @observable isInit = false;
  @observable isReady = false;

  @observable isDownload = false;
  @observable downloadFileName = '';
  @observable downloadList = [];

  @computed get chartName() {
    return `主品牌(${this.parent.selectedBrandName})不分類文字雲`;
  }

  constructor(parent) {
    makeObservable(this);

    this.parent = parent;
  }

  @action init = async () => {
    if (this.parent.initBlockedWords) {
      await this.getChart();
      runInAction(() => {
        this.isInit = true;
      });
    } else {
      const once = reaction(
        () => this.parent.initBlockedWords,
        async (boolean) => {
          if (boolean) {
            await this.getChart();
            runInAction(() => {
              this.isInit = true;
              once();
            });
          }
        }
      );
    }
  };

  @action getTableContent = () => {
    const words = [];
    this.chart?.words?.forEach((el) => {
      words.push({
        id: el.id,
        name: el.name,
        originCount: el.originCount,
        originCountCompare: 0
      });
    });
    this.prevChart?.words?.forEach((el) => {
      const target = words.find((w) => w.id === el.id);
      if (target) {
        target.originCountCompare = el.originCount;
      } else {
        words.push({
          id: el.id,
          name: el.name,
          originCount: 0,
          originCountCompare: el.originCount
        });
      }
    });

    words.sort((a, b) => b.originCount - a.originCount);

    this.tableContent = words;
  };

  @action getChart = async () => {
    try {
      this.isReady = false;
      const params = {
        ...(this.parent.postType !== 'all' && { postType: this.parent.postType }),
        ...(this.parent.blockedWords.length > 0 && { disableTerm: this.parent.blockedWords.map((el) => el.name) }),
        main: this.parent.selectedBrand,
        date: {
          gte: infoHeaderViewModel.multiViewModel.currentDate[0].format('YYYY-MM-DD'),
          lte: infoHeaderViewModel.multiViewModel.currentDate[1].format('YYYY-MM-DD')
        }
      };

      const prevParams = {
        ...(this.parent.postType !== 'all' && { postType: this.parent.postType }),
        ...(this.parent.blockedWords.length > 0 && { disableTerm: this.parent.blockedWords.map((el) => el.name) }),
        main: this.parent.selectedBrand,
        date: {
          gte: infoHeaderViewModel.multiViewModel.autoPrevious[0].format('YYYY-MM-DD'),
          lte: infoHeaderViewModel.multiViewModel.autoPrevious[1].format('YYYY-MM-DD')
        }
      };

      const { present, previous } = await BrandEffectService.getBrandWord(params, prevParams);
      runInAction(() => {
        if (present.data.chartData[0].type !== 'nodata') {
          this.chart = new PresentBrandWordViewModel({ ...present.data.chartData[0], data: present.data.chartData[0].data[0] }, this);
        } else {
          this.chart = ChartFactory.createChartViewModel({ ...present.data.chartData[0], data: present.data.chartData[0].data[0] });
        }

        if (previous.data.chartData[0].type !== 'nodata') {
          this.prevChart = new PreviousBrandWordViewModel({ ...previous.data.chartData[0], data: previous.data.chartData[0].data[0] }, this);
        } else {
          this.prevChart = ChartFactory.createChartViewModel({ ...previous.data.chartData[0], data: previous.data.chartData[0].data[0] });
        }

        if (this.chart.type !== 'nodata' || this.prevChart.type !== 'nodata') {
          this.getTableContent();
        }

        this.presentKey = uuidv4();
        this.previousKey = uuidv4();

        this.isReady = true;
      });
    } catch (error) {
      this.chart = new NoDataChartViewModel({ data: { type: 'nodata', subType: 'ana-error' } });
      this.prevChart = new NoDataChartViewModel({ data: { type: 'nodata', subType: 'ana-error' } });
      this.isReady = true;
    }
  };

  @action onDownload = async () => {
    await optionsStore.onImgDownload();
    const chart = document.querySelector('#main_brand_word_download');
    const canvas = await html2canvas(chart);
    const a = document.createElement('a');
    a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
    a.download = `${packageStore.activePackageName}-${this.chartName}-${infoHeaderViewModel.multiViewModel.currentTimeText}-${infoHeaderViewModel.multiViewModel.autoPreviousTimeText}.jpg`;
    a.click();
    optionsStore.onImgDownloadEnd();
  };

  @action onAllDownload = async () => {
    const chart = document.querySelector('#main_brand_word_download');
    const canvas = await html2canvas(chart);
    const a = document.createElement('a');
    a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
    a.download = `${packageStore.activePackageName}-${this.chartName}-${infoHeaderViewModel.multiViewModel.currentTimeText}-${infoHeaderViewModel.multiViewModel.autoPreviousTimeText}.jpg`;
    a.click();
  };

  @action onDownloadExcel = () => {
    const csv = this.tableContent.map((el) => ({
      name: el.name,
      curr: el.originCount,
      prev: el.originCountCompare
    }));
    const csvKey = [
      { key: 'name', header: '名稱' },
      { key: 'curr', header: '本期提及則數' },
      { key: 'prev', header: '前期提及則數' }
    ];
    const data = [...csv];
    const header = csvKey.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [csvKey.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    const wordbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wordbook, workSheet, generateWorksheetName(this.chartName));
    xlsx.writeFile(wordbook, `${packageStore.activePackageName}-${this.chartName}-${infoHeaderViewModel.multiViewModel.currentTimeText}-${infoHeaderViewModel.multiViewModel.autoPreviousTimeText}.xlsx`);
  };
  @action onAllExcelDownload = (book) => {
    const csv = this.tableContent.map((el) => ({
      name: el.name,
      curr: el.originCount,
      prev: el.originCountCompare
    }));
    const csvKey = [
      { key: 'name', header: '名稱' },
      { key: 'curr', header: '本期提及則數' },
      { key: 'prev', header: '前期提及則數' }
    ];
    const data = [...csv];
    const header = csvKey.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [csvKey.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    xlsx.utils.book_append_sheet(book, workSheet, generateWorksheetName(this.chartName));
  };
  @action onModalOpen = (word) => {
    const { name, linkData } = word;

    const json = JSON.stringify(linkData);
    const result = encodeURI(json);
    const url = `${getHost()}/topics-result?result=${result}`;
    this.parent.onModalOpen({ name, url });
  };
}
