import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import packageStore from 'src/stores/packageStore';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getTwo } from 'src/utils/chart';

const convertFunction = (item) => {
  return ({
    name: item.name ?? item,
    score: getTwo(item.score ?? 0),
    ...(item.assocs && { children: item.assocs.map((el) => convertFunction(el)) })
  });
};

const isAllZero = (list) => {
  if (list.every((mother) => mother.children?.every((first) => first.score === 0))) {
    return true;
  }

  if (list.every((mother) => mother.children?.every((first) => first.children?.every((second) => second.score === 0)))) {
    return true;
  }

  return false;
};

export default class HotWordAssocsModel {
  constructor(data, prevData, query, search) {
    const format = data.map((el) => convertFunction(el));
    const prevFormat = prevData.map((el) => convertFunction(el));

    const csv = [];
    format.forEach((el) => {
      el.children.forEach((c1) => {
        csv.push({
          word: el.name,
          first: c1.name,
          second: 'x',
          important: c1.score
        });
        c1.children.forEach((c2) => {
          csv.push({
            word: el.name,
            first: c1.name,
            second: c2.name,
            important: c2.score
          });
        });
      });
    });
    prevFormat.forEach((el) => {
      el.children.forEach((c1) => {
        csv.push({
          word: el.name,
          first: c1.name,
          second: 'x',
          important: c1.score
        });
        c1.children.forEach((c2) => {
          csv.push({
            word: el.name,
            first: c1.name,
            second: c2.name,
            important: c2.score
          });
        });
      });
    });

    const csvKey = [
      { key: 'word', header: '母詞' },
      { key: 'first', header: '下探一階詞' },
      { key: 'second', header: '下探二階詞' },
      { key: 'important', header: '熱詞重要度' }
    ];

    const {
      womType,
      postType,
      category,
      tagKey
    } = query;

    const linkData = {
      ...(womType && {
        womType
      }),
      ...(postType && {
        postType
      }),
      ...(category ? {
        category: category.map((el) => ({
          id: el,
          name: SOURCE_CATEGORY_TYPE[el]
        }))
      } : {
        category: SOURCE_CATEGORY.map((el) => ({
          id: el.value,
          name: el.label
        }))
      }),
      packageContent: {
        id: packageStore.activePackageId,
        name: packageStore.activePackageName
      },
      series: [{
        id: search.id,
        name: search.name,
        tagKey,
        tagKeyName: packageStore.series.find((el) => el.id === tagKey).name
      }],
      chart: {
        name: '圖表分析/品牌/關鍵字分析/系列熱詞下探'
      }
    };


    this.data = {
      chartData: [{
        id: 'industry_keyword_hot_assocs',
        name: '行業熱詞文字雲',
        data: [
          ...(
            format.length > 0 && !isAllZero(format)
              ? [format]
              : [{
                type: 'nodata',
                subType: format.length === 0 ? 'ana-error' : 'default'
              }]
          ),
          ...(
            prevFormat.length > 0 && !isAllZero(prevFormat)
              ? [prevFormat]
              : [
                ...(
                  infoHeaderViewModel.multiViewModel.hasCompare
                    ? [{
                      type: 'nodata',
                      subType: prevFormat.length === 0 ? 'ana-error' : 'default'
                    }]
                    : []
                )
              ]
          )
        ],
        type: 'word-assocs',
        csv,
        csvKey,
        linkData
      }]
    };
  }

  static fromRes(data, prevData, query, search) {
    return new HotWordAssocsModel(data, prevData, query, search);
  }
}
