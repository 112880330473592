import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import { CloseOutlined, CheckOutlined, CaretDownOutlined, StopOutlined, DownloadOutlined, SaveOutlined, ReloadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Table, Modal, Tooltip } from 'antd';
import Select from 'src/components/select';

import { POST_TYPE, WORD_OF_MOUTH_TYPE, WORD_COUNT_TYPE, RELATED_CHART_TYPE } from 'src/consts';

import PaperIcon from 'src/assets/PaperIcon.svg';
import withRouter from 'src/components/withRouter';
import packageStore from 'src/stores/packageStore';

import CategoryPopoverSelect from 'src/components/CategoryPopoverSelect';

import InfoHeader from 'src/components/InfoHeader';
import WordsChart from './components/WordsChart';

import RelatedAnalysisPageViewModel from './viewModel';

import styles from './styles.module.scss';

@observer
class RelatedAnalysisPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new RelatedAnalysisPageViewModel();
  }

  componentDidMount() {
    this.viewModel.setReaction();
    this.viewModel.updateLevel(this.props.router);
  }

  componentDidUpdate() {
    this.viewModel.updateLevel(this.props.router);
  }

  componentWillUnmount() {
    this.viewModel.once?.();
  }

  render() {
    return (
      <>
        <div className={styles.pageContainer}>
          <InfoHeader
            breadcrumb={['關聯分析', this.viewModel.level ? i18n.t(`common_${this.viewModel.level}`) : '階層']}
            isMultiPeriod
            callback={this.viewModel.afterDateUpdate}
          />
          <div className={styles.pageHeader}>
            <div className={styles.pageTitle}>
              關聯分析圖表
            </div>
            <div className={styles.statisticFilter}>
              <div className={styles.filterTitle}>
                {i18n.t('chart_page_statistic_filter')}
              </div>
              <Select
                placeholder={i18n.t('filter_post_type_all')}
                options={POST_TYPE}
                value={this.viewModel.selectedPostType}
                onSelect={this.viewModel.onPostTypeChange}
                popupMatchSelectWidth={false}
                popupClassName={styles.sortMenu}
                className={clsx('sortSelect', styles.filterSelect)}
                suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
              />
              <Select
                placeholder={i18n.t('filter_word_of_mouth_title')}
                options={WORD_OF_MOUTH_TYPE}
                value={this.viewModel.selectedWOMType}
                onSelect={this.viewModel.onWOMTypeChange}
                popupMatchSelectWidth={false}
                popupClassName={styles.sortMenu}
                className={clsx('sortSelect', styles.filterSelect)}
                suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                disabled
              />
              <CategoryPopoverSelect
                viewModel={this.viewModel.categoryPopover}
                title="來源類型"
              />
              <Button
                className={clsx(styles.btn, styles.submitBtn)}
                icon={<CheckOutlined />}
                onClick={this.viewModel.onSubmitChartFilter}
              >
                {i18n.t('btn_filter_submit')}
              </Button>
              {
                this.viewModel.isShowStatisticClean
                && (
                  <Button
                    className={clsx(styles.btn, styles.cleanBtn)}
                    icon={<CloseOutlined />}
                    type="link"
                    onClick={this.viewModel.onCleanStatisticFilter}
                  >
                    {i18n.t('btn_filter_clean_all')}
                  </Button>
                )
              }
            </div>
            {
              this.viewModel.level === 'series'
              && (
                <div className={styles.statisticFilter}>
                  <div className={styles.filterTitle}>
                    選擇系列群組
                  </div>
                  <Select
                    placeholder={i18n.t('package_modal_series_select')}
                    options={packageStore.series.map((el) => ({
                      value: el.id,
                      label: el.name
                    }))}
                    value={this.viewModel.selectedTagKey}
                    onSelect={this.viewModel.onSeriesParentSelect}
                    popupMatchSelectWidth={false}
                    popupClassName={styles.sortMenu}
                    className={clsx('sortSelect', styles.filterSelect)}
                    suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                  />
                </div>
              )
            }
          </div>
          <div className={styles.resContainer}>
            <div className={styles.typeSegment}>
              {
                RELATED_CHART_TYPE.map((el) => {
                  return (
                    <div
                      key={el.id}
                      className={clsx(styles.segmentItem, this.viewModel.selectedType === el.id && styles.active)}
                      onClick={() => this.viewModel.onTypeChange(el.id)}
                    >
                      {el.name}
                    </div>
                  );
                })
              }
            </div>
            <div className={styles.chartContainer}>
              <div className={clsx(styles.actionsContainer, this.viewModel.selectedType === 'words' && styles.border)}>
                {/* preserve for phase2 */}
                {
                  this.viewModel.selectedType === 'words'
                    ? (
                      <div className={styles.chartFilter}>
                        <div className={styles.filterTitle}>
                          {i18n.t('chart_page_graphic_filter')}
                        </div>
                        {/* <div className={styles.filterItem}>
                          <div className={styles.itemTitle}>
                            {i18n.t('chart_page_index_title')}
                          </div>
                          <Select
                            options={WORD_INDEX_TYPE}
                            value={this.viewModel.selectedIndex}
                            onSelect={this.viewModel.onChartIndexChange}
                            popupMatchSelectWidth={false}
                            popupClassName={styles.sortMenu}
                            className={clsx('sortSelect', styles.filterSelect)}
                            suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                          />
                        </div> */}
                        <div className={styles.filterItem}>
                          <div className={styles.itemTitle}>
                            {i18n.t('chart_page_word_count_title')}
                          </div>
                          <Select
                            options={WORD_COUNT_TYPE}
                            value={this.viewModel.selectedCount}
                            onSelect={this.viewModel.onChartCountChange}
                            popupMatchSelectWidth={false}
                            popupClassName={styles.sortMenu}
                            className={clsx('sortSelect', styles.filterSelect)}
                            suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                          />
                        </div>
                        <Button
                          className={clsx(styles.btn, styles.submitBtn)}
                          icon={<CheckOutlined />}
                          onClick={this.viewModel.onSubmitChartFilter}
                        >
                          {i18n.t('btn_filter_submit')}
                        </Button>
                        {
                          this.viewModel.isShowChartClean
                          && (
                            <Button
                              className={clsx(styles.btn, styles.cleanBtn)}
                              icon={<CloseOutlined />}
                              type="link"
                              onClick={this.viewModel.onCleanChartFilter}
                            >
                              {i18n.t('btn_filter_clean_all')}
                            </Button>
                          )
                        }
                      </div>
                    )
                    : (
                      <div />
                    )
                }
                <div className={styles.btnContainer}>
                  {
                    this.viewModel.selectedType === 'words'
                    && (
                      <Tooltip
                        title="查看屏蔽名單"
                        placement="bottom"
                      >
                        <Button
                          icon={<StopOutlined />}
                          onClick={this.viewModel.onStopModalOpen}
                        />
                      </Tooltip>
                    )
                  }
                  <Tooltip
                    title="下載數據"
                    placement="bottom"
                  >
                    <Button
                      icon={<DownloadOutlined />}
                      onClick={this.viewModel.onDownloadExcel}
                    />
                  </Tooltip>
                  <Tooltip
                    title="下載圖表"
                    placement="bottom"
                  >
                    <Button
                      icon={<SaveOutlined />}
                      onClick={this.viewModel.onDownload}
                    />
                  </Tooltip>
                </div>
              </div>
              <div ref={this.viewModel.ref}>
                <WordsChart viewModel={this.viewModel.wordsChartViewModel} />
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={this.viewModel.isStopModalOpen}
          centered
          closable={false}
          footer={null}
          className={styles.blockModal}
          onCancel={this.viewModel.onStopModalClose}
          width={928}
        >
          <div className={styles.modalHeader}>
            <div className={styles.modalTitle}>
              <img src={PaperIcon} alt="paper" className={styles.titleIcon} />
              <div className={styles.modalText}>
                {i18n.t('wordbook_page_block_modal_title')}
              </div>
            </div>
            <Button
              icon={<CloseOutlined />}
              type="link"
              onClick={this.viewModel.onStopModalClose}
            >
              關閉
            </Button>
          </div>
          <div className={styles.tableContainer}>
            <Table
              key={this.viewModel.blockWords.length}
              rowClassName={styles.row}
              rowKey={(item) => item.id}
              dataSource={this.viewModel.blockWords}
              pagination={false}
              className="packagesTable"
            >
              <Table.Column
                title={i18n.t('wordbook_page_table_name')}
                dataIndex="name"
                key="name"
                className={styles.columns}
                width="50%"
                render={(_, record) => {
                  return (
                    <div>
                      {record.name}
                    </div>
                  );
                }}
              />
              <Table.Column
                title={i18n.t('wordbook_page_table_action_title')}
                dataIndex="name"
                key="name"
                className={styles.columns}
                width="50%"
                render={(_, record) => {
                  return (
                    <Button
                      icon={<ReloadOutlined />}
                      type="text"
                      onClick={() => this.viewModel.onRemoveWord(record.id)}
                    >
                      {i18n.t('wordbook_page_table_action_recover')}
                    </Button>
                  );
                }}
              />
            </Table>
          </div>
        </Modal>
      </>
    );
  }
}

RelatedAnalysisPage.propTypes = {
  router: PropTypes.object

};

RelatedAnalysisPage.defaultProps = {
  router: {}
};


export default withRouter(RelatedAnalysisPage);
