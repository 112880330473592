import API from 'src/apis';
import dataAnalysis from 'src/models/response/charts/dataAnalysis';
import packageStore from 'src/stores/packageStore';

export default class BrandCompetitionService {

  static getPackageId = () => {
    return packageStore.activePackageId;
  };

  static async getBrandReputation(params) {
    const res = await API.dataReport.brandCompetition.getBrandReputation(BrandCompetitionService.getPackageId(), params);

    return dataAnalysis.BrandCompetition.BrandReputationModel.fromRes(res.data.result, params);
  }

  static async getBrandVolumeTrend(params) {
    const res = await API.dataReport.brandCompetition.getBrandVolumeTrend(BrandCompetitionService.getPackageId(), params);

    return dataAnalysis.BrandCompetition.BrandVolumeTrendModel.fromRes(res.data.result, params);
  }

  static async getBrandVolumeRanking(params) {
    const res = await API.dataReport.brandCompetition.getBrandVolumeCount(BrandCompetitionService.getPackageId(), params);

    return dataAnalysis.BrandCompetition.BrandVolumeRankingModel.fromRes(res.data.result, params);
  }

  static async getBrandVolumeProportion(params) {
    const res = await API.dataReport.brandCompetition.getBrandVolumeCount(BrandCompetitionService.getPackageId(), params);

    return dataAnalysis.BrandCompetition.BrandVolumeProportionModel.fromRes(res.data.result, params);
  }

  static async getBrandSeriesVolumeCompare(params) {
    const res = await API.dataReport.brandCompetition.getBrandSeriesVolumeCompare(BrandCompetitionService.getPackageId(), params);

    return dataAnalysis.BrandCompetition.BrandSeriesVolumeCompareModel.fromRes(res.data.result, params);
  }

  static async getBrandProductVolumeCompare(params) {
    const res = await API.dataReport.brandCompetition.getBrandProductVolumeCompare(BrandCompetitionService.getPackageId(), params);

    return dataAnalysis.BrandCompetition.BrandProductVolumeCompareModel.fromRes(res.data.result, params);
  }

  static async getProductVolumeRanking(params) {
    const res = await API.dataReport.brandCompetition.getProductVolumeRanking(BrandCompetitionService.getPackageId(), params);

    return dataAnalysis.BrandCompetition.ProductVolumeRankingModel.fromRes(res.data.result, params);
  }

  static async getBrandSourceCategoryCompare(params) {
    const res = await API.dataReport.brandCompetition.getBrandSourceCategoryCompare(BrandCompetitionService.getPackageId(), params);

    return dataAnalysis.BrandCompetition.BrandSourceCategoryCompareModel.fromRes(res.data.result, params);
  }

  static async getBrandSourceChannelCompare(params) {
    const res = await API.dataReport.brandCompetition.getBrandSourceChannelCompare(BrandCompetitionService.getPackageId(), params);

    return dataAnalysis.BrandCompetition.BrandSourceChannelCompareModel.fromRes(res.data.result, params);
  }

  static async getBrandSentimentOverview(params) {
    const res = await API.dataReport.brandCompetition.getBrandSentimentOverview(BrandCompetitionService.getPackageId(), params);

    return dataAnalysis.BrandCompetition.BrandSentimentOverviewModel.fromRes(res.data.result, params);
  }

  static async getBrandSentimentWords(params) {
    const res = await API.dataReport.brandCompetition.getBrandSentimentWords(BrandCompetitionService.getPackageId(), params);

    return dataAnalysis.BrandCompetition.BrandPNWordModel.fromRes(res.data.result, params);
  }

  static async getBrandConsumerFeature(params) {
    const res = await API.dataReport.brandCompetition.getBrandConsumerFeature(BrandCompetitionService.getPackageId(), params);

    return dataAnalysis.BrandCompetition.BrandConsumerFeatureModel.fromRes(res.data.result, params);
  }

  static async getBrandWords(params) {
    const res = await API.dataReport.brandCompetition.getBrandWords(BrandCompetitionService.getPackageId(), params);

    return dataAnalysis.BrandCompetition.BrandWordModel.fromRes(res.data.result, params);
  }
}
