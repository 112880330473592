export default class TopicDetailModel {
  constructor(data) {
    const { post, comment, sentiment } = data;

    const {
      url,
      author,
      website,
      channel,
      hit: {
        level1,
        tag,
        level3
      },
      commonHit: {
        level1: commonLevel1,
        tag: commonTag,
        level3: commonLevel3
      },
      title,
      content,
      sentiment: postSentiment,
      replyId,
      date,
      viewCount,
      shareCount,
      commentCount,
      womCount
    } = post;

    this.post = {
      url,
      hitPostType: replyId ? `回文${replyId < 99999 ? replyId : ''}` : '主文',
      author,
      website,
      channel,
      hitBrand: level1.id ? level1 : { id: null, name: '無' },
      hitSeries: tag.id ? tag : { id: null, name: '無' },
      hitProduct: level3.id ? level3 : { id: null, name: '無' },
      commonHitBrand: commonLevel1.length >= 1 ? commonLevel1.join(', ') : '無',
      commonHitSeries: commonTag.length >= 1 ? commonTag.join(', ') : '無',
      commonHitProduct: commonLevel3.length >= 1 ? commonLevel3.join(', ') : '無',
      title,
      content,
      sentiment: postSentiment,
      date,
      viewCount,
      shareCount,
      commentCount,
      womCount
    };

    this.comment = comment.map((el, i) => ({
      order: i + 1,
      vocKey: el.vocKey,
      replyId: el.replyId < 99999 ? el.replyId : '---',
      author: el.author,
      date: el.date,
      sentiment: el.sentiment,
      content: el.content,
      commonHit: {
        level1: (el.commonHit.level1.length >= 1 && el.commonHit.level1[0].trim()) ? el.commonHit.level1.join(', ') : '無',
        tag: (el.commonHit.tag.length >= 1 && el.commonHit.tag[0].trim()) ? el.commonHit.tag.join(', ') : '無',
        level3: (el.commonHit.level3.length && el.commonHit.level3[0].trim()) >= 1 ? el.commonHit.level3.join(', ') : '無'
      }
    }));

    const positive = sentiment['1'] ?? 0;
    const neutral = sentiment['0'] ?? 0;
    const negative = sentiment['-1'] ?? 0;

    this.commentStatistic = {
      total: positive + neutral + negative,
      positive,
      neutral,
      negative
    };

  }

  static fromRes(data) {
    return new TopicDetailModel(data);
  }
}
