import optionsStore from 'src/stores/optionsStore';
import { request, getHost, getHeaders } from '../utils';

export const volume = {
  getIndustryVolumeTrend: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/industry/volume/trend`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getIndustryVolumeProportion: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/industry/volume/count`,
      headers: getHeaders(),
      data
    };
    return request(options);
  }
};

export const source = {
  category: {
    getIndustrySourceCategoryTrend: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/industry/source/category/trend`,
        headers: getHeaders(),
        data
      };
      return request(options);
    },
    getIndustrySourceCategoryCount: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/industry/source/category/count`,
        headers: getHeaders(),
        data
      };
      return request(options);
    }
  },
  website: {
    getIndustrySourceWebsiteTrend: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/industry/source/website/trend`,
        headers: getHeaders(),
        data
      };
      return request(options);
    },
    getIndustrySourceWebsiteCount: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/industry/source/website/count`,
        headers: getHeaders(),
        data
      };
      return request(options);
    }
  },
  channel: {
    getIndustrySourceChannelTrend: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/industry/source/channel/trend`,
        headers: getHeaders(),
        data
      };
      return request(options);
    },
    getIndustrySourceChannelCount: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/industry/source/channel/count`,
        headers: getHeaders(),
        data
      };
      return request(options);
    }
  },
  getIndustrySocialActivity: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/industry/source/activity`,
      headers: getHeaders(),
      data
    };
    return request(options);
  }
};

export const sentiment = {
  getIndustrySentimentTrend: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/industry/sentiment/trend`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getIndustrySentimentCount: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/industry/sentiment/count`,
      headers: getHeaders(),
      data
    };
    return request(options);
  }
};

export const keyword = {
  getIndustryHotWord: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/industry/term/hot`,
      headers: getHeaders(),
      // cancelToken: optionsStore.source.token,
      data
    };
    return request(options);
  },
  getIndustryHotWordDiscover: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/industry/term/discover`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getIndustryNewWord: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/industry/term/new`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getIndustryTrendWord: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/industry/term/trending`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getIndustryHotWordAssoc: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/industry/term/assoc`,
      headers: getHeaders(),
      data
    };
    return request(options);
  }
};


