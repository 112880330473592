import SeriesVolumeTrendModel from '../IndustryTrend/SeriesVolumeTrend';
import SeriesVolumeProportionModel from './SeriesVolumeProportion';
import SeriesBrandVolumeCompareModel from './SeriesBrandVolumeCompare';
import SeriesProductVolumeCompareModel from './SeriesProductVolumeCompare';
import SeriesSourceCategoryCompareModel from './SeriesSourceCategoryCompare';
import TopsSeriesBrandVolumeProportionModel from './TopsSeriesBrandVolumeProportion';
import TopsSeriesProductVolumeRankingModel from './TopsSeriesProductVolumeRanking';
import TopsSeriesWord from './TopsSeriesWord';

export default {
  SeriesVolumeTrendModel,
  SeriesVolumeProportionModel,
  SeriesBrandVolumeCompareModel,
  SeriesProductVolumeCompareModel,
  SeriesSourceCategoryCompareModel,
  TopsSeriesBrandVolumeProportionModel,
  TopsSeriesProductVolumeRankingModel,
  TopsSeriesWord
};
