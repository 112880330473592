import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Waypoint } from 'react-waypoint';

import i18n from 'src/i18n';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Modal, Checkbox, Radio } from 'antd';

import PaperIcon from 'src/assets/PaperIcon.svg';
import SearchIcon from 'src/assets/MenuIcons/SearchIcon.svg';

import CheckItem from './CheckItem';
import styles from './styles.module.scss';

@observer
class SingleSelectModal extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <Modal
        open={viewModel.isModalOpen}
        closable={false}
        centered
        onCancel={viewModel.onClose}
        className="selectModal"
        width={422}
        footer={null}
      >
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <div className={styles.headerTitle}>
              <img src={PaperIcon} alt="paper" className={styles.titleIcon} />
              <div className={styles.titleText}>
                {i18n.t('package_modal_title')}
              </div>
            </div>
            <div className={styles.headerClose}>
              <Button
                icon={<CloseOutlined />}
                type="link"
                className={styles.headerClose}
                onClick={viewModel.onClose}
              >
                {i18n.t('package_modal_close')}
              </Button>
            </div>
          </div>
          <div className={styles.modalContent}>
            <div className={styles.selectCard}>
              <div className={styles.cardHeader}>
                <div className={styles.cardTitle}>
                  {i18n.t('package_modal_series_select')}
                </div>
              </div>
              <div className={styles.cardContent}>
                <div className={styles.searchInputContainer}>
                  <input
                    className={styles.searchInput}
                    placeholder={i18n.t('package_modal_input_placeholder')}
                    value={viewModel.seriesKeywordCache}
                    onChange={viewModel.onSeriesKeywordChange}
                    onKeyDown={viewModel.onSeriesKeyDown}
                  />
                  <div
                    className={styles.searchBtn}
                    onClick={viewModel.onSeriesSearchSubmit}
                  >
                    <img src={SearchIcon} alt="" />
                  </div>
                </div>
                <div className={styles.levelCheckboxContainer}>
                  {
                    viewModel.searchSeries.map((el) => {
                      return (
                        <div
                          key={el.id}
                          className={styles.seriesGroup}
                        >
                          <div
                            className={styles.seriesTitle}
                            onClick={() => viewModel.onParentSelect(el.id)}
                          >
                            <div className={clsx(styles.selectItem, viewModel.selectedParent === el.id && styles.active)} />
                            <div className={styles.title}>
                              {el.name}
                            </div>
                          </div>
                          <div className={styles.childrenContainer}>
                            {
                              el.children.filter((c) => c.name.toLowerCase().includes(viewModel.seriesKeyword.toLowerCase())).map((c) => {
                                return (
                                  <CheckItem key={c.id} viewModel={c} disabled={viewModel.selectedParent !== el.id} />
                                );
                              })
                            }
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            </div>
          </div>
          <div className={styles.modalFooter}>
            <div className={styles.btnContainer}>
              <Button
                className={clsx(styles.btn, styles.cleanBtn)}
                icon={<CloseOutlined />}
                onClick={viewModel.onCleanAll}
              >
                {i18n.t('package_modal_clean')}
              </Button>
              <Button
                className={clsx(styles.btn, !viewModel.disabledConfirm && styles.applyBtn)}
                icon={<CheckOutlined />}
                type="primary"
                onClick={viewModel.onSubmit}
                disabled={viewModel.disabledConfirm}
              >
                {i18n.t('package_modal_apply')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

SingleSelectModal.propTypes = {
  viewModel: PropTypes.object
};

SingleSelectModal.defaultProps = {
  viewModel: {}
};

export default SingleSelectModal;
