import { COLOR_SET } from 'src/consts/chart';
import { CONDITION_KEY_TC } from 'src/consts';
import { generateCompletedDayArray, getTotalDays, generateCSVKey } from 'src/utils/chart';
import packageStore from 'src/stores/packageStore';
import { getHost } from 'src/utils';
import { encodeURI } from 'js-base64';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';

export default class AdvSearchTrendModel {
  constructor(origin, prevOrigin, query) {
    const data = (() => {
      const totalDays = getTotalDays();
      return origin.map((ori) => {
        if (totalDays >= 32 && totalDays <= 90) {
          const dayArr = generateCompletedDayArray();
          ori.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...ori,
            series: dayArr.map((el) => ({
              date: el.id,
              count: el.count
            }))
          };
        }

        if (totalDays >= 91) {
          const dayArr = generateCompletedDayArray();
          ori.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...ori,
            series: dayArr.map((el) => ({
              date: el.id,
              accurate: `${el.days[0]}~${el.days[el.days.length - 1]}`,
              count: el.count
            }))
          };
        }

        const dayArr = generateCompletedDayArray();
        return {
          ...ori,
          series: dayArr.map((d) => ({
            date: d,
            count: ori.series.find((s) => s.date === d)?.count ?? 0
          }))
        };
      });
    })();

    const prevData = (() => {
      const totalDays = getTotalDays('previous');
      return prevOrigin.map((ori) => {
        if (totalDays >= 32 && totalDays <= 90) {
          const dayArr = generateCompletedDayArray('previous');
          ori.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...ori,
            series: dayArr.map((el) => ({
              date: el.id,
              count: el.count
            }))
          };
        }

        if (totalDays >= 91) {
          const dayArr = generateCompletedDayArray('previous');
          ori.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...ori,
            series: dayArr.map((el) => ({
              date: el.id,
              accurate: `${el.days[0]}~${el.days[el.days.length - 1]}`,
              count: el.count
            }))
          };
        }

        const dayArr = generateCompletedDayArray('previous');
        return {
          ...ori,
          series: dayArr.map((d) => ({
            date: d,
            count: ori.series.find((s) => s.date === d)?.count ?? 0
          }))
        };
      });
    })();

    const formatData = data.slice(0, 20).map((el) => ({
      id: `${el.condition1}-${el.condition2}`,
      condition1: el.condition1,
      condition2: el.condition2,
      name: el.name,
      series: el.series
    }));

    const prevFormatData = prevData.slice(0, 20).map((el) => ({
      id: `${el.condition1}-${el.condition2}`,
      condition1: el.condition1,
      condition2: el.condition2,
      name: el.name,
      series: el.series
    }));

    const colors = {};
    formatData.forEach((el) => {
      const target = colors[el.id];
      if (!target) {
        colors[`${el.id}`] = COLOR_SET[Object.keys(colors).length % 20];
      }
    });

    prevFormatData.forEach((el) => {
      const target = colors[el.id];
      if (!target) {
        colors[`${el.id}`] = COLOR_SET[Object.keys(colors).length % 20];
      }
    });

    const {
      womType,
      postType,
      condition1,
      condition2,
      tagKeyName
    } = query;

    const condition1Key = condition1.key;
    const condition2Key = condition2.key;

    const csv = data.map((el) => {
      const [c1Name, c2Name] = el.name.split('-');
      const row = {
        [`交叉條件一${condition1Key}`]: c1Name,
        [`交叉條件二${condition2Key}`]: c2Name
      };

      el.series.forEach((series) => {
        row[`${series.date}`] = series.count;
      });

      return row;
    });

    const prevCsv = prevData.map((el) => {
      const [c1Name, c2Name] = el.name.split('-');
      const row = {
        [`交叉條件一${condition1Key}`]: c1Name,
        [`交叉條件二${condition2Key}`]: c2Name
      };

      el.series.forEach((series) => {
        row[`${series.date}`] = series.count;
      });

      return row;
    });

    csv.push(...prevCsv);

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'adv_search_volume_trend',
          name: '交叉分析聲量趨勢',
          data: [
            ...(
              formatData.length > 0 && !formatData.every((el) => el.series.every((date) => date.count === 0))
                ? [{
                  labels: formatData[0].series.map((el) => el.date),
                  datasets: formatData.map((el, i) => ({
                    label: el.name,
                    condition1: el.condition1,
                    condition2: el.condition2,
                    data: el.series.map((s) => s.count),
                    borderColor: colors[el.id]
                  }))
                }]
                : [{ type: 'nodata', subType: formatData.length === 0 ? 'ana-error' : 'default' }]
            ),
            ...(
              prevFormatData.length > 0 && !prevFormatData.every((el) => el.series.every((date) => date.count === 0))
                ? [{
                  labels: prevFormatData[0].series.map((el) => el.date),
                  datasets: prevFormatData.map((el, i) => ({
                    label: el.name,
                    condition1: el.condition1,
                    condition2: el.condition2,
                    data: el.series.map((s) => s.count),
                    borderColor: colors[el.id]
                  }))
                }]
                : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{ type: 'nodata', subType: prevFormatData.length === 0 ? 'ana-error' : 'default' }]
                      : []
                  )
                ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const c1 = chart.data.datasets[datasetIndex].condition1;
              const c2 = chart.data.datasets[datasetIndex].condition2;
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const [c1Name, c2Name] = chart.data.datasets[datasetIndex].label.split('-');
              const gte = chart.data.labels[index];

              const topicsResult = {
                [`${condition1Key}`]: [{
                  id: c1,
                  ...(condition1.value.tagKey && {
                    tagKey: condition1.value.tagKey,
                    tagKeyName
                  }),
                  name: c1Name.trim()
                }],
                [`${condition2Key}`]: [{
                  id: c2,
                  ...(condition2.value.tagKey && {
                    tagKey: condition2.value.tagKey,
                    tagKeyName
                  }),
                  name: c2Name.trim()
                }],
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: `交叉分析/『${CONDITION_KEY_TC[condition1Key]}』交叉『${CONDITION_KEY_TC[condition2Key]}』/趨勢圖`
                },
                womCount,
                date: {
                  gte,
                  lte: gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'line',
          options: {
            responsive: true,
            event: ['click'],
            tension: 0.3,
            scales: {
              x: {
                title: {
                  display: true,
                  text: '日期'
                }
              },
              y: {
                beginAtZero: true,
                grace: 5,
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ]
    };
  }
  static fromRes(data, prevData, query) {
    return new AdvSearchTrendModel(data, prevData, query);
  }
}
