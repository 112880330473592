import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import OpinionService from 'src/services/opinion';

export default class OpinionSingleTopicPageViewModel {
  @observable vocKey = '';
  @observable vocTitle = '';
  @observable hitPostType = '';
  @observable womCount = 0;
  @observable postUrl = '';
  @observable website = '';
  @observable channel = '';
  @observable author = '';
  @observable date = '';
  @observable viewCount = 0;
  @observable shareCount = 0;
  @observable commentCount = 0;
  @observable content = '';

  @observable page = 1;

  @observable comments = [];

  @computed get totalComments() {
    return this.comments.length;
  }

  constructor() {
    makeObservable(this);

    this.init();
  }

  @action init = async () => {
    const kid = window.location.pathname.split('/')[2];
    this.vocKey = kid;
    await this.getOpinion();
  };

  @action getOpinion = async () => {
    try {
      const { comment, post } = await OpinionService.getOpinion(this.vocKey);
      runInAction(() => {
        const {
          author,
          channel,
          commentCount,
          content,
          date,
          hitPostType,
          shareCount,
          title,
          url,
          viewCount,
          website,
          likeCount
        } = post;

        this.vocTitle = title;
        this.hitPostType = hitPostType;
        this.womCount = likeCount;
        this.postUrl = url;
        this.website = website;
        this.channel = channel;
        this.author = author;
        this.date = date;
        this.viewCount = viewCount;
        this.shareCount = shareCount;
        this.commentCount = commentCount;
        this.content = content;

        this.comments = comment;
      });
    } catch (error) {
      console.log(error);
    }
  };
}
