import { userVocIdentity } from 'src/consts';

export default class SwitchUserListModel {
  constructor(data) {
    const list = data.users.map((el) => ({
      id: el.id,
      name: el.name,
      account: el.email,
      teamName: el.teamName,
      vocPermission: userVocIdentity.find((identity) => identity.value === el.vocPermission).label
    }));

    this.userList = list;
  }

  static fromRes(data) {
    return new SwitchUserListModel(data);
  }
}
