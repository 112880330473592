import React from 'react';
import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { diff } from 'deep-diff';

import PackageService from 'src/services/package';
import AdvanceAnalysisService from 'src/services/advanceAnalysis';
import { PACKAGE_SORT_TYPE } from 'src/consts';
import { message } from 'antd';

export default class ManageSectionViewModel {
  @observable packages = [];
  @observable selectedPackages = [];

  @observable keyword = '';
  @observable keywordCache = '';

  @observable filterDate = [];
  @observable filterDateCache = [];
  @observable anchor = '';

  @observable isProcess = false;

  @observable sortedBy = PACKAGE_SORT_TYPE.LastChangeDateDesc;

  @observable deleteTarget = {};
  @observable isDeleteModalOpen = false;
  @observable deleteType = 'single';

  @observable reAnalysisTarget = {};
  @observable reAnalysisRange = [];
  @observable isReAnalysisModalOpen = false;
  @observable isReAnalysisDateModalOpen = false;
  @observable onStopJobs = [];

  @observable isNoteAdvAnalysisModalOpen = false;
  agreeRef = React.createRef();

  @computed get selectedPackageKeys() {
    return this.selectedPackages.map((el) => el.id);
  }

  constructor() {
    makeObservable(this);
  }

  @action init = async () => {
    this.packages = [];
    this.anchor = null;

    await this.getPackages();
  };

  @action getPackages = async () => {
    if (this.isProcess) {
      return;
    }
    this.isProcess = true;
    try {
      const params = {
        order: this.sortedBy,
        ...(this.filterDate?.[0] && { startDate: this.filterDate[0].startOf('day').toISOString() }),
        ...(this.filterDate?.[1] && { endDate: this.filterDate[1].endOf('day').toISOString() }),
        ...(this.keywordCache.trim && { keyword: this.keywordCache.trim() }),
        ...(this.anchor && { anchor: this.anchor })
      };

      const { packageList, anchor } = await PackageService.getPackages(params);

      runInAction(() => {
        this.packages.push(...packageList);
        this.anchor = anchor;
      });

    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.isProcess = false;
      });
    }
  };

  @action afterUpdate = async () => {
    this.packages = [];
    this.selectedPackages = [];

    this.keyword = '';
    this.keywordCache = '';

    this.filterDate = [];
    this.filterDateCache = [];

    this.anchor = null;
    this.sortedBy = PACKAGE_SORT_TYPE.LastChangeDateDesc;

    await this.getPackages();
  };

  // > table actions

  @action onSelect = (record, selected, selectedRows, nativeEvent) => {
    this.selectedPackages = [...selectedRows];
  };

  @action onSelectAll = (selected, selectedRows, changeRows) => {
    this.selectedPackages = [...selectedRows];
  };

  @action onDeleteSelected = async () => {
    this.deleteType = 'multi';

    this.onDeleteModalOpen();
  };

  @action processDeleteSelected = async () => {
    try {
      await Promise.all(this.selectedPackageKeys.map((el) => PackageService.deletePackage(el)));

      runInAction(() => {
        this.packages = [];
        this.anchor = null;
      });

      this.getPackages();
    } catch (error) {
      console.log(error);
    }
  };

  @action onClearSelected = () => {
    this.selectedPackages = [];
  };

  // > item actions.

  @action onEditPackages = async (id, openFunc, readOnlyOpenFunc) => {
    try {
      const { packageStatus, marketing7PStatus, mindSharePStatus, starProductStatus } = await PackageService.getAnalysisLog(id);

      if (
        (marketing7PStatus.jobId && !['DONE', 'CANCEL', 'FAIL'].includes(marketing7PStatus.stage))
        || (mindSharePStatus.jobId && !['DONE', 'CANCEL', 'FAIL'].includes(mindSharePStatus.stage))
        || (starProductStatus.jobId && !['DONE', 'CANCEL', 'FAIL'].includes(starProductStatus.stage))
      ) {
        runInAction(() => {
          this.isNoteAdvAnalysisModalOpen = true;
        });
        // await this.onUserSelect();
        // readOnlyOpenFunc(id, false);
        return;
      }

      if (
        (!packageStatus.jobId || ['DONE', 'CANCEL', 'FAIL'].includes(packageStatus.stage))
      ) {
        openFunc(id);
      } else {
        readOnlyOpenFunc(id, false);
      }
    } catch (error) {
      message.error('無法進行操作，請稍後再試！');
    }
  };

  @action onPinPackages = async (id) => {
    try {
      await PackageService.pinPackage(id);
      runInAction(() => {
        this.packages = [];
        this.anchor = null;
      });
      await this.getPackages();
    } catch (error) {
      console.log(error);
    }
  };

  @action onCheckProjectPackages = (id) => {
    const url = (() => {
      // local
      if (/:\d{4,}$/.test(window.location.origin)) {
        return 'https://buzz-pm-d90d217a.miraclemobile.com.tw/project/settings/';
      }

      // dev
      if (/d90d217a/.test(window.location.host)) {
        return 'https://buzz-pm-d90d217a.miraclemobile.com.tw/project/settings/';
      }

      // pro
      return 'https://pm.i-buzz-system.com/project/settings/';
    })();
    window.open(`${url}${id}`, '_blank');
  };

  @action onDeletePackages = (p) => {
    this.deleteTarget = p;

    this.deleteType = 'single';
    this.isDeleteModalOpen = true;
  };

  @action processDelete = async () => {
    try {
      await PackageService.deletePackage(this.deleteTarget.id);
      runInAction(() => {
        this.packages = [];
        this.anchor = null;
      });
      await this.getPackages();
    } catch (error) {
      console.log(error);
    }
  };

  @action onConfirmDelete = async () => {
    if (this.deleteType === 'single') {
      await this.processDelete();
    } else {
      await this.processDeleteSelected();
    }

    runInAction(() => {
      this.onDeleteModalClose();
    });
  };

  // > filter related
  // > search input event.

  @action onChange = (e) => {
    this.keyword = e.target.value;
  };

  @action onSubmit = async () => {

    this.anchor = null;
    this.packages = [];
    this.selectedPackages = [];
    this.keywordCache = this.keyword;

    await this.getPackages();
  };

  @action onKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onSubmit();
    }
  };

  // > filter date

  @action onDateChange = async (e) => {
    this.filterDate = e;
    if (!this.filterDate) {
      this.filterDate = [];
      await this.onPickerChange(false);
    }
  };

  @action onPickerChange = async (bool) => {
    if (!bool && diff(this.filterDateCache, this.filterDate)) {
      this.filterDateCache = [...this.filterDate];
      this.packages = [];
      this.anchor = null;
      await this.getPackages();
    }
  };

  // > pin sort
  @action onPinClick = async () => {
    if (this.sortedBy === PACKAGE_SORT_TYPE.PinDateDesc) {
      return;
    }

    this.sortedBy = PACKAGE_SORT_TYPE.PinDateDesc;

    this.packages = [];
    this.anchor = null;
    await this.getPackages();
  };

  // > table sort

  @action onSortChange = async (type) => {
    if (type === 'establishTime') {
      switch (this.sortedBy) {
        case PACKAGE_SORT_TYPE.CreateDateAsc:
          this.sortedBy = PACKAGE_SORT_TYPE.CreateDateDesc;
          break;
        case PACKAGE_SORT_TYPE.CreateDateDesc:
          this.sortedBy = PACKAGE_SORT_TYPE.PinDateDesc;
          break;
        default:
          this.sortedBy = PACKAGE_SORT_TYPE.CreateDateAsc;
          break;
      }
    }
    if (type === 'adjustTime') {
      switch (this.sortedBy) {
        case PACKAGE_SORT_TYPE.LastChangeDateAsc:
          this.sortedBy = PACKAGE_SORT_TYPE.LastChangeDateDesc;
          break;
        case PACKAGE_SORT_TYPE.LastChangeDateDesc:
          this.sortedBy = PACKAGE_SORT_TYPE.PinDateDesc;
          break;
        default:
          this.sortedBy = PACKAGE_SORT_TYPE.LastChangeDateAsc;
          break;
      }
    }

    this.packages = [];
    this.anchor = null;
    await this.getPackages();
  };

  // > modal related
  @action onDeleteModalOpen = () => {
    this.isDeleteModalOpen = true;
  };

  @action onDeleteModalClose = () => {
    this.isDeleteModalOpen = false;

    this.deleteTarget = {};
    this.selectedPackages = [];
  };

  // > re-analysis
  @action reAnalysis = async (id) => {
    try {
      const { packageStatus, projectStatus, marketing7PStatus, mindSharePStatus, starProductStatus } = await PackageService.getAnalysisLog(id);
      const packageInfo = await PackageService.getPackageInfo(id);
      runInAction(() => {
        this.reAnalysisTarget = {
          packageId: id,
          ...packageInfo
        };
      });
      if (
        !((!packageStatus.jobId || ['DONE', 'CANCEL', 'FAIL'].includes(packageStatus.stage))
          && (!projectStatus.jobId || projectStatus.stage === 'DONE'))
      ) {
        message.error('數據分析中，無法進行操作！');
        return;
      }

      if (
        (marketing7PStatus.jobId && !['DONE', 'CANCEL', 'FAIL'].includes(marketing7PStatus.stage))
        || (mindSharePStatus.jobId && !['DONE', 'CANCEL', 'FAIL'].includes(mindSharePStatus.stage))
        || (starProductStatus.jobId && !['DONE', 'CANCEL', 'FAIL'].includes(starProductStatus.stage))
      ) {
        runInAction(() => {
          if (marketing7PStatus.jobId) {
            this.onStopJobs.push(marketing7PStatus.jobId);
          }
          if (mindSharePStatus.jobId) {
            this.onStopJobs.push(mindSharePStatus.jobId);
          }
          if (starProductStatus.jobId) {
            this.onStopJobs.push(starProductStatus.jobId);
          }
        });

        this.isReAnalysisModalOpen = true;
        return;
      }

      runInAction(() => {
        this.isReAnalysisDateModalOpen = true;
      });

    } catch (error) {
      console.log(error);
    }
  };

  @action onStopAndReAnalysis = async () => {
    try {
      await Promise.all(this.onStopJobs.map((el) => AdvanceAnalysisService.stopAnalysis(el)));
      this.onReAnalysisModalClose();
      runInAction(() => {
        this.isReAnalysisDateModalOpen = true;
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action onReAnalysisModalClose = () => {
    this.isReAnalysisModalOpen = false;
  };

  @action onReAnalysisDateModalClose = () => {
    this.isReAnalysisDateModalOpen = false;
  };

  @action onReAnalysisDateSelect = (e) => {
    this.reAnalysisRange = e;
  };

  @action onReAnalysis = async () => {
    try {
      await PackageService.onReAnalysis(
        this.reAnalysisTarget.packageId,
        {
          gte: this.reAnalysisRange[0].format('YYYY-MM-DD'),
          lte: this.reAnalysisRange[1].format('YYYY-MM-DD')
        }
      );
      message.success('數據包將會進行重新分析！');
      runInAction(() => {
        this.packages = [];
        this.anchor = null;
      });
      await this.getPackages();
    } catch (error) {
      message.error('重新分析失敗，請稍後再試');
    } finally {
      runInAction(() => {
        this.onReAnalysisDateModalClose();
      });
    }
  };

  // @action onUserSelect = () => {
  //   return new Promise((res, rej) => {
  //     console.log('this.agreeRef.current', this.agreeRef.current);
  //     this.agreeRef.current.addEventListener('click', () => this.removeFunction(res));
  //   });
  // };

  // removeFunction = (res) => {
  //   res();
  //   this.onNoteAdvAnalysisModalClose();
  //   this.agreeRef.current.removeEventListener('click', this.removeFunction);
  // };

  @action onNoteAdvAnalysisModalClose = () => {
    this.isNoteAdvAnalysisModalOpen = false;
  };

}
