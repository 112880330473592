import API from 'src/apis';
import packageStore from 'src/stores/packageStore';

import ChartsModel from 'src/models/response/charts';
import Marketing7PSettingModel from 'src/models/response/Marketing7pSetting';
import MindShareSettingModel from 'src/models/response/MindShareSetting';
import StarProductSettingModel from 'src/models/response/StarProductSetting';

export default class AdvanceAnalysisService {

  static getPackageId() {
    return packageStore.activePackageId;
  }

  // > Setting part

  static async getMarketing7PSetting() {
    const res = await API.advanceAnalysis.getMarketing7PSetting(AdvanceAnalysisService.getPackageId());

    return Marketing7PSettingModel.fromRes(res.data.result);
  }

  static async postMarketing7PSetting(data) {
    const res = await API.advanceAnalysis.postMarketing7PSetting(AdvanceAnalysisService.getPackageId(), data);

    return res.data.jobId;
  }

  static async getMindShareSetting() {
    const res = await API.advanceAnalysis.getMindShareSetting(AdvanceAnalysisService.getPackageId());

    return MindShareSettingModel.fromRes(res.data.result);
  }

  static async postMindShareSetting(data) {
    const res = await API.advanceAnalysis.postMindShareSetting(AdvanceAnalysisService.getPackageId(), data);

    return res.data.jobId;
  }

  static async getStarProductSetting() {
    const res = await API.advanceAnalysis.getStarProductSetting(AdvanceAnalysisService.getPackageId());

    return StarProductSettingModel.fromRes(res.data.result);
  }

  static async postStarProductSetting(data) {
    const res = await API.advanceAnalysis.postStarProductSetting(AdvanceAnalysisService.getPackageId(), data);

    return res.data.jobId;
  }

  static async stopAnalysis(jid) {
    await API.advanceAnalysis.stopAnalysis(AdvanceAnalysisService.getPackageId(), jid);
  }

  // > Chart part

  static async getMarketing7pCount(data) {
    const res = await API.advanceAnalysis.getMarket7pCount(AdvanceAnalysisService.getPackageId(), data);

    return ChartsModel.AdvanceAnalysis.Radar7PModel.fromRes(res.data.result, data);
  }

  static async getMarket7pWord(data) {
    const res = await API.advanceAnalysis.getMarket7pWord(AdvanceAnalysisService.getPackageId(), data);

    return ChartsModel.AdvanceAnalysis.WordCloud7PModel.fromRes(res.data.result);
  }

  static async getMindShare(params, query) {
    const res = await API.advanceAnalysis.getMindShare(AdvanceAnalysisService.getPackageId(), params);

    return ChartsModel.AdvanceAnalysis.MindShareModel.fromRes(res.data.result, query, params);
  }

  static async getStarProduct(params, query) {
    const res = await API.advanceAnalysis.getStarProduct(AdvanceAnalysisService.getPackageId(), params);

    return ChartsModel.AdvanceAnalysis.StarProductModel.fromRes(res.data.result, query, params);
  }

  static async getBrandReputation(data) {
    const res = await API.advanceAnalysis.getBrandReputation(AdvanceAnalysisService.getPackageId(), data);

    return ChartsModel.AdvanceAnalysis.BrandReputationModel.fromRes(res.data.result, data);
  }

  static async getProductReputation(data) {
    const res = await API.advanceAnalysis.getProductReputation(AdvanceAnalysisService.getPackageId(), data);

    return ChartsModel.AdvanceAnalysis.ProductReputationModel.fromRes(res.data.result, data);
  }
}
