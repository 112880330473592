import { COLOR_SET } from 'src/consts/chart';
import { getHost } from 'src/utils';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { generateCSVKey } from 'src/utils/chart';

export default class IndustrySourceChannelRankingModel {
  constructor(data, prevData, query) {
    if (data.length === 0 && prevData.length === 0) {
      this.data = {
        chartData: [{
          id: 'industry_source_channel_ranking',
          name: '行業來源頻道排行',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }]
        }]
      };
      return;
    }

    const combine = data.map((el) => ({
      ...el,
      prevCount: 0
    }));

    prevData.forEach((el) => {
      const target = combine.find((current) => current.id === el.id);
      if (target) {
        target.prevCount = el.count;
      }
      // else {
      //   combine.push({
      //     id: el.id,
      //     name: el.name,
      //     count: 0,
      //     prevCount: el.count
      //   });
      // }
    });

    combine.sort((a, b) => b.count - a.count);

    if (combine.every((el) => el.count === 0) && combine.every((el) => el.prevCount === 0)) {
      this.data = {
        chartData: [{
          id: 'industry_source_channel_ranking',
          name: '行業來源頻道排行',
          data: [{
            type: 'nodata'
          }]
        }]
      };
      return;
    }

    const { currentDate, autoPrevious } = infoHeaderViewModel.multiViewModel;

    const {
      postType,
      womType
    } = query;

    const prototype = [
      {
        date: `${currentDate[0].format('YYYY-MM-DD')} ~ ${currentDate[1].format('YYYY-MM-DD')}`
      },
      {
        date: `${autoPrevious[0].format('YYYY-MM-DD')} ~ ${autoPrevious[1].format('YYYY-MM-DD')}`
      }
    ];

    const csv = prototype.map((el, di) => {
      const row = el;
      for (let i = 0; i < combine.length; i += 1) {
        if (di === 0) {
          row[`${combine[i].name}`] = combine[i].count;
        } else {
          row[`${combine[i].name}`] = combine[i].prevCount;
        }
      }

      return row;
    });

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'industry_source_channel_ranking',
          name: '行業來源頻道排行',
          data: [
            {
              labels: combine.slice(0, 20).map((el) => el.name),
              datasets: [
                {
                  label: `${currentDate[0].format('YYYY-MM-DD')} ~ ${currentDate[1].format('YYYY-MM-DD')}`,
                  data: combine.slice(0, 20).map((el) => el.count),
                  channelId: combine.slice(0, 20).map((el) => el.id),
                  backgroundColor: COLOR_SET[0],
                  borderSkipped: true,
                  maxBarThickness: 50
                },
                {
                  label: `${autoPrevious[0].format('YYYY-MM-DD')} ~ ${autoPrevious[1].format('YYYY-MM-DD')}`,
                  data: combine.slice(0, 20).map((el) => el.prevCount),
                  channelId: combine.slice(0, 20).map((el) => el.id),
                  backgroundColor: COLOR_SET[19],
                  borderSkipped: true,
                  maxBarThickness: 50
                }
              ]
            }
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const channelId = chart.data.datasets[datasetIndex].channelId[index];
              const channelName = chart.data.labels[index];
              const [gte, lte] = chart.data.datasets[datasetIndex].label.split('~');
              const womCount = chart.data.datasets[datasetIndex].data[index];

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                channel: [{
                  id: channelId,
                  name: channelName
                }],
                chart: {
                  name: '數據報告/行業趨勢觀察/行業來源頻道排行'
                },
                industry: true,
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'bar',
          options: {
            event: ['click'],
            scales: {
              y: {
                grace: 5,
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ]
    };
  }
  static fromRes(data, prevData, query) {
    return new IndustrySourceChannelRankingModel(data, prevData, query);
  }
}
