import { COLOR_SET } from 'src/consts/chart';
import { BRAND_MIND_SHARE_TABLE } from 'src/consts/tables';
import packageStore from 'src/stores/packageStore';
import { getHost } from 'src/utils';
import { encodeURI } from 'js-base64';
import { getTwo } from 'src/utils/chart';

export default class MindShareModel {
  constructor(data, query, params) {
    if (data.length === 0) {
      this.data = {
        chartData: [{
          id: 'mind_share',
          name: '品牌心佔率',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }],
          type: 'nodata'
        }]
      };
      return;
    }

    const supportAvg = (() => {
      let total = 0;

      data.forEach((el) => {
        total += (getTwo(el.supportRatio) * 100);
      });

      return total / data.length;
    })();

    const mentionAvg = (() => {
      let total = 0;

      data.forEach((el) => {
        total += (getTwo(el.mentionRatio) * 100);
      });

      return total / data.length;
    })();

    const avgLine = {
      id: 'avgLine',
      beforeDraw(chart, args, options) {
        const { ctx, chartArea: { top, right, bottom, left, width, height },
          scales: { x, y } } = chart;
        ctx.save();

        // > 擁護比平均線
        ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
        ctx.strokeRect(left, y.getPixelForValue(supportAvg), width, 0);
        ctx.restore();

        // > 提及比平均線
        ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
        ctx.strokeRect(x.getPixelForValue(mentionAvg), top, 0, height);
        ctx.restore();
      }
    };

    const csv = data.map((el) => ({
      name: el.name,
      mention: getTwo(el.mentionRatio) * 100,
      support: getTwo(el.supportRatio) * 100
    }));

    const csvKey = [
      { key: 'name', header: '品牌名稱' },
      { key: 'mention', header: '提及比' },
      { key: 'support', header: '擁護比' }

    ];

    this.data = {
      chartData: [
        {
          id: 'mind_share',
          name: '品牌心佔率',
          data: [
            {
              labels: '',
              datasets: data.map((el, i) => ({
                label: el.name,
                brand: { id: el.id, name: el.name },
                data: [{
                  x: getTwo(el.mentionRatio) * 100,
                  y: getTwo(el.supportRatio) * 100
                }],
                backgroundColor: COLOR_SET[i % 20],
                tooltip: {
                  callbacks: {
                    label: (context) => {
                      const { dataset, parsed } = context;
                      const { x, y } = parsed;
                      const { label } = dataset;
                      return `${label} - 提及比:${getTwo(x)}%, 擁護比:${getTwo(y)}% `;
                    }
                  }
                }
              }))
            }
          ],
          type: 'customize-scatter',
          onPointClick: (elements, chart, parent) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const brand = chart.data.datasets[datasetIndex].brand;
              const { x: mentionRatio, y: supportRatio } = chart.data.datasets[datasetIndex].data[index];
              const { start_date: gte, finish_date: lte } = params;
              const { womType } = query;

              const topicsResult = {
                ...(
                  womType !== 'all' && {
                    womType
                  }
                ),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '進階分析/品牌心佔率'
                },
                brand: [{
                  id: brand.id,
                  name: brand.name
                }],
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              parent.parent.onPointClick({ id: brand.id, name: brand.name }, {
                ...topicsResult,
                mindShare: {
                  mentionRatio
                }
              }, {
                ...topicsResult,
                mindShare: {
                  supportRatio
                },
                sentiment: [1]
              });
            }
          },
          options: {
            event: ['click'],
            scales: {
              x: {
                title: {
                  display: true,
                  text: '提及比'
                },
                grace: '15%',
                beginAtZero: true,
                ticks: {
                  callback: (v, i, t) => {
                    return `${v}%`;
                  }
                }
              },
              y: {
                title: {
                  display: true,
                  text: '擁護比'
                },
                grace: '15%',
                beginAtZero: true,
                ticks: {
                  callback: (v, i, t) => {
                    return `${v}%`;
                  }
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: 'auto',
                color: '#000',
                formatter: (value, context) => {
                  return context.chart.data.datasets[context.datasetIndex].label;
                },
                align: 'top'
              }
            }
          },
          csv,
          csvKey,
          plugins: [avgLine],
          table: {
            template: BRAND_MIND_SHARE_TABLE,
            data: data.slice().sort((a, b) => b.support - a.support).map((el, i) => ({
              ranking: i + 1,
              name: el.name,
              support: `${Math.round(el.supportRatio * 100)}%`,
              mention: `${Math.round(el.mentionRatio * 100)}%`
            }))
          }
        }
      ]
    };
    // ? ref: https://www.youtube.com/watch?v=glSJZEoKs1M
    // let rect;
    // const area = {
    //   id: 'area',
    //   events: ['mousemove'],
    //   beforeDatasetsDraw(chart, args, plugins) {
    //     if (rect) {

    //     }
    //   },
    //   afterEvent(chart, args) {
    //     console.log(args);
    //   }
    // };
  }

  static fromRes(data, query, params) {
    return new MindShareModel(data, query, params);
  }
}
