import { COLOR_SET } from 'src/consts/chart';
import packageStore from 'src/stores/packageStore';
import { getHost } from 'src/utils';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import { generateCSVKey, htmlLegendPlugin } from 'src/utils/chart';

export default class IndustryRadarModel {
  constructor(data, previousData, query) {
    const csv = [];

    if (data.length > 0) {
      const row = {
        名稱: '行業當期'
      };
      data.forEach((el) => {
        row[`${el.name}`] = el.count;
      });

      csv.push(row);
    }

    if (previousData.length > 0) {
      const row = {
        名稱: '行業前期'
      };
      previousData.forEach((el) => {
        row[`${el.name}`] = el.count;
      });

      csv.push(row);
    }

    const csvKey = generateCSVKey(csv[0]);

    for (let i = data.length; i < 5; i += 1) {
      data.push({
        id: '',
        name: '',
        count: 0
      });
    }
    for (let i = previousData.length; i < 5; i += 1) {
      previousData.push({
        id: '',
        name: '',
        count: 0
      });
    }


    this.data = {
      chartData: [
        {
          id: 'industry_word_radar',
          name: '行業雷達圖',
          data: [
            ...(
              !data.filter((el) => el.id).every((el) => el.count === 0)
                ? [{
                  labels: data.map((el) => el.name),
                  datasets: [
                    {
                      label: '行業',
                      timeSet: 'present',
                      feature: data.map((el) => ({ id: el.id, name: el.name })),
                      data: data.map((f) => f.count),
                      total: (() => {
                        let sum = 0;
                        data.forEach((f) => {
                          sum += f.count;
                        });
                        return sum;
                      })(),
                      borderColor: COLOR_SET[0],
                      tooltip: {
                        callbacks: {
                          label: (context) => {
                            const { formattedValue, dataset, label: featureName, raw } = context;
                            const { total: t, label } = dataset;
                            const percentage = t ? Math.round((raw / t) * 1000) / 10 : 0;
                            if (!featureName.trim()) {
                              return '';
                            }
                            return `${label} - ${featureName}: ${formattedValue}, 佔比: ${percentage}%`;
                          }
                        }
                      }
                    }
                  ]
                }]
                : [{
                  type: 'nodata',
                  subType: data.filter((el) => el.id).length > 0 ? 'default' : 'ana-error'
                }]
            ),
            ...(
              !previousData.filter((el) => el.id).every((el) => el.count === 0)
                ? [{
                  labels: previousData.map((el) => el.name),
                  datasets: [{
                    label: '行業',
                    timeSet: 'previous',
                    feature: previousData.map((el) => ({ id: el.id, name: el.name })),
                    data: previousData.map((f) => f.count),
                    total: (() => {
                      let sum = 0;
                      data.forEach((f) => {
                        sum += f.count;
                      });
                      return sum;
                    })(),
                    borderColor: COLOR_SET[0],
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const { formattedValue, dataset, label: featureName, raw } = context;
                          const { total: t, label } = dataset;
                          const percentage = t ? Math.round((raw / t) * 1000) / 10 : 0;
                          if (!featureName.trim()) {
                            return '';
                          }
                          return `${label} - ${featureName}: ${formattedValue}, 佔比: ${percentage}%`;
                        }
                      }
                    }
                  }]
                }]
                : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{ type: 'nodata', subType: previousData.filter((el) => el.id).length > 0 ? 'default' : 'ana-error' }]
                      : []
                  )
                ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType,
                category
              } = query;
              const { datasetIndex, index } = elements[0];
              const feature = chart.data.datasets[datasetIndex].feature[index];
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const [gte, lte] = chart.data.datasets[datasetIndex].timeSet === 'present'
                ? infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'))
                : infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD'));

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category ? {
                  category: category.map((el) => ({
                    id: el,
                    name: SOURCE_CATEGORY_TYPE[el]
                  }))
                } : {
                  category: SOURCE_CATEGORY.map((el) => ({
                    id: el.value,
                    name: el.label
                  }))
                }),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '分類詞庫/行業/雷達圖'
                },
                featureCategory: {
                  id: feature.id,
                  name: feature.name
                },
                industry: true,
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'radar',
          options: {
            responsive: true,
            event: ['click'],
            scales: {
              r: {
                angleLines: {
                  display: false
                },
                ticks: {
                  display: false
                }
              }
            },
            plugins: {
              legend: {
                display: false
              },
              datalabels: {
                display: false
              }
            }
          },
          plugins: [htmlLegendPlugin],
          csv,
          csvKey
        }
      ]
    };
  }

  static fromRes(data, p, query) {
    return new IndustryRadarModel(data, p, query);
  }
}
