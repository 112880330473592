import { makeObservable, observable, action, computed } from 'mobx';

export default class SearchTopicCardViewModel {
  @observable vocKey = '';
  @observable vocTitle = '';
  @observable website = '';
  @observable channel = '';
  @observable author = '';
  @observable date = '';
  @observable content = '';
  @observable viewCount = 0;
  @observable shareCount = 0;
  @observable commentCount = 0;
  @observable likeCount = 0;
  @observable replyId = 0;
  @observable url = '';

  @computed get href() {
    const origin = window.location.origin;
    return `${origin}/opinion/${this.vocKey}`;
  }

  constructor(data) {
    makeObservable(this);

    this.init(data);
  }

  @action init = (data) => {
    const {
      vocKey,
      vocTitle,
      website,
      channel,
      author,
      date,
      content,
      viewCount,
      shareCount,
      commentCount,
      likeCount,
      replyId,
      url
    } = data;

    this.vocKey = vocKey;
    this.vocTitle = vocTitle;
    this.website = website;
    this.channel = channel;
    this.author = author;
    this.date = date;
    this.content = content;
    this.viewCount = viewCount;
    this.shareCount = shareCount;
    this.commentCount = commentCount;
    this.likeCount = likeCount;
    this.replyId = replyId;
    this.url = url;
  };

  @action onClickTopicPage = () => {

  };
}
