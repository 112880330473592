export default class TopTenBrandModel {
  constructor(data) {
    this.data = data.slice(0, 10).map((el) => ({
      id: el.id,
      name: el.name
    }));
  }

  static fromRes(data) {
    return new TopTenBrandModel(data);
  }
}
