import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';

import { Layout } from 'antd';
import { Link } from 'react-router-dom';

import Logo from 'src/assets/Logo.png';

import styles from './styles.module.scss';

@observer
class SimpleHeader extends React.Component {
  render() {
    return (
      <Layout.Header className={clsx('header', styles.headerContainer)}>
        <Link to="/home" className={styles.logoContainer}>
          <img src={Logo} alt="" />
        </Link>
      </Layout.Header>
    );
  }
}

SimpleHeader.propTypes = {

};

SimpleHeader.defaultProps = {

};

export default SimpleHeader;
