import { CATEGORY_COLOR_SET } from 'src/consts/chart';
import { SOURCE_CATEGORY_TYPE, SOURCE_ORDER_TC } from 'src/consts';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { generateCSVKey } from 'src/utils/chart';

export default class BrandSourceCategoryCompareModel {
  constructor(origin, query) {
    if (origin.length === 0) {
      this.data = {
        chartData: [{
          id: 'brand_source_category_compare',
          name: '品牌來源類型堆疊',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }]
        }]
      };
      return;
    }

    const data = origin.map((el) => ({
      id: el.id,
      name: el.name,
      item: [
        {
          name: 'news',
          count: el.item.find((item) => item.name === 'news')?.count ?? 0
        },
        {
          name: 'forum',
          count: el.item.find((item) => item.name === 'forum')?.count ?? 0
        },
        {
          name: 'facebook',
          count: el.item.find((item) => item.name === 'facebook')?.count ?? 0
        },
        {
          name: 'ig',
          count: el.item.find((item) => item.name === 'ig')?.count ?? 0
        },
        {
          name: 'threads',
          count: el.item.find((item) => item.name === 'threads')?.count ?? 0
        },
        {
          name: 'video',
          count: el.item.find((item) => item.name === 'video')?.count ?? 0
        },
        {
          name: 'others',
          count: el.item.find((item) => item.name === 'others')?.count ?? 0
        }
      ]
    }));

    if (data.every((el) => el.item.every((item) => item.count === 0))) {
      this.data = {
        chartData: [{
          id: 'series_brand_volume_compare',
          name: '系列品牌聲量堆疊',
          data: [{
            type: 'nodata',
            subType: 'default'
          }]
        }]
      };
      return;
    }

    const {
      postType,
      womType
    } = query;

    const csv = data.map((el) => {
      const row = {
        品牌名稱: el.name
      };
      for (let i = 0; i < el.item.length; i += 1) {
        row[`${SOURCE_CATEGORY_TYPE[el.item[i].name]}`] = el.item[i].count;
      }
      return row;
    });

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'brand_source_category_compare',
          name: '品牌來源類型堆疊',
          data: [
            {
              labels: data.slice(0, 10).map((el) => el.name),
              datasets: data[0].item.map((el, i) => ({
                label: SOURCE_CATEGORY_TYPE[el.name],
                data: data.slice(0, 10).map((d) => d.item[i].count),
                brand: data.slice(0, 10).map((d) => ({ id: d.id, name: d.name })),
                category: el.name,
                backgroundColor: (context) => {
                  const chart = context.chart;
                  if (!chart.hovered) {
                    return CATEGORY_COLOR_SET[el.name];
                  }
                  return chart.hovered - 1 === context.datasetIndex ? CATEGORY_COLOR_SET[el.name] : '#a1a1a1';
                },
                borderSkipped: true,
                maxBarThickness: 50
              }))
            }
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const brand = chart.data.datasets[datasetIndex].brand[index];
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const category = chart.data.datasets[datasetIndex].category;
              const [gte, lte] = infoHeaderViewModel.multiViewModel.currentDate;

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                category: [{
                  id: category,
                  name: SOURCE_CATEGORY_TYPE[category]
                }],
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '數據報告/行業趨勢觀察/品牌來源類型堆疊'
                },
                brand: [{
                  id: brand.id,
                  name: brand.name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          onPointHover: (elements, ref) => {
            const chart = ref.current;
            if (elements.length > 0) {
              const datasetIndex = elements[0].datasetIndex;
              chart.hovered = datasetIndex + 1;
            } else {
              chart.hovered = null;
            }
            chart.update();
          },
          type: 'bar',
          options: {
            event: ['click'],
            scales: {
              x: {
                stacked: true
              },
              y: {
                stacked: true,
                grace: 5,
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom',
                labels: {
                  sort: (a, b) => {
                    const indexA = SOURCE_ORDER_TC.indexOf(a.text);
                    const indexB = SOURCE_ORDER_TC.indexOf(b.text);
                    return indexA - indexB;
                  }
                }
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ],
      brand: data.map((el) => ({ id: el.id, name: el.name }))
    };
  }

  static fromRes(data, query) {
    return new BrandSourceCategoryCompareModel(data, query);
  }
}
