import BrandVolumeTrendModel from './BrandVolumeTrend';
import BrandVolumeRankingModel from './BrandVolumeRanking';
import BrandVolumeProportionModel from './BrandVolumeProportion';
import IndustrySourceCategoryProportionModel from './IndustrySourceCategoryProportion';
import BrandSourceCategoryProportionModel from './BrandSourceCategoryProportion';
import IndustrySourceChannelRankingModel from './industrySourceChannelRanking';
import BrandSourceChannelRankingModel from './BrandSourceChannelRanking';
import SeriesVolumeProportionModel from './SeriesVolumeProportion';
import TopsSeriesProductVolumeRankingModel from './TopsSeriesProductVolumeRanking';
import BrandSentimentOverviewModel from './BrandSentimentOverview';
import BrandConsumerFeatureModel from './BrandConsumerFeature';
import IndustryTopsFeatureWordModel from './IndustryTopsFeatureWord';
import BrandTopsFeatureWordModel from './BrandTopsFeatureWord';

export default {
  BrandVolumeTrendModel,
  BrandVolumeRankingModel,
  BrandVolumeProportionModel,
  IndustrySourceCategoryProportionModel,
  BrandSourceCategoryProportionModel,
  IndustrySourceChannelRankingModel,
  BrandSourceChannelRankingModel,
  SeriesVolumeProportionModel,
  TopsSeriesProductVolumeRankingModel,
  BrandSentimentOverviewModel,
  BrandConsumerFeatureModel,
  IndustryTopsFeatureWordModel,
  BrandTopsFeatureWordModel
};
