import { v4 as uuidv4 } from 'uuid';

export default class ArticleModel {
  constructor(data) {
    this.articles = data.map((el) => ({
      id: uuidv4(),
      thumbnail: el.picture,
      date: el.date,
      content: el.title,
      url: el.url
    }));
  }

  static fromRes(data) {
    return new ArticleModel(data);
  }
}
