import * as industry from './industry';
import * as brand from './brand';
import * as series from './series';
import * as product from './product';

export default {
  industry,
  brand,
  series,
  product
};
