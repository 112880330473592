import API from 'src/apis';
import Auth from './auth';

export default class AuthenticateService {
  static async logout() {
    await API.auth.logout();

    Auth.removeToken();
  }

  static async checkMaintenance() {
    const res = await API.auth.checkMaintenance();

    return res.data.result;
  }
}
