import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';

import ScrollTopIcon from 'src/assets/scrollTopIcon.svg';
import ScrollTopIconHover from 'src/assets/scrollTopIconHover.svg';

import ScrollTopViewModel from './viewModel';

import styles from './styles.module.scss';

@observer
class ScrollTop extends React.Component {
  constructor() {
    super();

    this.viewModel = new ScrollTopViewModel();
  }
  render() {
    const { containerName } = this.props;
    return (
      <div
        className={styles.container}
        onClick={() => this.viewModel.onClick(containerName)}
        onMouseEnter={this.viewModel.onHover}
        onMouseLeave={this.viewModel.onLeave}
      >
        <img
          src={this.viewModel.isHover ? ScrollTopIconHover : ScrollTopIcon}
          alt=""
          className={styles.icon}
        />
      </div>
    );
  }
}

ScrollTop.propTypes = {
  containerName: PropTypes.string
};

ScrollTop.defaultProps = {
  containerName: null
};


export default ScrollTop;
