import ProductSourceCategoryTrendModel from './Trend';
import ProductSourceCategoryRankingModel from './Ranking';
import ProductSourceCategoryProportionModel from './Proportion';
import ProductSourceCategoryCompareModel from './Compare';

export default {
  ProductSourceCategoryTrendModel,
  ProductSourceCategoryRankingModel,
  ProductSourceCategoryProportionModel,
  ProductSourceCategoryCompareModel
};
