import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import Auth from 'src/services/auth';
import Cookies from 'js-cookie';
import { message } from 'antd';
import { INDUSTRY_TREND_TYPE, MARKETING_DIFFERENTIATION_TYPE, BRAND_COMPETITION_TYPE, BRAND_EFFECT_TYPE, BRAND_HEALTH_TYPE, BRAND_ROUTINE_TYPE } from 'src/consts/chart';

import UserService from 'src/services/user';
import packageStore from './packageStore';
import optionsStore from './optionsStore';

class UserStore {
  @observable userId = '';
  @observable userName = '';
  @observable userAccount = '';
  @observable userValidity = '';
  @observable userType = '';
  @observable userTeamId = '';

  @observable activeUserId = '';
  @observable activeUserName = '';
  @observable activeUserAccount = '';
  @observable activeUserValidity = '';
  @observable activeUserType = '';
  @observable activeUserTeamId = '';

  @observable search = false;
  @observable topic = false;
  @observable chart = false;
  @observable wordbook = false;
  @observable related = false;
  @observable advanceSearch = false;
  @observable notification = false;
  @observable dataReport = {
    industryTrend: false,
    marketDifferentiation: false,
    brandCompetition: false,
    brandHealth: false,
    brandEffect: false,
    brandRoutine: false
  };
  @observable advance = {
    marketing7P: false,
    mindShare: false,
    starProduction: false,
    reputation: false
  };

  @observable customerList = [];

  @computed get isIndustryTrendAvailable() {
    return this.dataReport.industryTrend && ['industry', 'brand', 'series', 'product'].some((item) => packageStore.opened.includes(item));
    // INDUSTRY_TREND_TYPE.filter((el) => el.rule.every((r) => packageStore.opened.includes(r))).length > 0;
  }

  @computed get isMarketDifferentiationAvailable() {
    return this.dataReport.marketDifferentiation && packageStore.opened.includes('series');
    // MARKETING_DIFFERENTIATION_TYPE.filter((el) => el.rule.every((r) => packageStore.opened.includes(r))).length > 0;
  }

  @computed get isBrandCompetitionAvailable() {
    return this.dataReport.brandCompetition && ['brand', 'product'].some((item) => packageStore.opened.includes(item));
    // BRAND_COMPETITION_TYPE.filter((el) => el.rule.every((r) => packageStore.opened.includes(r))).length > 0;
  }

  @computed get isBrandHealthAvailable() {
    return this.dataReport.brandHealth && packageStore.opened.includes('brand');
    // BRAND_HEALTH_TYPE.filter((el) => el.rule.every((r) => packageStore.opened.includes(r))).length > 0;
  }

  @computed get isBrandEffectAvailable() {
    return this.dataReport.brandEffect && ['industry', 'brand', 'product'].some((item) => packageStore.opened.includes(item));
    // BRAND_EFFECT_TYPE.filter((el) => el.rule.every((r) => packageStore.opened.includes(r))).length > 0;
  }

  @computed get isBrandRoutineAvailable() {
    return this.dataReport.brandRoutine && ['industry', 'brand', 'series'].some((item) => packageStore.opened.includes(item));
    // BRAND_ROUTINE_TYPE.filter((el) => el.rule.every((r) => packageStore.opened.includes(r))).length > 0;
  }

  @computed get isReputationAvailable() {
    return this.advance.reputation && (packageStore.opened.includes('brand') || packageStore.opened.includes('product'));
  }

  constructor() {
    makeObservable(this);
  }

  @action init = async () => {
    if (!Auth.hasToken) {
      throw new Error('no permission');
    }

    if (this.userId) {
      return true;
    }

    await this.initUser();
    if (!this.userType) {
      throw new Error('no permission');
    }
    return true;
  };

  @action initUser = async () => {
    try {
      const res = await UserService.myProfile();

      runInAction(() => {
        const {
          id,
          name,
          account,
          permission,
          teamId
        } = res;

        this.userId = id;
        this.userName = name;
        this.userAccount = account;
        this.userType = permission;
        this.userTeamId = teamId;

      });
      if (this.userType !== 'customer') {
        this.updateUserList();
      } else {
        this.updateUser({
          id: this.userId,
          name: this.userName,
          account: this.userAccount
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  @action onUserSelect = (user) => {
    Cookies.set('activeUserId', user.id);
    this.updateUser(user);
  };

  @action updateUser = async (user) => {
    try {
      this.activeUserId = user.id;
      this.activeUserName = user.name;
      this.activeUserAccount = user.account;
      optionsStore.setLoading('userInfo');

      const res = await UserService.getUserInfoWithPackages(user.id);
      runInAction(() => {
        const {
          permission,
          validity,
          packages,
          search,
          topic,
          chart,
          wordbook,
          related,
          advanceSearch,
          notification,
          dataReport,
          advance
        } = res;

        this.activeUserType = permission;
        this.activeUserValidity = validity;

        if (user.id === this.userId) {
          this.userValidity = validity;
        }

        this.search = search;
        this.topic = topic;
        this.chart = chart;
        this.wordbook = wordbook;
        this.related = related;
        this.advanceSearch = advanceSearch;
        this.notification = notification;
        this.dataReport = {
          ...this.dataReport,
          ...dataReport
        };
        this.advance = {
          ...this.advance,
          ...advance
        };

        if (packages?.length === 0) {
          message.info('目前沒有任何可使用的數據包唷！');
        }

        packageStore.updatePackages(packages);
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        optionsStore.setCompleted('userInfo');
      });
    }
  };

  @action updateUserList = async () => {
    try {
      const { userList } = await UserService.getSwitchUserList();

      runInAction(() => {
        this.customerList = userList.sort((a, b) => {
          if (a.id === this.userId) {
            return -1; // a 在前面
          }
          if (b.id === this.userId) {
            return 1; // b 在前面
          }
          return 0; // 保持原本順序
        });
        const previousUserId = Cookies.get('activeUserId');
        const previousUser = userList.find((el) => el.id === previousUserId);
        if (previousUser) {
          this.updateUser(previousUser);
        } else {
          this.updateUser({
            id: this.userId,
            name: this.userName,
            account: this.userAccount
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action updatePackages = async () => {
    try {
      optionsStore.setLoading('package list');
      const { packages } = await UserService.getUserInfoWithPackages(this.activeUserId);
      packageStore.updatePackages(packages);

    } catch (error) {
      console.log(error);
    } finally {
      optionsStore.setCompleted('package list');
    }
  };
}

const userStore = new UserStore();

export default userStore;
