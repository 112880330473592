import Industry from './industry';
import Brand from './brand';
import Series from './series';
import Product from './product';

export default {
  Industry,
  Brand,
  Series,
  Product
};
