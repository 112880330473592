import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


@observer
class LineChart extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <div style={{ width: '100%', display: 'flex', aspectRatio: 3 }}>
        <Line key={viewModel.key} options={viewModel.options} data={viewModel.data} ref={viewModel.ref} />
      </div>
    );
  }
}

LineChart.propTypes = {
  viewModel: PropTypes.object
};

LineChart.defaultProps = {
  viewModel: {}
};


export default LineChart;
