import { COLOR_SET } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { generateCSVKey } from 'src/utils/chart';

export default class IndustrySourceChannelRankingModel {
  constructor(origin, query) {
    if (origin.length === 0) {
      this.data = {
        chartData: [{
          id: 'industry_source_channel_ranking',
          name: '行業來源頻道排行',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }]
        }]
      };
      return;
    }

    if (origin.every((el) => el.count === 0)) {
      this.data = {
        chartData: [{
          id: 'industry_source_channel_ranking',
          name: '行業來源頻道排行',
          data: [{
            type: 'nodata'
          }]
        }]
      };
      return;
    }

    const data = origin.sort((a, b) => b.count - a.count).slice(0, 20);

    const [gte, lte] = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'));

    const {
      postType,
      womType
    } = query;

    const csv = [
      {
        date: `${gte} ~ ${lte}`
      }
    ];

    origin.forEach((el) => {
      const row = csv[0];

      row[`${el.name}`] = el.count;
    });

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'industry_source_channel_ranking',
          name: '行業來源頻道排行',
          data: [
            {
              labels: data.map((el) => el.name),
              datasets: [
                {
                  label: `${gte} ~ ${lte}`,
                  data: data.map((el) => el.count),
                  channelId: data.map((el) => el.id),
                  backgroundColor: COLOR_SET[0],
                  borderSkipped: true,
                  maxBarThickness: 50
                }
              ]
            }
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const channelId = chart.data.datasets[datasetIndex].channelId[index];
              const channelName = chart.data.labels[index];
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                channel: [{
                  id: channelId,
                  name: channelName
                }],
                chart: {
                  name: '數據報告/品牌成效分析/行業來源頻道排行'
                },
                industry: true,
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'bar',
          options: {
            event: ['click'],
            scales: {
              y: {
                grace: 5,
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ],
      channel: origin.map((el) => ({ id: el.id, name: el.name }))
    };
  }
  static fromRes(data, query) {
    return new IndustrySourceChannelRankingModel(data, query);
  }
}
