import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Switch, Collapse, Table } from 'antd';

import i18n from 'src/i18n';
import optionsStore from 'src/stores/optionsStore';

import styles from './styles.module.scss';

@observer
class WordCloud extends React.Component {
  _renderPanelIcon = () => {
    return (
      <Switch
        defaultChecked={false}
      />
    );
  };

  componentDidMount() {
    this.props.viewModel.didMount();
  }

  componentWillUnmount() {
    this.props.viewModel.willUnmount();
  }

  render() {
    const { viewModel, sizeData } = this.props;
    const { resizeRef } = sizeData;
    return (
      <div className={styles.container}>
        {/* WORDS HERE */}
        <div
          id={`wordsContainer${viewModel.timeSec}`}
          ref={resizeRef}
          className={styles.wordsContainer}
        />
        <Collapse
          collapsible="icon"
          expandIcon={this._renderPanelIcon}
          ghost
          className={styles.collapseContainer}
          style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
        >
          <Collapse.Panel
            key="table"
            className="wordsCollapse"
            header={i18n.t('wordbook_page_statistic_table_title')}
          >
            <div className={styles.tableContainer}>
              <Table
                key={viewModel.sortWords.length}
                rowClassName={styles.row}
                rowKey={(item) => item.id}
                dataSource={viewModel.sortWords}
                pagination={false}
                className="packagesTable"
              >
                {
                  viewModel.table?.map((el) => {
                    return (
                      <Table.Column
                        title={el.title}
                        dataIndex={el.dataIndex}
                        key={el.key}
                        className={styles.columns}
                        width={viewModel.table.length === 3 ? '33%' : '25%'}
                        render={(_, record, i) => {
                          if (el.dataIndex === 'ranking') {
                            return (
                              <div>
                                {i + 1}
                              </div>
                            );
                          }
                          return (
                            <div>
                              {record[el.dataIndex]}
                            </div>
                          );
                        }}
                      />
                    );
                  })
                }
              </Table>
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  }
}

WordCloud.propTypes = {
  viewModel: PropTypes.object,
  sizeData: PropTypes.object
};

WordCloud.defaultProps = {
  viewModel: {},
  sizeData: {
    resizeRef: null,
    height: 1,
    width: 1
  }
};


export default WordCloud;
