import { request, getHost, getHeaders } from './utils';

export const getSourceStatistics = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/vocService/source/channel`,
    headers: getHeaders()
  };
  return request(options);
};


export const getPackageSources = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/source`,
    headers: getHeaders(),
    data
  };
  return request(options);
};
