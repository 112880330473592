import React from 'react';
import { makeObservable, observable, action, computed } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

export default class PieChartViewModel {
  type = 'pie';
  ref = React.createRef();
  @observable key = '';
  @observable name = '';
  @observable options = {};
  @observable plugins = [];
  @observable data = {
    labels: [],
    datasets: []
  };

  @observable csv = [];
  @observable csvKey = [];
  @observable legendContainerId = '';

  constructor(data) {
    makeObservable(this);

    this.init(data);
  }

  @action init = (content) => {
    const {
      data,
      name,
      onPointClick,
      options,
      plugins,
      csv,
      csvKey
    } = content;

    this.key = uuidv4();
    this.name = name;
    this.data = data;
    this.options = {
      ...options,
      plugins: {
        ...options.plugins,
        legend: {
          ...options.plugins.legend,
          labels: {
            ...options.plugins.legend?.labels,
            color: '#000'
          },
          position: 'right'
        }
      },
      onClick: (_, elements) => onPointClick(elements, this.ref.current),
      onHover: (event, elements) => {
        const target = event.native.target;
        if (elements.length > 0) {
          target.style.cursor = 'pointer';
        } else {
          target.style.cursor = 'default';
        }
      }
    };
    this.legendContainerId = this.options.plugins.htmlLegend?.containerID ?? 'legend-container';
    this.csv = csv;
    this.csvKey = csvKey;
    this.plugins = plugins;
  };
}

