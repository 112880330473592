import { request, getHost, getHeaders } from './utils';

export const getTopics = (pid, params, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/post`,
    headers: getHeaders(),
    params,
    data
  };
  return request(options);
};

export const getNextPageTopics = (pid, sid, params) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/vocService/package/${pid}/post`,
    headers: getHeaders(),
    params: {
      searchId: sid,
      ...params
    }
  };
  return request(options);
};

export const updateSentiment = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/post/sentiment`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getSingleTopic = (pid, vocKey, params) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/vocService/package/${pid}/post/${vocKey}`,
    headers: getHeaders(),
    params
  };
  return request(options);
};

// > topics page levels selected

export const getSelectedLevel = (pid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/dataPackage/${pid}/topicSearchRecord`,
    headers: getHeaders()
  };
  return request(options);
};

export const updateLevels = (pid, data) => {
  const options = {
    method: 'put',
    url: `${getHost()}/api/v1/dataPackage/${pid}/topicSearchRecord`,
    headers: getHeaders(),
    data
  };
  return request(options);
};
