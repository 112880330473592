import { COLOR_SET, AVG_LINE_COLOR } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import { SOURCE_CATEGORY_TYPE } from 'src/consts';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';

export default class IndustrySourceSocialActivityModel {
  constructor(data, prevData, query) {
    const [gte, lte] = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'));
    const [prevGte, prevLte] = infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD'));

    const csv = data.map((el) => ({
      date: `${gte}~${lte}`,
      sns: el.snsCount,
      news: el.newsCount,
      sn: el.sn
    }));

    prevData.forEach((el) => {
      csv.push({
        date: `${prevGte}~${prevLte}`,
        sns: el.snsCount,
        news: el.newsCount,
        sn: el.sn
      });
    });

    const csvKey = [
      { key: 'date', header: '日期' },
      { key: 'sns', header: '社群' },
      { key: 'news', header: '新聞' },
      { key: 'sn', header: 'SN值' }
    ];

    this.data = {
      chartData: [
        {
          id: 'industry_source_social',
          name: '行業社群活躍度',
          data: [
            ...(
              data[0] && data[0].sn !== 0 && data[0].snsCount !== 0 && data[0].newsCount !== 0
                ? [{
                  labels: ['行業'],
                  datasets: [
                    {
                      label: '新聞',
                      data: [data[0].newsCount],
                      time: 'present',
                      backgroundColor: COLOR_SET[0],
                      maxBarThickness: 50,
                      categoryPercentage: 0.1,
                      yAxisID: 'y'
                    },
                    {
                      label: '社群',
                      data: [data[0].snsCount],
                      time: 'present',
                      backgroundColor: COLOR_SET[1],
                      maxBarThickness: 50,
                      categoryPercentage: 0.1,
                      yAxisID: 'y'
                    },
                    {
                      label: 'SN值',
                      data: [data[0].sn],
                      backgroundColor: AVG_LINE_COLOR,
                      borderSkipped: true,
                      type: 'line',
                      yAxisID: 'y1'
                    }
                  ]
                }]
                : [{
                  type: 'nodata',
                  subType: data[0] ? 'default' : 'ana-error'
                }]
            ),
            ...(
              prevData[0] && prevData[0].sn !== 0 && prevData[0].snsCount !== 0 && prevData[0].newsCount !== 0
                ? [{
                  labels: ['行業'],
                  datasets: [
                    {
                      label: '新聞',
                      data: [prevData[0].newsCount],
                      time: 'previous',
                      backgroundColor: COLOR_SET[0],
                      maxBarThickness: 50,
                      categoryPercentage: 0.1,
                      yAxisID: 'y'
                    },
                    {
                      label: '社群',
                      data: [prevData[0].snsCount],
                      time: 'previous',
                      backgroundColor: COLOR_SET[1],
                      maxBarThickness: 50,
                      categoryPercentage: 0.1,
                      yAxisID: 'y'
                    },
                    {
                      label: 'SN值',
                      data: [prevData[0].sn],
                      backgroundColor: AVG_LINE_COLOR,
                      borderSkipped: true,
                      type: 'line',
                      yAxisID: 'y1'
                    }
                  ]
                }]
                : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{
                        type: 'nodata',
                        subType: prevData[0] ? 'default' : 'ana-error'
                      }]
                      : []
                  )
                ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType
              } = query;
              const { datasetIndex, index } = elements[0];
              const type = chart.data.datasets[datasetIndex].label;
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const time = chart.data.datasets[datasetIndex].time;
              let category = '';
              if (type === '社群') {
                category = ['forum', 'facebook', 'ig'];
              } else if (type === '新聞') {
                category = ['news'];
              } else {
                return;
              }
              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                category: category.map((el) => ({
                  id: el,
                  name: SOURCE_CATEGORY_TYPE[el]
                })),
                SNType: type,
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/行業/來源分析/行業社群活躍度'
                },
                industry: true,
                womCount,
                date: {
                  gte: time === 'present' ? gte : prevGte,
                  lte: time === 'present' ? lte : prevLte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'bar',
          options: {
            event: ['click'],
            scales: {
              y: {
                type: 'linear',
                display: true,
                position: 'left',
                grace: '15%',
                title: {
                  display: true,
                  text: '聲量'
                }
              },
              y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grace: '15%',
                title: {
                  display: true,
                  text: 'SN值'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ]
    };
  }

  static fromRes(data, prevData, query) {
    return new IndustrySourceSocialActivityModel(data, prevData, query);
  }
}
