import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import { Waypoint } from 'react-waypoint';

import { CloseOutlined, CheckOutlined, DownloadOutlined, CaretDownOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import Select from 'src/components/select';

import { POST_TYPE } from 'src/consts';

import InfoHeader from 'src/components/InfoHeader';
import ScrollTop from 'src/components/ScrollTop';
import packageStore from 'src/stores/packageStore';
import FloatingScrollTo from '../Components/FloatingScrollTo';
import BrandEffectFactory from './charts/factory';

import PopoverSelect from '../Components/PopoverSelect';
import BrandEffectPageViewModel from './viewModel';

import styles from './styles.module.scss';

@observer
class BrandEffectPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new BrandEffectPageViewModel();
  }
  componentWillUnmount() {
    const container = document.querySelector('.main');
    container.scrollTo({ top: 0, behavior: 'smooth' });
  }
  render() {
    return (
      <>
        <div
          className={styles.pageContainer}
        >
          <InfoHeader
            breadcrumb={['數據報告', '品牌成效分析']}
            callback={this.viewModel.getCharts}
          />
          <div className={styles.contentContainer}>
            <div className={styles.filterContainer}>
              <div className={styles.filterText}>
                {i18n.t('data_report_setting_text')}
              </div>
              {
                packageStore.isBrandAvailable
                && (
                  <Select
                    placeholder="請選擇主品牌"
                    options={this.viewModel.brandList}
                    value={this.viewModel.selectedBrand}
                    onSelect={this.viewModel.onBrandChange}
                    popupMatchSelectWidth={false}
                    popupClassName={styles.sortMenu}
                    className={clsx('sortSelect')}
                    suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                  />
                )
              }
              <Select
                options={POST_TYPE}
                value={this.viewModel.postType}
                onSelect={this.viewModel.onPostTypeChange}
                popupMatchSelectWidth={false}
                popupClassName={styles.sortMenu}
                className={clsx('sortSelect')}
                suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
              />
              {
                packageStore.isSeriesAvailable
                && (
                  <>
                    <div className={styles.filterText}>
                      {i18n.t('data_report_select_series')}
                    </div>
                    <Select
                      placeholder="請選擇系列群組"
                      options={this.viewModel.seriesParent}
                      value={this.viewModel.selectedParent}
                      onSelect={this.viewModel.onSeriesParentSelect}
                      popupMatchSelectWidth={false}
                      popupClassName={styles.sortMenu}
                      className={clsx('sortSelect')}
                      suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                    />
                  </>
                )
              }
              <Button
                icon={<CheckOutlined />}
                className={clsx(styles.btn, styles.searchBtn)}
                onClick={this.viewModel.onSubmit}
              >
                {i18n.t('data_report_search')}
              </Button>
              <Button
                icon={<CloseOutlined />}
                className={clsx(styles.btn, styles.cleanBtn)}
                type="link"
                onClick={this.viewModel.onClean}
              >
                {i18n.t('data_report_clean')}
              </Button>
            </div>
            <div className={styles.resContainer}>
              <div className={styles.actionRow}>
                <div className={styles.scrollToSelect}>
                  <div className={styles.text}>
                    {i18n.t('data_report_quick_anchor')}
                  </div>
                  <PopoverSelect
                    viewModel={this.viewModel.chartPopover}
                    placeholder={i18n.t('data_report_quick_anchor_select_title')}
                  />
                </div>
                <div className={styles.actionContainer}>
                  <Button
                    icon={<DownloadOutlined />}
                    className={clsx(styles.btn)}
                    onClick={this.viewModel.onDownloadAllExcel}
                  >
                    下載全部數據
                  </Button>
                  <Button
                    icon={<SaveOutlined />}
                    className={clsx(styles.btn)}
                    onClick={this.viewModel.onDownloadAllImage}
                  >
                    下載全部圖表
                  </Button>
                </div>
              </div>

              <Waypoint
                onEnter={this.viewModel.onFloatingHide}
                onLeave={this.viewModel.onFloatingShow}
              />
              {
                this.viewModel.isInit
                && (
                  <div className={styles.chartsContainer}>
                    {
                      this.viewModel.chartItems.map((el) => {
                        return BrandEffectFactory.createChartView(el);
                      })
                    }
                  </div>
                )
              }
            </div>

          </div>

        </div>
        {
          this.viewModel.isShowFloatingActions
          && (
            <>
              <ScrollTop containerName="main" />
              <FloatingScrollTo viewModel={this.viewModel.floatingScrollToViewModel} />
            </>
          )
        }
        <Modal
          open={this.viewModel.isModalOpen}
          onCancel={this.viewModel.onModalClose}
          width={500}
          centered
          closable={false}
          footer={null}
          className={styles.modal}
          bodyStyle={{
            height: 160,
            display: 'flex',
            flexDirection: 'column',
            rowGap: 36,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div className={styles.modalHeader}>
            <div className={styles.title}>
              {i18n.t('wordbook_page_modal_content').replace('%word%', this.viewModel.selectedWord)}
            </div>
            <CloseOutlined className={styles.close} onClick={this.viewModel.onModalClose} />
          </div>
          <div className={styles.btnContainer}>
            <Button
              className={clsx(styles.btn, styles.normalBtn)}
              onClick={this.viewModel.onTopicsClick}
            >
              {i18n.t('wordbook_page_modal_action_topic')}
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

BrandEffectPage.propTypes = {

};

BrandEffectPage.defaultProps = {

};


export default BrandEffectPage;
