import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { message } from 'antd';
import { SOURCE_CATEGORY } from 'src/consts';
import { getHost } from 'src/utils';
import { encodeURI } from 'js-base64';

import packageStore from 'src/stores/packageStore';
import BlockedWordsService from 'src/services/blockedwords';
import ChartFactory from 'src/components/Charts/factory';

export default class WordChartItemViewModel {

  parent = {};

  @observable data = [];
  @observable activeTabId = null;
  @observable isModalOpen = false;

  @observable wordsViewModel = null;

  @observable onReady = false;

  @computed get activeTab() {
    return this.activeTabId ? this.data.find((el) => el.id === this.activeTabId) : {};
  }

  @computed get activeTabName() {
    return this.activeTabId ? this.data.find((el) => el.id === this.activeTabId).name : '';
  }

  @computed get showChart() {
    return this.activeTabId ? this.data.find((el) => el.id === this.activeTabId).chart : [];
  }

  constructor(data, parent, reserveFeatureId) {
    makeObservable(this);

    this.init(data, parent, reserveFeatureId);
  }

  @action init = async (data, parent, reserveFeatureId) => {
    this.parent = parent;
    this.data = data.data.map((el) => ({
      id: el.id,
      name: el.name,
      chart: [ChartFactory.createChartViewModel({
        id: el.id,
        name: el.name,
        data: el.item,
        csv: data.csv ?? [],
        csvKey: data.csvKey ?? [],
        type: 'words'
      }, this)]
    }));
    runInAction(() => {
      const reserveTarget = this.data.find((el) => el.id === reserveFeatureId);
      if (reserveTarget) {
        this.activeTabId = reserveTarget.id;
      } else {
        this.activeTabId = this.data[0].id;
      }

      this.onReady = true;
    });
  };

  @action onTabsChange = async (key) => {
    this.activeTabId = key;
  };

  @action onModalOpen = (viewModel) => {
    this.wordsViewModel = viewModel;

    this.isModalOpen = true;
  };

  @action onModalClose = () => {
    this.isModalOpen = false;
    this.wordsViewModel.onModalClose();

    this.wordsViewModel = null;
  };

  @action onTopicClick = () => {
    const wordContent = this.wordsViewModel.selectedWordContentByName;
    const { selectedPostType, selectedSourceCategoryType, selectedWOM } = this.parent;
    const linkData = {
      ...(selectedWOM && selectedWOM !== 'all' && { womType: selectedWOM }),
      ...(selectedPostType !== 'all' && {
        postType: selectedPostType
      }),
      ...(
        selectedSourceCategoryType
          ? {
            category: selectedSourceCategoryType.map((el) => ({
              id: el.id,
              name: el.name
            }))
          }
          : {
            category: SOURCE_CATEGORY.map((el) => ({
              id: el.value,
              name: el.label
            }))
          }
      ),
      featureCategory: {
        id: wordContent.group.id,
        name: wordContent.group.name
      },
      feature: {
        id: wordContent.id,
        name: wordContent.name,
        count: wordContent.originCount
      },
      packageContent: {
        id: packageStore.activePackageId,
        name: packageStore.activePackageName
      },
      chart: {
        name: '進階分析/行銷7P分析/文字雲'
      },
      date: {
        gte: this.parent.multipleDatePickerViewModel.currentDate[0].format('YYYY-MM-DD'),
        lte: this.parent.multipleDatePickerViewModel.currentDate[1].format('YYYY-MM-DD')
      }
    };
    const json = JSON.stringify(linkData);
    const result = encodeURI(json);
    const url = `${getHost()}/topics-result?result=${result}`;
    window.open(url, '_blank');

    this.onModalClose();
  };

  @action onWordBlock = async (word) => {
    try {
      await BlockedWordsService.addBlockWords(word);
      this.parent.setReserveFeatureId(this.activeTabId);
      runInAction(async () => {
        await this.parent.getBlockedWords();
        await this.parent.getGraphicData();

        this.onModalClose();
      });
    } catch (error) {
      if (error.response?.status === 409) {
        message.error('該詞已在屏蔽名單中!');
        return;
      }
      message.error('無法新增屏蔽詞，請稍候再試');
    }
  };

}
