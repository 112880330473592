import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import { CSVLink } from 'react-csv';

import ChartFactory from 'src/components/Charts/factory';
import { DownloadOutlined, SaveOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import Select from 'src/components/select';

import Loading from 'src/assets/loading.svg';
import SinglePopoverSelect from 'src/components/SinglePopoverSelect';
import ChartTimeStamp from 'src/components/ChartTimeStamp';
import optionsStore from 'src/stores/optionsStore';

import { WORD_OF_MOUTH_TYPE } from 'src/consts';

import styles from './styles.module.scss';

@observer
class IndustrySourceChannelRanking extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <div
        id="industry_source_channel_ranking"
        className={styles.chartItem}
      >
        <div className={styles.chartTitle}>
          <div className={styles.title}>
            {viewModel.chartName}
          </div>
          <div
            className={clsx(
              styles.actions,
              optionsStore.isImgDownload && styles.displayNone
            )}
          >
            <Select
              value={viewModel.selectedWOM}
              onChange={viewModel.onWOMChange}
              options={WORD_OF_MOUTH_TYPE}
              popupMatchSelectWidth={false}
              popupClassName={styles.sortMenu}
              className={clsx('sortSelect', styles.filterSelect)}
              suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
            />
            <SinglePopoverSelect
              viewModel={viewModel.popoverViewModel}
              title="選擇來源頻道"
              callback={viewModel.getChart}
            />
            <Tooltip
              title="下載數據"
              placement="bottom"
            >
              <Button
                icon={<DownloadOutlined />}
                onClick={viewModel.onDownloadExcel}
              />
            </Tooltip>
            <Tooltip
              title="下載圖表"
              placement="bottom"
            >
              <Button
                icon={<SaveOutlined />}
                onClick={viewModel.onDownload}
              />
            </Tooltip>
            {
              viewModel.isDownload
              && (
                <CSVLink
                  className="industry_source_channel_ranking"
                  data={viewModel.downloadList}
                  filename={viewModel.downloadFileName}
                  style={{ display: 'none' }}
                />
              )
            }
          </div>
        </div>
        <div className={styles.timeStampContainer}>
          <ChartTimeStamp isPresent />
        </div>
        {
          (viewModel.isInit)
          && (
            <div
              key={viewModel.key}
              className={styles.chartContainer}
            >
              {ChartFactory.generateChartView(viewModel.chart)}
            </div>
          )
        }
        {
          !viewModel.isReady
          && (
            <div className={styles.curtain}>
              <div className={styles.color} />
              <div className={styles.absolute}>
                <img src={Loading} alt="" style={{ borderRadius: 8 }} />
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

IndustrySourceChannelRanking.propTypes = {
  viewModel: PropTypes.object
};

IndustrySourceChannelRanking.defaultProps = {
  viewModel: {}
};


export default IndustrySourceChannelRanking;
