export default class TopicCardModel {
  constructor(data) {
    const {
      vocKey,
      author,
      title,
      url,
      website,
      channel,
      content,
      date,
      hit,
      matchId,
      replyId,
      sentiment,
      commentCount,
      shareCount,
      viewCount,
      womCount,
      searchId,
      packageId
    } = data;

    this.vocKey = vocKey;
    this.matchId = matchId;
    this.vocTitle = title;
    this.author = author;
    this.date = date;
    this.website = website;
    this.channel = channel;
    this.url = url;
    this.content = content;
    this.hitPostType = replyId ? `回文${replyId < 99999 ? replyId : ''}` : '主文';
    this.sentiment = sentiment;
    this.hit = hit;
    this.commentCount = commentCount;
    this.shareCount = shareCount;
    this.viewCount = viewCount;
    this.womCount = womCount;
    this.searchId = searchId;
    this.packageId = packageId;
  }

  static fromRes(data) {
    return new TopicCardModel(data);
  }
}
