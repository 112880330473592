import ChartAnalysis from './chartAnalysis';
import Wordbook from './wordbook';
import AdvanceAnalysis from './advanceAnalysis';
import RelatedAnalysis from './relatedAnalysis';

export default {
  ChartAnalysis,
  Wordbook,
  AdvanceAnalysis,
  RelatedAnalysis
};
