import { makeObservable, observable, action, computed, runInAction } from 'mobx';

import PackageService from 'src/services/package';
import optionsStore from 'src/stores/optionsStore';

import CheckItemViewModel from './CheckItem/viewModel';
import CheckGroupItemViewModel from './CheckGroupItem/viewModel';

export default class LevelSelectModalViewModel {
  @observable isModalOpen = false;
  @observable projectId = '';

  @observable brand = [];
  @observable brandKeyword = '';
  @observable brandKeywordCache = '';

  @observable series = [];
  @observable seriesKeyword = '';

  @observable product = [];
  @observable productKeyword = '';

  @observable isProductInProcess = false;

  // > brand computed

  @computed get brandIndeterminate() {
    return this.searchBrand.some((el) => el.checkedCache) && !this.isAllBrandChecked;
  }

  @computed get isAllBrandChecked() {
    return this.searchBrand.every((el) => el.checkedCache);
  }

  @computed get selectedBrandsWithName() {
    return this.brand.filter((el) => el.checkedCache);
  }

  @computed get selectedBrands() {
    return this.brand.filter((el) => el.checkedCache).map((el) => el.id);
  }

  @computed get searchBrand() {
    return this.brand.filter((el) => el.name.toLowerCase().includes(this.brandKeyword.toLowerCase()));
  }

  // > series computed

  @computed get selectedSeriesParent() {
    return this.series.filter((el) => el.groupIndeterminate || el.groupAllSelected).map((el) => ({ parentId: null, id: el.id }));
  }

  @computed get selectedSeries() {
    return this.series.map((el) => el.SelectedWithParent).filter((el) => el.length > 0).flat();
  }

  @computed get showSeries() {
    return this.series.filter((el) => el.keywordChildren.length > 0);
  }

  @computed get seriesIndeterminate() {
    return this.showSeries.some((g) => {
      return g.keywordChildren.some((el) => el.checkedCache);
    }) && !this.isAllSeriesChecked;
  }

  @computed get isAllSeriesChecked() {
    return this.showSeries.every((g) => {
      return g.keywordChildren.every((el) => el.checkedCache);
    });
  }

  // > product computed

  @computed get selectedProduct() {
    return this.product.map((el) => el.selectedItem).filter((el) => el.length > 0).flat();
  }

  @computed get SearchProduct() {
    return this.product.filter((el) => el.keywordChildren.length > 0);
  }

  @computed get showProduct() {
    return this.selectedBrands.length > 0 ? this.SearchProduct.filter((el) => this.selectedBrands.includes(el.id)) : this.SearchProduct;
  }

  @computed get productIndeterminate() {
    return this.showProduct.some((g) => {
      return g.keywordChildren.some((el) => el.checkedCache);
    }) && !this.isAllProductChecked;
  }

  @computed get isAllProductChecked() {
    return this.showProduct.every((g) => {
      return g.keywordChildren.every((el) => el.checkedCache);
    });
  }

  @computed get brandText() {
    const isSelected = this.brand.filter((el) => el.checked).map((el) => el.name);
    return isSelected.length >= 1 ? `「${isSelected.join(', ')}」` : '尚未選擇';
  }

  @computed get seriesText() {
    const res = this.series.filter((el) => !!el.checkedText);
    return res.length >= 1 ? res.map((el) => el.checkedText).join(', ') : '尚未選擇';
  }

  @computed get productText() {
    const res = this.product.filter((el) => !!el.checkedText);
    return res.length >= 1 ? res.map((el) => el.checkedText).join(', ') : '尚未選擇';
  }

  constructor() {
    makeObservable(this);
  }

  // > modal related

  @action onModalOpen = () => {
    this.isModalOpen = true;
  };

  @action onModalClose = () => {
    this.isModalOpen = false;

    this.brandKeyword = '';
    this.brandKeywordCache = '';
    this.seriesKeyword = '';
    this.series.forEach((el) => el.setKeyword(this.seriesKeyword));
    this.productKeyword = '';
    this.product.forEach((el) => el.setKeyword(this.productKeyword));
  };

  // > actions related

  @action onAllBrandSelect = () => {
    if (!this.isAllBrandChecked) {
      this.searchBrand.forEach((el) => {
        el.setSelect(true);
        this.afterBrandSelect(true, el);
      });
    } else {
      this.searchBrand.forEach((el) => {
        el.setSelect(false);
        this.afterBrandSelect(false, el);
      });
    }
  };

  @action onAllSeriesSelect = () => {
    if (!this.isAllSeriesChecked) {
      this.showSeries.forEach((g) => {
        g.keywordChildren.forEach((c) => {
          c.setSelect(true);
        });
      });
    } else {
      this.showSeries.forEach((g) => {
        g.keywordChildren.forEach((c) => {
          c.setSelect(false);
        });
      });
    }
  };

  @action onAllProductSelect = () => {
    if (!this.isAllProductChecked) {
      this.showProduct.forEach((g) => {
        g.keywordChildren.forEach((c) => {
          c.setSelect(true);
        });
      });
    } else {
      this.showProduct.forEach((g) => {
        g.keywordChildren.forEach((c) => {
          c.setSelect(false);
        });
      });
    }
  };

  @action onCleanAll = () => {
    this.brand.forEach((el) => {
      el.setSelect(false);
    });
    this.series.forEach((g) => {
      g.children.forEach((c) => {
        c.setSelect(false);
      });
    });
    this.product.forEach((g) => {
      g.children.forEach((c) => {
        c.setSelect(false);
      });
    });
  };

  @action onSubmit = () => {
    this.brand.forEach((el) => {
      el.confirmSelect();
    });
    this.series.forEach((g) => {
      g.children.forEach((c) => {
        c.confirmSelect();
      });
    });
    this.product.forEach((g) => {
      g.children.forEach((c) => {
        c.confirmSelect();
      });
    });

    this.onModalClose();
  };

  @action onClose = () => {
    this.brand.forEach((el) => {
      el.onDisposeSelect();
    });
    this.series.forEach((g) => {
      g.children.forEach((c) => {
        c.onDisposeSelect();
      });
    });

    this.product.forEach((g) => {
      g.children.forEach((c) => {
        c.onDisposeSelect();
      });
    });

    this.onModalClose();
  };

  // > project related
  @action updateProjectId = async (pid) => {
    this.projectId = pid;
    this.brand = [];
    await this.getBrand();
    await this.getProductOnce();
  };

  // > brand related
  // > api

  @action getBrand = async (lastAnchor = null) => {
    try {
      const { brandList, anchor } = await PackageService.getBrand(this.projectId, {
        ...(lastAnchor && { anchor: lastAnchor })
      });
      runInAction(async () => {
        this.brand = [...this.brand, ...brandList.map((el) => new CheckItemViewModel(el))];
        if (anchor) {
          await this.getBrand(anchor);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action afterBrandSelect = async (bool, brand) => {
    const target = this.product.find((el) => brand.id === el.id);
    if (target.children.length === 0) {
      await this.getProductByBrand(brand);
    }
  };

  // > search

  @action onBrandKeywordChange = (e) => {
    this.brandKeywordCache = e.target.value;
    if (!e.target.value.trim()) {
      this.onBrandSearchSubmit();
    }
  };

  @action onBrandSearchSubmit = () => {
    // ? note: use computed instead of call api to reduce api calling times.
    this.brandKeyword = this.brandKeywordCache;
  };

  @action onBrandKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onBrandSearchSubmit();
    }
  };

  // > series related
  // > api

  @action updateSeries = (data) => {
    this.series = data.map((el) => {
      const target = this.series.find((item) => item.id === el.id);
      if (target) {
        return target;
      }
      return new CheckGroupItemViewModel({ ...el, level: 'series' }, this);
    });
  };

  // > search

  @action onSeriesKeywordChange = (e) => {
    this.seriesKeyword = e.target.value;
    if (!e.target.value.trim()) {
      this.onSeriesSearchSubmit();
    }
  };

  @action onSeriesSearchSubmit = () => {
    // ? note: use computed instead of call api to reduce api calling times.
    this.series.forEach((el) => el.setKeyword(this.seriesKeyword));
  };

  @action onSeriesKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onSeriesSearchSubmit();
    }
  };


  // > product related
  // > api

  @action getProductByBrand = async (brand) => {
    optionsStore.setLoading(brand.id);
    try {
      this.isProductInProcess = true;
      const { productList } = await PackageService.getProduct(this.projectId, {
        sids: [brand.id],
        limit: 10000
      });
      runInAction(() => {
        const target = this.product.find((el) => el.id === brand.id);
        target.updateChildren(productList);
      });
    } catch (error) {
      console.log(error);
    } finally {
      optionsStore.setCompleted(brand.id);
    }
  };

  @action getProductOnce = async () => {
    this.product = this.brand.map((el) => {
      const product = {
        id: el.id,
        name: el.name,
        level: 'product',
        children: []
      };
      return new CheckGroupItemViewModel(product, this);
    });
    await Promise.all(this.brand.map((el) => this.getProductByBrand(el)));
  };

  // > search

  @action onProductKeywordChange = (e) => {
    this.productKeyword = e.target.value;
    if (!e.target.value.trim()) {
      this.onProductSearchSubmit();
    }
  };

  @action onProductSearchSubmit = () => {
    // ? note: use computed instead of call api to reduce api calling times.
    this.product.forEach((el) => el.setKeyword(this.productKeyword));
  };

  @action onProductKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onProductSearchSubmit();
    }
  };
}
