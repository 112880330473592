import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ChartFactory from 'src/components/Charts/factory';
import ChartTimeStamp from 'src/components/ChartTimeStamp';
import styles from './styles.module.scss';

@observer
class ChartItem extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <div className={styles.chartItem}>
        {
          (!viewModel.isLoading)
          && (
            <>
              {
                viewModel.charts.map((el, i) => {
                  return (
                    <div
                      key={el.key}
                      className={styles.chartContainer}
                    >
                      <ChartTimeStamp isPresent={i === 0} isPrevious={i === 1} />
                      {ChartFactory.generateChartView(el)}
                    </div>
                  );
                })
              }
            </>

          )
        }
      </div>
    );
  }
}

ChartItem.propTypes = {
  viewModel: PropTypes.object
};

ChartItem.defaultProps = {
  viewModel: {}
};


export default ChartItem;
