import { makeObservable, observable, action, computed } from 'mobx';
import dayjs from 'dayjs';

import packageStore from 'src/stores/packageStore';

import MultiPeriodDatePickerViewModel from '../MultiPeriodDatePicker/viewModel';

class InfoHeaderViewModel {
  @observable multiViewModel = new MultiPeriodDatePickerViewModel();

  @observable projectKeyword = '';
  @observable projectKeywordCache = '';

  @computed get searchProjectsRes() {
    return !this.projectKeywordCache ? packageStore.packages : packageStore.packages.filter((el) => el.name.toLowerCase().includes(this.projectKeywordCache.toLowerCase()));
  }

  constructor() {
    makeObservable(this);
  }

  @action onProjectSearchChange = (e) => {
    this.projectKeyword = e.target.value;
    if (!this.projectKeyword.trim()) {
      this.projectKeywordCache = '';
    }
  };

  @action onProjectSearchSubmit = () => {
    this.projectKeywordCache = this.projectKeyword;
  };

  @action onProjectSearchKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onProjectSearchSubmit();
    }
  };

  @action onProjectSelectOpenChange = () => {
    this.projectKeyword = '';
    this.projectKeywordCache = '';
  };
}

const infoHeaderViewModel = new InfoHeaderViewModel();

export default infoHeaderViewModel;
