export default class OtherResourceModel {
  constructor(data) {
    const {
      _id,
      title,
      date,
      link
    } = data;

    this.id = _id;
    this.name = title;
    this.date = date;
    this.url = link;
  }

  static fromRes(data) {
    return new OtherResourceModel(data);
  }
}
