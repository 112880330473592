import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';

import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Modal, Checkbox } from 'antd';

import PaperIcon from 'src/assets/PaperIcon.svg';
import SearchIcon from 'src/assets/MenuIcons/SearchIcon.svg';

import CheckItem from './CheckItem';

import styles from './styles.module.scss';

@observer
class BulletinSentimentSelectModal extends React.Component {
  render() {
    const { viewModel, onSubmitCallback } = this.props;
    return (
      <Modal
        open={viewModel.isModalOpen}
        closable={false}
        centered
        onCancel={viewModel.onClose}
        className="selectModal"
        width={422}
        footer={null}
      >
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <div className={styles.headerTitle}>
              <img src={PaperIcon} alt="paper" className={styles.titleIcon} />
              <div className={styles.titleText}>
                {i18n.t('bulletin_drawer_sentiment_header')}
              </div>
            </div>
            <div className={styles.headerClose}>
              <Button
                icon={<CloseOutlined />}
                type="link"
                className={styles.headerClose}
                onClick={viewModel.onClose}
              >
                {i18n.t('package_modal_close')}
              </Button>
            </div>
          </div>
          <div className={styles.modalContent}>
            <div className={styles.selectCard}>
              <div className={styles.cardHeader}>
                <div className={styles.cardTitle}>
                  {i18n.t('bulletin_drawer_sentiment_option')}
                </div>
                <div className={styles.cardSelectAll}>
                  <Checkbox
                    indeterminate={viewModel.indeterminate}
                    checked={viewModel.isAllChecked}
                    onChange={viewModel.onAllSelect}

                  />
                  <div className={styles.selectAllText}>
                    {i18n.t('package_modal_select_all')}
                  </div>
                </div>
              </div>
              <div className={styles.cardContent}>
                <div className={styles.searchInputContainer}>
                  <input
                    className={styles.searchInput}
                    placeholder={i18n.t('package_modal_input_placeholder')}
                    value={viewModel.keyword}
                    onChange={viewModel.onKeywordChange}
                    onKeyDown={viewModel.onKeyDown}
                  />
                  <div
                    className={styles.searchBtn}
                    onClick={viewModel.onSearchSubmit}
                  >
                    <img src={SearchIcon} alt="" />
                  </div>
                </div>
                <div className={styles.levelCheckboxContainer}>
                  {
                    viewModel.searchSentiment?.map((el) => {
                      return <CheckItem key={el.id} viewModel={el} />;
                    })
                  }
                </div>
              </div>
            </div>
          </div>
          <div className={styles.modalFooter}>
            <div className={styles.btnContainer}>
              <Button
                className={clsx(styles.btn, styles.cleanBtn)}
                icon={<CloseOutlined />}
                onClick={viewModel.onCleanAll}
              >
                {i18n.t('package_modal_clean')}
              </Button>
              <Button
                className={clsx(styles.btn, styles.applyBtn)}
                icon={<CheckOutlined />}
                type="primary"
                onClick={() => viewModel.onSubmit(onSubmitCallback)}
              >
                {i18n.t('package_modal_apply')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

BulletinSentimentSelectModal.propTypes = {
  viewModel: PropTypes.object,
  onSubmitCallback: PropTypes.func
};

BulletinSentimentSelectModal.defaultProps = {
  viewModel: {},
  onSubmitCallback: () => {}
};


export default BulletinSentimentSelectModal;
