import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import { CSVLink } from 'react-csv';

import { DownloadOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Collapse, Table, Switch, Tooltip } from 'antd';

import Loading from 'src/assets/loading.svg';

import { WORDS_TABLE_COMPARE } from 'src/consts/tables';
import ChartFactory from 'src/components/Charts/factory';
import ChartTimeStamp from 'src/components/ChartTimeStamp';
import optionsStore from 'src/stores/optionsStore';

import styles from './styles.module.scss';
import PresentBrandWord from './WordCloud/Present';
import PreviousBrandWord from './WordCloud/Previous';

@observer
class BrandWord extends React.Component {
  _renderPanelIcon = (props) => {
    return (
      <Switch
        defaultChecked={false}
      />
    );
  };

  render() {
    const { viewModel } = this.props;
    return (
      <div
        id="main_brand_word"
        className={styles.chartItem}
      >
        <div id="main_brand_word_download">
          <div className={styles.chartTitle}>
            <div className={styles.title}>
              {viewModel.chartName}
            </div>
            <div
              className={clsx(
                styles.actions,
                optionsStore.isImgDownload && styles.displayNone
              )}
            >
              <Tooltip
                title="下載數據"
                placement="bottom"
              >
                <Button
                  icon={<DownloadOutlined />}
                  onClick={viewModel.onDownloadExcel}
                />
              </Tooltip>
              <Tooltip
                title="下載圖表"
                placement="bottom"
              >
                <Button
                  icon={<SaveOutlined />}
                  onClick={viewModel.onDownload}
                />
              </Tooltip>
              {
                viewModel.isDownload
                && (
                  <CSVLink
                    className="main_brand_word"
                    data={viewModel.downloadList}
                    filename={viewModel.downloadFileName}
                    style={{ display: 'none' }}
                  />
                )
              }
            </div>
          </div>

          {
            (viewModel.isInit)
            && (
              <div>
                {
                  viewModel.chart.type === 'nodata'
                    ? (
                      <>
                        {ChartFactory.generateChartView({ type: 'nodata' })}
                      </>
                    )
                    : (
                      <>
                        <div className={styles.timeStampContainer}>
                          <ChartTimeStamp isPresent />
                        </div>
                        <div
                          key={viewModel.presentKey}
                          className={styles.chartContainer}
                        >
                          <PresentBrandWord viewModel={viewModel.chart} />
                        </div>
                      </>
                    )
                }
                {
                  viewModel.prevChart.type === 'nodata'
                    ? (
                      <>
                        {ChartFactory.generateChartView({ type: 'nodata' })}
                      </>
                    )
                    : (
                      <>
                        <div className={styles.timeStampContainer}>
                          <ChartTimeStamp isAutoPrevious />
                        </div>
                        <div
                          key={viewModel.previousKey}
                          className={styles.chartContainer}
                        >
                          <PreviousBrandWord viewModel={viewModel.prevChart} />
                        </div>
                      </>
                    )
                }
              </div>
            )
          }
          {
            !viewModel.isReady
            && (
              <div className={styles.curtain}>
                <div className={styles.color} />
                <div className={styles.absolute}>
                  <img src={Loading} alt="" style={{ borderRadius: 8 }} />
                </div>
              </div>
            )
          }
        </div>
        {
          (viewModel.isInit)
          && (
            <div>
              {
                !(viewModel.chart.type === 'nodata' && viewModel.prevChart.type === 'nodata')
                && (
                  <Collapse
                    collapsible="icon"
                    expandIcon={this._renderPanelIcon}
                    ghost
                    className={styles.collapseContainer}
                    style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
                  // defaultActiveKey="table"
                  >
                    <Collapse.Panel
                      key="table"
                      className="wordsCollapse"
                      header={i18n.t('wordbook_page_statistic_table_title')}
                    >
                      <div className={styles.tableContainer}>
                        <Table
                          rowClassName={styles.row}
                          rowKey={(item) => item.id}
                          dataSource={viewModel.tableContent}
                          pagination={false}
                          className="packagesTable"
                        >
                          {
                            WORDS_TABLE_COMPARE.map((el) => {
                              return (
                                <Table.Column
                                  title={el.title}
                                  dataIndex={el.dataIndex}
                                  key={el.key}
                                  className={styles.columns}
                                  width="25%"
                                  render={(_, record, i) => {
                                    if (el.dataIndex === 'ranking') {
                                      return (
                                        <div>
                                          {i + 1}
                                        </div>
                                      );
                                    }
                                    return (
                                      <div>
                                        {record[el.dataIndex]}
                                      </div>
                                    );
                                  }}
                                />
                              );
                            })
                          }
                        </Table>
                      </div>
                    </Collapse.Panel>
                  </Collapse>
                )
              }
            </div>
          )
        }
      </div>
    );
  }
}

BrandWord.propTypes = {
  viewModel: PropTypes.object
};

BrandWord.defaultProps = {
  viewModel: {}
};


export default BrandWord;
