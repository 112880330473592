import React from 'react';
import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { CellMeasurerCache } from 'react-virtualized';
import { SOURCE_CATEGORY_TYPE_TC } from 'src/consts';

import CheckItemViewModel from './CheckItem/viewModel';
import CheckGroupItemViewModel from './CheckGroupItem/viewModel';

export default class SourceSelectModalViewModel {
  @observable isModalOpen = false;
  @observable isShow = 'category';

  @observable category = [];
  @observable categoryKeyword = '';
  @observable categoryKeywordCache = '';

  @observable website = [];
  @observable websiteKeyword = '';

  @observable channel = [];
  @observable channelKeyword = '';

  @computed get modalSize() {
    switch (this.isShow) {
      case 'category':
        return 422;
      case 'website':
        return 776;
      default:
        return 1130;
    }
  }

  // > category computed

  @computed get categoryIndeterminate() {
    return this.searchCategory.some((el) => el.checkedCache) && !this.isAllCategoryChecked;
  }

  @computed get isAllCategoryChecked() {
    return this.searchCategory.every((el) => el.checkedCache);
  }

  @computed get selectedCategories() {
    const result = [];
    for (let i = 0; i < this.category.length; i += 1) {
      if (this.category[i].checkedCache) {
        result.push(this.category[i].id);
      }
    }
    return result;
  }

  @computed get activateSourceCategory() {
    const result = [];
    for (let i = 0; i < this.category.length; i += 1) {
      if (this.category[i].checkedCache) {
        result.push({ value: this.category[i].id, label: this.category[i].name });
      }
    }
    return result;
  }

  @computed get searchCategory() {
    const result = [];
    for (let i = 0; i < (this.category?.length ?? 0); i += 1) {
      if (this.category[i].name.toLowerCase().includes(this.categoryKeyword.toLowerCase())) {
        result.push(this.category[i]);
      }
    }
    return result;
  }

  @computed get selectedChannelsByCategory() {
    const websites = this.selectedChannelsByWebsite;
    const result = [];
    for (let i = 0; i < this.website.length; i += 1) {
      let count = 0;
      for (let j = 0; j < (this.website[i].children?.length ?? 0); j += 1) {
        count += websites[`${this.website[i].name}/${this.website[i].children[j].id}`] || 0;
      }
      if (count > 0) {
        result.push({ id: this.website[i].id, name: this.website[i].name, count });
      }
    }
    return result;
  }

  @computed get selectedCategoryText() {
    const isSelected = [];
    for (let i = 0; i < this.category.length; i += 1) {
      if (this.category[i].checked) {
        isSelected.push(this.category[i]);
      }
    }
    return isSelected.length >= 1 ? isSelected.map((el) => el.name).join(', ') : '尚未選擇';
  }

  @computed get selectedWebsiteText() {
    const isSelected = [];
    for (let i = 0; i < this.website.length; i += 1) {
      if (this.website[i].checkedText) {
        isSelected.push(this.website[i].checkedText);
      }
    }
    return isSelected.length >= 1 ? isSelected.join(', ') : '尚未選擇';
  }

  @computed get selectedChannelText() {
    const isSelected = [];
    for (let i = 0; i < this.channel.length; i += 1) {
      if (this.channel[i].checkedText) {
        isSelected.push(this.channel[i].checkedText);
      }
    }
    return isSelected.length >= 1 ? isSelected.join(', ') : '尚未選擇';
  }

  // > website computed

  @computed get websiteIndeterminate() {
    return this.showWebsites.some((g) => {
      return g.keywordChildren.some((el) => el.checkedCache);
    }) && !this.isAllWebsiteChecked;
  }

  @computed get isAllWebsiteChecked() {
    if (this.selectedCategories.length === 0) {
      return false;
    }
    return this.showWebsites.every((g) => {
      return g.keywordChildren.every((el) => el.checkedCache);
    });
  }

  @computed get selectedWebsites() {
    const result = [];
    for (let i = 0; i < this.website.length; i += 1) {
      result.push(...(this.website[i].selectedItem || []));
    }
    return result;
  }

  @computed get selectedWebsitesWithParent() {
    const result = [];
    for (let i = 0; i < this.website.length; i += 1) {
      for (let j = 0; j < this.website[i].selectedItem.length; j += 1) {
        result.push(`${this.website[i].name}/${this.website[i].selectedItem[j]}`);
      }
    }
    return result;
  }

  @computed get searchWebsite() {
    const result = [];
    for (let i = 0; i < (this.website?.length ?? 0); i += 1) {
      if (this.website[i].keywordChildren.length > 0) {
        result.push(this.website[i]);
      }
    }
    return result;
  }

  @computed get showWebsites() {
    const result = [];
    for (let i = 0; i < this.searchWebsite.length; i += 1) {
      if (this.selectedCategories.includes(this.searchWebsite[i].id)) {
        result.push(this.searchWebsite[i]);
      }
    }
    return result;
  }

  @computed get flatWebsites() {
    const result = [];

    for (let i = 0; i < this.showWebsites.length; i += 1) {
      result.push(this.showWebsites[i]);
      for (let j = 0; j < this.showWebsites[i].keywordChildren.length; j += 1) {
        result.push(this.showWebsites[i].keywordChildren[j]);
      }
    }

    return result;
  }

  @computed get selectedChannelsByWebsite() {
    const obj = {};
    for (let i = 0; i < this.channel.length; i += 1) {
      for (let j = 0; j < this.channel[i].children.length; j += 1) {
        if (this.channel[i].children[j].checked) {
          obj[`${this.channel[i].name}`] = (obj[`${this.channel[i].name}`] || 0) + 1;
        }
      }
    }

    return obj;
  }

  // > channel computed

  @computed get channelIndeterminate() {
    return this.showChannel.some((g) => {
      return g.keywordChildren.some((el) => el.checkedCache);
    }) && !this.isAllChannelChecked;
  }

  @computed get isAllChannelChecked() {
    if (this.selectedWebsites.length === 0) {
      return false;
    }
    return this.showChannel.every((g) => {
      return g.keywordChildren.every((el) => el.checkedCache);
    });
  }

  @computed get selectedChannels() {
    const result = [];
    for (let i = 0; i < this.channel.length; i += 1) {
      result.push(...(this.channel[i].selectedItem || []));
    }
    return result;
  }

  @computed get searchChannel() {
    const result = [];
    for (let i = 0; i < (this.channel?.length ?? 0); i += 1) {
      if (this.channel[i].keywordChildren.length > 0) {
        result.push(this.channel[i]);
      }
    }
    return result;
  }

  @computed get showChannel() {
    const result = [];
    for (let i = 0; i < this.searchChannel.length; i += 1) {
      if (this.selectedWebsitesWithParent.includes(this.searchChannel[i].name)) {
        result.push(this.searchChannel[i]);
      }
    }
    return result;
  }

  @computed get flatChannel() {
    const result = [];

    for (let i = 0; i < this.showChannel.length; i += 1) {
      result.push(this.showChannel[i]);
      for (let j = 0; j < this.showChannel[i].keywordChildren.length; j += 1) {
        result.push(this.showChannel[i].keywordChildren[j]);
      }
    }

    return result;
  }

  constructor() {
    makeObservable(this);
  }

  // > initial

  @action updateSources = (source) => {
    return new Promise((res) => {
      const category = [];
      const website = [];
      const channel = [];

      for (let i = 0; i < source.length; i += 1) {
        category.push(new CheckItemViewModel(source[i]));
        website.push(new CheckGroupItemViewModel(source[i]));
        for (let j = 0; j < source[i].children.length; j += 1) {
          channel.push(new CheckGroupItemViewModel({
            id: source[i].children[j].id,
            name: `${source[i].name}/${source[i].children[j].name}`,
            children: source[i].children[j].children
          }));
        }
      }
      this.category = category;
      this.website = website;
      this.channel = channel;
      res();
    });
  };

  @action setIsShow = (type) => {
    this.isShow = type;
  };

  @action onOpenWithType = (type) => {
    this.isShow = type;
    this.onModalOpen();
  };

  @action setInit = (selectedChannel) => {
    const categorySet = new Set();
    const websiteSet = new Set();
    const channelSet = new Set(selectedChannel);

    for (let i = 0; i < this.channel.length; i += 1) {
      for (let j = 0; j < this.channel[i].children.length; j += 1) {
        if (channelSet.has(this.channel[i].children[j].id)) {
          this.channel[i].children[j].setInit();
          const source = this.channel[i].name.split('/');
          categorySet.add(SOURCE_CATEGORY_TYPE_TC[source[0]]);
          websiteSet.add(source[1]);
        }
      }
    }

    for (let i = 0; i < this.category.length; i += 1) {
      if (categorySet.has(this.category[i].id)) {
        this.category[i].setInit();
      }
    }

    for (let i = 0; i < this.website.length; i += 1) {
      for (let j = 0; j < this.website[i].children.length; j += 1) {
        if (websiteSet.has(this.website[i].children[j].id)) {
          this.website[i].children[j].setInit();
        }
      }
    }
  };

  // > 240118 new for performance issue.
  @action updateSelectedContent = (selectedChannel) => {
    this.onCleanAll();

    this.setInit(selectedChannel);
  };
  // > modal related
  @action initText = (channels) => {
    this.onCleanAll();
    this.setInit(channels);
    this.onSubmit();

    return this.selectedChannelsByCategory.map((item) => `${item.name}(${item.count}), `).join('');
  };

  @action onModalOpen = () => {
    this.isModalOpen = true;
  };

  @action onModalClose = () => {
    this.isModalOpen = false;

    this.categoryKeyword = '';
    this.categoryKeywordCache = '';
    this.websiteKeyword = '';
    this.website.forEach((el) => el.setKeyword(this.websiteKeyword));
    this.channelKeyword = '';
    this.channel.forEach((el) => el.setKeyword(this.channelKeyword));
  };

  @action onSubmit = (cb) => {
    for (let i = 0; i < this.category.length; i += 1) {
      this.category[i].confirmSelect();
    }

    if (this.isShow === 'website' || this.isShow === 'channel') {
      for (let i = 0; i < this.website.length; i += 1) {
        for (let j = 0; j < this.website[i].children.length; j += 1) {
          this.website[i].children[j].confirmSelect();
        }
      }
    }

    if (this.isShow === 'channel') {
      for (let i = 0; i < this.channel.length; i += 1) {
        for (let j = 0; j < this.channel[i].children.length; j += 1) {
          this.channel[i].children[j].confirmSelect();
        }
      }
    }

    // > 240118 new for performance issue.
    if (cb) {
      cb({
        channels: this.selectedChannels,
        textProto: this.selectedChannelsByCategory
      });
    }

    this.onModalClose();
  };

  @action onCancel = () => {
    for (let i = 0; i < this.category.length; i += 1) {
      this.category[i].onDisposeSelect();
    }

    if (this.isShow === 'website' || this.isShow === 'channel') {
      for (let i = 0; i < this.website.length; i += 1) {
        for (let j = 0; j < this.website[i].children.length; j += 1) {
          this.website[i].children[j].onDisposeSelect();
        }
      }
    }

    if (this.isShow === 'channel') {
      for (let i = 0; i < this.channel.length; i += 1) {
        for (let j = 0; j < this.channel[i].children.length; j += 1) {
          this.channel[i].children[j].onDisposeSelect();
        }
      }
    }

    this.onModalClose();
  };

  @action onCleanAll = () => {
    for (let i = 0; i < this.category.length; i += 1) {
      this.category[i].setSelect(false);
    }

    if (this.isShow === 'website' || this.isShow === 'channel') {
      for (let i = 0; i < this.website.length; i += 1) {
        for (let j = 0; j < this.website[i].children.length; j += 1) {
          this.website[i].children[j].setSelect(false);
        }
      }
    }

    if (this.isShow === 'channel') {
      for (let i = 0; i < this.channel.length; i += 1) {
        for (let j = 0; j < this.channel[i].children.length; j += 1) {
          this.channel[i].children[j].setSelect(false);
        }
      }
    }
  };

  // > category
  @action onAllCategorySelect = () => {
    if (!this.isAllCategoryChecked) {
      for (let i = 0; i < this.searchCategory.length; i += 1) {
        this.searchCategory[i].setSelect(true);
        this.afterCategorySelect(this.searchCategory[i], true);
      }
    } else {
      for (let i = 0; i < this.searchCategory.length; i += 1) {
        this.searchCategory[i].setSelect(false);
        this.afterCategorySelect(this.searchCategory[i], false);
      }
    }
  };

  @action onCategoryKeywordChange = (e) => {
    this.categoryKeywordCache = e.target.value;
    if (!e.target.value.trim()) {
      this.onCategorySearchSubmit();
    }
  };


  @action onCategorySearchSubmit = () => {
    // ? note: use computed instead of call api to reduce api calling times.
    this.categoryKeyword = this.categoryKeywordCache;
  };

  @action onCategoryKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onCategorySearchSubmit();
    }
  };

  @action afterCategorySelect = (category, checked) => {
    const target = this.website.find((el) => el.name === category.name);
    if (target && !checked) {
      const selectedItem = target.selectedItem.map((item) => `${target.name}/${item}`);
      const targetWebsites = this.channel.filter((el) => selectedItem.includes(el.name));

      targetWebsites.forEach((el) => el.onAllClean());
      target.onAllClean();
    }
  };

  // > website
  @action onAllWebsiteSelect = () => {
    if (!this.isAllWebsiteChecked) {
      this.showWebsites.forEach((el) => {
        el.children.forEach((w) => {
          w.setSelect(true);
          this.afterWebsiteSelect({
            ...w,
            name: `${el.name}/${w.name}`
          }, true);
        });
      });
    } else {
      this.showWebsites.forEach((el) => {
        el.children.forEach((w) => {
          w.setSelect(false);
          this.afterWebsiteSelect({
            ...w,
            name: `${el.name}/${w.name}`
          }, false);
        });
      });
    }
  };

  @action onWebsiteKeywordChange = (e) => {
    this.websiteKeyword = e.target.value;
    if (!e.target.value.trim()) {
      this.onWebsiteSearchSubmit();
    }
  };

  @action onWebsiteSearchSubmit = () => {
    this.website.forEach((el) => el.setKeyword(this.websiteKeyword));
  };

  @action onWebsiteKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onWebsiteSearchSubmit();
    }
  };

  @action afterWebsiteSelect = (website, checked) => {
    const map = new Map(this.channel.map((item) => [item.name, item]));
    const target = map.get(website.name);
    if (target && !checked) {
      target.onAllClean();
    }
  };

  // > channel

  @action onAllChannelSelect = () => {
    if (!this.isAllChannelChecked) {
      this.showChannel.forEach((el) => {
        el.children.forEach((w) => {
          w.setSelect(true);
        });
      });
    } else {
      this.showChannel.forEach((el) => {
        el.children.forEach((w) => {
          w.setSelect(false);
        });
      });
    }
  };

  @action onChannelKeywordChange = (e) => {
    this.channelKeyword = e.target.value;
    if (!e.target.value.trim()) {
      this.onChannelSearchSubmit();
    }
  };

  @action onChannelSearchSubmit = () => {
    this.channel.forEach((el) => el.setKeyword(this.channelKeyword));
  };

  @action onChannelKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onChannelSearchSubmit();
    }
  };
}
