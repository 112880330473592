import { COLOR_SET } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import { SOURCE_CATEGORY_TYPE } from 'src/consts';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { generateCSVKey } from 'src/utils/chart';

export default class BrandSourceWebsiteProportionModel {
  constructor(data, prevData, query) {
    if (data.length === 0 && prevData.length === 0) {
      this.data = {
        chartData: [{
          id: 'brand_source_website_proportion',
          name: '品牌',
          data: [
            {
              labels: [],
              datasets: [],
              type: 'nodata',
              subType: 'ana-error'
            },
            ...(
              infoHeaderViewModel.multiViewModel.hasCompare
                ? [{
                  labels: [],
                  datasets: [],
                  type: 'nodata',
                  subType: 'ana-error'
                }]
                : []
            )
          ]
        }]
      };
      return;
    }


    const brandList = BrandSourceWebsiteProportionModel.generateTabList(data, prevData);
    const colors = BrandSourceWebsiteProportionModel.generateColor(data, prevData);
    const currTime = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD')).join('~');
    const prevTime = infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD')).join('~');
    const csvForAll = [];

    brandList.forEach((brand) => {
      const targetInData = data.find((el) => brand.id === el.id);
      const sliceItem = targetInData?.item.slice(0, 20) ?? [];
      const targetInPrev = prevData?.find((el) => brand.id === el.id);
      const slicePrevData = targetInPrev?.item.slice(0, 20) ?? [];
      const total = (() => {
        if (!targetInData) {
          return null;
        }
        let sum = 0;
        sliceItem.forEach((c) => {
          sum += c.count;
        });
        return sum;
      })();
      const prevTotal = (() => {
        if (!targetInPrev) {
          return null;
        }
        let sum = 0;
        slicePrevData.forEach((c) => {
          sum += c.count;
        });
        return sum;
      })();
      const csv = BrandSourceWebsiteProportionModel.generateCSV(targetInData, total, targetInPrev, prevTotal);
      const csvKey = generateCSVKey(csv[0]);
      csvForAll.push({
        id: brand.id,
        name: brand.name,
        csv,
        csvKey
      });
    });

    this.data = {
      chartData: brandList.slice(0, 10).map((brand) => {
        const targetInData = data.find((item) => item.id === brand.id);
        const sliceItem = targetInData?.item.slice(0, 20) ?? [];
        const targetInPrevData = prevData.find((item) => item.id === brand.id);
        const slicePrevData = targetInPrevData?.item.slice(0, 20) ?? [];
        const total = (() => {
          if (!targetInData) {
            return null;
          }
          let sum = 0;
          sliceItem.forEach((c) => {
            sum += c.count;
          });
          return sum;
        })();
        const prevTotal = (() => {
          if (!targetInPrevData) {
            return null;
          }
          let sum = 0;
          slicePrevData.forEach((c) => {
            sum += c.count;
          });
          return sum;
        })();

        const { csv, csvKey } = csvForAll.find((el) => el.id === brand.id);

        return {
          id: brand.id,
          name: brand.name,
          data: [
            ...(
              total
                ? [{
                  labels: sliceItem.map((item) => item.name),
                  datasets: [{
                    label: currTime,
                    data: sliceItem.map((item) => item.count),
                    website: sliceItem.map((item) => item.name),
                    backgroundColor: sliceItem.map((item) => colors[item.name]),
                    datalabels: {
                      color: '#fff',
                      formatter: (value) => {
                        const percentage = ((value / total) * 100).toFixed();
                        return percentage > 5 ? `${percentage}%` : null;
                      }
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const percentage = ((context.parsed / total) * 100).toFixed();
                          return ` ${context.parsed}: [${percentage}%]`;
                        }
                      }
                    }
                  }]
                }]
                : [{
                  type: 'nodata',
                  subType: targetInData ? 'pie' : 'ana-error'
                }]
            ),
            ...(
              prevTotal
                ? [{
                  labels: slicePrevData.map((item) => item.name),
                  datasets: [{
                    label: prevTime,
                    data: slicePrevData.map((item) => item.count),
                    website: slicePrevData.map((item) => item.name),
                    backgroundColor: slicePrevData.map((item) => colors[item.name]),
                    datalabels: {
                      color: '#fff',
                      formatter: (value) => {
                        const percentage = ((value / prevTotal) * 100).toFixed();
                        return percentage > 5 ? `${percentage}%` : null;
                      }
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const percentage = ((context.parsed / prevTotal) * 100).toFixed();
                          return ` ${context.parsed}: [${percentage}%]`;
                        }
                      }
                    }
                  }]
                }]
                : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{
                        type: 'nodata',
                        subType: targetInPrevData ? 'pie' : 'ana-error'
                      }]
                      : []
                  )
                ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType,
                category
              } = query;
              const { datasetIndex, index } = elements[0];
              const website = chart.data.datasets[datasetIndex].website[index];
              const [gte, lte] = chart.data.datasets[datasetIndex].label.split('~');
              const womCount = chart.data.datasets[datasetIndex].data[index];

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category && {
                  category: category.map((c) => ({
                    id: c,
                    name: SOURCE_CATEGORY_TYPE[c]
                  }))
                }),
                website: [{
                  id: website,
                  name: website
                }],
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/品牌/來源分析/品牌來源網站/品牌來源網站佔比'
                },
                brand: [{
                  id: brand.id,
                  name: brand.name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'pie',
          options: {
            event: ['click'],
            plugins: {
              legend: {
                position: 'right'
                // display: false
              }
              // htmlLegend: {
              //   containerID: 'brand_source_website_proportion_legend'
              // }
            }
          },
          // plugins: [htmlLegendPlugin],
          csv,
          csvKey
        };
      }),
      csvForAll
    };
  }

  static generateTabList = (list, prevList) => {
    const tab = [];

    list?.forEach((el) => {
      const target = tab.find((item) => item.id === el.id);
      if (!target) {
        tab.push({ id: el.id, name: el.name });
      }
    });
    // prevList.forEach((el) => {
    //   const target = tab.find((item) => item.id === el.id);
    //   if (!target) {
    //     tab.push({ id: el.id, name: el.name });
    //   }
    // });

    return tab;
  };

  static generateColor = (list, prevList) => {
    const colors = {};

    list?.forEach((el) => {
      el.item.forEach((item) => {
        const target = colors[item.name];
        if (!target) {
          colors[`${item.name}`] = COLOR_SET[Object.keys(colors).length % 20];
        }
      });
    });

    prevList?.forEach((el) => {
      el.item.forEach((item) => {
        const target = colors[item.name];
        if (!target) {
          colors[`${item.name}`] = COLOR_SET[Object.keys(colors).length % 20];
        }
      });
    });

    return colors;
  };

  static generateCSV = (curr, currTotal, prev, prevTotal) => {
    const csv = [];

    if (curr) {
      const time = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'));
      const row = {
        日期: time.join('~')
      };
      curr.item.forEach((el) => {
        row[`${el.name}`] = el.count;
        row[`${el.name}-佔比`] = el.count / currTotal;
      });
      csv.push(row);
    }

    if (prev) {
      const time = infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD'));
      const row = {
        日期: time.join('~')
      };
      prev.item.forEach((el) => {
        row[`${el.name}`] = el.count;
        row[`${el.name}-佔比`] = el.count / prevTotal;
      });
      csv.push(row);
    }

    return csv;
  };

  static fromRes(data, prevData, query) {
    return new BrandSourceWebsiteProportionModel(data, prevData, query);
  }
}
