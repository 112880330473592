import React from 'react';
import { makeObservable, observable, action, computed } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

export default class LineChartViewModel {
  type = 'line';
  ref = React.createRef();
  @observable key = '';
  @observable options = {};
  @observable data = {
    labels: [],
    datasets: []
  };
  @observable csv = [];
  @observable csvKey = [];

  constructor(data) {
    makeObservable(this);

    this.init(data);
  }



  @action init = (content) => {
    const {
      name,
      data,
      onPointClick,
      options,
      csv,
      csvKey
    } = content;

    this.key = uuidv4();
    this.data = data;
    this.options = {
      ...options,
      plugins: {
        ...options.plugins,
        legend: {
          ...options.plugins.legend,
          labels: {
            ...options.plugins.legend?.labels,
            color: '#000'
          }
        }
      },
      scales: {
        ...options.scales,
        x: {
          ...options.scales.x,
          title: {
            ...options.scales.x?.title,
            color: '#000'
          },
          ticks: {
            ...options.scales.x?.ticks,
            color: '#000'
          }
        },
        y: {
          ...options.scales.y,
          title: {
            ...options.scales.y?.title,
            color: '#000'
          },
          ticks: {
            ...options.scales.y?.ticks,
            color: '#000'
          }
        },
        ...(options.scales.y1 && {
          y1: {
            ...options.scales.y1,
            title: {
              ...options.scales.y1?.title,
              color: '#000'
            },
            ticks: {
              ...options.scales.y1?.ticks,
              color: '#000'
            }
          }
        })
      },
      maintainAspectRatio: false,
      onClick: (_, elements) => onPointClick(elements, this.ref.current),
      onHover: (event, elements) => {
        const target = event.native.target;
        if (elements.length > 0) {
          target.style.cursor = 'pointer';
        } else {
          target.style.cursor = 'default';
        }
      }
    };
    this.csv = csv;
    this.csvKey = csvKey;
    this.name = name;
  };
}

