import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import { Virtuoso } from 'react-virtuoso';

import i18n from 'src/i18n';

import { CheckOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Checkbox, Tooltip } from 'antd';

import PaperIcon from 'src/assets/PaperIcon.svg';
import SearchIcon from 'src/assets/MenuIcons/SearchIcon.svg';

import CheckItem from './CheckItem';
import FlatCheckItem from './FlatCheckItem';

import styles from './styles.module.scss';

@observer
class SourceSelectModal extends React.Component {
  _renderCategory = (item) => {
    return <CheckItem key={item.id} viewModel={item} afterSelect={this.props.viewModel.afterCategorySelect} />;
  };

  _renderWebsite = (item) => {
    return <FlatCheckItem key={item.id} viewModel={item} afterSelect={this.props.viewModel.afterWebsiteSelect} needItem />;
  };

  _renderChannel = (item) => {
    return <FlatCheckItem key={item.id} viewModel={item} />;
  };

  render() {
    const { viewModel, onSubmitCallback } = this.props;
    return (
      <Modal
        open={viewModel.isModalOpen}
        closable={false}
        centered
        onCancel={viewModel.onCancel}
        className="selectModal"
        width={viewModel.modalSize}
        footer={null}
      >
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <div className={styles.headerTitle}>
              <img src={PaperIcon} alt="paper" className={styles.titleIcon} />
              <div className={styles.titleText}>
                {i18n.t('bulletin_drawer_sources_header')}
              </div>
              <Tooltip
                title="來源設定必須選擇頻道項目"
                placement="bottom"

              >
                <QuestionCircleOutlined className={styles.icon} />
              </Tooltip>
            </div>
            <div className={styles.headerClose}>
              <Button
                icon={<CloseOutlined />}
                type="link"
                className={styles.headerClose}
                onClick={viewModel.onCancel}
              >
                {i18n.t('package_modal_close')}
              </Button>
            </div>
          </div>
          <div className={styles.modalContent}>
            <div className={styles.selectCard}>
              <div className={styles.cardHeader}>
                <div className={styles.cardTitle}>
                  {i18n.t('source_category_title')}
                </div>
                <div className={styles.cardSelectAll}>
                  <Checkbox
                    indeterminate={viewModel.categoryIndeterminate}
                    checked={viewModel.isAllCategoryChecked}
                    onChange={viewModel.onAllCategorySelect}

                  />
                  <div className={styles.selectAllText}>
                    {i18n.t('package_modal_select_all')}
                  </div>
                </div>
              </div>
              <div className={styles.cardContent}>
                <div className={styles.searchInputContainer}>
                  <input
                    className={styles.searchInput}
                    placeholder={i18n.t('package_modal_input_placeholder')}
                    value={viewModel.categoryKeywordCache}
                    onChange={viewModel.onCategoryKeywordChange}
                    onKeyDown={viewModel.onCategoryKeyDown}
                  />
                  <div
                    className={styles.searchBtn}
                    onClick={viewModel.onCategorySearchSubmit}
                  >
                    <img src={SearchIcon} alt="" />
                  </div>
                </div>
                <div className={styles.levelCheckboxContainer}>
                  <Virtuoso
                    data={viewModel.searchCategory}
                    style={{ height: 188 }}
                    itemContent={(index, item) => this._renderCategory(item)}
                  />
                </div>
              </div>
            </div>
            {
              (viewModel.isShow === 'website' || viewModel.isShow === 'channel')
              && (
                <div className={styles.selectCard}>
                  <div className={styles.cardHeader}>
                    <div className={styles.cardTitle}>
                      {i18n.t('source_website_title')}
                    </div>
                    <div className={styles.cardSelectAll}>
                      <Checkbox
                        indeterminate={viewModel.websiteIndeterminate}
                        checked={viewModel.isAllWebsiteChecked}
                        onChange={viewModel.onAllWebsiteSelect}
                      />
                      <div className={styles.selectAllText}>
                        {i18n.t('package_modal_select_all')}
                      </div>
                    </div>
                  </div>
                  <div className={styles.cardContent}>
                    <div className={styles.searchInputContainer}>
                      <input
                        className={styles.searchInput}
                        placeholder={i18n.t('package_modal_input_placeholder')}
                        value={viewModel.websiteKeyword}
                        onChange={viewModel.onWebsiteKeywordChange}
                        onKeyDown={viewModel.onWebsiteKeyDown}
                      />
                      <div
                        className={styles.searchBtn}
                        onClick={viewModel.onWebsiteSearchSubmit}
                      >
                        <img src={SearchIcon} alt="" />
                      </div>
                    </div>
                    <div className={styles.levelCheckboxContainer}>
                      <Virtuoso
                        data={viewModel.flatWebsites}
                        style={{ height: 188 }}
                        itemContent={(index, item) => this._renderWebsite(item)}
                      />
                    </div>
                  </div>
                </div>
              )
            }
            {
              viewModel.isShow === 'channel'
              && (
                <div className={styles.selectCard}>
                  <div className={styles.cardHeader}>
                    <div className={styles.cardTitle}>
                      {i18n.t('source_channel_title')}
                    </div>
                    <div className={styles.cardSelectAll}>
                      <Checkbox
                        indeterminate={viewModel.channelIndeterminate}
                        checked={viewModel.isAllChannelChecked}
                        onChange={viewModel.onAllChannelSelect}
                      />
                      <div className={styles.selectAllText}>
                        {i18n.t('package_modal_select_all')}
                      </div>
                    </div>
                  </div>
                  <div className={styles.cardContent}>
                    <div className={styles.searchInputContainer}>
                      <input
                        className={styles.searchInput}
                        placeholder={i18n.t('package_modal_input_placeholder')}
                        value={viewModel.channelKeyword}
                        onChange={viewModel.onChannelKeywordChange}
                        onKeyDown={viewModel.onChannelKeyDown}
                      />
                      <div
                        className={styles.searchBtn}
                        onClick={viewModel.onChannelSearchSubmit}
                      >
                        <img src={SearchIcon} alt="" />
                      </div>
                    </div>
                    <div className={styles.levelCheckboxContainer}>
                      <Virtuoso
                        data={viewModel.flatChannel}
                        style={{ height: 188 }}
                        itemContent={(index, item) => this._renderChannel(item)}
                      />
                    </div>
                  </div>
                </div>
              )
            }

          </div>
          <div className={styles.modalFooter}>
            <div className={styles.btnContainer}>
              <Button
                className={clsx(styles.btn, styles.cleanBtn)}
                icon={<CloseOutlined />}
                onClick={viewModel.onCleanAll}
              >
                {i18n.t('package_modal_clean')}
              </Button>
              <Button
                className={clsx(styles.btn, styles.applyBtn)}
                icon={<CheckOutlined />}
                type="primary"
                onClick={() => viewModel.onSubmit(onSubmitCallback)}
              >
                {i18n.t('package_modal_apply')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

SourceSelectModal.propTypes = {
  viewModel: PropTypes.object,
  onSubmitCallback: PropTypes.func
};

SourceSelectModal.defaultProps = {
  viewModel: {},
  onSubmitCallback: () => { }
};

export default SourceSelectModal;
