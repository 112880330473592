import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
);


@observer
class BarChart extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <div style={{ width: '100%', display: 'flex', aspectRatio: 3 }}>
        <Bar key={viewModel.key} options={viewModel.options} data={viewModel.data} ref={viewModel.ref} />
      </div>
    );
  }
}

BarChart.propTypes = {
  viewModel: PropTypes.object
};

BarChart.defaultProps = {
  viewModel: {}
};


export default BarChart;
