import { request, getHost, getHeaders } from '../utils';

export const volume = {
  getBrandVolumeTrend: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/brand/volume/trend`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getBrandVolumeCount: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/brand/volume/count`,
      headers: getHeaders(),
      data
    };
    return request(options);
  }
};

export const source = {
  category: {
    getBrandSourceCategoryTrend: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/brand/source/category/trend`,
        headers: getHeaders(),
        data
      };
      return request(options);
    },
    getBrandSourceCategoryCount: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/brand/source/category/count`,
        headers: getHeaders(),
        data
      };
      return request(options);
    }
  },
  website: {
    getBrandSourceWebsiteTrend: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/brand/source/website/trend`,
        headers: getHeaders(),
        data
      };
      return request(options);
    },
    getBrandSourceWebsiteCount: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/brand/source/website/count`,
        headers: getHeaders(),
        data
      };
      return request(options);
    }
  },
  channel: {
    getBrandSourceChannelTrend: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/brand/source/channel/trend`,
        headers: getHeaders(),
        data
      };
      return request(options);
    },
    getBrandSourceChannelCount: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/brand/source/channel/count`,
        headers: getHeaders(),
        data
      };
      return request(options);
    }
  },
  getBrandSocialActivity: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/brand/source/activity`,
      headers: getHeaders(),
      data
    };
    return request(options);
  }
};

export const sentiment = {
  getSentimentTrend: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/brand/sentiment/trend`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getSentimentCount: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/brand/sentiment/count`,
      headers: getHeaders(),
      data
    };
    return request(options);
  }
};

export const keyword = {
  getBrandHotWords: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/brand/term/hot`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getBrandHotWordsDiscover: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/brand/term/discover`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getBrandNewWords: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/brand/term/new`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getBrandTrendWords: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/brand/term/trending`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getBrandHotWordsAssoc: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/brand/term/assoc`,
      headers: getHeaders(),
      data
    };
    return request(options);
  }
};
