import API from 'src/apis';
import AdvSearchModel from 'src/models/response/charts/advanceSearch';
import packageStore from 'src/stores/packageStore';

export default class AdvancedSearchService {
  static getPackageId = () => {
    return packageStore.activePackageId;
  };

  static async getChart(chartType, data, prevData, tagKeyName) {
    switch (chartType) {
      case 'trend':
        return AdvancedSearchService.getCrossTrend(data, prevData, tagKeyName);
      case 'ranking':
        return AdvancedSearchService.getCrossRanking(data, prevData, tagKeyName);
      case 'proportion':
        return AdvancedSearchService.getCrossProportion(data, prevData, tagKeyName);
      case 'compare':
        return AdvancedSearchService.getCrossCompare(data, prevData, tagKeyName);
      default:
        return {};
    }
  }

  static async getCrossTrend(data, prevData, tagKeyName) {
    const [res, prevRes] = await Promise.all([
      API.advancedSearch.getAdvancedResearchTrend(AdvancedSearchService.getPackageId(), data),
      prevData
        ? API.advancedSearch.getAdvancedResearchTrend(AdvancedSearchService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return AdvSearchModel.AdvSearchTrendModel.fromRes(res.data.result, prevRes.data.result, { ...data, tagKeyName });
  }

  static async getCrossRanking(data, prevData, tagKeyName) {
    const [res, prevRes] = await Promise.all([
      API.advancedSearch.getAdvancedResearchCount(AdvancedSearchService.getPackageId(), data),
      prevData
        ? API.advancedSearch.getAdvancedResearchCount(AdvancedSearchService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return AdvSearchModel.AdvSearchRankingModel.fromRes(res.data.result, prevRes.data.result, { ...data, tagKeyName });
  }

  static async getCrossProportion(data, prevData, tagKeyName) {
    const [res, prevRes] = await Promise.all([
      API.advancedSearch.getAdvancedResearchCount(AdvancedSearchService.getPackageId(), data),
      prevData
        ? API.advancedSearch.getAdvancedResearchCount(AdvancedSearchService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return AdvSearchModel.AdvSearchProportionModel.fromRes(res.data.result, prevRes.data.result, { ...data, tagKeyName });
  }

  static async getCrossCompare(data, prevData, tagKeyName) {
    const [res, prevRes] = await Promise.all([
      API.advancedSearch.getAdvancedResearchStack(AdvancedSearchService.getPackageId(), data),
      prevData
        ? API.advancedSearch.getAdvancedResearchStack(AdvancedSearchService.getPackageId(), prevData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return AdvSearchModel.AdvSearchCompareModel.fromRes(res.data.result, prevRes.data.result, { ...data, tagKeyName });
  }

}
