import { COLOR_SET } from 'src/consts/chart';
import packageStore from 'src/stores/packageStore';
import { getHost } from 'src/utils';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import { generateCSVKey, htmlLegendPlugin } from 'src/utils/chart';

export default class ProductRadarModel {
  constructor(origin, previousOrigin, query) {
    const {
      postType,
      womType,
      category
    } = query;

    const features = [];

    origin.forEach((el) => {
      el.item.forEach((item) => {
        const target = features.find((feature) => feature.id === item.id);
        if (!target) {
          features.push({ id: item.id, name: item.name });
        }
      });
    });

    for (let i = features.length; i < 5; i += 1) {
      features.push({
        id: '',
        name: ''
      });
    }

    // > GENERATE LEVEL ITEMS
    const levelItems = origin.map((el, i) => ({
      id: el.id,
      name: el.name,
      color: COLOR_SET[i % 20]
    }));

    previousOrigin.forEach((el) => {
      const target = levelItems.find((b) => b.id === el.id);
      if (!target) {
        levelItems.push({
          id: el.id,
          name: el.name,
          color: COLOR_SET[levelItems.length % 20]
        });
      }
    });

    // > GENERATE colors
    const color = {};
    levelItems.forEach((el) => {
      color[`${el.id}`] = el.color;
    });

    // > GENERATE CHART DATA
    const data = origin.map((el) => {
      const row = {
        id: el.id,
        name: el.name,
        item: []
      };
      features.forEach((feature) => {
        const target = el.item.find((item) => item.id === feature.id);
        row.item.push({ id: feature.id, name: feature.name, count: target?.count ?? 0 });
      });

      return row;
    });


    const previousData = previousOrigin.map((el) => {
      const row = {
        id: el.id,
        name: el.name,
        item: []
      };
      features.forEach((feature) => {
        const target = el.item.find((item) => item.id === feature.id);
        row.item.push({ id: feature.id, name: feature.name, count: target?.count ?? 0 });
      });

      return row;
    });

    // > generate csv
    const csv = [];
    if (data.length > 0 && previousData.length > 0) {
      levelItems.forEach((b) => {
        const inPresent = data.find((el) => el.id === b.id);
        const inPrevious = previousData.find((el) => el.id === b.id);
        if (inPresent) {
          const row = {
            名稱: `${inPresent.name}-當期`
          };
          features.forEach((feature) => {
            const target = inPresent.item.find((item) => item.id === feature.id);
            row[`${feature.name}`] = target?.count ?? 0;
          });
          csv.push(row);
        }
        if (inPrevious) {
          const row = {
            名稱: `${inPrevious.name}-前期`
          };
          features.forEach((feature) => {
            const target = inPrevious.item.find((item) => item.id === feature.id);
            row[`${feature.name}`] = target?.count ?? 0;
          });
          csv.push(row);
        }
      });
    } else {
      data.forEach((el) => {
        const row = {
          名稱: el.name
        };
        features.forEach((feature) => {
          const target = el.item.find((item) => item.id === feature.id);
          row[`${feature.name}`] = target?.count ?? 0;
        });
        csv.push(row);
      });
    }

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'product_word_radar',
          name: '產品雷達圖',
          data: [
            ...(
              !data.every((el) => el.item.every((item) => item.count === 0))
                ? [{
                  labels: features.map((el) => el.name),
                  datasets: data.slice(0, 10).map((el, i) => ({
                    label: el.name,
                    product: { id: el.id, name: el.name },
                    features,
                    data: el.item.map((f) => f.count),
                    total: (() => {
                      let sum = 0;
                      el.item.forEach((f) => {
                        sum += f.count;
                      });
                      return sum;
                    })(),
                    borderColor: color[el.id],
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const { formattedValue, dataset, label: featureName, raw } = context;
                          const { total: t, label } = dataset;
                          const percentage = t ? Math.round((raw / t) * 1000) / 10 : 0;
                          if (!featureName.trim()) {
                            return '';
                          }
                          return `${label} - ${featureName}: ${formattedValue}, 佔比: ${percentage}%`;
                        }
                      }
                    }
                  }))
                }]
                : [{ type: 'nodata' }]
            ),
            ...(
              !previousData.every((el) => el.item.every((item) => item.count === 0))
                ? [{
                  labels: features.map((el) => el.name),
                  datasets: previousData.slice(0, 10).map((el, i) => ({
                    label: el.name,
                    timeSet: 'previous',
                    brand: { id: el.id, name: el.name },
                    features,
                    data: el.item.map((f) => f.count),
                    total: (() => {
                      let sum = 0;
                      el.item.forEach((f) => {
                        sum += f.count;
                      });
                      return sum;
                    })(),
                    borderColor: color[el.id],
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const { formattedValue, dataset, label: featureName, raw } = context;
                          const { total: t, label } = dataset;
                          const percentage = t ? Math.round((raw / t) * 1000) / 10 : 0;
                          if (!featureName.trim()) {
                            return '';
                          }
                          return `${label} - ${featureName}: ${formattedValue}, 佔比: ${percentage}%`;
                        }
                      }
                    }
                  }))
                }]
                : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{ type: 'nodata' }]
                      : []
                  )
                ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const feature = chart.data.datasets[datasetIndex].features[index];
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const product = chart.data.datasets[datasetIndex].product;
              const [gte, lte] = chart.data.datasets[datasetIndex].timeSet === 'present'
                ? infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'))
                : infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD'));

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category ? {
                  category: category.map((el) => ({
                    id: el,
                    name: SOURCE_CATEGORY_TYPE[el]
                  }))
                } : {
                  category: SOURCE_CATEGORY.map((el) => ({
                    id: el.value,
                    name: el.label
                  }))
                }),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '分類詞庫/產品/雷達圖'
                },
                featureCategory: {
                  id: feature.id,
                  name: feature.name
                },
                product: [{
                  id: product.id,
                  name: product.name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'radar',
          options: {
            responsive: true,
            event: ['click'],
            scales: {
              r: {
                angleLines: {
                  display: false
                },
                ticks: {
                  display: false
                }
              }
            },
            plugins: {
              legend: {
                display: false
              },
              datalabels: {
                display: false
              }
            }
          },
          plugins: [htmlLegendPlugin],
          csv,
          csvKey
        }
      ]
    };
  }

  static fromRes(data, previousOrigin, query) {
    return new ProductRadarModel(data, previousOrigin, query);
  }
}
