import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import { CSVLink } from 'react-csv';

import { CheckOutlined, CaretDownOutlined, CloseOutlined, LineChartOutlined, BarChartOutlined, PieChartOutlined, DownloadOutlined, SaveOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import Select from 'src/components/select';
import Cascader from 'src/components/cascader';
import { POST_TYPE, WORD_OF_MOUTH_TYPE } from 'src/consts';
import EditIcon from 'src/assets/Bulletin/EditIcon.svg';
import SourceSelectModal from 'src/components/SourceSelectModal';
import InfoHeader from 'src/components/InfoHeader';
import optionsStore from 'src/stores/optionsStore';
import packageStore from 'src/stores/packageStore';

import SingleClassModal from './components/SingleClassModal';
import SingleSelectModal from './components/SingleSelectModal';
import AdvanceSearchViewModel from './viewModel';
import ChartItem from './components/ChartItem';

import styles from './styles.module.scss';

@observer
class AdvanceSearchPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new AdvanceSearchViewModel();
  }

  componentDidMount() {
    this.viewModel.didMount();
  }

  componentWillUnmount() {
    this.viewModel.willUnmount();
  }

  render() {
    return (
      <>
        <InfoHeader
          breadcrumb={['交叉分析']}
          isMultiPeriod
          callback={this.viewModel.getChart}
        />
        <div className={styles.pageContainer}>
          <div className={styles.settingContainer}>
            <div className={styles.crossSettingContainer}>
              {
                packageStore.isBrandAvailable
                && (
                  <div className={styles.suggestContainer}>
                    <div className={styles.suggestContent}>
                      <div className={styles.suggestTitle}>
                        {i18n.t('advance_search_suggestions')}
                      </div>
                      {
                        packageStore.isSeriesAvailable
                        && (
                          <Button
                            className={styles.suggestBtn}
                            onClick={this.viewModel.onBrandxSeriesClick}
                          >
                            {i18n.t('advance_search_brand_series')}
                          </Button>
                        )
                      }
                      {
                        packageStore.isProductAvailable
                        && (
                          <Button
                            className={styles.suggestBtn}
                            onClick={this.viewModel.onBrandxProduct}
                          >
                            {i18n.t('advance_search_brand_product')}
                          </Button>
                        )
                      }
                      <Button
                        className={styles.suggestBtn}
                        onClick={this.viewModel.onBrandxForumChannel}
                      >
                        {i18n.t('advance_search_brand_forum_channel')}
                      </Button>

                      <Button
                        className={styles.suggestBtn}
                        onClick={this.viewModel.onBrandxFbChannel}
                      >
                        {i18n.t('advance_search_brand_fb_channel')}
                      </Button>
                    </div>

                    <div className={styles.suggestNote}>
                      {i18n.t('advance_search_suggest_note')}
                    </div>
                  </div>
                )
              }
              <div className={styles.settingContent}>
                <div className={styles.settingTitle}>
                  {i18n.t('advance_search_cross_setting')}
                </div>
                <div className={styles.optionRow}>
                  <Cascader
                    options={this.viewModel.disabledSelectedOptions}
                    placeholder={i18n.t('advance_search_cross_setting_option_one')}
                    suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                    size="large"
                    style={{ width: 240 }}
                    className={styles.select}
                    displayRender={(label) => `${i18n.t('advance_search_cross_setting_option_one')}：${label[label.length - 1]}`}
                    onChange={this.viewModel.onOptionOneSelect}
                    value={this.viewModel.selectedOne}
                    allowClear={false}
                  />
                  {
                    this.viewModel.selectedOne
                    && (
                      <div
                        className={styles.paramsContainer}
                        onClick={() => this.viewModel.onSelectedModalOpen(this.viewModel.selectedOneId, 'one')}
                      >
                        <div className={styles.paramsText}>
                          {`${this.viewModel.selectedOneText}已選擇：${this.viewModel.paramsOneText.slice(0, 200)}`}
                        </div>
                        <img src={EditIcon} alt="" />
                      </div>
                    )
                  }

                </div>
                <div className={styles.optionRow}>
                  <Cascader
                    options={this.viewModel.disabledSelectedOptions}
                    placeholder={i18n.t('advance_search_cross_setting_option_two')}
                    suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                    size="large"
                    style={{ width: 240 }}
                    className={styles.select}
                    displayRender={(label) => `${i18n.t('advance_search_cross_setting_option_two')}：${label[label.length - 1]}`}
                    onChange={this.viewModel.onOptionTwoSelect}
                    value={this.viewModel.selectedTwo}
                    allowClear={false}
                  />
                  {
                    this.viewModel.selectedTwo
                    && (
                      <div
                        className={styles.paramsContainer}
                        onClick={() => this.viewModel.onSelectedModalOpen(this.viewModel.selectedTwoId, 'two')}
                      >
                        <div className={styles.paramsText}>
                          {`${this.viewModel.selectedTwoText}已選擇：${this.viewModel.paramsTwoText.slice(0, 200)}`}
                        </div>
                        <img src={EditIcon} alt="" />
                      </div>
                    )
                  }
                </div>

                <div className={styles.btnsContainer}>
                  <Button
                    className={styles.actionsBtn}
                    onClick={this.viewModel.onClean}
                  >
                    {i18n.t('advance_search_cross_setting_clean_setting')}
                  </Button>
                  <Button
                    className={styles.actionsBtn}
                    icon={<CheckOutlined />}
                    onClick={this.viewModel.onSubmit}
                  >
                    {i18n.t('advance_search_cross_setting_submit')}
                  </Button>
                </div>

              </div>
            </div>

          </div>
          {
            this.viewModel.selectedSubType
            && (
              <div className={styles.resContainer}>
                <div className={styles.titleContainer}>
                  {`${this.viewModel.selectedOneTextCache} x ${this.viewModel.selectedTwoTextCache}`}
                </div>
                <div className={styles.statisticFilter}>
                  <div className={styles.filterTitle}>
                    {i18n.t('chart_page_statistic_filter')}
                  </div>
                  <Select
                    placeholder={i18n.t('filter_post_type_all')}
                    options={POST_TYPE}
                    value={this.viewModel.selectedPostType}
                    onSelect={this.viewModel.onPostTypeChange}
                    popupMatchSelectWidth={false}
                    popupClassName={styles.sortMenu}
                    className={clsx('sortSelect', styles.filterSelect)}
                    suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                  />
                  <Select
                    placeholder={i18n.t('filter_word_of_mouth_title')}
                    options={WORD_OF_MOUTH_TYPE}
                    value={this.viewModel.selectedWOMType}
                    onSelect={this.viewModel.onWOMTypeChange}
                    popupMatchSelectWidth={false}
                    popupClassName={styles.sortMenu}
                    className={clsx('sortSelect', styles.filterSelect)}
                    suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                  />
                  <Button
                    className={clsx(styles.btn, styles.submitBtn)}
                    icon={<CheckOutlined />}
                    onClick={this.viewModel.getChart}
                  >
                    {i18n.t('btn_filter_submit')}
                  </Button>
                  {
                    this.viewModel.isShowStatisticClean
                    && (
                      <Button
                        className={clsx(styles.btn, styles.cleanBtn)}
                        icon={<CloseOutlined />}
                        type="link"
                        onClick={this.viewModel.onCleanStatisticFilter}
                      >
                        {i18n.t('btn_filter_clean_all')}
                      </Button>
                    )
                  }
                </div>
                <div className="download-chart">
                  <div className={styles.chartHeader}>
                    <div className={styles.chartTitle}>
                      <span className={styles.titleText}>
                        {this.viewModel.chartName}
                      </span>
                      <span className={styles.tooltip} style={{ display: optionsStore.isImgDownload ? 'none' : 'inline-block' }}>
                        <Tooltip
                          title={(
                            <div className={styles.descContainer}>
                              {'在時間區間內，X軸與Y軸交叉之圖表\n。X軸與Y軸的交叉項目不能相同。'}
                            </div>
                          )}
                          placement="rightTop"
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                      </span>
                    </div>
                    <div className={clsx(styles.buttonsContainer, optionsStore.isImgDownload && styles.displayNone)}>
                      <Tooltip
                        title="下載數據"
                        placement="bottom"
                      >
                        <Button
                          icon={<DownloadOutlined />}
                          onClick={this.viewModel.onCSVDownload}
                        />
                      </Tooltip>
                      <Tooltip
                        title="下載圖表"
                        placement="bottom"
                      >
                        <Button
                          icon={<SaveOutlined />}
                          onClick={this.viewModel.onDownload}
                          className={styles.saveBtn}
                        />
                      </Tooltip>
                      <Tooltip
                        title="趨勢圖"
                        placement="bottom"
                      >
                        <Button
                          icon={<div className={styles.line} />}
                          onClick={() => this.viewModel.onSubChartTypeChange('trend')}
                          className={this.viewModel.selectedSubType === 'trend' ? styles.active : styles.inactive}
                        />
                      </Tooltip>
                      <Tooltip
                        title="長條圖"
                        placement="bottom"
                      >
                        <Button
                          icon={<div className={styles.bar} />}
                          onClick={() => this.viewModel.onSubChartTypeChange('ranking')}
                          className={this.viewModel.selectedSubType === 'ranking' ? styles.active : styles.inactive}
                        />
                      </Tooltip>
                      <Tooltip
                        title="圓餅圖"
                        placement="bottom"
                      >
                        <Button
                          icon={<div className={styles.pie} />}
                          onClick={() => this.viewModel.onSubChartTypeChange('proportion')}
                          className={this.viewModel.selectedSubType === 'proportion' ? styles.active : styles.inactive}
                        />
                      </Tooltip>
                      <Tooltip
                        title="堆疊圖"
                        placement="bottom"
                      >
                        <Button
                          icon={<div className={styles.compare} />}
                          onClick={() => this.viewModel.onSubChartTypeChange('compare')}
                          className={clsx(this.viewModel.selectedSubType === 'compare' ? styles.active : styles.inactive, styles.compare)}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <ChartItem
                    viewModel={this.viewModel.showChart}
                  />
                </div>
              </div>
            )
          }

        </div>
        <SingleClassModal viewModel={this.viewModel.singleSelectedModal} />
        <SingleSelectModal viewModel={this.viewModel.seriesViewModel} />
        <SourceSelectModal viewModel={this.viewModel.sourceSelectModalVmOne} />
        <SourceSelectModal viewModel={this.viewModel.sourceSelectModalVmTwo} />
      </>
    );
  }
}

AdvanceSearchPage.propTypes = {

};

AdvanceSearchPage.defaultProps = {

};


export default AdvanceSearchPage;
