import { SOURCE_CATEGORY_TYPE } from 'src/consts';

export default class OpinionModel {
  constructor(data) {
    const {
      category,
      website,
      channel,
      post,
      summary
    } = data;

    this.category = category.map((el) => ({
      id: el.name,
      name: SOURCE_CATEGORY_TYPE[el.name],
      count: el.count
    }));

    this.website = website.map((el) => ({
      id: el.name,
      name: el.name,
      count: el.count
    }));

    this.channel = channel.map((el) => ({
      id: el.id,
      name: el.name,
      count: el.count
    }));

    this.post = post.map((el) => ({
      vocKey: el.vocKey,
      vocTitle: el.title,
      website: el.website,
      channel: el.channel,
      author: el.author,
      date: el.date,
      content: el.content,
      viewCount: el.viewCount,
      shareCount: el.shareCount,
      commentCount: el.commentCount,
      likeCount: el.likeCount,
      replyId: el.replyId < 99999 ? el.replyId : '---',
      url: el.url
    }));

    this.summary = summary.totalCount;
  }

  static fromRes(data) {
    return new OpinionModel(data);
  }
}
