export const Action = {
  UpdateProfile: 'UpdateProfile'
};

export const actions = (dispatch) => ({
  updateProfile: (profile) => dispatch({
    type: Action.UpdateProfile,
    payload: profile
  })
});
