import BrandVolumeTrendModel from './BrandVolumeTrend';
import BrandSeriesWGSModel from './BrandSeriesWGS';
import BrandProductVolumeRankingModel from './BrandProductVolumeRanking';
import BrandSourceCategoryProportionModel from './BrandSourceCategoryProportion';
import BrandSourceChannelRankingModel from './BrandSourceChannelRanking';
import BrandSentimentOverviewModel from './BrandSentimentOverview';
import BrandSentimentWordModel from './BrandSentimentWord';
import BrandConsumerFeature from './BrandConsumerFeature';
import BrandTopsFeatureWordModel from './BrandTopsFeatureWord';

export default {
  BrandVolumeTrendModel,
  BrandSeriesWGSModel,
  BrandProductVolumeRankingModel,
  BrandSourceCategoryProportionModel,
  BrandSourceChannelRankingModel,
  BrandSentimentOverviewModel,
  BrandSentimentWordModel,
  BrandConsumerFeature,
  BrandTopsFeatureWordModel
};
