export default class SelectedLevelModel {
  constructor(data) {
    if (!data) {
      this.brand = [];
      this.series = [];
      this.product = [];
    } else {
      const {
        searchKeywordLevel1Ids,
        searchTags,
        searchKeywordLevel3Ids
      } = data;

      this.brand = [...searchKeywordLevel1Ids];
      this.series = searchTags.map((el) => el.id);
      this.product = [...searchKeywordLevel3Ids];
    }
  }

  static fromRes(data) {
    return new SelectedLevelModel(data);
  }
}
