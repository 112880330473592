import { request, getHost, getHeaders } from './utils';

export const getBulletins = (pid, params) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/dataPackage/${pid}/notification`,
    headers: getHeaders(),
    params: {
      ...params
    }
  };
  return request(options);
};

export const createBulletins = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/dataPackage/${pid}/notification`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBulletinInfo = (pid, bid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/dataPackage/${pid}/notification/${bid}`,
    headers: getHeaders()
  };
  return request(options);
};

export const updateBulletin = (pid, bid, data) => {
  const options = {
    method: 'put',
    url: `${getHost()}/api/v1/dataPackage/${pid}/notification/${bid}`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const deleteBulletin = (pid, bid) => {
  const options = {
    method: 'delete',
    url: `${getHost()}/api/v1/dataPackage/${pid}/notification/${bid}`,
    headers: getHeaders()
  };
  return request(options);
};

export const sendBulletin = (pid, bid, frequency) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/dataPackage/${pid}/notification/${bid}/send`,
    headers: getHeaders(),
    params: {
      frequency
    }
  };
  return request(options);
};
