import { COLOR_SET, AVG_LINE_COLOR } from 'src/consts/chart';
import packageStore from 'src/stores/packageStore';
import { getHost } from 'src/utils';
import { encodeURI } from 'js-base64';
import { generateCSVKey } from 'src/utils/chart';

export default class StarProductModel {
  constructor(origin, query, params) {
    const { overview, product, tag } = origin;

    const {
      womType,
      tagKey
    } = query;

    if (overview.length === 0) {
      this.volumeTotal = {
        chartData: [{
          id: 'product_word_radar',
          name: '產品雷達圖',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }],
          type: 'nodata'
        }]
      };
    } else {
      const overviewTotal = (() => {
        let total = 0;
        overview.forEach((el) => {
          total += el.count;
        });
        return total;
      })();

      overview.sort((a, b) => b.count - a.count);

      const overviewCsv = overview.map((el) => ({
        品牌名稱: el.name,
        聲量: el.count,
        佔比: `${(Math.floor((el.count / overviewTotal) * 1000) / 10)} %`
      }));

      const overviewCsvKey = generateCSVKey(overviewCsv[0]);

      this.volumeTotal = {
        chartData: [{
          id: 'brand_volume_overview',
          name: '品牌聲量總覽',
          data: [{
            labels: overview.map((el) => el.name),
            datasets: [
              {
                label: '佔比',
                data: overview.map((el) => Math.floor((el.count / overviewTotal) * 100)),
                backgroundColor: AVG_LINE_COLOR,
                borderColor: AVG_LINE_COLOR,
                type: 'line',
                yAxisID: 'y1'
              },
              {
                label: '聲量',
                data: overview.map((el) => el.count),
                brand: overview.map((el) => ({ id: el.id, name: el.name })),
                backgroundColor: COLOR_SET[0],
                borderSkipped: true,
                categoryPercentage: 0.2,
                yAxisID: 'y'
              }
            ]
          }],
          onPointClick: (elements, chart, _) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const brand = chart.data.datasets[datasetIndex].brand[index];
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const { start_date: gte, finish_date: lte } = params;

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '進階分析/明星商品佔比/品牌聲量總覽'
                },
                brand: [{
                  id: brand.id,
                  name: brand.name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'bar',
          options: {
            event: ['click'],
            tension: 0.4,
            scales: {
              y: {
                type: 'linear',
                display: true,
                position: 'left',
                grace: '20%',
                title: {
                  display: true,
                  text: '聲量'
                }
              },
              y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grace: '20%',
                title: {
                  display: true,
                  text: '佔比'
                }
              }
            },
            plugins: {
              legend: {
                display: false
              },
              datalabels: {
                display: false
              }
            }
          },
          csv: overviewCsv,
          csvKey: overviewCsvKey
        }]
      };
    }

    if (product.length === 0) {
      this.top3Product = {
        chartData: [{
          id: 'brand_product_volume_compare',
          name: 'TOP1~3產品佔品牌總聲量的占比圖',
          data: [{
            labels: [],
            datasets: []
          }],
          type: 'nodata'
        }]
      };
    } else {
      const productColors = {};

      const formatProductData = product.map((brand) => ({
        ...brand,
        item: brand.item.map((item) => {
          const color = productColors[item.name];
          if (!color) {
            productColors[`${item.name}`] = COLOR_SET[Object.keys(productColors).length % 20];
          }
          return {
            id: item.id,
            name: item.name,
            count: item.count,
            color: productColors[item.name]
          };
        })
      }));

      const topProductCsv = product.map((el) => {
        const row = {
          品牌名稱: el.name
        };

        el.item.forEach((item) => {
          row[`${item.name}`] = item.count;
        });

        return row;
      });

      const topKey = generateCSVKey(topProductCsv[0]);

      this.top3Product = {
        chartData: [
          {
            id: 'brand_source_channel_compare',
            name: 'TOP1~3產品佔品牌總聲量的占比圖',
            data: [formatProductData],
            onPointClick: (elements, chart, _) => {
              if (elements.length > 0) {
                const { datasetIndex, index } = elements[0];
                const productItem = chart.data.datasets[datasetIndex].item;

                if (productItem.id === '其他') {
                  return;
                }

                const brandItem = chart.data.datasets[datasetIndex].mainItem[index];
                const womCount = chart.data.datasets[datasetIndex].data[index];
                const { start_date: gte, finish_date: lte } = params;


                const topicsResult = {
                  ...(womType && {
                    womType
                  }),
                  packageContent: {
                    id: packageStore.activePackageId,
                    name: packageStore.activePackageName
                  },
                  chart: {
                    name: '進階分析/明星商品佔比/TOP1~3產品佔品牌總聲量的占比圖'
                  },
                  brand: [{
                    id: brandItem.id,
                    name: brandItem.name
                  }],
                  product: [{
                    id: productItem.id,
                    name: productItem.name
                  }],
                  womCount,
                  date: {
                    gte,
                    lte: lte ?? gte
                  }
                };

                const json = JSON.stringify(topicsResult);
                const result = encodeURI(json);
                const url = `${getHost()}/topics-result?result=${result}`;
                window.open(url, '_blank');
              }
            },
            legend: 'topOneItemInGroup',
            type: 'customize-stack-bar',
            options: {
              event: ['click'],
              scales: {
                x: {
                  stacked: true
                },
                y: {
                  stacked: true,
                  title: {
                    display: true,
                    text: '聲量'
                  },
                  grace: '20%'
                }
              },
              plugins: {
                legend: {
                  display: false
                },
                datalabels: {
                  display: false
                }
              }
            },
            csv: topProductCsv,
            csvKey: topKey
          }
        ]
      };
    }

    if (tag.length === 0) {
      this.top3Series = {
        chartData: [{
          id: 'series_brand_volume_compare',
          name: 'TOP1~3 系列佔品牌總聲量的占比圖',
          data: [{
            labels: [],
            datasets: []
          }],
          type: 'nodata'
        }]
      };
    } else {
      const seriesColors = {};

      const formatSeriesData = tag.map((brand) => ({
        ...brand,
        item: brand.item.map((item) => {
          const color = seriesColors[item.name];
          if (!color) {
            seriesColors[`${item.name}`] = COLOR_SET[Object.keys(seriesColors).length % 20];
          }
          return {
            id: item.id,
            name: item.name,
            count: item.count,
            color: seriesColors[item.name]
          };
        })
      }));

      const topSeriesCsv = formatSeriesData.map((el) => {
        const row = {
          系列名稱: el.name
        };
        for (let i = 0; i < el.item.length; i += 1) {
          row[`${el.item[i].name}`] = el.item[i].count ?? 0;
        }
        return row;
      });

      const topKey = generateCSVKey(topSeriesCsv[0]);

      this.top3Series = {
        chartData: [
          {
            id: 'brand_series_volume_compare',
            name: 'TOP1~3 系列佔品牌總聲量的占比圖',
            data: [formatSeriesData],
            onPointClick: (elements, chart, _) => {
              if (elements.length > 0) {
                const { datasetIndex, index } = elements[0];
                const series = chart.data.datasets[datasetIndex].item;

                if (series.id === '其他') {
                  return;
                }

                const brandItem = chart.data.datasets[datasetIndex].mainItem[index];
                const womCount = chart.data.datasets[datasetIndex].data[index];
                const { start_date: gte, finish_date: lte } = params;

                const topicsResult = {
                  ...(womType && {
                    womType
                  }),
                  packageContent: {
                    id: packageStore.activePackageId,
                    name: packageStore.activePackageName
                  },
                  chart: {
                    name: '進階分析/明星商品佔比/TOP1~3 系列佔品牌總聲量的占比圖'
                  },
                  brand: [{
                    id: brandItem.id,
                    name: brandItem.name
                  }],
                  series: [{
                    id: series.id,
                    name: series.name,
                    tagKey,
                    tagKeyName: packageStore.series.find((el) => el.id === tagKey).name
                  }],
                  womCount,
                  date: {
                    gte,
                    lte: lte ?? gte
                  }
                };

                const json = JSON.stringify(topicsResult);
                const result = encodeURI(json);
                const url = `${getHost()}/topics-result?result=${result}`;
                window.open(url, '_blank');
              }
            },
            onPointHover: (elements, ref) => {
              const chart = ref.current;
              if (elements.length > 0) {
                const { datasetIndex } = elements[0];
                const hoverTarget = chart.data.datasets[datasetIndex].customName;
                chart.hovered = hoverTarget;
              } else {
                chart.hovered = null;
              }
              chart.update();
            },
            legend: 'topOneItemInGroup',
            type: 'customize-stack-bar',
            options: {
              event: ['click'],
              scales: {
                x: {
                  stacked: true
                },
                y: {
                  stacked: true,
                  title: {
                    display: true,
                    text: '聲量'
                  },
                  grace: '20%'
                }
              },
              plugins: {
                legend: {
                  display: false
                },
                datalabels: {
                  display: false
                }
              }
            },
            csv: topSeriesCsv,
            csvKey: topKey
          }
        ]
      };
    }
  }

  static fromRes(data, query, params) {
    return new StarProductModel(data, query, params);
  }
}
