import dayjs from 'dayjs';

export default class StarProductSettingModel {
  constructor(data) {
    const {
      startDate,
      endDate,
      searchKeywordLevel1Ids,
      channelIds,
      womType,
      searchTagId,
      jobId
    } = data ?? {};

    this.date = [dayjs(startDate), dayjs(endDate)];
    this.selectedBrand = [...searchKeywordLevel1Ids ?? []];
    this.selectedChannel = [...channelIds ?? []];
    this.womType = womType ?? null;
    this.searchTagId = searchTagId ?? null;
    this.jobId = jobId;
  }

  static fromRes(data) {
    return new StarProductSettingModel(data);
  }
}
