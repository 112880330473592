import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import { CSVLink } from 'react-csv';

import { CloseOutlined, CheckOutlined, CaretDownOutlined, DownloadOutlined, SaveOutlined, StopOutlined, ReloadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Tooltip } from 'antd';
import { POST_TYPE, WORD_OF_MOUTH_TYPE, WORDBOOK_CHART_TYPE, WORD_COUNT_TYPE } from 'src/consts';
import withRouter from 'src/components/withRouter';
import PaperIcon from 'src/assets/PaperIcon.svg';
import InfoHeader from 'src/components/InfoHeader';
import packageStore from 'src/stores/packageStore';
import optionsStore from 'src/stores/optionsStore';
import Select from 'src/components/select';

import CategoryPopoverSelect from 'src/components/CategoryPopoverSelect';

import WordChartItem from './WordChartItem';
import ChartItem from './ChartItem';

import WordbookPageViewModel from './viewModel';

import styles from './styles.module.scss';

@observer
class WordbookPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new WordbookPageViewModel();
  }

  componentDidMount() {
    this.viewModel.setUpdateLevelReaction();
    this.viewModel.updateLevel(this.props.router);
  }

  componentDidUpdate() {
    this.viewModel.updateLevel(this.props.router);
  }

  componentWillUnmount() {
    this.viewModel.once?.();
  }

  render() {
    return (
      <>
        <div className={styles.pageContainer}>
          <InfoHeader
            breadcrumb={['分類詞庫', this.viewModel.level ? i18n.t(`common_${this.viewModel.level}`) : '階層', this.viewModel.chartTypeText]}
            callback={this.viewModel.getGraphicData}
            isMultiPeriod
          />
          <div className={styles.pageHeader}>
            <div className={styles.pageTitle}>
              分類詞庫圖表
            </div>
            <div className={styles.statisticFilter}>
              <div className={styles.filterTitle}>
                {i18n.t('chart_page_statistic_filter')}
              </div>
              <Select
                placeholder={i18n.t('filter_post_type_all')}
                options={POST_TYPE}
                value={this.viewModel.selectedPostType}
                onSelect={this.viewModel.onPostTypeChange}
                popupMatchSelectWidth={false}
                popupClassName={styles.sortMenu}
                className={clsx('sortSelect', styles.filterSelect)}
                suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
              />
              <Select
                placeholder={i18n.t('filter_word_of_mouth_title')}
                options={WORD_OF_MOUTH_TYPE}
                value={this.viewModel.selectedWOMType}
                onSelect={this.viewModel.onWOMTypeChange}
                popupMatchSelectWidth={false}
                popupClassName={styles.sortMenu}
                className={clsx('sortSelect', styles.filterSelect)}
                suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
              />
              <CategoryPopoverSelect
                title={i18n.t('source_category_title')}
                viewModel={this.viewModel.categoryPopover}
              />

              <Button
                className={clsx(styles.btn, styles.submitBtn)}
                icon={<CheckOutlined />}
                onClick={this.viewModel.onFilterSubmit}
              >
                {i18n.t('btn_filter_submit')}
              </Button>
              {
                this.viewModel.isShowStatisticClean
                && (
                  <Button
                    className={clsx(styles.btn, styles.cleanBtn)}
                    icon={<CloseOutlined />}
                    type="link"
                    onClick={this.viewModel.onCleanStatisticFilter}
                  >
                    {i18n.t('btn_filter_clean_all')}
                  </Button>
                )
              }
            </div>
            {
              this.viewModel.level === 'series'
              && (
                <div className={clsx(styles.statisticFilter)}>
                  <div className={styles.filterTitle}>
                    選擇系列群組
                  </div>
                  <Select
                    placeholder={i18n.t('package_modal_series_select')}
                    options={packageStore.series.map((el) => ({
                      value: el.id,
                      label: el.name
                    }))}
                    value={this.viewModel.selectedTagKey}
                    onSelect={this.viewModel.onSeriesParentSelect}
                    popupMatchSelectWidth={false}
                    popupClassName={styles.sortMenu}
                    className={clsx('sortSelect', styles.filterSelect)}
                    suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                  />
                </div>
              )
            }

          </div>
          <div className={styles.resContainer}>
            <div className={styles.typeSegment}>
              {
                WORDBOOK_CHART_TYPE.map((el) => {
                  return (
                    <div
                      key={el.id}
                      className={clsx(styles.segmentItem, this.viewModel.selectedType === el.id && styles.active)}
                      onClick={() => this.viewModel.onTypeChange(el.id)}
                    >
                      {el.name}
                    </div>
                  );
                })
              }
            </div>
            <div className={clsx(styles.chartContainer, this.viewModel.selectedType !== 'words' && 'download-chart')}>
              <div className={clsx(styles.actionsContainer, this.viewModel.selectedType === 'words' && styles.border)}>
                {
                  this.viewModel.selectedType === 'words'
                    ? (
                      <div className={styles.chartFilter}>
                        <div className={styles.filterTitle}>
                          {i18n.t('chart_page_graphic_filter')}
                        </div>
                        {/* <div className={styles.filterItem}>
                          <div className={styles.itemTitle}>
                            {i18n.t('chart_page_index_title')}
                          </div>
                          <Select
                            options={WORD_INDEX_TYPE}
                            value={this.viewModel.selectedIndex}
                            onSelect={this.viewModel.onChartIndexChange}
                            popupMatchSelectWidth={false}
                            popupClassName={styles.sortMenu}
                            className={clsx('sortSelect', styles.filterSelect)}
                            suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                          />
                        </div> */}
                        <div className={styles.filterItem}>
                          <div className={styles.itemTitle}>
                            {i18n.t('chart_page_word_count_title')}
                          </div>
                          <Select
                            options={WORD_COUNT_TYPE}
                            value={this.viewModel.selectedCount}
                            onSelect={this.viewModel.onChartCountChange}
                            popupMatchSelectWidth={false}
                            popupClassName={styles.sortMenu}
                            className={clsx('sortSelect', styles.filterSelect)}
                            suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                          />
                        </div>
                        <Button
                          className={clsx(styles.btn, styles.submitBtn)}
                          icon={<CheckOutlined />}
                          onClick={this.viewModel.onFilterSubmit}
                        >
                          {i18n.t('btn_filter_submit')}
                        </Button>
                        {
                          this.viewModel.isShowChartClean
                          && (
                            <Button
                              className={clsx(styles.btn, styles.cleanBtn)}
                              icon={<CloseOutlined />}
                              type="link"
                              onClick={this.viewModel.onCleanChartFilter}
                            >
                              {i18n.t('btn_filter_clean_all')}
                            </Button>
                          )
                        }
                      </div>
                    )
                    : (
                      <div className={styles.chartContent}>
                        <div className={styles.chartTitle}>
                          <span className={styles.titleText}>
                            {this.viewModel.radarChartTitle}
                          </span>
                          <span className={styles.tooltip}>
                            <Tooltip
                              title={this.viewModel.radarChartDesc}
                              placement="right"
                            >
                              <QuestionCircleOutlined
                                style={{ display: optionsStore.isImgDownload ? 'none' : 'inline-block' }}
                              />
                            </Tooltip>
                          </span>
                        </div>
                      </div>
                    )
                }
                <div className={styles.btnContainer}>
                  {
                    this.viewModel.selectedType === 'words'
                    && (
                      <Tooltip
                        title="查看屏蔽名單"
                        placement="bottom"
                      >
                        <Button
                          icon={<StopOutlined />}
                          onClick={this.viewModel.onStopModalOpen}
                          style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
                        />
                      </Tooltip>
                    )
                  }
                  {
                    this.viewModel.isDownload
                    && (
                      <CSVLink
                        className="chartDataDownload"
                        data={this.viewModel.downloadList}
                        filename={this.viewModel.downloadFileName}
                        style={{ display: 'none' }}
                      />
                    )
                  }
                  <Tooltip
                    title="下載數據"
                    placement="bottom"
                  >
                    <Button
                      icon={<DownloadOutlined />}
                      onClick={this.viewModel.onDataDownload}
                      style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
                    />
                  </Tooltip>
                  <Tooltip
                    title="下載圖表"
                    placement="bottom"
                  >
                    <Button
                      icon={<SaveOutlined />}
                      onClick={this.viewModel.onDownload}
                      style={{ display: optionsStore.isImgDownload ? 'none' : 'block' }}
                    />
                  </Tooltip>
                  {
                    this.viewModel.selectedType === 'words' && this.viewModel.level !== 'industry'
                    && (
                      <Button
                        icon={(
                          <div className={styles.downloadAll} />
                        )}
                        className={styles.downloadAllBtn}
                        onClick={this.viewModel.onCSVForAllDownload}
                        style={{ display: optionsStore.isImgDownload ? 'none' : 'flex' }}
                      />
                    )
                  }
                </div>
              </div>
              <div
                ref={this.viewModel.ref}
                className={clsx(this.viewModel.selectedType === 'radar' && styles.chart)}
              >
                {
                  this.viewModel.selectedType === 'radar'
                    ? (
                      <ChartItem viewModel={this.viewModel.chartItems} />
                    )
                    : (
                      <WordChartItem viewModel={this.viewModel.chartItems} />
                    )
                }
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={this.viewModel.isStopModalOpen}
          centered
          closable={false}
          footer={null}
          className={styles.blockModal}
          onCancel={this.viewModel.onStopModalClose}
          width={928}
        >
          <div className={styles.modalHeader}>
            <div className={styles.modalTitle}>
              <img src={PaperIcon} alt="paper" className={styles.titleIcon} />
              <div className={styles.modalText}>
                {i18n.t('wordbook_page_block_modal_title')}
              </div>
            </div>
            <Button
              icon={<CloseOutlined />}
              type="link"
              onClick={this.viewModel.onStopModalClose}
            >
              關閉
            </Button>
          </div>
          <div className={styles.tableContainer}>
            <Table
              key={this.viewModel.blockWords.length}
              rowClassName={styles.row}
              rowKey={(item) => item.id}
              dataSource={this.viewModel.blockWords}
              pagination={false}
              className="packagesTable"
            >
              <Table.Column
                title={i18n.t('wordbook_page_table_name')}
                dataIndex="name"
                key="name"
                className={styles.columns}
                width="50%"
                render={(_, record) => {
                  return (
                    <div>
                      {record.name}
                    </div>
                  );
                }}
              />
              <Table.Column
                title={i18n.t('wordbook_page_table_action_title')}
                dataIndex="name"
                key="name"
                className={styles.columns}
                width="50%"
                render={(_, record) => {
                  return (
                    <Button
                      icon={<ReloadOutlined />}
                      type="text"
                      onClick={() => this.viewModel.onRemoveWord(record.id)}
                    >
                      {i18n.t('wordbook_page_table_action_recover')}
                    </Button>
                  );
                }}
              />
            </Table>
          </div>
        </Modal>
      </>
    );
  }
}

WordbookPage.propTypes = {
  router: PropTypes.object
};

WordbookPage.defaultProps = {
  router: {}
};


export default withRouter(WordbookPage);
