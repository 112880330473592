import { request, getHost, getHeaders, getCacheHeaders } from './utils';

export const getProjects = (params) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/project`,
    headers: getHeaders(),
    params
  };
  return request(options);
};

export const getProjectsById = (projectId) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/project/${projectId}`,
    headers: getHeaders()
  };
  return request(options);
};

export const getBrand = (id, data) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/project/${id}/searchSet/keyword`,
    headers: getHeaders(),
    params: {
      level: 1,
      limit: 10000,
      ...data
    }
  };
  return request(options);
};

export const getSeries = (id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/project/${id}/searchSet/tag`,
    headers: getHeaders()
  };
  return request(options);
};

export const getProduct = (id, data) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/project/${id}/searchSet/keyword`,
    headers: getHeaders(),
    params: {
      level: 3,
      limit: 100000,
      ...data
    }
  };
  return request(options);
};

export const getFeatureSet = (id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/project/${id}/featureSet`,
    headers: getHeaders()
  };
  return request(options);
};

export const getMyCustomers = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/user/myCustomers`,
    headers: getHeaders()
  };
  return request(options);
};

export const createPackage = (data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/dataPackage`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const updatePackage = (id, data) => {
  const options = {
    method: 'put',
    url: `${getHost()}/api/v1/dataPackage/${id}`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getPackages = (params) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/dataPackage/my`,
    headers: getHeaders(),
    params: {
      order: 'createDateDesc',
      limit: 20,
      ...params
    }
  };
  return request(options);
};

export const getPackageInfo = (id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/dataPackage/${id}`,
    headers: getHeaders()
  };
  return request(options);
};


export const pinPackage = (id) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/dataPackage/${id}/pin`,
    headers: getHeaders()
  };
  return request(options);
};

export const deletePackage = (id) => {
  const options = {
    method: 'delete',
    url: `${getHost()}/api/v1/dataPackage/${id}`,
    headers: getHeaders()
  };
  return request(options);
};

export const getDeletedPackages = (params) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/deletedDataPackage`,
    headers: getHeaders(),
    params: {
      limit: 50,
      ...params
    }
  };
  return request(options);
};

export const getDeletedPackageInfo = (id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/deletedDataPackage/${id}`,
    headers: getHeaders()
  };
  return request(options);
};

export const getPackageBrand = (id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/dataPackage/${id}/searchSetKeyword`,
    headers: getCacheHeaders(),
    params: {
      level: 1
    }
  };
  return request(options);
};

export const getPackageSeries = (id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/dataPackage/${id}/searchSetTag`,
    headers: getCacheHeaders()
  };
  return request(options);
};

export const getPackageProduct = (id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/dataPackage/${id}/searchSetKeyword`,
    headers: getCacheHeaders(),
    params: {
      level: 3
    }
  };
  return request(options);
};

export const getFeatureSetContent = (pid, fid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/project/${pid}/featureSet/${fid}/category`,
    headers: getCacheHeaders()
  };
  return request(options);
};

export const getAnalysisLog = (pid) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/vocService/package/${pid}/logs`,
    headers: getCacheHeaders()
  };
  return request(options);
};

export const onReAnalysis = (pid, date) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}`,
    headers: getCacheHeaders(),
    data: {
      date
    }
  };
  return request(options);
};

