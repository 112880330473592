import { makeObservable, observable, action, computed, reaction, runInAction } from 'mobx';
import { BRAND_COMPETITION_TYPE } from 'src/consts/chart';
import { message } from 'antd';
import * as xlsx from 'xlsx';

import packageStore from 'src/stores/packageStore';
import optionsStore from 'src/stores/optionsStore';

import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import BlockedWordsService from 'src/services/blockedwords';
import PopoverSelectViewModel from '../Components/PopoverSelect/viewModel';
import FloatingScrollToViewModel from '../Components/FloatingScrollTo/viewModel';

import BrandCompetitionFactory from './charts/factory';

export default class BrandCompetitionPageViewModel {
  page = '數據報告／品牌競爭分析';

  @observable postType = 'all';
  @observable chartPopover = new PopoverSelectViewModel(this);
  @observable floatingScrollToViewModel = new FloatingScrollToViewModel(this);

  @observable seriesParent = [];
  @observable selectedParent = null;

  @observable blockedWords = [];
  @observable initBlockedWords = false;

  @observable brandList = [];
  @observable selectedBrand = null;
  @observable selectedBrandCache = null;

  @observable topOneBrand = {};

  @observable isShowFloatingActions = false;

  @observable isModalOpen = false;
  @observable selectedWord = '';
  @observable selectedWordURL = '';

  @observable isInit = false;
  @observable chartItems = [];

  @computed get selectedBrandName() {
    return this.brandList.find((el) => el.value === this.selectedBrandCache)?.label ?? '主品牌';
  }

  @computed get showCharts() {
    return BRAND_COMPETITION_TYPE.filter((el) => el.rule.every((r) => packageStore.opened.includes(r)));
  }

  constructor() {
    makeObservable(this);

    this.init();
  }

  @action init = async () => {
    if (!packageStore.isLevelProcessing) {
      this.updateBasicContent();
      await this.updateChartItems();
      await this.BlockedWordsService();
    } else {
      const once = reaction(
        () => packageStore.isLevelProcessing,
        async (bool) => {
          if (!bool) {
            this.updateBasicContent();
            await this.updateChartItems();
            await this.BlockedWordsService();
            once();
          }
        }
      );
    }
  };

  @action BlockedWordsService = async () => {
    try {
      const { list } = await BlockedWordsService.getBlockedWords();
      runInAction(() => {
        this.blockedWords = list;
        this.initBlockedWords = true;
      });
    } catch (error) {
      message.error('取得屏蔽詞異常，請稍後再試');
      runInAction(() => {
        this.initBlockedWords = false;
      });
    }
  };

  @action updateBasicContent = () => {
    this.seriesParent = packageStore.series.map((el) => ({ value: el.id, label: el.name }));
    this.brandList = packageStore.brand.map((el) => ({ value: el.id, label: el.name }));
    this.selectedParent = this.seriesParent[0]?.value;
    this.selectedBrand = this.brandList[0]?.value;
    this.selectedBrandCache = this.selectedBrand;
  };

  @action updateChartItems = async () => {
    this.chartItems = this.showCharts.map((el) => ({
      id: el.id,
      viewModel: BrandCompetitionFactory.createChartViewModel(el.id, this)
    }));

    this.isInit = true;

    await this.getCharts();
  };

  @action getCharts = async () => {
    const checkLatest = await packageStore.checkLatest();
    if (checkLatest.needUpdate) {
      await packageStore.syncLatest(checkLatest);
      return;
    }
    this.topOneBrand = {};
    try {
      Promise.all(this.chartItems.map((el) => el.viewModel.init()));
    } catch (error) {
      console.log(error);
    }
  };

  // @action getCharts = async () => {
  //   try {
  //     const queue = this.chartItems.map((el) => (
  //       () => el.viewModel.init()
  //     ));
  //     await queue.reduce(async (p, current) => {
  //       return p.then(async () => {
  //         await current();
  //         await new Promise((res) => {
  //           setTimeout(res, 500);
  //         });
  //       });
  //     }, Promise.resolve());
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  @action onBrandChange = (e) => {
    this.selectedBrand = e;
  };

  @action updateTopOneBrand = (brand) => {
    this.topOneBrand = brand;
  };

  @action onPostTypeChange = (e) => {
    this.postType = e;
  };

  @action onSeriesParentSelect = (e) => {
    this.selectedParent = e;
  };

  @action onSelectCallback = (target) => {
    const targetDOM = document.querySelector(`#${target.id}`);
    const container = document.querySelector('main');

    container.scrollTo({
      behavior: 'smooth',
      left: 0,
      // > 84 for header height and margin
      top: targetDOM.offsetTop - 84
    });
  };

  @action onFloatingShow = () => {
    this.isShowFloatingActions = true;
  };

  @action onFloatingHide = () => {
    this.isShowFloatingActions = false;
  };
  @action onModalOpen = (content) => {
    const {
      url,
      name
    } = content;
    this.isModalOpen = true;
    this.selectedWord = name;
    this.selectedWordURL = url;
  };

  @action onModalClose = () => {
    this.isModalOpen = false;
  };

  @action onTopicsClick = () => {
    window.open(this.selectedWordURL, '_blank');

    this.onModalClose();
  };

  // > ACTIONS
  @action onSubmit = () => {
    if (packageStore.isBrandAvailable && !this.selectedBrand) {
      message.error('尚未設定主品牌');
      return;
    }
    if (packageStore.isSeriesAvailable && !this.selectedParent) {
      message.error('尚未設定系列群組');
      return;
    }

    this.selectedBrandCache = this.selectedBrand;
    this.updateChartItems();
  };

  @action onClean = () => {
    this.postType = 'all';
    this.selectedParent = null;
    this.selectedBrand = null;
  };

  @action onDownloadAllImage = async () => {
    try {
      await optionsStore.onImgDownload();
      const queue = this.chartItems.map((el) => (
        () => el.viewModel.onDownload()
      ));
      queue.reduce(async (p, current) => {
        return p.then(async () => {
          await current();
          await new Promise((res) => {
            setTimeout(res, 500);
          });
        });
      }, Promise.resolve());
    } catch (error) {
      message.error('下載失敗，請稍後再試。');
    } finally {
      runInAction(() => {
        optionsStore.onImgDownloadEnd();
      });
    }
  };

  @action onDownloadAllExcel = async () => {
    try {
      await optionsStore.onImgDownload();
      const wordbook = xlsx.utils.book_new();
      const queue = this.chartItems.map((el) => (
        () => el.viewModel.onAllExcelDownload(wordbook)
      ));
      await queue.reduce(async (p, current) => {
        return p.then(async () => {
          await current();
          await new Promise((res) => {
            setTimeout(res, 500);
          });
        });
      }, Promise.resolve());
      xlsx.writeFile(wordbook, `${packageStore.activePackageName}-品牌競爭分析-${infoHeaderViewModel.multiViewModel.currentTimeText}-${infoHeaderViewModel.multiViewModel.autoPreviousTimeText}.xlsx`);
    } catch (error) {
      message.error('下載失敗，請稍後再試。');
      console.log('error', error);
    } finally {
      runInAction(() => {
        optionsStore.onImgDownloadEnd();
      });
    }
  };
}
