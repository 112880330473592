import { COLOR_SET } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import packageStore from 'src/stores/packageStore';
import { getHost } from 'src/utils';
import { encodeURI } from 'js-base64';
import { CONDITION_KEY_TC } from 'src/consts';
import { generateCSVKey } from 'src/utils/chart';

export default class AdvSearchCompareModel {
  constructor(data, prevData, query) {
    const colors = {};

    const formatData = data.slice(0, 20).map((c1) => {
      const sorted = c1.item.sort((a, b) => a.count >= b.count ? -1 : 1);
      const topsItem = sorted.slice(0, 10).map((item) => {
        const color = colors[item.name];
        if (!color) {
          colors[`${item.name}`] = COLOR_SET[Object.keys(colors).length % 20];
        }
        return {
          id: item.condition2,
          name: item.name,
          count: item.count,
          color: colors[item.name]
        };
      });

      const others = sorted.slice(10);
      let otherTotal = 0;
      for (let i = 0; i < others.length; i += 1) {
        otherTotal += others[i].count;
      }

      topsItem.unshift({
        id: 'cusOthers',
        name: '其他',
        count: otherTotal,
        color: '#ababab'
      });

      return {
        id: c1.condition1,
        name: c1.name,
        item: topsItem
      };
    });

    const formatPrevData = prevData.slice(0, 20).map((c1) => {
      const sorted = c1.item.sort((a, b) => a.count >= b.count ? -1 : 1);
      const topsItem = sorted.slice(0, 10).map((item) => {
        const color = colors[item.name];
        if (!color) {
          colors[`${item.name}`] = COLOR_SET[Object.keys(colors).length % 20];
        }
        return {
          id: item.condition2,
          name: item.name,
          count: item.count,
          color: colors[item.name]
        };
      });

      const others = sorted.slice(10);
      let otherTotal = 0;
      for (let i = 0; i < others.length; i += 1) {
        otherTotal += others[i].count;
      }

      topsItem.unshift({
        id: 'cusOthers',
        name: '其他',
        count: otherTotal,
        color: '#ababab'
      });

      return {
        id: c1.condition1,
        name: c1.name,
        item: topsItem
      };
    });

    const {
      womType,
      postType,
      condition1,
      condition2,
      tagKeyName
    } = query;

    const { currentDate, previousDate } = infoHeaderViewModel.multiViewModel;
    const currentTime = currentDate.map((el) => el.format('YYYY-MM-DD'));
    const previousTime = previousDate.map((el) => el.format('YYYY-MM-DD'));

    const condition1Key = condition1.key;
    const condition2Key = condition2.key;

    const csv = data.map((el) => {
      const row = {
        交叉條件一: el.name
      };

      el.item.forEach((item) => {
        row[`${item.name}`] = item.count;
      });

      return row;
    });

    console.log('csv', csv);

    const prevCsv = prevData.map((el) => {
      const row = {
        交叉條件一: el.name
      };

      el.item.forEach((item) => {
        row[`${item.name}`] = item.count;
      });

      return row;
    });

    csv.push(...prevCsv);

    const csvKey = generateCSVKey(csv[0]);

    console.log('csvKey', csvKey);

    this.data = {
      chartData: [
        {
          id: 'adv_search_volume_compare',
          name: '交叉分析聲量比較',
          data: [
            ...(formatData.length > 0 && !formatData.every((el) => el.item.every((item) => item.count === 0))
              ? [formatData]
              : [{
                type: 'nodata',
                subType: formatData.length === 0 ? 'ana-error' : 'default'
              }]
            ),
            ...(formatPrevData.length > 0 && !formatPrevData.every((el) => el.item.every((item) => item.count === 0))
              ? [formatPrevData]
              : [
                ...(
                  infoHeaderViewModel.multiViewModel.hasCompare
                    ? [{
                      type: 'nodata',
                      subType: formatPrevData.length === 0 ? 'ana-error' : 'default'
                    }]
                    : []
                )
              ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const c1Item = chart.data.datasets[datasetIndex].mainItem[index];
              const c2Item = chart.data.datasets[datasetIndex].item;
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const [gte, lte] = chart.data.datasets[datasetIndex].time === 'present'
                ? currentTime
                : previousTime;

              if (c2Item.id === 'cusOthers') {
                return;
              }

              const topicsResult = {
                [`${condition1Key}`]: [{
                  id: c1Item.id,
                  ...(condition1.value.tagKey && {
                    tagKey: condition1.value.tagKey,
                    tagKeyName
                  }),
                  name: c1Item.name.trim()
                }],
                [`${condition2Key}`]: [{
                  id: c2Item.id,
                  ...(condition2.value.tagKey && {
                    tagKey: condition2.value.tagKey,
                    tagKeyName
                  }),
                  name: c2Item.name.trim()
                }],
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: `交叉分析/『${CONDITION_KEY_TC[condition1Key]}』交叉『${CONDITION_KEY_TC[condition2Key]}』/比較圖`
                },
                womCount,
                date: {
                  gte,
                  lte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);

              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');

            }
          },
          onPointHover: (elements, ref) => {
            const chart = ref.current;
            if (elements.length > 0) {
              const { datasetIndex } = elements[0];
              if (chart.data.datasets[datasetIndex].item.id === 'cusOthers') {
                return;
              }
              const hoverTarget = chart.data.datasets[datasetIndex].customName;
              chart.hovered = hoverTarget;
            } else {
              chart.hovered = null;
            }
            chart.update();
          },
          legend: 'topTenInAllGroup',
          type: 'customize-stack-bar',
          options: {
            event: ['click'],
            scales: {
              x: {
                stacked: true
              },
              y: {
                stacked: true,
                grace: 5,
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                display: false
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ]
    };
  }

  static fromRes(data, prevData, query) {
    return new AdvSearchCompareModel(data, prevData, query);
  }
}
