export default class MyInfoModel {
  constructor(data) {
    const {
      id,
      name,
      email,
      permission,
      teamId,
      accessibleFeatures
    } = data;

    const {
      advancedSearch,
      analysisCharts,
      analysisLexicon,
      brandCompetitiveness,
      brandEffect,
      brandHealthCheck,
      brandReport,
      notification,
      market7p,
      marketDifference,
      marketingTrend,
      mindShare,
      publicOpinionSearch,
      relatedSearch,
      reputationAnalysis,
      starProduct,
      topic
    } = accessibleFeatures;

    this.id = id;
    this.name = name;
    this.account = email;
    this.permission = permission;
    this.teamId = teamId;
    this.search = publicOpinionSearch;
    this.topic = topic;
    this.chart = analysisCharts;
    this.wordbook = analysisLexicon;
    this.related = relatedSearch;
    this.advanceSearch = advancedSearch;
    this.notification = notification;

    this.dataReport = {
      industryTrend: marketingTrend,
      marketDifferentiation: marketDifference,
      brandCompetition: brandCompetitiveness,
      brandHealth: brandHealthCheck,
      brandEffect,
      brandRoutine: brandReport
    };

    this.advance = {
      marketing7P: market7p,
      mindShare,
      starProduction: starProduct,
      reputation: reputationAnalysis
    };
  }

  static fromRes(data) {
    return new MyInfoModel(data);
  }
}
