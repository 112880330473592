import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { CloseOutlined, CheckOutlined, CaretDownFilled, DownloadOutlined, SaveOutlined, PauseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Collapse, Progress, Empty, Tooltip, Modal } from 'antd';
import Select from 'src/components/select';
import RangeDatePicker from 'src/components/datepicker';

import { ADVANCE_ANALYSIS_OPTIONS, WORD_OF_MOUTH_TYPE, E_WOM_TYPE } from 'src/consts';

import userStore from 'src/stores/userStore';
import optionsStore from 'src/stores/optionsStore';
import packageStore from 'src/stores/packageStore';
import SourceSelectModal from 'src/components/SourceSelectModal';
import InfoHeader from 'src/components/InfoHeader';

import SingleClassModal from '../components/SingleClassModal';
import ChartItem from '../components/ChartItem';
import MindShareViewModel from './viewModel';

import styles from './styles.module.scss';

@observer
class MindSharePage extends React.Component {
  constructor() {
    super();

    this.viewModel = new MindShareViewModel();
  }

  componentDidMount() {
    this.viewModel.setReaction();
  }

  componentWillUnmount() {
    this.viewModel.once?.();
  }

  render() {
    return (
      <>
        <div className={styles.pageContainer}>
          <InfoHeader
            breadcrumb={['進階分析', '品牌心佔率']}
            showPicker={this.viewModel.selectedSec !== 'setting'}
            multiViewModel={this.viewModel.multipleDatePickerViewModel}
            callback={this.viewModel.getGraphicData}
          />
          {
            userStore.activeUserType !== 'customer'
            && (
              <div className={styles.sectionContainer}>
                <div className={styles.segmentContainer}>
                  {
                    ADVANCE_ANALYSIS_OPTIONS.map((el) => {
                      return (
                        <div
                          key={el.id}
                          className={clsx(
                            styles.segmentItem,
                            this.viewModel.selectedSec === el.id && styles.active
                          )}
                          onClick={() => this.viewModel.onSecChange(el.id)}
                        >
                          {el.name}
                        </div>
                      );
                    })
                  }
                </div>
                {
                  this.viewModel.selectedSec === 'setting'
                  && (
                    <div className={styles.settingContainer}>
                      <div className={styles.settingContent}>
                        <div className={styles.settingTitle}>
                          {i18n.t('advance_setting')}
                        </div>
                        <div className={styles.dateContainer}>
                          <div className={styles.itemTitle}>
                            {i18n.t('advance_date_setting')}
                          </div>
                          <RangeDatePicker
                            value={this.viewModel.date}
                            onCalendarChange={this.viewModel.onDateSelect}
                            changeOnBlur
                            allowClear
                            disabled={this.viewModel.disabledAdjustSetting}
                            disabledDate={(current) => {
                              return current < dayjs(packageStore.packageStartDate).startOf('day')
                                || current > dayjs(packageStore.packageEndDate).endOf('day')
                                || current > dayjs().endOf('day')
                                || (this.viewModel.date?.[0] && current > this.viewModel.date?.[0].add(548, 'day').startOf('day'))
                                || (this.viewModel.date?.[1] && current < this.viewModel.date?.[1].subtract(548, 'day'));
                            }}
                          />
                        </div>
                        <div className={styles.searchItem}>
                          <div className={styles.itemHeader}>
                            <div className={styles.itemTitle}>
                              {i18n.t('advance_analysis_item_title')}
                            </div>
                          </div>
                          <Button
                            className={styles.selectButton}
                            onClick={this.viewModel.onBrandSelectButtonClick}
                            disabled={this.viewModel.disabledAdjustSetting}
                          >
                            {i18n.t('advance_analysis_brand_item_title')}
                          </Button>
                          <div
                            className={clsx(
                              styles.preview,
                              this.viewModel.selectedBrand.length === 0 && styles.unselected
                            )}
                          >
                            {this.viewModel.brandSelectViewModel.text}
                          </div>
                        </div>
                        <div className={styles.searchItem}>
                          <div className={styles.itemHeader}>
                            <div className={styles.itemTitle}>
                              {i18n.t('advance_analysis_source_title')}
                            </div>
                          </div>
                          <Button
                            className={styles.selectButton}
                            onClick={this.viewModel.onSourceSelectButtonClick}
                            disabled={this.viewModel.disabledAdjustSetting}
                          >
                            {i18n.t('advance_analysis_source_item_title')}
                          </Button>
                          <div
                            className={clsx(
                              styles.preview,
                              this.viewModel.sourceSelectedIncomplete && styles.unselected
                            )}
                          >
                            {
                              this.viewModel.sourceSelectedIncomplete
                                ? i18n.t('advance_item_no_data').replace('%type%', i18n.t('advance_analysis_source_item_title'))
                                : (
                                  <>
                                    {
                                      this.viewModel.sourceSelectModalViewModel.selectedChannelsByCategory.map((el) => (
                                        <span key={el.id}>
                                          {`${el.name}(${el.count}), `}
                                        </span>
                                      ))
                                    }
                                    <span>
                                      {`共計 {${this.viewModel.totalChannels}}`}
                                    </span>
                                  </>
                                )
                            }
                          </div>
                        </div>
                        <div className={styles.selectRow}>
                          <div className={styles.rowTitle}>
                            {i18n.t('advance_vom_type')}
                          </div>
                          <Select
                            size="large"
                            suffixIcon={<CaretDownFilled style={{ pointerEvents: 'none' }} />}
                            className={styles.select}
                            placeholder={i18n.t('advance_vom_type_placeholder')}
                            options={WORD_OF_MOUTH_TYPE}
                            value={this.viewModel.selectedWOM}
                            onChange={this.viewModel.onVOMSelect}
                            disabled={this.viewModel.disabledAdjustSetting}
                          />
                        </div>
                      </div>
                      {
                        this.viewModel.jobId
                        && (
                          <div className={styles.progressContainer}>
                            <div className={styles.barContainer}>
                              <Progress
                                percent={this.viewModel.status.progress}
                                showInfo={false}
                                strokeColor="#0367A5"
                              />
                              <Button
                                className={styles.stopBtn}
                                icon={<PauseCircleOutlined />}
                                disabled={!this.viewModel.disabledAdjustSetting}
                                onClick={this.viewModel.stopAnalysis}
                              >
                                終止分析
                              </Button>
                            </div>
                            <div className={styles.infoText}>
                              {this.viewModel.progressText}
                            </div>
                          </div>
                        )
                      }
                      <div className={styles.actions}>
                        <Button
                          className={styles.actionBtn}
                          icon={<CloseOutlined />}
                          onClick={this.viewModel.onClean}
                          disabled={this.viewModel.disabledAdjustSetting}
                        >
                          {i18n.t('advance_search_cross_setting_clean_setting')}
                        </Button>
                        <Button
                          className={clsx(styles.actionBtn, styles.submitBtn)}
                          type="primary"
                          icon={<CheckOutlined />}
                          onClick={this.viewModel.onSubmit}
                          disabled={this.viewModel.disabledAdjustSetting}
                        >
                          {i18n.t('advance_search_cross_setting_submit')}
                        </Button>
                      </div>
                    </div>
                  )
                }
              </div>
            )
          }
          {
            this.viewModel.selectedSec === 'result'
            && (
              <div>
                {
                  this.viewModel.status.stage === 'DONE'
                    ? (
                      <>
                        <Collapse
                          className={styles.collapse}
                        >
                          <Collapse.Panel
                            showArrow={false}
                            header={(
                              <div
                                className={styles.collapseHeader}
                              >
                                <div>
                                  {i18n.t('advance_setting_res_title')}
                                </div>
                                <CaretDownFilled />
                              </div>
                            )}
                          >
                            <div className={styles.resSetting}>
                              <div className={styles.setting}>
                                <div className={styles.settingRow}>
                                  {`${i18n.t('advance_date_setting')}：${this.viewModel.cacheContent.dateString}`}
                                </div>
                                <div className={styles.settingRow}>
                                  {`${i18n.t('advance_analysis_item_title')}：${this.viewModel.cacheContent.brand}`}
                                </div>
                                <div className={styles.settingRow}>
                                  {`${i18n.t('advance_analysis_source_item_title')}：`}
                                  {
                                    this.viewModel.cacheContent.source.map((el) => (
                                      <span key={el.id}>
                                        {`${el.name}(${el.count}), `}
                                      </span>
                                    ))
                                  }
                                  <span>
                                    {`共計 {${this.viewModel.cacheContent.sourceTotal}}`}
                                  </span>
                                </div>
                                <div className={styles.settingRow}>
                                  {`${i18n.t('advance_vom_type')}：${E_WOM_TYPE[this.viewModel.cacheContent.vom]}`}
                                </div>
                              </div>
                            </div>
                          </Collapse.Panel>
                        </Collapse>
                        <div className={clsx(styles.resultContainer)}>
                          <div className={clsx('download-chart', styles.chartContainer)}>
                            <div className={clsx(styles.actionsContainer)}>
                              <div className={styles.chartTitle}>
                                <span>
                                  品牌心佔率
                                </span>
                                <span className={styles.tooltip} style={{ display: optionsStore.isImgDownload ? 'none' : 'inline-block' }}>
                                  <Tooltip
                                    title={(
                                      <div className={styles.descContainer}>
                                        透過心佔率兩大評判項目 - 品牌主動提及比與主動擁護比，可協助企業掌握消費者心中對自家品牌與競品間的主動關注程度差異性，並探索受消費者主動提及與主動擁護之關鍵要素。

                                      </div>
                                    )}
                                    placement="rightTop"
                                  >
                                    <QuestionCircleOutlined />
                                  </Tooltip>
                                </span>
                              </div>
                              <div className={styles.btnContainer}>
                                <Tooltip
                                  title="下載數據"
                                  placement="bottom"
                                >
                                  <Button
                                    icon={<DownloadOutlined />}
                                    onClick={this.viewModel.onDownloadCsv}
                                    style={{ display: optionsStore.isLoading ? 'none' : 'block' }}
                                  />
                                </Tooltip>
                                <Tooltip
                                  title="下載圖表"
                                  placement="bottom"
                                >
                                  <Button
                                    icon={<SaveOutlined />}
                                    onClick={this.viewModel.onDownload}
                                    style={{ display: optionsStore.isLoading ? 'none' : 'block' }}
                                  />
                                </Tooltip>
                              </div>
                            </div>
                            <div ref={this.viewModel.ref}>
                              <ChartItem viewModel={this.viewModel.showChart} />
                            </div>
                          </div>
                        </div>
                      </>
                    )
                    : (
                      <div className={styles.emptyContainer}>
                        <Empty
                          description="資料分析中，請稍後再試。"
                        />
                      </div>
                    )
                }


              </div>
            )
          }
        </div>
        <Modal
          open={this.viewModel.isSelectModalOpen}
          onCancel={this.viewModel.closeSelectModal}
          width={500}
          centered
          closable={false}
          footer={null}
          className={styles.modal}
          bodyStyle={{
            height: 160,
            display: 'flex',
            flexDirection: 'column',
            rowGap: 36,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div className={styles.modalHeader}>
            <div className={styles.title}>
              {`已點擊「${this.viewModel.targetData.brand.name}」，請選擇顯示的話題列表`}
            </div>
            <CloseOutlined className={styles.close} onClick={this.viewModel.closeSelectModal} />
          </div>
          <div className={styles.btnContainer}>
            <Button
              className={clsx(styles.btn, styles.normalBtn)}
              onClick={this.viewModel.onMentionClick}
            >
              提及比
            </Button>
            <Button
              className={clsx(styles.btn, styles.normalBtn)}
              onClick={this.viewModel.onSupportClick}
            >
              擁護比
            </Button>
          </div>
        </Modal>
        {
          userStore.activeUserType !== 'customer'
          && (
            <>
              <SourceSelectModal viewModel={this.viewModel.sourceSelectModalViewModel} />
              <SingleClassModal viewModel={this.viewModel.brandSelectViewModel} limit={10} />
            </>
          )
        }
      </>
    );
  }
}

MindSharePage.propTypes = {

};

MindSharePage.defaultProps = {

};


export default MindSharePage;
