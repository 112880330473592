import { request, getHost, getHeaders } from '../utils';

export const volume = {
  getProductVolumeTrend: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/product/volume/trend`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getProductVolumeCount: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/product/volume/count`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getSeriesProductVolumeTrend: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/series/product/trend`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getSeriesProductVolumeCount: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/series/product/count`,
      headers: getHeaders(),
      data
    };
    return request(options);
  }
};

export const source = {
  category: {
    getSourceCategoryTrend: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/product/source/category/trend`,
        headers: getHeaders(),
        data
      };
      return request(options);
    },
    getSourceCategoryCount: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/product/source/category/count`,
        headers: getHeaders(),
        data
      };
      return request(options);
    }
  },
  website: {
    getSourceWebsiteTrend: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/product/source/website/trend`,
        headers: getHeaders(),
        data
      };
      return request(options);
    },
    getSourceWebsiteCount: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/product/source/website/count`,
        headers: getHeaders(),
        data
      };
      return request(options);
    }
  },
  channel: {
    getSourceChannelTrend: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/product/source/channel/trend`,
        headers: getHeaders(),
        data
      };
      return request(options);
    },
    getSourceChannelCount: (pid, data) => {
      const options = {
        method: 'post',
        url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/product/source/channel/count`,
        headers: getHeaders(),
        data
      };
      return request(options);
    }
  },
  getSourceActivity: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/product/source/activity`,
      headers: getHeaders(),
      data
    };
    return request(options);
  }
};

export const sentiment = {
  getSentimentTrend: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/product/sentiment/trend`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getSentimentCount: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/product/sentiment/count`,
      headers: getHeaders(),
      data
    };
    return request(options);
  }
};

export const keyword = {
  getProductHotWord: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/product/term/hot`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getProductHotWordDiscover: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/product/term/discover`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getProductNewWord: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/product/term/new`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getProductTrendWord: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/product/term/trending`,
      headers: getHeaders(),
      data
    };
    return request(options);
  },
  getProductHotWordAssoc: (pid, data) => {
    const options = {
      method: 'post',
      url: `${getHost()}/api/vocService/package/${pid}/basic-analysis/product/term/assoc`,
      headers: getHeaders(),
      data
    };
    return request(options);
  }
};

