import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import { getTotalDays } from 'src/utils/chart';

import infoHeaderViewModel from '../InfoHeader/viewModel';

import styles from './styles.module.scss';

@observer
class ChartTimeStamp extends React.Component {
  render() {
    const { isPresent, isPrevious, isCombine, isBoth, isAutoPrevious, isAutoCombine } = this.props;
    const { currentDateCache, previousDateCache } = infoHeaderViewModel.multiViewModel;
    const [presentGte, presentLte] = currentDateCache.map((el) => el?.format('YYYY-MM-DD'));
    const totalDays = getTotalDays();
    const [autoGte, autoLte] = currentDateCache.map((el) => el?.subtract(totalDays, 'day')?.format('YYYY-MM-DD'));
    const [previousGte, previousLte] = previousDateCache.map((el) => el?.format('YYYY-MM-DD'));
    return (
      <div className={styles.time}>
        {
          isPresent
          && (
            <div>
              {`日期範圍：${presentGte} ~ ${presentLte}`}
            </div>
          )
        }
        {
          isPrevious
          && (
            <div>
              {`日期範圍：${previousGte} ~ ${previousLte}`}
            </div>
          )
        }
        {
          isCombine
          && (
            <div>
              {`日期範圍：${presentGte} ~ ${previousLte}`}
            </div>
          )
        }
        {
          isBoth
          && (
            <>
              <div>
                {`當期範圍：${presentGte} ~ ${presentLte}`}
              </div>
              <div>
                {`前期範圍：${previousGte} ~ ${previousLte}`}
              </div>
            </>
          )
        }
        {
          isAutoPrevious
          && (
            <div>
              {`日期範圍：${autoGte} ~ ${autoLte}`}
            </div>
          )
        }
        {
          isAutoCombine
          && (
            <div>
              {`日期範圍：${autoGte} ~ ${presentLte}`}
            </div>
          )
        }
      </div>
    );
  }
}

ChartTimeStamp.propTypes = {
  isPresent: PropTypes.bool,
  isPrevious: PropTypes.bool,
  isCombine: PropTypes.bool,
  isBoth: PropTypes.bool,
  isAutoPrevious: PropTypes.bool,
  isAutoCombine: PropTypes.bool
};

ChartTimeStamp.defaultProps = {
  isPresent: false,
  isPrevious: false,
  isCombine: false,
  isBoth: false,
  isAutoPrevious: false,
  isAutoCombine: false
};


export default ChartTimeStamp;
