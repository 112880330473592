export default class OpinionChartModel {
  constructor(data) {
    this.summary = data.summary;
    this.categories = data.category;
    this.channels = data.channel;
    this.dayTrend = data.volume_by_day;
    this.monthTrend = data.volume_by_month;
    this.sentiment = data.sentiment;
    this.word = data.term;
  }

  static fromRes(data) {
    return new OpinionChartModel(data);
  }
}
