import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import clsx from 'clsx';
import i18n from 'src/i18n';
import { Waypoint } from 'react-waypoint';
import { PlusOutlined, CloseOutlined, CheckOutlined, CaretDownOutlined, EditOutlined } from '@ant-design/icons';
import { Tooltip, Select, Button, Table } from 'antd';


import { userSSOIdentity, userSSOStatus, userVOCLoginAuth } from 'src/consts';
import { ACCOUNT_TABLE } from 'src/consts/tables';
import userStore from 'src/stores/userStore';


import SearchIcon from 'src/assets/MenuIcons/SearchIcon.svg';
import FilterIcon from 'src/assets/FilterIcon.svg';

import EditDrawer from './components/EditDrawer';
import CreateModal from './components/CreateModal';

import AccountManagementViewModel from './viewModel';

import styles from './styles.module.scss';

@observer
class AccountManagementPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new AccountManagementViewModel();
  }

  render() {
    return (
      <>
        <div className={styles.pageContainer}>
          <div className={styles.pageTitleContainer}>
            <div className={styles.titlePrefix} />
            <div className={styles.pageTitle}>
              {i18n.t('account_manage_page_title')}
            </div>
          </div>
          <div className={styles.tableContainer}>
            <div className={styles.filterContainer}>
              <div className={styles.filterTitleContainer}>
                <div className={styles.filterTitle}>
                  {i18n.t('search_filter')}
                </div>
                <img src={FilterIcon} alt="" className={styles.filterIcon} />
                <Select
                  placeholder={i18n.t('user_sso_identity_title')}
                  options={userSSOIdentity}
                  value={this.viewModel.filterSSOIdentity}
                  onSelect={this.viewModel.onFilterSSOIdentityChange}
                  popupMatchSelectWidth={false}
                  popupClassName={styles.sortMenu}
                  className={clsx('sortSelect', styles.filterSelect)}
                  suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                />
                <Select
                  placeholder={i18n.t('filter_sso_status')}
                  options={userSSOStatus}
                  value={this.viewModel.filterSSOStatus}
                  onSelect={this.viewModel.onFilterSSOStatusChange}
                  popupMatchSelectWidth={false}
                  popupClassName={styles.sortMenu}
                  className={clsx('sortSelect', styles.filterSelect)}
                  suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                />
                {
                  userStore.userType === 'admin'
                  && (
                    <>
                      <Select
                        placeholder={i18n.t('filter_department_employee')}
                        options={this.viewModel.departmentList}
                        value={this.viewModel.filterEmployeeDep}
                        onSelect={this.viewModel.onFilterEmployDepChange}
                        popupMatchSelectWidth={false}
                        popupClassName={styles.sortMenu}
                        className={clsx('sortSelect', styles.filterSelect)}
                        suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                        showSearch
                        filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                      />
                      <Select
                        placeholder={i18n.t('filter_department_customer')}
                        options={this.viewModel.departmentList}
                        value={this.viewModel.filterUserDep}
                        onSelect={this.viewModel.onFilterUserDepChange}
                        popupMatchSelectWidth={false}
                        popupClassName={styles.sortMenu}
                        className={clsx('sortSelect', styles.filterSelect)}
                        suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                      />
                    </>
                  )
                }
                <Select
                  placeholder={i18n.t('user_voc_login_auth_title')}
                  options={userVOCLoginAuth}
                  value={this.viewModel.filterVOCLoginAuth}
                  onSelect={this.viewModel.onFilterVOCLoginAuthChange}
                  popupMatchSelectWidth={false}
                  popupClassName={styles.sortMenu}
                  className={clsx('sortSelect', styles.filterSelect)}
                  suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                />
                <Select
                  placeholder={i18n.t('account_manage_column_title_salesman')}
                  options={this.viewModel.salesOptions}
                  value={this.viewModel.filterSalesman}
                  onSelect={this.viewModel.onFilterSalesmanChange}
                  popupMatchSelectWidth={false}
                  popupClassName={styles.sortMenu}
                  className={clsx('sortSelect', styles.filterSelect)}
                  suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                  showSearch
                  filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                />
                <Button
                  icon={<CheckOutlined />}
                  className={styles.submitBtn}
                  onClick={this.viewModel.onFilter}
                >
                  {i18n.t('btn_filter_submit')}
                </Button>
                {
                  this.viewModel.isShowCleanAll
                  && (
                    <Button
                      icon={<CloseOutlined />}
                      className={styles.cleanAllBtn}
                      onClick={this.viewModel.cleanAllFilter}
                    >
                      {i18n.t('btn_filter_clean_all')}
                    </Button>
                  )
                }
              </div>
            </div>
            <div className={styles.actionsContainer}>
              <Button
                icon={<PlusOutlined />}
                className={styles.addCustomerBtn}
                onClick={this.viewModel.createModalViewModel.onOpen}
              >
                {i18n.t('account_manage_add_customer')}
              </Button>
              <div className={styles.searchContainer}>
                <input
                  className={styles.searchInput}
                  placeholder={i18n.t('account_manage_search_title')}
                  value={this.viewModel.keyword}
                  onChange={this.viewModel.onSearchChange}
                  onKeyDown={this.viewModel.onKeyDown}
                />
                <div
                  className={styles.searchBtn}
                  onClick={this.viewModel.onSearchSubmit}
                >
                  <img src={SearchIcon} alt="" />
                </div>
              </div>
            </div>
            <div className={clsx('tableContent', styles.resContainer)}>
              <Table
                rowClassName={styles.row}
                rowKey={(item) => item.uid}
                dataSource={this.viewModel.userList}
                pagination={false}
                scroll={{ y: Number(document.querySelector('.tableContent')?.offsetHeight) - Number(document.querySelector('.ant-table-header')?.offsetHeight), x: 600 }}
              >
                {
                  ACCOUNT_TABLE.map((el) => {
                    if (el.key === 'action') {
                      return (
                        <Table.Column
                          width="5%"
                          title={el.title}
                          dataIndex={el.dataIndex}
                          key={el.key}
                          className={styles.columns}
                          render={(_, record) => {
                            return (
                              <div>
                                {
                                  (this.viewModel.availableEdit.includes(record.vocIdentity) || !record.vocIdentity || record.uid === userStore.userId)
                                    ? (
                                      <Tooltip
                                        placement="topRight"
                                        title={i18n.t('account_manage_column_action_note')}
                                        overlayClassName={styles.actionNote}
                                        arrow={{ pointAtCenter: true }}
                                      >
                                        <Button
                                          icon={<EditOutlined />}
                                          type="link"
                                          className={styles.actionBtn}
                                          disabled={record.userSSOStatus === '已停用'}
                                          onClick={() => this.viewModel.onDrawerOpen({
                                            uid: record.uid,
                                            userSSOIdentity: record.userSSOIdentity,
                                            userDepartment: record.employeeDep ?? record.customerDep,
                                            userName: record.userName,
                                            userAccount: record.userAccount
                                          })}
                                        />
                                      </Tooltip>
                                    )
                                    : (
                                      <div
                                        style={{ lineHeight: '32px' }}
                                      >
                                        ---
                                      </div>
                                    )
                                }
                              </div>

                            );
                          }}
                        />
                      );
                    }
                    if (el.key === 'customerDep') {
                      return (
                        <Table.Column
                          title={el.title}
                          dataIndex={el.dataIndex}
                          key={el.key}
                          width="7%"
                          className={styles.columns}
                          render={(text, record) => {
                            if (!text) {
                              return <div>---</div>;
                            }
                            const depArr = text.map((e) => {
                              return this.viewModel.departmentList.find((ele) => ele.value === e).label;
                            });
                            if (depArr.length === 1) {
                              return <div>{depArr}</div>;
                            }
                            return (
                              <Tooltip
                                title={depArr.join(', ')}
                              >
                                <div className={styles.customerDepTooltip}>
                                  More...
                                </div>
                              </Tooltip>
                            );
                          }}
                        />
                      );
                    }
                    if (el.key === 'userAccount') {
                      return (
                        <Table.Column
                          title={el.title}
                          dataIndex={el.dataIndex}
                          key={el.key}
                          className={styles.columns}
                          render={(text, record, index) => {
                            if (index === this.viewModel.userList.length - 1 && this.viewModel.anchor) {
                              return (
                                <Waypoint
                                  onEnter={this.viewModel.getUserList}
                                >
                                  {
                                    !text
                                      ? (
                                        <div>---</div>
                                      )
                                      : (
                                        <div>{text}</div>
                                      )
                                  }

                                </Waypoint>
                              );

                            }
                            if (!text) {
                              return <div>---</div>;
                            }
                            return <div>{text}</div>;
                          }}
                        />
                      );
                    }
                    return (
                      <Table.Column
                        title={el.title}
                        dataIndex={el.dataIndex}
                        key={el.key}
                        width={el.key === 'lastAdjustTime' ? '11%' : '9%'}
                        className={styles.columns}
                        render={(text, record) => {
                          if (!text) {
                            return <div>---</div>;
                          }
                          return <div>{text}</div>;
                        }}
                      />
                    );
                  })
                }
              </Table>
            </div>
          </div>
        </div>
        <EditDrawer viewModel={this.viewModel.editDrawerViewModel} />
        <CreateModal viewModel={this.viewModel.createModalViewModel} />
      </>
    );
  }
}

export default AccountManagementPage;
