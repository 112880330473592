import axios from 'axios';
import Auth from 'src/services/auth';

export async function request(options) {
  try {
    console.log(`[API] request: ${JSON.stringify(options, null, 2)}`);
    const result = await axios(options);
    const msg = result?.data?.message;
    console.log(`[API] status: ${result?.status}, message: ${msg}`);

    return result;
  } catch (err) {
    const status = err?.response?.status;

    if (err?.response?.status === 503) {
      console.log('[API] maintenance');
      window.location.href = '/maintenance';
    }
    // const data = err?.response?.data;
    const msg = err?.message ?? err;

    const log = [
      status ? `status: ${status}` : null,
      // data ? `data: ${JSON.stringify(data)}` : null,
      msg ? `msg: ${msg}` : null
    ]
      .filter((el) => !!el)
      .join(', ');

    console.log(`[API] error ${log}`);

    throw err;
  }
}

export const getHost = () => {
  // local
  if (/:\d{4,}$/.test(window.location.origin)) {
    const r = window.location.origin.split(/:\d{4,}/);
    if (r[0]) {
      // return `${r[0]}:9002`;
      return 'https://buzz-voc-d90d217a.miraclemobile.com.tw';
    }
  }

  // dev
  if (/d90d217a/.test(window.location.host)) {
    return 'https://buzz-voc-d90d217a.miraclemobile.com.tw';
  }

  // pro
  return 'https://vocplus.com.tw';
};

export const getAsiaIndexHost = () => {
  // local
  if (/:\d{4,}$/.test(window.location.origin)) {
    const r = window.location.origin.split(/:\d{4,}/);
    if (r[0]) {
      return `${r[0]}:9002`;
    }
  }
  return 'https://vocplus.com.tw';
};

export const getHeaders = () => {
  return {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    ...(Auth.hasToken() && { Authorization: `Bearer ${Auth.getToken()}` })
  };
};

export const getCacheHeaders = () => {
  return {
    'Content-Type': 'application/json',
    'Cache-Control': 'private',
    ...(Auth.hasToken() && { Authorization: `Bearer ${Auth.getToken()}` })
  };
};
