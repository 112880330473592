import { COLOR_SET } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import { generateCSVKey } from 'src/utils/chart';

export default class SeriesBrandVolumeProportionModel {
  constructor(data, prevData, query) {
    if (data.length === 0 && prevData.length === 0) {
      this.data = {
        chartData: [{
          id: 'brand_source_category_proportion',
          name: '系列',
          data: [
            {
              labels: [],
              datasets: [],
              type: 'nodata',
              subType: 'ana-error'
            },
            ...(
              infoHeaderViewModel.multiViewModel.hasCompare
                ? [{
                  labels: [],
                  datasets: [],
                  type: 'nodata',
                  subType: 'ana-error'
                }]
                : []
            )
          ]
        }]
      };
      return;
    }

    const seriesList = SeriesBrandVolumeProportionModel.generateTabList(data, prevData);
    const currTime = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD')).join('~');
    const prevTime = infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD')).join('~');
    const csvForAll = [];
    seriesList.forEach((series) => {
      const targetInData = data.find((el) => series.id === el.id);
      const sliceItem = targetInData?.item ?? [];
      const targetInPrevData = prevData.find((el) => series.id === el.id);
      const slicePrevItem = targetInPrevData?.item ?? [];
      const total = (() => {
        let sum = 0;
        sliceItem.forEach((el) => {
          sum += el.count;
        });
        return sum;
      })();

      const prevTotal = (() => {
        let sum = 0;
        slicePrevItem.forEach((el) => {
          sum += el.count;
        });
        return sum;
      })();

      const csv = SeriesBrandVolumeProportionModel.generateCSV(targetInData, total, targetInPrevData, prevTotal);
      const csvKey = generateCSVKey(csv[0]);
      csvForAll.push({
        id: series.id,
        name: series.name,
        csv,
        csvKey
      });
    });

    this.data = {
      chartData: seriesList.slice(0, 10).map((series) => {
        const targetInData = data.find((el) => series.id === el.id);
        const sliceItem = targetInData?.item.slice(0, 10) ?? [];
        const targetInPrevData = prevData.find((el) => series.id === el.id);
        const slicePrevItem = targetInPrevData?.item.slice(0, 10) ?? [];
        const total = (() => {
          let sum = 0;
          sliceItem.forEach((el) => {
            sum += el.count;
          });
          return sum;
        })();

        const prevTotal = (() => {
          let sum = 0;
          slicePrevItem.forEach((el) => {
            sum += el.count;
          });
          return sum;
        })();

        const { csv, csvKey } = csvForAll.find((el) => el.id === series.id);

        return {
          id: series.id,
          name: series.name,
          data: [
            ...(
              total
                ? [{
                  labels: sliceItem.map((el) => el.name),
                  datasets: [{
                    label: currTime,
                    brand: sliceItem.map((el) => ({ id: el.id, name: el.name })),
                    data: sliceItem.map((el) => el.count),
                    backgroundColor: sliceItem.map((el, i) => COLOR_SET[i % 20]),
                    datalabels: {
                      color: '#fff',
                      formatter: (value) => {
                        const percentage = ((value / total) * 100).toFixed();
                        return percentage > 5 ? `${percentage}%` : null;
                      }
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const percentage = ((context.parsed / total) * 100).toFixed();
                          return ` ${context.parsed}: [${percentage}%]`;
                        }
                      }
                    }
                  }]
                }]
                : [{
                  type: 'nodata',
                  subType: targetInData ? 'pie' : 'ana-error'
                }]
            ),
            ...(
              prevTotal
                ? [{
                  labels: slicePrevItem.map((el) => el.name),
                  datasets: [{
                    label: prevTime,
                    brand: slicePrevItem.map((el) => ({ id: el.id, name: el.name })),
                    data: slicePrevItem.map((el) => el.count),
                    backgroundColor: slicePrevItem.map((el, i) => COLOR_SET[i % 20]),
                    datalabels: {
                      color: '#fff',
                      formatter: (value) => {
                        const percentage = ((value / prevTotal) * 100).toFixed();
                        return percentage > 5 ? `${percentage}%` : null;
                      }
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const percentage = ((context.parsed / prevTotal) * 100).toFixed();
                          return ` ${context.parsed}: [${percentage}%]`;
                        }
                      }
                    }
                  }]
                }]
                : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{
                        type: 'nodata',
                        subType: targetInPrevData ? 'pie' : 'ana-error'
                      }]
                      : []
                  )
                ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType,
                category,
                tagKey
              } = query;
              const { datasetIndex, index } = elements[0];
              const brand = chart.data.datasets[datasetIndex].brand[index];
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const [gte, lte] = chart.data.datasets[datasetIndex].label.split('~');

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category ? {
                  category: category.map((el) => ({
                    id: el,
                    name: SOURCE_CATEGORY_TYPE[el]
                  }))
                } : {
                  category: SOURCE_CATEGORY.map((el) => ({
                    id: el.value,
                    name: el.label
                  }))
                }),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/系列/聲量分析/各別系列品牌/系列品牌聲量趨勢'
                },
                brand: [{
                  id: brand.id,
                  name: brand.name
                }],
                series: [{
                  id: series.id,
                  name: series.name,
                  tagKey,
                  tagKeyName: packageStore.series.find((el) => el.id === tagKey).name
                }],
                womCount,
                date: {
                  gte,
                  lte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'pie',
          options: {
            event: ['click'],
            plugins: {
              legend: {
                position: 'right'
              }
            }
          },
          csv,
          csvKey
        };
      }),
      csvForAll
    };
  }

  static generateTabList = (list, prevList) => {
    const tab = [];

    list.sort((a, b) => b.total - a.total).forEach((el) => {
      const target = tab.find((item) => item.id === el.id);
      if (!target) {
        tab.push({ id: el.id, name: el.name });
      }
    });
    // prevList.forEach((el) => {
    //   const target = tab.find((item) => item.id === el.id);
    //   if (!target) {
    //     tab.push({ id: el.id, name: el.name });
    //   }
    // });

    return tab;
  };

  static generateCSV = (curr, currTotal, prev, prevTotal) => {
    const csv = [];

    if (curr) {
      const time = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'));
      const row = {
        date: time.join('~')
      };
      curr.item.forEach((el) => {
        row[`${el.name}`] = el.count;
        row[`${el.name}-佔比`] = el.count / currTotal;
      });
      csv.push(row);
    }

    if (prev) {
      const time = infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD'));
      const row = {
        date: time.join('~')
      };
      prev.item.forEach((el) => {
        row[`${el.name}`] = el.count;
        row[`${el.name}-佔比`] = el.count / prevTotal;
      });
      csv.push(row);
    }

    return csv;
  };

  static fromRes(data, prevData, query) {
    return new SeriesBrandVolumeProportionModel(data, prevData, query);
  }
}
