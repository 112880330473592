import dayjs from 'dayjs';

export default class UserInfoWithPackagesModel {
  constructor(data) {
    const {
      user,
      dataPackages
    } = data;

    const {
      permission,
      startDate,
      endDate,
      accessibleFeatures
    } = user;

    const {
      advancedSearch,
      analysisCharts,
      analysisLexicon,
      brandCompetitiveness,
      brandEffect,
      brandHealthCheck,
      brandReport,
      notification,
      market7p,
      marketDifference,
      marketingTrend,
      mindShare,
      publicOpinionSearch,
      relatedSearch,
      reputationAnalysis,
      starProduct,
      topic
    } = accessibleFeatures;

    this.validity = `${dayjs(startDate).format('YYYY/MM/DD')} ~ ${dayjs(endDate).format('YYYY/MM/DD')}`;
    this.packages = dataPackages.map((el) => ({
      id: el._id,
      name: el.name,
      industry: el.industry,
      brand: el.brand,
      tag: el.tag,
      product: el.product,
      startDate: el.startDate,
      endDate: el.endDate,
      projectId: el.projectId,
      featureSetId: el.featureSetId
    }));
    this.permission = permission;

    this.search = publicOpinionSearch;
    this.topic = topic;
    this.chart = analysisCharts;
    this.wordbook = analysisLexicon;
    this.related = relatedSearch;
    this.advanceSearch = advancedSearch;
    this.notification = notification;

    this.dataReport = {
      industryTrend: marketingTrend,
      marketDifferentiation: marketDifference,
      brandCompetition: brandCompetitiveness,
      brandHealth: brandHealthCheck,
      brandEffect,
      brandRoutine: brandReport
    };

    this.advance = {
      marketing7P: market7p,
      mindShare,
      starProduction: starProduct,
      reputation: reputationAnalysis
    };
  }

  static fromRes(data) {
    return new UserInfoWithPackagesModel(data);
  }
}
