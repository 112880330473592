import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { diff } from 'deep-diff';

import { PACKAGE_SORT_TYPE } from 'src/consts';
import PackageService from 'src/services/package';

export default class DeleteSectionViewModel {
  @observable packages = [];

  @observable keyword = '';
  @observable keywordCache = '';
  @observable filterDate = [];
  @observable filterDateCache = [];

  @observable sortedBy = PACKAGE_SORT_TYPE.LastChangeDateDesc;

  @observable anchor = null;

  @observable isProcess = false;

  constructor() {
    makeObservable(this);
  }

  @action init = async () => {
    this.packages = [];
    this.anchor = null;

    await this.getPackages();
  };

  @action getPackages = async () => {
    if (this.isProcess) {
      return;
    }
    this.isProcess = true;
    try {
      const params = {
        order: this.sortedBy,
        ...(this.keywordCache.trim() && { keyword: this.keywordCache }),
        ...(this.filterDate?.[0] && { startDate: this.filterDate[0].startOf('day').toISOString() }),
        ...(this.filterDate?.[1] && { endDate: this.filterDate[1].endOf('day').toISOString() }),
        ...(this.anchor && { anchor: this.anchor })
      };

      const { packageList, anchor } = await PackageService.getDeletedPackages(params);

      runInAction(() => {
        this.packages = [...packageList];
        this.anchor = anchor;
      });

    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.isProcess = false;
      });
    }
  };

  @action onCheckPackage = (id, openFunc) => {
    openFunc(id);
  };

  @action onChange = (e) => {
    this.keyword = e.target.value;
  };

  @action onSubmit = async () => {

    this.keywordCache = this.keyword;
    this.packages = [];
    this.selectedPackages = [];
    this.anchor = null;

    await this.getPackages();
  };

  @action onKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onSubmit();
    }
  };

  @action onSortChange = () => {
    if (this.sortedBy === PACKAGE_SORT_TYPE.LastChangeDateAsc) {
      this.sortedBy = PACKAGE_SORT_TYPE.LastChangeDateDesc;
    } else {
      this.sortedBy = PACKAGE_SORT_TYPE.LastChangeDateAsc;
    }

    this.packages = [];
    this.anchor = null;
    this.getPackages();
  };

  @action onDateChange = async (e) => {
    this.filterDate = e;
    if (!this.filterDate) {
      this.filterDate = [];
      await this.onPickerChange(false);
    }
  };

  @action onPickerChange = async (bool) => {
    if (!bool && diff(this.filterDateCache, this.filterDate)) {
      this.filterDateCache = [...this.filterDate];
      this.packages = [];
      this.anchor = null;
      await this.getPackages();
    }
  };

}
