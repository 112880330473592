import { COLOR_SET } from 'src/consts/chart';
import { generateCompletedDayArray, getTotalDays, generateCSVKey } from 'src/utils/chart';
import { getHost } from 'src/utils';
import { SOURCE_CATEGORY_TYPE } from 'src/consts';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';

export default class ProductSourceWebsiteTrendModel {
  constructor(origin, prevOrigin, query) {
    const data = ProductSourceWebsiteTrendModel.generateData(origin);
    const previousData = ProductSourceWebsiteTrendModel.generateData(prevOrigin, 'previous');
    const productList = ProductSourceWebsiteTrendModel.generateList(data, previousData);
    const colors = ProductSourceWebsiteTrendModel.generateColor(data, previousData);
    const csvForAll = [];

    if (!data && !previousData) {
      this.data = {
        chartData: [{
          id: 'brand_source_category_ranking',
          name: '產品',
          data: [{
            labels: [],
            datasets: [],
            type: 'nodata',
            subType: 'ana-error'
          },
          ...(
            infoHeaderViewModel.multiViewModel.hasCompare
              ? [{
                labels: [],
                datasets: [],
                type: 'nodata',
                subType: 'ana-error'
              }]
              : []
          )]
        }]
      };
      return;
    }

    productList.forEach((product) => {
      const targetInCurrent = data?.find((el) => product.id === el.id);
      const targetInPrev = previousData?.find((el) => product.id === el.id);
      const csv = [];
      ProductSourceWebsiteTrendModel.pushCSV(targetInCurrent, csv);
      ProductSourceWebsiteTrendModel.pushCSV(targetInPrev, csv);
      const csvKey = generateCSVKey(csv[0]);
      csvForAll.push({
        id: product.id,
        name: product.name,
        csv,
        csvKey
      });
    });

    this.data = {
      chartData: productList.slice(0, 20).map((product) => {
        const targetInCurrent = data?.find((el) => product.id === el.id);
        const currentEmpty = targetInCurrent ? ProductSourceWebsiteTrendModel.emptyCheck(targetInCurrent) : true;
        const targetInPrev = previousData?.find((el) => product.id === el.id);
        const prevEmpty = targetInPrev ? ProductSourceWebsiteTrendModel.emptyCheck(targetInPrev) : true;
        const { csv, csvKey } = csvForAll.find((el) => el.id === product.id);

        return {
          id: product.id,
          name: product.name,
          data: [
            ...(
              !currentEmpty
                ? [{
                  labels: targetInCurrent.item[0].series.map((d) => d.date),
                  datasets: targetInCurrent.item.map((c, i) => ({
                    label: c.name,
                    accurate: targetInCurrent.item[0].series.map((d) => d.accurate ?? d.date),
                    data: c.series.map((s) => s.count),
                    borderColor: colors[c.name]
                  }))
                }]
                : [{
                  type: 'nodata',
                  subType: targetInCurrent ? 'default' : 'ana-error'
                }]
            ),
            ...(
              !prevEmpty
                ? [{
                  labels: targetInPrev.item[0].series.map((d) => d.date),
                  datasets: targetInPrev.item.map((c, i) => ({
                    label: c.name,
                    accurate: targetInPrev.item[0].series.map((d) => d.accurate ?? d.date),
                    data: c.series.map((s) => s.count),
                    borderColor: colors[c.name]
                  }))
                }]
                : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{
                        type: 'nodata',
                        subType: targetInPrev ? 'default' : 'ana-error'
                      }]
                      : []
                  )
                ]
            )

          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType,
                category
              } = query;
              const { datasetIndex, index } = elements[0];
              const [gte, lte] = chart.data.datasets[datasetIndex].accurate[index].split('~');
              const website = chart.data.datasets[datasetIndex].label;
              const womCount = chart.data.datasets[datasetIndex].data[index];

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category && {
                  category: category.map((c) => ({
                    id: c,
                    name: SOURCE_CATEGORY_TYPE[c]
                  }))
                }),
                website: [{
                  id: website,
                  name: website
                }],
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/產品/來源分析/產品來源網站/產品來源網站趨勢'
                },
                product: [{
                  id: product.id,
                  name: product.name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'line',
          options: {
            responsive: true,
            event: ['click'],
            tension: 0.3,
            scales: {
              y: {
                beginAtZero: true,
                grace: 5,
                title: {
                  display: true,
                  text: '聲量'
                }
              },
              x: {
                title: {
                  display: true,
                  text: '日期'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        };
      }),
      csvForAll
    };
  }

  static generateData = (list, time = 'present') => {
    if (list.length === 0) {
      return null;
    }
    const totalDays = getTotalDays(time);
    return list.map((ori) => {
      const items = ori.item.slice(0, 20).map((item) => {
        if (totalDays >= 32 && totalDays <= 90) {
          const dayArr = generateCompletedDayArray(time);
          item.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...item,
            series: dayArr.map((el) => ({
              date: el.id,
              count: el.count
            }))
          };
        }

        if (totalDays >= 91) {
          const dayArr = generateCompletedDayArray(time);
          item.series.forEach((el) => {
            const target = dayArr.find((d) => d.days.includes(el.date));
            if (target) {
              target.count += el.count;
            }
          });
          return {
            ...item,
            series: dayArr.map((el) => ({
              date: el.id,
              accurate: `${el.days[0]}~${el.days[el.days.length - 1]}`,
              count: el.count
            }))
          };
        }

        const dayArr = generateCompletedDayArray(time);
        return {
          ...item,
          series: dayArr.map((d) => ({
            date: d,
            count: item.series.find((s) => s.date === d)?.count ?? 0
          }))
        };
      });
      return {
        ...ori,
        item: items
      };
    });
  };

  static generateColor = (list, prevList) => {
    const colors = {};

    list?.forEach((el) => {
      el.item.forEach((item) => {
        const target = colors[item.name];
        if (!target) {
          colors[`${item.name}`] = COLOR_SET[Object.keys(colors).length % 20];
        }
      });
    });

    prevList?.forEach((el) => {
      el.item.forEach((item) => {
        const target = colors[item.name];
        if (!target) {
          colors[`${item.name}`] = COLOR_SET[Object.keys(colors).length % 20];
        }
      });
    });

    return colors;
  };

  static generateList = (list, prevList) => {
    const brandList = [];
    list?.forEach((el) => {
      const target = brandList.find((brand) => brand.id === el.id);
      if (!target) {
        brandList.push({ id: el.id, name: el.name });
      }
    });
    // prevList?.forEach((el) => {
    //   const target = brandList.find((brand) => brand.id === el.id);
    //   if (!target) {
    //     brandList.push({ id: el.id, name: el.name });
    //   }
    // });
    return brandList;
  };
  static pushCSV = (data, csv) => {
    if (data) {
      const rows = data.item[0]?.series?.map((d) => {
        const row = {
          date: d.date
        };
        for (let i = 0; i < data.item.length; i += 1) {
          row[`${data.item[i]?.name}`] = data.item[i]?.series.find((s) => s.date === d.date).count;
        }
        return row;
      }) ?? [];
      csv.push(...rows);
    }
  };

  static emptyCheck = (data) => {
    return data.item.every((el) => el.series.every((date) => date.count === 0));
  };

  static fromRes(data, prevOrigin, query) {
    return new ProductSourceWebsiteTrendModel(data, prevOrigin, query);
  }
}
