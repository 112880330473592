import { makeObservable, observable, action, computed, runInAction } from 'mobx';

import TopicService from 'src/services/topic';
import { valueToTextSentiment } from 'src/utils';
import packageStore from 'src/stores/packageStore';

export default class ListTopicCardViewModel {
  @observable vocKey = '';
  @observable matchId = '';
  @observable searchId = '';
  @observable packageId = '';
  @observable vocTitle = '';
  @observable website = '';
  @observable channel = '';
  @observable author = '';
  @observable date = '';
  @observable content = '';
  @observable viewCount = 0;
  @observable shareCount = 0;
  @observable commentCount = 0;
  @observable likeCount = 0;
  @observable hitPostType = '';
  @observable sentiment = 'positive';
  @observable canEditSentiment = true;

  @observable brandHit = '';
  @observable seriesHit = '';
  @observable productHit = '';

  @computed get href() {
    const origin = window.location.origin;
    return `${origin}/topic/${this.packageId}/${this.vocKey}?matchId=${this.matchId}&searchId=${this.searchId}${this.canEditSentiment ? '' : '&editable=false'}`;
  }


  constructor(data) {
    makeObservable(this);

    this.init(data);
  }

  @action init = (data) => {
    const {
      vocKey,
      matchId,
      searchId,
      packageId,
      vocTitle,
      website,
      channel,
      author,
      date,
      content,
      viewCount,
      shareCount,
      commentCount,
      womCount,
      hitPostType,
      sentiment,
      hit,
      url,
      canEditSentiment
    } = data;

    this.vocKey = vocKey;
    this.matchId = matchId;
    this.searchId = searchId;
    this.packageId = packageId;
    this.url = url;
    this.vocTitle = vocTitle;
    this.website = website;
    this.channel = channel;
    this.author = author;
    this.date = date;
    this.content = content;
    this.viewCount = viewCount;
    this.shareCount = shareCount;
    this.commentCount = commentCount;
    this.womCount = womCount;
    this.hitPostType = hitPostType;
    this.sentiment = valueToTextSentiment(sentiment);
    this.canEditSentiment = canEditSentiment ?? true;

    const {
      level1,
      tag,
      level3
    } = hit;

    this.brandHit = level1.id ? level1 : { id: null, name: '無' };
    this.seriesHit = tag.id ? tag : { id: null, name: '無' };
    this.productHit = level3.id ? level3 : { id: null, name: '無' };
  };

  @action onSentimentChange = async (e, canChangeSentiment) => {
    if (!canChangeSentiment) {
      return;
    }
    try {

      const sentiment = (() => {
        switch (e) {
          case 'positive':
            return 0;
          case 'neutral':
            return -1;
          default:
            return 1;
        }
      })();

      await TopicService.updateSentiment(this.packageId, [{
        vocKey: this.vocKey,
        sentiment,
        ...((this.brandHit.id && !this.productHit.id) && { search: this.brandHit.id }),
        ...((this.productHit.id) && { search: this.productHit.id }),
        ...((this.seriesHit.id && {
          tagKey: packageStore.series.find((el) => el.children.some((c) => c.id === this.seriesHit.id)).id,
          tagValue: this.seriesHit.id
        }))
      }]);
      runInAction(() => {
        this.sentiment = valueToTextSentiment(sentiment);
      });
    } catch (error) {
      console.log(error);
    }

  };
}
