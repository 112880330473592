export const COLOR_SET = [
  '#B03B3B', '#ED8438', '#FFD233', '#99AE6B', '#339D77', '#37A2DD', '#3857A9', '#8C99FF', '#8F3AE4', '#F452A0',
  '#FCCBE3', '#E0C8F7', '#DDE0FF', '#9BABD4', '#AFDAF1', '#B3DACC', '#D6DFC4', '#FFEDAD', '#F8CEB0', '#E1B6B6'
];

export const CATEGORY_COLOR_SET = {
  news: '#B03B3B',
  forum: '#ED8438',
  facebook: '#FFD233',
  ig: '#99AE6B',
  threads: '#339D77',
  video: '#37A2DD',
  others: '#3857A9'
};
export const CATEGORY_COLOR_SET_WITH_BLOG = {
  news: '#B03B3B',
  forum: '#ED8438',
  facebook: '#FFD233',
  ig: '#99AE6B',
  threads: '#339D77',
  video: '#37A2DD',
  blog: '#3857A9',
  others: '#8C99FF'
};

export const SENTIMENT_COLOR_SET = {
  positive: '#33A99B',
  neutral: '#E2BD00',
  negative: '#BD4215'
};

export const WORD_CONFIG = {
  small: {
    maxRatio: 4,
    basicSize: 20
  },
  medium: {
    maxRatio: 3,
    basicSize: 20
  },
  large: {
    maxRatio: 3,
    basicSize: 15
  }
};

export const AVG_LINE_COLOR = '#1B2B67';

export const TREND_ITEMS = [
  {
    id: 'potential',
    name: '潛在趨勢',
    color: 'rgba(76, 131, 161, 0.3)'
  },
  {
    id: 'normal',
    name: '中度趨勢',
    color: 'rgba(153, 174, 107, 0.3)'
  },
  {
    id: 'strong',
    name: '中強趨勢',
    color: 'rgba(223, 186, 54, 0.3)'
  },
  {
    id: 'strongest',
    name: '強度趨勢',
    color: 'rgba(210, 125, 58, 0.3)'
  }
];

export const CHART_ANALYSIS_TYPE = {
  industry: [
    {
      id: 'volume',
      name: '聲量分析',
      children: [
        {
          id: 'industry_volume_trend',
          name: '行業聲量趨勢'
        },
        {
          id: 'industry_volume_proportion',
          name: '行業系列聲量佔比',
          additionRule: 'series'
        }
      ]
    },
    {
      id: 'source',
      name: '來源分析',
      children: [
        {
          id: 'industry_source_category',
          name: '行業來源類型',
          children: [
            {
              id: 'industry_source_category_trend',
              name: '行業來源類型趨勢',
              type: 'line'
            },
            {
              id: 'industry_source_category_ranking',
              name: '行業來源類型排行',
              type: 'bar'
            },
            {
              id: 'industry_source_category_proportion',
              name: '行業來源類型佔比',
              type: 'pie'
            }
          ]
        },
        {
          id: 'industry_source_website',
          name: '行業來源網站',
          children: [
            {
              id: 'industry_source_website_trend',
              name: '行業來源網站趨勢',
              type: 'line'
            },
            {
              id: 'industry_source_website_ranking',
              name: '行業來源網站排行',
              type: 'bar'
            },
            {
              id: 'industry_source_website_proportion',
              name: '行業來源網站佔比',
              type: 'pie'
            }
          ]
        },
        {
          id: 'industry_source_channel',
          name: '行業來源頻道',
          children: [
            {
              id: 'industry_source_channel_trend',
              name: '行業來源頻道趨勢',
              type: 'line'
            },
            {
              id: 'industry_source_channel_ranking',
              name: '行業來源頻道排行',
              type: 'bar'
            },
            {
              id: 'industry_source_channel_proportion',
              name: '行業來源頻道佔比',
              type: 'pie'
            }
          ]
        },
        {
          id: 'industry_source_social',
          name: '行業社群活躍度'
        }
      ]
    },
    {
      id: 'sentiment',
      name: '情緒分析',
      children: [
        {
          id: 'industry_sentiment_overview',
          name: '行業情緒總覽'
        },
        {
          id: 'industry_sentiment_trend',
          name: '行業正負面情緒趨勢'
        },
        {
          id: 'industry_sentiment_volume',
          name: '行業正負面情緒聲量'
        }
      ]
    },
    {
      id: 'keyword',
      name: '關鍵字分析',
      children: [
        {
          id: 'industry_keyword_hot',
          replace: '%replace%熱詞文字雲',
          name: '行業熱詞文字雲'
        },
        {
          id: 'industry_keyword_new',
          replace: '%replace%新詞文字雲',
          name: '行業新詞'
        },
        {
          id: 'industry_keyword_trend',
          replace: '%replace%趨勢詞',
          name: '行業趨勢詞'
        },
        {
          id: 'industry_keyword_hot_assocs',
          replace: '%replace%熱詞探勘',
          name: '行業熱詞探勘'
        }
      ]
    }
  ],
  brand: [
    {
      id: 'volume',
      name: '聲量分析',
      children: [
        {
          id: 'brand_volume_trend',
          name: '品牌聲量趨勢'
        },
        {
          id: 'brand_volume_ranking',
          name: '品牌聲量排行'
        },
        {
          id: 'brand_volume_proportion',
          name: '品牌聲量佔比'
        }
      ]
    },
    {
      id: 'source',
      name: '來源分析',
      children: [
        {
          id: 'brand_source_category',
          name: '品牌來源類型',
          children: [
            {
              id: 'brand_source_category_trend',
              name: '品牌來源類型趨勢',
              type: 'line',
              replace: '%replace%來源類型趨勢'
            },
            {
              id: 'brand_source_category_ranking',
              name: '品牌來源類型排行',
              type: 'bar',
              replace: '%replace%來源類型排行'
            },
            {
              id: 'brand_source_category_proportion',
              name: '品牌來源類型佔比',
              type: 'pie',
              replace: '%replace%來源類型佔比'
            },
            {
              id: 'brand_source_category_compare',
              name: '品牌來源類型比較',
              type: 'compare'
            }
          ]
        },
        {
          id: 'brand_source_website',
          name: '品牌來源網站',
          children: [
            {
              id: 'brand_source_website_trend',
              name: '品牌來源網站趨勢',
              type: 'line',
              replace: '%replace%來源網站趨勢'
            },
            {
              id: 'brand_source_website_ranking',
              name: '品牌來源網站排行',
              type: 'bar',
              replace: '%replace%來源網站排行'
            },
            {
              id: 'brand_source_website_proportion',
              name: '品牌來源網站佔比',
              type: 'pie',
              replace: '%replace%來源網站佔比'
            },
            {
              id: 'brand_source_website_compare',
              name: '品牌來源網站比較',
              type: 'compare'
            }
          ]
        },
        {
          id: 'brand_source_channel',
          name: '品牌來源頻道',
          children: [
            {
              id: 'brand_source_channel_trend',
              name: '品牌來源頻道趨勢',
              type: 'line',
              replace: '%replace%來源頻道趨勢'
            },
            {
              id: 'brand_source_channel_ranking',
              name: '品牌來源頻道排行',
              type: 'bar',
              replace: '%replace%來源頻道排行'
            },
            {
              id: 'brand_source_channel_proportion',
              name: '品牌來源頻道佔比',
              type: 'pie',
              replace: '%replace%來源頻道佔比'
            },
            {
              id: 'brand_source_channel_compare',
              name: '品牌來源頻道比較',
              type: 'compare'
            }
          ]
        },
        {
          id: 'brand_source_social',
          name: '品牌社群活躍度'
        }
      ]
    },
    {
      id: 'sentiment',
      name: '情緒分析',
      children: [
        {
          id: 'brand_sentiment_overview',
          name: '品牌情緒總覽'
        },
        {
          id: 'single_brand_sentiment_trend',
          name: '各別品牌情緒趨勢',
          replace: '%replace%情緒趨勢'
        },
        {
          id: 'brand_sentiment_positive_trend',
          name: '總體品牌正面情緒趨勢'
        },
        {
          id: 'brand_sentiment_negative_trend',
          name: '總體品牌負面情緒趨勢'
        },
        {
          id: 'brand_sentiment_volume',
          name: '總體品牌正負面情緒聲量'
        }
      ]
    },
    {
      id: 'keyword',
      name: '關鍵字分析',
      children: [
        {
          id: 'brand_keyword_hot',
          name: '品牌熱詞文字雲',
          replace: '%replace%熱詞文字雲'
        },
        {
          id: 'brand_keyword_new',
          name: '品牌新詞',
          replace: '%replace%新詞文字雲'
        },
        {
          id: 'brand_keyword_trend',
          name: '品牌趨勢詞',
          replace: '%replace%趨勢詞'
        },
        {
          id: 'brand_keyword_hot_assocs',
          name: '品牌熱詞探勘',
          replace: '%replace%熱詞探勘'
        }
      ]
    }
  ],
  series: [
    {
      id: 'volume',
      name: '聲量分析',
      children: [
        {
          id: 'series_volume',
          name: '系列聲量',
          children: [
            {
              id: 'series_volume_trend',
              name: '系列聲量趨勢',
              type: 'line'
            },
            {
              id: 'series_volume_ranking',
              name: '系列聲量排行',
              type: 'bar'
            },
            {
              id: 'series_volume_proportion',
              name: '系列聲量佔比',
              type: 'pie'
            }
          ]
        },
        {
          id: 'series_brand_volume',
          name: '各別系列品牌',
          additionRule: 'brand',
          children: [
            {
              id: 'series_brand_volume_trend',
              name: '系列品牌趨勢',
              type: 'line',
              replace: '%replace%品牌趨勢'
            },
            {
              id: 'series_brand_volume_ranking',
              name: '系列品牌排行',
              type: 'bar',
              replace: '%replace%品牌排行'
            },
            {
              id: 'series_brand_volume_proportion',
              name: '系列品牌佔比',
              type: 'pie',
              replace: '%replace%品牌佔比'
            },
            {
              id: 'series_brand_volume_compare',
              name: '系列品牌聲量比較',
              type: 'compare'
            }
          ]
        }
      ]
    },
    {
      id: 'source',
      name: '來源分析',
      children: [
        {
          id: 'series_source_category',
          name: '系列來源類型',
          children: [
            {
              id: 'series_source_category_trend',
              name: '系列來源類型趨勢',
              type: 'line',
              replace: '%replace%來源類型趨勢'
            },
            {
              id: 'series_source_category_ranking',
              name: '系列來源類型排行',
              type: 'bar',
              replace: '%replace%來源類型排行'
            },
            {
              id: 'series_source_category_proportion',
              name: '系列來源類型佔比',
              type: 'pie',
              replace: '%replace%來源類型佔比'
            },
            {
              id: 'series_source_category_compare',
              name: '系列來源類型比較',
              type: 'compare'
            }
          ]
        },
        {
          id: 'series_source_website',
          name: '系列來源網站',
          children: [
            {
              id: 'series_source_website_trend',
              name: '系列來源網站趨勢',
              type: 'line',
              replace: '%replace%來源網站趨勢'
            },
            {
              id: 'series_source_website_ranking',
              name: '系列來源網站排行',
              type: 'bar',
              replace: '%replace%來源網站排行'
            },
            {
              id: 'series_source_website_proportion',
              name: '系列來源網站佔比',
              type: 'pie',
              replace: '%replace%來源網站佔比'
            },
            {
              id: 'series_source_website_compare',
              name: '系列來源網站比較',
              type: 'compare'
            }
          ]
        },
        {
          id: 'series_source_channel',
          name: '系列來源頻道',
          children: [
            {
              id: 'series_source_channel_trend',
              name: '系列來源頻道趨勢',
              type: 'line',
              replace: '%replace%來源頻道趨勢'
            },
            {
              id: 'series_source_channel_ranking',
              name: '系列來源頻道排行',
              type: 'bar',
              replace: '%replace%來源頻道排行'
            },
            {
              id: 'series_source_channel_proportion',
              name: '系列來源頻道佔比',
              type: 'pie',
              replace: '%replace%來源頻道佔比'
            },
            {
              id: 'series_source_channel_compare',
              name: '系列來源頻道比較',
              type: 'compare'
            }
          ]
        }
      ]
    },
    {
      id: 'keyword',
      name: '關鍵字分析',
      children: [
        {
          id: 'series_keyword_hot',
          name: '系列熱詞文字雲',
          replace: '%replace%熱詞文字雲'
        },
        {
          id: 'series_keyword_new',
          name: '系列新詞',
          replace: '%replace%新詞文字雲'
        },
        {
          id: 'series_keyword_trend',
          name: '系列趨勢詞',
          replace: '%replace%趨勢詞'
        },
        {
          id: 'series_keyword_hot_assocs',
          name: '系列熱詞探勘',
          replace: '%replace%熱詞探勘'
        }
      ]
    }
  ],
  product: [
    {
      id: 'volume',
      name: '聲量分析',
      children: [
        {
          id: 'product_volume',
          name: '產品聲量',
          children: [
            {
              id: 'product_volume_trend',
              name: '產品聲量趨勢',
              type: 'line'
            },
            {
              id: 'product_volume_ranking',
              name: '產品聲量排行',
              type: 'bar'
            },
            {
              id: 'product_volume_proportion',
              name: '產品聲量佔比',
              type: 'pie'
            }
          ]
        },
        {
          id: 'product_series_volume',
          name: '各別系列產品',
          additionRule: 'series',
          children: [
            {
              id: 'product_series_volume_trend',
              name: '系列產品趨勢',
              type: 'line',
              replace: '%replace%產品趨勢'
            },
            {
              id: 'product_series_volume_ranking',
              name: '系列產品排行',
              type: 'bar',
              replace: '%replace%產品排行'
            },
            {
              id: 'product_series_volume_proportion',
              name: '系列產品佔比',
              type: 'pie',
              replace: '%replace%產品佔比'
            },
            {
              id: 'product_series_volume_compare',
              name: '系列產品比較',
              type: 'compare'
            }
          ]
        }
      ]
    },
    {
      id: 'source',
      name: '來源分析',
      children: [
        {
          id: 'product_source_category',
          name: '產品來源類型',
          children: [
            {
              id: 'product_source_category_trend',
              name: '產品來源類型趨勢',
              type: 'line',
              replace: '%replace%來源類型趨勢'
            },
            {
              id: 'product_source_category_ranking',
              name: '產品來源類型排行',
              type: 'bar',
              replace: '%replace%來源類型排行'
            },
            {
              id: 'product_source_category_proportion',
              name: '產品來源類型佔比',
              type: 'pie',
              replace: '%replace%來源類型佔比'
            },
            {
              id: 'product_source_category_compare',
              name: '產品來源類型比較',
              type: 'compare'
            }
          ]
        },
        {
          id: 'product_source_website',
          name: '產品來源網站',
          children: [
            {
              id: 'product_source_website_trend',
              name: '產品來源網站趨勢',
              type: 'line',
              replace: '%replace%來源網站趨勢'
            },
            {
              id: 'product_source_website_ranking',
              name: '產品來源網站排行',
              type: 'bar',
              replace: '%replace%來源網站排行'
            },
            {
              id: 'product_source_website_proportion',
              name: '產品來源網站佔比',
              type: 'pie',
              replace: '%replace%來源網站佔比'
            },
            {
              id: 'product_source_website_compare',
              name: '產品來源網站比較',
              type: 'compare'
            }
          ]
        },
        {
          id: 'product_source_channel',
          name: '產品來源頻道',
          children: [
            {
              id: 'product_source_channel_trend',
              name: '產品來源頻道趨勢',
              type: 'line',
              replace: '%replace%來源頻道趨勢'
            },
            {
              id: 'product_source_channel_ranking',
              name: '產品來源頻道排行',
              type: 'bar',
              replace: '%replace%來源頻道排行'
            },
            {
              id: 'product_source_channel_proportion',
              name: '產品來源頻道佔比',
              type: 'pie',
              replace: '%replace%來源頻道佔比'
            },
            {
              id: 'product_source_channel_compare',
              name: '產品來源頻道比較',
              type: 'compare'
            }
          ]
        },
        {
          id: 'product_source_social',
          name: '產品社群活躍度'
        }
      ]
    },
    {
      id: 'sentiment',
      name: '情緒分析',
      children: [
        {
          id: 'product_sentiment_overview',
          name: '產品情緒總覽'
        },
        {
          id: 'single_product_sentiment_trend',
          name: '各別產品情緒趨勢',
          replace: '%replace%情緒趨勢'
        },
        {
          id: 'product_sentiment_positive_trend',
          name: '總體產品正面情緒趨勢'
        },
        {
          id: 'product_sentiment_negative_trend',
          name: '總體產品負面情緒趨勢'
        },
        {
          id: 'product_sentiment_volume',
          name: '總體產品正負面情緒聲量'
        }
      ]
    },
    {
      id: 'keyword',
      name: '關鍵字分析',
      children: [
        {
          id: 'product_keyword_hot',
          name: '產品熱詞文字雲',
          replace: '%replace%熱詞文字雲'
        },
        {
          id: 'product_keyword_new',
          name: '產品新詞',
          replace: '%replace%新詞文字雲'
        },
        {
          id: 'product_keyword_trend',
          name: '產品趨勢詞',
          replace: '%replace%趨勢詞'
        },
        {
          id: 'product_keyword_hot_assocs',
          name: '產品熱詞探勘',
          replace: '%replace%熱詞探勘'
        }
      ]
    }
  ]
};

export const INDUSTRY_TREND_TYPE = [
  {
    id: 'industry_volume_trend',
    name: '行業聲量趨勢',
    rule: ['industry']
  },
  {
    id: 'industry_source_channel_ranking',
    name: '行業來源頻道排行',
    rule: ['industry']
  },
  {
    id: 'industry_keyword_hot',
    name: '行業不分類文字雲',
    rule: ['industry']
  },
  {
    id: 'industry_consumer_feature',
    name: '行業消費者關注分類',
    rule: ['industry']
  },
  {
    id: 'industry_top1_word',
    name: '行業TOP1文字雲',
    rule: ['industry']
  },
  {
    id: 'industry_top2_word',
    name: '行業TOP2文字雲',
    rule: ['industry']
  },
  {
    id: 'industry_top3_word',
    name: '行業TOP3文字雲',
    rule: ['industry']
  },
  {
    id: 'brand_volume_trend',
    name: '品牌聲量趨勢',
    rule: ['brand']
  },
  {
    id: 'series_volume_trend',
    name: '系列聲量趨勢',
    rule: ['series']
  },
  {
    id: 'product_volume_trend',
    name: '產品聲量趨勢',
    rule: ['product']
  },
  {
    id: 'brand_reputation',
    name: '品牌聲譽定位',
    rule: ['brand']
  },
  {
    id: 'product_reputation',
    name: '產品聲譽定位',
    rule: ['product']
  },
  {
    id: 'brand_source_category_compare',
    name: '品牌來源類型比較',
    rule: ['brand']
  },
  {
    id: 'brand_sentiment_volume',
    name: '品牌正負面情緒總覽',
    rule: ['brand']
  }
];

export const MARKETING_DIFFERENTIATION_TYPE = [
  {
    id: 'series_volume_trend',
    name: '系列聲量趨勢',
    rule: ['series']
  },
  {
    id: 'series_volume_proportion',
    name: '系列聲量佔比',
    rule: ['series']
  },
  {
    id: 'series_brand_volume_compare',
    name: '系列品牌聲量比較',
    rule: ['series', 'brand']
  },
  {
    id: 'series_product_volume_compare',
    name: '系列產品聲量比較',
    rule: ['series', 'product']
  },
  {
    id: 'series_source_category_compare',
    name: '系列來源類型比較',
    rule: ['series']
  },
  {
    id: 'series_top1_brand_volume_proportion',
    name: 'TOP1系列品牌聲量佔比',
    rule: ['series', 'brand']
  },
  {
    id: 'series_top1_product_volume_ranking',
    name: 'TOP1系列產品聲量排行',
    rule: ['series', 'product']
  },
  {
    id: 'series_top1_word',
    name: 'TOP1系列不分類文字雲',
    rule: ['series']
  },
  {
    id: 'series_top2_brand_volume_proportion',
    name: 'TOP2系列品牌聲量佔比',
    rule: ['series', 'brand']
  },
  {
    id: 'series_top2_product_volume_ranking',
    name: 'TOP2系列產品聲量排行',
    rule: ['series', 'product']
  },
  {
    id: 'series_top2_word',
    name: 'TOP2系列不分類文字雲',
    rule: ['series']
  },
  {
    id: 'series_top3_brand_volume_proportion',
    name: 'TOP3系列品牌聲量佔比',
    rule: ['series', 'brand']
  },
  {
    id: 'series_top3_product_volume_ranking',
    name: 'TOP3系列產品聲量排行',
    rule: ['series', 'product']
  },
  {
    id: 'series_top3_word',
    name: 'TOP3系列不分類文字雲',
    rule: ['series']
  }
];

export const BRAND_COMPETITION_TYPE = [
  {
    id: 'brand_reputation',
    name: '品牌聲譽定位',
    rule: ['brand']
  },
  {
    id: 'brand_volume_trend',
    name: '品牌聲量趨勢',
    rule: ['brand']
  },
  {
    id: 'brand_volume_ranking',
    name: '品牌聲量排行',
    rule: ['brand']
  },
  {
    id: 'brand_volume_proportion',
    name: '品牌聲量佔比',
    rule: ['brand']
  },
  {
    id: 'brand_series_volume_compare',
    name: '品牌系列聲量比較',
    rule: ['brand', 'series']
  },
  {
    id: 'brand_product_volume_compare',
    name: '品牌產品聲量比較(PBI)',
    rule: ['brand', 'product']
  },
  {
    id: 'product_volume_ranking',
    name: '產品聲量排行',
    rule: ['product']
  },
  {
    id: 'brand_source_category_compare',
    name: '品牌來源類型比較',
    rule: ['brand']
  },
  {
    id: 'brand_source_channel_compare',
    name: '品牌來源頻道比較',
    rule: ['brand']
  },
  {
    id: 'brand_sentiment_overview',
    name: '品牌正負情緒總覽',
    rule: ['brand']
  },
  {
    id: 'brand_top1_pn_word',
    name: 'TOP1品牌正負面文字雲',
    rule: ['brand']
  },
  {
    id: 'brand_main_pn_word',
    name: '主品牌(%brand%)正負面文字雲',
    rule: ['brand']
  },
  {
    id: 'brand_consumer_feature',
    name: '品牌消費者關注分類',
    rule: ['brand']
  },
  {
    id: 'brand_top1_word',
    name: 'TOP1品牌不分類文字雲',
    rule: ['brand']
  },
  {
    id: 'brand_main_word',
    name: '主品牌(%brand%)不分類文字雲',
    rule: ['brand']
  }
];

export const BRAND_HEALTH_TYPE = [
  {
    id: 'brand_main_volume_trend',
    name: '主品牌(%brand%)聲量趨勢',
    rule: ['brand']
  },
  {
    id: 'brand_main_series_WGS',
    name: '主品牌(%brand%)系列WGS定位',
    rule: ['brand', 'series']
  },
  {
    id: 'brand_main_product_volume_ranking',
    name: '主品牌(%brand%)產品聲量排行',
    rule: ['brand', 'product']
  },
  {
    id: 'brand_main_source_category_proportion',
    name: '主品牌(%brand%)來源類型佔比',
    rule: ['brand']
  },
  {
    id: 'brand_main_source_channel_ranking',
    name: '主品牌(%brand%)來源頻道排行',
    rule: ['brand']
  },
  {
    id: 'brand_main_sentiment_overview',
    name: '主品牌(%brand%)正負評總覽',
    rule: ['brand']
  },
  {
    id: 'brand_main_pn_word',
    name: '主品牌(%brand%)正負面文字雲',
    rule: ['brand']
  },
  {
    id: 'brand_main_consumer_feature',
    name: '主品牌(%brand%)消費者關注分類',
    rule: ['brand']
  },
  {
    id: 'brand_top1_feature_word',
    name: '主品牌(%brand%)TOP1分類文字雲',
    rule: ['brand']
  },
  {
    id: 'brand_top2_feature_word',
    name: '主品牌(%brand%)TOP2分類文字雲',
    rule: ['brand']
  },
  {
    id: 'brand_top3_feature_word',
    name: '主品牌(%brand%)TOP3分類文字雲',
    rule: ['brand']
  }
];

export const BRAND_EFFECT_TYPE = [
  {
    id: 'brand_volume_trend',
    name: '品牌聲量趨勢',
    rule: ['brand']
  },
  {
    id: 'brand_volume_ranking',
    name: '品牌聲量排行',
    rule: ['brand']
  },
  {
    id: 'brand_volume_proportion',
    name: '品牌聲量佔比',
    rule: ['brand']
  },
  {
    id: 'series_brand_volume_compare',
    name: '系列品牌聲量比較',
    rule: ['brand', 'series']
  },
  {
    id: 'product_brand_volume_compare',
    name: '產品品牌聲量比較',
    rule: ['brand', 'product']
  },
  {
    id: 'product_volume_ranking',
    name: '產品聲量排行',
    rule: ['product']
  },
  {
    id: 'industry_source_category_proportion',
    name: '行業來源類型佔比',
    rule: ['industry']
  },
  {
    id: 'main_product_source_category_proportion',
    name: '主品牌(%brand%)來源類型佔比',
    rule: ['brand']
  },
  {
    id: 'industry_source_channel_ranking',
    name: '行業來源頻道排行',
    rule: ['industry']
  },
  {
    id: 'main_product_source_channel_ranking',
    name: '主品牌(%brand%)來源頻道排行',
    rule: ['brand']
  },
  {
    id: 'industry_word',
    name: '行業不分類文字雲',
    rule: ['industry']
  },
  {
    id: 'main_brand_word',
    name: '主品牌(%brand%)不分類文字雲',
    rule: ['brand']
  },
  {
    id: 'brand_sentiment_overview',
    name: '品牌正負情緒總覽',
    rule: ['brand']
  }
];

export const BRAND_ROUTINE_TYPE = [
  {
    id: 'brand_volume_trend',
    name: '品牌聲量趨勢',
    rule: ['brand']
  },
  {
    id: 'brand_volume_ranking',
    name: '品牌聲量排行',
    rule: ['brand']
  },
  {
    id: 'brand_volume_proportion',
    name: '品牌聲量佔比',
    rule: ['brand']
  },
  {
    id: 'industry_source_category_proportion',
    name: '行業來源類型佔比',
    rule: ['industry']
  },
  {
    id: 'brand_source_category_proportion',
    name: '主品牌(%brand%)來源類型佔比',
    rule: ['brand']
  },
  {
    id: 'industry_source_channel_ranking',
    name: '行業來源頻道排行',
    rule: ['industry']
  },
  {
    id: 'brand_source_channel_ranking',
    name: '主品牌(%brand%)來源頻道排行',
    rule: ['brand']
  },
  {
    id: 'series_volume_proportion',
    name: '系列聲量佔比',
    rule: ['series']
  },
  {
    id: 'top1_series_product_volume_ranking',
    name: 'TOP1系列產品聲量排行',
    rule: ['series', 'product']
  },
  {
    id: 'top2_series_product_volume_ranking',
    name: 'TOP2系列產品聲量排行',
    rule: ['series', 'product']
  },
  {
    id: 'top3_series_product_volume_ranking',
    name: 'TOP3系列產品聲量排行',
    rule: ['series', 'product']
  },
  {
    id: 'brand_sentiment_overview',
    name: '品牌正負情緒總覽',
    rule: ['brand']
  },
  {
    id: 'brand_consumer_feature',
    name: '品牌消費者關注分類',
    rule: ['brand']
  },
  {
    id: 'industry_top1_feature_word',
    name: '行業TOP1分類文字雲',
    rule: ['industry']
  },
  {
    id: 'main_brand_top1_feature_word',
    name: '主品牌(%brand%)TOP1分類文字雲',
    rule: ['brand']
  },
  {
    id: 'industry_top2_feature_word',
    name: '行業TOP2分類文字雲',
    rule: ['industry']
  },
  {
    id: 'main_brand_top2_feature_word',
    name: '主品牌(%brand%)TOP2分類文字雲',
    rule: ['brand']
  },
  {
    id: 'industry_top3_feature_word',
    name: '行業TOP3分類文字雲',
    rule: ['industry']
  },
  {
    id: 'main_brand_top3_feature_word',
    name: '主品牌(%brand%)TOP3分類文字雲',
    rule: ['brand']
  }
];
