import React from 'react';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import TagCloud from 'react-tag-cloud';

import { WORDS_TABLE } from 'src/consts/tables';

import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Switch, Collapse, Table } from 'antd';

import styles from './styles.module.scss';

@observer
class PresentBrandWord extends React.Component {
  componentDidMount() {
    this.props.viewModel.didMount();
  }

  componentWillUnmount() {
    this.props.viewModel.willUnmount();
  }

  render() {
    const { viewModel } = this.props;
    return (
      <div className={styles.container}>
        <div id="presentBrandWord" className={styles.wordsContainer} />
      </div>
    );
  }
}

PresentBrandWord.propTypes = {
  viewModel: PropTypes.object
};

PresentBrandWord.defaultProps = {
  viewModel: {}
};


export default PresentBrandWord;
