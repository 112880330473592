import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';

import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Modal, Checkbox } from 'antd';

import PaperIcon from 'src/assets/PaperIcon.svg';
import SearchIcon from 'src/assets/MenuIcons/SearchIcon.svg';

import CheckItem from './CheckItem';

import styles from './styles.module.scss';

@observer
class SingleClassModal extends React.Component {
  render() {
    const { viewModel, limit } = this.props;
    return (
      <Modal
        open={viewModel.isModalOpen}
        closable={false}
        centered
        onCancel={viewModel.onClose}
        className="selectModal"
        width={422}
        footer={null}
      >
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <div className={styles.headerTitle}>
              <img src={PaperIcon} alt="paper" className={styles.titleIcon} />
              <div className={styles.titleText}>
                {viewModel.modalTitle}
              </div>
            </div>
            <div className={styles.headerClose}>
              <Button
                icon={<CloseOutlined />}
                type="link"
                className={styles.headerClose}
                onClick={viewModel.onClose}
              >
                {i18n.t('package_modal_close')}
              </Button>
            </div>
          </div>
          <div className={styles.modalContent}>
            <div className={styles.selectCard}>
              <div className={styles.cardHeader}>
                <div className={styles.cardTitle}>
                  {viewModel.modalTitle}
                </div>
                {
                  limit
                    ? (
                      <div className={styles.selectLimitText}>
                        {`至多可以選擇${limit}個品牌`}
                      </div>
                    )
                    : (
                      <div className={styles.cardSelectAll}>
                        <Checkbox
                          indeterminate={viewModel.indeterminate}
                          checked={viewModel.isAllChecked}
                          onChange={viewModel.onAllSelect}

                        />
                        <div className={styles.selectAllText}>
                          {i18n.t('package_modal_select_all')}
                        </div>
                      </div>
                    )
                }

              </div>
              <div className={styles.cardContent}>
                <div className={styles.searchInputContainer}>
                  <input
                    className={styles.searchInput}
                    placeholder={i18n.t('package_modal_input_placeholder')}
                    value={viewModel.keyword}
                    onChange={viewModel.onKeywordChange}
                    onKeyDown={viewModel.onKeyDown}
                  />
                  <div
                    className={styles.searchBtn}
                    onClick={viewModel.onSearchSubmit}
                  >
                    <img src={SearchIcon} alt="" />
                  </div>
                </div>
                <div className={styles.levelCheckboxContainer}>
                  {
                    viewModel.searchItems.map((el) => {
                      return <CheckItem key={el.id} viewModel={el} disabled={limit && viewModel.cacheSelectedLength === limit} />;
                    })
                  }
                </div>
              </div>
            </div>
          </div>
          <div className={styles.modalFooter}>
            <div className={styles.btnContainer}>
              <Button
                className={clsx(styles.btn, styles.cleanBtn)}
                icon={<CloseOutlined />}
                onClick={viewModel.onCleanAll}
              >
                {i18n.t('package_modal_clean')}
              </Button>
              <Button
                className={clsx(styles.btn, styles.applyBtn)}
                icon={<CheckOutlined />}
                type="primary"
                onClick={viewModel.onSubmit}
              >
                {i18n.t('package_modal_apply')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

SingleClassModal.propTypes = {
  viewModel: PropTypes.object,
  limit: PropTypes.number
};

SingleClassModal.defaultProps = {
  viewModel: {},
  limit: null
};


export default SingleClassModal;
