import IndustryVolumeTrend from './IndustryVolumeTrend';
import IndustrySourceChannelRanking from './IndustrySourceChannelRanking';
import IndustryKeywordHot from './IndustryKeywordHot';
import IndustryConsumerFeature from './IndustryConsumerFeature';
import IndustryTopsCloud from './IndustryTopsCloud';
import BrandVolumeTrend from './BrandVolumeTrend';
import SeriesVolumeTrend from './SeriesVolumeTrend';
import ProductVolumeTrend from './ProductVolumeTrend';
import BrandReputation from './BrandReputation';
import ProductReputation from './ProductReputation';
import BrandSourceCategoryCompare from './BrandSourceCategoryCompare';
import BrandSentimentOverview from './BrandSentimentOverview';

import IndustryVolumeTrendViewModel from './IndustryVolumeTrend/viewModel';
import IndustrySourceChannelRankingViewModel from './IndustrySourceChannelRanking/viewModel';
import IndustryKeywordHotViewModel from './IndustryKeywordHot/viewModel';
import IndustryConsumerFeatureViewModel from './IndustryConsumerFeature/viewModel';
import IndustryTopsCloudViewModel from './IndustryTopsCloud/viewModel';
import BrandVolumeTrendViewModel from './BrandVolumeTrend/viewModel';
import SeriesVolumeTrendViewModel from './SeriesVolumeTrend/viewModel';
import ProductVolumeTrendViewModel from './ProductVolumeTrend/viewModel';
import BrandReputationViewModel from './BrandReputation/viewModel';
import ProductReputationViewModel from './ProductReputation/viewModel';
import BrandSourceCategoryCompareViewModel from './BrandSourceCategoryCompare/viewModel';
import BrandSentimentOverviewViewModel from './BrandSentimentOverview/viewModel';

export default class IndustryTrendChartFactory {
  static createChartViewModel(id, parent) {
    switch (id) {
      case 'industry_volume_trend':
        return new IndustryVolumeTrendViewModel(parent);
      case 'industry_source_channel_ranking':
        return new IndustrySourceChannelRankingViewModel(parent);
      case 'industry_keyword_hot':
        return new IndustryKeywordHotViewModel(parent);
      case 'industry_consumer_feature':
        return new IndustryConsumerFeatureViewModel(parent);
      case 'industry_top1_word':
        return new IndustryTopsCloudViewModel(parent, 'Top1');
      case 'industry_top2_word':
        return new IndustryTopsCloudViewModel(parent, 'Top2');
      case 'industry_top3_word':
        return new IndustryTopsCloudViewModel(parent, 'Top3');
      case 'brand_volume_trend':
        return new BrandVolumeTrendViewModel(parent);
      case 'series_volume_trend':
        return new SeriesVolumeTrendViewModel(parent);
      case 'product_volume_trend':
        return new ProductVolumeTrendViewModel(parent);
      case 'brand_reputation':
        return new BrandReputationViewModel(parent);
      case 'product_reputation':
        return new ProductReputationViewModel(parent);
      case 'brand_source_category_compare':
        return new BrandSourceCategoryCompareViewModel(parent);
      case 'brand_sentiment_volume':
        return new BrandSentimentOverviewViewModel(parent);
      default:
        return null;
    }
  }

  static createChartView(item) {
    const { id, viewModel } = item;

    switch (id) {
      case 'industry_volume_trend':
        return (<IndustryVolumeTrend key={id} viewModel={viewModel} />);
      case 'industry_source_channel_ranking':
        return (<IndustrySourceChannelRanking key={id} viewModel={viewModel} />);
      case 'industry_keyword_hot':
        return (<IndustryKeywordHot key={id} viewModel={viewModel} />);
      case 'industry_consumer_feature':
        return (<IndustryConsumerFeature key={id} viewModel={viewModel} />);
      case 'industry_top1_word':
      case 'industry_top2_word':
      case 'industry_top3_word':
        return (<IndustryTopsCloud key={id} viewModel={viewModel} />);
      case 'brand_volume_trend':
        return (<BrandVolumeTrend key={id} viewModel={viewModel} />);
      case 'series_volume_trend':
        return (<SeriesVolumeTrend key={id} viewModel={viewModel} />);
      case 'product_volume_trend':
        return (<ProductVolumeTrend key={id} viewModel={viewModel} />);
      case 'brand_reputation':
        return (<BrandReputation key={id} viewModel={viewModel} />);
      case 'product_reputation':
        return (<ProductReputation key={id} viewModel={viewModel} />);
      case 'brand_source_category_compare':
        return (<BrandSourceCategoryCompare key={id} viewModel={viewModel} />);
      case 'brand_sentiment_volume':
        return (<BrandSentimentOverview key={id} viewModel={viewModel} />);
      default:
        return null;
    }
  }
}
