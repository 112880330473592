import dayjs from 'dayjs';

export default class BulletinInfoModel {
  constructor(data) {
    const {
      _id,
      title,
      startDate,
      endDate,
      method,
      contents
    } = data;

    const {
      isAISummary,
      amountToShow,
      frequencies,
      receivers
    } = method.settings;

    const todaySet = new Set(['noon', 'night']);
    const hasToday = frequencies.some((el) => todaySet.has(el));

    const generalFrequencies = frequencies.filter((el) => !todaySet.has(el));
    if (hasToday) {
      generalFrequencies.push('today');
    }
    const todayFrequencies = frequencies.filter((el) => todaySet.has(el));

    this.id = _id;
    this.name = title;
    this.time = [dayjs(startDate), dayjs(endDate)];
    this.amountToShow = amountToShow;
    this.isAISummary = isAISummary;
    this.frequencies = generalFrequencies;
    this.todayFrequencies = todayFrequencies;
    this.receivers = receivers;
    this.contents = contents;

  }

  static fromRes(data) {
    return new BulletinInfoModel(data);
  }
}
