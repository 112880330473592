export default class OpinionDetailModel {
  constructor(data) {
    const { post, comment } = data;

    const {
      url,
      author,
      website,
      channel,
      title,
      content,
      replyId,
      date,
      viewCount,
      shareCount,
      commentCount,
      likeCount
    } = post;

    this.post = {
      url,
      hitPostType: replyId ? `回文${replyId < 99999 ? replyId : ''}` : '主文',
      author,
      website,
      channel,
      title,
      content,
      date,
      viewCount,
      shareCount,
      commentCount,
      likeCount
    };

    this.comment = comment.map((el, i) => ({
      order: el.replyId < 99999 ? el.replyId : '---',
      author: el.author,
      date: el.date,
      content: el.content
    }));

  }

  static fromRes(data) {
    return new OpinionDetailModel(data);
  }
}
