import { makeObservable, observable, action, computed } from 'mobx';
import { encodeURI } from 'js-base64';
import { message } from 'antd';
import * as xlsx from 'xlsx';
import html2canvas from 'html2canvas';

import optionsStore from 'src/stores/optionsStore';
import { getHost } from 'src/utils';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import { COLOR_SET } from 'src/consts/chart';

export default class ChannelViewModel {
  @observable originData = null;
  @observable chartData = null;
  @observable searchData = null;
  @observable options = null;

  @computed get excelTitle() {
    return [
      { key: 'channel', header: '來源頻道' },
      { key: 'volume', header: '聲量' }
    ];
  }

  @computed get excelData() {
    const excel = [];
    if (!this.originData) {
      return excel;
    }

    for (let i = 0; i < this.originData.length; i += 1) {
      const item = this.originData[i];
      excel.push({
        channel: item.name,
        volume: item.count
      });
    }

    return excel;
  }

  constructor() {
    makeObservable(this);
  }

  @action update = (data, searchData) => {
    this.updateContent(data, searchData);
    this.updateOptions(searchData);
  };

  @action updateContent = (data, searchData) => {
    if (!data || data.length === 0) {
      return;
    }

    this.originData = data;

    const limitedData = data.slice(0, 20);

    const labels = [];
    const datas = [];
    const channelId = [];

    for (let i = 0; i < limitedData.length; i += 1) {
      const item = limitedData[i];
      labels.push(item.name);
      datas.push(item.count);
      channelId.push(item.id);
    }

    this.chartData = {
      labels,
      datasets: [{
        label: searchData.keyword,
        data: datas,
        channelId,
        backgroundColor: COLOR_SET[0]
      }]
    };
  };

  @action updateOptions = (searchData) => {
    this.searchData = searchData;
    const onPointClick = (elements, chart) => {
      if (elements.length === 0) {
        return;
      }
      const { datasetIndex, index } = elements[0];
      const womCount = chart.data.datasets[datasetIndex].data[index];
      const channelId = chart.data.datasets[datasetIndex].channelId[index];
      const channelName = chart.data.labels[index];

      const { keyword, postType, womType, date: { gte, lte } } = searchData;

      const archiveData = {
        keyword,
        ...(womType && {
          womType
        }),
        ...(postType && {
          postType
        }),
        channel: { id: channelId, name: channelName },
        chart: {
          name: '輿情搜尋/摘要圖表/來源頻道排行'
        },
        date: {
          gte,
          lte: lte ?? gte
        },
        womCount
      };

      console.log('archiveData', archiveData);

      const json = JSON.stringify(archiveData);
      const result = encodeURI(json);
      const url = `${getHost()}/opinion-topics?result=${result}`;

      window.open(url, '_blank');
    };

    this.options = {
      responsive: true,
      event: ['click'],
      indexAxis: 'y',
      scales: {
        x: {
          type: 'linear',
          display: true,
          grace: '20%',
          title: {
            display: true,
            text: '聲量'
          }
        },
        y: {
          ticks: {
            callback: (value, index, values) => {
              if (this.originData[index]?.name?.length > 25) {
                return `${this.originData[index]?.name?.substr(0, 25)}...`;
              }
              return this.originData[index]?.name;
            }
          }
        }
      },
      onHover: (event, elements) => {
        const native = event.native;
        if (!native) {
          return;
        }
        const target = native.target;
        if (!target) {
          return;
        }
        if (elements.length > 0) {
          target.style.cursor = 'pointer';
        } else {
          target.style.cursor = 'default';
        }
      },
      onClick: (ev, elements, chart) => onPointClick(elements, chart),
      plugins: {
        legend: {
          position: 'bottom'
        },
        datalabels: {
          display: false
        }
      }
    };
  };

  @action onDownloadExcel = () => {
    this.isDownload = false;
    if (!this.excelTitle || !this.excelData) {
      message.error('csv檔不存在');
      return;
    }
    const data = [...this.excelData];
    const header = this.excelTitle.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [this.excelTitle.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    const wordbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wordbook, workSheet, '來源頻道排行');
    xlsx.writeFile(wordbook, `輿情搜尋-${this.searchData.keyword}-來源頻道排行-${this.searchData.date.gte}-${this.searchData.date.lte}.xlsx`);
  };

  @action appendSheet = (wordbook) => {
    this.isDownload = false;
    if (!this.excelTitle || !this.excelData) {
      message.error('csv檔不存在');
      return;
    }
    const data = [...this.excelData];
    const header = this.excelTitle.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [this.excelTitle.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    xlsx.utils.book_append_sheet(wordbook, workSheet, '來源頻道排行');
  };

  @action onDownload = async () => {
    await optionsStore.onImgDownload();
    const chart = document.querySelector('.opinion-channel-ranking');
    const canvas = await html2canvas(chart);
    const a = document.createElement('a');
    a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
    a.download = `輿情搜尋-${this.searchData.keyword}-來源頻道排行-${this.searchData.date.gte}-${this.searchData.date.lte}.jpg`;
    a.click();
    optionsStore.onImgDownloadEnd();
  };
}
