import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';

import { CloseOutlined, CheckOutlined, CaretDownFilled, StopOutlined, DownloadOutlined, SaveOutlined, ReloadOutlined, PauseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Table, Modal, Collapse, Progress, Empty, Tooltip } from 'antd';
import PaperIcon from 'src/assets/PaperIcon.svg';
import Select from 'src/components/select';
import RangeDatePicker from 'src/components/datepicker';

import { ADVANCE_ANALYSIS_OPTIONS, WORD_OF_MOUTH_TYPE, MARKETING_7P_CHART_TYPE, POST_TYPE, E_WOM_TYPE, WORD_INDEX_TYPE, WORD_COUNT_TYPE } from 'src/consts';

import userStore from 'src/stores/userStore';
import optionsStore from 'src/stores/optionsStore';
import packageStore from 'src/stores/packageStore';
import SourceSelectModal from 'src/components/SourceSelectModal';
import InfoHeader from 'src/components/InfoHeader';
import CategoryPopoverSelect from 'src/components/CategoryPopoverSelect';

import SingleClassModal from '../components/SingleClassModal';
import ChartItem from '../components/ChartItem';
import WordChartItem from '../components/WordChartItem';
import Marketing7PageViewModel from './viewModel';


import styles from './styles.module.scss';

@observer
class Marketing7Page extends React.Component {
  constructor() {
    super();

    this.viewModel = new Marketing7PageViewModel();
  }

  componentDidMount() {
    this.viewModel.didMount();
  }

  componentWillUnmount() {
    this.viewModel.once?.();
  }

  render() {
    return (
      <>
        <div className={styles.pageContainer}>
          <InfoHeader
            breadcrumb={['進階分析', '行銷7P分析']}
            showPicker={this.viewModel.selectedSec !== 'setting'}
            multiViewModel={this.viewModel.multipleDatePickerViewModel}
            callback={this.viewModel.getGraphicData}
          />
          {
            userStore.activeUserType !== 'customer'
            && (
              <div className={styles.sectionContainer}>
                <div className={styles.segmentContainer}>
                  {
                    ADVANCE_ANALYSIS_OPTIONS.map((el) => {
                      return (
                        <div
                          key={el.id}
                          className={clsx(
                            styles.segmentItem,
                            this.viewModel.selectedSec === el.id && styles.active
                          )}
                          onClick={() => this.viewModel.onSecChange(el.id)}
                        >
                          {el.name}
                        </div>
                      );
                    })
                  }
                </div>
                {
                  this.viewModel.selectedSec === 'setting'
                  && (
                    <div className={styles.settingContainer}>
                      <div className={styles.settingContent}>
                        <div className={styles.settingTitle}>
                          {i18n.t('advance_setting')}
                        </div>
                        <div className={styles.dateContainer}>
                          <div className={styles.itemTitle}>
                            {i18n.t('advance_date_setting')}
                          </div>
                          <RangeDatePicker
                            value={this.viewModel.date}
                            onCalendarChange={this.viewModel.onDateSelect}
                            changeOnBlur
                            allowClear
                            disabled={this.viewModel.disabledAdjustSetting}
                            disabledDate={(current) => {
                              return current < dayjs(packageStore.packageStartDate).startOf('day')
                                || current > dayjs(packageStore.packageEndDate).endOf('day')
                                || current > dayjs().endOf('day')
                                || (this.viewModel.date?.[0] && current > this.viewModel.date?.[0].add(548, 'day').startOf('day'))
                                || (this.viewModel.date?.[1] && current < this.viewModel.date?.[1].subtract(548, 'day'));
                            }}
                          />
                        </div>
                        <div className={styles.searchItem}>
                          <div className={styles.itemHeader}>
                            <div className={styles.itemTitle}>
                              {i18n.t('advance_analysis_item_title')}
                            </div>
                          </div>
                          <Button
                            className={styles.selectButton}
                            onClick={this.viewModel.onBrandSelectButtonClick}
                            disabled={this.viewModel.disabledAdjustSetting}
                          >
                            {i18n.t('advance_analysis_brand_item_title')}
                          </Button>
                          <div
                            className={clsx(
                              styles.preview,
                              this.viewModel.selectedBrand.length === 0 && styles.unselected
                            )}
                          >
                            {this.viewModel.brandSelectViewModel.text}
                          </div>
                        </div>
                        <div className={styles.searchItem}>
                          <div className={styles.itemHeader}>
                            <div className={styles.itemTitle}>
                              {i18n.t('advance_analysis_source_title')}
                            </div>
                          </div>
                          <Button
                            className={styles.selectButton}
                            onClick={this.viewModel.onSourceSelectButtonClick}
                            disabled={this.viewModel.disabledAdjustSetting}
                          >
                            {i18n.t('advance_analysis_source_item_title')}
                          </Button>
                          <div
                            className={clsx(
                              styles.preview,
                              this.viewModel.sourceSelectedIncomplete && styles.unselected
                            )}
                          >
                            {
                              this.viewModel.sourceSelectedIncomplete
                                ? i18n.t('advance_item_no_data').replace('%type%', i18n.t('advance_analysis_source_item_title'))
                                : (
                                  <>
                                    {
                                      this.viewModel.sourceSelectModalViewModel.selectedChannelsByCategory.map((el) => (
                                        <span key={el.id}>
                                          {`${el.name}(${el.count}), `}
                                        </span>
                                      ))
                                    }
                                    <span>
                                      {`共計 {${this.viewModel.totalChannels}}`}
                                    </span>
                                  </>
                                )
                            }
                          </div>
                        </div>
                        <div className={styles.selectRow}>
                          <div className={styles.rowTitle}>
                            {i18n.t('advance_vom_type')}
                          </div>
                          <Select
                            size="large"
                            suffixIcon={<CaretDownFilled style={{ pointerEvents: 'none' }} />}
                            className={styles.select}
                            placeholder={i18n.t('advance_vom_type_placeholder')}
                            options={WORD_OF_MOUTH_TYPE}
                            value={this.viewModel.selectedWOM}
                            onChange={this.viewModel.onVOMSelect}
                            disabled={this.viewModel.disabledAdjustSetting}
                          />
                        </div>
                        <div className={styles.selectRow}>
                          <div className={styles.rowTitle}>
                            {i18n.t('advance_feature_set')}
                          </div>
                          <Select
                            size="large"
                            suffixIcon={<CaretDownFilled style={{ pointerEvents: 'none' }} />}
                            className={styles.select}
                            placeholder={i18n.t('advance_feature_set_placeholder')}
                            options={this.viewModel.featureSets}
                            value={this.viewModel.selectedFeatureSets}
                            onChange={this.viewModel.onFeatureSetSelect}
                            disabled={this.viewModel.disabledAdjustSetting}
                          />
                        </div>
                      </div>
                      {
                        this.viewModel.jobId
                        && (
                          <div className={styles.progressContainer}>
                            <div className={styles.barContainer}>
                              <Progress
                                percent={this.viewModel.status.progress}
                                showInfo={false}
                                strokeColor="#0367A5"
                              />
                              <Button
                                className={styles.stopBtn}
                                icon={<PauseCircleOutlined />}
                                disabled={!this.viewModel.disabledAdjustSetting}
                                onClick={this.viewModel.stopAnalysis}
                              >
                                終止分析
                              </Button>
                            </div>
                            <div className={styles.infoText}>
                              {this.viewModel.progressText}
                            </div>
                          </div>
                        )
                      }
                      <div className={styles.actions}>
                        <Button
                          className={styles.actionBtn}
                          icon={<CloseOutlined />}
                          onClick={this.viewModel.onClean}
                          disabled={this.viewModel.disabledAdjustSetting}
                        >
                          {i18n.t('advance_search_cross_setting_clean_setting')}
                        </Button>
                        <Button
                          className={clsx(styles.actionBtn, styles.submitBtn)}
                          type="primary"
                          icon={<CheckOutlined />}
                          onClick={this.viewModel.onSubmit}
                          disabled={this.viewModel.disabledAdjustSetting}
                        >
                          {i18n.t('advance_search_cross_setting_submit')}
                        </Button>
                      </div>
                    </div>
                  )
                }
              </div>
            )
          }
          {
            this.viewModel.selectedSec === 'result'
            && (
              <div>
                {
                  this.viewModel.status.stage === 'DONE'
                    ? (
                      <>
                        <Collapse
                          className={styles.collapse}
                        >
                          <Collapse.Panel
                            showArrow={false}
                            header={(
                              <div
                                className={styles.collapseHeader}
                              >
                                <div>
                                  {i18n.t('advance_setting_res_title')}
                                </div>
                                <CaretDownFilled />
                              </div>
                            )}
                          >
                            <div className={styles.resSetting}>
                              <div className={styles.setting}>
                                <div className={styles.settingRow}>
                                  {`${i18n.t('advance_date_setting')}：${this.viewModel.cacheContent.dateString}`}
                                </div>
                                <div className={styles.settingRow}>
                                  {`${i18n.t('advance_analysis_item_title')}：${this.viewModel.cacheContent.brand}`}
                                </div>
                                <div className={styles.settingRow}>
                                  {`${i18n.t('advance_analysis_source_item_title')}：`}
                                  {
                                    this.viewModel.cacheContent.source.map((el) => (
                                      <span key={el.id}>
                                        {`${el.name}(${el.count}), `}
                                      </span>
                                    ))
                                  }
                                  <span>
                                    {`共計 {${this.viewModel.cacheContent.sourceTotal}}`}
                                  </span>
                                </div>
                                <div className={styles.settingRow}>
                                  {`${i18n.t('advance_vom_type')}：${E_WOM_TYPE[this.viewModel.cacheContent.vom]}`}
                                </div>
                              </div>
                            </div>
                          </Collapse.Panel>
                        </Collapse>
                        <div className={styles.filter}>
                          <div className={styles.filterTitle}>
                            {i18n.t('chart_page_statistic_filter')}
                          </div>
                          <Select
                            placeholder={i18n.t('filter_post_type_all')}
                            options={POST_TYPE}
                            value={this.viewModel.selectedPostType}
                            onSelect={this.viewModel.onPostTypeChange}
                            popupMatchSelectWidth={false}
                            popupClassName={styles.sortMenu}
                            className={clsx('sortSelect', styles.filterSelect)}
                            suffixIcon={<CaretDownFilled style={{ pointerEvents: 'none' }} />}
                          />
                          <CategoryPopoverSelect
                            viewModel={this.viewModel.categoryPopover}
                            title="來源類型"
                          />
                          <Button
                            className={clsx(styles.btn, styles.submitBtn)}
                            onClick={this.viewModel.getGraphicData}
                            icon={<CheckOutlined />}
                          >
                            {i18n.t('btn_filter_submit')}
                          </Button>
                          {
                            this.viewModel.isShowStatisticClean
                            && (
                              <Button
                                className={clsx(styles.btn, styles.cleanBtn)}
                                icon={<CloseOutlined />}
                                type="link"
                                onClick={this.viewModel.onCleanStatisticFilter}
                              >
                                {i18n.t('btn_filter_clean_all')}
                              </Button>
                            )
                          }
                        </div>
                        <div className={styles.resultContainer}>
                          <div className={styles.segmentContainer}>
                            {
                              MARKETING_7P_CHART_TYPE.map((el) => {
                                return (
                                  <div
                                    key={el.id}
                                    className={clsx(
                                      styles.segmentItem,
                                      this.viewModel.selectedChart === el.id && styles.active
                                    )}
                                    onClick={() => this.viewModel.onChartChange(el.id)}
                                  >
                                    {el.name}
                                  </div>
                                );
                              })
                            }
                          </div>
                          <div className={clsx(styles.chartContainer, this.viewModel.selectedChart !== 'words' && 'download-chart')}>
                            <div className={clsx(styles.actionsContainer, this.viewModel.selectedChart === 'words' && styles.border)}>
                              {
                                this.viewModel.selectedChart === 'words'
                                  ? (
                                    <div className={styles.chartFilter}>
                                      <div className={styles.filterTitle}>
                                        {i18n.t('chart_page_graphic_filter')}
                                      </div>
                                      {/* <div className={styles.filterItem}>
                                        <div className={styles.itemTitle}>
                                          {i18n.t('chart_page_index_title')}
                                        </div>
                                        <Select
                                          options={WORD_INDEX_TYPE}
                                          value={this.viewModel.selectedIndex}
                                          onSelect={this.viewModel.onChartIndexChange}
                                          popupMatchSelectWidth={false}
                                          popupClassName={styles.sortMenu}
                                          className={clsx('sortSelect', styles.filterSelect)}
                                          suffixIcon={<CaretDownFilled style={{ pointerEvents: 'none' }} />}
                                        />
                                      </div> */}
                                      <div className={styles.filterItem}>
                                        <div className={styles.itemTitle}>
                                          {i18n.t('chart_page_word_count_title')}
                                        </div>
                                        <Select
                                          options={WORD_COUNT_TYPE}
                                          value={this.viewModel.selectedCount}
                                          onSelect={this.viewModel.onChartCountChange}
                                          popupMatchSelectWidth={false}
                                          popupClassName={styles.sortMenu}
                                          className={clsx('sortSelect', styles.filterSelect)}
                                          suffixIcon={<CaretDownFilled style={{ pointerEvents: 'none' }} />}
                                        />
                                      </div>
                                      <Button
                                        className={clsx(styles.btn, styles.submitBtn)}
                                        icon={<CheckOutlined />}
                                        onClick={this.viewModel.getGraphicData}
                                      >
                                        {i18n.t('btn_filter_submit')}
                                      </Button>
                                      {
                                        this.viewModel.isShowChartClean
                                        && (
                                          <Button
                                            className={clsx(styles.btn, styles.cleanBtn)}
                                            icon={<CloseOutlined />}
                                            type="link"
                                            onClick={this.viewModel.onCleanChartFilter}
                                          >
                                            {i18n.t('btn_filter_clean_all')}
                                          </Button>
                                        )
                                      }
                                    </div>
                                  )
                                  : (
                                    <div className={styles.chartContent}>
                                      <div className={styles.chartTitle}>
                                        <span className={styles.titleText}>
                                          行銷7P分析-雷達圖
                                        </span>
                                        <span className={styles.tooltip} style={{ display: optionsStore.isImgDownload ? 'none' : 'inline-block' }}>
                                          <Tooltip
                                            title={(
                                              <div className={styles.descContainer}>
                                                針對行銷7P量身打造了獨特的分類詞庫，並運用獨家演算法，以掌握該品牌之Product (產品)、Place (地點)、Price (價格)、Promotion (促銷/宣傳)、People/Participants (參與人員)、Process (過程)、Physical Evidence(有形的展示)的消費者真實回饋。
                                              </div>
                                            )}
                                            placement="rightTop"
                                          >
                                            <QuestionCircleOutlined />
                                          </Tooltip>
                                        </span>
                                      </div>
                                    </div>
                                  )
                              }
                              <div className={styles.btnContainer}>
                                {
                                  this.viewModel.selectedChart === 'words'
                                  && (
                                    <Tooltip
                                      title="查看屏蔽名單"
                                      placement="bottom"
                                    >
                                      <Button
                                        icon={<StopOutlined />}
                                        onClick={this.viewModel.onStopModalOpen}
                                      />
                                    </Tooltip>
                                  )
                                }
                                <Tooltip
                                  title="下載數據"
                                  placement="bottom"
                                >
                                  <Button
                                    icon={<DownloadOutlined />}
                                    onClick={this.viewModel.onDownloadCsv}
                                    style={{ display: optionsStore.isLoading && this.viewModel.selectedChart === 'radar' ? 'none' : 'block' }}
                                  />
                                </Tooltip>
                                <Tooltip
                                  title="下載圖表"
                                  placement="bottom"
                                >
                                  <Button
                                    icon={<SaveOutlined />}
                                    onClick={this.viewModel.onDownload}
                                    style={{ display: optionsStore.isLoading && this.viewModel.selectedChart === 'radar' ? 'none' : 'block' }}
                                  />
                                </Tooltip>
                              </div>
                            </div>
                            <div ref={this.viewModel.ref}>
                              {
                                this.viewModel.selectedChart === 'radar'
                                  ? (
                                    <ChartItem viewModel={this.viewModel.radarChart} />
                                  )
                                  : (
                                    <WordChartItem viewModel={this.viewModel.wordChart} />
                                  )
                              }
                            </div>
                          </div>
                        </div>
                      </>
                    )
                    : (
                      <div className={styles.emptyContainer}>
                        <Empty
                          description="資料分析中，請稍後再試。"
                        />
                      </div>
                    )
                }

              </div>
            )
          }
        </div>
        <Modal
          open={this.viewModel.isStopModalOpen}
          centered
          closable={false}
          footer={null}
          className={styles.blockModal}
          onCancel={this.viewModel.onStopModalClose}
          width={928}
        >
          <div className={styles.modalHeader}>
            <div className={styles.modalTitle}>
              <img src={PaperIcon} alt="paper" className={styles.titleIcon} />
              <div className={styles.modalText}>
                {i18n.t('wordbook_page_block_modal_title')}
              </div>
            </div>
            <Button
              icon={<CloseOutlined />}
              type="link"
              onClick={this.viewModel.onStopModalClose}
            >
              關閉
            </Button>
          </div>
          <div className={styles.tableContainer}>
            <Table
              key={this.viewModel.blockWords.length}
              rowClassName={styles.row}
              rowKey={(item) => item.id}
              dataSource={this.viewModel.blockWords}
              pagination={false}
              className="packagesTable"
            >
              <Table.Column
                title={i18n.t('wordbook_page_table_name')}
                dataIndex="name"
                key="name"
                className={styles.columns}
                width="50%"
                render={(_, record) => {
                  return (
                    <div>
                      {record.name}
                    </div>
                  );
                }}
              />
              <Table.Column
                title={i18n.t('wordbook_page_table_action_title')}
                dataIndex="name"
                key="name"
                className={styles.columns}
                width="50%"
                render={(_, record) => {
                  return (
                    <Button
                      icon={<ReloadOutlined />}
                      type="text"
                      onClick={() => this.viewModel.onRemoveWord(record.id)}
                    >
                      {i18n.t('wordbook_page_table_action_recover')}
                    </Button>
                  );
                }}
              />
            </Table>
          </div>
        </Modal>
        {
          userStore.activeUserType !== 'customer'
          && (
            <>
              <SourceSelectModal viewModel={this.viewModel.sourceSelectModalViewModel} />
              <SingleClassModal viewModel={this.viewModel.brandSelectViewModel} />
            </>
          )
        }
      </>
    );
  }
}

Marketing7Page.propTypes = {

};

Marketing7Page.defaultProps = {

};


export default Marketing7Page;
