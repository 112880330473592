import { makeObservable, observable, action, runInAction } from 'mobx';
import * as xlsx from 'xlsx';
import { message } from 'antd';

import optionsStore from 'src/stores/optionsStore';
import OpinionService from 'src/services/opinion';

import DayTrendViewModel from 'src/components/CustomizeLayout/sections/chart/charts/DayTrend/viewModel';
import MonthTrendViewModel from './charts/MonthTrend/viewModel';
import CategoryViewModel from './charts/Category/viewModel';
import ChannelViewModel from './charts/Channel/viewModel';
import SentimentViewModel from './charts/Sentiment/viewModel';
import WordViewModel from './charts/Word/viewModel';

export default class OpinionChartSectionViewModel {
  @observable keyword = '';
  @observable searchData = {};

  @observable isLoading = false;
  @observable volume = 0;
  @observable pnRatio = 0;
  @observable hotCategory = '-';
  @observable hotWebsite = '-';
  @observable hotChannel = '-';
  @observable hotWord = '-';
  @observable hotTopic = '-';
  @observable hotTopicUrl = '';

  @observable category = new CategoryViewModel();
  @observable channel = new ChannelViewModel();
  @observable dayTrend = new DayTrendViewModel();
  @observable monthTrend = new MonthTrendViewModel();
  @observable sentiment = new SentimentViewModel();
  @observable word = new WordViewModel();

  constructor() {
    makeObservable(this);
  }

  @action getChartContent = async (searchData) => {
    const { channel: channelItem, ...rest } = searchData;
    const reqData = {
      ...rest,
      ...(channelItem && { channel: channelItem.map((item) => item.id) })
    };
    this.isLoading = true;
    try {
      optionsStore.setLoading('getOpinionChart');
      const {
        summary,
        categories,
        channels,
        dayTrend,
        monthTrend,
        sentiment,
        word
      } = await OpinionService.getOpinionChart(reqData);
      const {
        count,
        pn,
        category,
        website,
        channel,
        post
      } = summary;

      const filteredWord = word.filter((item) => item.name !== searchData.keyword);

      this.dayTrend.update(dayTrend, searchData);
      this.monthTrend.update(monthTrend, searchData);
      this.category.update(categories, searchData);
      this.channel.update(channels, searchData);
      this.sentiment.update(sentiment, searchData);
      this.word.update(filteredWord, searchData);

      runInAction(() => {
        this.keyword = searchData.keyword;
        this.searchData = searchData;

        this.volume = count;
        this.pnRatio = pn;
        this.hotCategory = category;
        this.hotWebsite = website;
        this.hotChannel = channel;
        this.hotWord = filteredWord[0]?.name || '';
        this.hotTopic = post.title;
        this.hotTopicUrl = post.url;
      });
    } catch (error) {
      console.log(error);
    } finally {
      optionsStore.setCompleted('getOpinionChart');
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action clearSummary = () => {
    this.volume = 0;
    this.pnRatio = 0;
    this.hotCategory = '-';
    this.hotWebsite = '-';
    this.hotChannel = '-';
    this.hotWord = '-';
    this.hotTopic = '-';
    this.hotTopicUrl = '-';
  };

  @action onDownloadAllChart = async () => {
    try {
      await optionsStore.onImgDownload();
      const queue = [
        () => this.dayTrend.onDownload(),
        () => this.monthTrend.onDownload(),
        () => this.category.onDownload(),
        () => this.channel.onDownload(),
        () => this.sentiment.onDownload(),
        () => this.word.onDownload()
      ];
      queue.reduce(async (p, current) => {
        return p.then(async () => {
          await current();
          await new Promise((res) => {
            setTimeout(res, 500);
          });
        });
      }, Promise.resolve());
    } catch (error) {
      message.error('下載失敗，請稍後再試。');
    } finally {
      runInAction(() => {
        optionsStore.onImgDownloadEnd();
      });
    }
  };

  @action onDownloadAllData = () => {
    const wordbook = xlsx.utils.book_new();
    this.dayTrend.appendSheet(wordbook);
    this.monthTrend.appendSheet(wordbook);
    this.category.appendSheet(wordbook);
    this.channel.appendSheet(wordbook);
    this.sentiment.appendSheet(wordbook);
    this.word.appendSheet(wordbook);
    xlsx.writeFile(wordbook, `輿情搜尋-${this.searchData.keyword}-${this.searchData.date.gte}-${this.searchData.date.lte}.xlsx`);
  };

}
