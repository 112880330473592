import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { getLevelText, getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { message } from 'antd';

import { RELATED_WORD_OPTIONS, SOURCE_CATEGORY } from 'src/consts';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';

import RelatedAnalysisService from 'src/services/charts/relatedAnalysis';
import BlockedWordsService from 'src/services/blockedwords';

import WordCloudViewModel from './WordCloud/viewModel';

export default class WordsChartViewModel {
  parent = {};
  @observable key = uuidv4();
  @observable activeTimeSec = '';
  @observable cloudViewModel = new WordCloudViewModel(this, 'present');
  @observable prevCloudViewModel = new WordCloudViewModel(this, 'previous');
  @observable selectedWord = {};

  @observable levelOptions = [];
  @observable selectedLevelOption = null;

  @observable selectedType = 'who';

  @observable isModalOpen = false;
  @observable isLoading = true;

  @computed get typeItems() {
    return RELATED_WORD_OPTIONS.map((el) => ({
      key: el.id,
      label: el.name
    }));
  }

  @computed get selectedLevel() {
    return this.levelOptions.find((el) => el.value === this.selectedLevelOption);
  }

  @computed get activeWords() {
    const target = this.levelOptions.find((el) => el.value === this.selectedLevelOption);
    const targetWord = target.chart.find((el) => el.id === this.selectedType)?.data ?? [];

    return targetWord ?? [];
  }

  @computed get activePrevWords() {
    const target = this.levelOptions.find((el) => el.value === this.selectedLevelOption);
    const targetWord = target.prevChart.find((el) => el.id === this.selectedType).data;

    return targetWord ?? [];
  }

  @computed get chartTitle() {
    return `${this.selectedLevel?.label ?? getLevelText(this.parent.level)}-人事時地物`;
  }

  @computed get chartDesc() {
    return `在時間區間內，TOP${this.parent.level === 'product' ? 20 : 10}各${getLevelText(this.parent.level)}的人事時地物之詞頻或權重。`;
  }

  constructor(parent) {
    makeObservable(this);

    this.init(parent);
  }

  @action init = (parent) => {
    this.parent = parent;
  };

  @action getData = async () => {
    try {

      if (!this.selectedLevelOption) {
        return;
      }

      // > 24/04/25 updating
      const checkLatest = await packageStore.checkLatest();
      if (checkLatest.needUpdate) {
        await packageStore.syncLatest(checkLatest);
        return;
      }

      const { currentDate, previousDate, hasCompare } = infoHeaderViewModel.multiViewModel;

      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      if (this.parent.level === 'brand') {
        const data = {
          metric: this.parent.selectedIndex,
          size: this.parent.selectedCount,
          ...(this.parent.selectedPostType !== 'all' && { postType: this.parent.selectedPostType }),
          ...(this.parent.selectedWOMType !== 'all' && { womType: this.parent.selectedWOMType }),
          ...(this.parent.categoryPopover.selected.length > 0 && { category: this.parent.categoryPopover.selected.map((el) => el.id) }),
          search: this.selectedLevelOption,
          ...(this.parent.blockWords.length > 0 && {
            disableTerm: this.parent.blockWords.map((el) => el.name)
          }),
          date: {
            gte: currentDate[0].format('YYYY-MM-DD'),
            lte: currentDate[1].format('YYYY-MM-DD')
          }
        };
        const prevData = {
          metric: this.parent.selectedIndex,
          size: this.parent.selectedCount,
          ...(this.parent.selectedPostType !== 'all' && { postType: this.parent.selectedPostType }),
          ...(this.parent.selectedWOMType !== 'all' && { womType: this.parent.selectedWOMType }),
          ...(this.parent.categoryPopover.selected.length > 0 && { category: this.parent.categoryPopover.selected.map((el) => el.id) }),
          search: this.selectedLevelOption,
          ...(this.parent.blockWords.length > 0 && {
            disableTerm: this.parent.blockWords.map((el) => el.name)
          }),
          date: {
            gte: previousDate[0].format('YYYY-MM-DD'),
            lte: previousDate[1].format('YYYY-MM-DD')
          }
        };
        const res = await RelatedAnalysisService.getBrandRelated(data, hasCompare ? prevData : null);
        runInAction(() => {
          const target = this.levelOptions.find((el) => el.value === this.selectedLevelOption);
          target.chart = res.data.chartData.map((el) => ({
            ...el,
            data: el.data
          }));
          target.prevChart = res.data.chartData.map((el) => ({
            ...el,
            data: el.prevData
          }));

          this.cloudViewModel.updateList(this.activeWords);
          this.prevCloudViewModel.updateList(this.activePrevWords);
        });
      }

      if (this.parent.level === 'series') {
        const data = {
          metric: this.parent.selectedIndex,
          size: this.parent.selectedCount,
          ...(this.parent.selectedPostType !== 'all' && { postType: this.parent.selectedPostType }),
          ...(this.parent.selectedWOMType !== 'all' && { womType: this.parent.selectedWOMType }),
          ...(this.parent.categoryPopover.selected.length > 0 && { category: this.parent.categoryPopover.selected.map((el) => el.id) }),
          tagKey: this.parent.selectedTagKey,
          tagValue: this.selectedLevelOption,
          ...(this.parent.blockWords.length > 0 && {
            disableTerm: this.parent.blockWords.map((el) => el.name)
          }),
          date: {
            gte: currentDate[0].format('YYYY-MM-DD'),
            lte: currentDate[1].format('YYYY-MM-DD')
          }
        };
        const prevData = {
          metric: this.parent.selectedIndex,
          size: this.parent.selectedCount,
          ...(this.parent.selectedPostType !== 'all' && { postType: this.parent.selectedPostType }),
          ...(this.parent.selectedWOMType !== 'all' && { womType: this.parent.selectedWOMType }),
          ...(this.parent.categoryPopover.selected.length > 0 && { category: this.parent.categoryPopover.selected.map((el) => el.id) }),
          tagKey: this.parent.selectedTagKey,
          tagValue: this.selectedLevelOption,
          ...(this.parent.blockWords.length > 0 && {
            disableTerm: this.parent.blockWords.map((el) => el.name)
          }),
          date: {
            gte: previousDate[0].format('YYYY-MM-DD'),
            lte: previousDate[1].format('YYYY-MM-DD')
          }
        };
        const res = await RelatedAnalysisService.getSeriesRelated(data, hasCompare ? prevData : null);
        runInAction(() => {
          const target = this.levelOptions.find((el) => el.value === this.selectedLevelOption);
          target.chart = res.data.chartData.map((el) => ({
            ...el,
            data: el.data
          }));
          target.prevChart = res.data.chartData.map((el) => ({
            ...el,
            data: el.prevData
          }));

          this.cloudViewModel.updateList(this.activeWords);
          this.prevCloudViewModel.updateList(this.activePrevWords);
        });
      }

      if (this.parent.level === 'product') {
        const data = {
          metric: this.parent.selectedIndex,
          size: this.parent.selectedCount,
          ...(this.parent.selectedPostType !== 'all' && { postType: this.parent.selectedPostType }),
          ...(this.parent.selectedWOMType !== 'all' && { womType: this.parent.selectedWOMType }),
          ...(this.parent.categoryPopover.selected.length > 0 && { category: this.parent.categoryPopover.selected.map((el) => el.id) }),
          search: this.selectedLevelOption,
          ...(this.parent.blockWords.length > 0 && {
            disableTerm: this.parent.blockWords.map((el) => el.name)
          }),
          date: {
            gte: currentDate[0].format('YYYY-MM-DD'),
            lte: currentDate[1].format('YYYY-MM-DD')
          }
        };
        const prevData = {
          metric: this.parent.selectedIndex,
          size: this.parent.selectedCount,
          ...(this.parent.selectedPostType !== 'all' && { postType: this.parent.selectedPostType }),
          ...(this.parent.selectedWOMType !== 'all' && { womType: this.parent.selectedWOMType }),
          ...(this.parent.categoryPopover.selected.length > 0 && { category: this.parent.categoryPopover.selected.map((el) => el.id) }),
          search: this.selectedLevelOption,
          ...(this.parent.blockWords.length > 0 && {
            disableTerm: this.parent.blockWords.map((el) => el.name)
          }),
          date: {
            gte: previousDate[0].format('YYYY-MM-DD'),
            lte: previousDate[1].format('YYYY-MM-DD')
          }
        };
        const res = await RelatedAnalysisService.getProductRelated(data, hasCompare ? prevData : null);
        runInAction(() => {
          const target = this.levelOptions.find((el) => el.value === this.selectedLevelOption);
          target.chart = res.data.chartData.map((el) => ({
            ...el,
            data: el.data
          }));
          target.prevChart = res.data.chartData.map((el) => ({
            ...el,
            data: el.prevData
          }));

          this.cloudViewModel.updateList(this.activeWords);
          this.prevCloudViewModel.updateList(this.activePrevWords);
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action updateLevelOptions = (list) => {
    this.levelOptions = list;
    this.selectedLevelOption = this.levelOptions?.[0]?.value ?? null;
    this.getData();
  };

  @action onLevelOptionsSelected = (e) => {
    this.selectedLevelOption = e.target.value;
    this.selectedType = 'who';
    this.getData();
  };

  @action onTypeChange = (e) => {
    this.isLoading = true;

    setTimeout(() => {
      runInAction(() => {
        this.selectedType = e;
        this.cloudViewModel.updateList(this.activeWords);
        this.prevCloudViewModel.updateList(this.activePrevWords);
        this.isLoading = false;
      });

    }, 1);
  };

  @action onTopicClick = () => {
    const [gte, lte] = this.activeTimeSec === 'present'
      ? infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'))
      : infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD'));

    const {
      selectedPostType,
      selectedWOMType,
      selectedTagKey,
      selectedCategory
    } = this.parent;

    const topicsResult = {
      ...(selectedWOMType !== 'all' && {
        womType: selectedWOMType
      }),
      ...(selectedPostType !== 'all' && {
        postType: selectedPostType
      }),
      ...(selectedCategory.length > 0 ? {
        category: selectedCategory.map((el) => ({ id: el.id, name: el.name }))
      } : {
        category: SOURCE_CATEGORY.map((el) => ({
          id: el.value,
          name: el.label
        }))
      }),
      packageContent: {
        id: packageStore.activePackageId,
        name: packageStore.activePackageName
      },
      chart: {
        name: `關聯分析/${getLevelText(this.parent.level)}/人事時地物文字雲`
      },
      date: {
        gte,
        lte
      },
      [`${this.parent.level}`]: [{
        id: this.selectedLevel.value,
        name: this.selectedLevel.label,
        ...(
          this.parent.level === 'series'
          && {
            tagKey: selectedTagKey,
            tagKeyName: packageStore.series.find((el) => el.id === selectedTagKey).name
          }
        )
      }],
      term: {
        name: this.selectedWord.name,
        score: this.selectedWord.score,
        count: this.selectedWord.originCount
      }
    };

    const json = JSON.stringify(topicsResult);
    const result = encodeURI(json);

    const url = `${getHost()}/topics-result?result=${result}`;
    window.open(url, '_blank');
  };


  @action onModalOpen = (content) => {
    this.selectedWord = content.selectedWordByName;
    this.activeTimeSec = content.timeSec;
    if (!this.selectedWord || !this.activeTimeSec) {
      return;
    }
    this.isModalOpen = true;
  };

  @action onModalClose = () => {
    this.isModalOpen = false;
    this.selectedWord = {};
  };

  @action onWordBlock = async (word) => {
    try {
      await BlockedWordsService.addBlockWords(word);
      await this.parent.getBlockedWords();
      await this.parent.afterDateUpdate();
      runInAction(() => {

        this.onModalClose();
      });
    } catch (error) {
      if (error.response?.status === 409) {
        message.error('該詞已在屏蔽名單中!');
        return;
      }
      message.error('無法新增屏蔽詞，請稍候再試');
    }
  };
}
