import { request, getHost, getHeaders } from '../utils';

export const getBrandVolumeTrend = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-health/volume/trend`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandSeriesWGS = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-health/wgs`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandProductVolumeRanking = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-health/product/volume/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandSourceCategoryProportion = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-health/source/category/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandSourceChannelRanking = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-health/source/channel/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandSentimentOverview = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-health/sentiment`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandSentimentWord = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-health/sentiment/term`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandConsumerFeature = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-health/feature/count`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const getBrandTopsFeatureWord = (pid, data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/vocService/package/${pid}/report/brand-health/feature/term`,
    headers: getHeaders(),
    data
  };
  return request(options);
};
