import HotWordsModel from './HotWords';
import NewWordsModel from './NewWords';
import TrendWordsModel from './TrendWords';
import HotWordAssocsModel from './HotWordAssocs';

export default {
  HotWordsModel,
  NewWordsModel,
  TrendWordsModel,
  HotWordAssocsModel
};
