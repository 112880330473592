import axios from 'axios';
import { makeObservable, observable, action, computed, reaction, runInAction } from 'mobx';
import { delay } from 'src/utils';

class OptionsStore {
  @observable loadingQueue = [];
  @observable isImgDownload = false;
  // @observable source = axios.CancelToken.source();
  // @observable tick = null;
  // @observable tickTime = 0;

  @computed get isLoading() {
    return this.loadingQueue.length !== 0;
  }

  constructor() {
    makeObservable(this);

    // this.init();
  }

  // @action init = () => {
  //   reaction(
  //     () => this.isLoading,
  //     (boolean) => {
  //       if (boolean) {
  //         this.tickTime = 5;
  //         this.tick = setInterval(() => {
  //           if (this.tickTime === 0) {
  //             runInAction(() => {
  //               clearInterval(this.tick);
  //               this.tick = null;
  //             });
  //             return;
  //           }
  //           runInAction(() => {
  //             this.tickTime -= 1;
  //             console.log('tickTime', this.tickTime);
  //           });
  //         }, 1000);
  //       } else {
  //         runInAction(() => {
  //           clearInterval(this.tick);
  //           this.tick = null;
  //         });
  //       }
  //     }
  //   );
  // };

  isInQueue = (name = 'default') => {
    return this.loadingQueue.find((item) => item === name);
  };

  @action setLoading = (name = 'default') => {
    this.loadingQueue.push(name);
  };

  @action setCompleted = (name = 'default') => {
    this.loadingQueue = this.loadingQueue.filter((el) => el !== name);
  };

  @action onImgDownload = async () => {
    this.setLoading('downloadImg');
    this.isImgDownload = true;
    await delay(100);
  };

  @action onImgDownloadEnd = () => {
    this.isImgDownload = false;
    this.setCompleted('downloadImg');
  };

  @action onReject = (router) => {
    this.loadingQueue = [];
    router.navigate('/home');
  };

  // @action onReject = () => {
  //   this.source.cancel();
  //   this.loadingQueue = [];

  //   this.source = axios.CancelToken.source();
  // };
}

const optionsStore = new OptionsStore();

export default optionsStore;
