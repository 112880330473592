import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { message } from 'antd';

import BlockedWordsService from 'src/services/blockedwords';
import ChartFactory from 'src/components/Charts/factory';

export default class ChartItemViewModel {

  parent = {};

  @observable data = [];
  @observable activeTabId = null;
  @observable isModalOpen = false;

  @observable wordsViewModel = null;

  @observable onReady = false;

  @computed get showChart() {
    return this.activeTabId ? this.data.find((el) => el.id === this.activeTabId).chart : [];
  }

  constructor(data, parent) {
    makeObservable(this);

    this.init(data, parent);
  }

  @action init = (data, parent) => {
    this.parent = parent;
    this.data = data.chartData.map((el) => ({
      id: el.id,
      name: el.name,
      chart: el.data.map((d) => ChartFactory.createChartViewModel({ ...el, data: d, csv: el.csv, csvKey: el.csvKey }, this))
    }));

    this.activeTabId = this.data[0].id;

    this.onReady = true;
  };

  @action onTabsChange = (key) => {
    this.activeTabId = key;
  };

  @action onModalOpen = (viewModel) => {
    this.wordsViewModel = viewModel;

    this.isModalOpen = true;
  };

  @action onModalClose = () => {
    this.isModalOpen = false;
    this.wordsViewModel.onModalClose();

    this.wordsViewModel = null;
  };

  @action onWordBlock = async (word) => {
    try {
      await BlockedWordsService.addBlockWords(word);
      runInAction(() => {
        this.parent.getGraphicData();
        this.parent.getBlockWords();

        this.onModalClose();
      });
    } catch (error) {
      if (error.response?.status === 409) {
        message.error('該詞已在屏蔽名單中!');
        return;
      }
      message.error('無法新增屏蔽詞，請稍候再試');
    }
  };

}
