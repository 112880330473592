import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';

import withRouter from 'src/components/withRouter';
import { CloseOutlined, CheckOutlined, CaretDownOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Radio, Table, Modal, Tooltip } from 'antd';
import { POST_TYPE, WORD_OF_MOUTH_TYPE, WORD_COUNT_TYPE } from 'src/consts';
import { getLevelText } from 'src/utils';
import Select from 'src/components/select';

import PaperIcon from 'src/assets/PaperIcon.svg';

import InfoHeader from 'src/components/InfoHeader';
import CategoryPopoverSelect from 'src/components/CategoryPopoverSelect';

import styles from './styles.module.scss';
import WordChartItem from './WordChartItem';
import ChartAnalysisPageViewModel from './viewModel';


@observer
class ChartAnalysisPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new ChartAnalysisPageViewModel();
  }

  componentDidUpdate(prev) {
    this.viewModel.updateLevel(prev, this.props.router);
  }

  render() {
    return (
      <>
        <div className={styles.pageContainer}>
          <InfoHeader
            breadcrumb={[
              ...(
                this.viewModel.level
                  ? this.viewModel.breadcrumb
                  : ['圖表分析', getLevelText(this.props.router.params.level)]
              )
            ]}
            isMultiPeriod={!(this.viewModel.isNewWord || this.viewModel.isTrendWord)}
            callback={this.viewModel.onDateSelect}
          />
          <div className={styles.typeSegment}>
            {
              this.viewModel.analysisTypes.map((el) => {
                return (
                  <div
                    key={el.id}
                    className={clsx(styles.segmentItem, this.viewModel.selectedType === el.id && styles.active)}
                    onClick={() => this.viewModel.onTypeChange(el.id)}
                  >
                    {el.name}
                  </div>
                );
              })
            }
          </div>
          <div className={styles.resContainer}>
            <div className={styles.subTypesContainer}>
              <Radio.Group
                options={this.viewModel.showSubtypes}
                optionType="button"
                size="middle"
                className={clsx('chartTypeRadio', styles.subtypeGroup)}
                value={this.viewModel.selectedSubtype}
                onChange={this.viewModel.onSubtypeChange}
              />
            </div>
            <div className={styles.actionRow}>
              <div className={styles.statisticFilter}>
                <div className={styles.filterTitle}>
                  {i18n.t('chart_page_statistic_filter')}
                </div>
                <Select
                  placeholder={i18n.t('filter_post_type_all')}
                  options={POST_TYPE}
                  value={this.viewModel.selectedPostType}
                  onSelect={this.viewModel.onPostTypeChange}
                  popupMatchSelectWidth={false}
                  popupClassName={styles.sortMenu}
                  className={clsx('sortSelect', styles.filterSelect)}
                  suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                  disabled={this.viewModel.isAssocs}
                />
                <Select
                  placeholder={i18n.t('filter_word_of_mouth_title')}
                  options={WORD_OF_MOUTH_TYPE}
                  value={this.viewModel.selectedWOMType}
                  onSelect={this.viewModel.onWOMTypeChange}
                  popupMatchSelectWidth={false}
                  popupClassName={styles.sortMenu}
                  className={clsx('sortSelect', styles.filterSelect)}
                  suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                />
                {
                  this.viewModel.showCategorySelect
                  && (
                    <CategoryPopoverSelect
                      title="來源類型"
                      viewModel={this.viewModel.sourceCategoryPopover}
                    />
                  )
                }
                <Button
                  className={clsx(styles.btn, styles.submitBtn)}
                  icon={<CheckOutlined />}
                  onClick={this.viewModel.getGraphicData}
                >
                  {i18n.t('btn_filter_submit')}
                </Button>
                {
                  this.viewModel.isShowStatisticClean
                  && (
                    <Button
                      className={clsx(styles.btn, styles.cleanBtn)}
                      icon={<CloseOutlined />}
                      type="link"
                      onClick={this.viewModel.onCleanStatisticFilter}
                    >
                      {i18n.t('btn_filter_clean_all')}
                    </Button>
                  )
                }
              </div>
              {
                this.viewModel.chartItems.data?.length > 1 && !this.viewModel.chartItems.notAllowAll
                && (
                  <Tooltip
                    title="下載全部數據"
                    placement="bottom"
                  >
                    <Button
                      icon={(
                        <div className={styles.downloadAll} />
                      )}
                      className={styles.downloadAllBtn}
                      onClick={this.viewModel.chartItems.onCSVForAllDownload}
                    />
                  </Tooltip>
                )
              }
            </div>
            {
              this.viewModel.needTagKey
              && (
                <div className={styles.chartFilter}>
                  <div className={styles.filterTitle}>
                    選擇系列群組
                  </div>
                  <div className={styles.filterItem}>
                    <Select
                      options={this.viewModel.tagList}
                      value={this.viewModel.selectedTag}
                      onSelect={this.viewModel.onTagChange}
                      popupMatchSelectWidth={false}
                      popupClassName={styles.sortMenu}
                      className={clsx('sortSelect', styles.filterSelect)}
                      suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                    />
                  </div>
                </div>
              )
            }
            {
              (this.viewModel.selectedType === 'keyword' && !this.viewModel.isAssocs && !this.viewModel.isTrendWord)
              && (
                <div className={styles.chartFilter}>
                  <div className={styles.filterTitle}>
                    {i18n.t('chart_page_graphic_filter')}
                  </div>
                  {/* <div className={styles.filterItem}>
                    <div className={styles.itemTitle}>
                      {i18n.t('chart_page_index_title')}
                    </div>
                    <Select
                      options={WORD_INDEX_TYPE}
                      value={this.viewModel.selectedIndex}
                      onSelect={this.viewModel.onChartIndexChange}
                      popupMatchSelectWidth={false}
                      popupClassName={styles.sortMenu}
                      className={clsx('sortSelect', styles.filterSelect)}
                      suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                    />
                  </div> */}
                  <div className={styles.filterItem}>
                    <div className={styles.itemTitle}>
                      {i18n.t('chart_page_word_count_title')}
                    </div>
                    <Select
                      options={WORD_COUNT_TYPE}
                      value={this.viewModel.selectedCount}
                      onSelect={this.viewModel.onChartCountChange}
                      popupMatchSelectWidth={false}
                      popupClassName={styles.sortMenu}
                      className={clsx('sortSelect', styles.filterSelect)}
                      suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
                    />
                  </div>
                  <Button
                    className={clsx(styles.btn, styles.submitBtn)}
                    icon={<CheckOutlined />}
                    onClick={this.viewModel.onWordCloudShowCountChange}
                  >
                    {i18n.t('btn_filter_submit')}
                  </Button>
                  {
                    this.viewModel.isShowChartClean
                    && (
                      <Button
                        className={clsx(styles.btn, styles.cleanBtn)}
                        icon={<CloseOutlined />}
                        type="link"
                        onClick={this.viewModel.onCleanChartFilter}
                      >
                        {i18n.t('btn_filter_clean_all')}
                      </Button>
                    )
                  }
                </div>
              )
            }
            <WordChartItem viewModel={this.viewModel.chartItems} />
          </div>
        </div>
        <Modal
          open={this.viewModel.isBlockModalOpen}
          centered
          closable={false}
          footer={null}
          className={styles.blockModal}
          onCancel={this.viewModel.onBlockModalClose}
          width={928}
        >
          <div className={styles.modalHeader}>
            <div className={styles.modalTitle}>
              <img src={PaperIcon} alt="paper" className={styles.titleIcon} />
              <div className={styles.modalText}>
                {i18n.t('wordbook_page_block_modal_title')}
              </div>
            </div>
            <Button
              icon={<CloseOutlined />}
              type="link"
              onClick={this.viewModel.onBlockModalClose}
            >
              關閉
            </Button>
          </div>
          <div className={styles.tableContainer}>
            <Table
              key={this.viewModel.blockWords.length}
              rowClassName={styles.row}
              rowKey={(item) => item.id}
              dataSource={this.viewModel.blockWords}
              pagination={false}
              className="packagesTable"
            >
              <Table.Column
                title={i18n.t('wordbook_page_table_name')}
                dataIndex="name"
                key="name"
                className={styles.columns}
                width="50%"
                render={(_, record) => {
                  return (
                    <div>
                      {record.name}
                    </div>
                  );
                }}
              />
              <Table.Column
                title={i18n.t('wordbook_page_table_action_title')}
                dataIndex="name"
                key="name"
                className={styles.columns}
                width="50%"
                render={(_, record) => {
                  return (
                    <Button
                      icon={<ReloadOutlined />}
                      type="text"
                      onClick={() => this.viewModel.onRemoveWord(record.id)}
                    >
                      {i18n.t('wordbook_page_table_action_recover')}
                    </Button>
                  );
                }}
              />
            </Table>
          </div>
        </Modal>
      </>
    );
  }
}

ChartAnalysisPage.propTypes = {
  router: PropTypes.object
};

ChartAnalysisPage.defaultProps = {
  router: { params: {} }
};


export default withRouter(ChartAnalysisPage);
