import React from 'react';
import i18n from 'src/i18n';


import HomeIcon from 'src/assets/MenuIcons/HomeIcon.svg';
import TopicIcon from 'src/assets/MenuIcons/TopicIcon.svg';
import ChartIcon from 'src/assets/MenuIcons/ChartIcon.svg';
import WordbookIcon from 'src/assets/MenuIcons/WordbookIcon.svg';
import DataReportIcon from 'src/assets/MenuIcons/DataReportIcon.svg';
import AdvanceIcon from 'src/assets/MenuIcons/AdvanceIcon.svg';
import RelatedIcon from 'src/assets/MenuIcons/RelatedIcon.svg';
import AdvanceSearchIcon from 'src/assets/MenuIcons/SearchIcon.svg';
import BulletinIcon from 'src/assets/MenuIcons/BulletinIcon.svg';
import AccountManageIcon from 'src/assets/MenuIcons/AccountManageIcon.svg';

import { ROUTES } from '.';

export const menuData = [
  // > { key: , icon: , children: , label: String, type }
  // > note: all content list here.
  // > 首頁
  {
    key: ROUTES.Home,
    icon: <img src={HomeIcon} alt="" style={{ width: 20, height: 20 }} />,
    children: null,
    label: i18n.t('home_page_name')
  },
  // > 話題列表
  {
    key: ROUTES.Topics,
    icon: <img src={TopicIcon} alt="" style={{ width: 20, height: 20 }} />,
    children: null,
    label: i18n.t('topic_page_name')
  },
  // > 圖表分析
  {
    key: ROUTES.Chart.Main,
    icon: <img src={ChartIcon} alt="" style={{ width: 20, height: 20 }} />,
    children: [
      {
        key: ROUTES.Chart.Industry,
        icon: null,
        children: null,
        label: i18n.t('common_industry')
      },
      {
        key: ROUTES.Chart.Brand,
        icon: null,
        children: null,
        label: i18n.t('common_brand')
      },
      {
        key: ROUTES.Chart.Series,
        icon: null,
        children: null,
        label: i18n.t('common_series')
      },
      {
        key: ROUTES.Chart.Product,
        icon: null,
        children: null,
        label: i18n.t('common_product')
      }
    ],
    label: i18n.t('chart_page_name')
  },
  // > 分類詞庫
  {
    key: ROUTES.Wordbook.Main,
    icon: <img src={WordbookIcon} alt="" style={{ width: 20, height: 20 }} />,
    children: [
      {
        key: ROUTES.Wordbook.Industry,
        icon: null,
        children: null,
        label: i18n.t('common_industry')
      },
      {
        key: ROUTES.Wordbook.Brand,
        icon: null,
        children: null,
        label: i18n.t('common_brand')
      },
      {
        key: ROUTES.Wordbook.Series,
        icon: null,
        children: null,
        label: i18n.t('common_series')
      },
      {
        key: ROUTES.Wordbook.Product,
        icon: null,
        children: null,
        label: i18n.t('common_product')
      }
    ],
    label: i18n.t('wordbook_page_name')
  },
  // > 數據報告
  {
    key: ROUTES.DataReport.Main,
    icon: <img src={DataReportIcon} alt="" style={{ width: 20, height: 20 }} />,
    children: [
      {
        key: ROUTES.DataReport.IndustryTrend,
        icon: null,
        children: null,
        label: i18n.t('data_report_type_industry_trend')
      },
      {
        key: ROUTES.DataReport.MarketDifferentiation,
        icon: null,
        children: null,
        label: i18n.t('data_report_type_market_differentiation')
      },
      {
        key: ROUTES.DataReport.BrandCompetition,
        icon: null,
        children: null,
        label: i18n.t('data_report_type_brand_competition')
      },
      {
        key: ROUTES.DataReport.BrandHealth,
        icon: null,
        children: null,
        label: i18n.t('data_report_type_brand_health')
      },
      {
        key: ROUTES.DataReport.BrandEffect,
        icon: null,
        children: null,
        label: i18n.t('data_report_type_brand_effect')
      },
      {
        key: ROUTES.DataReport.BrandRoutine,
        icon: null,
        children: null,
        label: i18n.t('data_report_type_brand_routine')
      }
    ],
    label: i18n.t('data_report_page_name')
  },
  // > 進階分析
  {
    key: ROUTES.Advance.Main,
    icon: <img src={AdvanceIcon} alt="" style={{ width: 20, height: 10 }} />,
    children: [
      {
        key: ROUTES.Advance.Marketing7P,
        icon: null,
        children: null,
        label: i18n.t('advance_type_marketing_7p')
      },
      {
        key: ROUTES.Advance.MindShare,
        icon: null,
        children: null,
        label: i18n.t('advance_type_mind_share')
      },
      {
        key: ROUTES.Advance.StartProduct,
        icon: null,
        children: null,
        label: i18n.t('advance_type_star_product')
      },
      {
        key: ROUTES.Advance.Reputation,
        icon: null,
        children: null,
        label: i18n.t('advance_type_reputation')
      }
    ],
    label: i18n.t('advance_page_name')
  },
  //  > 關聯分析
  {
    key: ROUTES.Related.Main,
    icon: <img src={RelatedIcon} alt="" style={{ width: 20, height: 20 }} />,
    children: [
      {
        key: ROUTES.Related.Brand,
        icon: null,
        children: null,
        label: i18n.t('common_brand')
      },
      {
        key: ROUTES.Related.Series,
        icon: null,
        children: null,
        label: i18n.t('common_series')
      },
      {
        key: ROUTES.Related.Product,
        icon: null,
        children: null,
        label: i18n.t('common_product')
      }
    ],
    label: i18n.t('related_page_name')
  },
  // > 交叉分析
  {
    key: ROUTES.AdvanceSearch,
    icon: <img src={AdvanceSearchIcon} alt="" style={{ width: 20, height: 20 }} />,
    children: null,
    label: i18n.t('advance_search_page_name')
  },
  {
    type: 'divider'
  },
  // > 系統通報
  {
    key: ROUTES.Bulletin,
    icon: <img src={BulletinIcon} alt="" style={{ width: 20, height: 20 }} />,
    children: null,
    label: i18n.t('bulletin_page_name')
  },
  // > 帳號管理
  {
    key: ROUTES.AccountManage,
    icon: <img src={AccountManageIcon} alt="" style={{ width: 20, height: 20 }} />,
    children: null,
    label: i18n.t('account_manage_page_name')
  }
];
