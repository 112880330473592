import { makeObservable, observable, action, computed } from 'mobx';

export default class PopoverSelectViewModel {
  @observable list = [];
  @observable type = '';

  @observable keyword = '';
  @observable keywordCache = '';

  @observable selected = {
    id: null,
    name: null,
    count: null
  };

  @observable parent = null;

  @observable open = false;

  @computed get searchRes() {
    return !this.keywordCache ? this.list : this.list.filter((el) => el.name.toLowerCase().includes(this.keyword.toLowerCase()));
  }

  constructor(parent, type) {
    makeObservable(this);

    this.init(parent, type);
  }

  @action init = (parent, type) => {
    this.parent = parent;
    this.type = type;
  };

  @action updateList = (list) => {
    this.list = list;
  };

  @action onOpenChange = (bool, disabled) => {
    if (disabled) {
      return;
    }
    this.open = bool;
  };

  @action onCancel = () => {
    this.open = false;

    this.keyword = '';
    this.keywordCache = '';
  };

  @action onSelect = (e) => {
    this.selected = e;
    this.open = false;

    this.parent.onSelectCallback(this.type, this.selected);
  };

  @action onCleanSelect = () => {
    this.selected = {
      id: null,
      name: null,
      count: null
    };
  };

  @action onKeywordChange = (e) => {
    this.keyword = e.target.value;
  };

  @action onSubmit = () => {
    this.keywordCache = this.keyword;
  };

  @action onKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onSubmit();
    }
  };
}
