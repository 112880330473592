import { COLOR_SET, WORD_CONFIG } from 'src/consts/chart';

export default class SeriesWordDiscoverCloud {
  constructor(data) {
    if (!data.length >= 1) {
      this.data = {
        chartData: [{
          id: 'industry_word_cloud',
          name: '行業文字雲',
          data: [{
            labels: [],
            datasets: []
          }],
          type: 'nodata'
        }]
      };
      return;
    }

    const maxNum = Math.max(...data.map((el) => el.count));
    const minNum = Math.min(...data.map((el) => el.count));

    const size = (() => {
      const length = data.length;
      if (length <= 20) {
        return 'small';
      }
      if (length > 20 && length <= 50) {
        return 'medium';
      }
      return 'large';
    })();

    const getNew = (num) => {
      // FOR ONLY ONE SITUATION
      if (maxNum === minNum) {
        return WORD_CONFIG[size].basicSize * WORD_CONFIG[size].maxRatio;
      }
      return ((num - minNum) * (WORD_CONFIG[size].maxRatio / (maxNum - minNum)) + 1) * WORD_CONFIG[size].basicSize;
    };

    const res = data.map((el, i) => ({
      id: el.name,
      name: el.name,
      score: el.score,
      count: getNew(el.count),
      originCount: el.count,
      color: COLOR_SET[i % 20]
    }));

    const csv = res.map((el) => ({
      word: el.name,
      count: el.originCount
    }));

    const csvKey = [
      { key: 'word', header: '特性詞' },
      { key: 'count', header: '提及則數' }
    ];

    this.data = {
      chartData: [{
        id: 'brand_word_hot',
        name: '系列特性詞下探',
        data: [res],
        type: 'words',
        csv,
        csvKey
      }]
    };
  }

  static fromRes(data) {
    return new SeriesWordDiscoverCloud(data);
  }
}
