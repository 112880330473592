import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { COLOR_SET, WORD_CONFIG } from 'src/consts/chart';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import packageStore from 'src/stores/packageStore';
import { getTwo } from 'src/utils/chart';

export default class HotWordsModel {
  constructor(data, prevData, query, search) {
    if (data.length === 0 && prevData.length === 0) {
      this.data = {
        chartData: [
          {
            id: 'industry_sentiment_trend',
            name: '行業正負面情緒趨勢',
            data: [
              {
                type: 'nodata',
                subType: 'ana-error'
              },
              ...(
                infoHeaderViewModel.multiViewModel.hasCompare
                  ? [{
                    type: 'nodata',
                    subType: 'ana-error'
                  }]
                  : []
              )
            ]
          }]
      };
      return;
    }

    const currentMaxNum = Math.max(...data.map((el) => el.count));
    const currentMinNum = Math.min(...data.map((el) => el.count));
    const prevMaxNum = Math.max(...prevData.map((el) => el.count));
    const prevMinNum = Math.min(...prevData.map((el) => el.count));

    const size = (() => {
      const length = data.length;
      if (length <= 20) {
        return 'small';
      }
      if (length > 20 && length <= 50) {
        return 'medium';
      }
      return 'large';
    })();

    const getNew = (num, maxNum, minNum) => {
      // FOR ONLY ONE SITUATION
      if (maxNum === minNum) {
        return WORD_CONFIG[size].basicSize * WORD_CONFIG[size].maxRatio;
      }
      return ((num - minNum) * (WORD_CONFIG[size].maxRatio / (maxNum - minNum)) + 1) * WORD_CONFIG[size].basicSize;
    };

    const { currentTimeText, previousTimeText } = infoHeaderViewModel.multiViewModel;
    const [currentGte, currentLte] = currentTimeText.split('~');
    const [prevGte, prevLte] = previousTimeText.split('~');

    const {
      postType,
      womType,
      category,
      tagKey
    } = query;

    const topicsResult = {
      ...(womType && {
        womType
      }),
      ...(postType && {
        postType
      }),
      ...(category ? {
        category: category.map((el) => ({
          id: el,
          name: SOURCE_CATEGORY_TYPE[el]
        }))
      } : {
        category: SOURCE_CATEGORY.map((el) => ({
          id: el.value,
          name: el.label
        }))
      }),
      packageContent: {
        id: packageStore.activePackageId,
        name: packageStore.activePackageName
      },
      series: [{
        id: search.id,
        name: search.name,
        tagKey,
        tagKeyName: packageStore.series.find((el) => el.id === tagKey).name
      }],
      chart: {
        name: '圖表分析/系列/關鍵字分析/系列熱詞文字雲'
      }
    };

    const res = data.map((el, i) => ({
      id: el.name,
      name: el.name,
      count: getNew(el.count, currentMaxNum, currentMinNum),
      originCount: el.count,
      score: getTwo(el.score),
      color: COLOR_SET[i % 20],
      linkData: {
        ...topicsResult,
        term: {
          name: el.name,
          score: getTwo(el.score),
          count: el.count
        },
        date: {
          gte: currentGte,
          lte: currentLte
        }
      }
    }));

    const previousRes = prevData.map((el, i) => ({
      id: el.name,
      name: el.name,
      count: getNew(el.count, prevMaxNum, prevMinNum),
      originCount: el.count,
      score: getTwo(el.score),
      color: COLOR_SET[i % 20],
      linkData: {
        ...topicsResult,
        term: {
          name: el.name,
          score: getTwo(el.score),
          count: el.count
        },
        date: {
          gte: prevGte,
          lte: prevLte
        }
      }
    }));

    const csv = res.map((el) => ({
      word: el.name,
      count: el.originCount,
      prevCount: 0
    }));

    previousRes?.forEach((el) => {
      const target = csv.find((word) => el.name === word.name);
      if (target) {
        target.prevCount = el.originCount;
      } else {
        csv.push({
          word: el.name,
          count: 0,
          prevCount: el.originCount
        });
      }
    });

    const csvKey = [
      { key: 'word', header: '熱詞' },
      { key: 'count', header: '當期提及則數' },
      { key: 'prevCount', header: '前期提及則數' }
    ];

    this.data = {
      chartData: [{
        id: 'brand_word_hot',
        name: '行業熱詞',
        data: [
          ...(
            res.length > 0 && !res.every((el) => el.originCount === 0)
              ? [res]
              : [{
                type: 'nodata',
                subType: res?.length === 0 ? 'ana-error' : 'default'
              }]
          ),
          ...(
            previousRes.length > 0 && !previousRes.every((el) => el.originCount === 0)
              ? [previousRes]
              : [
                ...(
                  infoHeaderViewModel.multiViewModel.hasCompare
                    ? [{ type: 'nodata', subType: previousRes.length === 0 ? 'ana-error' : 'default' }]
                    : []
                )
              ]
          )
        ],
        type: 'words',
        csv,
        csvKey
      }]
    };

  }

  static fromRes(data, prevData, query, search) {
    return new HotWordsModel(data, prevData, query, search);
  }
}
