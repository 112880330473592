import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import i18n from 'src/i18n';
import clsx from 'clsx';

import { LeftOutlined } from '@ant-design/icons';
import { Button, Menu, Layout } from 'antd';

import withRouter from '../withRouter';

import SideMenuViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class SideMenu extends React.Component {
  constructor() {
    super();

    this.viewModel = new SideMenuViewModel();
  }

  componentDidMount() {
    this.viewModel.didMount(this.props.router);
  }

  componentDidUpdate(prev) {
    this.viewModel.didUpdate(prev.router, this.props.router);
  }

  componentWillUnmount() {
    if (this.viewModel.once) {
      this.viewModel.once();
    }
  }

  render() {
    const { router } = this.props;
    return (
      <div className={styles.container}>
        {
          (router.location.pathname !== '/package-manage') && (
            <>
              <Layout.Sider
                width={240}
                className={styles.siderContainer}
                collapsed={this.viewModel.isCollapsed}
                collapsedWidth={60}
                collapsible
                trigger={null}
              >
                <Menu
                  className={styles.menuContainer}
                  onClick={(e) => { this.viewModel.onMenuClick(e, router); }}
                  onOpenChange={this.viewModel.onOpenChange}
                  items={toJS(this.viewModel.filterMenu)}
                  mode="inline"
                  selectedKeys={this.viewModel.currentSelected}
                  openKeys={this.viewModel.openKeys}
                />
              </Layout.Sider>
              <div
                className={styles.trigger}
                onClick={this.viewModel.toggleCollapse}
              >
                <LeftOutlined
                  className={clsx(styles.icon)}
                  rotate={this.viewModel.isCollapsed ? 180 : 0}
                />
              </div>
            </>
          )
        }
      </div>
    );
  }
}

SideMenu.propTypes = {
  router: PropTypes.object
};

SideMenu.defaultProps = {
  router: {}
};


export default withRouter(SideMenu);
