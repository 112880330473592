import { COLOR_SET } from 'src/consts/chart';
import { getHost } from 'src/utils';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';
import packageStore from 'src/stores/packageStore';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';

export default class IndustryVolumeProportionModel {
  constructor(origin, preOrigin, query) {
    // > filter Data

    const data = origin.slice(0, 10);
    const prevData = preOrigin.slice(0, 10);


    // > GENERATE COLOR
    const colors = {};
    data.forEach((el) => {
      const target = colors[el.id];
      if (!target) {
        colors[`${el.id}`] = COLOR_SET[Object.keys(colors).length % 20];
      }
    });
    prevData.forEach((el) => {
      const target = colors[el.id];
      if (!target) {
        colors[`${el.id}`] = COLOR_SET[Object.keys(colors).length % 20];
      }
    });

    const [gte, lte] = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'));
    const [prevGte, prevLte] = infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD'));

    const total = (() => {
      let sum = 0;
      data.forEach((el) => {
        sum += el.count;
      });
      return sum;
    })();

    const prevTotal = (() => {
      let sum = 0;
      prevData.forEach((el) => {
        sum += el.count;
      });
      return sum;
    })();

    const csv = data.map((el) => ({
      date: `${gte}~${lte}`,
      name: el.name,
      volume: el.count,
      proportion: `${Math.floor((el.count / total) * 1000) / 10}%`
    }));

    const prevCsv = prevData.map((el) => ({
      date: `${prevGte}~${prevLte}`,
      name: el.name,
      volume: el.count,
      proportion: `${Math.floor((el.count / prevTotal) * 1000) / 10}%`
    }));

    csv.push(...prevCsv);

    const csvKey = [
      { key: 'date', header: '日期' },
      { key: 'name', header: '系列名稱' },
      { key: 'volume', header: '聲量' },
      { key: 'proportion', header: '佔比' }
    ];

    this.data = {
      chartData: [
        {
          id: 'industry_volume_proportion',
          name: '行業系列聲量佔比',
          data: [
            ...(
              total
                ? [{
                  labels: data.map((el) => el.name),
                  datasets: [{
                    label: `${gte}~${lte}`,
                    data: data.map((el) => el.count),
                    tag: data.map((el) => ({ id: el.id, name: el.name })),
                    backgroundColor: data.map((el) => colors[el.id]),
                    datalabels: {
                      color: '#fff',
                      formatter: (value) => {
                        const percentage = ((value / total) * 100).toFixed();
                        return percentage > 5 ? `${percentage}%` : null;
                      }
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const percentage = ((context.parsed / total) * 100).toFixed();
                          return `${context.parsed}: [${percentage}%]`;
                        }
                      }
                    }
                  }],
                  type: 'pie'
                }]
                : [{
                  type: 'nodata',
                  subType: data.length > 0 ? 'pie' : 'ana-error'
                }]
            ),
            ...(
              prevTotal
                ? [
                  {
                    labels: prevData.map((el) => el.name),
                    datasets: [{
                      label: `${prevGte}~${prevLte}`,
                      data: prevData.map((el) => el.count),
                      tag: prevData.map((el) => ({ id: el.id, name: el.name })),
                      backgroundColor: prevData.map((el) => colors[el.id]),
                      datalabels: {
                        color: '#fff',
                        formatter: (value) => {
                          const percentage = ((value / prevTotal) * 100).toFixed();
                          return percentage > 5 ? `${percentage}%` : null;
                        }
                      },
                      tooltip: {
                        callbacks: {
                          label: (context) => {
                            const percentage = ((context.parsed / prevTotal) * 100).toFixed();
                            return `${context.parsed}: [${percentage}%]`;
                          }
                        }
                      }
                    }],
                    type: 'pie'
                  }
                ]
                : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{
                        type: 'nodata',
                        subType: prevData.length > 0 ? 'pie' : 'ana-error'
                      }]
                      : []
                  )
                ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType,
                category,
                tagKey
              } = query;
              const { datasetIndex, index } = elements[0];
              const tag = chart.data.datasets[datasetIndex].tag[index];
              const [g, l] = chart.data.datasets[datasetIndex].label.split('~');
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category ? {
                  category: category.map((el) => ({
                    id: el,
                    name: SOURCE_CATEGORY_TYPE[el]
                  }))
                } : {
                  category: SOURCE_CATEGORY.map((el) => ({
                    id: el.value,
                    name: el.label
                  }))
                }),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/行業/聲量分析/行業系列聲量佔比'
                },
                industry: true,
                series: [{
                  id: tag.id,
                  name: tag.name,
                  tagKey,
                  tagKeyName: packageStore.series.find((el) => el.id === tagKey).name
                }],
                womCount,
                date: {
                  gte: g,
                  lte: l
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'pie',
          options: {
            event: ['click'],
            plugins: {
              legend: {
                position: 'right'
              }
            }
          },
          plugins: null,
          csv,
          csvKey
        }
      ]
    };
  }

  static fromRes(data, prevOrigin, query) {
    return new IndustryVolumeProportionModel(data, prevOrigin, query);
  }
}
