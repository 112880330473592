import React from 'react';
import PropTypes from 'prop-types';
import { Observer } from 'mobx-react';
import clsx from 'clsx';

import { Checkbox } from 'antd';

import CheckItem from '../CheckItem';

import styles from './styles.module.scss';


class CheckGroupItem extends React.Component {
  render() {
    const { viewModel, onGroupSelectAll = () => { }, afterSelect = () => { } } = this.props;
    return (
      <Observer>
        {() => {
          return (
            <div className={styles.groupContainer}>
              <div className={styles.groupHead}>
                <Checkbox
                  indeterminate={viewModel.groupIndeterminate}
                  checked={viewModel.groupAllSelected}
                  onChange={() => viewModel.onGroupSelect(onGroupSelectAll, afterSelect)}
                />
                <div className={styles.groupName}>
                  {viewModel.name}
                </div>
              </div>
              <div className={styles.childrenContainer}>
                {
                  viewModel.keywordChildren.map((el) => {
                    return <CheckItem viewModel={el} key={el.id} />;
                  })
                }
              </div>
            </div>
          );
        }}
      </Observer>
    );
  }
}

CheckGroupItem.propTypes = {
  viewModel: PropTypes.object,
  onGroupSelectAll: PropTypes.func,
  afterSelect: PropTypes.func
};

CheckGroupItem.defaultProps = {
  viewModel: {},
  onGroupSelectAll: () => { },
  afterSelect: () => { }
};

export default CheckGroupItem;
