import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import i18n from 'src/i18n';

export default class SentimentItemViewModel {
  @observable sentiment = 'positive';

  @observable isAnimation = false;
  @observable inProcess = false;

  constructor(sentiment) {
    makeObservable(this);

    this.init(sentiment);
  }

  @action init = (sentiment) => {
    this.sentiment = sentiment;
  };
  @action onSentimentClick = async (cb, canChangeSentiment) => {
    if (!canChangeSentiment) {
      return;
    }
    this.inProcess = true;
    this.isAnimation = true;

    await cb(this.sentiment, canChangeSentiment);

    setTimeout(() => {
      runInAction(() => {
        switch (this.sentiment) {
          case 'positive':
            this.sentiment = 'neutral';
            break;
          case 'neutral':
            this.sentiment = 'negative';
            break;
          default:
            this.sentiment = 'positive';
            break;
        }

        runInAction(() => {
          this.isAnimation = false;
        });
      });

    }, 301);

    setTimeout(() => {
      runInAction(() => {
        this.inProcess = false;
      });

    }, 400);
  };

  @computed get sentimentText() {
    switch (this.sentiment) {
      case 'positive':
        return i18n.t('filter_sentiment_positive');
      case 'neutral':
        return i18n.t('filter_sentiment_neutral');
      default:
        return i18n.t('filter_sentiment_negative');
    }
  }

  @computed get nextSentimentText() {
    switch (this.sentiment) {
      case 'positive':
        return i18n.t('filter_sentiment_neutral');
      case 'neutral':
        return i18n.t('filter_sentiment_negative');
      default:
        return i18n.t('filter_sentiment_positive');
    }
  }
}
