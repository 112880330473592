import dayjs from 'dayjs';

export default class PackageLogsModel {
  constructor(data) {
    const items = data[0]?.item ?? [];

    const projectStatus = items.find((el) => !el.packageId);
    this.projectStatus = projectStatus ? {
      jobId: projectStatus.jobId,
      progress: projectStatus.progress,
      startDate: projectStatus.startDate,
      finishDate: projectStatus.finishDate,
      beginAt: dayjs(projectStatus.beginAt).format('YYYY/MM/DD HH:mm'),
      endAt: dayjs(projectStatus.endAt).format('YYYY/MM/DD HH:mm'),
      stage: projectStatus.stage,
      description: projectStatus.stateDesc
    } : {};
    const packageStatus = items.find((el) => el.type === 'plus');
    this.packageStatus = packageStatus ? {
      jobId: packageStatus.jobId,
      progress: packageStatus.progress,
      startDate: packageStatus.startDate,
      finishDate: packageStatus.finishDate,
      beginAt: dayjs(packageStatus.beginAt).format('YYYY/MM/DD HH:mm'),
      endAt: dayjs(packageStatus.endAt).format('YYYY/MM/DD HH:mm'),
      stage: packageStatus.stage,
      description: projectStatus.stateDesc
    } : {};
    const marketing7PStatus = items.find((el) => el.type === '7p');
    this.marketing7PStatus = marketing7PStatus ? {
      jobId: marketing7PStatus.jobId,
      progress: marketing7PStatus.progress,
      startDate: marketing7PStatus.startDate,
      finishDate: marketing7PStatus.finishDate,
      beginAt: dayjs(marketing7PStatus.beginAt).format('YYYY/MM/DD HH:mm'),
      endAt: dayjs(marketing7PStatus.endAt).format('YYYY/MM/DD HH:mm'),
      stage: marketing7PStatus.stage,
      description: projectStatus.stateDesc
    } : {};
    const mindSharePStatus = items.find((el) => el.type === 'mindShare');
    this.mindSharePStatus = mindSharePStatus ? {
      jobId: mindSharePStatus.jobId,
      progress: mindSharePStatus.progress,
      startDate: mindSharePStatus.startDate,
      finishDate: mindSharePStatus.finishDate,
      beginAt: dayjs(mindSharePStatus.beginAt).format('YYYY/MM/DD HH:mm'),
      endAt: dayjs(mindSharePStatus.endAt).format('YYYY/MM/DD HH:mm'),
      stage: mindSharePStatus.stage,
      description: projectStatus.stateDesc
    } : {};
    const starProductStatus = items.find((el) => el.type === 'starProduct');
    this.starProductStatus = starProductStatus ? {
      jobId: starProductStatus.jobId,
      progress: starProductStatus.progress,
      startDate: starProductStatus.startDate,
      finishDate: starProductStatus.finishDate,
      beginAt: dayjs(starProductStatus.beginAt).format('YYYY/MM/DD HH:mm'),
      endAt: dayjs(starProductStatus.endAt).format('YYYY/MM/DD HH:mm'),
      stage: starProductStatus.stage,
      description: projectStatus.stateDesc
    } : {};

  }

  static fromRes(data) {
    return new PackageLogsModel(data);
  }
}
