import { makeObservable, observable, action, computed, runInAction } from 'mobx';

import UserService from 'src/services/user';
import userStore from 'src/stores/userStore';

import EditDrawerViewModel from './components/EditDrawer/viewModel';
import CreateModalViewModel from './components/CreateModal/viewModel';

export default class AccountManagementViewModel {
  @observable filterSSOIdentity = null;
  @observable filterSSOStatus = null;
  @observable filterEmployeeDep = null;
  @observable filterUserDep = null;
  @observable filterVOCLoginAuth = null;
  @observable filterSalesman = null;

  @observable keyword = '';
  @observable keywordCache = '';

  @observable userList = [];
  @observable salesmanList = [];
  @observable departmentList = [];

  @observable anchor = null;

  @observable editDrawerViewModel = new EditDrawerViewModel(this);
  @observable createModalViewModel = new CreateModalViewModel(this);

  @computed get availableEdit() {
    switch (userStore.userType) {
      case 'admin':
        return ['Admin', '組長', '組員', '客戶'];
      case 'teamLeader':
        return ['組員', '客戶'];
      default:
        return ['客戶'];
    }
  }

  @computed get isShowCleanAll() {
    return this.filterSSOIdentity !== null || this.filterSSOStatus !== null
      || this.filterEmployeeDep !== null || this.filterUserDep !== null
      || this.filterVOCLoginAuth !== null || this.filterSalesman !== null
      || !!this.keywordCache;
  }

  @computed get salesOptions() {
    return this.salesmanList.map((el) => {
      return ({
        value: el.id,
        label: `${el.teamName} - ${el.name}`
      });
    });

  }

  @computed get filterParams() {
    return {
      ...(this.keyword && { keyword: this.keyword }),
      ...(this.filterSSOIdentity && { type: this.filterSSOIdentity }),
      ...(this.filterSSOStatus && { status: this.filterSSOStatus }),
      ...(this.filterEmployeeDep && { team: this.filterEmployeeDep }),
      ...(this.filterUserDep && { customerBelong: this.filterUserDep }),
      ...(this.filterVOCLoginAuth && { vocStatus: this.filterVOCLoginAuth }),
      ...(this.filterSalesman && { assignedSales: this.filterSalesman })
    };
  }

  constructor() {
    makeObservable(this);

    this.getTeamList();
    this.getSalesList();
    this.getUserList();
  }

  @action getTeamList = async () => {
    try {
      const { teamList } = await UserService.getTeamList();
      runInAction(() => {
        this.departmentList = [...teamList];
        this.editDrawerViewModel.updateDepSelect(teamList);
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action getSalesList = async () => {
    try {
      const { salesList } = await UserService.getSalesList(userStore.userType !== 'admin' ? userStore.userTeamId : null);
      runInAction(() => {
        this.salesmanList = [...salesList];
        this.editDrawerViewModel.updateSalesSelect(salesList);
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action getUserList = async () => {
    try {
      const res = await UserService.getUserList(this.anchor, this.filterParams);
      runInAction(() => {
        this.userList = [...this.userList, ...res.userList];
        this.anchor = res.anchor;
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action onFilterSSOIdentityChange = (v) => {
    this.filterSSOIdentity = v;
  };

  @action onFilterSSOStatusChange = (v) => {
    this.filterSSOStatus = v;
  };

  @action onFilterEmployDepChange = (v) => {
    this.filterEmployeeDep = v;
  };

  @action onFilterUserDepChange = (v) => {
    this.filterUserDep = v;
  };

  @action onFilterVOCLoginAuthChange = (v) => {
    this.filterVOCLoginAuth = v;
  };

  @action onFilterSalesmanChange = (v) => {
    this.filterSalesman = v;
  };


  @action onFilter = async () => {
    this.userList = [];
    this.anchor = null;
    await this.getUserList();
  };

  @action cleanAllFilter = async () => {
    this.filterSSOIdentity = null;
    this.filterSSOStatus = null;
    this.filterEmployeeDep = null;
    this.filterUserDep = null;
    this.filterVOCLoginAuth = null;
    this.filterSalesman = null;

    this.keyword = '';
    this.keywordCache = '';

    this.userList = [];
    this.anchor = null;

    await this.getUserList();
  };

  @action onSearchChange = (e) => {
    this.keyword = e.target.value;
  };

  @action onSearchSubmit = async () => {
    this.keywordCache = this.keyword;

    this.userList = [];
    this.anchor = null;

    await this.getUserList();
  };

  @action onKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onSearchSubmit();
    }
  };

  @action onDrawerOpen = (data) => {
    this.editDrawerViewModel.onDrawerOpen(data);
  };

  @action afterAccountEdit = async () => {
    this.userList = [];
    this.anchor = null;

    await Promise.all([
      this.getUserList()
    ]);
  };
}
