import { COLOR_SET } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY } from 'src/consts';
import { generateCSVKey } from 'src/utils/chart';

export default class BrandVolumeRankingModel {
  constructor(origin, query) {
    if (origin.length === 0) {
      this.data = {
        chartData: [{
          id: 'brand_volume_ranking',
          name: '品牌聲量排行',
          data: [{
            type: 'nodata',
            subType: 'ana-error'
          }]
        }]
      };
      return;
    }

    const data = origin.slice(0, 10);

    if (data.every((el) => el.count === 0)) {
      this.data = {
        chartData: [{
          id: 'brand_volume_ranking',
          name: '品牌聲量排行',
          data: [{
            type: 'nodata'
          }]
        }]
      };
      return;
    }

    const {
      postType,
      womType
    } = query;

    const [gte, lte] = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'));

    const csv = [
      {
        date: `${gte} ~ ${lte}`
      }
    ];

    origin.forEach((el) => {
      const row = csv[0];

      row[`${el.name}`] = el.count;
    });

    const csvKey = generateCSVKey(csv[0]);

    this.data = {
      chartData: [
        {
          id: 'brand_volume_ranking',
          name: '品牌聲量排行',
          data: [{
            labels: data.map((el) => el.name),
            datasets: [
              {
                label: `${gte} ~ ${lte}`,
                data: data.map((el) => el.count),
                brandId: data.map((el) => el.id),
                backgroundColor: COLOR_SET[0],
                borderSkipped: true,
                categoryPercentage: 0.2
              }
            ]
          }],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const { datasetIndex, index } = elements[0];
              const brandId = chart.data.datasets[datasetIndex].brandId[index];
              const brandName = chart.data.labels[index];
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                category: SOURCE_CATEGORY.map((el) => ({
                  id: el.value,
                  name: el.label
                })),
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '數據報告/品牌例行性報告/品牌聲量排行'
                },
                brand: [{
                  id: brandId,
                  name: brandName
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'bar',
          options: {
            event: ['click'],
            scales: {
              y: {
                beginAtZero: true,
                grace: 5,
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ],
      brand: origin.map((el) => ({ id: el.id, name: el.name }))
    };
  }

  static fromRes(data, query) {
    return new BrandVolumeRankingModel(data, query);
  }
}
