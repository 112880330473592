import { makeObservable, observable, action, computed, reaction, runInAction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import i18n from 'src/i18n';
import { message } from 'antd';

import optionsStore from 'src/stores/optionsStore';

export default class BulletinContentViewModel {
  @observable parent = {};
  @observable id = '';

  @observable title = '';
  @observable titleCache = '';

  @observable channelIds = [];
  @observable sentiment = [];

  // > change for better performance
  @observable selectedBrand = '';
  @observable selectedSeriesParent = '';
  @observable selectedSeries = '';
  @observable selectedProduct = '';

  @observable selectedChannels = [];

  @observable selectedSentiment = [];

  // > use static text, not computing
  @observable selectedLevelText = '「尚未選擇」';
  @observable selectedSourceText = '「尚未選擇」';
  @observable selectedSentimentText = '「尚未選擇」';

  @observable isEdit = false;

  @observable validation = {
    title: true,
    level: true,
    sentiment: true,
    source: true
  };

  @observable isInit = false;

  @computed get totalChannels() {
    return this.selectedChannels?.length;
  }

  @computed get hasError() {
    return this.parent.isValidate && Object.values(this.validation).includes(false);
  }

  constructor(data, parent) {
    makeObservable(this);

    this.init(data, parent);
  }

  @action init = async (data, parent) => {
    const id = uuidv4();
    optionsStore.setLoading(`${id}-content`);

    this.parent = parent;

    const {
      title,
      // > mapping theses in modal content
      searchKeywordLevel1Ids,
      searchKeywordLevel3Ids,
      searchTags,
      channelIds,
      sentiment
    } = data;

    runInAction(() => {
      this.id = id;
      this.title = title;
      this.titleCache = title;

      this.selectedBrand = searchKeywordLevel1Ids?.[0] ?? null;
      this.selectedSeries = searchTags?.[0].id ?? null;
      this.selectedProduct = searchKeywordLevel3Ids?.[0] ?? null;

      this.selectedChannels = channelIds ?? [];
      this.selectedSentiment = sentiment ?? [];

      this.validation = {
        title: true,
        level: true,
        sentiment: true,
        source: true
      };

      this.isInit = true;
      optionsStore.setCompleted(`${id}-content`);
    });
  };

  @action onLevelsModalOpen = () => {
    this.parent.onContentIdChange(this.id);
    this.parent.levelsViewModel.updateInfo({
      brand: this.selectedBrand,
      series: this.selectedSeries ?? null,
      product: this.selectedProduct
    });

    this.parent.levelsViewModel.onModalOpen();
  };

  @action onSourceModalOpen = () => {
    this.parent.onContentIdChange(this.id);
    this.parent.sourceSelectViewModel.updateSelectedContent(this.selectedChannels);

    this.parent.sourceSelectViewModel.onOpenWithType('channel');
  };

  @action onSentimentModalOpen = () => {
    this.parent.onContentIdChange(this.id);
    this.parent.sentimentViewModel.updateSelected(this.selectedSentiment);

    this.parent.sentimentViewModel.onModalOpen();
  };

  @action onEditChange = () => {
    this.isEdit = true;
  };

  @action onTitleChange = (e) => {
    this.title = e.target.value;
  };

  @action onSubmitTitleChange = () => {
    if (!this.title.trim()) {
      message.error('通報內容名稱不可留白！');
      this.title = this.titleCache;
      return;
    }
    this.titleCache = this.title;
    this.isEdit = false;
  };

  @action onDisposeTitleChange = () => {
    this.title = this.titleCache;
    this.isEdit = false;
  };

  @action onCheckContent = () => {
    return new Promise((res, rej) => {
      if (!this.title) {
        this.validation.title = false;
      } else {
        this.validation.title = true;
      }

      if (!this.selectedBrand && !this.selectedSeries && !this.selectedProduct) {
        this.validation.level = false;
      } else {
        this.validation.level = true;
      }

      if (this.selectedChannels.length === 0) {
        this.validation.source = false;
      } else {
        this.validation.source = true;
      }

      if (this.selectedSentiment.length === 0) {
        this.validation.sentiment = false;
      } else {
        this.validation.sentiment = true;
      }

      if (this.hasError) {
        message.error(`通報內容 - ${this.title}的內容有誤，請重新確認！`);
        rej();
      }

      res();
    });
  };

  // > 20240118 new for performance issue.
  @action initCalculateText = () => {
    return new Promise((res) => {
      const { text, seriesParent } = this.parent.levelsViewModel.initContent({ brand: this.selectedBrand, series: this.selectedSeries, product: this.selectedProduct });
      this.selectedSeriesParent = seriesParent;
      console.log('seriesParent', seriesParent);
      this.selectedLevelText = text;
      this.parent.sourceSelectViewModel.onOpenWithType('channel');
      const sourceText = this.parent.sourceSelectViewModel.initText(this.selectedChannels);
      this.selectedSourceText = `「${sourceText}共計 (${this.totalChannels})」`;
      this.selectedSentimentText = this.parent.sentimentViewModel.initText(this.selectedSentiment);

      res();
    });
  };

  @action updateLevel = ({ brand, series, seriesParent, product, text }) => {
    this.selectedBrand = brand;
    this.selectedSeriesParent = seriesParent;
    this.selectedSeries = series;
    this.selectedProduct = product;

    this.selectedLevelText = text;
  };

  @action updateSource = ({ channels, textProto }) => {
    this.selectedChannels = channels;

    if (channels.length === 0) {
      this.selectedSourceText = '「尚未選擇」';
      return;
    }

    const text = textProto.map((item) => `${item.name}(${item.count}), `).join('');

    this.selectedSourceText = `「${text}共計 (${this.totalChannels})」`;
  };

  @action updateSentiment = ({ sentiment, text }) => {
    this.selectedSentiment = sentiment;

    this.selectedSentimentText = text;
  };

  static fromRes(data, parent) {
    return new BulletinContentViewModel(data, parent);
  }
}
