import { request, getHost, getHeaders } from './utils';

export const getAnnouncement = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/announcement`,
    headers: getHeaders()
  };
  return request(options);
};

export const createAnnouncement = (data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/announcement`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const putAnnouncement = (id, data) => {
  const options = {
    method: 'put',
    url: `${getHost()}/api/v1/announcement/${id}`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const deleteAnnouncement = (id) => {
  const options = {
    method: 'delete',
    url: `${getHost()}/api/v1/announcement/${id}`,
    headers: getHeaders()
  };
  return request(options);
};
