import API from 'src/apis';
import packageStore from 'src/stores/packageStore';
import ChartsModel from 'src/models/response/charts';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';

export default class WordbookService {
  static getPackageId = () => {
    return packageStore.activePackageId;
  };

  // > call outside
  static async getWordBookChart(level, chartType, data) {
    const {
      currentDate: [gte, lte],
      previousDate: [previousGte, previousLte],
      hasCompare
    } = infoHeaderViewModel.multiViewModel;

    const presentData = {
      ...data,
      date: {
        gte: gte.format('YYYY-MM-DD'),
        lte: lte.format('YYYY-MM-DD')
      }
    };
    const previousData = {
      ...data,
      date: {
        gte: previousGte.format('YYYY-MM-DD'),
        lte: previousLte.format('YYYY-MM-DD')
      }
    };
    switch (level) {
      case 'industry':
        switch (chartType) {
          case 'radar':
            return WordbookService.getIndustryWordRadar(presentData, hasCompare ? previousData : null);
          default:
            return WordbookService.getIndustryWordCloud(presentData, hasCompare ? previousData : null);
        }
      case 'brand':
        switch (chartType) {
          case 'radar':
            return WordbookService.getBrandWordRadar(presentData, hasCompare ? previousData : null);
          default:
            return WordbookService.getBrandWordCloud(presentData, hasCompare ? previousData : null);
        }
      case 'series':
        switch (chartType) {
          case 'radar':
            return WordbookService.getSeriesWordRadar(presentData, hasCompare ? previousData : null);
          default:
            return WordbookService.getSeriesWordCloud(presentData, hasCompare ? previousData : null);
        }
      case 'product':
        switch (chartType) {
          case 'radar':
            return WordbookService.getProductWordRadar(presentData, hasCompare ? previousData : null);
          default:
            return WordbookService.getProductWordCloud(presentData, hasCompare ? previousData : null);
        }
      default:
        return null;
    }
  }

  static async getDiscover(data, level) {
    if (level === 'industry') {
      const res = await WordbookService.getIndustryWordCloudDiscover(data);
      return res;
    }

    if (level === 'brand') {
      const res = await WordbookService.getBrandWordCloudDiscover(data);
      return res;
    }

    if (level === 'series') {
      const res = await WordbookService.getSeriesWordCloudDiscover(data);
      return res;
    }

    const res = await WordbookService.getProductWordDiscover(data);
    return res;
  }

  // > don't call
  static async getIndustryWordRadar(data, previousData) {
    const [res, previousRes] = await Promise.all([
      API.wordbook.getIndustryWordRadar(WordbookService.getPackageId(), data),
      previousData
        ? API.wordbook.getIndustryWordRadar(WordbookService.getPackageId(), previousData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.Wordbook.IndustryRadarModel.fromRes(res.data.result, previousRes.data.result, data);
  }

  static async getIndustryWordCloud(data, previousData) {
    const [res, previousRes] = await Promise.all([
      API.wordbook.getIndustryWordCloud(WordbookService.getPackageId(), data),
      previousData
        ? API.wordbook.getIndustryWordCloud(WordbookService.getPackageId(), previousData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.Wordbook.IndustryWordCloud.fromRes(res.data.result, previousRes.data.result);
  }

  static async getIndustryWordCloudDiscover(data) {
    const res = await API.wordbook.getIndustryWordCloudDiscover(WordbookService.getPackageId(), data);

    return ChartsModel.Wordbook.IndustryWordDiscoverCloud.fromRes(res.data.result);
  }

  static async getBrandWordRadar(data, previousData) {
    const [res, previousRes] = await Promise.all([
      API.wordbook.getBrandWordRadar(WordbookService.getPackageId(), data),
      previousData
        ? API.wordbook.getBrandWordRadar(WordbookService.getPackageId(), previousData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.Wordbook.BrandRadarModel.fromRes(res.data.result, previousRes.data.result, data);
  }

  static async getBrandWordCloud(data, previousData) {
    const [res, previousRes] = await Promise.all([
      API.wordbook.getBrandWordCloud(WordbookService.getPackageId(), data),
      previousData
        ? API.wordbook.getBrandWordCloud(WordbookService.getPackageId(), previousData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.Wordbook.BrandWordCloud.fromRes(res.data.result, previousRes.data.result);
  }

  static async getBrandWordCloudDiscover(data) {
    const res = await API.wordbook.getBrandWordCloudDiscover(WordbookService.getPackageId(), data);

    return ChartsModel.Wordbook.BrandWordDiscoverCloud.fromRes(res.data.result);
  }

  static async getSeriesWordRadar(data, previousData) {
    const [res, previousRes] = await Promise.all([
      API.wordbook.getSeriesWordRadar(WordbookService.getPackageId(), data),
      previousData
        ? API.wordbook.getSeriesWordRadar(WordbookService.getPackageId(), previousData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.Wordbook.SeriesRadarModel.fromRes(res.data.result, previousRes.data.result, data);
  }

  static async getSeriesWordCloud(data, previousData) {
    const [res, previousRes] = await Promise.all([
      API.wordbook.getSeriesWordCloud(WordbookService.getPackageId(), data),
      previousData
        ? API.wordbook.getSeriesWordCloud(WordbookService.getPackageId(), previousData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.Wordbook.SeriesWordCloud.fromRes(res.data.result, previousRes.data.result);
  }


  static async getSeriesWordCloudDiscover(data) {
    const res = await API.wordbook.getSeriesWordCloudDiscover(WordbookService.getPackageId(), data);

    return ChartsModel.Wordbook.SeriesWordDiscoverCloud.fromRes(res.data.result, data);
  }

  static async getProductWordRadar(data, previousData) {
    const [res, previousRes] = await Promise.all([
      API.wordbook.getProductWordRadar(WordbookService.getPackageId(), data),
      previousData
        ? API.wordbook.getProductWordRadar(WordbookService.getPackageId(), previousData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.Wordbook.ProductRadarModel.fromRes(res.data.result, previousRes.data.result, data);
  }

  static async getProductWordCloud(data, previousData) {
    const [res, previousRes] = await Promise.all([
      API.wordbook.getProductWordCloud(WordbookService.getPackageId(), data),
      previousData
        ? API.wordbook.getProductWordCloud(WordbookService.getPackageId(), previousData)
        : new Promise((r) => { r({ data: { result: [] } }); })
    ]);

    return ChartsModel.Wordbook.ProductWordCloud.fromRes(res.data.result, previousRes.data.result);
  }

  static async getProductWordDiscover(data) {
    const res = await API.wordbook.getProductWordCloudDiscover(WordbookService.getPackageId(), data);

    return ChartsModel.Wordbook.ProductWordDiscoverCloud.fromRes(res.data.result);
  }
}
