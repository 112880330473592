import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';

import SearchIcon from 'src/assets/MenuIcons/SearchIcon.svg';

import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Popover, Empty, Checkbox } from 'antd';

import CheckItem from './CheckItem';

import styles from './styles.module.scss';

@observer
class CategoryPopoverSelect extends React.Component {
  componentDidMount() {
    this.props.viewModel.didMount();
  }

  componentWillUnmount() {
    this.props.viewModel.willUnmount();
  }
  render() {
    const { viewModel, callback, title } = this.props;
    return (
      <Popover
        placement="bottomLeft"
        showArrow={false}
        open={viewModel.open}
        trigger="click"
        onOpenChange={(bool) => viewModel.onOpenChange(bool, callback)}
        content={(
          <div className={styles.switchProjectPopover}>
            <div className={styles.selectCard}>
              <div className={styles.cardHeader}>
                <div className={styles.cardTitle}>
                  {`選擇${title}`}
                </div>
                <div className={styles.cardSelectAll}>
                  <Checkbox
                    indeterminate={viewModel.indeterminate}
                    checked={viewModel.isAllChecked}
                    onChange={viewModel.onAllSelect}
                  />
                  <div className={styles.selectAllText}>
                    {i18n.t('package_modal_select_all')}
                  </div>
                </div>
              </div>
              <div className={styles.cardContent}>
                <div className={styles.searchInputContainer}>
                  <input
                    className={styles.searchInput}
                    placeholder={i18n.t('package_modal_input_placeholder')}
                    value={viewModel.keyword}
                    onChange={viewModel.onKeywordChange}
                    onKeyDown={viewModel.onKeyDown}
                  />
                  <div
                    className={styles.searchBtn}
                    onClick={viewModel.onSubmit}
                  >
                    <img src={SearchIcon} alt="" />
                  </div>
                </div>
                <div className={styles.levelCheckboxContainer}>
                  {
                    viewModel.searchContent.map((el) => {
                      return <CheckItem key={el.id} viewModel={el} />;
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        )}
      >
        <div className={clsx(styles.selector)}>
          <div className={clsx(styles.text, viewModel.selected.length > 0 && styles.active)}>
            {`${title}${viewModel.selected.length > 0 ? `(${viewModel.selected.length})` : ''}`}
          </div>
          <CaretDownOutlined className={styles.icon} />
        </div>
      </Popover>
    );
  }
}

CategoryPopoverSelect.propTypes = {
  viewModel: PropTypes.object,
  callback: PropTypes.func,
  title: PropTypes.string
};

CategoryPopoverSelect.defaultProps = {
  viewModel: {},
  callback: () => {},
  title: ''
};


export default CategoryPopoverSelect;
