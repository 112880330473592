import { SENTIMENT_COLOR_SET, AVG_LINE_COLOR } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { SOURCE_CATEGORY_TYPE, SOURCE_CATEGORY } from 'src/consts';

export default class ProductSentimentOverviewModel {
  constructor(origin, prevOrigin, query) {
    const { currentDate, previousDate } = infoHeaderViewModel.multiViewModel;
    const currentTime = currentDate.map((el) => el.format('YYYY-MM-DD'));
    const previousTime = previousDate.map((el) => el.format('YYYY-MM-DD'));

    // > filter data
    const data = origin.slice(0, 20);
    const prevData = prevOrigin.slice(0, 20);
    const csv = [];

    const currCsv = origin.map((el) => ({
      date: currentTime.join('~'),
      name: el.name,
      positive: el['1'],
      neutral: el['0'],
      negative: el['-1'],
      pn: el.pn
    }));
    csv.push(...currCsv);

    const prevCsv = prevOrigin.map((el) => ({
      date: previousTime.join('~'),
      name: el.name,
      positive: el['1'],
      neutral: el['0'],
      negative: el['-1'],
      pn: el.pn
    }));
    csv.push(...prevCsv);

    const csvKey = [
      { key: 'date', header: '日期' },
      { key: 'name', header: '名稱' },
      { key: 'positive', header: '偏正面' },
      { key: 'neutral', header: '偏中性' },
      { key: 'negative', header: '偏負面' },
      { key: 'pn', header: 'P/N' }
    ];


    this.data = {
      chartData: [
        {
          id: 'product_sentiment_overview',
          name: '產品情緒總覽',
          data: [
            ...(
              !data.every((el) => el['-1'] + el['0'] + el['1'] === 0)
                ? [{
                  labels: data.map((el) => el.name),
                  datasets: [
                    {
                      label: 'p/n',
                      data: data.map((el) => el.pn),
                      backgroundColor: AVG_LINE_COLOR,
                      borderColor: AVG_LINE_COLOR,
                      type: 'line',
                      yAxisID: 'y1'
                    },
                    {
                      label: '正面',
                      data: data.map((el) => el['1']),
                      sentiment: '1',
                      product: data.map((el) => ({ id: el.id, name: el.name })),
                      backgroundColor: SENTIMENT_COLOR_SET.positive,
                      borderSkipped: true,
                      maxBarThickness: 50,
                      yAxisID: 'y'
                    },
                    {
                      label: '中性',
                      data: data.map((el) => el['0']),
                      sentiment: '0',
                      product: data.map((el) => ({ id: el.id, name: el.name })),
                      backgroundColor: SENTIMENT_COLOR_SET.neutral,
                      borderSkipped: true,
                      maxBarThickness: 50,
                      yAxisID: 'y'
                    },
                    {
                      label: '負面',
                      data: data.map((el) => el['-1']),
                      sentiment: '-1',
                      product: data.map((el) => ({ id: el.id, name: el.name })),
                      backgroundColor: SENTIMENT_COLOR_SET.negative,
                      borderSkipped: true,
                      maxBarThickness: 50,
                      yAxisID: 'y'
                    }
                  ]
                }]
                : [{
                  type: 'nodata',
                  subType: data.length > 0 ? 'default' : 'ana-error'
                }]
            ),
            ...(
              !prevData.every((el) => el['-1'] + el['0'] + el['1'] === 0)
                ? [{
                  labels: prevData.map((el) => el.name),
                  datasets: [
                    {
                      label: 'p/n',
                      data: prevData.map((el) => el.pn),
                      backgroundColor: AVG_LINE_COLOR,
                      borderColor: AVG_LINE_COLOR,
                      type: 'line',
                      yAxisID: 'y1'
                    },
                    {
                      label: '正面',
                      data: prevData.map((el) => el['1']),
                      sentiment: '1',
                      product: prevData.map((el) => ({ id: el.id, name: el.name })),
                      backgroundColor: SENTIMENT_COLOR_SET.positive,
                      borderSkipped: true,
                      maxBarThickness: 50,
                      yAxisID: 'y'
                    },
                    {
                      label: '中性',
                      data: prevData.map((el) => el['0']),
                      sentiment: '0',
                      product: prevData.map((el) => ({ id: el.id, name: el.name })),
                      backgroundColor: SENTIMENT_COLOR_SET.neutral,
                      borderSkipped: true,
                      maxBarThickness: 50,
                      yAxisID: 'y'
                    },
                    {
                      label: '負面',
                      data: prevData.map((el) => el['-1']),
                      sentiment: '-1',
                      product: prevData.map((el) => ({ id: el.id, name: el.name })),
                      backgroundColor: SENTIMENT_COLOR_SET.negative,
                      borderSkipped: true,
                      maxBarThickness: 50,
                      yAxisID: 'y'
                    }
                  ]
                }]
                : [
                  ...(
                    infoHeaderViewModel.multiViewModel.hasCompare
                      ? [{
                        type: 'nodata',
                        subType: prevData.length > 0 ? 'default' : 'ana-error'
                      }]
                      : []
                  )
                ]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType,
                category
              } = query;
              const { datasetIndex, index } = elements[0];
              const sentiment = chart.data.datasets[datasetIndex].sentiment;
              if (!sentiment) {
                return;
              }
              const womCount = chart.data.datasets[datasetIndex].data[index];
              const product = chart.data.datasets[datasetIndex].product[index];
              const [gte, lte] = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'));

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category ? {
                  category: category.map((el) => ({
                    id: el,
                    name: SOURCE_CATEGORY_TYPE[el]
                  }))
                } : {
                  category: SOURCE_CATEGORY.map((el) => ({
                    id: el.value,
                    name: el.label
                  }))
                }),
                sentiment: [Number(sentiment)],
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/產品/情緒分析/產品情緒總覽'
                },
                product: [{
                  id: product.id,
                  name: product.name
                }],
                womCount,
                date: {
                  gte,
                  lte: lte ?? gte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'bar',
          options: {
            event: ['click'],
            tension: 0.3,
            scales: {
              x: {
                stacked: true
              },
              y: {
                stacked: true,
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                  display: true,
                  text: '聲量'
                }
              },
              y1: {
                type: 'linear',
                display: true,
                position: 'right',
                title: {
                  display: true,
                  text: 'PN值'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        }
      ]
    };
  }

  static fromRes(data, prevData, query) {
    return new ProductSentimentOverviewModel(data, prevData, query);
  }
}
