import { COLOR_SET } from 'src/consts/chart';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import { getHost } from 'src/utils';
import { SOURCE_CATEGORY_TYPE } from 'src/consts';
import packageStore from 'src/stores/packageStore';
import { encodeURI } from 'js-base64';
import { generateCSVKey } from 'src/utils/chart';

export default class BrandSourceChannelRankingModel {
  constructor(data, prevData, query) {
    if (data.length === 0 && prevData.length === 0) {
      this.data = {
        chartData: [{
          id: 'brand_source_channel_ranking',
          name: '品牌',
          data: [{
            labels: [],
            datasets: []
          }],
          type: 'nodata',
          subType: 'ana-error'
        }]
      };
      return;
    }



    const dataMap = new Map();
    const prevDataMap = new Map();

    for (let i = 0; i < data.length; i += 1) {
      dataMap.set(data[i].id, data[i]);
    }

    for (let i = 0; i < prevData.length; i += 1) {
      prevDataMap.set(prevData[i].id, prevData[i]);
    }

    const combine = BrandSourceChannelRankingModel.getCombineData(data, prevData);
    const currTime = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD')).join('~');
    const prevTime = infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD')).join('~');
    const csvForAll = [];
    const csvForAllMap = new Map();

    for (let i = 0; i < combine.length; i += 1) {
      const targetInCurrent = dataMap.get(combine[i].id);
      const targetInPrevious = prevDataMap.get(combine[i].id);
      const csv = BrandSourceChannelRankingModel.generateCSV(targetInCurrent, targetInPrevious);
      const csvKey = generateCSVKey(csv[0]);
      csvForAll.push({
        id: combine[i].id,
        name: combine[i].name,
        csv,
        csvKey
      });
      csvForAllMap.set(combine[i].id, { csv, csvKey });
    }

    this.data = {
      chartData: combine.slice(0, 10).map((brand) => {
        const targetInCurrent = dataMap.get(brand.id);
        const currentEmpty = targetInCurrent?.item.every((item) => item.count === 0) ?? true;
        const targetInPrevious = prevDataMap.get(brand.id);
        const previousEmpty = targetInPrevious?.item.every((item) => item.count === 0) ?? true;
        const { csv, csvKey } = csvForAllMap.get(brand.id) ?? { csv: [], csvKey: [] };

        return {
          id: brand.id,
          name: brand.name,
          data: [
            ...(
              !(currentEmpty && previousEmpty)
                ? [{
                  labels: brand.item.map((item) => item.name),
                  datasets: [
                    ...(
                      !currentEmpty
                        ? [{
                          label: currTime,
                          channel: brand.item.map((c) => c.id),
                          data: brand.item.map((c) => c.count),
                          backgroundColor: COLOR_SET[0],
                          borderSkipped: true,
                          categoryPercentage: 0.2
                        }]
                        : []
                    ),
                    ...(
                      !previousEmpty
                        ? [{
                          label: prevTime,
                          channel: brand.item.map((c) => c.id),
                          data: brand.item.map((c) => c.prevCount),
                          backgroundColor: COLOR_SET[19],
                          borderSkipped: true,
                          categoryPercentage: 0.2
                        }]
                        : []
                    )
                  ]
                }]
                : [{ type: 'nodata', subType: 'default' }]
            )
          ],
          onPointClick: (elements, chart, params) => {
            if (elements.length > 0) {
              const {
                postType,
                womType,
                category
              } = query;
              const { datasetIndex, index } = elements[0];
              const channelId = chart.data.datasets[datasetIndex].channel[index];
              const channelName = chart.data.labels[index];
              const [gte, lte] = chart.data.datasets[datasetIndex].label.split('~');
              const womCount = chart.data.datasets[datasetIndex].data[index];

              const topicsResult = {
                ...(womType && {
                  womType
                }),
                ...(postType && {
                  postType
                }),
                ...(category && {
                  category: category.map((c) => ({
                    id: c,
                    name: SOURCE_CATEGORY_TYPE[c]
                  }))
                }),
                channel: [{
                  id: channelId,
                  name: channelName
                }],
                packageContent: {
                  id: packageStore.activePackageId,
                  name: packageStore.activePackageName
                },
                chart: {
                  name: '圖表分析/品牌/來源分析/品牌來源頻道/品牌來源頻道排行'
                },
                brand: [{
                  id: brand.id,
                  name: brand.name
                }],
                womCount,
                date: {
                  gte,
                  lte
                }
              };

              const json = JSON.stringify(topicsResult);
              const result = encodeURI(json);
              const url = `${getHost()}/topics-result?result=${result}`;
              window.open(url, '_blank');
            }
          },
          type: 'bar',
          options: {
            event: ['click'],
            scales: {
              y: {
                title: {
                  display: true,
                  text: '聲量'
                }
              }
            },
            plugins: {
              legend: {
                position: 'bottom'
              },
              datalabels: {
                display: false
              }
            }
          },
          csv,
          csvKey
        };
      }),
      csvForAll
    };
  }

  static getCombineData = (list, prevList) => {
    const combineOrder = [];
    const combineMap = new Map();

    for (let i = 0; i < list.length; i += 1) {
      combineOrder.push(list[i].id);
      combineMap.set(list[i].id, {
        ...list[i],
        item: list[i].item.slice(0, 20).map((item) => ({
          ...item,
          prevCount: 0
        }))
      });
    }

    for (let i = 0; i < prevList.length; i += 1) {
      const itemInCombine = combineMap.get(prevList[i].id);
      if (itemInCombine) {
        const inItemsMap = new Map(itemInCombine.item.map((item) => [item.name, item]));
        for (let j = 0; j < prevList[i].item.length; j += 1) {
          const target = inItemsMap.get(prevList[i].item[j].name);
          if (target) {
            target.prevCount = prevList[i].item[j].count;
          }

        }
      }
    }

    const combine = [];

    for (let i = 0; i < combineOrder.length; i += 1) {
      const target = combineMap.get(combineOrder[i]);
      target.item.sort((a, b) => b.count - a.count);
      combine.push(target);
    }

    return combine;
  };

  static generateCSV = (curr, prev) => {
    const csv = [];

    if (curr) {
      const time = infoHeaderViewModel.multiViewModel.currentDate.map((el) => el.format('YYYY-MM-DD'));
      const row = {
        日期: time.join('~')
      };
      curr.item.forEach((el) => {
        row[`${el.name}`] = el.count;
      });
      csv.push(row);
    }

    if (prev) {
      const time = infoHeaderViewModel.multiViewModel.previousDate.map((el) => el.format('YYYY-MM-DD'));
      const row = {
        日期: time.join('~')
      };
      prev.item.forEach((el) => {
        row[`${el.name}`] = el.count;
      });
      csv.push(row);
    }

    return csv;
  };

  static fromRes(data, prevData, query) {
    return new BrandSourceChannelRankingModel(data, prevData, query);
  }
}
