import BrandSourceChannelTrendModel from './Trend';
import BrandSourceChannelRankingModel from './Ranking';
import BrandSourceChannelProportionModel from './Proportion';
import BrandSourceChannelCompareModel from './Compare';

export default {
  BrandSourceChannelTrendModel,
  BrandSourceChannelRankingModel,
  BrandSourceChannelProportionModel,
  BrandSourceChannelCompareModel
};
