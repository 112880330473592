import { makeObservable, observable, action, computed, runInAction, reaction } from 'mobx';
import { ADVANCE_SEARCH_OPTIONS, SENTIMENT_TYPE } from 'src/consts';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import { message } from 'antd';
import * as xlsx from 'xlsx';

import PackageService from 'src/services/package';
import SourceService from 'src/services/source';
import packageStore from 'src/stores/packageStore';
import AdvancedSearchService from 'src/services/advanceResearch';
import infoHeaderViewModel from 'src/components/InfoHeader/viewModel';
import SourceSelectModalViewModel from 'src/components/SourceSelectModal/viewModel';
import ChartFactory from 'src/components/Charts/factory';
import optionsStore from 'src/stores/optionsStore';

import SingleClassModalViewModel from './components/SingleClassModal/viewModel';
import CheckItemViewModel from './components/SingleClassModal/CheckItem/viewModel';
import CheckGroupItemViewModel from './components/SingleClassModal/CheckGroupItem/viewModel';
import SingleSelectModalViewModel from './components/SingleSelectModal/viewModel';
import ChartItemViewModel from './components/ChartItem/viewModel';

export default class AdvanceSearchViewModel {
  @observable options = [...ADVANCE_SEARCH_OPTIONS];

  @observable time = [dayjs().subtract(7, 'day'), dayjs()];

  @observable selectedOne = null;
  @observable previousSelectedOne = null;
  @observable selectedTwo = null;
  @observable previousSelectedTwo = null;

  @observable condition1Cache = null;
  @observable condition2Cache = null;

  @observable selectedOneText = '';
  @observable selectedOneTextCache = '';
  @observable selectedTwoText = '';
  @observable selectedTwoTextCache = '';

  @observable paramsOne = null;
  @observable paramsTwo = null;

  @observable brand = [];
  @observable product = [];

  @observable feature = [];
  @observable sentiment = [];

  @observable seriesViewModel = new SingleSelectModalViewModel();
  @observable singleSelectedModal = new SingleClassModalViewModel();
  @observable sourceSelectModalVmOne = new SourceSelectModalViewModel();
  @observable sourceSelectModalVmTwo = new SourceSelectModalViewModel();

  @observable trendViewModel = new ChartItemViewModel(this);
  @observable rankingViewModel = new ChartItemViewModel(this);
  @observable proportionViewModel = new ChartItemViewModel(this);
  @observable compareViewModel = new ChartItemViewModel(this);

  // > RES section
  @observable selectedPostType = 'all';
  @observable selectedWOMType = 'all';

  @observable selectedSubType = null;

  // > download
  @observable isDownload = false;
  @observable downloadList = [];
  @observable downloadFileName = '';

  @observable reactionOne = null;
  @observable reactionTwo = null;

  @computed get selectedOneId() {
    return this.selectedOne ? this.selectedOne[this.selectedOne.length - 1] : null;
  }

  @computed get previousSelectedOneId() {
    return this.previousSelectedOne ? this.previousSelectedOne[this.previousSelectedOne.length - 1] : null;
  }

  @computed get selectedTwoId() {
    return this.selectedTwo ? this.selectedTwo[this.selectedTwo.length - 1] : null;
  }

  @computed get previousSelectedTwoId() {
    return this.previousSelectedTwo ? this.previousSelectedTwo[this.previousSelectedTwo.length - 1] : null;
  }

  @computed get disabledSelectedOptions() {
    return this.options.map((el) => ({
      value: el.value,
      label: el.label,
      children: el.children ? el.children.map((c) => ({
        value: c.value,
        label: c.label,
        disabled: c.value === this.selectedOneId
          || c.value === this.selectedTwoId
          || (c.value === 'brand' && !packageStore.isBrandAvailable)
          || (c.value === 'series' && !packageStore.isSeriesAvailable)
          || (c.value === 'product' && !packageStore.isProductAvailable)
      })) : null,
      disabled: el.value === this.selectedOneId || el.value === this.selectedTwoId
    }));
  }

  @computed get getSelectedBrand() {
    const isSelected = this.brand.filter((el) => el.checked);
    return isSelected.length > 0 ? isSelected.map((el) => el.name).join(', ') : '尚未選擇';
  }

  @computed get getSelectedProduct() {
    const res = this.product.filter((el) => !!el.checkedText);
    return res.length >= 1 ? res.map((el) => el.checkedText).join(', ') : '尚未選擇';
  }

  @computed get getSelectedFeature() {
    const isSelected = this.feature.filter((el) => el.checked);
    return isSelected.length > 0 ? isSelected.map((el) => el.name).join(', ') : '尚未選擇';
  }

  @computed get getSelectedSentiment() {
    const isSelected = this.sentiment.filter((el) => el.checked);
    return isSelected.length > 0 ? isSelected.map((el) => el.name).join(', ') : '尚未選擇';
  }

  @computed get paramsOneText() {
    switch (this.selectedOneId) {
      case 'brand':
        return this.getSelectedBrand;
      case 'series':
        return this.seriesViewModel.selectedSeriesParentText;
      case 'product':
        return this.getSelectedProduct;
      case 'category':
        return this.sourceSelectModalVmOne.selectedCategoryText;
      case 'website':
        return this.sourceSelectModalVmOne.selectedWebsiteText;
      case 'channel':
        return this.sourceSelectModalVmOne.selectedChannelText;
      case 'featureSet':
        return this.getSelectedFeature;
      case 'sentiment':
        return this.getSelectedSentiment;
      default:
        return null;
    }
  }

  @computed get paramsTwoText() {
    switch (this.selectedTwoId) {
      case 'brand':
        return this.getSelectedBrand;
      case 'series':
        return this.seriesViewModel.selectedSeriesParentText;
      case 'product':
        return this.getSelectedProduct;
      case 'category':
        return this.sourceSelectModalVmTwo.selectedCategoryText;
      case 'website':
        return this.sourceSelectModalVmTwo.selectedWebsiteText;
      case 'channel':
        return this.sourceSelectModalVmTwo.selectedChannelText;
      case 'featureSet':
        return this.getSelectedFeature;
      case 'sentiment':
        return this.getSelectedSentiment;
      default:
        return null;
    }
  }

  @computed get isShowStatisticClean() {
    return this.selectedPostType !== 'all' || this.selectedWOMType !== 'all';
  }

  @computed get chartName() {
    const typeText = (() => {
      switch (this.selectedSubType) {
        case 'trend':
          return '趨勢';
        case 'ranking':
          return '排行';
        case 'proportion':
          return '佔比';
        case 'compare':
          return '堆疊';
        default:
          return '';
      }
    })();

    return `『${this.selectedOneTextCache}』 交叉 『${this.selectedTwoTextCache}』 ${typeText}`;
  }

  @computed get showChart() {
    switch (this.selectedSubType) {
      case 'trend':
        return this.trendViewModel;
      case 'ranking':
        return this.rankingViewModel;
      case 'proportion':
        return this.proportionViewModel;
      case 'compare':
        return this.compareViewModel;
      default:
        return '';
    }
  }

  constructor() {
    makeObservable(this);

    this.init();
  }

  @action didMount = () => {
    const reactionOne = reaction(
      () => this.selectedOne,
      () => {
        this.onCleanSelectedOne();
      }
    );
    const reactionTwo = reaction(
      () => this.selectedTwo,
      () => {
        this.onCleanSelectedTwo();
      }
    );

    this.reactionOne = reactionOne;
    this.reactionTwo = reactionTwo;
  };

  willUnmount = () => {
    this.reactionOne?.();
    this.reactionTwo?.();
  };

  @action init = () => {
  };

  @action onTimeChange = (e) => {
    this.time = e;
  };

  @action onOptionOneSelect = (e, option) => {
    this.selectedOne = e;
    this.selectedOneText = option[option.length - 1].label;
    this.paramsOne = null;

    this.checkSelectedContent(option[option.length - 1].value);
  };

  @action onOptionTwoSelect = (e, option) => {
    this.selectedTwo = e;
    this.selectedTwoText = option[option.length - 1].label;
    this.paramsTwo = null;

    this.checkSelectedContent(option[option.length - 1].value);
  };

  @action checkSelectedContent = async (content) => {
    switch (content) {
      case 'brand':
        if (this.brand.length === 0) {
          this.brand = packageStore.brand.map((el) => new CheckItemViewModel(el));
        }
        break;
      case 'series':
        break;
      case 'product':
        if (this.product.length === 0) {
          this.product = packageStore.product.map((el) => new CheckGroupItemViewModel(el));
        }
        break;
      case 'category':
      case 'website':
      case 'channel':
        if (this.sourceSelectModalVmOne.category.length === 0) {
          await this.getPackageSources();
        }
        break;
      case 'featureSet':
        if (this.feature.length === 0) {
          await this.getFeatureSetContent();
        }
        break;
      case 'sentiment':
        if (this.sentiment.length === 0) {
          this.sentiment = SENTIMENT_TYPE.map((el) => new CheckItemViewModel({ id: el.value, name: el.label }));
        }
        break;
      default:
        break;
    }
  };

  @action getPackageSources = async () => {
    try {
      optionsStore.setLoading('source');
      const { source } = await SourceService.getPackageSources();

      runInAction(() => {
        this.sourceSelectModalVmOne.updateSources(source);
        this.sourceSelectModalVmTwo.updateSources(source);
      });
    } catch (error) {
      console.log(error);
    } finally {
      optionsStore.setCompleted('source');
    }
  };

  @action getFeatureSetContent = async () => {
    try {
      optionsStore.setLoading('feature');
      if (!packageStore.activeFeatureSetId) {
        this.feature = [];
        return;
      }

      const { feature } = await PackageService.getFeatureSetContent(packageStore.activeProjectId, packageStore.activeFeatureSetId);

      runInAction(() => {
        this.feature = feature.map((el) => new CheckItemViewModel(el));
      });

    } catch (error) {
      console.log(error);
    } finally {
      optionsStore.setCompleted('feature');
    }
  };

  @action onSelectedModalOpen = (target, sourceTag) => {
    switch (target) {
      case 'brand':
        this.singleSelectedModal.setItems(target, this.brand);
        break;
      case 'series':
        this.seriesViewModel.onModalOpen();
        break;
      case 'product':
        this.singleSelectedModal.setItems(target, this.product, true);
        break;
      case 'category':
      case 'website':
      case 'channel':
        switch (sourceTag) {
          case 'one':
            this.sourceSelectModalVmOne.onOpenWithType(target);
            break;
          default:
            this.sourceSelectModalVmTwo.onOpenWithType(target);
            break;
        }
        break;
      case 'featureSet':
        this.singleSelectedModal.setItems(target, this.feature);
        break;
      case 'sentiment':
        this.singleSelectedModal.setItems(target, this.sentiment);
        break;
      default:
        break;
    }
  };

  @action onBrandxSeriesClick = async () => {
    this.selectedOne = ['level', 'brand'];
    this.selectedOneText = '品牌';
    this.selectedTwo = ['level', 'series'];
    this.selectedTwoText = '系列';

    await Promise.all([
      this.checkSelectedContent('brand'),
      this.checkSelectedContent('series')
    ]);

    runInAction(() => {
      this.brand.forEach((el) => el.setInit(true));
      this.seriesViewModel.onParentSelect(this.seriesViewModel.series[0].id);
      this.seriesViewModel.seriesTarget.children.forEach((el) => {
        el.setInit();
      });
      this.seriesViewModel.onSubmit();
    });
  };

  @action onBrandxProduct = async () => {
    this.selectedOne = ['level', 'brand'];
    this.selectedOneText = '品牌';
    this.selectedTwo = ['level', 'product'];
    this.selectedTwoText = '產品';

    await Promise.all([
      this.checkSelectedContent('brand'),
      this.checkSelectedContent('product')
    ]);

    runInAction(() => {
      this.brand.forEach((el) => el.setInit(true));
      this.product.forEach((el) => el.children.forEach((c) => c.setInit(true)));
    });
  };

  @action onBrandxForumChannel = async () => {
    this.selectedOne = ['level', 'brand'];
    this.selectedOneText = '品牌';
    this.selectedTwo = ['source', 'channel'];
    this.selectedTwoText = '來源頻道';

    await Promise.all([
      this.checkSelectedContent('brand'),
      this.checkSelectedContent('channel')
    ]);

    runInAction(() => {
      this.brand.forEach((el) => el.setInit(true));
      this.sourceSelectModalVmTwo.setIsShow(this.selectedTwoId);
      this.sourceSelectModalVmTwo.onCleanAll();
      this.sourceSelectModalVmTwo.category.forEach((el) => {
        if (el.id === 'forum') {
          el.setSelect(true);
        }
      });
      this.sourceSelectModalVmTwo.showWebsites.forEach((w) => w.onGroupSelect(this.sourceSelectModalVmTwo.afterWebsiteSelect));
      this.sourceSelectModalVmTwo.showChannel.forEach((c) => c.onGroupSelect(() => { }));
      this.sourceSelectModalVmTwo.onSubmit();
    });
  };

  @action onBrandxFbChannel = async () => {
    this.selectedOne = ['level', 'brand'];
    this.selectedOneText = '品牌';
    this.selectedTwo = ['source', 'channel'];
    this.selectedTwoText = '來源頻道';

    await Promise.all([
      this.checkSelectedContent('brand'),
      this.checkSelectedContent('channel')
    ]);

    runInAction(() => {
      this.brand.forEach((el) => el.setInit(true));
      this.sourceSelectModalVmTwo.setIsShow(this.selectedTwoId);
      this.sourceSelectModalVmTwo.onCleanAll();
      this.sourceSelectModalVmTwo.category.forEach((el) => {
        if (el.id === 'facebook') {
          el.setSelect(true);
        }
      });
      this.sourceSelectModalVmTwo.showWebsites.forEach((w) => w.onGroupSelect(this.sourceSelectModalVmTwo.afterWebsiteSelect));
      this.sourceSelectModalVmTwo.showChannel.forEach((c) => c.onGroupSelect(() => { }));
      this.sourceSelectModalVmTwo.onSubmit();
    });
  };

  @action onClean = () => {
    this.selectedOne = null;
    this.selectedTwo = null;
  };

  @action onCleanSelectedOne = () => {
    switch (this.previousSelectedOneId) {
      case 'brand':
        this.brand.forEach((el) => el.setInit(false));
        break;
      case 'series':
        this.seriesViewModel.onCleanAll();
        break;
      case 'product':
        this.product.forEach((el) => {
          el.children.forEach((c) => c.setInit(false));
        });
        break;
      case 'category':
      case 'website':
      case 'channel':
        this.sourceSelectModalVmOne.onCleanAll();
        this.sourceSelectModalVmOne.onSubmit();
        break;
      case 'featureSet':
        this.feature.forEach((el) => el.setInit(false));
        break;
      case 'sentiment':
        this.sentiment.forEach((el) => el.setInit(false));
        break;
      default:
    }

    this.previousSelectedOne = this.selectedOne;
  };

  @action onCleanSelectedTwo = () => {
    switch (this.previousSelectedTwoId) {
      case 'brand':
        this.brand.forEach((el) => el.setInit(false));
        break;
      case 'series':
        this.seriesViewModel.onCleanAll();
        break;
      case 'product':
        this.product.forEach((el) => {
          el.children.forEach((c) => c.setInit(false));
        });
        break;
      case 'category':
      case 'website':
      case 'channel':
        this.sourceSelectModalVmTwo.onCleanAll();
        this.sourceSelectModalVmTwo.onSubmit();
        break;
      case 'featureSet':
        this.feature.forEach((el) => el.setInit(false));
        break;
      case 'sentiment':
        this.sentiment.forEach((el) => el.setInit(false));
        break;
      default:
    }
    this.previousSelectedTwo = this.selectedTwo;
  };

  @action onSubmit = async () => {
    const condition1 = (() => {
      switch (this.selectedOneId) {
        case 'brand':
          if (this.brand.filter((el) => el.checked).map((el) => el.id).length === 0) {
            message.error('請設定品牌條件');
            return null;
          }
          return {
            key: 'brand',
            value: this.brand.filter((el) => el.checked).map((el) => el.id)
          };
        case 'series':
          if (!this.seriesViewModel.seriesTarget?.id) {
            message.error('請設定系列條件');
            return null;
          }
          return {
            key: 'series',
            value: {
              tagKey: this.seriesViewModel.seriesTarget.id,
              tagValue: this.seriesViewModel.seriesTarget.children.filter((el) => el.checked).map((el) => el.id)
            }
          };
        case 'product':
          if (this.product.map((el) => el.selectedItem).filter((el) => el.length > 0).flat().length === 0) {
            message.error('請設定產品條件');
            return null;
          }
          return {
            key: 'product',
            value: this.product.map((el) => el.selectedItem).filter((el) => el.length > 0).flat()
          };
        case 'category':
          if (this.sourceSelectModalVmOne.selectedCategories.length === 0) {
            message.error('請設定來源類型條件');
            return null;
          }
          return {
            key: 'category',
            value: this.sourceSelectModalVmOne.selectedCategories
          };
        case 'website':
          if (this.sourceSelectModalVmOne.selectedWebsites.length === 0) {
            message.error('請設定來源網站條件');
            return null;
          }
          return {
            key: 'website',
            value: this.sourceSelectModalVmOne.selectedWebsites
          };
        case 'channel':
          if (this.sourceSelectModalVmOne.selectedChannels.length === 0) {
            message.error('請設定來源頻道條件');
            return null;
          }
          return {
            key: 'channel',
            value: this.sourceSelectModalVmOne.selectedChannels
          };
        case 'featureSet':
          if (this.feature.filter((el) => el.checked).map((el) => el.id).length === 0) {
            message.error('請設定分類詞庫條件');
            return null;
          }
          return {
            key: 'featureCategory',
            value: this.feature.filter((el) => el.checked).map((el) => el.id)
          };
        case 'sentiment':
          if (this.sentiment.filter((el) => el.checked).map((el) => el.id).length === 0) {
            message.error('請設定情緒條件');
            return null;
          }
          return {
            key: 'sentiment',
            value: this.sentiment.filter((el) => el.checked).map((el) => `${el.id}`)
          };
        default:
          return null;
      }
    })();

    const condition2 = (() => {
      switch (this.selectedTwoId) {
        case 'brand':
          if (this.brand.filter((el) => el.checked).map((el) => el.id).length === 0) {
            message.error('請設定品牌條件');
            return null;
          }
          return {
            key: 'brand',
            value: this.brand.filter((el) => el.checked).map((el) => el.id)
          };
        case 'series':
          if (!this.seriesViewModel.seriesTarget?.id) {
            message.error('請設定系列條件');
            return null;
          }
          return {
            key: 'series',
            value: {
              tagKey: this.seriesViewModel.seriesTarget.id,
              tagValue: this.seriesViewModel.seriesTarget.children.filter((el) => el.checked).map((el) => el.id)
            }
          };
        case 'product':
          if (this.product.map((el) => el.selectedItem).filter((el) => el.length > 0).flat().length === 0) {
            message.error('請設定產品條件');
            return null;
          }
          return {
            key: 'product',
            value: this.product.map((el) => el.selectedItem).filter((el) => el.length > 0).flat()
          };
        case 'category':
          if (this.sourceSelectModalVmTwo.selectedCategories.length === 0) {
            message.error('請設定來源類型條件');
            return null;
          }
          return {
            key: 'category',
            value: this.sourceSelectModalVmTwo.selectedCategories
          };
        case 'website':
          if (this.sourceSelectModalVmTwo.selectedWebsites.length === 0) {
            message.error('請設定來源網站條件');
            return null;
          }
          return {
            key: 'website',
            value: this.sourceSelectModalVmTwo.selectedWebsites
          };
        case 'channel':
          if (this.sourceSelectModalVmTwo.selectedChannels.length === 0) {
            message.error('請設定來源頻道條件');
            return null;
          }
          return {
            key: 'channel',
            value: this.sourceSelectModalVmTwo.selectedChannels
          };
        case 'featureSet':
          if (this.feature.filter((el) => el.checked).map((el) => el.id).length === 0) {
            message.error('請設定分類詞庫條件');
            return null;
          }
          return {
            key: 'featureCategory',
            value: this.feature.filter((el) => el.checked).map((el) => el.id)
          };
        case 'sentiment':
          if (this.sentiment.filter((el) => el.checked).map((el) => el.id).length === 0) {
            message.error('請設定情緒條件');
            return null;
          }
          return {
            key: 'sentiment',
            value: this.sentiment.filter((el) => el.checked).map((el) => `${el.id}`)
          };
        default:
          return null;
      }
    })();

    if (!condition1 || !condition2) {
      message.error('請完整設定交叉分析條件');
      return;
    }

    this.condition1Cache = condition1;
    this.condition2Cache = condition2;
    this.selectedOneTextCache = this.selectedOneText;
    this.selectedTwoTextCache = this.selectedTwoText;
    this.selectedSubType = 'compare';
    await this.getChart();

  };

  @action getChart = async () => {
    optionsStore.setLoading('search');
    // > 24/04/25 updating
    const checkLatest = await packageStore.checkLatest();
    if (checkLatest.needUpdate) {
      optionsStore.setCompleted('search');
      await packageStore.syncLatest(checkLatest);
      return;
    }
    try {
      const { currentDate, previousDate, hasCompare } = infoHeaderViewModel.multiViewModel;
      const res = await AdvancedSearchService.getChart(
        this.selectedSubType,
        {
          date: {
            gte: currentDate[0].format('YYYY-MM-DD'),
            lte: currentDate[1].format('YYYY-MM-DD')
          },
          condition1: this.condition1Cache,
          condition2: this.condition2Cache,
          ...(this.selectedPostType !== 'all' && { postType: this.selectedPostType }),
          ...(this.selectedWOMType !== 'all' && { womType: this.selectedWOMType })
        },
        ...(
          hasCompare
            ? [{
              date: {
                gte: previousDate[0].format('YYYY-MM-DD'),
                lte: previousDate[1].format('YYYY-MM-DD')
              },
              condition1: this.condition1Cache,
              condition2: this.condition2Cache,
              ...(this.selectedPostType !== 'all' && { postType: this.selectedPostType }),
              ...(this.selectedWOMType !== 'all' && { womType: this.selectedWOMType })
            }]
            : [null]
        ),
        this.seriesViewModel.seriesTarget?.name
      );

      const charts = res.data.chartData[0].data.map((el, i) => ChartFactory.createChartViewModel({ ...res.data.chartData[0], data: el, time: i === 0 ? 'present' : 'previous' }));

      switch (this.selectedSubType) {
        case 'trend':
          this.trendViewModel.updateChart(charts);
          break;
        case 'ranking':
          this.rankingViewModel.updateChart(charts);
          break;
        case 'proportion':
          this.proportionViewModel.updateChart(charts);
          break;
        case 'compare':
          this.compareViewModel.updateChart(charts);
          break;
        default:
          break;
      }
    } catch (error) {
      console.log(error);
    } finally {
      optionsStore.setCompleted('search');
    }
  };

  @action onPostTypeChange = (e) => {
    this.selectedPostType = e;
  };

  @action onWOMTypeChange = (e) => {
    this.selectedWOMType = e;
  };

  @action onCleanStatisticFilter = () => {
    this.selectedPostType = 'all';
    this.selectedWOMType = 'all';
  };

  @action onSubChartTypeChange = (value) => {
    this.selectedSubType = value;
    this.getChart();
  };

  @action onCSVDownload = () => {
    optionsStore.setLoading();
    const { csv, csvKey } = this.showChart.charts[0];
    if (!csv || !csvKey) {
      message.info('聲量數據不存在');
      optionsStore.setCompleted();
      return;
    }

    const { currentTimeText, previousTimeText, hasCompare } = infoHeaderViewModel.multiViewModel;
    const data = [...csv];
    const header = csvKey.map((el) => el.key);
    const workSheet = xlsx.utils.json_to_sheet(data, { header });
    xlsx.utils.sheet_add_aoa(workSheet, [csvKey.map((el) => el.header)], { origin: 'A1' });
    workSheet['!cols'] = header.map((_, i) => ({ width: 15 }));
    const wordbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wordbook, workSheet, '交叉分析');
    xlsx.writeFile(wordbook, `${packageStore.activePackageName}-${this.chartName}-${currentTimeText}${hasCompare ? `-${previousTimeText}` : ''}.xlsx`);
    optionsStore.setCompleted();
  };

  @action onDownload = async () => {
    optionsStore.onImgDownload();
    const chart = document.querySelector('.download-chart');
    try {
      const canvas = await html2canvas(chart);
      const a = document.createElement('a');
      const { currentTimeText, previousTimeText, hasCompare } = infoHeaderViewModel.multiViewModel;
      a.href = canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream');
      a.download = `${packageStore.activePackageName}-${this.chartName}-${currentTimeText}${hasCompare ? `-${previousTimeText}` : ''}.jpg`;
      a.click();
    } catch (error) {
      console.log(error);
    } finally {
      optionsStore.onImgDownloadEnd();
    }
  };
}
