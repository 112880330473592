export default class CustomerListModel {
  constructor(data) {
    this.customerList = data.map((el) => {
      return ({
        value: el.id,
        label: el.name
      });
    });
  }

  static fromRes(data) {
    return new CustomerListModel(data);
  }
}
