import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import ChartFactory from 'src/components/Charts/factory';

export default class ChartItemViewModel {

  parent = {};

  @observable data = [];
  @observable activeTabId = null;
  @observable isModalOpen = false;

  @observable wordsViewModel = null;

  @observable onReady = false;

  @computed get showChart() {
    return this.activeTabId ? this.data.find((el) => el.id === this.activeTabId).chart : [];
  }

  constructor(data, parent) {
    makeObservable(this);

    this.init(data, parent);
  }

  @action init = (data, parent) => {
    this.parent = parent;
    this.data = data.chartData.map((el) => ({
      id: el.id,
      name: el.name,
      chart: el.data.map((d) => ChartFactory.createChartViewModel({ ...el, data: d }, this))
    }));
    this.activeTabId = this.data[0].id;

    this.onReady = true;
  };

  @action onTabsChange = (key) => {
    this.activeTabId = key;
  };
}
